import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PatientNeeds } from "./PatientExperience/PatientNeeds";
import { PatientJourney } from "./PatientExperience/PatientJourney";
import { PatientSentiment } from "./PatientExperience/PatientSentiment";
import { FilterActiveInterface, FilterInterface } from ".";
import { fetchedFilters, pageWiseFilters } from "./data";
import SkeletonCard, { SkeletonFull, SkeletonJourney } from "../../Components/SkeletonCard";
import {
  getPatientExperienceData,
  getPatientNeedsDetails,
} from "./ServiceHelpers/PatientExperience";
import { Skeleton, Space } from "antd";

const DUMMY_DATA = {
  patientNeeds: [
    {
      cardName: "",
      month: [],
      value: [],
      unit: [],
      vsPrevMonth: "",
      topChart: {},
      chartName: ["Topic Opportunities"],
      xAxisLabel: ["Sentiments (Net Promoter Score)"],
      yAxisLabel: ["# of Patients Mentioning Topic"],
      footer: "",
      chartColor: ["gray"],
      tableLinks: [],
      chartAccessor: [],
      chartData: [
        { x: 21, y: 3 },
        { x: 22, y: 8 },
        { x: 23, y: 5 },
        { x: 24, y: 10 },
        { x: 25, y: 6 },
        { x: 26, y: 15 },
        { x: 27, y: 3 },
        { x: 28, y: 12 },
        { x: 29, y: 7 },
        { x: 30, y: 9 },
        { x: 31, y: 11 },
        { x: 32, y: 13 },
        { x: 33, y: 9 },
        { x: 34, y: 6 },
        { x: 35, y: 15 },
        { x: 36, y: 5 },
        { x: 37, y: 4 },
        { x: 38, y: 10 },
      ],
      storyDetails: {},
    },
    {
      cardName: "",
      month: [],
      value: [],
      unit: [],
      vsPrevMonth: "",
      topChart: {},
      chartName: ["Unmet Needs Summary"],
      xAxisLabel: [],
      yAxisLabel: [],
      footer: "",
      chartColor: ["#3B9FE7", "#E65428", "#9C9AA1", "#00AA67"],
      chartAccessor: [
        { id: "Mentions Over Time", type: "line" },
        { id: "Consumer Sentiment", type: "bar" },
      ],
      tableLinks: [{ id: "Story Name", link: "" }],
      chartData: [
        {
          "Story Name": "Alcohol Consumption",
          "Story Type": "Patient Complaint",
          "#Mentions": 32,
          "Mentions Over Time": [
            {
              "Feb 21": {
                "Series 1": 200,
              },
              "Mar 21": {
                "Series 1": 231,
              },
              "Apr 21": {
                "Series 1": 210,
              },
              "May 21": {
                "Series 1": 205,
              },
              "Jun 21": {
                "Series 1": 235,
              },
              "Jul 21": {
                "Series 1": 212,
              },
              "Aug 21": {
                "Series 1": 204,
              },
              "Sep 21": {
                "Series 1": 216,
              },
              "Oct 21": {
                "Series 1": 212,
              },
              "Nov 21": {
                "Series 1": 220,
              },
            },
          ],
          "QoQ Growth": "1.5%",
          "Trend Score": 23.7,
          "Consumer Sentiment": [
            {
              "Consumer Sentiment": {
                Negative: 6,
                Neutral: 14,
                Positive: 12,
              },
            },
          ],
        },
        {
          "Story Name": "Side Effects",
          "Story Type": "Patient Needs",
          "#Mentions": 30,
          "Mentions Over Time": [
            {
              "Feb 21": {
                "Series 1": 200,
              },
              "Mar 21": {
                "Series 1": 209,
              },
              "Apr 21": {
                "Series 1": 220,
              },
              "May 21": {
                "Series 1": 225,
              },
              "Jun 21": {
                "Series 1": 235,
              },
              "Jul 21": {
                "Series 1": 222,
              },
              "Aug 21": {
                "Series 1": 223,
              },
              "Sep 21": {
                "Series 1": 228,
              },
              "Oct 21": {
                "Series 1": 232,
              },
              "Nov 21": {
                "Series 1": 230,
              },
            },
          ],
          "QoQ Growth": "1.5%",
          "Trend Score": -2.4,
          "Consumer Sentiment": [
            {
              "Consumer Sentiment": {
                Negative: 7,
                Neutral: 15,
                Positive: 8,
              },
            },
          ],
        },
        {
          "Story Name": "Meal Restriction",
          "Story Type": "General Topic",
          "#Mentions": 24,
          "Mentions Over Time": [
            {
              "Feb 21": {
                "Series 1": 180,
              },
              "Mar 21": {
                "Series 1": 209,
              },
              "Apr 21": {
                "Series 1": 210,
              },
              "May 21": {
                "Series 1": 218,
              },
              "Jun 21": {
                "Series 1": 206,
              },
              "Jul 21": {
                "Series 1": 197,
              },
              "Aug 21": {
                "Series 1": 207,
              },
              "Sep 21": {
                "Series 1": 218,
              },
              "Oct 21": {
                "Series 1": 212,
              },
              "Nov 21": {
                "Series 1": 210,
              },
            },
          ],
          "QoQ Growth": "1.2%",
          "Trend Score": 12.4,
          "Consumer Sentiment": [
            {
              "Consumer Sentiment": {
                Negative: 6,
                Neutral: 8,
                Positive: 10,
              },
            },
          ],
        },
        {
          "Story Name": "Pill Box",
          "Story Type": "Patient Complaint",
          "#Mentions": 21,
          "Mentions Over Time": [
            {
              "Feb 21": {
                "Series 1": 196,
              },
              "Mar 21": {
                "Series 1": 200,
              },
              "Apr 21": {
                "Series 1": 198,
              },
              "May 21": {
                "Series 1": 201,
              },
              "Jun 21": {
                "Series 1": 201,
              },
              "Jul 21": {
                "Series 1": 202,
              },
              "Aug 21": {
                "Series 1": 198,
              },
              "Sep 21": {
                "Series 1": 202,
              },
              "Oct 21": {
                "Series 1": 207,
              },
              "Nov 21": {
                "Series 1": 204,
              },
            },
          ],
          "QoQ Growth": "0.8%",
          "Trend Score": 12.4,
          "Consumer Sentiment": [
            {
              "Consumer Sentiment": {
                Negative: 10,
                Neutral: 5,
                Positive: 6,
              },
            },
          ],
        },
        {
          "Story Name": "Rx Subscriptions",
          "Story Type": "Patient Complaint",
          "#Mentions": 19,
          "Mentions Over Time": [
            {
              "Feb 21": {
                "Series 1": 202,
              },
              "Mar 21": {
                "Series 1": 201,
              },
              "Apr 21": {
                "Series 1": 210,
              },
              "May 21": {
                "Series 1": 215,
              },
              "Jun 21": {
                "Series 1": 215,
              },
              "Jul 21": {
                "Series 1": 212,
              },
              "Aug 21": {
                "Series 1": 213,
              },
              "Sep 21": {
                "Series 1": 218,
              },
              "Oct 21": {
                "Series 1": 212,
              },
              "Nov 21": {
                "Series 1": 210,
              },
            },
          ],
          "QoQ Growth": "1.0%",
          "Trend Score": -1.2,
          "Consumer Sentiment": [
            {
              "Consumer Sentiment": {
                Negative: 6,
                Neutral: 10,
                Positive: 3,
              },
            },
          ],
        },
        {
          "Story Name": "Side Effects",
          "Story Type": "Patient Needs",
          "#Mentions": 30,
          "Mentions Over Time": [
            {
              "Feb 21": {
                "Series 1": 200,
              },
              "Mar 21": {
                "Series 1": 209,
              },
              "Apr 21": {
                "Series 1": 220,
              },
              "May 21": {
                "Series 1": 225,
              },
              "Jun 21": {
                "Series 1": 235,
              },
              "Jul 21": {
                "Series 1": 222,
              },
              "Aug 21": {
                "Series 1": 223,
              },
              "Sep 21": {
                "Series 1": 228,
              },
              "Oct 21": {
                "Series 1": 232,
              },
              "Nov 21": {
                "Series 1": 230,
              },
            },
          ],
          "QoQ Growth": "1.5%",
          "Trend Score": -2.4,
          "Consumer Sentiment": [
            {
              "Consumer Sentiment": {
                Negative: 7,
                Neutral: 15,
                Positive: 8,
              },
            },
          ],
        },
      ],
      storyDetails: {
        "Alcohol Consumption": [
          {
            transcriptDate: "12 June 2020",
            timeDuration: "05.33.23",
            details: [
              {
                person: "Agent",
                speech: "Are there any other questions I can answer for you?",
              },
              {
                person: "Patient",
                speech:
                  "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
              },
              {
                person: "Agent",
                speech:
                  "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
              },
            ],
          },
          {
            transcriptDate: "10 May 2020",
            timeDuration: "12.48.45",
            details: [
              {
                person: "Agent",
                speech: "Are there any other questions I can answer for you?",
              },
              {
                person: "Patient",
                speech:
                  "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
              },
              {
                person: "Agent",
                speech:
                  "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
              },
            ],
          },
          {
            transcriptDate: "1 Jan2020",
            timeDuration: "01.00.45",
            details: [
              {
                person: "Agent",
                speech: "Are there any other questions I can answer for you?",
              },
              {
                person: "Patient",
                speech:
                  "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
              },
              {
                person: "Agent",
                speech:
                  "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
              },
            ],
          },
        ],
        "Side Effects": [
          {
            transcriptDate: "10 May 2020",
            timeDuration: "02.13.32",
            details: [
              {
                person: "Agent",
                speech: "Are there any other questions I can answer for you?",
              },
              {
                person: "Patient",
                speech: "So, Are there any Side Effects associated with this new drug",
              },
              {
                person: "Agent",
                speech: "Um, no. Currently there are no reported Side Effects...",
              },
            ],
          },
        ],
      },
    },
  ],
  patientJourney: {
    unmetNeedsAndQuestionTime: [
      {
        cardName: "",
        month: [],
        value: [],
        unit: [],
        vsPrevMonth: [],
        topChart: {},
        chartName: ["Top 5 Unmet Needs"],
        xAxisLabel: [],
        yAxisLabel: [],
        footer: "",
        chartColor: [],
        tableLinks: [],
        chartAccessor: [],
        chartData: [
          {
            "30": {
              Access: "78%",
              "Side Effects": "64%",
              "COVID-19": "31%",
              "Dosing Instructions": "27%",
              "Dietary Guidance": "12%",
            },
            "60": {
              "Dietary Restriction": "61%",
              "Dosing Instructions": "59%",
              Access: "44%",
              "ZSumab App": "32%",
              "Side Effects": "27%",
            },
            "90": {
              "Side Effects": "45%",
              "COVID-19": "43%",
              Access: "29%",
              "Dosing Instructions": "22%",
              "Pill Box": "19%",
            },
            "180": {
              "Pill Box": "51%",
              Access: "38%",
              "Dosing Instructions": "37%",
              "COVID-19": "34%",
              "Side Effects": "16%",
            },
            "180+": {
              "COVID-19": "45%",
              Access: "42%",
              "Medication Expiration": "36%",
              "Side Effects": "32%",
              Billing: "27%",
            },
          },
        ],
      },
      {
        cardName: "",
        month: [],
        value: [42, 12, 13, 41, 11],
        unit: ["secs", "secs", "secs", "secs", "secs"],
        vsPrevMonth: [],
        topChart: {},
        chartName: ["Average Time Spent in Asking Questions"],
        xAxisLabel: [],
        yAxisLabel: [],
        footer: "",
        chartColor: [],
        tableLinks: [],
        chartAccessor: [],
        chartData: [
          {
            "Feb 21": {
              "Series 1": 45,
            },
            "Mar 21": {
              "Series 1": 50,
            },
            "Apr 21": {
              "Series 1": 52,
            },
            "May 21": {
              "Series 1": 37,
            },
            "Jun 21": {
              "Series 1": 85,
            },
            "Jul 21": {
              "Series 1": 63,
            },
            "Aug 21": {
              "Series 1": 70,
            },
            "Sep 21": {
              "Series 1": 65,
            },
            "Oct 21": {
              "Series 1": 68,
            },
            "Nov 21": {
              "Series 1": 72,
            },
          },
          {
            "Feb 21": {
              "Series 1": 45,
            },
            "Mar 21": {
              "Series 1": 50,
            },
            "Apr 21": {
              "Series 1": 52,
            },
            "May 21": {
              "Series 1": 37,
            },
            "Jun 21": {
              "Series 1": 85,
            },
            "Jul 21": {
              "Series 1": 63,
            },
            "Aug 21": {
              "Series 1": 70,
            },
            "Sep 21": {
              "Series 1": 65,
            },
            "Oct 21": {
              "Series 1": 68,
            },
            "Nov 21": {
              "Series 1": 72,
            },
          },
          {
            "Feb 21": {
              "Series 1": 45,
            },
            "Mar 21": {
              "Series 1": 50,
            },
            "Apr 21": {
              "Series 1": 52,
            },
            "May 21": {
              "Series 1": 37,
            },
            "Jun 21": {
              "Series 1": 85,
            },
            "Jul 21": {
              "Series 1": 63,
            },
            "Aug 21": {
              "Series 1": 70,
            },
            "Sep 21": {
              "Series 1": 65,
            },
            "Oct 21": {
              "Series 1": 68,
            },
            "Nov 21": {
              "Series 1": 72,
            },
          },
          {
            "Feb 21": {
              "Series 1": 45,
            },
            "Mar 21": {
              "Series 1": 50,
            },
            "Apr 21": {
              "Series 1": 52,
            },
            "May 21": {
              "Series 1": 37,
            },
            "Jun 21": {
              "Series 1": 85,
            },
            "Jul 21": {
              "Series 1": 63,
            },
            "Aug 21": {
              "Series 1": 70,
            },
            "Sep 21": {
              "Series 1": 65,
            },
            "Oct 21": {
              "Series 1": 68,
            },
            "Nov 21": {
              "Series 1": 72,
            },
          },
          {
            "Feb 21": {
              "Series 1": 45,
            },
            "Mar 21": {
              "Series 1": 50,
            },
            "Apr 21": {
              "Series 1": 52,
            },
            "May 21": {
              "Series 1": 37,
            },
            "Jun 21": {
              "Series 1": 85,
            },
            "Jul 21": {
              "Series 1": 63,
            },
            "Aug 21": {
              "Series 1": 70,
            },
            "Sep 21": {
              "Series 1": 65,
            },
            "Oct 21": {
              "Series 1": 68,
            },
            "Nov 21": {
              "Series 1": 72,
            },
          },
        ],
      },
    ],
    sentimentBreakdown: [
      {
        cardName: "30",
        month: [],
        value: [],
        unit: [],
        vsPrevMonth: [],
        topChart: {
          positive: 45,
          neutral: 44,
          negative: 11,
        },
        chartName: ["Overall Negative Sentiments"],
        xAxisLabel: [],
        yAxisLabel: [],
        footer: "",
        chartColor: [],
        tableLinks: [],
        chartAccessor: [],
        chartData: [
          {
            Confused: {
              "Series 1": 37,
            },
            Mad: {
              "Series 1": 45,
            },
            Frustrated: {
              "Series 1": 50,
            },
            Disoriented: {
              "Series 1": 52,
            },
            Sad: {
              "Series 1": 85,
            },
          },
        ],
      },
      {
        cardName: "60",
        month: [],
        value: [],
        unit: [],
        vsPrevMonth: [],
        topChart: {
          positive: 47,
          neutral: 21,
          negative: 32,
        },
        chartName: [],
        xAxisLabel: [],
        yAxisLabel: [],
        footer: "",
        chartColor: [],
        tableLinks: [],
        chartAccessor: [],
        chartData: [
          {
            Confused: {
              "Series 1": 37,
            },
            Mad: {
              "Series 1": 45,
            },
            Frustrated: {
              "Series 1": 50,
            },
            Disoriented: {
              "Series 1": 52,
            },
            Sad: {
              "Series 1": 85,
            },
          },
        ],
      },
      {
        cardName: "90",
        month: [],
        value: [],
        unit: [],
        vsPrevMonth: [],
        topChart: {
          positive: 30,
          neutral: 12,
          negative: 58,
        },
        chartName: [],
        xAxisLabel: [],
        yAxisLabel: [],
        footer: "",
        chartColor: [],
        tableLinks: [],
        chartAccessor: [],
        chartData: [
          {
            Confused: {
              "Series 1": 37,
            },
            Mad: {
              "Series 1": 45,
            },
            Frustrated: {
              "Series 1": 50,
            },
            Disoriented: {
              "Series 1": 52,
            },
            Sad: {
              "Series 1": 85,
            },
          },
        ],
      },
      {
        cardName: "180",
        month: [],
        value: [],
        unit: [],
        vsPrevMonth: [],
        topChart: {
          positive: 27,
          neutral: 24,
          negative: 49,
        },
        chartName: [],
        xAxisLabel: [],
        yAxisLabel: [],
        footer: "",
        chartColor: [],
        tableLinks: [],
        chartAccessor: [],
        chartData: [
          {
            Confused: {
              "Series 1": 37,
            },
            Mad: {
              "Series 1": 45,
            },
            Frustrated: {
              "Series 1": 50,
            },
            Disoriented: {
              "Series 1": 52,
            },
            Sad: {
              "Series 1": 85,
            },
          },
        ],
      },
      {
        cardName: "180+",
        month: [],
        value: [],
        unit: [],
        vsPrevMonth: [],
        topChart: {
          positive: 19,
          neutral: 20,
          negative: 61,
        },
        chartName: [],
        xAxisLabel: [],
        yAxisLabel: [],
        footer: "",
        chartColor: [],
        tableLinks: [],
        chartAccessor: [],
        chartData: [
          {
            Confused: {
              "Series 1": 37,
            },
            Mad: {
              "Series 1": 45,
            },
            Frustrated: {
              "Series 1": 50,
            },
            Disoriented: {
              "Series 1": 52,
            },
            Sad: {
              "Series 1": 85,
            },
          },
        ],
      },
    ],
  },
  patientSentiment: [
    {
      cardName: "Overall Sentiments for November 2021",
      kpi: [],
      month: [],
      value: [],
      unit: [],
      vsPrevMonth: [],
      topChart: { Positive: 47, Neutral: 21, Negative: 32 },
      chartName: [],
      xAxisLabel: [],
      yAxisLabel: [],
      chartColor: ["#26C38D", "#DEDCDE", "#E65428"],
      chartAccessor: [],
      footer: "",
      chartData: [],
    },
    {
      cardName: "Overall Sentiments Change from Previous Month",
      kpi: [
        "Overall Positive Sentiment",
        "Overall Neutral Sentiment",
        "Overall Negative Sentiment",
      ],
      month: [],
      value: [],
      unit: ["%", "%", "%"],
      vsPrevMonth: ["3.5", "0.5", "-3.5"],
      topChart: {},
      chartName: [],
      xAxisLabel: [],
      yAxisLabel: [],
      chartColor: [],
      chartAccessor: [],
      footer: "",
      chartData: [],
    },
    {
      cardName: "Sentiment By Topic",
      kpi: [],
      month: [],
      value: [],
      unit: [],
      vsPrevMonth: [],
      topChart: {},
      chartName: [],
      xAxisLabel: [],
      yAxisLabel: [],
      chartColor: ["#E65428", "#DEDCDE", "#26C38D"],
      chartAccessor: [],
      footer: "",
      chartData: [
        {
          "Alcohol Consumption": {
            Negative: 43,
            Neutral: 44,
            Positive: 13,
          },
          "Side Effects": {
            Negative: 40,
            Neutral: 46,
            Positive: 14,
          },
          "Meal Restrictions": {
            Negative: 71,
            Neutral: 21,
            Positive: 8,
          },
          "Receiving too many calls": {
            Negative: 60,
            Neutral: 9,
            Positive: 31,
          },
          "Pill Box": {
            Negative: 54,
            Neutral: 27,
            Positive: 19,
          },
        },
      ],
    },
    {
      cardName: "Sentiment Over Time",
      kpi: [],
      month: [],
      value: [],
      unit: [],
      vsPrevMonth: [],
      topChart: {},
      chartName: [
        "All",
        "Alcohol Consumption",
        "Side Effects",
        "Meal Restrictions",
        "Rx Subscription",
        "Pill Box",
      ],
      xAxisLabel: [],
      yAxisLabel: [
        "Number of Calls",
        "Number of Calls",
        "Number of Calls",
        "Number of Calls",
        "Number of Calls",
        "Number of Calls",
      ],
      chartColor: ["#E65428", "#DEDCDE", "#26C38D"],
      chartAccessor: [],
      footer: "",
      chartData: [
        {
          "Feb 21": {
            Negative: 66,
            Neutral: 56,
            Positive: 24,
          },
          "Mar 21": {
            Negative: 60,
            Neutral: 59,
            Positive: 29,
          },
          "Apr 21": {
            Negative: 61,
            Neutral: 66,
            Positive: 25,
          },
          "May 21": {
            Negative: 43,
            Neutral: 62,
            Positive: 32,
          },
          "Jun 21": {
            Negative: 52,
            Neutral: 68,
            Positive: 42,
          },
          "Jul 21": {
            Negative: 38,
            Neutral: 74,
            Positive: 38,
          },
          "Aug 21": {
            Negative: 31,
            Neutral: 76,
            Positive: 40,
          },
          "Sep 21": {
            Negative: 34,
            Neutral: 77,
            Positive: 48,
          },
          "Oct 21": {
            Negative: 26,
            Neutral: 84,
            Positive: 49,
          },
          "Nov 21": {
            Negative: 22,
            Neutral: 80,
            Positive: 50,
          },
        },
        {
          "Feb 21": {
            Negative: 36,
            Neutral: 22,
            Positive: 24,
          },
          "Mar 21": {
            Negative: 30,
            Neutral: 29,
            Positive: 29,
          },
          "Apr 21": {
            Negative: 41,
            Neutral: 36,
            Positive: 25,
          },
          "May 21": {
            Negative: 43,
            Neutral: 32,
            Positive: 32,
          },
          "Jun 21": {
            Negative: 52,
            Neutral: 38,
            Positive: 42,
          },
          "Jul 21": {
            Negative: 38,
            Neutral: 34,
            Positive: 38,
          },
          "Aug 21": {
            Negative: 31,
            Neutral: 46,
            Positive: 40,
          },
          "Sep 21": {
            Negative: 34,
            Neutral: 47,
            Positive: 48,
          },
          "Oct 21": {
            Negative: 26,
            Neutral: 54,
            Positive: 49,
          },
          "Nov 21": {
            Negative: 22,
            Neutral: 60,
            Positive: 50,
          },
        },
        {
          "Feb 21": {
            Negative: 66,
            Neutral: 56,
            Positive: 24,
          },
          "Mar 21": {
            Negative: 60,
            Neutral: 59,
            Positive: 29,
          },
          "Apr 21": {
            Negative: 61,
            Neutral: 66,
            Positive: 25,
          },
          "May 21": {
            Negative: 43,
            Neutral: 62,
            Positive: 32,
          },
          "Jun 21": {
            Negative: 52,
            Neutral: 68,
            Positive: 42,
          },
          "Jul 21": {
            Negative: 38,
            Neutral: 74,
            Positive: 38,
          },
          "Aug 21": {
            Negative: 31,
            Neutral: 76,
            Positive: 40,
          },
          "Sep 21": {
            Negative: 34,
            Neutral: 77,
            Positive: 48,
          },
          "Oct 21": {
            Negative: 26,
            Neutral: 84,
            Positive: 49,
          },
          "Nov 21": {
            Negative: 22,
            Neutral: 80,
            Positive: 50,
          },
        },
        {
          "Feb 21": {
            Negative: 66,
            Neutral: 56,
            Positive: 24,
          },
          "Mar 21": {
            Negative: 60,
            Neutral: 59,
            Positive: 29,
          },
          "Apr 21": {
            Negative: 61,
            Neutral: 66,
            Positive: 25,
          },
          "May 21": {
            Negative: 43,
            Neutral: 62,
            Positive: 32,
          },
          "Jun 21": {
            Negative: 52,
            Neutral: 68,
            Positive: 42,
          },
          "Jul 21": {
            Negative: 38,
            Neutral: 74,
            Positive: 38,
          },
          "Aug 21": {
            Negative: 31,
            Neutral: 76,
            Positive: 40,
          },
          "Sep 21": {
            Negative: 34,
            Neutral: 77,
            Positive: 48,
          },
          "Oct 21": {
            Negative: 26,
            Neutral: 84,
            Positive: 49,
          },
          "Nov 21": {
            Negative: 22,
            Neutral: 80,
            Positive: 50,
          },
        },
        {
          "Feb 21": {
            Negative: 66,
            Neutral: 56,
            Positive: 24,
          },
          "Mar 21": {
            Negative: 60,
            Neutral: 59,
            Positive: 29,
          },
          "Apr 21": {
            Negative: 61,
            Neutral: 66,
            Positive: 25,
          },
          "May 21": {
            Negative: 43,
            Neutral: 62,
            Positive: 32,
          },
          "Jun 21": {
            Negative: 52,
            Neutral: 68,
            Positive: 42,
          },
          "Jul 21": {
            Negative: 38,
            Neutral: 74,
            Positive: 38,
          },
          "Aug 21": {
            Negative: 31,
            Neutral: 76,
            Positive: 40,
          },
          "Sep 21": {
            Negative: 34,
            Neutral: 77,
            Positive: 48,
          },
          "Oct 21": {
            Negative: 26,
            Neutral: 84,
            Positive: 49,
          },
          "Nov 21": {
            Negative: 22,
            Neutral: 80,
            Positive: 50,
          },
        },
        {
          "Feb 21": {
            Negative: 66,
            Neutral: 56,
            Positive: 24,
          },
          "Mar 21": {
            Negative: 60,
            Neutral: 59,
            Positive: 29,
          },
          "Apr 21": {
            Negative: 61,
            Neutral: 66,
            Positive: 25,
          },
          "May 21": {
            Negative: 43,
            Neutral: 62,
            Positive: 32,
          },
          "Jun 21": {
            Negative: 52,
            Neutral: 68,
            Positive: 42,
          },
          "Jul 21": {
            Negative: 38,
            Neutral: 74,
            Positive: 38,
          },
          "Aug 21": {
            Negative: 31,
            Neutral: 76,
            Positive: 40,
          },
          "Sep 21": {
            Negative: 34,
            Neutral: 77,
            Positive: 48,
          },
          "Oct 21": {
            Negative: 26,
            Neutral: 84,
            Positive: 49,
          },
          "Nov 21": {
            Negative: 22,
            Neutral: 80,
            Positive: 50,
          },
        },
      ],
    },
  ],
};

/**
 * This page is the main engagement tab in the dashboard page. It also contains the routes to navigate between Patient Needs, Patient Journey and Patient Sentiment
 *
 * 	@param filterActiveStatus - Props used to pass the active filters as true
 *  @param setFilterActiveStatus - Function to set the status of active filters as true
 *  @param filters - This props is used to pass the filters in the card
 *
 * @returns the navigation to navigate between different subtabs
 */

export default function Engagement(props: {
  filterActiveStatus: FilterActiveInterface;
  setFilterActiveStatus: React.Dispatch<React.SetStateAction<FilterActiveInterface>>;
  filters: FilterInterface;
}) {
  const { subsubtab } = useParams();
  // const [patientExperienceData, setPatientExperienceData] =
  // 	React.useState<any>({});
  const [needsData, setNeedsData] = React.useState<any>({});
  const [journeyData, setJourneyData] = React.useState<any>({});
  const [sentimentData, setSentimentData] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>(true);
  const [subTab, setSubTab] = React.useState("");
  let navigate = useNavigate();

  function findId(filter: string, value: any) {
    let grpIdx: number = 0;
    let idx: number = 0;
    fetchedFilters.map((f) => {
      f.optionsObject.map((group) => {
        if (filter === f.filter) {
          if (group.subgroup.length === 0 && value === group.name) {
            idx = group.id;
            grpIdx = group.id;
            return group.id;
          } else if (group.subgroup.length > 0) {
            if (Array.isArray(value) && value.find((f) => f === group.name)) {
              grpIdx = group.id;
            } else if (Array.isArray(value)) {
              value.map((v) => {
                const ob = group.subgroup.find((sg) => sg.name === v);
                if (ob) {
                  grpIdx = group.id;
                  idx = ob.id;
                }

                return -1;
              });
            }
          }
        }
      });
    });
    return { idx, grpIdx };
  }

  const getFiltersObject = (filters: FilterInterface) => {
    var poFilters: FilterInterface = Object.assign({}, filters);
    poFilters.Call_Type = findId("Call_Type", filters.Call_Type).grpIdx;
    poFilters.Therapy_Status = findId("Therapy_Status", filters.Therapy_Status).grpIdx;
    poFilters.Call_Intenty = findId("Call_Intent", filters.Call_Intent).grpIdx;
    poFilters.Adherence = findId("Adherence", filters.Adherence).grpIdx;
    return poFilters;
  };
  const fetchPatientExperienceData = async (st: string) => {
    setLoading(true);
    // setPatientExperienceData({});
    var poFilters: FilterInterface = getFiltersObject(props.filters);
    const data: any = await getPatientExperienceData(st || "", poFilters, props.filterActiveStatus);

    if (data) {
      if (subsubtab === "Needs") setNeedsData(data);
      if (subsubtab === "Journey") setJourneyData(data);
      if (subsubtab === "Sentiment") setSentimentData(data);
      setLoading(false);
    } else {
      // setPatientExperienceData({});
    }
  };

  const addSubTabFilters = (tab_name: string) => {
    const obj: FilterActiveInterface = props.filterActiveStatus;
    for (let key in obj) {
      obj[key] = false;
    }
    var filters = pageWiseFilters.find((page) => page.tab === "Patient Needs");
    console.log("Filters Found", filters);
    if (filters?.filter.length === 0) {
      filters.subtab &&
        filters.subtab
          .find((page) => page.name === tab_name)
          ?.filters.map((filter) => (obj[filter] = true));
    } else {
      filters && filters.filter.map((filter) => (obj[filter] = true));
    }
    props.setFilterActiveStatus(obj);
  };
  React.useEffect(() => {
    if (subsubtab === undefined) navigate("Needs");
  });
  React.useEffect(() => {
    console.log("Inside this function ", props.filters, subsubtab);
    addSubTabFilters(subsubtab || "");
    setSubTab(subsubtab || "");
    fetchPatientExperienceData(subsubtab || "");
  }, [props.filters, subsubtab]);
  // React.useEffect(() => {
  // 	const obj: FilterActiveInterface = props.filterActiveStatus;
  // 	for (let key in obj) {
  // 		obj[key] = false;
  // 	}
  // 	pageWiseFilters
  // 		.find((page) => page.tab === "Patient Experience")
  // 		?.filter.map((filter) => (obj[filter] = true));
  // 	props.setFilterActiveStatus(obj);
  // }, []);
  return (
    <>
      {console.log("Load", loading)}
      {RenderView(loading, subsubtab || "", needsData, sentimentData, journeyData)}
    </>
  );
}

function RenderView(
  loading: boolean,
  subsubtab: string,
  needsData: any,
  sentimentData: any,
  journeyData: any
) {
  switch (subsubtab) {
    case "Needs":
      return (
        <>
          {!loading && needsData.patientNeeds && needsData.patientNeeds.length > 0 ? (
            <PatientNeeds data={needsData.patientNeeds} />
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
              <SkeletonFull />
              <SkeletonFull />
            </div>
          )}
        </>
      );
    case "Journey":
      return (
        <>
          {!loading &&
          journeyData.patientJourney &&
          Object.keys(journeyData.patientJourney).length > 0 ? (
            <PatientJourney data={journeyData.patientJourney} />
          ) : (
            <SkeletonJourney />
          )}
        </>
      );
    case "Sentiment":
      return (
        <>
          {!loading &&
          sentimentData.patientSentiments &&
          sentimentData.patientSentiments.length > 0 ? (
            <PatientSentiment data={sentimentData.patientSentiments} />
          ) : (
            <div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-6">
              <SkeletonCard />
              <SkeletonCard />
            </div>
          )}
        </>
      );
  }
}
// {!loading ? (
// 	subTab === "Needs" &&
// 	patientExperienceData.patientNeeds.length > 0 ? (
// 		<PatientNeeds data={patientExperienceData.patientNeeds} />
// 	) : subTab === "Journey" &&
// 	  Object.keys(patientExperienceData.patientJourney).length >
// 			0 ? (
// 		<PatientJourney
// 			data={patientExperienceData.patientJourney}
// 		/>
// 	) : subTab === "Sentiment" &&
// 	  patientExperienceData.patientSentiments.length > 0 ? (
// 		<PatientSentiment
// 			data={patientExperienceData.patientSentiments}
// 		/>
// 	) : (
// 		<h1>Patient Needs : {subsubtab}</h1>
// 	)
// ) : subsubtab === "Needs" ? (
// <div className="grid grid-cols-1 md:grid-cols-1 gap-6">
// 	<SkeletonFull />
// 	<SkeletonFull />
// </div>
// ) : subsubtab === "Journey" ? (
// 	<SkeletonJourney />
// ) : (
// 	<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-6">
// 		<SkeletonCard />
// 		<SkeletonCard />
// 	</div>
// )}

import { exportDataInterface } from ".";

export function transformExportData(data: exportDataInterface) {
	const csvData: string[][] = [];
	function convertHtmlToString(html: string): string {
		// Replace "<br>" with newline character
		const stringWithNewlines = html.replace(/<br>/gi, '\n');
		// Remove HTML tags
		const strippedString = stringWithNewlines.replace(/<[^>]+>/g, '');
		// Remove extra whitespace
		const trimmedString = strippedString.trim();
		return trimmedString;
	}
	csvData.push([
		"Story Id",
		"Story Name",
		"Transcript",
		"Transcript Text",
		"Transcript Added On",
		"Source",
		"Transcript Tags",
		"Transcript Status",
		"Highlighted Text",
	]);
	// console.log("Data:", data);
	const storyName = data.storyName;
	const storyId = data.storyId;
	data.transcripts.map((transcript) => {
		let transcriptText = convertHtmlToString(transcript.highlightText);
		let tags: string = "";
		transcript.Tags.map((tag) => tags + ", " + tag);
		csvData.push([
			storyId,
			storyName,
			transcript["Annotated Selection"],
			transcriptText,
			transcript["Date Added on"],
			transcript.Source,
			tags,
			transcript.Status,
			transcript.highlightedString,
		]);
	});
	// console.log("CSV Data : ", csvData);
	return csvData;
}

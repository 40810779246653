import { NavLink } from "react-router-dom";
export default function SubNavigation(props: {
  tabs?: {
    subTab: string;
    subSubTabs: string[];
  }[];
  subSubTabs?: string[];
  innerRoute?: boolean;
  url?: string;
  redirectFlag?: boolean;
  isStudyTabsRestricted?: boolean;
}) {
  return (
    <div className="flex items-center w-full shadow-lg" style={{ zIndex: 99, position: "relative" }}>
      {props.tabs?.map((tab, index) => {
        return (
          <div key={tab.subTab} className="mx-4 py-3 text-text text-base font-medium ">
            {/* {console.log("TAB", tab,"/"+tab.subTab.split(" ").join("_"),props.innerRoute)} */}
            <NavLink
              end={index === 0 && (0 || props.redirectFlag) ? true : false}
              to={
                index === 0 && (0 || props.redirectFlag)
                  ? props.url
                    ? props.url
                    : ""
                  : props.innerRoute
                  ? tab.subTab.split(" ").join("_")
                  : (props.url ? props.url : "") + "/" + tab.subTab.split(" ").join("_")
              }
              className={(navData) =>
                navData.isActive ? "tab-active hover:text-text-teal" : props.isStudyTabsRestricted ? "text-gray-400 cursor-not-allowed" : "hover:text-text-teal"
              }
              onClick={(event) => {
                if (props.isStudyTabsRestricted) {
                  event.preventDefault();
                }
              }}
            >
              {tab.subTab}
            </NavLink>
          </div>
        );
      })}
      {props.subSubTabs?.map((subTab, index) => {
        return (
          <div key={subTab} className="mx-4 py-3 text-text text-base font-medium">
            <NavLink to={subTab.split(" ").join("_")} className={(navData) => (navData.isActive ? "tab-active hover:text-text-teal" : "hover:text-text-teal")}>
              {subTab}
            </NavLink>
          </div>
        );
      })}
    </div>
  );
}

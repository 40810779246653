import React from "react";
import { Header } from "../../../Components/ReportCards/CommonCardComponents/Header";
import { PatientDisconTable } from "./PatientDisconTable";
import { PatientScoreChart } from "./PatientScoreChart";
import { NumberData } from "../../../Components/ReportCards/CommonCardComponents/NumberData";
import { ArrowLegendDiscon } from "../../../Components/ReportCards/CommonCardComponents/ArrowLegendDiscon";
import * as NodeGraphServiceHelper from "../../SearchEngine/ServiceHelpers/nodeGraph";
// import { patientScoreChartData, patientRiskBucket } from "./data";

// const DummData = [
//   {
//     patientRiskBucket: [
//       {
//         cardName: "Low",
//         value: "245",
//         vsPrevMonth: -10,
//       },
//       {
//         cardName: "Medium",
//         value: "230",
//         vsPrevMonth: -7,
//       },
//       {
//         cardName: "High",
//         value: "270",
//         vsPrevMonth: 10,
//       },
//     ],
//   },
//   {
//     patientScore: {
//       cardName: "#Patients scorecard over time",
//       yAxisLabel: [],
//       chartData: [
//         {
//           "Jul 21": {
//             Negative: 1136,
//             Neutral: 353,
//             Positive: 548,
//           },
//           "Aug 21": {
//             Negative: 714,
//             Neutral: 202,
//             Positive: 305,
//           },
//           "Sep 21": {
//             Negative: 1220,
//             Neutral: 382,
//             Positive: 579,
//           },
//           "Oct 21": {
//             Negative: 1142,
//             Neutral: 379,
//             Positive: 543,
//           },
//           "Nov 21": {
//             Negative: 1150,
//             Neutral: 329,
//             Positive: 529,
//           },
//           "Dec 21": {
//             Negative: 1044,
//             Neutral: 313,
//             Positive: 530,
//           },
//           "Jan 22": {
//             Negative: 1162,
//             Neutral: 361,
//             Positive: 574,
//           },
//           "Feb 22": {
//             Negative: 1130,
//             Neutral: 353,
//             Positive: 550,
//           },
//           "Mar 22": {
//             Negative: 1179,
//             Neutral: 341,
//             Positive: 528,
//           },
//           "Apr 22": {
//             Negative: 937,
//             Neutral: 297,
//             Positive: 441,
//           },
//           "May 22": {
//             Negative: 968,
//             Neutral: 276,
//             Positive: 401,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 1,
//             Neutral: 0,
//             Positive: 1,
//           },
//           "Aug 21": {
//             Negative: 2,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Sep 21": {
//             Negative: 1,
//             Neutral: 1,
//             Positive: 0,
//           },
//           "Nov 21": {
//             Negative: 1,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Dec 21": {
//             Negative: 3,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Jan 22": {
//             Negative: 2,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Feb 22": {
//             Negative: 3,
//             Neutral: 1,
//             Positive: 0,
//           },
//           "Mar 22": {
//             Negative: 2,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Apr 22": {
//             Negative: 2,
//             Neutral: 0,
//             Positive: 1,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 99,
//             Neutral: 16,
//             Positive: 21,
//           },
//           "Aug 21": {
//             Negative: 62,
//             Neutral: 13,
//             Positive: 10,
//           },
//           "Sep 21": {
//             Negative: 133,
//             Neutral: 11,
//             Positive: 15,
//           },
//           "Oct 21": {
//             Negative: 109,
//             Neutral: 16,
//             Positive: 12,
//           },
//           "Nov 21": {
//             Negative: 86,
//             Neutral: 10,
//             Positive: 10,
//           },
//           "Dec 21": {
//             Negative: 74,
//             Neutral: 11,
//             Positive: 18,
//           },
//           "Jan 22": {
//             Negative: 107,
//             Neutral: 11,
//             Positive: 9,
//           },
//           "Feb 22": {
//             Negative: 84,
//             Neutral: 18,
//             Positive: 6,
//           },
//           "Mar 22": {
//             Negative: 77,
//             Neutral: 9,
//             Positive: 15,
//           },
//           "Apr 22": {
//             Negative: 49,
//             Neutral: 10,
//             Positive: 8,
//           },
//           "May 22": {
//             Negative: 64,
//             Neutral: 8,
//             Positive: 5,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 0,
//             Neutral: 1,
//             Positive: 1,
//           },
//           "Aug 21": {
//             Negative: 3,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Sep 21": {
//             Negative: 4,
//             Neutral: 0,
//             Positive: 1,
//           },
//           "Oct 21": {
//             Negative: 3,
//             Neutral: 1,
//             Positive: 0,
//           },
//           "Nov 21": {
//             Negative: 6,
//             Neutral: 0,
//             Positive: 1,
//           },
//           "Jan 22": {
//             Negative: 3,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Feb 22": {
//             Negative: 3,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Mar 22": {
//             Negative: 0,
//             Neutral: 0,
//             Positive: 1,
//           },
//           "Apr 22": {
//             Negative: 2,
//             Neutral: 1,
//             Positive: 0,
//           },
//           "May 22": {
//             Negative: 1,
//             Neutral: 0,
//             Positive: 0,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 259,
//             Neutral: 48,
//             Positive: 51,
//           },
//           "Aug 21": {
//             Negative: 155,
//             Neutral: 25,
//             Positive: 31,
//           },
//           "Sep 21": {
//             Negative: 267,
//             Neutral: 47,
//             Positive: 68,
//           },
//           "Oct 21": {
//             Negative: 237,
//             Neutral: 39,
//             Positive: 73,
//           },
//           "Nov 21": {
//             Negative: 247,
//             Neutral: 33,
//             Positive: 59,
//           },
//           "Dec 21": {
//             Negative: 219,
//             Neutral: 38,
//             Positive: 52,
//           },
//           "Jan 22": {
//             Negative: 208,
//             Neutral: 33,
//             Positive: 53,
//           },
//           "Feb 22": {
//             Negative: 244,
//             Neutral: 36,
//             Positive: 57,
//           },
//           "Mar 22": {
//             Negative: 222,
//             Neutral: 33,
//             Positive: 53,
//           },
//           "Apr 22": {
//             Negative: 181,
//             Neutral: 34,
//             Positive: 46,
//           },
//           "May 22": {
//             Negative: 179,
//             Neutral: 30,
//             Positive: 22,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 8,
//             Neutral: 1,
//             Positive: 6,
//           },
//           "Aug 21": {
//             Negative: 10,
//             Neutral: 1,
//             Positive: 2,
//           },
//           "Sep 21": {
//             Negative: 4,
//             Neutral: 1,
//             Positive: 1,
//           },
//           "Oct 21": {
//             Negative: 1,
//             Neutral: 0,
//             Positive: 1,
//           },
//           "Nov 21": {
//             Negative: 2,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Dec 21": {
//             Negative: 2,
//             Neutral: 1,
//             Positive: 0,
//           },
//           "Jan 22": {
//             Negative: 4,
//             Neutral: 0,
//             Positive: 1,
//           },
//           "Feb 22": {
//             Negative: 1,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Mar 22": {
//             Negative: 0,
//             Neutral: 1,
//             Positive: 0,
//           },
//           "May 22": {
//             Negative: 2,
//             Neutral: 0,
//             Positive: 0,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 62,
//             Neutral: 16,
//             Positive: 21,
//           },
//           "Aug 21": {
//             Negative: 59,
//             Neutral: 12,
//             Positive: 9,
//           },
//           "Sep 21": {
//             Negative: 70,
//             Neutral: 24,
//             Positive: 26,
//           },
//           "Oct 21": {
//             Negative: 72,
//             Neutral: 28,
//             Positive: 23,
//           },
//           "Nov 21": {
//             Negative: 84,
//             Neutral: 22,
//             Positive: 28,
//           },
//           "Dec 21": {
//             Negative: 95,
//             Neutral: 25,
//             Positive: 42,
//           },
//           "Jan 22": {
//             Negative: 107,
//             Neutral: 32,
//             Positive: 36,
//           },
//           "Feb 22": {
//             Negative: 106,
//             Neutral: 25,
//             Positive: 54,
//           },
//           "Mar 22": {
//             Negative: 80,
//             Neutral: 36,
//             Positive: 45,
//           },
//           "Apr 22": {
//             Negative: 81,
//             Neutral: 26,
//             Positive: 58,
//           },
//           "May 22": {
//             Negative: 113,
//             Neutral: 23,
//             Positive: 60,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 2,
//             Neutral: 1,
//             Positive: 2,
//           },
//           "Aug 21": {
//             Negative: 3,
//             Neutral: 3,
//             Positive: 1,
//           },
//           "Sep 21": {
//             Negative: 8,
//             Neutral: 1,
//             Positive: 1,
//           },
//           "Oct 21": {
//             Negative: 9,
//             Neutral: 2,
//             Positive: 1,
//           },
//           "Nov 21": {
//             Negative: 3,
//             Neutral: 2,
//             Positive: 4,
//           },
//           "Dec 21": {
//             Negative: 1,
//             Neutral: 2,
//             Positive: 4,
//           },
//           "Jan 22": {
//             Negative: 4,
//             Neutral: 2,
//             Positive: 8,
//           },
//           "Feb 22": {
//             Negative: 6,
//             Neutral: 1,
//             Positive: 4,
//           },
//           "Mar 22": {
//             Negative: 5,
//             Neutral: 0,
//             Positive: 0,
//           },
//           "Apr 22": {
//             Negative: 5,
//             Neutral: 2,
//             Positive: 0,
//           },
//           "May 22": {
//             Negative: 4,
//             Neutral: 3,
//             Positive: 2,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 650,
//             Neutral: 231,
//             Positive: 384,
//           },
//           "Aug 21": {
//             Negative: 375,
//             Neutral: 140,
//             Positive: 215,
//           },
//           "Sep 21": {
//             Negative: 667,
//             Neutral: 256,
//             Positive: 409,
//           },
//           "Oct 21": {
//             Negative: 658,
//             Neutral: 267,
//             Positive: 373,
//           },
//           "Nov 21": {
//             Negative: 673,
//             Neutral: 229,
//             Positive: 382,
//           },
//           "Dec 21": {
//             Negative: 592,
//             Neutral: 207,
//             Positive: 372,
//           },
//           "Jan 22": {
//             Negative: 675,
//             Neutral: 244,
//             Positive: 40,
//           },
//           "Feb 22": {
//             Negative: 617,
//             Neutral: 243,
//             Positive: 370,
//           },
//           "Mar 22": {
//             Negative: 734,
//             Neutral: 235,
//             Positive: 373,
//           },
//           "Apr 22": {
//             Negative: 555,
//             Neutral: 195,
//             Positive: 284,
//           },
//           "May 22": {
//             Negative: 564,
//             Neutral: 192,
//             Positive: 284,
//           },
//         },
//         {
//           "Jul 21": {
//             Negative: 55,
//             Neutral: 39,
//             Positive: 61,
//           },
//           "Aug 21": {
//             Negative: 45,
//             Neutral: 8,
//             Positive: 37,
//           },
//           "Sep 21": {
//             Negative: 66,
//             Neutral: 41,
//             Positive: 58,
//           },
//           "Oct 21": {
//             Negative: 53,
//             Neutral: 26,
//             Positive: 60,
//           },
//           "Nov 21": {
//             Negative: 48,
//             Neutral: 33,
//             Positive: 45,
//           },
//           "Dec 21": {
//             Negative: 58,
//             Neutral: 29,
//             Positive: 42,
//           },
//           "Jan 22": {
//             Negative: 52,
//             Neutral: 39,
//             Positive: 61,
//           },
//           "Feb 22": {
//             Negative: 66,
//             Neutral: 29,
//             Positive: 59,
//           },
//           "Mar 22": {
//             Negative: 59,
//             Neutral: 27,
//             Positive: 41,
//           },
//           "Apr 22": {
//             Negative: 62,
//             Neutral: 29,
//             Positive: 44,
//           },
//           "May 22": {
//             Negative: 41,
//             Neutral: 20,
//             Positive: 28,
//           },
//         },
//       ],
//       chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
//     },
//   },
//   {
//     dummyDataTable: [
//       {
//         Patient_ID: "ZS1000",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "Low",
//         Top_Unmet_Needs: "Fear of side effects",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1001",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Confusion on Lab Monitoring",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1002",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "Low",
//         Top_Unmet_Needs: "Frustration around delayed shipments",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1003",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Confusion on Dosing",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1004",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "Low",
//         Top_Unmet_Needs: "Confusion on beverage consumption",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1005",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Lab Monitoring Guidance (average patient mentions)",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1006",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Common Side Effects (average negativity)",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1007",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Shipment (average patient mentions)",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1008",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Dosing Information (average negativity) ",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1009",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Insurance (average negativity)",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1010",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Diet (average curiousity)",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//       {
//         Patient_ID: "ZS1011",
//         Current_Therapy: "ZSabcd",
//         Discon_Risk: "High",
//         Top_Unmet_Needs: "Skin Rashes, Itching & Flushing (average negativity)",
//         Therapy_Start_Date: "01 Dec 2022",
//         Time_On_Therapy: "223",
//         Out_Of_Stock_Date: "01 Dec 2022",
//       },
//     ],
//   },
// ];

export default function DisconPredictions() {

  const [fetchData, setFetchData] = React.useState<any>();
  const [patientRiskBucketData, setPatientRiskBucketData] = React.useState<any>(null);
  const [patientScoreChartData, setPatientScoreChartData] = React.useState<any>(null);
  const [patientDiscTableData, setPatientDiscTableData] = React.useState<any>(null);
  
  async function fetchDataForPage() {
    const data: any = await NodeGraphServiceHelper.getNodeJsons(
      "kural/json_data/hnp_discon_preds.txt"
    );
    // const data: any = DummData;
    if (data) {
      setFetchData(data);
      setPatientRiskBucketData(data[0]["patientRiskBucket"]);
      setPatientScoreChartData(data[1]["patientScore"]);
      setPatientDiscTableData(data[2]["dummyDataTable"]);
    }
  }

  React.useEffect(() => {
    fetchDataForPage();
  }, []);

  return (
    <>
      {patientRiskBucketData && patientScoreChartData && (
        <>
          <div className=" flex grid md:grid-cols-1">
            <div className="bg-white shadow-md shadow-gray-900">
              <div className="grid lg:grid-cols-12 md:grid-cols-1 gap-0">
                <div className="p-3 col-span-4">
                  <div className="font-semibold text-base mb-7">
                    <p>#Patients by risk bucket</p>
                  </div>
                  <div className="flex justify-between pr-5">
                    {patientRiskBucketData.length > 0
                      ? patientRiskBucketData.map((risk: any) => (
                          <div>
                            <h4 className="font-semibold mb-3">
                              <Header header={risk.cardName} />
                            </h4>
                            <div className="flex align-top justify-between">
                              <div className="font-semibold text-4xl">
                                <span>
                                  {
                                    <>
                                      <div className="flex items-center">
                                        <div className="flex items-end flex-start ">
                                          <ArrowLegendDiscon dataNumber={risk.vsPrevMonth} />
                                        </div>
                                        <div className="flex items-end flex-start">
                                          <NumberData dataNumber={risk.value} />
                                        </div>
                                      </div>
                                    </>
                                  }
                                </span>{" "}
                              </div>
                            </div>
                            <div className="text-sm text-gray-500 ml-1 mt-1">
                              <span>Vs Previous Month</span>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
                <div className="px-3 col-span-8 border-left">
                  <PatientScoreChart data={patientScoreChartData} />
                </div>
              </div>
            </div>
          </div>
          <div className="grid lg:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-6 w-full">
            <PatientDisconTable data={patientDiscTableData} />
          </div>
        </>
      )}
    </>
  );
}

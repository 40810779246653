import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import { transformBarChartData } from "../../../Pages/Dashboard/Processing";

import { NumberData } from "./NumberData";

/**
 * This card is used to render the plain line chart 
 * 
 * @remarks - This is a common component and it is used in multiple files
 * 
 * @param color - This props is used to pass the color of the line as a string
 * @param chartData - This props is used to render the chart data
 *
 * @returns the plain line chart without any axis or labels
 */

export const lineChartRender = (color: string, chartData: any, paddings?: string, margins?: any, format?: string) => {
	return (
		<ReportTypes
			header={null}
			hideShadow
			hideRibbon
			// footer="Footer Text"
			minHeight="30px"
			height="90px"
			bodyPadding={paddings ? paddings : "5px 24px"}
			chartType={ReportTypesComponentInterface.chartType.line}
			series={transformBarChartData(chartData, [color]).series}
			data={transformBarChartData(chartData, [color]).data}
			axis={{
				primaryX: null,
				primaryY: null,
			}}
			chartConfig={{
				cartesian: {
					line: {
						lineWidth: 3,
						pointSize: 10,
					},
					grid: {
						enableGridX: false,
						enableGridY: false,
						// gridYValues: 3,
						// gridXValues: 3,
					},
				},
			}}
			style={{
				margin: {
					top: margins ? margins.top : 10,
					right: margins ? margins.right : 5,
					bottom: margins ? margins.bottom : 5,
					left: margins ? margins.left : 5,
				},
				labelTextColor: "white",
				axisWidth: 0,
			}}
			tooltip={{
				isInteractive: true,
				format: format ? format : 'left'
			}}
		/>
	);
};

/**
 * This card is used to render Average Time Spent in Asking Questions line chart in Unmet Patient Needs Tab
 * 
 * @remarks - This card is a part of UnmetNeedsAndQuestionTime file
 * 
 * @param data - This props is used to pass data in chart
 *
 * @returns the data and line chart in Unmet Patient Needs tab
 */

export function UnmetPatientCard(props: { data: any }) {
	const chartColors = ["#DFE0FE", "#A6B2F3", "#7E92E9", "#5369DA", "#2B44C7"];

	return (
		<div className="w-full h-full bg-body">
			<div className="w-full h-full bg-white p2 p-4">
				<div className="text-lg font-bold mb-5">
					{props.data.chartName}
				</div>
				<div className="flex">
					{props.data.chartData.map((agentChart: any, index: number) => (
						<div className="w-1/5">
							<div className="pl-2">
								<NumberData
									dataNumber={props.data.value[index]}
									suffixElement={props.data.unit[index]}
								/>
							</div>
							{Object.keys(agentChart).length !== 0 && lineChartRender(chartColors[index], agentChart, undefined, undefined, index === 4 ? 'right' : 'left')}
						</div>
					))}
				</div>
			</div>
		</div>
	);
}

import { Button, Modal, Input, Select, Tooltip, Checkbox, Dropdown, Menu, Skeleton, Form, Tag, Space, message } from "antd";
import { icons, userTypeEnum } from "../../GlobalThemes";
import React, { useState, useEffect } from "react";
import ReportTypes from "../../libraries/report-types";
import { transformTableData } from "../../Pages/Dashboard/Processing";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import Tags from "../Tags";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";
import AppContext from "../../store";
import { useNavigate } from "react-router-dom";
import { TranscriptModalData } from "./TranscriptModal";
import * as OldStoriesServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/oldStories";
import { transcriptDataInter } from "../../Pages/SearchEngine/Search";
import AddStoryModalContent from "../AddStoryModal";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";

export interface fullTranscript {
	highlight?: boolean;
	renderText?: string;
	speaker: string;
	text: string;
	dialogueNo: number;
}

interface transModalPayloadData {
	transcriptData: any;
	presignedURL: string;
	format: string;
}

/**
 * Renders the Modal to display the whole transcript.
 *
 * @param id - The props for the transcript Id
 * @param fullTranscriptData - The props for the whole transcript data

/**
 * Renders the single transcript with heading, agent and patient transcript, and action to add in story.
 *
 * @param storyId - The props for story id if it is added to any story
 * @param data - The props for transcript data with name, transcripts, stories Added array, s3path, etc.
 * @param expanded - The props to expand the card to show more information
 * @param fetchedStoryData - The props represent the list of all the stories fetched from backend
 * @param pinnedStoriesData - The props represent the pinned stories data
 *
 * @param addToStoryApi - The function will be called to add transcript to story
 * @param changePinnedStories - The function is used to pin and unpin the stories
 * @param addNewStory - The function will be called to add new story to the list
 *
 * @returns the whole transcript in a modal.
 */

export function Transcripts(props: {
	storyId?: any;
	dataForNegativeSampling?: any;
	data: {
		storyDialogues: any[];
		id: number;
		name: string;
		tags: string[];
		s3path: string;
		storiesAdded?: {
			storyId: any;
			storyName: string;
		}[];
		content?: string;
		context: string;
		context_preview: string;
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory?: boolean;
		patientId: Number;
		speaker: string;
		elasticSearchId?: string;
		dialogueNo?: number;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
	};
	expanded?: boolean;
	showTags?: boolean;
	hideProgressBar?: boolean;
	fetchedStoryData?: any;
	fetchStoryData?: any;
	addToStoryApi?: (transcriptData: any, id: any[]) => Promise<boolean>;
	changePinnedStories?: (stories: any) => void;
	addNewStory?: (addData: any) => void;
	pinnedStoriesData?: any;
	transcriptData?: any;
	storyDialogues?: any;
	searchString?: any;
	setSearchString?: any;
	keywords?: string[];
	apiResponseData?: transcriptDataInter | null;
	tagName?: string;
	storyType?: number;
}) {
	const context = React.useContext(AppContext);

	var storyObject = {
		storyName: "",
	};
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [isAddModalVisible, setIsAddModalVisible] = useState<boolean>(false);
	const [isStoryModalVisible, setIsStoryModalVisible] = useState<boolean>(false);
	const [addStoryModal, setAddStoryModal] = useState<boolean>(false);
	const [createStoryModal, setCreateStoryModal] = useState<boolean>(false);
	const [rowId, setRowId] = useState(-1);
	const [existingCount, setExistingCount] = useState<number>(0);
	const [showMore, setShowMore] = useState<boolean>(false);
	const [storyData, setStoryData] = useState<any>(storyObject);
	const [ids, setids] = useState<any>(true);
	const [loadFullTranscript, setLoadFullTranscript] = useState(false);
	const [skeletonLoader, setSkeletonLoader] = useState(false);
	const [fullTranscriptData, setFullTranscriptData] = useState<transModalPayloadData>();
	const [storyChange, setStoryChange] = useState<boolean>(false);
	const [modalContent, setModalContent] = React.useState(<></>);

	let expandedSummary = props.data.context ? JSON.parse(props.data.context) : props.data.transcripts;
	let expandedContextPreview = props.data.context_preview ? JSON.parse(props.data.context_preview) : props.data.transcripts;
	const dialogueNoArray = expandedSummary.map((detail: { dialogueNo: any }) => detail.dialogueNo);

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
		setIsAddModalVisible(false);
	};

	const handleCreateOk = () => {
		setCreateStoryModal(false);
		setAddStoryModal(true);
	};

	const handleCreateCancel = () => {
		setCreateStoryModal(false);
		setAddStoryModal(true);
	};
	const [pinStates, setPinStates] = useState<Array<any>>([]);
	const [addStates, setAddStates] = useState<Array<any>>([]);
	const [tempAddStates, setTempAddStates] = useState<Array<any>>([]);

	var grouped_columns = [
		{
			Cell: (props: any) => {
				return (
					<div
						onClick={() => {
							var pins = [...pinStates];
							pins[props.row.original.index] = !pins[props.row.original.index];
							setPinStates(pins);
						}}
					>
						{icons.find((l) => l.id === (pinStates[props.row.original.index] ? "Pin Filled" : "Pin Angle"))?.icon}
					</div>
				);
			},
			title: " ",
			accessor: "_",
			width: 50,
		},
		{
			title: "",
			accessor: "add",
			Cell: (props: any) => {
				// var add = [...addStates];
				var add = [...tempAddStates];
				// console.log("Checkbox Props Id ", props.row.original.index);
				// console.log("Checked Story Data ", add);
				// console.log(add);
				return (
					<Checkbox
						checked={add[props.row.original.index]}
						disabled={addStates[props.row.original.index]}
						// checked={addStates[props.row.original.index]}
						// disabled={addStates[props.row.original.index]}
						// className="reach-check"
						className={`${addStates[props.row.original.index] ? "disabled-check" : "reach-check"}`}
						onChange={(e) => {
							// console.log("Row", props.row);
							// console.log(e.target.checked);
							add[props.row.original.index] = !add[props.row.original.index];
							// setAddStates(add);
							setTempAddStates(add);
							setStoryChange(true);
						}}
					/>
				);
			},
		},
		{
			title: "Story Name",
			accessor: "storyName",
			width: 300,
		},
		{
			title: "Date Created",
			accessor: "dateCreated",
		},
		{
			title: "# of Annotated Selections",
			accessor: "noofAnnotationsSelections",
		},
	];

	const [cStoryList, setCStoryList] = useState<any>(props.fetchedStoryData);
	const [addToStory, setAddToStory] = useState<any>(props.fetchedStoryData);

	const handleStoryOk = () => {
		setAddStoryModal(false);
	};

	const showCreateStory = () => {
		setAddStoryModal(false);
		setCreateStoryModal(true);
	};

	const handleStoryCancel = () => {
		pinnedStories();
		var arr: any = [];
		arr.fill(false, cStoryList.length);
		setAddStoryModal(false);
	};
	const pinnedStories = () => {
		var pins = [...pinStates];
		var stories: any = [];
		pins.forEach((pin, index) => {
			if (pin) {
				stories.push(cStoryList[index]);
			}
		});
		if (props.changePinnedStories) {
			props.changePinnedStories(stories);
		}
	};

	const addStoryChecklist = (storyData: any) => {
		var table: any = storyData;
		var transcriptData: any = [];
		if (storyData && storyData.length > 0) {
			storyData.forEach((singleData: any, index: number) => {
				if (props.data.storiesAdded !== undefined && props.data.storiesAdded.length > 0) {
					if (props.data.storiesAdded.find((story, index) => story.storyId === singleData.id)) {
						transcriptData[index] = true;
						table[index]["index"] = index;
					} else {
						transcriptData[index] = false;
						table[index]["index"] = index;
					}
				} else {
					transcriptData[index] = false;
					table[index]["index"] = index;
				}
			});
		}
		setExistingCount(transcriptData.length);
		setAddStates(transcriptData);
		setTempAddStates(transcriptData);
	};

	useEffect(() => {
		// console.log("Pinned Stories UseEffect called: ", cStoryList, props.pinnedStoriesData)
		var pinData: any = [];
		if (cStoryList) {
			cStoryList.forEach((singleData: any, index: number) => {
				if (props.pinnedStoriesData && props.pinnedStoriesData.findIndex((story: any) => story.id === singleData.id) !== -1) {
					pinData[index] = true;
				} else {
					pinData[index] = false;
				}
			});
			setPinStates(pinData);
		}
	}, [props.pinnedStoriesData]);

	useEffect(() => {
		// console.log(rowId);
	}, [rowId]);

	useEffect(() => {}, [props.data]);

	useEffect(() => {
		setAddToStory(props.fetchedStoryData);
		setCStoryList(props.fetchedStoryData);
		addStoryChecklist(props.fetchedStoryData);
	}, [props.fetchedStoryData]);

	const addToStoryShow = (storyData: any) => {
		var storyNames = "";
		const TooltipData = () => {
			return (
				<>
					{storyData.map((story: any) => {
						return (
							<>
								<p>{story.storyName}</p>
							</>
						);
					})}
				</>
			);
		};
		storyData.forEach((story: any, index: number) => {
			storyNames = storyNames + `${story.storyName}\n`;
		});
		// console.log(storyNames);
		if (storyData.length === 1) {
			return storyNames;
		} else {
			return (
				<Tooltip placement="top" title={<TooltipData />}>
					{`${storyData[0].storyName} +${storyData.length - 1}`}
				</Tooltip>
			);
		}
	};

	const showPinnedStoriesName = (pinnedData: any) => {
		const menu = (
			<Menu>
				<Menu.Item
					onClick={() => {
						setAddStoryModal(true);
						setStoryChange(false);
					}}
				>
					{/* Choose a Different Story */}
				</Menu.Item>
			</Menu>
		);
		const TooltipData = () => {
			return (
				<>
					{pinnedData.map((pin: any) => {
						return (
							<>
								<p>{pin["storyName"]}</p>
							</>
						);
					})}
				</>
			);
		};

		var pins = pinnedData;
		var ids: any = [];
		// var storyNames = "";
		pins.forEach((pin: any, index: number) => {
			ids.push(pin.id);
			// storyNames = storyNames + pin["Story Name"] + "\n";
		});
		var button = (
			<Button
				type="primary"
				className="green-button flex items-center"
				onClick={() => {
					setSkeletonLoader(true);
					if (props.addToStoryApi) {
						props.addToStoryApi(props.data, ids).then((res) => {
							props.fetchStoryData();
							setSkeletonLoader(false);
						});
					}
				}}
			>
				Add to {pins.length === 1 ? pins[0]["storyName"] : `${pins[0]["storyName"]} + ${pins.length - 1}`}
			</Button>
		);
		var dropdown = (
			// <Dropdown overlay={menu}>
			<Button
				type="primary"
				className="green-button flex items-center pl-1"
				style={{ marginLeft: "1px" }}
				icon={icons.find((l) => l.id === "ArrowDown")?.icon}
				onClick={() => {
					setAddStoryModal(true);
					setStoryChange(false);
				}}
			></Button>
			// </Dropdown>
		);
		if (pins.length === 1) {
			return (
				<div className="flex">
					{button}
					{dropdown}
				</div>
			);
		} else {
			return (
				<div className="flex">
					<Tooltip placement="top" title={<TooltipData />}>
						{button}
					</Tooltip>
					{dropdown}
				</div>
			);
		}
	};

	const showSearchResults = (e: any) => {
		var exist = [...props.fetchedStoryData];
		// console.log(exist);
		exist = props.fetchedStoryData.filter((story: any, index: number) => story["storyName"].toLowerCase().includes(e.target.value));
		// console.log(exist);
		setAddToStory(exist);
	};

	const onFinish = (values: any) => {
		if (props.addNewStory) {
			props.addNewStory(values);
		}
		handleCreateCancel();
	};

	const [defaultPin, setDefaultPin] = useState(false);

	useEffect(() => {
		// console.log(rowId);
		var pins = [...pinStates];
		var stories: any = [];
		if (props.storyId && defaultPin === false && cStoryList.length > 0) {
			let index: any = cStoryList.findIndex((i: any) => i.id === props.storyId);
			let alreadyPinned: any = cStoryList[index];
			stories.push(alreadyPinned);
			setDefaultPin(true);
		}
		pins.forEach((pin, index) => {
			if (pin) {
				stories.push(cStoryList[index]);
			}
		});
		// console.log("pins", stories);
		if (props.changePinnedStories) {
			props.changePinnedStories(stories);
		}
	}, []);

	const [storyDialogues, setStoryDialogues] = React.useState([]);
	const getNotAddedTranscriptDetails = (propsData: any, stories: any) => {
		// console.log("Values on adding transcript to story", propsData, stories, props);
		let arrForSelected: any = [];
		//    console.log(props.transcriptData);

		let listData: any = props.transcriptData ? props.transcriptData : props.dataForNegativeSampling;

		stories.map((s: any) => {
			let index: any;
			if (props.data) {
				index = listData.findIndex((element: any) => {
					if (element.trId === propsData.trId) {
						return true;
					}

					return false;
				});
			}

			let selectedIndex = index;
			if (index === 0) {
				let objSelected: any = {};
				objSelected["selected_elastic_id"] = propsData.elasticSearchId;
				objSelected["selected_dialogue_no"] = propsData.dialogueNo;
				objSelected["source"] = propsData.source;

				objSelected["selected_index"] = selectedIndex;
				objSelected["story_id"] = s;
				objSelected["previous_elastic_id"] = null;
				objSelected["previous_dialogue_no"] = null;
				objSelected["previous_index"] = null;
				//@ts-ignore
				objSelected["search_query"] = propsData.source === 1 ? props.searchString : "";
				arrForSelected.push(objSelected);
			}
			// console.log("----------------------------------", props.searchString);

			while (index !== 0) {
				let objSelected: any = {};
				objSelected["selected_elastic_id"] = propsData.elasticSearchId;
				objSelected["selected_dialogue_no"] = propsData.dialogueNo;
				objSelected["source"] = propsData.source;

				objSelected["selected_index"] = selectedIndex;
				objSelected["story_id"] = s;

				let gotPreviousStory: any = false;

				while (gotPreviousStory !== true && index > 0) {
					--index;
					let flag: any = false;

					if (listData[index].storiesAdded.length !== 0) {
						listData[index].storiesAdded.map((story: any) => {
							if (story.storyId === s) {
								flag = true;
							}
						});

						if (flag === true) {
							gotPreviousStory = false;
						} else {
							gotPreviousStory = true;
						}
					} else {
						gotPreviousStory = true;
					}
				}
				objSelected["previous_elastic_id"] = listData[index].elasticSearchId;
				objSelected["previous_dialogue_no"] = listData[index].dialogueNo;
				objSelected["previous_index"] = index;
				//@ts-ignore
				// objSelected["search_query"] = propsData.source === 1 ? context.searchWord : "";
				objSelected["search_query"] = propsData.source === 1 ? props.searchString : "";

				// console.log("-----------------------------------------------", index, context.searchWord);
				// }
				arrForSelected.push(objSelected);
			}
		});

		setStoryDialogues(arrForSelected);
		// console.log("objToSend", arrForSelected);
		const newData = { ...props.data };
		newData.storyDialogues = [...arrForSelected];

		// props.data.storyDialogues = [...arrForSelected];
		// props.data.storyDialogues = [...arrForSelected];
	};

	let navigate = useNavigate();

	const navigateToPatientJourney = () => {
		navigate("/Patient_Journey/patient-details/" + props.data.patientId, {
			state: { lh_id: props.data.patientId },
		});
	};

	function showNewStoryModal() {
		setIsAddModalVisible(true);
		setModalContent(<AddStoryModalContent handleCancel={handleCancel} onSave={handleAddApi} tagName={"Story"} data={props.fetchedStoryData} storyType={props.storyType} />);
	}

	function handleAddApi(addData: any) {
		setIsAddModalVisible(false);
		if (props.addNewStory) {
			props.addNewStory(addData);
			setTimeout(() => {
				props.fetchStoryData();
			}, 1000);
		}
	}

	const handleClickGetFullTrans = async () => {
		// if (context.envId !== 2) {
		setLoadFullTranscript(true);
		setIsModalVisible(true);
		const transData: any = await SearchServiceHelper.getFullTranscript(props.data.s3path ? props.data.s3path : "");
		// console.log("Fetched : ", props.data.s3path);
		setFullTranscriptData(transData);
		setLoadFullTranscript(false);
		// }
	};

	return (
		<div>
			{skeletonLoader ? (
				<div className="px-8 pb-4 pt-8  border-b">
					<Skeleton active></Skeleton>
				</div>
			) : (
				<div className="flex px-8 pb-4 pt-8 border-b">
					<div className="w-5/6">
						<div
							className="pb-2 font-semibold text-base text-text-teal w-max"
							onClick={handleClickGetFullTrans}
							style={{ cursor: "pointer" }}
							// style={{ cursor: context.envId !== 2 ? "pointer" : "text" }}
						>
							{props.data?.name}
						</div>
						<div className="text-base pb-2">
							{showMore ? (
								<>
									{props.data.context &&
										expandedSummary.map((detail: any, index: number) => {
											if (!showMore && (index === 0 || index === 4)) return <React.Fragment key={index} />;
											return (
												<p key={index} className={`text-text-gray-${props.data.dialogueNo !== detail.dialogueNo ? "70" : "90"} px-0 py-1`}>
													<Markdown remarkPlugins={[breaks, gfm]}>{detail.speaker ? `${detail.speaker} : ${detail.text}` : detail.text}</Markdown>
												</p>
											);
										})}
								</>
							) : (
								<>
									{props.data.context_preview &&
										expandedContextPreview.map((detail: any) => {
											return (
												<p className="px-0 py-1">
													<Markdown remarkPlugins={[breaks, gfm]}>{detail.speaker ? `${detail.speaker} : ${detail.text}` : detail.text}</Markdown>
												</p>
											);
										})}
								</>
								// <p className="px-0 py-1"><Markdown remarkPlugins={[breaks, gfm]}>{String(props.data.content)}</Markdown></p>
							)}

							<div
								className="text-text-teal font-normal cursor-pointer w-max mb-2"
								onClick={() => {
									setShowMore(!showMore);
								}}
							>
								{showMore ? <>Show less</> : <>Show more</>}
							</div>
							<div className="flex item-center">
								{!props.data.addedToStory && props.data.storiesAdded?.length === 0 && props.tagName !== "Collection" ? (
									<div className="flex">
										{props.pinnedStoriesData?.length === 0 ? (
											<Button
												type="primary"
												icon={icons.find((l) => l.id === "Plus")?.icon}
												color="green"
												className="green-button flex items-center"
												onClick={() => {
													setAddStoryModal(true);
													setStoryChange(false);
												}}
											>
												Add Story
											</Button>
										) : (
											showPinnedStoriesName(props.pinnedStoriesData)
										)}
									</div>
								) : (
									<></>
								)}

								{props.data.storiesAdded !== undefined && props.data.storiesAdded.length > 0 ? (
									<div className="flex items-center">
										<div className="mr-2">{icons.find((l) => l.id === "Check 2")?.icon}</div>
										<p className="text-sm">Added to {addToStoryShow(props.data.storiesAdded)}</p>
										<Button
											type="primary"
											icon={icons.find((l) => l.id === "Edit Underline")?.icon}
											className="green-button flex items-center ml-8"
											onClick={() => {
												setAddStoryModal(true);
												setStoryChange(false);
											}}
										>
											Edit
										</Button>
									</div>
								) : (
									<></>
								)}

								{context.envId === 3 ? ( //change to 3
									<div className="flex items-center ml-5 text-xs text-text-teal cursor-pointer" onClick={navigateToPatientJourney}>
										<span>{icons.find((l) => l.id === "PatientDetails")?.icon} Patient Details</span>
									</div>
								) : (
									<></>
								)}
							</div>
						</div>
					</div>
					<div className="ml-4 w-1/6">
						<div className="py-2">
							<Space direction="vertical">
								<Tags data={props.data?.tags || []} />
							</Space>
						</div>
					</div>
				</div>
			)}

			{fullTranscriptData?.format === "csv" ? (
				<>
					{/* {console.log("Search Engine Transcript Data: ", fullTranscriptData)} */}
					{message.error("Preview not available.")}
				</>
			) : (
				<Modal
					title={props.data?.name}
					width={1500}
					bodyStyle={{
						height: "600px",
						overflowY: "auto",
					}}
					centered
					// height={1200}
					open={isModalVisible}
					onOk={handleOk}
					onCancel={handleCancel}
					className="modal-footer-left text-left"
					footer={[
						<Button type="primary" className="green-button items-start mr-6" onClick={handleCancel}>
							Close
						</Button>,
					]}
				>
					{loadFullTranscript ? (
						<div>
							<Skeleton active></Skeleton>
							<Skeleton active></Skeleton>
							<Skeleton active></Skeleton>
						</div>
					) : (
						fullTranscriptData && (
							<TranscriptModalData id={props.data?.id || 0} transPayloadData={fullTranscriptData} keywords={props.keywords} highlightedDialogueNo={dialogueNoArray} />
						)
					)}
				</Modal>
			)}

			<Modal
				title={null}
				width={1000}
				centered
				open={addStoryModal}
				onOk={handleStoryOk}
				onCancel={() => {
					handleStoryCancel();
					setTempAddStates(addStates);
				}}
				className="modal-footer-left text-left"
				footer={[
					<div key="buttons-container" style={{ display: "flex", justifyContent: "flex-end" }}>
						<Button
							type="default"
							onClick={() => {
								handleStoryCancel();
								setTempAddStates(addStates);
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							className="green-button items-start"
							disabled={!storyChange}
							onClick={async () => {
								var stories: any = [];
								if (storyChange === true) {
									setSkeletonLoader(true);
									// addStates.forEach((pin, index) => {
									tempAddStates.forEach((pin, index) => {
										if (pin) {
											stories.push(cStoryList[index].id);
										}
									});
									getNotAddedTranscriptDetails(props.data, stories);
									if (props.addToStoryApi) {
										const d = props.addToStoryApi(props.data, stories).then((response) => {
											if (response) {
												props.fetchStoryData();
												setSkeletonLoader(false);
											}
										});
									}
									// setids(false);
								}
								setAddStates(tempAddStates);
								handleStoryCancel();
							}}
						>
							Add
						</Button>
					</div>,
				]}
			>
				<div>
					<div className="mx-4">
						<div className="text-2xl text-text-gray-100 font-normal my-4">Select Story</div>
						<p className="text-base mb-6" style={{ color: "#9C9AA1" }}>
							You can add the transcript to a story by selecting an existing story from the list below or by creating a new story and adding it to the new story.
						</p>
						<div className="flex mt-6">
							<div className="flex items-center">
								<p className="text-base font-semibold mr-4">Existing Story: {addToStory ? addToStory.length : 0}</p>
								<div>
									<Input.Search
										className="flex w-96 input-search"
										placeholder={`Search Story Name`}
										onChange={(e) => {
											showSearchResults(e);
										}}
									/>
								</div>
							</div>
							<div className="flex items-center ml-auto cursor-pointer">
								<Modal
									title={null}
									open={isAddModalVisible}
									bodyStyle={{
										overflowY: "auto",
									}}
									centered
									onCancel={handleCancel}
									footer={null}
									destroyOnClose={true}
								>
									{modalContent}
								</Modal>
								<div
									className="flex items-center"
									onClick={() => {
										showNewStoryModal();
									}}
								>
									<div className="mr-2">{icons.find((l) => l.id === "Plus Filled Green")?.icon}</div>
									<p className="font-semibold text-base" style={{ color: "#27A6A4" }}>
										Create New Story
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="mt-4">
						<ReportTypes
							// handleEdit={showModal}
							// handleSave={handleSave}
							// hideActions={[false, false]}
							hideShadow
							flexContent="flex-start"
							hideRibbon
							minHeight="300px"
							chartType={ReportTypesComponentInterface.chartType.simpleTable}
							series={[]}
							chartConfig={{
								table: {
									noPagination: true,
									maxHeight: 350,
									tableData: {
										data: transformTableData(addToStory ? (addToStory.length > 0 ? addToStory : []) : [], []).data,
										column: grouped_columns,
									},
									noSearchField: true,
								},
							}}
						/>
					</div>
				</div>
			</Modal>
		</div>
	);
}

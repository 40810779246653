import { request } from "./../App";
import { searchQuery, transcriptDataInter } from "./../Pages/SearchEngine/Search";
export const getAllTranscripts = async (
  searchword?: string,
  startdate?: string,
  enddate?: string,
  speaker?: string,
  showResults?: boolean,
  storyId?: string,
  callType?: string,
  therapyStatus?: string,
  emotion?: string
) => {
  let str = "";
  if (searchword) str += "searchword=" + searchword;
  if (startdate) str === "" ? (str += "startdate=" + startdate) : (str += "&startdate=" + startdate);
  if (enddate) str === "" ? (str += "enddate=" + enddate) : (str += "&enddate=" + enddate);
  if (speaker && speaker !== "") str === "" ? (str += "speaker=" + speaker) : (str += "&speaker=" + speaker);
  if (showResults) str === "" ? (str += "showResults=" + showResults) : (str += "&showResults=" + showResults);
  if (storyId) str === "" ? (str += "storyId=" + storyId) : (str += "&storyId=" + storyId);

  if (callType) str === "" ? (str += "callType=" + callType) : (str += "&callType=" + callType);

  if (therapyStatus) str === "" ? (str += "therapyStatus=" + therapyStatus) : (str += "&therapyStatus=" + therapyStatus);

  if (emotion) str === "" ? (str += "emotion=" + emotion) : (str += "&emotion=" + emotion);

  const fetchUrl = `search/searchresults?${str}`;
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const getAllTranscripts2 = async (searchQueries: searchQuery) => {
  // let str = "";
  //  console.log("Queries", searchQueries);
  const obj: any = {};
  Object.entries(searchQueries).map(([key, value]) => {
    //    console.log("Key, Val", key, value);
    if ((Array.isArray(value) && value.length > 0) || typeof value === "boolean") obj[key] = value;
    else if (!Array.isArray(value) && value) obj[key] = value;

    if (key === "studyIds") {
      obj[key] = `${value}`;
    }
  });
  const fetchUrl = `search/searchresults`;
  const data = await request
    .post(fetchUrl, obj, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const getFullTranscript = async (s3path: string) => {
  const fetchUrl = "search/getfulltranscript";
  const data = await request
    .post(fetchUrl, s3path, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getS3ImageByteArrayData = async (s3path: string) => {
  const fetchUrl = "search/GetFullTranscriptforImage";
  const data = await request
    .post(fetchUrl, s3path, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const addToStory = async (story: { searchQuery: string; summaryJson: transcriptDataInter; storyIds: number[] }) => {
  const fetchUrl = "search/AddToStory";
  const data = await request
    .post(fetchUrl, story, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const fetchCookie = async () => {
  const fetchUrl = "Search/GetCookie";
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getFilters = async () => {
  const fetchUrl = "Search/GetSearchAdvancedFilters";
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
  // return [
  // 	{
  // 		optionsObject: [
  // 			{
  // 				id: "1",
  // 				name: "Inbound",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "2",
  // 				name: "Outbound",
  // 				subgroup: [],
  // 			},
  // 		],
  // 		filter: "Call_Type",
  // 		type: "select",
  // 	},
  // 	{
  // 		optionsObject: [
  // 			{
  // 				id: "1",
  // 				name: "ON THERAPY",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "2",
  // 				name: "DISCONTINUED",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "3",
  // 				name: "NEVER START",
  // 				subgroup: [],
  // 			},
  // 		],
  // 		filter: "Therapy_Status",
  // 		type: "select",
  // 	},
  // 	{
  // 		optionsObject: [
  // 			{
  // 				id: "1",
  // 				name: "positive",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "2",
  // 				name: "negative",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "3",
  // 				name: "neutral",
  // 				subgroup: [],
  // 			},
  // 		],
  // 		filter: "Sentiment",
  // 		type: "select",
  // 	},
  // 	{
  // 		optionsObject: [
  // 			{
  // 				id: "topic_0",
  // 				name: "closing",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_1",
  // 				name: "common side-effects on vumerity",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_2",
  // 				name: "confirm next call",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_3",
  // 				name: "introduction",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_4",
  // 				name: "lab monitoring guidance",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_5",
  // 				name: "nurse educator role",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_6",
  // 				name: "others",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_7",
  // 				name: "patient questionnaire for the vumerity nurse educator program",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_8",
  // 				name: "safety recording",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_9",
  // 				name: "shipment/shipping",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_10",
  // 				name: "specialty pharmacy",
  // 				subgroup: [],
  // 			},
  // 			{
  // 				id: "topic_11",
  // 				name: "vumerity dosing information",
  // 				subgroup: [],
  // 			},
  // 		],
  // 		filter: "Topics",
  // 		type: "select",
  // 	},
  // 	{
  // 		optionsObject: [
  // 			{
  // 				id: "j1",
  // 				name: "joy",
  // 				subgroup: [
  // 					{
  // 						id: "1",
  // 						name: "admiration",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "2",
  // 						name: "amusement",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "3",
  // 						name: "approval",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "4",
  // 						name: "caring",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "5",
  // 						name: "desire",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "6",
  // 						name: "excitement",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "7",
  // 						name: "gratitude",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "8",
  // 						name: "joy",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "9",
  // 						name: "love",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "10",
  // 						name: "optimism",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "11",
  // 						name: "pride",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "12",
  // 						name: "relief",
  // 						subgroup: [],
  // 					},
  // 				],
  // 			},
  // 			{
  // 				id: "a2",
  // 				name: "anger",
  // 				subgroup: [
  // 					{
  // 						id: "13",
  // 						name: "anger",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "14",
  // 						name: "annoyance",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "15",
  // 						name: "disapproval",
  // 						subgroup: [],
  // 					},
  // 				],
  // 			},
  // 			{
  // 				id: "s3",
  // 				name: "sadness",
  // 				subgroup: [
  // 					{
  // 						id: "16",
  // 						name: "disappointment",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "17",
  // 						name: "embarrassment",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "18",
  // 						name: "grief",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "19",
  // 						name: "remorse",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "20",
  // 						name: "sadness",
  // 						subgroup: [],
  // 					},
  // 				],
  // 			},
  // 			{
  // 				id: "d4",
  // 				name: "disgust",
  // 				subgroup: [
  // 					{
  // 						id: "21",
  // 						name: "disgust",
  // 						subgroup: [],
  // 					},
  // 				],
  // 			},
  // 			{
  // 				id: "f5",
  // 				name: "fear",
  // 				subgroup: [
  // 					{
  // 						id: "22",
  // 						name: "fear",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "23",
  // 						name: "nervousness",
  // 						subgroup: [],
  // 					},
  // 				],
  // 			},
  // 			{
  // 				id: "s6",
  // 				name: "surprise",
  // 				subgroup: [
  // 					{
  // 						id: "24",
  // 						name: "confusion",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "25",
  // 						name: "curiosity",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "26",
  // 						name: "surprise",
  // 						subgroup: [],
  // 					},
  // 					{
  // 						id: "27",
  // 						name: "realization",
  // 						subgroup: [],
  // 					},
  // 				],
  // 			},
  // 			{
  // 				id: "n7",
  // 				name: "neutral",
  // 				subgroup: [
  // 					{
  // 						id: "28",
  // 						name: "neutral",
  // 						subgroup: [],
  // 					},
  // 				],
  // 			},
  // 		],
  // 		filter: "Emotions",
  // 		type: "tree",
  // 	},
  // ];
};

export const getNodeTranscripts = async (id: string) => {
  const fetchUrl = `search/searchresults`;
  const data = await request
    .post(
      fetchUrl,
      { searchWord: "hi" },
      {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getSearchEmployee = async (studyId: number | any) => {
  const fetchUrl = `search/GetMetaDataFilters?studyId=${studyId}`;
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return null;
    });
  return data;
};

export const saveSearchFeedbackData = async (summary: { searchQuery: string; isLike: boolean; searchJson: transcriptDataInter }) => {
  const fetchUrl = "search/SaveSearchQueryFeedback";
  const data = await request
    .post(fetchUrl, summary, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return null;
    });
  return data;
};

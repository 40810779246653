import { ArrowComparison } from "../../../Components/ReportCards/CommonCardComponents/ArrowComparison";
import { Header } from "../../../Components/ReportCards/CommonCardComponents/Header";
import { NumberData } from "../../../Components/ReportCards/CommonCardComponents/NumberData";
import { PieChartLegends } from "../../../Components/ReportCards/CommonCardComponents/PieChartLegends";
import { lineChartRender } from "../../../Components/ReportCards/CommonCardComponents/UnmetPatientCard";
import ReportTypes from "../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import { transformTopChartData } from "../Processing";

/**
 * This card is used to render the program overview card in KPI page
 *
 * @remarks - This card is a part of KPI tab
 *
 * @param data - This props contain the card data with numbers and pie chart and line chart data
 *
 * @returns the card with heading, number data, pie chart and line charts
 */

export function ProgramOverviewCard(props: { data: any }) {
	// var colors = ["#99D3FD", "#DFE0FE"];
	var colors = ["#3287C4", "#3287C4"];
	var margins1 = {
		top: 10,
		right: 5,
		bottom: 5,
		left: 5,
	};
	var margins2 = {
		top: 10,
		right: 5,
		bottom: 5,
		left: 5,
	};
	return (
		<div className="p-4">
			<Header iconName="Briefcase" header="Program Overview" />
			<div>
				<div className="flex justify-between my-6">
					<div>
						<div className="flex items-end mb-5  mr-6 justify-between">
							<NumberData
								numberHeading={props.data.kpi[0]}
								dataNumber={props.data.value[0]}
								dataName={props.data.month[0]}
								suffixElement={props.data.unit[0]}
							/>
							<ArrowComparison
								dataNumber={props.data.vsPrevMonth[0]}
								dataName="vs Prev. Month"
							/>
						</div>
						<div className="mr-6">
							{lineChartRender(
								colors[0],
								props.data.chartData[0],
								"0px 0px",
								margins1
							)}
						</div>
					</div>
					<div className="flex">
						<div style={{ width: "170px" }}>
							<div className="font-bold">
								Inbound vs Outbound Calls
							</div>
							<ReportTypes
								// header={"Average Patient Volume"}

								hideShadow
								hideRibbon
								minHeight="80px"
								height="22vh"
								bodyPadding="0px 20px"
								title={{ title: "xx" }}
								chartType={
									ReportTypesComponentInterface.chartType.pie
								}
								series={
									transformTopChartData(
										props.data.topChart,
										props.data.chartColor
									).series
								}
								data={
									transformTopChartData(
										props.data.topChart,
										props.data.chartColor
									).data
								}
								chartConfig={{}}
								tooltip={{
									isInteractive: true,
									valueFormat: (value: any) => {
										return `${value}%`;
									},
								}}
								label={{
									format: (d: any) => {
										console.log(d);
										return `${d.value}%`;
									},
									skipSmallValue: true,
									skipWidth: 20,
								}}
								style={{
									labelTextColor: "black",

									margin: {
										top: 10,
										right: 10,
										bottom: 30,
										left: 20,
									},
								}}
							/>
						</div>
						<PieChartLegends
							legendData={
								transformTopChartData(
									props.data.topChart,
									props.data.chartColor
								).series
							}
						/>
					</div>
					<div className="ml-6 flex items-start">
						<div>
							<div className="flex items-end mb-5 justify-between">
								<NumberData
									numberHeading={props.data.kpi[1]}
									dataNumber={props.data.value[1]}
									dataName={props.data.month[1]}
									suffixElement={props.data.unit[1]}
								/>
								<div>
									<ArrowComparison
										dataNumber={props.data.vsPrevMonth[1]}
										dataName="vs Prev. Month"
									/>
								</div>
							</div>
							<div>
								{lineChartRender(
									colors[1],
									props.data.chartData[1],
									"0px 0px",
									margins2,
									"right"
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

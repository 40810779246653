import SubNavigation from "../../Components/SubNavigation";
import { DiscoverModuleTabs } from "../SearchEngine/data";
import { NodeGraph } from "./NodeGraph";
import React from "react";

/**
 * This page is the main search engine page, which also contains the routes for search and story tab
 *
 * @returns the navigation to open search and stories tab
 */

export default function NodeGraphView() {
  const tabs = DiscoverModuleTabs();

  return (
    <>
      <SubNavigation tabs={tabs} />
      <NodeGraph />
    </>
  );
}

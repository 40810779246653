import { Empty, List, Skeleton } from "antd";
import TranscriptsWrapper from "../../../Components/TransciptsWrapper";
import { Transcripts } from "../../../Components/SearchEngineCards/Transcripts";
import { useEffect, useRef, useState } from "react";
import { transcriptDataInter } from "../../SearchEngine/Search";
import SubHeaderComponent from "../../../Components/SubHeaderComponent";
import StoriesSummaryCard from "./StoriesSummaryCard";
import { useParams, useLocation } from "react-router-dom";
import * as NodeGraphServiceHelper from ".././../SearchEngine/ServiceHelpers/nodeGraph";

interface LocationState {
  result: any; // Replace 'any' with the actual type of 'result'
  name: string; // Change 'string' to the actual type of 'name' if needed
}

export function SelectedSavedSummary() {
  const location = useLocation();
  const { result, name } = location.state as LocationState;
  const { storyId } = useParams();
  const [transcriptLoading, setTranscriptLoading] = useState<boolean>(false);
  const [transcriptData, setTranscriptData] = useState<transcriptDataInter[]>([]);
  const dataCopy = useRef<transcriptDataInter[]>([]);
  const [emptyTextField, setEmptyTextField] = useState("View transcripts");
  const [expanded, setExpanded] = useState(false);
  const [showResultSummary, setShowResultSummary] = useState<boolean>(false);

  const fetchTranscripts = async () => {
    setTranscriptLoading(true);
    // Messages.loading("Fetching...", "key1");
    const transData: any = await NodeGraphServiceHelper.getNodeJsons(result.uploadS3Path);
    const data: any = transData.summaryJson;
    setTranscriptLoading(false);
    // const key = "Key 1";

    if (data) {
      setTranscriptData([data]);
      //      console.log(transcriptData);
      dataCopy.current = [data];
      //   Messages.success("Success", "key1");
    } else {
      //   Messages.error("Fetching failed", "key1");
      setTranscriptData([]);
    }
  };

  useEffect(() => {
    fetchTranscripts();
  }, []);

  return (
    <>
      <div className="pt-2 px-2">
        <SubHeaderComponent
          breadcrumb={[
            {
              item: "Collection",
              link: "/collection",
            },
            {
              item: name,
              link: `/collection/collection-mode/${storyId}`,
            },
            {
              item: result.searchQuery,
              link: "",
            },
          ]}
        />
      </div>
      <div className="bg-white px-2" style={{ height: "calc(100vh - 11rem)", overflowY: "auto" }}>
        {transcriptLoading ? (
          <div>
            <Skeleton active className="py-4 px-8 border-b" />
          </div>
        ) : (
          <div className="bg-white shadow-md shadow-gray-900">
            <StoriesSummaryCard data={result.searchSummary} searchString={result.searchQuery} isloaded={!transcriptLoading} />
          </div>
        )}
        <TranscriptsWrapper
          header={`Saved References`}
          expanded={expanded}
          toggleExpand={setExpanded}
          showSummary={showResultSummary}
          toggleSummary={setShowResultSummary}
          loading={transcriptLoading}
          noSideSummary={false}
        >
          {transcriptLoading ? (
            <div>
              <Skeleton active className="py-4 px-8 border-b" />
              <Skeleton active className="py-4 px-8 border-b" />
              <Skeleton active className="py-4 px-8 border-b" />
            </div>
          ) : (
            <div>
              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  hideOnSinglePage: true,
                  style: {
                    marginLeft: "2rem",
                    marginBottom: "1.2rem",
                    float: "left",
                    paddingBottom: "1rem",
                  },
                  onChange: (page) => {
                    //console.log(page);
                  },
                  pageSize: 10,
                }}
                dataSource={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
                locale={{
                  emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
                }}
                renderItem={(result, index) => (
                  <>
                    {/* {console.log("Trans",result)} */}
                    <Transcripts data={result} expanded={expanded} tagName={"Collection"} />
                  </>
                )}
              />
            </div>
          )}
        </TranscriptsWrapper>
      </div>
    </>
  );
}

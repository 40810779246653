import Card from "../card";
import * as CardComponentProps from "../card";
import * as ReportTypesComponentProps from "./charts/interfaces/main";
import * as RadialChart from "./charts/radialChart";
import * as CartesianChart from "./charts/cartesianChart";
import * as Table from "./charts/table";
import * as GeoMap from "./charts/geoMap";
import NoChartData from "../../Components/NoChartData";

/**
 * Renders the chosen Chart Component inside a Card
 *
 * @param props - The props for the Card and Chart Components
 *
 * @returns Chosen Chart Component inside a Card
 */
export default function ReportTypes(
  props: CardComponentProps.Interface & ReportTypesComponentProps.Interface
) {
  return (
    <>
      {(props.data && props.data.length > 0 && props.chartType !== 7) ||
      props.chartType === 5 ||
      (props.chartType === 7 && props.data[0].length > 0) ? (
        <Card
          header={props.header}
          borderedHeading={props.borderedHeading}
          subHeader={props.subHeader}
          footer={props.footer}
          minHeight={props.minHeight}
          height={props.height || "auto"}
          bodyPadding={props.bodyPadding}
          bodyChartContent={props.bodyChartContent}
          // hideActions
          // hideRibbon
          {...props}
        >
          {props.chartType === 0 && <CartesianChart.BarChart {...props} />}
          {props.chartType === 1 && <CartesianChart.LineChart {...props} />}
          {props.chartType === 2 && <RadialChart.PieChart {...props} />}
          {props.chartType === 3 && <Table.HeatMap {...props} />}
          {props.chartType === 5 && (
            <Table.SimpleTable
              tableData={props.chartConfig.table?.tableData}
              rows={props.chartConfig.table?.rows}
              noSearch={props.chartConfig.table?.noSearchField}
              noPagination={props.chartConfig.table?.noPagination}
              maxHeight={props.chartConfig.table?.maxHeight}
            />
          )}
          {props.chartType === 6 && <GeoMap.Choropleth {...props} />}
          {props.chartType === 7 && <CartesianChart.ScatterPlot {...props} />}
        </Card>
      ) : (
        <Card
          header={props.header}
          borderedHeading={props.borderedHeading}
          subHeader={props.subHeader}
          footer={props.footer}
          bodyPadding={props.bodyPadding}
          bodyChartContent={props.bodyChartContent}
          height="44.8vh"
          // hideActions
          // hideRibbon
          hideShadow
        >
          <div
            style={{
              height: "100%",
            }}
          >
            <NoChartData />
          </div>
        </Card>
      )}
    </>
  );
}
export {
  ReportTypesComponentProps,
  Card,
  CardComponentProps,
  CartesianChart,
  RadialChart,
  Table,
  GeoMap,
};

import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FilterActiveInterface, FilterInterface } from ".";
import SkeletonCard from "../../Components/SkeletonCard";
import CallExperience from "./CustomerSatisfaction/CallExperience";
import SurveyMetrics from "./CustomerSatisfaction/SurveyMetrics";
import { fetchedFilters, pageWiseFilters } from "./data";
import { getCustomerSatisfaction } from "./ServiceHelpers/CustomerSatisfaction";

const DUMMY_DATA = {
	surveyMetrics: [
		{
			cardName: "NPS",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { "Series 1": 80 },
			chartName: ["NPS Trend"],
			xAxisLabel: [],
			yAxisLabel: ["Label"],
			footer: "",
			chartColor: ["#3287C4"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
			],
		},
		{
			cardName: "Call Quality",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { "Call Quality": 50 },
			chartName: ["Call Quality Trend"],
			xAxisLabel: [],
			yAxisLabel: ["Label"],
			footer: "",
			chartColor: ["#3287C4"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
			],
		},
		{
			cardName: "Query Resolution",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { "Query Resolution": 22 },
			chartName: ["Query Resolution Trend"],
			xAxisLabel: [],
			yAxisLabel: ["Label"],
			footer: "",
			chartColor: ["#3287C4"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
			],
		},
	],
	callExperience: [
		{
			cardName: "Confidence",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { "Series 1": 65 },
			chartName: ["Confidence Trend"],
			xAxisLabel: [],
			yAxisLabel: ["% of Calls"],
			footer: "",
			chartColor: ["#3287C4"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
			],
		},
		{
			cardName: "Politeness",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { Politeness: 50 },
			chartName: ["Politeness Trend"],
			xAxisLabel: [],
			yAxisLabel: ["% of Calls"],
			footer: "",
			chartColor: ["#3287C4"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
			],
		},
		{
			cardName: "Sympathy",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { Sympathy: 22 },
			chartName: ["Sympathy Trend"],
			xAxisLabel: [],
			yAxisLabel: ["% of Calls"],
			footer: "",
			chartColor: ["#3287C4"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
			],
		},
	],
};

/**
 * This page is used to navigate between Survey Metrics and Call Experience
 *
 *  @param filterActiveStatus - Props used to pass the active filters as true
 *  @param setFilterActiveStatus - Function to set the status of active filters as true
 *  @param filters - Props used to pass the filters with values
 *
 * @returns the navigation to navigate between different subtabs
 */

export default function CustomerSatisfaction(props: {
	filterActiveStatus: FilterActiveInterface;
	setFilterActiveStatus: React.Dispatch<
		React.SetStateAction<FilterActiveInterface>
	>;
	filters: FilterInterface;
}) {
	const { subsubtab } = useParams();
	let navigate = useNavigate();
	const [csData, setCsData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);

	function findId(filter: string, value: any) {
		let grpIdx: number = 0;
		let idx: number = 0;
		fetchedFilters.map((f) => {
			f.optionsObject.map((group) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx = group.id;
						grpIdx = group.id;
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (
							Array.isArray(value) &&
							value.find((f) => f === group.name)
						) {
							grpIdx = group.id;
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find(
									(sg) => sg.name === v
								);
								if (ob) {
									grpIdx = group.id;
									idx = ob.id;
								}

								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	}

	const fetchCustomerSatisfaction = async () => {
		setLoading(true);
		var poFilters: FilterInterface = Object.assign({}, props.filters);
		poFilters.Call_Type = findId(
			"Call_Type",
			props.filters.Call_Type
		).grpIdx;
		poFilters.Therapy_Status = findId(
			"Therapy_Status",
			props.filters.Therapy_Status
		).grpIdx;
		poFilters.Call_Intenty = findId(
			"Call_Intent",
			props.filters.Call_Intent
		).grpIdx;
		poFilters.Adherence = findId(
			"Adherence",
			props.filters.Adherence
		).grpIdx;
		const data = await getCustomerSatisfaction(
			poFilters,
			props.filterActiveStatus
		);
		console.log(data);
		if (data) {
			setCsData(data);
			setLoading(false);
		}
	};
	const addSubTabFilters = (tab_name: string) => {
		const obj: FilterActiveInterface = props.filterActiveStatus;
		for (let key in obj) {
			obj[key] = false;
		}
		var filters = pageWiseFilters.find(
			(page) => page.tab === "Patient Experience"
		);
		if (filters?.filter.length === 0) {
			filters.subtab &&
				filters.subtab
					.find((page) => page.name === tab_name)
					?.filters.map((filter) => (obj[filter] = true));
		} else {
			filters && filters.filter.map((filter) => (obj[filter] = true));
		}
		console.log("Activeeee", pageWiseFilters);
		props.setFilterActiveStatus(obj);
	};
	React.useEffect(() => {
		if (subsubtab === undefined) navigate("Survey_Metrics");
	});
	React.useEffect(() => {
		console.log("Customer Satisfaction Tabs ", subsubtab, Object.assign({},props.filters));
		addSubTabFilters(subsubtab || "");
		if(subsubtab !== undefined) 
			fetchCustomerSatisfaction();
	}, [props.filters, subsubtab]);
	// React.useEffect(() => {
	// 	const obj: FilterActiveInterface = props.filterActiveStatus;
	// 	for (let key in obj) {
	// 		obj[key] = false;
	// 	}
	// 	pageWiseFilters
	// 		.find((page) => page.tab === "Customer Satisfaction")
	// 		?.filter.map((filter) => (obj[filter] = true));
	// 	props.setFilterActiveStatus(obj);
	// 	fetchCustomerSatisfaction();
	// }, []);
	return (
		<>
			{!loading ? (
				subsubtab === "Survey_Metrics" ? (
					<SurveyMetrics data={csData.surveyMetrics} />
				) : subsubtab === "Call_Experience" ? (
					<CallExperience data={csData.callExperience} />
				) : (
					<h1>Patient Experience : {subsubtab}</h1>
				)
			) : (
				<>
					<div className="grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-6">
						<SkeletonCard />
						<SkeletonCard />
						<SkeletonCard />
					</div>
				</>
			)}
		</>
	);
}

import TableWrapper from "../../../../Components/TableWrapper";
import ReportTypes from "../../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../../libraries/report-types/charts/interfaces/main";
import { annotatedSelectionEmptyData, annotated_selection_empty_grouped_columns } from "../../data";

export default function AnnotatedSelectionLoading() {
  return (
    <>
      <TableWrapper loading={true}>
        <div className="mt-4" style={{ height: "28em" }}>
          <ReportTypes
            hideShadow
            hideRibbon
            chartType={ReportTypesComponentInterface.chartType.simpleTable}
            series={[]}
            chartConfig={{
              table: {
                tableData: {
                  column: annotated_selection_empty_grouped_columns,
                  data: annotatedSelectionEmptyData,
                },

                noSearchField: true,
                noPagination: true,
                maxHeight: 394,
              },
            }}
          />
        </div>
      </TableWrapper>
    </>
  );
}

import { transcriptDataInter } from "../Search";
import * as OldStoriesService from "./../../../services/oldStories";

export const getAllStories: (storyType?: number) => Promise<unknown> = async (storyType?: number) => {
	const data = await OldStoriesService.getAllStories(storyType);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};
export const addNewStory: (addData: { StoryName: string; StoryType?: number }) => Promise<unknown> = async (addData) => {
	const data = await OldStoriesService.addNewStory(addData);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};
export const deleteStory: (storyId: number) => Promise<unknown> = async (storyId) => {
  const data = await OldStoriesService.deleteStory(storyId);
  if (data === null || data === undefined) return null;
  return data;
};
export const edtStory: (editData: {
	id: string;
	StoryName: string;
	// "StoryType": string;
}) => Promise<unknown> = async (editData) => {
  const data = await OldStoriesService.editStory(editData);
  if (data === null || data === undefined) return null;
  return data;
};

// export const publishStory: (storyId: string) => Promise<unknown> = async (
// 	storyId
// ) => {
// 	const data = await OldStoriesService.publishStory(storyId);
// 	if (data === null || data === undefined) return null;
// 	return data;
// };

export const getStorybyId: (storyId: number) => Promise<unknown> = async (storyId) => {
  const data = await OldStoriesService.getStorybyId(storyId);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

// export const deleteSavedSearch: (id: number) => Promise<unknown> = async (
// 	id
// ) => {
// 	const data = await StoriesService.deleteSavedSearch(id);
// 	if (data === null || data === undefined) return null;
// 	return data;
// };
export const addTransToStory: (transcriptData: any) => Promise<unknown> = async (transcriptData) => {
  const data = await OldStoriesService.addTransToStory(transcriptData);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

import { CardInterface } from "../../Pages/Dashboard/ProgramOverview/ProgramOverviewCalls";
import { Header } from "./CommonCardComponents/Header";

/**
 * This card is used to render the card which contain header, data and chart at bottom. It is a common card which is used in dashboard and many other pages
 *
 * @param CardInterface - This has header, icon name, chart data, header extra element to print
 *
 * @returns the card to render data with chart representation
 */

export function POCard(props: CardInterface) {
	return (
		<div className="bg-white shadow-md shadow-gray-900 p-4  w-full">
			{props.cardData === false ? (
				<div>
					<Header
						iconName={props.iconName}
						header={props.header}
						headerExtra={props.ribbonData}
					/>
				</div>
			) : (
				<div>
					<Header
						iconName={props.iconName}
						header={props.header}
						// headerExtra={props.headerExtra}
					/>
					{props.headerExtra ? (
						<div className="flex align-top justify-between">
							<div>{props.cardTopData}</div>
							<div>{props.headerExtra}</div>
						</div>
					) : (
						<div>{props.cardTopData}</div>
					)}
				</div>
			)}
			<div className="w-full">
				{/* <BarChart chartType={0} /> */}
				{props.cardExtra ? props.cardExtraData : null}
				{props.chartElement.length > 1 ? (
					<div className="grid grid-cols-2">{props.chartElement}</div>
				) : (
					<div>{props.chartElement}</div>
				)}
			</div>
			{props.cardBottomData ? props.cardBottomData : null}
		</div>
	);
}

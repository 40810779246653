import React from "react";
import BarChartWithBg from "../../../Components/BarChartWithBg";
import { POCard } from "../../../Components/ReportCards/POCard";
import ReportTypes from "../../../libraries/report-types";
import { transformBarChartData } from "../Processing";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";

/**
 * Renders the Call Experience SubTab with the charts and other data.
 *
 * @param data - Data to be Displayed on the Page.
 *
 * @returns  Call Experience SubTab
 */
export default function CallExperience(props: { data: any }) {
	const [fetchedData, setFetchedData] = React.useState(props.data);
	var data = [
		{
			header: fetchedData[0].cardName,
			cardTopData: (
				<div className="w-full p-4">
					<BarChartWithBg
						{...transformBarChartData(
							[
								{
									[fetchedData[0].cardName]:
										fetchedData[0].topChart,
								},
							],
							["black"],
							"bar"
						)}
						backgroundDivisions={2}
						noHeader
						range={[0, 33.33, 66.66, 100]}
						unit=""
					/>
				</div>
			),
			iconName: "Thumbs Up",
			chartElement: [
				<ReportTypes
					hideShadow
					header=""
					hideRibbon
					minHeight="30px"
					height="42.5vh"
					bodyPadding="0px 20px"
					title={{ title: fetchedData[0].chartName[0] }}
					chartType={ReportTypesComponentInterface.chartType.line}
					series={
						transformBarChartData(fetchedData[0].chartData, [
							"#3287C4",
						]).series
					}
					data={
						transformBarChartData(fetchedData[0].chartData, [
							"#3287C4",
						]).data
					}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: fetchedData[0].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
							minValue: 0,
							maxValue: 100,
						},
					}}
					chartConfig={{
						cartesian: {
							line: {
								lineWidth: 3,
								pointSize: 10,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.69, groupGap: 0 },
						},
					}}
					tooltip={{
						isInteractive: true,
						format: 'round_left'
					}}
					style={{
						margin: {
							top: 10,
							right: 10,
							bottom: 65,
							left: 50,
						},
						axisWidth: 0,
					}}
				/>,
			],
		},
		{
			header: fetchedData[1].cardName,
			cardTopData: (
				<div className="w-full p-4">
					<BarChartWithBg
						{...transformBarChartData(
							[
								{
									[fetchedData[1].cardName]:
										fetchedData[1].topChart,
								},
							],
							["black"],
							"bar"
						)}
						backgroundDivisions={2}
						noHeader
						range={[0, 33.33, 66.66, 100]}
						unit=""
					/>
				</div>
			),
			iconName: "EmotionHappyBlack",
			chartElement: [
				<ReportTypes
					hideShadow
					header=""
					hideRibbon
					minHeight="30px"
					height="42.5vh"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.line}
					series={
						transformBarChartData(fetchedData[1].chartData, [
							"#3287C4",
						]).series
					}
					data={
						transformBarChartData(fetchedData[1].chartData, [
							"#3287C4",
						]).data
					}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: fetchedData[1].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
							minValue: 0,
							maxValue: 100,
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "round",
					}}
					chartConfig={{
						cartesian: {
							line: {
								lineWidth: 3,
								pointSize: 10,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.69, groupGap: 0 },
						},
					}}
					style={{
						margin: {
							top: 10,
							right: 10,
							bottom: 65,
							left: 50,
						},
						axisWidth: 0,
					}}
				/>,
			],
		},
		{
			header: fetchedData[2].cardName,
			cardTopData: (
				<div className="w-full p-4">
					<BarChartWithBg
						{...transformBarChartData(
							[
								{
									[fetchedData[2].cardName]:
										fetchedData[2].topChart,
								},
							],
							["black"],
							"bar"
						)}
						backgroundDivisions={2}
						noHeader
						range={[0, 33.33, 66.66, 100]}
						unit=""
					/>
				</div>
			),
			iconName: "Sympathy Shake",
			chartElement: [
				<ReportTypes
					hideShadow
					header=""
					hideRibbon
					minHeight="30px"
					height="42.5vh"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.line}
					series={
						transformBarChartData(fetchedData[2].chartData, [
							"#3287C4",
						]).series
					}
					data={
						transformBarChartData(fetchedData[2].chartData, [
							"#3287C4",
						]).data
					}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: fetchedData[2].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
							minValue: 0,
							maxValue: 100,
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "round",
					}}
					chartConfig={{
						cartesian: {
							line: {
								lineWidth: 3,
								pointSize: 10,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.69, groupGap: 0 },
						},
					}}
					style={{
						margin: {
							top: 10,
							right: 10,
							bottom: 65,
							left: 50,
						},
						axisWidth: 0,
					}}
				/>,
			],
		},
	];
	return (
		<>
			<div className="w-full grid lg:grid-cols-3 sm:grid-cols-1 gap-6">
				{data.map((card: any) => (
					<POCard {...card} />
				))}
			</div>
		</>
	);
}

import React, { useEffect } from "react";
import { userTypeEnum } from "../GlobalThemes";

interface ContextUserProfileType {
	profileName: string;
	displayName: string;
}

interface AppContextType {
	lastRefreshedOn: string;
	startDate: string;
	endDate: string;
	setDates: (
		lastRefreshedOn: string,
		startDate: string,
		endDate: string
	) => void;
	userType: string;
	userName: string;
	setUser: (userName: string, userType: string) => void;
	searchWord: any;
	setSearch: (searchWord: any) => void;
	temp: any; // Update with the appropriate type
	setTempHere: (temp: any) => void;
	envId: number;
	setEnv: (envId: number) => void;
	userProfile: ContextUserProfileType | null;
	setUserProfile: (userProfile: ContextUserProfileType | null) => void;
	isStudyTabsRestricted: boolean;
	setIsStudyTabsRestricted: (isStudyTabsRestricted: boolean) => void;
	expIdForNodeGraph: any;
	setExpIdForNodeGraphHere: (expIdForNodeGraph: any) => void;
}

const AppContext = React.createContext<AppContextType>({
	lastRefreshedOn: "",
	startDate: "",
	endDate: "",
	setDates: (lastRefreshedOn, startDate, endDate) => {},
	userType: userTypeEnum[0],
	userName: "",
	setUser: (userName, userType) => {},
	searchWord: "",
	setSearch: (searchWord) => {},
	temp: null,
	setTempHere: (temp) => {},
	envId: 0,
	setEnv: (envId) => {},
	userProfile: null,
	setUserProfile: (userProfile) => {},
	isStudyTabsRestricted: false,
	setIsStudyTabsRestricted: (isStudyTabsRestricted) => {},
	expIdForNodeGraph: null,
	setExpIdForNodeGraphHere: (expIdForNodeGraph) => {},
});

export function ContextProvider(props: { children: React.ReactNode }) {
	const [lastRefreshedOn, setLastRefreshedOn] = React.useState(
		localStorage.getItem("lastRefreshedOn") || ""
	);
	const [startDate, setStartDate] = React.useState(
		localStorage.getItem("startDate") || ""
	);
	const [endDate, setEndDate] = React.useState(
		localStorage.getItem("endDate") || ""
	);
	const [searchWord, setSearchWord] = React.useState("");
	const [temp, setTemp] = React.useState(null);
	const [envId, setEnvId] = React.useState(0);
	const [userProfileData, setUserProfileData] =
		React.useState<ContextUserProfileType | null>(null);
	const [isStudyTabsRestricted, setIsStudyTabsRestrictedValue] =
		React.useState(false);
	const [expIdForNodeGraph, setExpIdForNodeGraph] = React.useState(null);
	const [userType, setUserType] = React.useState(userTypeEnum[0]);
	const [userName, setUserName] = React.useState("");

	useEffect(() => {
		caches.open("userData").then((cache) => {
			// console.log(cache);
			cache.match("/userData").then((res) => {
				// console.log(res);
				res?.json().then((res1) => {
					setUserType(res1.userType);
					setUserName(res1.userName);
				});
			});
		});
	}, []);

	function setTempHere(temp: any) {
		setTemp(temp);
	}

	function setExpIdForNodeGraphHere(expId: any) {
		setExpIdForNodeGraph(expId);
	}

	function setEnv(envId: number) {
		setEnvId(envId);
	}

	function setIsStudyTabsRestricted(isStudyTabsRestricted: boolean) {
		setIsStudyTabsRestrictedValue(isStudyTabsRestricted);
	}

	function setDates(
		lastRefreshedOn: string,
		startDate: string,
		endDate: string
	) {
		setLastRefreshedOn(lastRefreshedOn);
		setStartDate(startDate);
		setEndDate(endDate);
	}

	function setSearch(searchWord: any) {
		setSearchWord(searchWord);
	}

	function setUser(userName: string, userType: string) {
		setUserName(userName);
		setUserType(userType);
	}

	function setUserProfile(userProfile: ContextUserProfileType | null) {
		setUserProfileData(userProfile);
	}

	const context = {
		lastRefreshedOn: lastRefreshedOn,
		startDate: startDate,
		endDate: endDate,
		setDates: setDates,
		userType: userType,
		userName: userName,
		setUser: setUser,
		searchWord: searchWord,
		setSearch: setSearch,
		temp: temp,
		setTempHere: setTempHere,
		envId: envId,
		setEnv: setEnv,
		userProfile: userProfileData,
		setUserProfile: setUserProfile,
		isStudyTabsRestricted: isStudyTabsRestricted,
		setIsStudyTabsRestricted: setIsStudyTabsRestricted,
		expIdForNodeGraph: expIdForNodeGraph,
		setExpIdForNodeGraphHere: setExpIdForNodeGraphHere,
	};
	return (
		<AppContext.Provider value={context}>
			{props.children}
		</AppContext.Provider>
	);
}

export default AppContext;

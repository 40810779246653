import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { icons, userTypeEnum } from "../../GlobalThemes";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import AppContext from "../../store";
import SubMenu from "antd/lib/menu/SubMenu";
import { fetchCookie } from "../../services/search";
import NotificationDropdown from "../../Components/NotificationCenter";
import { useDispatch } from "react-redux";
import { resetState } from "../../state/slice/appSlice";

/**
 * Renders the Top Navigation Bar, shows the App Name, Data refresh time, User options.
 *
 * @param applicationName - The props for the Application Name
 * @param lastRefreshedOn - The props for the Last Refreshed Date
 * @param dataTimeframeForCalls - The props for the Data Timeframe
 *
 * @returns Top Navigation View
 */

interface ContextUserProfileType {
	profileName: string;
	displayName: string;
}

export default function TopNavbar(props: { applicationName: string; lastRefreshedOn: string; dataTimeframeForCalls: string; userProfileRoles: string[] }) {
	const context = React.useContext(AppContext);
	const getCookie = async () => {
		const data = await fetchCookie();
		console.log(data);
	};
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [selectedProfile, setSelectedProfile] = useState<ContextUserProfileType | null>(context.userProfile ? context.userProfile : null);

	const menu = (
		<Menu>
			<Menu.Item>
				<Link to="/search" className="hover:text-text-teal">
					<div className="text-text-teal text-base font-medium flex items-center gap-2">
						{/* {
							icons.find((l) => l.id === "Search Engine - Small")
								?.icon
						} */}
						Search Engine
					</div>
				</Link>
			</Menu.Item>
			<Menu.Item>
				<Link to="/discover" className="hover:text-text-teal">
					<div className="text-text-teal text-base font-medium flex items-center gap-2">
						{/* {
							icons.find(
								(l) =>
									l.id ===
									"dashboard"
							)?.icon
						} */}
						Discover
					</div>
				</Link>
			</Menu.Item>
		</Menu>
	);

	const menu2 = (
		<Menu>
			<Menu.Item>
				<a href={process.env.REACT_APP_ADD_USER_URL} className="text-text-teal font-medium text-xs flex items-center gap-2">
					{icons.find((l) => l.id === "NavbarUserSmall")?.icon}
					Add Users
				</a>
			</Menu.Item>
		</Menu>
	);

	const menu_sm = () => (
		<Menu>
			<SubMenu title="User">
				<Menu.Item>
					<a href={process.env.REACT_APP_SIGNOUT_URL}>
						<div className="text-text-teal font-medium flex items-center gap-2">
							{icons.find((l) => l.id === "NavbarUserSmall")?.icon}
							Sign Out
						</div>
					</a>
				</Menu.Item>
				<Menu.Item>
					<a href={process.env.REACT_APP_ADD_USER_URL}>
						<div className="text-text-teal font-medium flex items-center gap-2">
							{icons.find((l) => l.id === "NavbarUserSmall")?.icon}
							Add Users
						</div>
					</a>
				</Menu.Item>
			</SubMenu>
			<SubMenu title="Navigate">{menu}</SubMenu>
			<Menu.Item>Help</Menu.Item>
		</Menu>
	);

	const userProfileMenu = (
		<Menu>
			{props.userProfileRoles.map((item: any, index: number) => (
				<Menu.Item onClick={() => handleMenuClick(item)} key={index}>
					{item.displayName}
				</Menu.Item>
			))}
		</Menu>
	);

	const handleMenuClick = (profileData: ContextUserProfileType) => {
		setSelectedProfile(profileData);
		context.setUserProfile(profileData);
		dispatch(resetState());

		navigate("/");
		// console.log(`Selected Profile: ${profileData.profileName}`);
	};

	return (
		<div className="bg-navbar px-4 py-2 flex justify-between font-topNavigation w-screen">
			<div className="flex items-center sm:w-5/12 lg:w-6/12 xl:w-3/4">
				<img
					className="zsLogo"
					src="/logos/ZSLogoSmall.png"
					alt="ZS Logo"
					style={{
						height: "3rem",
						objectFit: "cover",
					}}
				/>
				<h1 className="text-navbar-text ml-8 text-xl font-bold leading-snug tracking-normal ">
					<Link to="/" className="hover:text-navbar-text">
						{props.applicationName}
					</Link>
				</h1>
			</div>
			<div className="flex items-center justify-between sm:w-5/12 md:w-6/12 lg:w-6/12 xl:w-1/4" style={{ justifyContent: "flex-end" }}>
				{/* <div className="flex text-navbar-text text-sm">
					<div className="mr-3">
						<p className="font-light">Last Refreshed On</p>
						<p className="font-semibold">{props.lastRefreshedOn}</p>
					</div>
					<div className="border-r border-navbar-border"></div>
					<div className="ml-3">
						<p className="font-light">Timeframe for Calls</p>
						<p className="font-semibold">
							{props.dataTimeframeForCalls}
						</p>
					</div>
				</div> */}
				<div className="flex items-center  lg:hidden cursor-pointer">
					<Dropdown overlay={menu_sm}>{icons.find((l) => l.id === "Menu")?.icon}</Dropdown>
				</div>
				<div className="justify-between items-center w-3/4 text-text-white hidden lg:flex">
					<Dropdown overlay={userProfileMenu} className="cursor-pointer">
						<div className="flex items-center" style={{ border: "1px solid grey", padding: "5px", borderRadius: "4px" }}>
							{selectedProfile ? (
								<span style={{ minWidth: "100px" }}>{`${selectedProfile.displayName}`} &nbsp;</span>
							) : (
								<span style={{ width: "100px" }}>Select Profile &nbsp;</span>
							)}
							<DownOutlined />
						</div>
					</Dropdown>

					<div className="flex items-center justify-center">
						{icons.find((l) => l.id === "NavbarUser")?.icon}
						<div className="text-xs">
							<p style={{ letterSpacing: "0.12em" }}>{context.userName}</p>
							<div>
								<a href={process.env.REACT_APP_SIGNOUT_URL} className="text-text-orange tracking-tight">
									Sign Out
								</a>

								{context.userType !== userTypeEnum[1] && (
									<Dropdown overlay={menu2} className="cursor-pointer" placement="bottomRight">
										{icons.find((l) => l.id === "ArrowDown")?.icon}
									</Dropdown>
								)}
							</div>
						</div>
					</div>

					<Dropdown overlay={menu} className="cursor-pointer">
						{icons.find((l) => l.id === "NavbarGrid")?.icon}
					</Dropdown>

					<div
						className="cursor-pointer"
						onClick={() => {
							getCookie();
						}}
					>
						{icons.find((l) => l.id === "NavbarQuestion")?.icon}
					</div>

					<div
						className="cursor-pointer"
						// onClick={() => {
						// 	getCookie();
						// }}
					>
						<NotificationDropdown />
					</div>
				</div>
			</div>
		</div>
	);
}

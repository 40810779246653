import { Collapse } from "antd";
import React from "react";
import { icons } from "../../../../GlobalThemes";
import "./style.css";

const { Panel } = Collapse;
export default function TableModal(props: {
	storyName: string;
	storyDetails: {
		transcriptDate: string;
		timeDuration: string;
		details: { person: string; speech: string }[];
	}[];

	// storyDetails: any;
}) {
	function PanelData(props: {
		storyDetails: {
			person: string;
			speech: string;
		}[];
	}): JSX.Element {
		return (
			<>
				<div className="bg-white px-4 py-2">
					<div className="text-lg text-text-gray- font-semibold">
						Details
					</div>
					<div className="text-base">
						{props.storyDetails.map((detail) => {
							if (detail.person === "Agent")
								return (
									<p className="text-text-gray-40 px-2 py-3">
										{`Agent : ${detail.speech}`}
									</p>
								);
							else
								return (
									<p className="text-text-gray-90 px-2 py-3">
										{`Patient : ${detail.speech}`}
									</p>
								);
						})}
					</div>
				</div>
			</>
		);
	}
	return (
		<>
			{props.storyDetails.length > 0 ? (
				<Collapse
					bordered={false}
					accordion
					expandIcon={({ isActive }) =>
						isActive
							? icons.find((l) => l.id === "Accordion Collapse")
									?.icon
							: icons.find((l) => l.id === "Accordion Expand")
									?.icon
					}
					className="modal-collapse site-collapse-custom-collapse"
				>
					{props.storyDetails.map((story, index) => {
						return (
							<Panel
								header={`Transcript from ${story.transcriptDate} [${story.timeDuration}]`}
								key={index + 1}
								className="site-collapse-custom-panel"
							>
								<PanelData storyDetails={story.details} />
							</Panel>
						);
					})}
				</Collapse>
			) : (
				<p className="m-2">No transcripts available.</p>
			)}
		</>
	);
}

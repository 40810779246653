import { FilterInterface } from "..";
import * as DashboardService from "../../../services/Dashboard";

export const getEngagementData: (
	subTab: string,
	filters: FilterInterface,
	filterState: any
) => Promise<unknown> = async (subTab: string, filters: FilterInterface, filterState: any) => {
	const data = await DashboardService.getEngagementData(subTab, filters, filterState);
	console.log("api", data);
	if (data === null || data === undefined) return null;
	var poData: any = data;
	var participationColors = [["#3B9FE7", "#FECA98"], ["#3B9FE7"]];
	var engagementByTopicsColors = [["#3B9FE7"], ["#3B9FE7"], ["#3B9FE7"]];
	data.callParticipation.forEach((po: any, index: number) => {
		if (po.multiChartData.length > 0) {
			poData.callParticipation[index].chartData = po.multiChartData;
		} else if (Object.keys(po.poChartData).length > 0) {
			poData.callParticipation[index].chartData = [po.poChartData];
		} else if (po.complexChartData.length > 0) {
			poData.callParticipation[index].chartData = po.complexChartData;
		}
		else {
			poData.callParticipation[index].chartData = [];
		}
		poData.callParticipation[index].chartColor = participationColors[index];
	});
	data.engagementByTopics.forEach((po: any, index: number) => {
		if (po.multiChartData.length > 0) {
			poData.engagementByTopics[index].chartData = po.multiChartData;
		} else if (Object.keys(po.poChartData).length > 0) {
			poData.engagementByTopics[index].chartData = [po.poChartData];
		} else if (po.singleChartData.length > 0) {
			poData.engagementByTopics[index].chartData = po.singleChartData;
		} else if (po.complexChartData.length > 0) {
			poData.engagementByTopics[index].chartData = po.complexChartData;
		} else if (po.engagemnetByTopicChartData.length > 0) {
			poData.engagementByTopics[index].chartData =
				po.engagemnetByTopicChartData;
		} else {
			poData.engagementByTopics[index].chartData = [];
		}
		poData.engagementByTopics[index].chartColor =
			engagementByTopicsColors[index];
	});

	return poData;
};

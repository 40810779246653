import { POCard } from "../../../Components/ReportCards/POCard";
import React, { useState, useEffect } from "react";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import { NumberData } from "../../../Components/ReportCards/CommonCardComponents/NumberData";
import { ArrowComparison } from "../../../Components/ReportCards/CommonCardComponents/ArrowComparison";
import { transformBarChartData } from "../Processing";
import { Radio } from "antd";
import { getProgramOverview } from "../ServiceHelpers/ProgramOverview";
import { FilterActiveInterface } from "..";
import { filterTabs } from "../ProgramOverview";

export interface CardInterface {
	cardData?: boolean;
	cardTopData?: React.ReactNode;
	cardBottomData?: React.ReactNode;
	header?: string | React.ReactNode;
	headerExtra?: React.ReactNode | string;
	bottomExtra?: React.ReactNode;
	iconName?: string;
	cardExtra?: boolean;
	cardExtraData?: React.ReactNode;
	chartElement: React.ReactNode[];
	multiCharts?: {
		multiEnabled?: boolean;
		multiChartType?: string;
		multiChartElements?: Array<string>;
	};
	ribbonData?: React.ReactNode;
}
// export function findMaxValue(
// 	data: (string[] | number[])[]
// ): number | undefined {
// 	let max = Number.MIN_SAFE_INTEGER;
// 	const bars = data[0] ? data[0].length : 0;
// 	const series = data.length - 1;
// 	let temp_sum = 0;
// 	for (let i = 0; i < bars; i++) {
// 		temp_sum = 0;
// 		for (let j = 1; j <= series; j++) {
// 			temp_sum += Number(data[j][i]);
// 		}
// 		if (temp_sum > max) max = temp_sum;
// 	}
// 	// console.log("MAX", max, data);
// 	return max;
// }

/**
 * This function is used to find the maximum value of the grid in a graph
 *
 * @param data - The props contains the list of grid values
 *
 * @returns the maximum value of the grid to be used in a chart
 */

export function findMaxGroupValue(
	data: (string[] | number[])[]
): number | undefined {
	console.log(data);
	let max = Number.MIN_SAFE_INTEGER;
	if (data.length === 0) {
		return max;
	}
	const bars = data[0].length;
	const series = data.length - 1;
	let temp_sum = 0;
	for (let i = 0; i < bars; i++) {
		temp_sum = 0;
		for (let j = 1; j <= series; j++) {
			temp_sum = Number(data[j][i]);
			if (temp_sum > max) max = temp_sum;
		}
	}
	const buffer = 0;
	if (max < 10) max = Math.ceil((max + buffer) / 5) * 5;
	else if (max < 100) max = Math.ceil((max + buffer) / 10) * 10;
	else if (max < 1000) max = Math.ceil((max + buffer) / 100) * 100;
	else if (max < 2000) max = Math.ceil((max + buffer) / 200) * 200;
	else if (max < 10000) max = Math.ceil((max + buffer) / 500) * 500;
	else max = Math.ceil((max + buffer) / 10000) * 10000;
	// console.log("MAX", max, data);
	return max;
}

/**
 * This is the Calls subtab in Program Overview tab
 *
 * @param data - The props contains the data to be rendered in Calls tab
 *
 * @returns three cards with Average Patient Volume, Average Duration of Calls and Average Calls Attempted
 */

export function ProgramOverviewCalls(props: { data: any }) {
	const [elements, setElements] = useState<Array<boolean>>([true, false]);
	const [indexChart, setIndexChart] = useState<number>(0);
	const [chartsData, setChartsData] = useState<Array<any>>([]);

	useEffect(() => {
		var data: any = [];
		props.data.forEach((call: any, index: number) => {
			var chart = transformBarChartData(call.chartData, call.chartColor);
			data.push(chart);
		});
		setChartsData(data);
	}, []);

	const volumeRadioRender = () => {
		return (
			<div className="flex justify-around">
				<Radio.Group defaultValue={"Volume"}>
					{["Volume", "Percentage"].map((element, index) => (
						<Radio
							value={element}
							className="radio-custom"
							defaultChecked={elements[index] === true}
							onChange={(e) => {
								var elem = elements;
								elements.forEach((el, i) => {
									if (i === index) {
										elem[index] = e.target.checked;
									} else {
										elem[i] = false;
									}
								});
								setIndexChart(index);
								setElements(elem);
							}}
						>
							{element}
						</Radio>
					))}
				</Radio.Group>
			</div>
		);
	};

	var charts = [
		[
			<ReportTypes
				header=""
				hideShadow
				hideRibbon
				minHeight="30px"
				height="44vh"
				bodyPadding="0px 20px"
				title={{ title: "xx" }}
				chartType={ReportTypesComponentInterface.chartType.bar}
				series={
					transformBarChartData(
						props.data && props.data[2].chartData,
						props.data && props.data[2].chartColor
					).series
				}
				data={
					transformBarChartData(
						props.data[2].chartData,
						props.data[2].chartColor
					).data
				}
				axis={{
					primaryX: {
						dim: 0,
						tickValues: 5,
						tickRotation: -45,
						legend: "",
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: 22,
					},
					primaryY: {
						tickSize: 0,
						tickValues: 5,
						tickRotation: 0,
						legend: props.data[2].yAxisLabel[0],
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: -40,
						// minValue: 0,
						// maxValue: findMaxValue(
						// 	transformBarChartData(
						// 		props.data[2].chartData,
						// 		props.data[2].chartColor
						// 	).data
						// ),
					},
				}}
				legend={{
					position: ReportTypesComponentInterface.legendPosition.top,
					direction:
						ReportTypesComponentInterface.legendDirection.row,
					symbolSize: 15,
					translateX: -2,
					translateY: -37,
					symbolShape:
						ReportTypesComponentInterface.legendSymbolShape.circle,
				}}
				chartConfig={{
					cartesian: {
						line: {
							pointSize: 20,
						},
						// groupMode: groupMode.grouped,
						grid: {
							enableGridX: false,
							enableGridY: true,
							gridYValues: 5,
							gridXValues: 5,
						},
						gaps: { barGap: 0.69, groupGap: 0 },
					},
				}}
				tooltip={{
					isInteractive: true,
					format: "stack",
				}}
				style={{
					margin: {
						top: 40,
						right: 10,
						bottom: 65,
						left: 48,
					},
					axisWidth: 1,
				}}
			/>,
		],
		[
			<ReportTypes
				header=""
				hideShadow
				hideRibbon={true}
				minHeight="30px"
				height="44vh"
				bodyPadding="0px 20px 0px 20px"
				title={{ title: "xx" }}
				chartType={ReportTypesComponentInterface.chartType.bar}
				series={
					transformBarChartData(
						props.data[2].chartData.length > 0
							? props.data[2].chartData[1]
							: [],
						props.data[2].chartData.length > 0
							? props.data[2].chartColor
							: []
					).series
				}
				data={
					transformBarChartData(
						props.data[2].chartData.length > 0
							? props.data[2].chartData[1]
							: [],
						props.data[2].chartData.length > 0
							? props.data[2].chartColor
							: []
					).data
				}
				axis={{
					primaryX: {
						dim: 0,
						tickValues: 5,
						tickRotation: -45,
						legend: "",
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: 22,
					},
					primaryY: {
						tickSize: 0,
						tickValues: 5,
						tickRotation: 0,
						legend: props.data[2].yAxisLabel[1],
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: -40,
						minValue: 0,
						maxValue: 100,
					},
				}}
				legend={{
					position: ReportTypesComponentInterface.legendPosition.top,
					direction:
						ReportTypesComponentInterface.legendDirection.row,
					symbolSize: 15,
					translateX: -2,
					translateY: -37,
					symbolShape:
						ReportTypesComponentInterface.legendSymbolShape.circle,
				}}
				tooltip={{
					isInteractive: true,
					format: "stack_percent",
				}}
				chartConfig={{
					cartesian: {
						line: {
							pointSize: 20,
						},
						// groupMode: groupMode.grouped,
						grid: {
							enableGridX: false,
							enableGridY: true,
							gridYValues: 5,
							gridXValues: 5,
						},
						gaps: { barGap: 0.69, groupGap: 0 },
					},
				}}
				style={{
					margin: {
						top: 40,
						right: 10,
						bottom: 65,
						left: 48,
					},
					axisWidth: 1,
				}}
			/>,
		],
	];
	var data = [
		{
			header: props.data[0].cardName,
			cardTopData: (
				<div className="flex items-end mx-7 my-4">
					<NumberData
						dataNumber={props.data[0].value}
						dataName={props.data[0].month}
						suffixElement={props.data[0].unit}
					/>
					<ArrowComparison
						dataNumber={props.data[0].vsPrevMonth}
						dataName={
							props.data[0].timeframeDisplay === "Quarter"
								? "vs Prev. Quarter"
								: "vs Prev. Month"
						}
					/>
				</div>
			),
			iconName: "Report User",
			chartElement: [
				<ReportTypes
					hideShadow
					hideRibbon
					minHeight="30px"
					height="44vh"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.bar}
					series={chartsData[0] ? chartsData[0].series : []}
					data={chartsData[0] ? chartsData[0].data : []}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: props.data[0].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
						},
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 20,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.69, groupGap: 0 },
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "normal",
					}}
					style={{
						margin: {
							top: 40,
							right: 10,
							bottom: 65,
							left: 48,
						},
					}}
				/>,
			],
		},
		{
			header: props.data[1].cardName,
			cardTopData: (
				<div className="flex items-end mx-7 my-4">
					<NumberData
						dataNumber={props.data[1].value[0]}
						dataName={props.data[1].month}
						suffixElement={props.data[1].unit[0]}
					/>
					<ArrowComparison
						dataNumber={props.data[1].vsPrevMonth}
						dataName={
							props.data[1].timeframeDisplay === "Quarter"
								? "vs Prev. Quarter"
								: "vs Prev. Month"
						}
					/>
				</div>
			),
			iconName: "Report Clock",
			chartElement: [
				<ReportTypes
					hideShadow
					hideRibbon
					minHeight="30px"
					height="44vh"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.bar}
					series={chartsData[1] ? chartsData[1].series : []}
					data={chartsData[1] ? chartsData[1].data : []}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: props.data[1].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
						},
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 20,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.69, groupGap: 0 },
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "normal_mins",
					}}
					style={{
						margin: {
							top: 40,
							right: 10,
							bottom: 65,
							left: 48,
						},
					}}
				/>,
			],
		},
		{
			header: props.data[2].cardName,
			cardBottomData: <div>{volumeRadioRender()}</div>,
			cardTopData: (
				<div className="flex items-end mx-7 my-4">
					<NumberData
						dataNumber={props.data[2].value}
						dataName={props.data[2].month}
						suffixElement={props.data[2].unit}
					/>
					<ArrowComparison
						dataNumber={props.data[2].vsPrevMonth}
						dataName={
							props.data[2].timeframeDisplay === "Quarter"
								? "vs Prev. Quarter"
								: "vs Prev. Month"
						}
					/>
				</div>
			),
			iconName: "Report Call",
			multiCharts: {
				multiEnabled: true,
				multiChartType: "radio",
				multiChartElements: ["Volume", "Percentage"],
			},
			chartElement: charts[indexChart],
		},
	];
	return (
		<div className="grid xl:grid-cols-3 lg:grid-cols-1 gap-4">
			{/* {console.log(props.data)} */}
			{data.length > 0 ? data.map((card) => <POCard {...card} />) : null}
		</div>
	);
}

import { icons } from "../../../GlobalThemes/index";

/**
 * This is the header which renders header with the icon for cards
 *
 * @remarks - This card is used inside the POCard to render the header of the card
 *
 * @param header - The props used to show the name of the card
 * @param  headerExtra - The props used to show any extra elements in header of the card
 * @param iconName - The props used to print the icon in front of the heading
 *
 * @returns the card name with the icon
 */

export function Header(props: {
	header?: string | React.ReactNode;
	headerExtra?: string | React.ReactNode;
	iconName?: string;
}) {
	return (
		<div className="flex justify-between">
			<div className="flex">
				<div className="mr-2">
					{icons.find((l) => l.id === props.iconName)?.icon}
				</div>
				<div className="flex">
					<div className="font-semibold text-base">
						<p>{props.header}</p>
					</div>
				</div>
			</div>
			<div>{props.headerExtra}</div>
		</div>
	);
}

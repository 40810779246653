import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IAppState } from "../../model/IAppState";
import { RootState } from "../store";
import { transcriptDataInter } from "../../Pages/SearchEngine/Search";
import { ISearchFilterValues } from "../../Components/SearchEngineCards/SearchFilters";
import { IDiscoverFilterValues } from "../../Components/DiscoverCards/DiscoverFilters";

const initialState: IAppState = {
	searchTab: {
		searchSummaryFilterInSync: true,
		searchFilters: {
			startDate: "",
			endDate: "",
			studyIds: [],
			searchWord: "",
			metaDataIds: [],
			pptSearch: false,
			userProfile: "",
			deepSearch: false,
			testButton1: false,
			testButton2: true,
			testButton3: false,
			testButton4: false,
			testButton5: false,
		},
		searchStreamedTitle: "",
		searchStreamedData: "Loading data...",
		searchLikeStatus: false,
		searchDislikeStatus: false,
		searchRefData: [],
	},
	discoverTab: {
		discoverFilters: {
			study: undefined,
			studyId: undefined,
			wave: undefined,
			waveId: undefined,
			region: undefined,
			regionId: undefined,
			studyIterId: undefined,
		},
		discoverTreeData: null,
	},
};

export const appReduxSlice = createSlice({
	name: "appSlice",
	initialState,
	reducers: {
		resetState: () => initialState,
		setSearchSummaryFilterInSync: (state, action: PayloadAction<boolean>) => {
			state.searchTab.searchSummaryFilterInSync = action.payload;
		},
		setSearchFilters: (state, action: PayloadAction<ISearchFilterValues>) => {
			state.searchTab.searchFilters = action.payload;
		},
		setSearchStreamedTitle: (state, action: PayloadAction<string>) => {
			state.searchTab.searchStreamedTitle = action.payload;
		},
		setSearchStreamedData: (state, action: PayloadAction<string>) => {
			state.searchTab.searchStreamedData = action.payload;
		},
		setSearchLikeStatus: (state, action: PayloadAction<boolean>) => {
			state.searchTab.searchLikeStatus = action.payload;
		},
		setSearchDislikeStatus: (state, action: PayloadAction<boolean>) => {
			state.searchTab.searchDislikeStatus = action.payload;
		},
		setSearchRefData: (state, action: PayloadAction<transcriptDataInter[]>) => {
			state.searchTab.searchRefData = action.payload;
		},
		setDiscoverFilters: (state, action: PayloadAction<IDiscoverFilterValues>) => {
			state.discoverTab.discoverFilters = action.payload;
		},
		setDiscoverTreeData: (state, action: PayloadAction<any>) => {
			state.discoverTab.discoverTreeData = action.payload;
		},
	},
});

export const {
	resetState,
	setSearchSummaryFilterInSync,
	setSearchFilters,
	setSearchStreamedTitle,
	setSearchStreamedData,
	setSearchLikeStatus,
	setSearchDislikeStatus,
	setSearchRefData,
	setDiscoverFilters,
	setDiscoverTreeData,
} = appReduxSlice.actions;

export const selectStore = (state: RootState) => state.app_reducer;

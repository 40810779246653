import { POCard } from "../../../Components/ReportCards/POCard";
import React, { useState, useEffect } from "react";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import { NumberData } from "../../../Components/ReportCards/CommonCardComponents/NumberData";
import { PieChartLegends } from "../../../Components/ReportCards/CommonCardComponents/PieChartLegends";
import {
	transformBarChartData,
	transformBubbleChartData,
	transformTableChartData,
	transformTableData,
	transformTopChartData,
} from "../Processing";
import { Radio } from "antd";
import { FilterActiveInterface } from "..";
import { filterTabs } from "../ProgramOverview";
import { groupMode } from "../../../libraries/report-types/charts/interfaces/cartesianChart";

/**
 * This is the Agent Activity subtab in Program Overview tab
 *
 * @param data - The props contains the data to be rendered in Agent Activity tab
 *
 * @returns three cards with Agent Activity on Program and Trends of Total Calls
 */

export function POAgentActivity(props: { data: any }) {
	const [elements, setElements] = useState<Array<boolean>>([true, false]);
	const [elementsFirst, setElementsFirst] = useState<Array<boolean>>([
		true,
		false,
	]);
	const [indexChart, setIndexChart] = useState<number>(0);
	const [indexElement, setIndexElement] = useState<number>(0);

	const chartRadioRender = () => {
		return (
			<div className="flex justify-end">
				<Radio.Group defaultValue={"Chart"}>
					{["Chart", "Table"].map((element, index) => (
						<Radio
							value={element}
							className="radio-custom"
							onChange={(e) => {
								var elem = elementsFirst;
								elementsFirst.forEach((el, i) => {
									if (i === index) {
										elem[index] = e.target.checked;
									} else {
										elem[i] = false;
									}
								});

								// console.log(elem);
								setIndexChart(index);
								setElementsFirst(elem);
							}}
						>
							{element}
						</Radio>
					))}
				</Radio.Group>
			</div>
		);
	};
	var charts = [
		[
			<ReportTypes
				// header={props.data[0].chartName[0]}
				hideShadow
				hideRibbon={false}
				// ribbonData={chartRadioRender()}
				// footer="Footer Text"
				// minHeight="300px"
				// height="400px"
				height="472px"
				bodyPadding="5px 24px"
				title={{ title: "Chart Title" }}
				chartType={ReportTypesComponentInterface.chartType.scatterPlot}
				series={
					transformBubbleChartData(
						props.data[0].chartData[0],
						props.data[0].chartColor
					).series
				}
				data={
					transformBubbleChartData(
						props.data[0].chartData[0],
						props.data[0].chartColor
					).data
				}
				tooltip={{
					isInteractive: true,
					format: "agentA",
				}}
				chartArea={{ backgroundDivision: 4, twoByTwo: true }}
				axis={{
					dualAxis: true,
					primaryX: {
						tickSize: 20,
						dim: 0,
						legend: "Average Call Duration (minutes)",
						legendOffset: 50,
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						// tickValues: 2,
						// minValue: -6,
						// maxValue: 6,
					},
					primaryY: {
						tickSize: 20,
						tickRotation: 0,
						legend: props.data[0].yAxisLabel[0],
						legendOffset: -60,
						// tickValues: 3,
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						// minValue: -6,
					},
				}}
				chartConfig={{
					cartesian: {
						grid: {
							enableGridX: true,
							enableGridY: true,
							// gridYValues: 3,
							// gridXValues: 2,
						},
					},
				}}
				style={{
					margin: {
						top: 20,
						right: 20,
						bottom: 60,
						left: 80,
					},
					labelTextColor: "white",
					axisWidth: 0,
				}}
			/>,
		],
		[
			<ReportTypes
				// handleEdit={showModal}
				// handleSave={handleSave}
				// header={props.data[0].chartName[1]}
				// hideActions={[false, false]}
				hideShadow
				hideRibbon={false}
				// ribbonData={chartRadioRender()}
				chartType={ReportTypesComponentInterface.chartType.simpleTable}
				series={[]}
				chartConfig={{
					table: {
						tableData: {
							data: transformTableData(
								props.data[0].chartData[1],
								[]
							).data,
							column: transformTableData(
								props.data[0].chartData[1],
								[]
							).column,
						},
						noPagination: true,
						maxHeight: "40vh",
					},
				}}
			/>,
		],
	];
	var data = [
		{
			header: props.data[0].cardName,
			ribbonData: chartRadioRender(),
			iconName: "Report Agent Activity",
			cardData: false,
			chartElement: charts[indexChart],
		},
		{
			header: props.data[1].cardName,
			cardTopData: (
				<div className="flex items-end mx-3 my-6 justify-between">
					<div className="flex items-end justify-between w-full">
						<div className="w-1/4">
							<NumberData
								numberHeading={props.data[1].kpi[0]}
								dataNumber={props.data[1].value[0]}
								dataName={props.data[1].month[0]}
								suffixElement={props.data[1].unit[0]}
							/>
						</div>

						<div className="flex w-2/4 justify-around">
							<div className="flex items-center">
								<div className="w-28">
									<ReportTypes
										// header={"Average Patient Volume"}

										hideShadow
										hideRibbon
										minHeight="100px"
										height="110px"
										bodyPadding="0px 0px"
										title={{ title: "xx" }}
										// legend={{
										// 	position:
										// 		ReportTypesComponentInterface.legendPosition
										// 			.right,
										// 	translateX: -120,
										// 	translateY: 6,
										// 	symbolSize: 15,
										// 	symbolShape:
										// 		ReportTypesComponentInterface.legendSymbolShape
										// 			.circle,
										// }}
										chartType={
											ReportTypesComponentInterface
												.chartType.pie
										}
										series={
											transformTopChartData(
												props.data[1].topChart,
												props.data[1].chartColor
											).series
										}
										data={
											transformTopChartData(
												props.data[1].topChart,
												props.data[1].chartColor
											).data
										}
										chartConfig={{}}
										style={{
											margin: {
												top: 10,
												right: 10,
												bottom: 10,
												left: 0,
											},
										}}
										tooltip={{
											isInteractive: true,
											valueFormat: (value: any) => {
												return `${value}%`;
											},
										}}
									/>
								</div>
								<div className="flex items-center">
									<PieChartLegends
										legendData={
											transformTopChartData(
												props.data[1].topChart,
												props.data[1].chartColor,
												true
											).series
										}
									/>
								</div>
							</div>
						</div>
						<div className="ml-12 w-1/4">
							<NumberData
								numberHeading={props.data[1].kpi[1]}
								dataNumber={props.data[1].value[1]}
								dataName={props.data[1].month[1]}
								suffixElement={props.data[1].unit[1]}
							/>
						</div>
					</div>
				</div>
			),
			iconName: "Report User",
			chartElement: [
				<ReportTypes
					header={props.data[1].chartName[indexElement]}
					hideShadow
					borderedHeading
					hideRibbon={false}
					ribbonData={
						<div className="flex justify-end">
							<Radio.Group defaultValue={"Total Calls"}>
								{["Total Calls", "Avg. Duration of Calls"].map(
									(elementS, index1) => (
										<Radio
											value={elementS}
											className="radio-custom"
											onChange={(e) => {
												var elem = elements;
												elements.forEach((el, i) => {
													if (i === index1) {
														elem[index1] =
															e.target.checked;
													} else {
														elem[i] = false;
													}
												});
												console.log(elem);
												console.log(index1);
												setElements(elem);
												setIndexElement(index1);
											}}
										>
											{elementS}
										</Radio>
									)
								)}
							</Radio.Group>
						</div>
					}
					minHeight="30px"
					// height="37vh"
					height="320px"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.bar}
					series={
						transformBarChartData(
							props.data[1].chartData.length > 0
								? props.data[1].chartData[indexElement]
								: [],
							props.data[1].chartData.length > 0
								? props.data[1].chartColor
								: []
						).series
					}
					data={
						transformBarChartData(
							props.data[1].chartData.length > 0
								? props.data[1].chartData[indexElement]
								: [],
							props.data[1].chartData.length > 0
								? props.data[1].chartColor
								: []
						).data
					}
					tooltip={{
						isInteractive: true,
						format: indexElement === 0 ? "stack" : "stack_mins",
					}}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: props.data[1].yAxisLabel[indexElement],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -37,
						},
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 20,
							},
							groupMode:
								indexElement === 0
									? groupMode.stacked
									: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: {
								barGap: indexElement === 0 ? 0.79 : 0.6,
								groupGap: 0,
							},
						},
					}}
					style={{
						margin: {
							top: 15,
							right: 10,
							bottom: 65,
							left: 48,
						},
					}}
				/>,
			],
		},
	];
	return (
		<div className="grid xl:grid-cols-2 lg:grid-cols-1 gap-4">
			{data.map((card) => (
				<POCard {...card} />
			))}
		</div>
	);
}

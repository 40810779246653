import { Divider, Drawer, Select, Skeleton, Space } from "antd";
import React from "react";
import Collapse from "../../../Components/Collapse";
import { SentimentPatientCard } from "../../../Components/ReportCards/CommonCardComponents/SentimentPatientCard";
import {
  SkeletonDetail,
  SkeletonSentiment,
} from "../../../Components/SkeletonCard";
import { icons } from "../../../GlobalThemes";
import ReportTypes from "../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import {
  transformBarChartData,
  transformTopChartData,
} from "../../Dashboard/Processing";
import { findMaxGroupValue } from "../../Dashboard/ProgramOverview/ProgramOverviewCalls";

type FetchData = {
  cardName: string;
  kpi: string[];
  month: string[];
  value: number[];
  unit: string[];
  vsPrevMonth: string[];
  vsPrevMonthArray: string[];
  topChart: {
    [id: string]: number;
  };
  chartName: string[];
  xAxisLabel: string[];
  yAxisLabel: string[];
  chartColor: string[];
  chartAccessor: string[];
  footer: string;
  storyData?: {
    id?: string;
    storyName?: string;
  }[];
  chartData: {
    [id: string]: {
      [id: string]: number;
    };
  }[];
};

function RenderDropdown(props: {
  options: string[];
  view: number;
  setView: React.Dispatch<React.SetStateAction<number>>;
}) {
  var reversedArray = [...props.options];

  return (
    <div>
      View : &nbsp;
      <Select
        className="w-44 h-9"
        value={props.view}
        onChange={(value: number) => {
          props.setView(value);
        }}
      >
        <Select.Option value={0} key={0}>
          {reversedArray[0]}
        </Select.Option>
        {reversedArray.reverse().map(
          (option, index) =>
            index < reversedArray.length - 1 && (
              <Select.Option value={index + 1} key={index + 1}>
                {option}
              </Select.Option>
            )
        )}
      </Select>
    </div>
  );
}

export function PatientScoreChart(props: { data: any }) {
  const [fetchedData, setFetchedData] = React.useState<FetchData | any>(null);
  const [loading, setLoading] = React.useState(true);
  const [view, setView] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [drawerHeader, setdrawerHeader] = React.useState("");
  const [drawerBody, setDrawerBody] = React.useState(<></>);
  const onClose = () => {
    setVisible(false);
  };

  React.useEffect(() => {
    setFetchedData(props.data);
    setLoading(false);
  }, []);
  // console.log("PATIENT SCORE CHART", props, fetchedData);

  return (
    <>
      {!loading ? (
        <div className="bg-white w-full">
          <ReportTypes
            header={
              <>
                <div className="flex gap-2 font-semibold text-base">
                  <p>{fetchedData?.cardName}</p>
                </div>
              </>
            }
            hideShadow
            hideRibbon={false}
            minHeight="30px"
            // height="25rem"
            height="220px"
            bodyPadding="0px 0px"
            title={{ title: "xx" }}
            chartType={ReportTypesComponentInterface.chartType.line}
            series={
              transformBarChartData(
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartData[view]
                  : [],
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartColor
                  : []
              ).series
            }
            data={
              transformBarChartData(
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartData[view]
                  : [],
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartColor
                  : []
              ).data
            }
            tooltip={{
              isInteractive: true,
              format: "z",
            }}
            axis={{
              primaryX: {
                tickSize: 0,
                dim: 0,
                tickValues: 3,
                tickRotation: 0,
                legend: "",
                legendPosition:
                  ReportTypesComponentInterface.axisLegendPosition.middle,
                legendOffset: 22,
              },
              primaryY: {
                tickSize: 0,
                tickValues: 2,
                tickRotation: 0,
                legend: fetchedData?.yAxisLabel[0],
                legendPosition:
                  ReportTypesComponentInterface.axisLegendPosition.middle,
                legendOffset: -45,
                minValue: 0,
                maxValue: findMaxGroupValue(
                  transformBarChartData(
                    fetchedData?.chartData.length > 0 &&
                      fetchedData !== undefined
                      ? fetchedData?.chartData[view]
                      : [],
                    fetchedData?.chartData.length > 0 &&
                      fetchedData !== undefined
                      ? fetchedData?.chartColor
                      : []
                  ).data
                ),
              },
            }}

            chartConfig={{
              cartesian: {
                line: {
                  lineWidth: 2,
                  pointSize: 8,
                },
                // groupMode: groupMode.grouped,
                grid: {
                  enableGridX: true,
                  enableGridY: true,
                  gridYValues: 3,
                  gridXValues: 8,
                },
              },
            }}
            style={{
              margin: {
                top: 0,
                right: 20,
                bottom: 40,
                left: 40,
              },
              axisColor: "#E7E7E7",
              textColor: "#53565A",
              fontSize: 10,
              axisWidth: 1,
            }}

          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

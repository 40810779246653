import { FilterInterface } from "..";
import * as DashboardService from "../../../services/Dashboard";

export const getKPIs: (
	filters: FilterInterface,
	filterState: any
) => Promise<unknown> = async (filters: FilterInterface, filterState: any) => {
	const data = await DashboardService.getKPIs(filters, filterState);
	var kpiData = Object.assign({}, data);
	var kpiColors = [
		["#2A71A4", "#A9DAFD", "#3B9FE7"],
		["#26C38D", "#DEDCDE", "#E65428", "#3B9FE7"],
		[],
		["#7232CF", "#E0C6FF"],
		[],
	];
	data.kpis.map((kpi: any, index: number) => {
		if (kpi.vsPrevMonthArray.length > 0) {
			kpiData.kpis[index].vsPrevMonth = kpi.vsPrevMonthArray;
		}
		if (Object.keys(kpi.customerTopChart).length > 0) {
			kpiData.kpis[index].topChart = kpi.customerTopChart;
		}
		if (kpi.singleChartData.length > 0) {
			kpiData.kpis[index].chartData = kpi.singleChartData;
		} else if (Object.keys(kpi.poChartData).length > 0) {
			kpiData.kpis[index].chartData = [kpi.poChartData];
		} else if (Object.keys(kpi.customerSatisfactionData).length > 0) {
			kpiData.kpis[index].chartData = [kpi.customerSatisfactionData];
		} else if (kpi.singleChartData.length > 0) {
			kpiData.kpis[index].chartData = kpi.singleChartData;
		} else if (kpi.callsByStateChartData.length > 0) {
			kpiData.kpis[index].chartData = kpi.callsByStateChartData;
		} else if (kpi.multiChartData.length > 0) {
			kpiData.kpis[index].chartData = kpi.multiChartData;
		} else {
			kpiData.kpis[index].chartData = [];
		}
		kpiData.kpis[index].chartColor = kpiColors[index];
	});
	return kpiData;
};

import React, { useState, useEffect, useRef } from "react";
import { Button, Drawer, Modal, message } from "antd";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Collapse from "../../../../Components/Collapse";
import HighlightDrawer from "../../../../Components/HighlightDrawer";
import TableWrapper from "../../../../Components/TableWrapper";
import { StoryType, icons } from "../../../../GlobalThemes";
import AnnotatedSelectionLoading from "./loading";
import { exportDataInterface } from "../../ExportToExcel";
import { TranscriptModalData } from "../../../../Components/SearchEngineCards/TranscriptModal";
import * as Messages from "../../../../Components/Messages";
import * as AnnotatedSelectionsHelper from "../../ServiceHelpers/annotatedSelections";
import * as SearchServiceHelper from "../../ServiceHelpers/search";
import * as StudyServiceHelper from "../../ServiceHelpers/study";
import * as StoriesFindSimilarConversations from "../../ServiceHelpers/stories_findSimilarConversations";

/**
 * Renders the Accordion Component along with its Headers and Sorting.
 *
 * @param headerInfo - Array of String containing Header Names
 * @param data - Data to be shown in the Accordion
 * @param filteredColumns - Array of Strings containing names of the header fields that is to be filtered.
 * @param addHighlightedTranscript - Callback function for handling addition of highlighted transcript
 * @param removeHighlightedTranscript - Callback function for handling removal of highlighted transcript
 * @param updateTranscript - Callback function for handling updation of transcript data on the accordion
 * @param deleteTranscript - Callback function for deleting a Transcript
 *
 * @returns Accordion Component
 */
export interface AnnotatedSelectionInterface {
	id: number;
	"Annotated Selection": string;
	"Date Added on": string;
	Source: string;
	Status: string;
	Tags: string[];
	elasticSearchId: string;
	// therapyStatus: string;
	// ToT: number;
	highlightText: string;
	highlightedString: string;
	Description: {
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		dialogueNo: number;
		s3path: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
	};
}
export default function AnnotatedSelection(props: {
	storyId: any;
	storyName: string;
	storyType?: number;
	createdBy?: string;
	addHighlightedTranscript: () => void;
	removeHighlightedTranscript: () => void;
}) {
	const headerInfo = ["Annotated Selection", "Date Added on", "Source", "Tags", "Status"];
	const filteredColumns = ["Source", "Tags", "Status"];

	const [fetching, setFetching] = useState<boolean>(false);
	const [data, setData] = useState<AnnotatedSelectionInterface[]>([]);
	const [dataCopy, setDataCopy] = useState<AnnotatedSelectionInterface[]>([]);
	const [updated, setUpdated] = useState(moment().toString());
	const [order, setOrder] = useState(0);
	const [filters, setFilters] = useState<
		{
			columnName: string;
			options: string[];
		}[]
	>([
		...filteredColumns.map((col) => {
			return { columnName: col, options: [] };
		}),
	]);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [fullTranscriptData, setFullTranscriptData] = useState();
	const [exportFlag, setExportFlag] = useState<boolean>(false);

	const filterTemp: { [id: string]: string[] } = {};
	filters.map((filter) => {
		filterTemp[filter.columnName] = [];
	});
	// filterTemp["Source"] = ["Search Based"];
	const [filtersApplied, setFiltersApplied] = useState(filterTemp);
	const [sliderVal, setSliderVal] = useState<any>();

	async function fetchData() {
		setFetching(false);
		try {
			const data: any = await AnnotatedSelectionsHelper.getAnnotations(String(props.storyId));
			if (data) {
				setData(data.annotatedSelections);
				setDataCopy(data.annotatedSelections);
				setUpdated(moment().toString());
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			// Show error message to the user
			message.error("Failed to fetch data. Please try again later.");
		} finally {
			setFetching(true);
		}
	}

	async function fetchStoryStatus() {
		try {
			const response: any = await StoriesFindSimilarConversations.getStoryStatus(props.storyId, props.storyType);
			if (response.export_status === "COMPLETE") {
				setExportFlag(true);
			} else {
				setExportFlag(false);
			}
		} catch (error) {
			console.error("Error fetching story status:", error);
			// Show error message to the user
			message.error("Failed to fetch story status. Please try again later.");
		}
	}

	useEffect(() => {
		fetchData();
		if (props && props.storyType && props.storyType === 2) {
			fetchStoryStatus();
			const intervalId = setInterval(fetchStoryStatus, 30000);
			return () => clearInterval(intervalId);
		}
	}, []);

	const filteredData = useRef([...data]);

	function updateTranscript(
		id: number,
		editData: {
			id: number;
			highlightText: string;
			Tags: string[];
			highlightedString: string;
		}
	) {
		const temp = [...data];
		const idx = temp.findIndex((l: any) => l.id === id);

		if (idx !== -1) {
			temp[idx].Status = "Updating";
			temp[idx].Tags = ["Updating"];
		}

		setData(temp);
		// createFilters();
		setUpdated(moment().toString());
		editHiglights(id, editData);
	}

	async function deleteTranscript(id: number) {
		const key = "updatable";
		Messages.loading("Deleting Transcript", key);

		const elasticSearchId = data.find((t) => t.id === id)?.elasticSearchId;
		const response: any = await AnnotatedSelectionsHelper.deleteAnnotation(id, props.storyId || "", elasticSearchId || "0");
		if (response) {
			const temp = [...data];
			const idx = temp.findIndex((d) => d.id === id);
			temp.splice(idx, 1);
			setData(temp);
			// createFilters();
			setUpdated(moment().toString());
			Messages.success("Transcript Deleted Sucessfully", key);
			fetchData();
			if (props && props.storyType && props.storyType === 2) {
				fetchStoryStatus();
			}
		} else {
			Messages.error("Failed!", key);
		}
	}

	async function editHiglights(
		id: number,
		editData: {
			id: number;
			highlightText: string;
			Tags: string[];
			highlightedString: string;
		}
	) {
		const key = "updatable";
		Messages.loading("Updating Story", key);
		const response: any = await AnnotatedSelectionsHelper.editHighlights(editData);

		if (response) {
			const temp = [...data];
			const idx = temp.findIndex((l) => l.id === id);

			if (idx !== -1) {
				temp[idx].Tags = response.Tags;
				temp[idx].Status = response.Status;
				temp[idx].highlightedString = response.highlightedString;
			}
			setData(temp);
			setUpdated(moment().toString());
			// createFilters();
			Messages.success("Story Updated Sucessfully", key);
			fetchData();
		} else {
			Messages.error("Failed!", key);
		}
	}

	function sortData(arr: any, columnName: string) {
		if (order === 2) setData(filteredData.current);
		else
			setData(
				[...filteredData.current].sort(function (a: any, b: any) {
					if (new Date(a[columnName]) < new Date(b[columnName])) {
						if (order === 0) return -1;
						else return 1;
					} else {
						if (order === 0) return 1;
						else return -1;
					}
				})
			);
		setOrder((order + 1) % 3);
	}

	function filterData(arr: any, columnName: string, columnValue: any) {
		// console.log("ToT", arr, columnValue);

		if (columnName === "ToT") {
			let temp: any = [];
			arr.map((i: any) => {
				if (columnValue[1] !== 100) {
					if (i.ToT > marks[columnValue[0]] && i.ToT < marks[columnValue[1]]) {
						temp.push(i);
					}
				} else {
					if (i.ToT > marks[columnValue[0]]) {
						temp.push(i);
					}
				}
			});
			// console.log(temp);
			return temp;
		} else {
			return arr.filter((a: any) => {
				if (Array.isArray(a[columnName])) {
					if (a[columnName].find((tag: string) => tag === columnValue)) return true;
					else return false;
				} else {
					return a[columnName] === columnValue;
				}
			});
		}
	}

	function createFilters() {
		const dataTemp = [...data];
		const filtersTemp: {
			columnName: string;
			options: string[];
		}[] = [
			{ columnName: "Source", options: [] },
			// { columnName: "ToT", options: [] },
			// { columnName: "Therapy Status", options: [] },
			{ columnName: "Tags", options: [] },
			{ columnName: "Status", options: [] },
		];

		filteredColumns.map((filteredCol) => {
			dataTemp.map((d: any) => {
				if (filteredCol === "Tags") {
					const tagArray = d["Tags"];
					tagArray.map((tag: string) => {
						if (filtersTemp.find((f) => f.columnName === "Tags")?.options.indexOf(tag) === -1) filtersTemp.find((f) => f.columnName === "Tags")?.options.push(tag);
					});
				} else {
					if (filteredCol === "Therapy Status") {
						if (filtersTemp.find((f) => f.columnName === filteredCol)?.options.indexOf(d["therapyStatus"]) === -1)
							filtersTemp.find((f) => f.columnName === filteredCol)?.options.push(d["therapyStatus"]);
					} else {
						if (filtersTemp.find((f) => f.columnName === filteredCol)?.options.indexOf(d[filteredCol]) === -1)
							filtersTemp.find((f) => f.columnName === filteredCol)?.options.push(d[filteredCol]);
					}
				}
			});
		});
		// console.log(filterTemp);
		setFilters(filtersTemp);
	}

	function setFilter(
		filtersApplied: {
			[id: string]: string[];
		},
		columnName: string,
		filterValue: any
	) {
		if (columnName === "ToT") {
			setSliderVal(filterValue);
		}

		const newFilter = { ...filtersApplied, [columnName]: filterValue };
		let tempData = [...dataCopy];

		for (let key in newFilter) {
			let temp: any[] = [];

			if (newFilter[key].length > 0) {
				newFilter[key].map((d: any) => {
					key === "ToT" &&
						columnName === "ToT" &&
						(temp = temp.concat(temp.concat(filterData(tempData, "ToT", filterValue)).filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item)))));

					if (columnName !== "ToT" && key === "ToT") {
						temp = temp.concat(temp.concat(filterData(tempData, "ToT", sliderVal)).filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item))));
					}
					// console.log("TEMP", temp, newFilter, key, d);
					temp =
						key === "Therapy Status"
							? temp.concat(temp.concat(filterData(tempData, "therapyStatus", d)).filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item))))
							: temp.concat(temp.concat(filterData(tempData, key, d)).filter((item) => !JSON.stringify(temp).includes(JSON.stringify(item))));
					// console.log("TEMP 2", temp, tempData, key, d);
				});

				tempData = [...temp];
			}
		}

		filteredData.current = [...tempData];
		setFiltersApplied(newFilter);
		setData(tempData);
		// console.log("AFTER", newFilter, tempData);
	}

	const marks: { [id: number]: string } = {
		0: "0",
		16.6: "30",
		33.3: "60",
		50: "90",
		66.6: "180",
		83.3: "360",
		100: "360+",
	};

	function sliderFilter(e: any) {
		setFilter(filtersApplied, "ToT", e);
		// console.log(e, data);
	}

	const [visible, setVisible] = useState(false);
	const [drawerHeader, setdrawerHeader] = useState("");
	const [drawerBody, setDrawerBody] = useState(<></>);
	const showDrawer = (id: number, header: string, transcriptId: number) => {
		setdrawerHeader(header);
		setDrawerBody(
			<HighlightDrawer
				id={transcriptId}
				header={header}
				addHighlightedTranscript={props.addHighlightedTranscript}
				removeHighlightedTranscript={props.removeHighlightedTranscript}
				setVisible={onClose}
				updateTranscript={updateTranscript}
			/>
		);
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};
	const navigate = useNavigate();
	function handleRedirectOnEmpty() {
		navigate("/search");
	}

	const exportDataObj: exportDataInterface = {
		storyId: props.storyId,
		storyName: props.storyName,
		transcripts: dataCopy,
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleClickGetFullTrans = async () => {
		setIsModalVisible(true);
		const s3Path = `story/reports/${props.storyId}/merged_annotations_${props.storyId}.pdf`;
		const transData: any = await SearchServiceHelper.getFullTranscript(s3Path);
		setFullTranscriptData(transData);
	};

	const handleDownloadPPT = async () => {
		const loadingMessage = message.loading(`Downloading`, 1000);
		try {
			const fileName = `${props.storyName}`;
			const s3Path = `story/reports/${props.storyId}/merged_annotations_${props.storyId}.zip`;
			const response: any = await StudyServiceHelper.downloadS3File(s3Path);

			if (response) {
				loadingMessage();
				const url = window.URL.createObjectURL(new Blob([response], { type: "application/zip" }));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();

				message.success("Export downloaded successfully.");
			} else {
				loadingMessage();
				message.error("Error downloading export. Please try again later.");
			}
		} catch (error) {
			loadingMessage();
			console.error("Error downloading export:", error);
			message.error("Error downloading export. Please try again later.");
		} finally {
			loadingMessage();
		}
	};

	function showDownloadPPT() {
		handleClickGetFullTrans();
		handleDownloadPPT();
	}

	useEffect(() => {
		createFilters();
		// console.log("Create Filters Called", props.filters);
	}, [data, updated]);

	useEffect(() => {}, [sliderVal]);

	return (
		<>
			<Drawer destroyOnClose={true} title={drawerHeader} placement="right" onClose={onClose} open={visible} width={680}>
				{drawerBody}
			</Drawer>
			<Modal
				title={props.storyName}
				width={1500}
				bodyStyle={{
					height: "600px",
					overflowY: "auto",
				}}
				centered
				open={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				className="modal-footer-left text-left"
				footer={[
					<Button type="primary" className="green-button items-start mr-6" onClick={handleCancel}>
						Close
					</Button>,
				]}>
				{fullTranscriptData && <TranscriptModalData id={props.storyId || 0} transPayloadData={fullTranscriptData} />}
			</Modal>
			{fetching ? (
				<TableWrapper
					title="Annotated Selection"
					value={data.length}
					exportData={data.length !== 0 && props.storyType === StoryType.Transcripts ? exportDataObj : null}
					exportReport={data.length !== 0 && props.storyType === StoryType.Reports ? exportFlag : null}
					showModal={showDownloadPPT}>
					{data.length === 0 ? (
						<div className="bg-body flex flex-col items-center justify-center h-96 m-2">
							<div className="text-text-gray-80 font-semibold text-base p-2">No Annotations Added</div>
							<div
								className="flex items-center gap-2 text-text-teal text-base cursor-pointer"
								onClick={() => {
									handleRedirectOnEmpty();
								}}>
								{icons.find((l) => l.id === "Plus Filled Green")?.icon}
								Add Annotated Selection
							</div>
						</div>
					) : (
						<div
							className="mt-4"
							// style={{ height: "28em" }}
							// style={{ height: "70vh" }}
						>
							<Collapse
								headerInfo={headerInfo}
								data={data}
								filters={filters}
								sorted={order}
								sortData={sortData}
								// filterData={filterData}
								filtersApplied={filtersApplied}
								sliderFilter={sliderFilter}
								setFilter={setFilter}
								showDrawer={showDrawer}
								deleteTranscript={deleteTranscript}
								createdBy={props.createdBy}
								storyType={props.storyType}
							/>
						</div>
					)}
				</TableWrapper>
			) : (
				<AnnotatedSelectionLoading />
			)}
		</>
	);
}

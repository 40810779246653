import * as ReportTypesComponentProps from "../../interfaces/main";
/**
 * Transform the Data from the Default Type to the type accepted by Bar Chart of Nivo.
 *
 * @param data - Raw Data
 * @param series - Series details
 * @param labelDim - Array containing the Label names
 * @returns Transformed data
 */
export default function transformData(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"],
	labelDim: number
) {
	let res: any = []; //Check this type

	series.map((s, idx) => {
		return data[s.dim].map((value: number, index: number) => {
			if (idx === 0) {
				return res.push({
					id: data[labelDim][index],
					[s.name]: value,
					[s.name + "Color"]: s.color,
				});
			} else {
				res[index][s.name] = value;
				res[index][s.name + "Color"] = s.color;
				return res;
			}
		});
	});

	return res;
}
// export function findRange(
// 	data: ReportTypesComponentProps.Interface["data"],
// 	series: ReportTypesComponentProps.Interface["series"]
// ) {
// 	console.log("Data Scatter ", data);
// 	if (data[0].length === 0) {
// 		return {
// 			minX: 0,
// 			minY: 0,
// 		};
// 	}
// 	let minY = Number.MAX_SAFE_INTEGER,
// 		maxY = 0;
// 	series.map((s, idx) => {
// 		data[s.dim].map((value: number, index: number) => {
// 			const y = data[s.dim][index];
// 			if (y < minY) minY = y;
// 			if (y > maxY) maxY = y;
// 		});
// 	});
// 	// console.log(
// 	// 	`x: ${minX} ${maxX} ${Math.max(Math.abs(minX), Math.abs(maxX))} ${
// 	// 		(minX + maxX) / 2
// 	// 	} , y: ${minY} ${maxY}`
// 	// );

// 	// let xBuffer =
// 	// 	Math.round((maxX - minX) * 0.05) > 1
// 	// 		? Math.round((maxX - minX) * 0.05)
// 	// 		: 1;
// 	let yBuffer =
// 		Math.round((maxY - minY) * 0.05) > 1
// 			? Math.round((maxY - minY) * 0.05)
// 			: 1;
// 	// xBuffer = 0;
// 	yBuffer = 0;
// 	// minX = Math.floor((minX - xBuffer) / 4) * 4;
// 	minY = Math.floor((minY - yBuffer) / 4) * 4;
// 	// maxX = Math.ceil((maxX + xBuffer) / 4) * 4;
// 	maxY = Math.ceil((maxY + yBuffer) / 4) * 4;

// 	// minY = Math.floor((minY - 0) / 1) * 1;

// 	// maxY = Math.ceil((maxY + 0) / 1) * 1;

// 	// minX = minX;
// 	// minY = minY;
// 	// maxX = maxX;
// 	// maxY = maxY;

// 	console.log("MinY MaxY", minY, maxY);
// 	return {
// 		minY: minY,

// 		maxY: maxY,
// 	};
// }
export function findMaxValue(
	data: (string[] | number[])[]
): number | undefined {
	let max = Number.MIN_SAFE_INTEGER;
	const bars = data[0] ? data[0].length : 0;
	const series = data.length - 1;
	let temp_sum = 0;
	for (let i = 0; i < bars; i++) {
		temp_sum = 0;
		for (let j = 1; j <= series; j++) {
			temp_sum += Number(data[j][i]);
		}
		if (temp_sum > max) max = temp_sum;
	}
	const buffer = 0;
	if (max < 10) max = Math.ceil((max + buffer) / 5) * 5;
	else if (max < 100) max = Math.ceil((max + buffer) / 10) * 10;
	else if (max < 1000) max = Math.ceil((max + buffer) / 100) * 100;
	else if (max < 2000) max = Math.ceil((max + buffer) / 200) * 200;
	else if (max < 10000) max = Math.ceil((max + buffer) / 500) * 500;
	else max = Math.ceil((max + buffer) / 10000) * 10000;

	// console.log("MAX", max, data);
	return max;
}

import { FilterInterface } from "..";
import * as DashboardService from "../../../services/Dashboard";
import { transformBarChartData } from "../Processing";
export const getPatientExperienceData: (
	subTab: string,
	filters: FilterInterface,
	filterState: any
) => Promise<unknown> = async (
	subTab: string,
	filters: FilterInterface,
	filterState: any
) => {
	const data = await DashboardService.getPatientExperienceData(
		subTab,
		filters,
		filterState
	);
	console.log("api----------------------------------------------", data);
	if (data === null || data === undefined) return null;
	var peData: any = data;
	var patientNeeds = [["gray"], ["#3B9FE7", "#9C9AA1", "#9C9AA1", "#9C9AA1"]];
	var sentimentColors = [
		["#26C38D", "#DEDCDE", "#E65428"],
		[],
		["#E65428", "#DEDCDE", "#26C38D"],
		["#E65428", "#DEDCDE", "#26C38D"],
	];
	if (subTab === "Journey") {
		peData = {
			patientJourney: data,
			patientNeeds: [],
			patientSentiments: [],
		};
		var i = -1;
		data.unmetNeedsAndQuestionTime.forEach((unaqt: any, index: number) => {
			if (unaqt.agentChartData.length >= 0) {
				peData.patientJourney.unmetNeedsAndQuestionTime[
					index
				].chartData = unaqt.agentChartData;
				if (unaqt.agentChartData.length == 0)
					peData.patientJourney.unmetNeedsAndQuestionTime[
						index
					].chartData = [{}];
				if (index === 1) {
					if (
						peData.patientJourney.unmetNeedsAndQuestionTime[0]
							.chartData[0]["30"] === undefined
					) {
						peData.patientJourney.unmetNeedsAndQuestionTime[0].chartData[0][
							"30"
						] = {};
						peData.patientJourney.unmetNeedsAndQuestionTime[1].chartData.splice(
							0,
							0,
							{}
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].unit.splice(
							0,
							0,
							""
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].value.splice(
							0,
							0,
							""
						);
					}
					if (
						peData.patientJourney.unmetNeedsAndQuestionTime[0]
							.chartData[0]["60"] === undefined
					) {
						peData.patientJourney.unmetNeedsAndQuestionTime[0].chartData[0][
							"60"
						] = {};
						peData.patientJourney.unmetNeedsAndQuestionTime[1].chartData.splice(
							1,
							0,
							{}
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].unit.splice(
							1,
							0,
							""
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].value.splice(
							1,
							0,
							""
						);
					}
					if (
						peData.patientJourney.unmetNeedsAndQuestionTime[0]
							.chartData[0]["90"] === undefined
					) {
						peData.patientJourney.unmetNeedsAndQuestionTime[0].chartData[0][
							"90"
						] = {};
						peData.patientJourney.unmetNeedsAndQuestionTime[1].chartData.splice(
							2,
							0,
							{}
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].unit.splice(
							2,
							0,
							""
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].value.splice(
							2,
							0,
							""
						);
					}
					if (
						peData.patientJourney.unmetNeedsAndQuestionTime[0]
							.chartData[0]["180"] === undefined
					) {
						peData.patientJourney.unmetNeedsAndQuestionTime[0].chartData[0][
							"180"
						] = {};
						peData.patientJourney.unmetNeedsAndQuestionTime[1].chartData.splice(
							3,
							0,
							{}
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].unit.splice(
							3,
							0,
							""
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].value.splice(
							3,
							0,
							""
						);
					}
					if (
						peData.patientJourney.unmetNeedsAndQuestionTime[0]
							.chartData[0]["180+"] === undefined
					) {
						peData.patientJourney.unmetNeedsAndQuestionTime[0].chartData[0][
							"180+"
						] = {};
						peData.patientJourney.unmetNeedsAndQuestionTime[1].chartData.splice(
							4,
							0,
							{}
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].unit.splice(
							4,
							0,
							""
						);
						peData.patientJourney.unmetNeedsAndQuestionTime[1].value.splice(
							4,
							0,
							""
						);
					}
				}
			} else {
				peData.patientJourney.unmetNeedsAndQuestionTime[
					index
				].chartData = [];
			}
		});

		var maxValue = 0;
		var gridValue = 100;
		data.sentimentBreakdown.forEach((sb: any, index: number) => {
			if (Object.keys(sb.customerTopChart).length > 0) {
				peData.patientJourney.sentimentBreakdown[index].topChart =
					sb.customerTopChart;
			} else {
				peData.patientJourney.sentimentBreakdown[index].topChart = {};
			}
			if (Object.keys(sb.poChartDataDouble).length > 0) {
				peData.patientJourney.sentimentBreakdown[index].chartData = [
					sb.poChartDataDouble,
				];
			} else {
				peData.patientJourney.sentimentBreakdown[index].chartData = [];
			}
			var values: any = transformBarChartData(peData.patientJourney.sentimentBreakdown[index].chartData, []).data;
			var singleMax = values.length > 0 ? Math.max(...values[1]) : 0;
			if(singleMax > maxValue) {
				maxValue = singleMax;
			}

		});

		if(maxValue > 0 && maxValue <= 10) {
			gridValue = 10;
		}
		else if(maxValue > 10) {
			gridValue = Math.ceil(maxValue / 10) * 10;
		}
		peData.patientJourney.sentimentBreakdown[0].gridValue = gridValue;

	} else {
		data.patientNeeds.forEach((pe: any, index: number) => {
			if (pe.patientNeedsChartData.length > 0) {
				peData.patientNeeds[index].chartData = pe.patientNeedsChartData;
			} else if (pe.singleChartData.length > 0) {
				peData.patientNeeds[index].chartData = pe.singleChartData;
			} else {
				peData.patientNeeds[index].chartData = [];
			}
			peData.patientNeeds[index].chartColor = patientNeeds[index];
		});

		data.patientSentiments.forEach((ps: any, index: number) => {
			if (ps.multiChartData.length > 0) {
				peData.patientSentiments[index].chartData = ps.multiChartData;
			} else {
				peData.patientSentiments[index].chartData = [];
			}
			peData.patientSentiments[index].chartColor = sentimentColors[index];
		});
		peData.patientJourney = {};
	}
	console.log(peData);
	return peData;
};

export const getPatientNeedsDetails: (
	storyId: string | number
) => Promise<unknown> = async (storyId: string | number) => {
	const data = await DashboardService.getPatientNeedsDetails(storyId);
	console.log("api", data);
	if (data === null || data === undefined) return null;

	return data;
};

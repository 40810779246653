import { transcriptDataInter } from "../Pages/SearchEngine/Search";
import { request } from "./../App";

export const getAllStories = async (storyType?: number) => {
	const fetchUrl = storyType !== undefined && storyType !== null
    ? `originalStory/GetOriginalStories?storyType=${storyType}`
    : "originalStory/GetOriginalStories";

	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const addNewStory = async (addData: { StoryName: string; StoryType?: number }) => {
	const fetchUrl = "originalStory/AddNewOriginalStory";
	const data = await request
		.post(fetchUrl, addData, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const deleteStory = async (storyId: number) => {
  const fetchUrl = `originalStory/DeleteOriginalStory?id=${storyId}`;
  const data = await request
    .delete(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const editStory = async (editData: {
	id: string;
	StoryName: string;
	// "StoryType": string;
}) => {
  const fetchUrl = "originalStory/EditStory";
  const data = await request
    .put(fetchUrl, editData, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

// export const addToStory = async (searchJson: any, storyId?: string) => {
// 	const fetchUrl = "search/AddToStory";
// 	const data = await request
// 		.post(fetchUrl, searchJson, { withCredentials: true })
// 		.then((response) => {
// 			// console.log(response.status);
// 			return response.data;
// 		})
// 		.catch((err) => {
// 			// console.log("Error : ", err.message);
// 			return null;
// 		});
// 	return data;
// };

// export const publishStory = async (storyId: string) => {
// 	const fetchUrl = `story/GetPublishStory?id=${storyId}`;
// 	const data = await request
// 		.post(fetchUrl, {}, { withCredentials: true })
// 		.then((response) => {
// 			// console.log(response.status);
// 			return response.data;
// 		})
// 		.catch((err) => {
// 			// console.log("Error : ", err.message);
// 			return null;
// 		});
// 	return data;
// };

export const getStorybyId = async (storyId: number) => {
  const fetchUrl = `originalStory/GetOriginalStoryById?id=${storyId}`;
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

// export const deleteSavedSearch = async (id: number) => {
// 	const fetchUrl = `search/DeleteSavedSearch?id=${id}`;
// 	const data = await request
// 		.delete(fetchUrl, { withCredentials: true })
// 		.then((response) => {
// 			// console.log(response.status);
// 			return response.data;
// 		})
// 		.catch((err) => {
// 			// console.log("Error : ", err.message);
// 			return null;
// 		});
// 	return data;
// };

export const addTransToStory = async (transcriptData: any, storyId?: number) => {
  // const fetchUrl = "originalStory/AddTransToStory";
  const fetchUrl = storyId ? "originalStory/AddTransToStory?storyId=" + storyId : "originalStory/AddTransToStory";
  const data = await request
    .post(fetchUrl, transcriptData, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

import React from "react";
import BarChartWithBg from "../../../Components/BarChartWithBg";
import { POCard } from "../../../Components/ReportCards/POCard";
import ReportTypes from "../../../libraries/report-types";
import { transformBarChartData } from "../Processing";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";

/**
 * Renders the Survey Metrics SubTab with the charts and other data.
 *
 * @param data - Data to be Displayed on the Page.
 *
 * @returns  Survey Metrics SubTab
 */
export default function SurveyMetrics(props: { data: any }) {
	const [fetchedData, setFetchedData] = React.useState(props.data);
	var data = [
		{
			header: fetchedData[0].cardName,
			cardTopData: (
				<div className="w-full p-4">
					<BarChartWithBg
						{...transformBarChartData(
							[
								{
									[fetchedData[0].cardName]:
										fetchedData[0].topChart,
								},
							],
							["black"],
							"bar"
						)}
						backgroundDivisions={3}
						noHeader
						range={[-100, 0.001, 30, 100]}
						unit=""
					/>
				</div>
			),
			iconName: "Report Nps",
			chartElement: [
				<ReportTypes
					header=""
					hideShadow
					hideRibbon
					minHeight="30px"
					height="42.5vh"
					bodyPadding="0px 20px"
					title={{ title: fetchedData[0].chartName[0] }}
					chartType={ReportTypesComponentInterface.chartType.line}
					{...transformBarChartData(
						fetchedData[0].chartData[0],
						fetchedData[0].chartColor,
						"line"
					)}
					// series={chartsData[0] ? chartsData[0].series : []}
					// data={chartsData[0] ? chartsData[0].data : []}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: fetchedData[0].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
							minValue: -100,
							maxValue: 100,
						},
					}}
					tooltip={{
						isInteractive: true,
						format: 'round_left'
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 10,
								lineWidth: 3,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
						},
					}}
					style={{
						margin: {
							top: 10,
							right: 10,
							bottom: 65,
							left: 50,
						},
						axisWidth: 0,
					}}
				/>,
			],
		},
		{
			header: fetchedData[1].cardName,
			cardTopData: (
				<div className="w-full p-4">
					<BarChartWithBg
						{...transformBarChartData(
							[
								{
									[fetchedData[1].cardName]:
										fetchedData[1].topChart,
								},
							],
							["black"],
							"bar"
						)}
						backgroundDivisions={3}
						noHeader
						range={[0, 2, 4, 5]}
						unit=""
					/>
				</div>
			),
			iconName: "Report Call",
			chartElement: [
				<ReportTypes
					header=""
					hideShadow
					hideRibbon
					minHeight="30px"
					height="42.5vh"
					bodyPadding="0px 20px"
					title={{ title: fetchedData[1].chartName[0] }}
					chartType={ReportTypesComponentInterface.chartType.line}
					{...transformBarChartData(
						fetchedData[1].chartData[0],
						fetchedData[1].chartColor,
						"line"
					)}
					// series={chartsData[0] ? chartsData[0].series : []}
					// data={chartsData[0] ? chartsData[0].data : []}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: fetchedData[1].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
							minValue: 0,
							maxValue: 5,
						},
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 10,
								lineWidth: 3,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "round",
					}}
					style={{
						margin: {
							top: 10,
							right: 10,
							bottom: 65,
							left: 50,
						},
						axisWidth: 0,
					}}
				/>,
			],
		},
		{
			header: fetchedData[2].cardName,
			cardTopData: (
				<div className="w-full p-4">
					<BarChartWithBg
						{...transformBarChartData(
							[
								{
									[fetchedData[2].cardName]:
										fetchedData[2].topChart,
								},
							],
							["black"],
							"bar"
						)}
						backgroundDivisions={3}
						noHeader
						range={[0, 33.33, 66.66, 100]}
						unit="%"
					/>
				</div>
			),
			iconName: "Report Query",
			chartElement: [
				<ReportTypes
					header=""
					hideShadow
					hideRibbon
					minHeight="30px"
					height="42.5vh"
					bodyPadding="0px 20px"
					title={{ title: fetchedData[2].chartName[0] }}
					chartType={ReportTypesComponentInterface.chartType.line}
					{...transformBarChartData(
						fetchedData[2].chartData[0],
						fetchedData[2].chartColor,
						"line"
					)}
					// series={chartsData[0] ? chartsData[0].series : []}
					// data={chartsData[0] ? chartsData[0].data : []}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: fetchedData[2].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
							minValue: 0,
							maxValue: 100,
						},
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 10,
								lineWidth: 3,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "round",
					}}
					style={{
						margin: {
							top: 10,
							right: 10,
							bottom: 65,
							left: 50,
						},
						axisWidth: 0,
					}}
				/>,
			],
		},
	];
	return (
		<>
			<div className="w-full grid lg:grid-cols-3 sm:grid-cols-1 gap-6">
				{data.map((card) => (
					<POCard {...card} />
				))}
			</div>
		</>
	);
}

import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import { transformBarChartData } from "../../../Pages/Dashboard/Processing";

import { NumberData } from "./NumberData";

/**
 * This card is used to render overall negative sentiment chart in sentiment breakdown tab
 * 
 * @remarks - This card is a part of SentimentBreakdown file
 * 
 * @param color - This props is used to pass the color  in chart
 * @param data - This props is used to pass data in chart
 * @param chartType - The props is used to decide the type of chart
 *
 * @returns the horizontal bar chart in Sentiments tab
 */

export function SentimentPatientCard(props: {
	color: string;
	data: any;
	chartType: any;
	gridValue: undefined | number;
}) {

	// const chartColors = ["#DFE0FE", "#A6B2F3", "#7E92E9", "#5369DA", "#2B44C7"];

	const barChartRender = () => {
		return (
			<div className="flex">
				<div className="mt-8 leading-9 font-semibold text-right">
					<div>
						{transformBarChartData(props.data, [props.color]).data[0].reverse().map((serie, index) => 
							<div>
								<p>{serie}</p>
							</div>
						)}
					</div>
				</div>
				<ReportTypes
					header={null}
					hideShadow
					hideRibbon
					// footer="Footer Text"
					minHeight="2rem"
					height="17.9rem"
					bodyPadding="0.4rem 1.2rem"
					chartType={props.chartType}
					series={
						transformBarChartData(props.data, [props.color]).series
					}
					data={transformBarChartData(props.data, [props.color]).data}
					label={{
						format: (d: any) => {
							console.log(d.value);
							return `${d.value}%`;
						},
						skipSmallValue: true,
						skipWidth: 80
					}}
					chartArea={{
						layout: ReportTypesComponentInterface.chartAreaLayout
							.horizontal,
					}}
					tooltip={{
						isInteractive: true,
						format: 'unmet'
					}}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: "% of Dialogues",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 37,
							maxValue: props.gridValue,
							minValue: 0,
						},
						primaryY: null,
					}}
					chartConfig={{
						cartesian: {
							line: {
								lineWidth: 3,
								pointSize: 10,
							},
							grid: {
								enableGridX: true,
								enableGridY: false,
								// gridYValues: 3,
								gridXValues: 5,
							},
							gaps: { barGap: 0.4, groupGap: 0 },
						},
					}}
					style={{
						margin: {
							top: 6,
							right: 10,
							bottom: 50,
							left: 5,
						},
						labelTextColor: "black",
						axisWidth: 1,
					}}
				/>
			</div>
		);
	};
	return <div>{barChartRender()}</div>;
}

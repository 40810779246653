import * as ReportTypesComponentProps from "../../interfaces/main";
/**
 * Transform the Data from the Default Type to the type accepted by Line Chart of Nivo.
 *
 * @param data - Raw Data
 * @param series - Series details
 * @param labelDim - Array containing the Label names
 * @returns Transformed data
 */
export default function transformData(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"]
) {
	let res: {
		id: string;
		color: String;
		data: { w: number; x: number; y: number; z: number }[];
	}[] = []; //Check this type

	series.map((s, idx) => {
		return data[s.dim].map((value: number, index: number) => {
			if (index === 0) {
				return res.push({
					id: s.name,
					color: s.color || "",
					data: [
						{
							w: data[s.dim][index].w || 0,
							x: data[s.dim][index].x,
							y: data[s.dim][index].y,
							z: data[s.dim][index].z || 2,
						},
					],
				});
			} else {
				res[idx].data.push({
					w: data[s.dim][index].w || 0,
					x: data[s.dim][index].x,
					y: data[s.dim][index].y,
					z: data[s.dim][index].z || 2,
				});
				return res;
			}
		});
	});

	return res;
}
export function findRange(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"]
) {
	// console.log("Data Scatter ", data);
	if (data[0].length === 0) {
		return {
			minX: 0,
			minY: 0,
			maxX: 0,
			maxY: 0,
			scaleX: 0,
			scaleY: 0,
			midX: 0,
			midY: 0,
		};
	}
	let minX = Number.MAX_SAFE_INTEGER,
		maxX = 0,
		minY = Number.MAX_SAFE_INTEGER,
		maxY = 0;
	series.map((s, idx) => {
		data[s.dim].map((value: number, index: number) => {
			const x = data[s.dim][index].x;
			const y = data[s.dim][index].y;
			if (x < minX) minX = x;
			if (x > maxX) maxX = x;
			if (y < minY) minY = y;
			if (y > maxY) maxY = y;
		});
	});
	// console.log(
	// 	`x: ${minX} ${maxX} ${Math.max(Math.abs(minX), Math.abs(maxX))} ${
	// 		(minX + maxX) / 2
	// 	} , y: ${minY} ${maxY}`
	// );

	// let xBuffer =
	// 	Math.round((maxX - minX) * 0.05) > 1
	// 		? Math.round((maxX - minX) * 0.05)
	// 		: 1;
	// let yBuffer =
	// 	Math.round((maxY - minY) * 0.05) > 1
	// 		? Math.round((maxY - minY) * 0.05)
	// 		: 1;
	// xBuffer = 0;
	// yBuffer = 0;
	// minX = Math.floor((minX - xBuffer) / 4) * 4;
	// minY = Math.floor((minY - yBuffer) / 4) * 4;
	// maxX = Math.ceil((maxX + xBuffer) / 4) * 4;
	// maxY = Math.ceil((maxY + yBuffer) / 4) * 4;
	minX = Math.floor((minX - 0) / 2) * 2;
	minY = Math.floor((minY - 0) / 1) * 1;
	maxX = Math.ceil((maxX + 0) / 2) * 2;
	maxY = Math.ceil((maxY + 0) / 1) * 1;

	// minX = minX;
	// minY = minY;
	// maxX = maxX;
	// maxY = maxY;

	return {
		minX: minX,
		minY: minY,
		maxX: maxX,
		maxY: maxY,
		scaleX: Math.max(Math.abs(minX), Math.abs(maxX)),
		scaleY: Math.max(Math.abs(minY), Math.abs(maxY)),
		midX: (minX + maxX) / 2,
		midY: (minY + maxY) / 2,
	};
}
export function findZRange(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"]
) {
	let minZ = Number.MAX_SAFE_INTEGER,
		maxZ = 0;
	series.map((s, idx) => {
		data[s.dim].map((value: number, index: number) => {
			const z = data[s.dim][index].z || 0;

			if (z <= minZ) minZ = z;
			if (z >= maxZ) maxZ = z;
		});
	});
	return {
		minZ: minZ,
		maxZ: maxZ,
	};
}
export function findMean(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"]
) {
	let sumX: number = 0,
		sumY: number = 0;
	let count = 0;
	if (data[0].length === 0) {
		return {
			meanX: 0,
			meanY: 0,
		};
	}
	series.map((s, idx) => {
		data[s.dim].map((value: number, index: number) => {
			sumX += data[s.dim][index].x;
			sumY += data[s.dim][index].y;
			count += 1;
		});
	});
	console.log(
		"Mean",
		Math.round((sumX / count) * 100) / 100,
		Math.round((sumY / count) * 100) / 100
	);
	return {
		meanX: Math.round((sumX / count) * 100) / 100,
		meanY: Math.round((sumY / count) * 100) / 100,
	};
}

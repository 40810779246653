import { Button, Form, Input, Select } from "antd";
import React, { useContext } from "react";
import AppContext from "../../store";
import { StoryType } from "../../GlobalThemes";

/**
 * This function is used to render the Modal to create a new story
 *
 * @param handleCancel - This props is used to close the modal
 * @param storytypes - This props show the dropdown of story types
 * @param editData -  This props store the story name and  story type
 * @param onSave - This props is used to save the story data
 *
 * @returns the modal  to edit or create story
 */

export default function AddStoryModalContent(props: {
  handleCancel: () => void;
  editData?: any;
  onSave?: (editData?: any) => void;
  tagName?: string;
  data: any;
  storyType?: number;
}) {
  // const { Option } = Select;
  const context = useContext(AppContext);
  const [storyName, setStoryName] = React.useState(props.editData ? props.editData["StoryName"] : "");
  const [storyType, setStoryType] = React.useState<number>(props.editData ? props.editData["StoryType"] : 1);
	const [storyList, setStoryList] = React.useState<any[]>(props.data);

  const onFinish = (values: any) => {
    if (props.onSave && props.editData) {
      props.onSave({
        id: props.editData.id,
        StoryName: values["StoryName"],
        StoryType: values["StoryType"],
      });
    } else if (props.onSave) {
      props.onSave({
        StoryName: values["StoryName"],
        StoryType: values["StoryType"],
      });
    }
  };
  return (
    <div>
      <div className="text-2xl text-text-gray-100 font-normal my-4">{props.editData ? <>Edit {props.tagName}</> : <>Create New {props.tagName}</>}</div>
      <div className="text-base text-text-gray-50 my-4">
        {props.editData ? (
          <>Please complete the form below to edit the {props.tagName ? props.tagName.toLowerCase() : ""}.</>
        ) : (
          <>Please complete the form below to create a new {props.tagName ? props.tagName.toLowerCase() : ""}.</>
        )}
      </div>
      <div className="mb-2">
        <Form name="newStoryStory" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }} initialValues={props.editData} layout="vertical" onFinish={onFinish} autoComplete="off">
          <Form.Item
            name="StoryName"
            label={<div className="text-sm text-text-gray-90 font-medium">{props.tagName} Name</div>}
            className="mt-4 mr-3 text-base"
            rules={[
              {
                required: true,
                message: `Please enter a ${props.tagName} name`,
              },
              () => ({
                validator(_, value) {
                  const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
                  if (specialCharsRegex.test(value)) {
                    return Promise.reject(new Error("Special characters not allowed in the name"));
                  }
                  
                  const storyNameExists = storyList.some((story) => story.storyName === value);
                  if (storyNameExists) {
                    return Promise.reject(new Error(`${props.tagName} name already exists!`));
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input
              value={storyName}
              onChange={(e) => {
                setStoryName(e.target.value);
              }}
              placeholder={`Enter a ${props.tagName} name`}
            />
          </Form.Item>
          {props.tagName === "Story" && context.envId !== 3 && !props.editData ? 
            (<Form.Item
              name="StoryType"
              label={<div className="text-sm text-text-gray-90 font-medium">{props.tagName} Type</div>}
              className="mr-3 text-base"
              rules={[
                {
                  required: true,
                  message: "Story type is required!",
                },
              ]}
            >
              <Select
                placeholder={`Please select a ${props.tagName} type`}
                // defaultValue={"Transcripts"}
                onChange={(value: number) => {
                  setStoryType(value);
                }}
              >
                <Select.Option value={1} disabled={props.storyType === StoryType.Reports? true : false}>{context.envId !== 2 ? "Transcripts" : "Notes"}</Select.Option>
                <Select.Option value={2} disabled={props.storyType === StoryType.Transcripts? true : false}>Reports</Select.Option>
              </Select>
            </Form.Item>)
            : null 
           }
          <div className="flex justify-end pt-3">
            <Button
              type="default"
              className="rounded-sm flex items-center"
              onClick={() => {
                if (props.handleCancel) props.handleCancel();
              }}
            >
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" className="green-button mx-3 rounded-sm flex items-center">
              {props.editData ? <>Edit {props.tagName}</> : <>Create {props.tagName}</>}
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

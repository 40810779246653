import { SettingsOutline } from "@styled-icons/evaicons-outline/SettingsOutline";
export default function GearIcon(props: { handleClick: () => void }) {
	return (
		<SettingsOutline
			title="Data Settings"
			onClick={() => props.handleClick()}
			size={24}
			style={{ color: "#32A29B" }}
		/>
	);
}

import { ResponsiveBar } from "@nivo/bar";
import * as ReportTypesComponentProps from "../../interfaces/main";
import transformData, { findMaxValue } from "./transform";
import "./style.css";
import millify from "millify";
import { millifyConfig } from "../../../../../GlobalThemes";
import { changeSecondsFormat, removeMinuteString } from "../ScatterPlot/chart";
const theme = (
	enableAxis?: boolean,
	axisWidth?: number,
	axisColor?: string,
	textColor?: string,
	background?: string,
	fontSize?: number
) => {
	return {
		background: background || "white",
		textColor: textColor || "black",
		fontSize: fontSize || 10,
		axis: {
			domain: {
				line: {
					stroke: enableAxis ? axisColor || "black" : "",
					strokeWidth: enableAxis
						? axisWidth !== undefined
							? axisWidth
							: 1
						: 0,
				},
			},
			textColor: axisColor || "black",
			tickColor: axisColor || "black",
			legend: {
				text: {
					fill: "#454250",
					fontSize: "0.875rem",
					fontWeight: 600,
				},
			},
			ticks: {
				text: {
					fill: "#454250",
					fontSize: "0.675rem",
					fontWeight: 600,
					// flexWrap: "wrap",
				},
			},
		},
		legends: {
			text: {
				fill: "#454250",
				fontSize: "0.7rem",
				fontWeight: 600,
				letterSpacing: "0.5px",
			},
		},
	};
};
const AreaLayer = (data: any, backgroundDivision?: 2 | 3 | 4) => {
	if (backgroundDivision && backgroundDivision === 2)
		return (
			<svg width="100%" height="100%">
				<defs>
					<linearGradient
						id="Gradient1a"
						x1="0%"
						x2="100%"
						y1="0%"
						y2="0%"
					>
						<stop offset="0%" stop-color="#F4F3F3" />
						<stop offset="50%" stop-color="#F4F3F3" />
						<stop offset="50%" stop-color="#82E6CF" />
						<stop offset="100%" stop-color="#82E6CF" />
					</linearGradient>
				</defs>
				<rect
					className="chartSvg2"
					style={{ fill: "url(#Gradient1a)" }}
					// x="50%"
					// y="-50%"
				/>
			</svg>
		);
	else if (backgroundDivision && backgroundDivision === 3) {
		if (data[1][0] < 33.33)
			return (
				<svg width="100%" height="100%">
					<defs>
						<linearGradient
							id="Gradient1b"
							x1="0%"
							x2="100%"
							y1="0%"
							y2="0%"
						>
							<stop offset="0%" stop-color="#E65428" />
							<stop offset="33.33%" stop-color="#E65428" />
							<stop offset="33.33%" stop-color="#DEDCDE" />
							<stop offset="66.66%" stop-color="#DEDCDE" />
							<stop offset="66.66%" stop-color="#B2B0B6" />
							<stop offset="100%" stop-color="#B2B0B6" />
						</linearGradient>
					</defs>
					<rect
						className="chartSvg2"
						style={{ fill: "url(#Gradient1b)" }}
						// x="50%"
						// y="-50%"
					/>
				</svg>
			);
		else if (data[1][0] < 66.66)
			return (
				<svg width="100%" height="100%">
					<defs>
						<linearGradient
							id="Gradient1c"
							x1="0%"
							x2="100%"
							y1="0%"
							y2="0%"
						>
							<stop offset="0%" stop-color="#DEDCDE" />
							<stop offset="33.33%" stop-color="#DEDCDE" />
							<stop offset="33.33%" stop-color="#FFB234" />
							<stop offset="66.66%" stop-color="#FFB234" />
							<stop offset="66.66%" stop-color="#B2B0B6" />
							<stop offset="100%" stop-color="#B2B0B6" />
						</linearGradient>
					</defs>
					<rect
						className="chartSvg2"
						style={{ fill: "url(#Gradient1c)" }}
						// x="50%"
						// y="-50%"
					/>
				</svg>
			);
		else
			return (
				<svg width="100%" height="100%">
					<defs>
						<linearGradient
							id="Gradient1"
							x1="0%"
							x2="100%"
							y1="0%"
							y2="0%"
						>
							<stop offset="0%" stop-color="#DEDCDE" />
							<stop offset="33.33%" stop-color="#DEDCDE" />
							<stop offset="33.33%" stop-color="#B2B0B6" />
							<stop offset="66.66%" stop-color="#B2B0B6" />
							<stop offset="66.66%" stop-color="#26C38D" />
							<stop offset="100%" stop-color="#26C38D" />
						</linearGradient>
					</defs>
					<rect
						className="chartSvg2"
						style={{ fill: "url(#Gradient1)" }}
						// x="50%"
						// y="-50%"
					/>
				</svg>
			);
	} else return <></>;
};

const customUnmetTooltip = (node: any, format: any) => {
	return (
		<div
			style={{
				color: "white",
				fontSize: "12px",
				maxWidth: "300px",
				background: "#333333ba",
				padding: "12px 16px",
			}}
		>
			<p>
				{format !== "stack" &&
				format !== "stack_percent" &&
				format !== "stack_mins"
					? removeMinuteString(node.data.id)
					: removeMinuteString(node.id)}
				:{" "}
				{format === "normal_mins" || format === "stack_mins"
					? changeSecondsFormat(node.value * 60)
					: Math.round(node.value * 10) / 10}
				{format === "unmet" || format === "stack_percent" ? "%" : ""}
			</p>
		</div>
	);
};

/**
 * Renders the Responsive Bar Chart Component. The component makes use of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the Bar Chart Component
 *
 * @returns Bar Chart Component of Nivo
 */
const BarChart: React.FC<ReportTypesComponentProps.Interface> = (
	props: ReportTypesComponentProps.Interface
): JSX.Element => (
	<>
		<ResponsiveBar
			onClick={(d, e) => {
				props.onBarSelect(d)
				console.log(d,e);
			}}
			data={transformData(
				props.data,
				props.series,
				props.axis?.primaryX?.dim || 0
			)}
			keys={props.series.map((s) => s.name)}
			theme={theme(
				props.axis?.primaryX?.enable ||
					props.axis?.primaryY?.enable !== undefined
					? props.axis?.primaryX?.enable ||
							props.axis?.primaryY?.enable
					: true,
				props.style?.axisWidth,
				props.style?.axisColor,
				props.style?.textColor,
				props.chartArea?.background,
				props.style?.fontSize
			)}
			layout={props.chartArea?.layout || "vertical"}
			layers={[
				() =>
					AreaLayer(props.data, props.chartArea?.backgroundDivision),
				"grid",
				"bars",
				"axes",
				"markers",
				"legends",
				"annotations",
			]}
			groupMode={props.chartConfig.cartesian?.groupMode || "stacked"}
			colors={(ob: { id: string | number; data: any }) =>
				String(ob.data[`${ob.id}Color`])
			}
			margin={
				props.style?.margin || {
					top: 20,
					right: 80,
					bottom: 50,
					left: 50,
				}
			}
			padding={props.chartConfig.cartesian?.gaps?.barGap || 0.1}
			innerPadding={props.chartConfig.cartesian?.gaps?.groupGap || 0}
			labelTextColor={props.style?.labelTextColor}
			labelSkipHeight={props.label?.skipSmallValue ? 20 : 0}
			labelSkipWidth={props.label?.skipWidth ? props.label?.skipWidth : 0}
			enableLabel={props.label ? true : false}
			label={props.label?.format}
			//label		to be added
			enableGridX={props.chartConfig.cartesian?.grid?.enableGridX}
			enableGridY={props.chartConfig.cartesian?.grid?.enableGridY}
			gridXValues={props.chartConfig.cartesian?.grid?.gridXValues}
			gridYValues={props.chartConfig.cartesian?.grid?.gridYValues}
			axisBottom={
				props.axis?.primaryX?.enable === false
					? null
					: props.axis?.primaryX
			}
			axisLeft={
				props.axis?.primaryY?.enable === false
					? null
					: {
							...props.axis?.primaryY,
							format: (value: any) =>
								typeof value === "number"
									? millify(Number(value), millifyConfig)
									: value,
					  }
			}
			valueScale={{
				type: props.axis?.primaryY?.scale || "linear",
			}}
			reverse={props.axis?.primaryY?.reverse}
			minValue={
				props.chartArea?.layout === "horizontal"
					? props.axis?.primaryX?.minValue || "auto"
					: props.axis?.primaryY?.minValue || "auto"
			}
			maxValue={
				props.chartArea?.layout === "horizontal"
					? props.axis?.primaryX?.maxValue || findMaxValue(props.data)
					: props.axis?.primaryY?.maxValue || findMaxValue(props.data)
			}
			isInteractive={props.tooltip ? true : false} // tooltip format to be added
			tooltip={(node) =>
				props.tooltip?.format === "unmet" ||
				props.tooltip?.format === "normal" ||
				props.tooltip?.format === "stack" ||
				props.tooltip?.format === "stack_percent" ||
				props.tooltip?.format === "normal_mins" ||
				props.tooltip?.format === "stack_mins"
					? customUnmetTooltip(node, props.tooltip?.format)
					: null
			}
			//valueFormat     to be added
			//defs     to be added for gradients
			animate={props.animate}
			borderRadius={props.style?.borderRadius || 0}
			borderWidth={props.style?.borderWidth || 0}
			legends={
				props.legend
					? [
							{
								anchor:
									props.legend?.position || "bottom-right",
								dataFrom: "keys",
								direction: props.legend?.direction || "column",
								itemHeight: 20,
								itemWidth: props.legend?.itemWidth || 140,
								toggleSerie: true,
								translateX: props.legend?.translateX || 120,
								translateY: props.legend?.translateY || 6,
								symbolShape: props.legend.symbolShape,
							},
					  ]
					: []
			}
		/>
	</>
);
export default BarChart;

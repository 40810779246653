import { DiscoverModuleTabs } from "../SearchEngine/data";
import SubNavigation from "../../Components/SubNavigation";
import { OldStoriesView } from "./OldStoriesView";

export default function OldStoriesPage() {
  const tabs = DiscoverModuleTabs();
  return (
    <>
      <SubNavigation tabs={tabs} />
      <OldStoriesView />
    </>
  );
}

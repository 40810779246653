import React from "react";
import { Button, Modal } from "antd";
import { icons } from "../../GlobalThemes";
// import { useNavigate } from "react-router-dom";

export default function DeleteAlert(props: {
  visible: boolean;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onYes?: any;
  setTitle?: string;
  selectedQuery?: string;
}) {
  return (
    <Modal
      destroyOnClose
      title={null}
      open={props.visible}
      footer={null}
      onCancel={() => {
        props.setVisible(false);
      }}
    >
      <div>
        <div className="py-5 px-4">
          <div className="flex items-center gap-4">
            <div>{icons.find((l) => l.id === "AlertCircle")?.icon}</div>
            <div className="text-2xl text-text-gray-100 font-normal my-4">Delete {props.setTitle}?</div>
          </div>
          <div className="text-base text-text-gray-100 ml-14">
            This action cannot be undone. Are you sure you want to delete <a className="font-medium italic">{props.selectedQuery}</a> ?
          </div>

          <div className="flex justify-end pt-4">
            <Button
              type="default"
              className="rounded-sm flex items-center"
              // className="bg-white text-text-gray-80 text-base px-2 py-1 border-2 rounded-sm"
              onClick={() => {
                props.setVisible(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              className="mx-3 rounded-sm flex items-center"
              // className="bg-text-teal text-white text-base border-text-teal border-2 px-2 py-1 mx-3 rounded-sm"
              onClick={() => {
                props.onYes();
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

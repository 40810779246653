import React from "react";
import BgImg from "./LandingBanner.jpg";
import AppContext from "../../../../store";

/**
 * This is the top banner which is rendered on the landing page
 *
 * @returns heading and description in a banner form on landing page
 */

export default function LandingBanner() {
	const context = React.useContext(AppContext);
	return (
		<div
			className="min-h-1/3 px-4 bg-banner text-text-gray bg-cover bg-right"
			style={{ backgroundImage: `url(${BgImg})`, minHeight: "25vh" }}
		>
			<div className="flex flex-col justify-center h-full w-1/2 py-8 pl-2">
				<h2 className="text-xl font-bold mb-4 text-text-gray">
					Knowledge Engine
				</h2>
				{context.envId === 2 ?
				<p className="font-normal font-base tracking-wide">
				Kural's engine uses generative AI to understand, interpret and synthesize conversations and expressions that are captured in transcripts, voice calls, or text documents. Kural enables users to go beyond traditional unstructured data and voice call analytics by allowing them to interact in real time & ask questions.
				</p>
				:
				<p className="font-normal font-base tracking-wide">
				Kural's engine uses generative AI to understand, interpret and synthesize conversations and expressions that are captured in transcripts, voice calls, or text documents. Kural enables users to go beyond traditional unstructured data and voice call analytics by allowing them to interact in real time & ask questions.
				<br />
				<br />
				Kural is still an AI intern and like all generative AI-based capabilities, she is meant to assist and enhance human capabilities, but her responses should never replace human judgment, expertise, or ethical considerations.
				</p>
				}
			</div>
		</div>
	);
}

// import * as SearchService from "../../../services/search";
import * as FindSimilarConversaionsService from "../../../services/findSimilarConversations";

export const getAllStories: (obj: {
	storyId?: number;
	studyId?: number | string[] | undefined;
	speaker?: string | undefined;
	startDate?: any;
	endDate?: any;
	// showResults?: boolean | undefined;
}) => Promise<unknown> = async (obj: {
	storyId?: number;
	studyId?: number | string[] | undefined;
	speaker?: string | undefined;
	startDate?: any;
	endDate?: any;
	// showResults?: boolean | undefined;
}) => {
  const data = await FindSimilarConversaionsService.getAllStories(obj);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getStoryStatus: (storyId: number, storyType?: number) => Promise<unknown> = async (
	storyId,
	storyType,
  ) => {
	const data = await FindSimilarConversaionsService.getStoryStatus(storyId, storyType);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
  };

// export const addToStory: (
// 	transcriptData: any,
// 	storyId: string
// ) => Promise<unknown> = async (transcriptData, storyId) => {
// 	const data = await SearchService.addToStory(transcriptData, storyId);
// 	// console.log("api", data);
// 	if (data === null || data === undefined) return null;
// 	return data;
// };

// NodeGraph.tsx
import React from "react";
import Tree, { NodeSvgShape } from "react-d3-tree";
import { linkHorizontal, linkVertical } from "d3-shape";
import { Transcripts } from "../../../../Components/NodeGraphTranscripts";
import "../../styles.css";
import {
  PlusOutlined,
  MinusOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { message } from 'antd';
import * as NodeGraphServiceHelper from "../../../SearchEngine/ServiceHelpers/nodeGraph";


const svgRect: NodeSvgShape = {
  shape: "rect",
  shapeProps: {
    width: 0,
    height: 0,
    x: -20,
    y: 20,
    stroke: "#2F80ED",
  },
};

const NodeLabel: React.FC<any> = ({ className, nodeData, selectedNode }) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [hovered, setHovered] = React.useState(false);

  const hasChildren = nodeData.children && nodeData.children.length > 0;
  const hoverText = nodeData.description ? nodeData.description : null; // Generate the text to be displayed on hover
  const isLeafNode = nodeData.leaf_node === "true";
  const isEmergingLeafNode = nodeData.leaf_node === "true" && nodeData.color_code === "emerging";

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleToggleCollapse = () => {
    // console.log("button state:" + collapsed)

    if (collapsed)
      setCollapsed(false);
    else
      setCollapsed(true);

    // console.log("updated button state:" + collapsed)
  };

  const maxWords = isLeafNode ? 5 : 8;
  const nodeName = nodeData.name;
  const truncatedNodeName =
    nodeName && nodeName.split(" ").length > maxWords
      ? `${nodeName.split(" ").slice(0, maxWords).join(" ")}...`
      : nodeName;

  const depth = nodeData.depth || 0;
  const backgroundColor = isEmergingLeafNode 
    ? "#FF8C8B" 
    : isLeafNode
    ? "white"
    : depth === 0
    ? "#F9F0FF"
    : depth === 1
    ? "#eff8fe"
    : "#E6F7FF";
  const color = isLeafNode
    ? "black"
    : depth === 0
    ? "#722ED1"
    : depth === 1
    ? "#09558C"
    : "#1890FF";
  const nodeHeight = isLeafNode ? 30 : 40;
  const nodeFontWeight = isLeafNode ? 400 : 600;

  return (
    <div
      // className={className + " node-container"}
      className={className + ` node-container ${selectedNode && selectedNode.id === nodeData.id_kural ? isEmergingLeafNode ? 'emerging-leaf-selected-node' : isLeafNode ? 'leaf-selected-node' : 'selected-node' : ''}`}
      style={{
        backgroundColor,
        height: nodeHeight,
        fontWeight: nodeFontWeight,
      }}
      onClick={handleToggleCollapse}
    >
      {/* {console.log("------------------------------ selectedNode nodeData.id", selectedNode, nodeData)} */}
      <div className="node-name" style={{ color }} title={nodeName}>
        {truncatedNodeName}
      </div>
      {!isLeafNode && (
        <>
          <div className="node-button" onClick={handleToggleCollapse}>
            <button
              // disabled={!hasChildren} 
              style={{
                height: "15px",
                width: "15px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
                border: "1px solid #2D8B93",
                borderRadius: "2px",
                boxShadow: "0px 1px 2px 0px #00000040",
              }}
            >
              
              {nodeData.depth != 0 ? (
                collapsed ? (
                  <MinusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
                 ) : (
                <PlusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
              )) : ( 
              nodeData.depth == 0 ? (
                <PlusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
              ) : (
                <MinusOutlined style={{ fontSize: "10px", color: "#2D8B93" }} />
              ))}
              
            </button>
          </div>
          {/* <div
            className="info-icon"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <InfoCircleOutlined
              style={{ fontSize: "12px", color: "#2D8B93" }}
            />
            {hovered && <div className="info-text">{hoverText}</div>}
          </div> */}
        </>
      )}
      {isLeafNode && (
        <div className="node-number-box">
          <span>{nodeData.no_of_transcripts}</span>
        </div>
      )}
    </div>
  );
};

const drawDiagonalPath = (linkData: any, orientation: any) => {
  const { source, target } = linkData;
  return orientation === "horizontal"
    ? linkHorizontal()({
        source: [source.y, source.x - 20],
        target: [target.y - 180, target.x - 20],
      })
    : linkVertical()({
        source: [source.x, source.y],
        target: [target.x, target.y],
      });
};

interface NodeGraphProps {
  data: any;
  selectedStudyDetails: any;
}

const ReadMore: React.FC<{ children: string }> = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = React.useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const wordLimit = 840;

  return (
    <p className="pt-2">
      {text.length > wordLimit ? isReadMore ? text.slice(0, wordLimit) + "..." : text : text}
      {text.length > wordLimit && (
        <span onClick={toggleReadMore} className="text-text-teal font-normal cursor-pointer">
          {isReadMore ? <>Show more</> : <>Show less</>}
        </span>
      )}
    </p>
  );
};

const NodeGraph: React.FC<NodeGraphProps> = ({ data, selectedStudyDetails }) => {
  interface SelectedNode {
    id: string;
    name: string;
    summary: string;
  }

  interface TranscriptPayload {
    nodeId: string;
    studyId: number;
    waveId: number;
    geoId: number;
    studyIterId: number;
  }

  // console.log("Node Graph Props Selected Study Data -> ", selectedStudyDetails);

  const rootChildren = data.children; // Get the children of the root node
  const [showSummary, setShowSummary] = React.useState(false);
  const [transcriptDataArray, setTranscriptDataArray] = React.useState<any[]>([]);

  // OLD HARDCODED DATA FOR DEMO PURPOSES
  // const [transcriptDataArray, setTranscriptDataArray] = React.useState<any[]>([
  //   {
  //     "id": 123,
  //     "name": "RA Market Landscape/Q1-2022/US/Rheumatologist Interview_1_6.20.2022",
  //     "tags": [],
  //     "storiesAdded": [],
  //     "source": 0,
  //     "studyId": 1,
  //     "studyName": "RA Market Landscape",
  //     "geography": "US",
  //     "wave": "Q1-2022",
  //     "fileName": "Rheumatologist Interview_1_6.20.2022",
  //     "dialogueNo": 638,
  //     "callId": null,
  //     "callDate": "2022-01-06T00:00:00",
  //     "callTime": null,
  //     "dialogueAppearedAt": 0,
  //     "dialogueLength": 0,
  //     "transcriptLength": 0,
  //     "s3path": "kural/processed_files/0136NJ4256_Rheumatologist Interview_1_6.20.2022.json",
  //     "addedToStory": false,
  //     "score": 0.0000145350204547867,
  //     "patientId": 0,
  //     "speaker": "interviewer",
  //     "context": "[{'speaker': 'interviewer',\n     'dialogueNo': 471,\n      'text': \"Okay, I understand. Thank you. And so if you have somebody who is anti-CCP positive with an RA diagnosis, to what extent would that impact your treatment decisions for that patient?\"},\n       {'speaker': 'respondent',\n        'dialogueNo': 472,\n         'text': \"I should mention in our lab we have strongly positive results where the CCP is greater than 250. We have moderately strong results where it's somewhere between I think 40 and 249, and then less than 40 is a mildly positive. And so we if you are strongly positive, then it sets off a little bit of a radar that okay, we really need to watch this patient closer, possibly be more aggressive. But at the end of the day, there's even severe seronegative patients I have, you always want to look at the clinical exam, listen to the patient, look at the physician global assessment, the joint count, how many joints are swollen and tender because at the end of the day the patient's symptoms and their  is more important than anything else.\"}]",
  //     "elasticSearchId": "25_638",
  //     "transcripts": [
  //       {
  //         "speaker": "interviewer",
  //         "text": "Okay, I understand. Thank you. And so if you have somebody who is anti-CCP positive with an RA diagnosis, to what extent would that impact your treatment decisions for that patient?",
  //         "dialogueNo": 471
  //       },
  //       {
  //         "speaker": "respondent",
  //         "text": "I should mention in our lab we have strongly positive results where the CCP is greater than 250. We have moderately strong results where it's somewhere between I think 40 and 249, and then less than 40 is a mildly positive. And so we if you are strongly positive, then it sets off a little bit of a radar that okay, we really need to watch this patient closer, possibly be more aggressive. But at the end of the day, there's even severe seronegative patients I have, you always want to look at the clinical exam, listen to the patient, look at the physician global assessment, the joint count, how many joints are swollen and tender because at the end of the day the patient's symptoms and their  is more important than anything else.",
  //         "dialogueNo": 472
  //       }
  //     ],
  //     "callTypeId": null,
  //     "storyDialogues": [],
  //     "therapyStatusId": null,
  //     "notAddedStories": []
  //   },
  //   {
  //     "id": 123,
  //     "name": "RA Market Landscape/Q1-2022/US/Rheumatologist Interview_2_11.20.2022",
  //     "tags": [],
  //     "storiesAdded": [],
  //     "source": 0,
  //     "studyId": 1,
  //     "studyName": "RA Market Landscape",
  //     "geography": "US",
  //     "wave": "Q1-2022",
  //     "fileName": "Rheumatologist Interview_2_11.20.2022",
  //     "dialogueNo": 638,
  //     "callId": null,
  //     "callDate": "2022-02-11T00:00:00",
  //     "callTime": null,
  //     "dialogueAppearedAt": 0,
  //     "dialogueLength": 0,
  //     "transcriptLength": 0,
  //     "s3path": "kural/processed_files/Rheumatologist Interview_2_11.20.2022.json",
  //     "addedToStory": false,
  //     "score": 0.0000145350204547867,
  //     "patientId": 0,
  //     "speaker": "interviewer",
  //     "context": "[{'speaker': 'interviewer',\n     'dialogueNo': 471,\n      'text': \"Cool. Any particular difficulties with rheumatoid arthritis, whether it be patients, treatment decisions, anything like that?\"},\n       {'speaker': 'respondent',\n        'dialogueNo': 472,\n         'text': \"Sure. Certainly. Some just don't respond the way you want them to.  Some of the medications are hard to access and patients can't get what they need. And then just the morbidity that's associated with it, the deformities and other extraticular issues that occur with it.\"}]",
  //     "elasticSearchId": "25_638",
  //     "transcripts": [
  //       {
  //         "speaker": "interviewer",
  //         "text": "Cool. Any particular difficulties with rheumatoid arthritis, whether it be patients, treatment decisions, anything like that?",
  //         "dialogueNo": 471
  //       },
  //       {
  //         "speaker": "respondent",
  //         "text": "Sure. Certainly. Some just don't respond the way you want them to.  Some of the medications are hard to access and patients can't get what they need. And then just the morbidity that's associated with it, the deformities and other extraticular issues that occur with it.",
  //         "dialogueNo": 472
  //       }
  //     ],
  //     "callTypeId": null,
  //     "storyDialogues": [],
  //     "therapyStatusId": null,
  //     "notAddedStories": []
  //   }
  // ]);
  const [selectedNode, setSelectedNode] = React.useState<SelectedNode | null>(
    null
  );

  // API Request to fetch NG Node Transcript Data
  async function fetchTranscriptData() {
    // const infoMessage = message.info("Fetching Transcript data...");
    try {
      if (!selectedNode) {
        // console.log("No selected node to fetch transcript data.");
        return;
      }
      
      const query: TranscriptPayload = {
        nodeId: selectedNode.id,
        studyId: parseInt(selectedStudyDetails.studyId, 10),
        waveId: parseInt(selectedStudyDetails.waveId, 10),
        geoId: parseInt(selectedStudyDetails.regionId, 10),
        studyIterId: selectedStudyDetails.studyIterId,
      };

      const data: any = await NodeGraphServiceHelper.getTranscriptDetailsForNode(query);

      if (data && data.searchResults) {
        setTranscriptDataArray(data.searchResults);
        // infoMessage();
        //message.success("Successfully fetched Transcript Data");
        // console.log("Fetched Transcript Data -> ", data);
      } else {
        // infoMessage();
        message.error("No Transcript Data found");
        console.log("Fetch Transcript Data API returned empty data or missing searchResults.");
      }
    } catch (error) {
      // infoMessage();
      message.error("Error fetching Transcript Data");
      console.error("Error fetching Transcript Data:", error);
    }
  }
  
  React.useEffect(() => {
    fetchTranscriptData();    
  }, [selectedNode]);

  const handleOnClickNode = (nodeData: any) => {
    // console.log("Node Clicked.", nodeData.summary);
    if (nodeData.summary) {
      const newNode: SelectedNode = {
        id: nodeData.id_kural,
        name: nodeData.name,
        summary: nodeData.summary,
      };
      setSelectedNode(newNode);
      // console.log("Selected Node Info -> ", selectedNode);

      setShowSummary(true); // Show the summary on node click
    } else {
      setShowSummary(false); // Hide the summary on node click
      setSelectedNode(null);
    }
  };

  return (
    <div id="treeWrapper" style={{ width: "100%", height: "75vh", overflowY:"auto" }}>
      <Tree
        data={data}
        nodeSvgShape={svgRect}
        pathFunc={drawDiagonalPath}
        separation={{ siblings: 0.5, nonSiblings: 0.5 }}
        orientation="horizontal"
        translate={{ x: 200, y: 300 }}
        allowForeignObjects={true}
        nodeLabelComponent={{
          render: 
            <NodeLabel 
              className="myLabelComponentInSvg" 
              selectedNode={selectedNode} 
            />,
          foreignObjectWrapper: {
            width: 200,
            height: 55,
            y: -50,
            x: -190,
          },
        }}
        initialDepth={0.02}
        depthFactor={350}
        shouldCollapseNeighborNodes={false}
        onClick={handleOnClickNode}
      />

      {/* Bottom Summary and Transcript Footer component */}
      {showSummary && selectedNode && (
        <div className="node-summary" >
          <div className="node-summary-header">
            <span>{selectedNode.name}</span>
          </div>

          <div className="node-summary-divider" /> {/* Add a divider line using CSS border */}
          
          <div className="node-summary-body">
            <span className="font-semibold text-xs">Summary:</span><br />
            <span className="text-xs/3">
              <ReadMore>{selectedNode.summary}</ReadMore>
            </span>
          </div>

          <div className="node-summary-divider" /> {/* Add a divider line using CSS border */}
          
          <div className="node-summary-body">
            <span className="font-semibold text-xs">References:</span><br />
          </div>

          {/* <div>
            <Transcripts
              data={transcriptData}
            />
          </div> */}

          {transcriptDataArray.map((transcriptData, index) => (
            <div key={index}>
              <Transcripts
                data={transcriptData}
              />
            </div>
          ))}

        </div>

      )}
    </div>
  );
};

export default NodeGraph;

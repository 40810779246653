import { Skeleton, Tag } from "antd";
import { icons } from "../../../GlobalThemes";
import { useEffect, useState } from "react";

/**
 * It is a wrapper of transcript list with result summary toggle and header of search page
 *
 * @param header - The props get the header of the search tab
 * @param noSummary - The props represent if summary is present
 * @param expanded - The props represent if more information on transcript card is visible
 * @param toggleExpand - The function is used to change the expanded property
 * @param toggleSummary - The function is used to change the result summary view
 * @param children - The props contain the children of the wrapper, such as Trancript card
 * @param showSummary - The props represent if summary is visible
 * @param loading - The props represent if page is loading and will render the skeleton in place of the data
 *
 * @returns The transcrpt wrapper with the list of transcripts in it
 */

export default function TranscriptsWrapper(props: {
	header: string;
	subHeader?: string;
	noSummary?: boolean;
	expanded?: boolean;
	toggleScrollToTop?: any;
	toggleSummary?: React.Dispatch<React.SetStateAction<boolean>>;
	children?: JSX.Element;
	showSummary?: boolean;
	loading?: boolean;
	noSideSummary?: boolean;
	goodSearch?: any;
	badSearch?: any;
	averageSearch?: any;
	showKeywords?: boolean;
	hideSummaryTags?: boolean;
	headerEditCallback?: string | undefined | any;
}) {
	const [edit, setEdit] = useState(false);
	// console.log("HEADER VALUE ", props.header); Commenting

	const [headerValue, setHeaderValue] = useState(props.header);

	const handleEdit = () => {
		setEdit(true);
	};
	useEffect(() => {
		if (props.header !== headerValue) {
			setHeaderValue(props.header);
		}
	}, [props.header]);

	const handleSave = () => {
		//setHeaderValue(textValue);
		props.headerEditCallback(headerValue);
		setEdit(false);
	};
	const handleCancel = () => {
		setHeaderValue(props.header);
		setEdit(false);
	};

	return (
		<>
			{!props.loading ? (
				<>
					{props.header && (
						<div>
							{!props.noSideSummary && (
								<div className="py-4 px-4 border-b">
									<div className="flex justify-between">
										<p
											className="font-semibold text-base flex"
											style={{ color: "#716E79" }}>
											<>
												{!edit ? (
													<>
														{headerValue}
														<div
															className="edit-icon ml-1"
															id="edit-icon"
															onClick={
																handleEdit
															}>
															{
																icons.find(
																	(l) =>
																		l.id ===
																		"Edit"
																)?.icon
															}
														</div>
													</>
												) : (
													<div className="edit-text align-center flex">
														<input
															id="topicInput"
															type="text"
															value={headerValue}
															onChange={(e) => {
																setHeaderValue(
																	e.target
																		.value
																);
															}}
														/>
														<div className="flex">
															<div
																id="save"
																onClick={
																	handleSave
																}>
																{
																	icons.find(
																		(l) =>
																			l.id ===
																			"Complete"
																	)?.icon
																}
															</div>
															<div
																className="close"
																id="close"
																onClick={
																	handleCancel
																}>
																{
																	icons.find(
																		(l) =>
																			l.id ===
																			"Remove"
																	)?.icon
																}
															</div>
														</div>
													</div>
												)}
											</>

											{!props.hideSummaryTags &&
												props.goodSearch && (
													<span className="ml-4 font-medium">
														<>
															<Tag color="green">
																High :{" "}
																{
																	props.goodSearch
																}
															</Tag>
															<Tag color="yellow">
																Medium :{" "}
																{
																	props.averageSearch
																}
															</Tag>
															<Tag color="red">
																Low :{" "}
																{
																	props.badSearch
																}
															</Tag>
														</>
													</span>
												)}
										</p>

										{!props.noSideSummary && (
											<div className="flex">
												<div
													style={{ color: "#27A6A4" }}
													className="flex cursor-pointer scroll-btn"
													onClick={() => {
														if (
															props.toggleScrollToTop
														)
															props.toggleScrollToTop();
													}}>
													{
														icons.find(
															(l) =>
																l.id ===
																"ArrowUp"
														)?.icon
													}
													{"Scroll To Top"}
												</div>
											</div>
										)}
									</div>
								</div>
							)}
							<div>{props.children}</div>
						</div>
					)}
				</>
			) : (
				<>
					<div className="py-3 px-8 border-b">
						<div className="flex justify-between">
							<div className="w-64">
								<Skeleton.Button
									active
									size="default"
									block={true}
								/>
							</div>
							<div className="flex">
								<div className="w-80">
									<Skeleton.Button
										active
										size="default"
										block={true}
									/>
								</div>
							</div>
						</div>
					</div>
					<div>{props.children}</div>
				</>
			)}
		</>
	);
}

import { Select, List, Skeleton, Empty, Switch, Checkbox } from "antd";
import { Transcripts } from "./Transcripts";
import { ResponsiveLine } from "@nivo/line";
import { icons } from "../../GlobalThemes";
import React, { useState, useEffect, useRef, useContext } from "react";
import { FilterInterface } from "../Dashboard";
import { Drawer as AntDrawer } from "antd";
import Drawer from "../Dashboard/Components/Drawer";
import { FilterCard } from "../../Components/FilterCard";
import SummaryCard from "../../Components/SummaryCard/SummaryCard";
import { ResponsiveChoropleth } from "@nivo/geo";
import { usFeatures } from "../../libraries/report-types/charts/geoMap/geoUSFeatures";
import TranscriptsWrapper from "./TransciptsWrapper";
import * as ExploreServiceHelper from "./../SearchEngine/ServiceHelpers/explore";
import * as OldStoriesServiceHelper from "./../SearchEngine/ServiceHelpers/oldStories";
import { searchFilterActiveStatus, transcriptStoriesDummyData, transcrtiptFilters, nodeGraphTranscriptData } from "../SearchEngine/data";
import { NodeGraphFilters } from "./NodeGraphFilters";
import * as SearchServiceHelper from "./../SearchEngine/ServiceHelpers/search";
import moment from "moment";
import * as Messages from "../../Components/Messages";
import AppContext from "../../store";
import { ResponsiveBar } from "@nivo/bar";
import NodeGraphCanvas from "../../Components/NodeGraphCanvas";
// import "tippy.js/dist/tippy.css";
// import "tippy.js/animations/scale.css";
// import * as StoriesServiceHelper from "./../SearchEngine/ServiceHelpers/stories";
// import * as NodeGraphServiceHelper from "./../SearchEngine/ServiceHelpers/nodeGraph";

export interface searchQuery {
	deepSearch?: boolean;
	testButton1?: boolean;
	testButton2?: boolean;
	testButton3?: boolean;
	testButton4?: boolean;
	testButton5?: boolean;
	searchWord?: string;
	startDate?: string | any;
	endDate?: string | any;
	speaker?: string;
	showResults?: boolean;
	storyId?: number;
	callTypeIds?: number[];
	therapyStatusIds?: number[];
	emotions?: number[];
	emotionsGroup?: number[];
	sentiment?: number[];
	topics?: number[];
	totStart?: string;
	totEnd?: string;
}

export interface transcriptDataInter {
	numberOfPatients: number;
	summary: string;
	numberOfSearchResults: number;
	emotionsCounts?: any;
	statesResults: any;
	searchResults: {
		id: number;
		s3path: string;
		callDate: string;
		callId: string;
		callTime: string;
		callTypeIds: string;
		therapyStatusIds: string;
		dialogueNo: number;
		name: string;
		tags: string[];
		dialogueAppearedAt: number;
		dialogueLength: number;
		transcriptLength: number;
		addedToStory: boolean;
		storiesAdded: {
			storyId: any;
			storyName: string;
		}[];
		patientId: number;
		speaker: string;
		elasticSearchId: string;
		transcripts: {
			dialogueNo: number;
			speaker: string;
			text: string;
		}[];
		storyDialogues: any;
	}[];
}

export interface filterValues {
	experimentId: number;
	clusterId?: number;
	startDate?: string;
	endDate?: string;
	speaker?: string;
	topic?: string;
	totStart?: string;
	totEnd?: string;
	callTypeId?: string;
	therapyStatusId?: string;
	sentiment_id?: string;
	emotion_id?: string[];
}

/**
 * This page is the main search tab in Search Engine Page. This page renders searched clusters and transcripts in a list.
 *
 * @returns Node Graph and List of Searched Transcripts.
 */

export function NodeGraph() {
	const app = useContext(AppContext);
	const [fetchedData, setFetchedData] = React.useState<any>(null);
	const [showResultSummary, setShowResultSummary] = useState<boolean>(false);
	const [expanded, setExpanded] = React.useState(false);
	const [visible, setVisible] = React.useState(false);
	const [transcriptData, setTranscriptData] = useState<any>([]);
	const dataCopy = React.useRef<any>([]);
	const [pinnedStories, setPinnedStories] = useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);
	const [transcriptLoading, setTranscriptLoading] = useState<boolean>(false);
	const [searchTranscriptQuery, setSearchTranscriptQuery] = useState<searchQuery>({});
	const [searchWordRequired, setsearchWordRequired] = useState<boolean>(false);
	const [selectLevel, setSelectLevel] = useState<any>();
	const [therapyStatus, setTherapyStatus] = useState<any>();
	const [emptyTextField, setEmptyTextField] = useState("Enter a search word to see results");
	const [searchString, setSearchString] = React.useState("");
	const [nodeGraphData, setNodeGraphData] = React.useState<any>(null);
	const [pageData, setPageData] = React.useState<any>();
	const [selectedNode, setSelectedNode] = React.useState<any>(null);
	const [updatedNode, setUpdatedNode] = React.useState<any>(null);
	const [keywords, setKeywords] = React.useState<string[] | undefined>();
	const [path, setPath] = React.useState<any>("kural/json_data/srch_node_graph.txt");
	const [response, setResponse] = useState({
		data: {
			nodes: [],
			link: [],
		},
	});
	// const [searchFilterData, setSearchFilterData] = useState<any>();
	var filterData = {
		startDate: "",
		endDate: "",
		speaker: "",
		searchWord: "",
	};
	var dateFormat = "MMM YYYY";
	const [searchFilterData, setSearchFilterData] = useState<searchQuery>(filterData);
	const data: any = [
		{
			transcriptStoriesDummyData: [
				{
					id: "story_98",
					"Story Name": "insurance",
					"Date Created": "30 Sep 2022",
					"Story Type": "General Tracker",
					"# of Annotated Selections": 2,
					Status: "In Draft",
					index: 11,
				},
				{
					id: "story_97",
					"Story Name": "alcohol",
					"Date Created": "30 Sep 2022",
					"Story Type": "General Tracker",
					"# of Annotated Selections": 18,
					Status: "In Draft",
					index: 12,
				},
			],
		},
		{
			transcriptFilters: [
				{
					optionsObject: [
						{
							id: "1",
							name: "Inbound",
							subgroup: [],
						},
						{
							id: "2",
							name: "Outbound",
							subgroup: [],
						},
					],
					filter: "Call_Type",
					type: "select",
				},
				{
					optionsObject: [
						{
							id: "1",
							name: "ON THERAPY",
							subgroup: [],
						},
						{
							id: "2",
							name: "DISCONTINUED",
							subgroup: [],
						},
						{
							id: "3",
							name: "NEVER START",
							subgroup: [],
						},
						{
							id: "-1",
							name: "UNKNOWN",
							subgroup: [],
						},
						{
							id: "4",
							name: "IN PROCESS",
							subgroup: [],
						},
						{
							id: "5",
							name: "ON THERAPY REPEAT",
							subgroup: [],
						},
					],
					filter: "Therapy_Status",
					type: "select",
				},
				{
					optionsObject: [
						{
							id: "1",
							name: "positive",
							subgroup: [],
						},
						{
							id: "2",
							name: "negative",
							subgroup: [],
						},
						{
							id: "3",
							name: "neutral",
							subgroup: [],
						},
					],
					filter: "Sentiment",
					type: "select",
				},
			],
		},
		{
			nodeGraphTranscriptData: {
				transcript_data: [
					{
						topicId: 0,
						topicName: "denied claim lectrazine",
						numberOfSearchResults: 3,
						searchResults: [
							{
								searchId: 1,
								name: "Transcript from 25 May 2022 [20:15:09]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 4,
								callId: "O1388T9ZZ5BCLGTD",
								callDate: "2022-05-25",
								callTime: "20:15:09",
								dialogueAppearedAt: 16.1,
								dialogueLength: 15.18,
								transcriptLength: 75.44,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 2,
										text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
										speaker: "Patient",
									},
									{
										dialogueNo: 3,
										text: "i'm sorry to hear that. can you tell me what happened ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 4,
										text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
										speaker: "Patient",
									},
									{
										dialogueNo: 5,
										text: "that doesn't sound right. let me see if i can help you figure this out.",
										speaker: "Agent",
									},
									{
										dialogueNo: 6,
										text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
								elasticSearchId: "O1388T9ZZ5BCLGTD_4",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
										highLights: ["lectrazine"],
										dialogueNo: 2,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. can you tell me what happened ?",
										highLights: [],
										dialogueNo: 3,
									},
									{
										speaker: "Patient",
										text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
										highLights: ["lectrazine"],
										dialogueNo: 4,
									},
									{
										speaker: "Agent",
										text: "that doesn't sound right. let me see if i can help you figure this out.",
										highLights: [],
										dialogueNo: 5,
									},
									{
										speaker: "Patient",
										text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
										highLights: [],
										dialogueNo: 6,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 2,
								name: "Transcript from 09 May 2022 [21:20:36]",
								tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 3,
								callId: "WW8PUBYMYJOW4S8N",
								callDate: "2022-05-09",
								callTime: "21:20:36",
								dialogueAppearedAt: 39.56,
								dialogueLength: 11.5,
								transcriptLength: 118.68,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
										speaker: "Patient",
									},
									{
										dialogueNo: 4,
										text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
										speaker: "Agent",
									},
									{
										dialogueNo: 5,
										text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
								elasticSearchId: "WW8PUBYMYJOW4S8N_3",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
										highLights: ["lectrazine"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
										highLights: ["lectrazine"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
										highLights: ["lectrazine"],
										dialogueNo: 3,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
										highLights: ["lectrazine"],
										dialogueNo: 4,
									},
									{
										speaker: "Patient",
										text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
										highLights: [],
										dialogueNo: 5,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 3,
								name: "Transcript from 25 May 2022 [15:30:20]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 2,
								callId: "F1T9KO8WBYD609GF",
								callDate: "2022-05-25",
								callTime: "15:30:20",
								dialogueAppearedAt: 3.68,
								dialogueLength: 6.44,
								transcriptLength: 119.14,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "hi, how can i help you ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 2,
										text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
										speaker: "Patient",
									},
									{
										dialogueNo: 3,
										text: "i'm sorry to hear that. can you tell me what happened ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 4,
										text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
								elasticSearchId: "F1T9KO8WBYD609GF_2",
								transcripts: [
									{
										speaker: "Agent",
										text: "hi, how can i help you ?",
										highLights: [],
										dialogueNo: 1,
									},
									{
										speaker: "Patient",
										text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
										highLights: ["lectrazine"],
										dialogueNo: 2,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. can you tell me what happened ?",
										highLights: [],
										dialogueNo: 3,
									},
									{
										speaker: "Patient",
										text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
										highLights: [],
										dialogueNo: 4,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
						],
						emotionsCounts: [
							{
								text: "headaches",
								value: 5711,
							},
							{
								text: "slight headache",
								value: 1130,
							},
						],
						headers: {
							"x-time": {
								query_expansion_time: 0.23832941055297852,
								retriever_time: 0.645418643951416,
								ranker_time: 0.7286033630371094,
								df_prepare_time: 0.008182287216186523,
								process_time: 1.665311574935913,
							},
							"x-search-config": {
								query_type: "keyword",
								expanded_query:
									"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
							},
							"x-auth-time": "0.3819572925567627",
							"x-process-time": "2.050896167755127",
						},
					},
					{
						topicId: 1,
						topicName: "feeling really anxious",
						numberOfSearchResults: 3,
						searchResults: [
							{
								searchId: 1,
								name: "Transcript from 17 May 2022 [11:44:17]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 3,
								callId: "U27WXBUWZB8TNX14",
								callDate: "2022-05-17",
								callTime: "11:44:17",
								dialogueAppearedAt: 18.4,
								dialogueLength: 16.1,
								transcriptLength: 104.88,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
										speaker: "Patient",
									},
									{
										dialogueNo: 4,
										text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
										speaker: "Agent",
									},
									{
										dialogueNo: 5,
										text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
								elasticSearchId: "U27WXBUWZB8TNX14_3",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
										highLights: ["calls", "feel", "getting", "time"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
										highLights: ["feel", "feeling"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
										highLights: ["call", "getting", "time"],
										dialogueNo: 3,
									},
									{
										speaker: "Agent",
										text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
										highLights: ["feel", "getting"],
										dialogueNo: 4,
									},
									{
										speaker: "Patient",
										text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
										highLights: ["calling", "feel"],
										dialogueNo: 5,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 2,
								name: "Transcript from 23 May 2022 [14:15:27]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 6,
								callId: "2VN0YRE1QMBTAEUC",
								callDate: "2022-05-23",
								callTime: "14:15:27",
								dialogueAppearedAt: 64.4,
								dialogueLength: 15.64,
								transcriptLength: 138.46,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [
									{
										storyId: "story_13",
										storyName: "dummy2",
									},
								],
								addedToStory: "true",
								context: [
									{
										dialogueNo: 4,
										text: "i ordered it from the company's website. they said it would take about a week to ship, but it's already been almost two weeks.",
										speaker: "Patient",
									},
									{
										dialogueNo: 5,
										text: "well, it's possible that there was a delay in shipping. have you contacted the company to see if they can give you any information about where your order is ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 6,
										text: "yeah, i've tried to call them a few times, but i can't get through to anyone. i've also sent them a few emails, but i haven't gotten any response.",
										speaker: "Patient",
									},
									{
										dialogueNo: 7,
										text: "well, it's possible that they're just very busy and haven't been able to get back to you yet. i would suggest trying to contact them again, and if you still can't get through, you can try to reach out to their customer service department.",
										speaker: "Agent",
									},
									{
										dialogueNo: 8,
										text: "yeah, i'll try that. i'm just really worried that something might have happened to my medication. i need it to manage my condition.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/2VN0YRE1QMBTAEUC.json",
								elasticSearchId: "2VN0YRE1QMBTAEUC_6",
								transcripts: [
									{
										speaker: "Patient",
										text: "i ordered it from the company's website. they said it would take about a week to ship, but it's already been almost two weeks.",
										highLights: [],
										dialogueNo: 4,
									},
									{
										speaker: "Agent",
										text: "well, it's possible that there was a delay in shipping. have you contacted the company to see if they can give you any information about where your order is ?",
										highLights: [],
										dialogueNo: 5,
									},
									{
										speaker: "Patient",
										text: "yeah, i've tried to call them a few times, but i can't get through to anyone. i've also sent them a few emails, but i haven't gotten any response.",
										highLights: ["call", "get", "gotten", "times"],
										dialogueNo: 6,
									},
									{
										speaker: "Agent",
										text: "well, it's possible that they're just very busy and haven't been able to get back to you yet. i would suggest trying to contact them again, and if you still can't get through, you can try to reach out to their customer service department.",
										highLights: ["get"],
										dialogueNo: 7,
									},
									{
										speaker: "Patient",
										text: "yeah, i'll try that. i'm just really worried that something might have happened to my medication. i need it to manage my condition.",
										highLights: [],
										dialogueNo: 8,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 3,
								name: "Transcript from 17 May 2022 [11:44:17]",
								tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 5,
								callId: "U27WXBUWZB8TNX14",
								callDate: "2022-05-17",
								callTime: "11:44:17",
								dialogueAppearedAt: 49.22,
								dialogueLength: 11.04,
								transcriptLength: 104.88,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 3,
										text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
										speaker: "Patient",
									},
									{
										dialogueNo: 4,
										text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
										speaker: "Agent",
									},
									{
										dialogueNo: 5,
										text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
										speaker: "Patient",
									},
									{
										dialogueNo: 6,
										text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 7,
										text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
								elasticSearchId: "U27WXBUWZB8TNX14_5",
								transcripts: [
									{
										speaker: "Patient",
										text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
										highLights: ["call", "getting", "time"],
										dialogueNo: 3,
									},
									{
										speaker: "Agent",
										text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
										highLights: ["feel", "getting"],
										dialogueNo: 4,
									},
									{
										speaker: "Patient",
										text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
										highLights: ["calling", "feel"],
										dialogueNo: 5,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
										highLights: ["calls"],
										dialogueNo: 6,
									},
									{
										speaker: "Patient",
										text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
										highLights: ["feel"],
										dialogueNo: 7,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 4,
								name: "Transcript from 23 May 2022 [12:11:21]",
								tags: ["Others", "Inbound", "UNKNOWN", "Negative"],
								dialogueNo: 4,
								callId: "UP3DS2H374JBNPNW",
								callDate: "2022-05-23",
								callTime: "12:11:21",
								dialogueAppearedAt: 20.7,
								dialogueLength: 9.66,
								transcriptLength: 98.44,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 2,
										text: "not great. i'm still waiting for my lectrazine medication to arrive and it's been over a week.",
										speaker: "Patient",
									},
									{
										dialogueNo: 3,
										text: "i'm sorry to hear that. can you tell me a little bit more about your symptoms ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 4,
										text: "i'm feeling really anxious and my heart is racing. i can't sleep or eat and i'm just really frustrated.",
										speaker: "Patient",
									},
									{
										dialogueNo: 5,
										text: "that sounds very difficult. i'll see if i can find out what's going on with your medication and try to get you some relief.",
										speaker: "Agent",
									},
									{
										dialogueNo: 6,
										text: "thank you.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/UP3DS2H374JBNPNW.json",
								elasticSearchId: "UP3DS2H374JBNPNW_4",
								transcripts: [
									{
										speaker: "Patient",
										text: "not great. i'm still waiting for my lectrazine medication to arrive and it's been over a week.",
										highLights: [],
										dialogueNo: 2,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. can you tell me a little bit more about your symptoms ?",
										highLights: [],
										dialogueNo: 3,
									},
									{
										speaker: "Patient",
										text: "i'm feeling really anxious and my heart is racing. i can't sleep or eat and i'm just really frustrated.",
										highLights: ["feeling"],
										dialogueNo: 4,
									},
									{
										speaker: "Agent",
										text: "that sounds very difficult. i'll see if i can find out what's going on with your medication and try to get you some relief.",
										highLights: ["get"],
										dialogueNo: 5,
									},
									{
										speaker: "Patient",
										text: "thank you.",
										highLights: [],
										dialogueNo: 6,
									},
								],
								callTypeId: 1,
								therapyStatusId: -1,
							},
							{
								searchId: 5,
								name: "Transcript from 17 May 2022 [11:44:17]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 7,
								callId: "U27WXBUWZB8TNX14",
								callDate: "2022-05-17",
								callTime: "11:44:17",
								dialogueAppearedAt: 77.74,
								dialogueLength: 9.66,
								transcriptLength: 104.88,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 5,
										text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
										speaker: "Patient",
									},
									{
										dialogueNo: 6,
										text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 7,
										text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
										speaker: "Patient",
									},
									{
										dialogueNo: 8,
										text: "i'm sorry to hear that. we'll do our best to make the calls less frequent. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
										speaker: "Agent",
									},
								],
								s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
								elasticSearchId: "U27WXBUWZB8TNX14_7",
								transcripts: [
									{
										speaker: "Patient",
										text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
										highLights: ["calling", "feel"],
										dialogueNo: 5,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
										highLights: ["calls"],
										dialogueNo: 6,
									},
									{
										speaker: "Patient",
										text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
										highLights: ["feel"],
										dialogueNo: 7,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. we'll do our best to make the calls less frequent. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
										highLights: ["calls"],
										dialogueNo: 8,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 6,
								name: "Transcript from 17 May 2022 [11:44:17]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 1,
								callId: "U27WXBUWZB8TNX14",
								callDate: "2022-05-17",
								callTime: "11:44:17",
								dialogueAppearedAt: 0,
								dialogueLength: 8.74,
								transcriptLength: 104.88,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
								elasticSearchId: "U27WXBUWZB8TNX14_1",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
										highLights: ["calls", "feel", "getting", "time"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
										highLights: ["feel", "feeling"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
										highLights: ["call", "getting", "time"],
										dialogueNo: 3,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
						],
						emotionsCounts: [
							{
								text: "headaches",
								value: 5711,
							},
							{
								text: "slight headache",
								value: 1130,
							},
						],
						headers: {
							"x-time": {
								query_expansion_time: 0.23832941055297852,
								retriever_time: 0.645418643951416,
								ranker_time: 0.7286033630371094,
								df_prepare_time: 0.008182287216186523,
								process_time: 1.665311574935913,
							},
							"x-search-config": {
								query_type: "keyword",
								expanded_query:
									"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
							},
							"x-auth-time": "0.3819572925567627",
							"x-process-time": "2.050896167755127",
						},
					},
					{
						topicId: 2,
						topicName: "worried effects lectrazine",
						numberOfSearchResults: 7,
						searchResults: [
							{
								searchId: 1,
								name: "Transcript from 04 May 2022 [18:32:15]",
								tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 1,
								callId: "SR0ANN6MVSM2XHRA",
								callDate: "2022-05-04",
								callTime: "18:32:15",
								dialogueAppearedAt: 0,
								dialogueLength: 11.5,
								transcriptLength: 126.5,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i'm really worried about the side effects of lectrazine. i've read that it can cause weight gain, hair loss, and headaches.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "yes, those are all potential side effects of lectrazine. however, they are all relatively rare. most people who take lectrazine don't experience any serious side effects.",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "but what if i'm one of the people who does experience side effects ?",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/SR0ANN6MVSM2XHRA.json",
								elasticSearchId: "SR0ANN6MVSM2XHRA_1",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm really worried about the side effects of lectrazine. i've read that it can cause weight gain, hair loss, and headaches.",
										highLights: ["cause", "effects", "lectrazine", "side", "worried"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "yes, those are all potential side effects of lectrazine. however, they are all relatively rare. most people who take lectrazine don't experience any serious side effects.",
										highLights: ["effects", "lectrazine", "side"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "but what if i'm one of the people who does experience side effects ?",
										highLights: ["effects", "side"],
										dialogueNo: 3,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 2,
								name: "Transcript from 04 May 2022 [10:31:55]",
								tags: ["Common Side Effects", "Inbound", "UNKNOWN", "Negative"],
								dialogueNo: 1,
								callId: "GY5L641CE6CYTX5M",
								callDate: "2022-05-04",
								callTime: "10:31:55",
								dialogueAppearedAt: 0,
								dialogueLength: 9.2,
								transcriptLength: 84.18,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i'm really worried about the side effects of lectrazine. i've heard that it can cause some serious problems.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "yes, lectrazine can cause some serious side effects, but it's important to remember that it's also a very effective medication. if you're concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "i don't know if i'm comfortable taking a medication that could have such serious side effects.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/GY5L641CE6CYTX5M.json",
								elasticSearchId: "GY5L641CE6CYTX5M_1",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm really worried about the side effects of lectrazine. i've heard that it can cause some serious problems.",
										highLights: ["cause", "effects", "lectrazine", "side", "worried"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "yes, lectrazine can cause some serious side effects, but it's important to remember that it's also a very effective medication. if you're concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.",
										highLights: ["cause", "effects", "lectrazine", "side"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "i don't know if i'm comfortable taking a medication that could have such serious side effects.",
										highLights: ["effects", "side"],
										dialogueNo: 3,
									},
								],
								callTypeId: 1,
								therapyStatusId: -1,
							},
							{
								searchId: 3,
								name: "Transcript from 09 May 2022 [21:20:36]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 1,
								callId: "WW8PUBYMYJOW4S8N",
								callDate: "2022-05-09",
								callTime: "21:20:36",
								dialogueAppearedAt: 0,
								dialogueLength: 9.66,
								transcriptLength: 118.68,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
								elasticSearchId: "WW8PUBYMYJOW4S8N_1",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
										highLights: ["effects", "lectrazine", "side", "worried"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
										highLights: ["cause", "effects", "lectrazine", "side"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
										highLights: ["lectrazine"],
										dialogueNo: 3,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 4,
								name: "Transcript from 10 May 2022 [11:13:55]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 1,
								callId: "NEAEBCB3J820JCG7",
								callDate: "2022-05-10",
								callTime: "11:13:55",
								dialogueAppearedAt: 0,
								dialogueLength: 7.82,
								transcriptLength: 58.42,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i've been taking lectrazine for a while now and i'm starting to notice some adverse effects.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "what kind of adverse effects are you noticing ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "i'm having a lot of trouble sleeping, and i'm really tired during the day. i'm also having a lot of headaches.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/NEAEBCB3J820JCG7.json",
								elasticSearchId: "NEAEBCB3J820JCG7_1",
								transcripts: [
									{
										speaker: "Patient",
										text: "i've been taking lectrazine for a while now and i'm starting to notice some adverse effects.",
										highLights: ["effects", "lectrazine"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "what kind of adverse effects are you noticing ?",
										highLights: ["effects"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "i'm having a lot of trouble sleeping, and i'm really tired during the day. i'm also having a lot of headaches.",
										highLights: [],
										dialogueNo: 3,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 5,
								name: "Transcript from 13 May 2022 [11:37:35]",
								tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Neutral"],
								dialogueNo: 2,
								callId: "8VHNCXKTEO2KE9JF",
								callDate: "2022-05-13",
								callTime: "11:37:35",
								dialogueAppearedAt: 3.22,
								dialogueLength: 7.36,
								transcriptLength: 59.34,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "hello, how are you today ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 2,
										text: "i'm feeling okay, but i'm a little concerned about the adverse events of lectrazine.",
										speaker: "Patient",
									},
									{
										dialogueNo: 3,
										text: "yes, lectrazine can cause some side effects, but they are usually mild and go away on their own.",
										speaker: "Agent",
									},
									{
										dialogueNo: 4,
										text: "what are the most common side effects ?",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/8VHNCXKTEO2KE9JF.json",
								elasticSearchId: "8VHNCXKTEO2KE9JF_2",
								transcripts: [
									{
										speaker: "Agent",
										text: "hello, how are you today ?",
										highLights: [],
										dialogueNo: 1,
									},
									{
										speaker: "Patient",
										text: "i'm feeling okay, but i'm a little concerned about the adverse events of lectrazine.",
										highLights: ["lectrazine"],
										dialogueNo: 2,
									},
									{
										speaker: "Agent",
										text: "yes, lectrazine can cause some side effects, but they are usually mild and go away on their own.",
										highLights: ["cause", "effects", "lectrazine", "side"],
										dialogueNo: 3,
									},
									{
										speaker: "Patient",
										text: "what are the most common side effects ?",
										highLights: ["effects", "side"],
										dialogueNo: 4,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 6,
								name: "Transcript from 10 May 2022 [20:54:19]",
								tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 1,
								callId: "GSADKQ7M04XE0EZJ",
								callDate: "2022-05-10",
								callTime: "20:54:19",
								dialogueAppearedAt: 0,
								dialogueLength: 6.44,
								transcriptLength: 25.76,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "hi, i'm worried about the potential side effects of the drug lectrazine.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "hi. don't worry, the potential side effects of lectrazine are usually mild and go away on their own. however, if you experience any serious side effects, please contact your doctor immediately.",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "ok, thank you.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/GSADKQ7M04XE0EZJ.json",
								elasticSearchId: "GSADKQ7M04XE0EZJ_1",
								transcripts: [
									{
										speaker: "Patient",
										text: "hi, i'm worried about the potential side effects of the drug lectrazine.",
										highLights: ["effects", "lectrazine", "side", "worried"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "hi. don't worry, the potential side effects of lectrazine are usually mild and go away on their own. however, if you experience any serious side effects, please contact your doctor immediately.",
										highLights: ["effects", "lectrazine", "side"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "ok, thank you.",
										highLights: [],
										dialogueNo: 3,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 7,
								name: "Transcript from 04 May 2022 [15:19:30]",
								tags: ["Others", "Outbound", "UNKNOWN", "Neutral"],
								dialogueNo: 3,
								callId: "R0NENK69XQCQ8VFH",
								callDate: "2022-05-04",
								callTime: "15:19:30",
								dialogueAppearedAt: 9.2,
								dialogueLength: 5.52,
								transcriptLength: 90.62,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "hi, i'm worried about the side effects of lectrazine.",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "hi, what are you worried about specifically ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "i've heard that it can cause weight gain and hair loss.",
										speaker: "Patient",
									},
									{
										dialogueNo: 4,
										text: "well, those are potential side effects, but they're not necessarily common. many people taking lectrazine don't experience any side effects at all.",
										speaker: "Agent",
									},
									{
										dialogueNo: 5,
										text: "but what if i do experience them ?",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/R0NENK69XQCQ8VFH.json",
								elasticSearchId: "R0NENK69XQCQ8VFH_3",
								transcripts: [
									{
										speaker: "Patient",
										text: "hi, i'm worried about the side effects of lectrazine.",
										highLights: ["effects", "lectrazine", "side", "worried"],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "hi, what are you worried about specifically ?",
										highLights: ["worried"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "i've heard that it can cause weight gain and hair loss.",
										highLights: ["cause"],
										dialogueNo: 3,
									},
									{
										speaker: "Agent",
										text: "well, those are potential side effects, but they're not necessarily common. many people taking lectrazine don't experience any side effects at all.",
										highLights: ["effects", "lectrazine", "side"],
										dialogueNo: 4,
									},
									{
										speaker: "Patient",
										text: "but what if i do experience them ?",
										highLights: [],
										dialogueNo: 5,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
						],
						emotionsCounts: [
							{
								text: "headaches",
								value: 5711,
							},
							{
								text: "slight headache",
								value: 1130,
							},
						],
						headers: {
							"x-time": {
								query_expansion_time: 0.23832941055297852,
								retriever_time: 0.645418643951416,
								ranker_time: 0.7286033630371094,
								df_prepare_time: 0.008182287216186523,
								process_time: 1.665311574935913,
							},
							"x-search-config": {
								query_type: "keyword",
								expanded_query:
									"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
							},
							"x-auth-time": "0.3819572925567627",
							"x-process-time": "2.050896167755127",
						},
					},
					{
						topicId: 3,
						topicName: "covered medication",
						numberOfSearchResults: 3,
						searchResults: [
							{
								searchId: 1,
								name: "Transcript from 25 May 2022 [15:30:20]",
								tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Positive"],
								dialogueNo: 4,
								callId: "F1T9KO8WBYD609GF",
								callDate: "2022-05-25",
								callTime: "15:30:20",
								dialogueAppearedAt: 16.1,
								dialogueLength: 11.5,
								transcriptLength: 119.14,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 2,
										text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
										speaker: "Patient",
									},
									{
										dialogueNo: 3,
										text: "i'm sorry to hear that. can you tell me what happened ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 4,
										text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
										speaker: "Patient",
									},
									{
										dialogueNo: 5,
										text: "well, let's see if we can figure out what's going on. can you give me your insurance information ?",
										speaker: "Agent",
									},
									{
										dialogueNo: 6,
										text: "sure.",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
								elasticSearchId: "F1T9KO8WBYD609GF_4",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
										highLights: [],
										dialogueNo: 2,
									},
									{
										speaker: "Agent",
										text: "i'm sorry to hear that. can you tell me what happened ?",
										highLights: [],
										dialogueNo: 3,
									},
									{
										speaker: "Patient",
										text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
										highLights: ["medication"],
										dialogueNo: 4,
									},
									{
										speaker: "Agent",
										text: "well, let's see if we can figure out what's going on. can you give me your insurance information ?",
										highLights: [],
										dialogueNo: 5,
									},
									{
										speaker: "Patient",
										text: "sure.",
										highLights: [],
										dialogueNo: 6,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 2,
								name: "Transcript from 12 May 2022 [15:40:06]",
								tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 3,
								callId: "JP6WSQ3VO38RD2YK",
								callDate: "2022-05-12",
								callTime: "15:40:06",
								dialogueAppearedAt: 36.8,
								dialogueLength: 9.2,
								transcriptLength: 64.4,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 1,
										text: "i'm really worried about the potential adverse effects of lectrazine. i've heard that it can cause serious problems like heart attacks and strokes. is this true ?",
										speaker: "Patient",
									},
									{
										dialogueNo: 2,
										text: "there is always the potential for adverse effects with any medication, but the risk is usually very low with lectrazine. serious problems like heart attacks and strokes are very rare. the most common side effects of lectrazine are mild and include headache, nausea, and dizziness.",
										speaker: "Agent",
									},
									{
										dialogueNo: 3,
										text: "i'm still really worried about taking this medication. are there any other risks i should be aware of ?",
										speaker: "Patient",
									},
									{
										dialogueNo: 4,
										text: "there are always risks associated with taking any medication, but the risks with lectrazine are very low. the most important thing is that you take the medication as directed and follow up with your doctor as recommended.",
										speaker: "Agent",
									},
								],
								s3path: "kural/processed_files/JP6WSQ3VO38RD2YK.json",
								elasticSearchId: "JP6WSQ3VO38RD2YK_3",
								transcripts: [
									{
										speaker: "Patient",
										text: "i'm really worried about the potential adverse effects of lectrazine. i've heard that it can cause serious problems like heart attacks and strokes. is this true ?",
										highLights: [],
										dialogueNo: 1,
									},
									{
										speaker: "Agent",
										text: "there is always the potential for adverse effects with any medication, but the risk is usually very low with lectrazine. serious problems like heart attacks and strokes are very rare. the most common side effects of lectrazine are mild and include headache, nausea, and dizziness.",
										highLights: ["medication"],
										dialogueNo: 2,
									},
									{
										speaker: "Patient",
										text: "i'm still really worried about taking this medication. are there any other risks i should be aware of ?",
										highLights: ["medication"],
										dialogueNo: 3,
									},
									{
										speaker: "Agent",
										text: "there are always risks associated with taking any medication, but the risks with lectrazine are very low. the most important thing is that you take the medication as directed and follow up with your doctor as recommended.",
										highLights: ["medication"],
										dialogueNo: 4,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
							{
								searchId: 3,
								name: "Transcript from 25 May 2022 [20:15:09]",
								tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
								dialogueNo: 6,
								callId: "O1388T9ZZ5BCLGTD",
								callDate: "2022-05-25",
								callTime: "20:15:09",
								dialogueAppearedAt: 39.1,
								dialogueLength: 9.2,
								transcriptLength: 75.44,
								patientId: -1,
								speaker: "Patient",
								storiesAdded: [],
								addedToStory: "false",
								context: [
									{
										dialogueNo: 4,
										text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
										speaker: "Patient",
									},
									{
										dialogueNo: 5,
										text: "that doesn't sound right. let me see if i can help you figure this out.",
										speaker: "Agent",
									},
									{
										dialogueNo: 6,
										text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
										speaker: "Patient",
									},
									{
										dialogueNo: 7,
										text: "well, sometimes insurance companies make decisions that don't make a lot of sense. but we can usually appeal those decisions.",
										speaker: "Agent",
									},
									{
										dialogueNo: 8,
										text: "really ?",
										speaker: "Patient",
									},
								],
								s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
								elasticSearchId: "O1388T9ZZ5BCLGTD_6",
								transcripts: [
									{
										speaker: "Patient",
										text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
										highLights: [],
										dialogueNo: 4,
									},
									{
										speaker: "Agent",
										text: "that doesn't sound right. let me see if i can help you figure this out.",
										highLights: [],
										dialogueNo: 5,
									},
									{
										speaker: "Patient",
										text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
										highLights: ["medication"],
										dialogueNo: 6,
									},
									{
										speaker: "Agent",
										text: "well, sometimes insurance companies make decisions that don't make a lot of sense. but we can usually appeal those decisions.",
										highLights: [],
										dialogueNo: 7,
									},
									{
										speaker: "Patient",
										text: "really ?",
										highLights: [],
										dialogueNo: 8,
									},
								],
								callTypeId: 2,
								therapyStatusId: -1,
							},
						],
						emotionsCounts: [
							{
								text: "headaches",
								value: 5711,
							},
							{
								text: "slight headache",
								value: 1130,
							},
						],
						headers: {
							"x-time": {
								query_expansion_time: 0.23832941055297852,
								retriever_time: 0.645418643951416,
								ranker_time: 0.7286033630371094,
								df_prepare_time: 0.008182287216186523,
								process_time: 1.665311574935913,
							},
							"x-search-config": {
								query_type: "keyword",
								expanded_query:
									"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
							},
							"x-auth-time": "0.3819572925567627",
							"x-process-time": "2.050896167755127",
						},
					},
				],
			},
		},
	];

	const [filters, setFilters] = React.useState<FilterInterface>({
		Timeframe_Display: undefined,
		Start_Date: "",
		End_Date: "",
		Call_Type: undefined,
		Therapy_Status: undefined,
		Call_Intent: undefined,
		Adherence: undefined,
		Sentiment: undefined,
		Topics: undefined,
		Emotions: new Array<string>(),
		totStart: undefined,
		totEnd: undefined,
	});
	const [fetchedFilters, setFetchedFilters] = React.useState<any>([]);
	const [headerTitle, setHeaderTitle] = React.useState<string | undefined>();

	const temp: any = [];
	const arrForFilteringChart: any = [];
	const colors: Record<string, string> = {
		Inbound: "rgb(224,198,255)",
		Outbound: "rgb(114,50,207)",
		Agent: "rgb(169,218,253)",
		Patient: "rgb(169,218,253)",
		Positive: "rgb(38,195,141)",
		Neutral: "rgb(222,220,222)",
		Negative: "rgb(230,84,40)",
	};
	// console.log('9999999999999999999999', RESPONSE)
	const handleFilters = (filterValue: any) => setSearchFilterData(filterValue);
	const handleFilters2 = (filterValue: string | boolean) => setSelectLevel(filterValue);
	const handleFilters3 = (filterValue: string | boolean) => setTherapyStatus(filterValue);
	const getColor = (bar: any) => colors[bar.id];
	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => setVisible(false);
	const topRef = useRef<any>(null);

	// const summaryDummyData="Calls can refer to different things, such as the Apple TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede Álvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena[1]. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets[1].Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device[2]. Troubleshooting tips are available if issues arise while using the calling feature[2]. Recent calls cannot be managed or deleted from the PC[2].Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls[3]. The service is available on Apple devices and other smartphones[3].";

	async function fetchDataForPage() {
		// add s3 parameter value
		// const data: any = await NodeGraphServiceHelper.getNodeJsons(path);
		// if (data) {
		//   setFetchedData(data);
		// }

		// OLD TEST ENV
		// const data: any = await ExploreServiceHelper.getNodeJsonsExplore(path);

		const data: any = await SearchServiceHelper.getFilters();
		data.push({
			filter: "TOT",
			optionsObject: [],
			type: "slider",
		});
		if (data) {
			setFetchedData(data);
		}
	}

	async function fetchCards(filters?: any) {
		setTranscriptData([]); //To Re-render page on applying filters
		setSelectedNode(null); //To Re-render page on applying filters
		Messages.loading("Fetching Clusters", "updatable");
		let data: any;
		filters = { ...filters, ...searchFilterData, ...searchTranscriptQuery };

		if (Object.entries(filters).length === 0) {
			data = await ExploreServiceHelper.getExploreCardsData(app.expIdForNodeGraph, null, null, null, null, null, null, null, null, null, null, null);
		} else {
			// console.log(filters); Commenting
			data = await ExploreServiceHelper.getExploreCardsData(
				filters.experimentId !== null ? filters.experimentId : null,

				// searchFilter.startDate = moment(query.startDate).format();

				filters.startDate ? filters.startDate : null,
				filters.endDate ? filters.endDate : null,
				filters.speaker !== null || filters.speaker !== "" ? (filters.speaker == "Patient" ? "patient" : filters.speaker == "Agent" ? "agent" : "") : "",
				filters.topics !== undefined ? filters.topics[0] : null,
				filters.totStart !== "" ? filters.totStart : null,
				filters.totEnd !== "" ? filters.totEnd : null,
				filters.callTypeIds && filters.callTypeIds[0] !== undefined ? filters.callTypeIds[0] : null,
				filters.therapyStatusIds && filters.therapyStatusIds[0] !== undefined ? filters.therapyStatusIds[0] : null,
				filters.sentiment && filters.sentiment[0] !== undefined ? filters.sentiment[0] : null,
				filters.emotions && filters.emotions.length > 0 ? filters.emotions : null,
				filters.clusterId !== undefined ? filters.clusterId : null
			);
		}

		if (data) {
			Messages.success("Success", "updatable");
			// console.log(data); Commenting
			setResponse(data);
			// setResponse(DUMMYRESPONSE);
			// setFetchedCards(data);
			// if (data.length === 0) {
			//   setResults(true);
			// } else {
			//   setResults(true);
			// }
		} else {
			Messages.error("Fetching failed", "updatable");
			console.log("ERROR");
		}
	}

	async function fetchFilters() {
		// const data: any = await SearchServiceHelper.getFilters();

		// transcrtiptFilters.push({
		//   filter: "TOT",
		//   optionsObject: [],
		//   type: "slider",
		// });

		// console.log('FILTERS DATA', data)
		// if (transcrtiptFilters) {
		//   setFetchedFilters(transcrtiptFilters);
		// }

		if (fetchedData) {
			// let transFilters: any = [...fetchedData[1]["transcriptFilters"]];
			// transFilters.push({
			//   filter: "TOT",
			//   optionsObject: [],
			//   type: "slider",
			// });
			// setFetchedFilters(transFilters);
			setFetchedFilters(fetchedData);
		}
	}
	async function fetchStoryData() {
		const data: any = await OldStoriesServiceHelper.getAllStories();

		if (data) {
			setFetchedStoryData(data);
		}
	}

	async function addNewStory(addData: { StoryName: string }) {
		const key = "updatable";
		Messages.loading("Adding Story", key);
		// console.log(addData);
		const data: any = await OldStoriesServiceHelper.addNewStory(addData);
		if (data) {
			var temp: any = [];
			var d = data["Date Created"];
			data["Date Created"] = moment(d).format("DD MMM YYYY");
			temp = [data, ...fetchedStoryData];
			setFetchedStoryData(temp);
			Messages.success("Successfully Created New Story", key);
		} else Messages.error("Failed", key);
	}

	async function addStoryToTranscriptData(transcript: {
		id: any;
		name: any;
		tags: any;
		storiesAdded: any;
		source: number;
		studyId: number;
		trId: number;
		studyName: string;
		geography: string;
		wave: string;
		fileName: string;
		dialogueNo: any;
		callId: any;
		callDate: any;
		callTime: any;
		s3path: any;
		addedToStory: any;
		score: number;
		dialogueAppearedAt: any;
		dialogueLength: any;
		transcriptLength: any;
		transcripts: any;
		patientId: any;
		speaker: any;
		content: any;
		context: string;
		context_preview: string;
		elasticSearchId: any;
		storyDialogues: any;
		therapyStatusId: string;
		notAddedStories: any;
	}) {
		const key = "updatable";
		Messages.loading("Adding Transcript to Story", key);
		// console.log("Trans", transcript);
		const data: any = await OldStoriesServiceHelper.addTransToStory(transcript);
		// console.log("Fetched : ", data);
		if (data) {
			Messages.success("Successfully Added Transcript to Story", key);

			const temp = [...transcriptData];
			//      console.log("transc", transcriptData, temp, transcript);

			const idx = temp[0].searchResults.findIndex((t: any) => t.callId === transcript.callId && t.dialogueNo === transcript.dialogueNo);

			temp[0].searchResults[idx].storiesAdded = data;
			if (data.length === 0) {
				temp[0].searchResults[idx].addedToStory = false;
			}
			setTranscriptData(temp);

			return true;
		} else {
			Messages.error("Failed", key);
			return false;
		}
	}

	const clearFilters = () => {
		const obj = {
			Timeframe_Display: undefined,
			Start_Date: "",
			End_Date: "",
			Call_Type: undefined,
			Therapy_Status: undefined,
			Call_Intent: undefined,
			Adherence: undefined,
			Sentiment: undefined,
			Topics: undefined,
			Emotions: new Array<string>(),
			totStart: undefined,
			totEnd: undefined,
		};
		setFilters(obj);
		setVisible(false);
		setTranscriptFilters(obj);
	};

	async function fetchTranscriptsForNode(clusterId: any) {
		const data: any = await ExploreServiceHelper.getClusterDetailsData(clusterId);

		if (data) {
			let tempArr: any = [];
			tempArr.push(data);
			const nodeTranscriptData = tempArr;
			if (nodeTranscriptData) {
				// console.log(nodeTranscriptData); Commenting
				setTranscriptData(nodeTranscriptData);
				dataCopy.current = [nodeTranscriptData];
				Messages.success("Success", "key1");
			} else {
				Messages.error("Fetching failed", "key1");
				setTranscriptData([]);
			}
		}
	}

	const fetchTranscripts = async (query: searchQuery) => {
		setTranscriptLoading(true);
		Messages.loading("Fetching...", "key1");
		// console.log("the search query coming in ", query);  Commenting
		// const data: any = await SearchServiceHelper.getAllTranscripts(query);
		// TODO Make API Call here
		setTranscriptLoading(false);
		const key = "Key 1";

		if (fetchedData) {
			// console.log(fetchedData[2]["nodeGraphTranscriptData"]["transcript_data"].topicId);
			// console.log("Fetched Data: ", fetchedData);  Commenting
			const nodeTranscriptData =
				fetchedData[2] && fetchedData[2]["nodeGraphTranscriptData"]["transcript_data"].filter((trans: any) => trans.topicId === selectedNode.topicId);

			// const nodeTranscriptData = fetchTranscriptsForNode(4);

			// if (nodeTranscriptData) {
			//   console.log(nodeTranscriptData);
			//   setTranscriptData(nodeTranscriptData);
			//   dataCopy.current = [nodeTranscriptData];
			//   Messages.success("Success", "key1");
			// } else {
			//   Messages.error("Fetching failed", "key1");
			//   setTranscriptData([]);
			// }
		}
	};

	const addToStorySearch = (transcriptData: any, storyId: any) => {
		var request = {
			transcriptData: transcriptData,
			storyId: storyId,
		};
		var add: any = [];
		storyId.forEach((story: any, index: number) => {
			add.push({
				storyId: story,
				storyName: fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)].storyName,
			});
		});

		// var updatedStoryDialogues = transcriptData.storyDialogues.map((dialogue: any) => {
		// 	return {
		// 		story_id: dialogue.story_id,
		// 		selected_call_id: dialogue.selected_call_id, //transcriptData.elasticSearchId,
		// 		selected_dialogue_no: dialogue.selected_dialogue_no,
		// 		selected_index: dialogue.selected_index,
		// 		previous_call_id: dialogue.previous_call_id,
		// 		previous_dialogue_no: dialogue.previous_dialogue_no,
		// 		previous_index: dialogue.previous_index,
		// 		source: dialogue.source,
		// 		search_query: dialogue.search_query,
		// 	};
		// });

		var trans = {
			id: transcriptData.id,
			name: transcriptData.name,
			tags: transcriptData.tags,
			storiesAdded: add,
			source: 1,
			studyId: transcriptData.studyId,
			trId: transcriptData.trId,
			studyName: transcriptData.studyName,
			geography: transcriptData.geography,
			wave: transcriptData.wave,
			fileName: transcriptData.fileName,
			dialogueNo: transcriptData.dialogueNo,
			callId: transcriptData.callId,
			callDate: transcriptData.callDate,
			callTime: transcriptData.callTime,
			s3path: transcriptData.s3path,
			addedToStory: true,
			score: transcriptData.score,
			dialogueAppearedAt: transcriptData.dialogue_appeared_at,
			dialogueLength: transcriptData.dialogueLength,
			transcriptLength: transcriptData.transcriptLength,
			transcripts: transcriptData.transcripts,
			patientId: transcriptData.patientId,
			speaker: transcriptData.speaker,
			content: transcriptData.content,
			context: transcriptData.context,
			context_preview: transcriptData.context_preview,
			elasticSearchId: transcriptData.elasticSearchId,
			storyDialogues: transcriptData.storyDialogues,
			callTypeId: transcriptData.callTypeId,
			therapyStatusId: transcriptData.therapyStatusId,
			notAddedStories: transcriptData.notAddedStories,
		};
		const d = addStoryToTranscriptData(trans);
		return d;
	};

	const changePinnedStories = (stories: any) => {
		setPinnedStories(stories);
	};

	const addSearchFilters = (query: searchQuery) => {
		var searchFilter: searchQuery = searchTranscriptQuery;
		searchFilter.searchWord = query.searchWord;
		searchFilter.speaker = query.speaker;
		if ((query.startDate && app.envId === 1) || app.envId === 2) {
			searchFilter.startDate = moment(query.startDate).startOf("month").add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "months");
		} else if (query.startDate && app.envId === 3) {
			searchFilter.startDate = moment(query.startDate).add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "days");
		} else {
			searchFilter.startDate = undefined;
			searchFilter.endDate = undefined;
		}
		searchFilter.deepSearch = query.deepSearch;
		searchFilter.testButton1 = query.testButton1;
		searchFilter.testButton2 = query.testButton2;
		searchFilter.testButton3 = query.testButton3;
		searchFilter.testButton4 = query.testButton4;
		searchFilter.testButton5 = query.testButton5;
		checksearchWord(searchFilter);
		setSearchTranscriptQuery(searchFilter);
	};

	const findId = (filter: string, value: string) => {
		const grpIdx: number[] = [];
		const idx: number[] = [];
		fetchedFilters.map((f: any) => {
			f.optionsObject.map((group: any) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx.push(group.id);
						grpIdx.push(group.id);
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (Array.isArray(value) && value.find((f) => f === group.name)) {
							grpIdx.push(group.id);
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find((sg: any) => sg.name === v);
								if (ob) {
									grpIdx.push(group.id);
									idx.push(ob.id);
								}
								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	};

	const setTranscriptFilters = (filter: any) => {
		// console.log("Filter", filter);  Commenting
		var transcriptFilters: searchQuery = searchTranscriptQuery;
		const callType = findId("Call_Type", filter.Call_Type);
		transcriptFilters.callTypeIds = callType.grpIdx;

		const therapyStatus = findId("Therapy_Status", filter.Therapy_Status);
		transcriptFilters.therapyStatusIds = therapyStatus.grpIdx;

		const sentiment = findId("Sentiment", filter.Sentiment);
		transcriptFilters.sentiment = sentiment.grpIdx;

		const topics = findId("Topics", filter.Topics);
		transcriptFilters.topics = topics.grpIdx;

		const emotions = findId("Emotions", filter.Emotions);
		transcriptFilters.emotionsGroup = emotions.grpIdx;
		transcriptFilters.emotions = emotions.idx;

		transcriptFilters.totStart = filter.totStart;
		transcriptFilters.totEnd = filter.totEnd;

		// console.log("TranscriptFilter", transcriptFilters);  Commenting
		setFilters(filter);
		checksearchWord(transcriptFilters);
		fetchCards(transcriptFilters);
		setSearchTranscriptQuery(transcriptFilters);
	};

	const checksearchWord = (query: searchQuery) => {
		if (query.searchWord === undefined || query.searchWord === "") {
			setsearchWordRequired(true);
		} else {
			setsearchWordRequired(false);
			setEmptyTextField("No Data");
			// console.log(query);  Commenting
			fetchTranscripts(query);
		}
	};

	const findValueForTherapyStatusGraph = (therapyStatus: any, bound: any, filterVal: any) => {
		let count = 0;
		transcriptData[0].searchResults.map((i: any) => {
			if (
				i.tags[2] === therapyStatus &&
				i.tags[1] === bound &&
				(filterVal.length === 1
					? i.tags[0] === filterVal[0]
					: filterVal.length === 2
					? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
					: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
			)
				++count;
		});
		return count;
	};

	const findValueForSentimentGraph = (patient: any, sentiment: any, filterVal: any, useTopic: boolean) => {
		if (useTopic === true) {
			let count = 0;
			// transcriptData[0].searchResults.map((i: any) => {
			//   if (
			//     i.tags[4] === sentiment &&
			//     i.tags[5] === patient &&
			//     (filterVal.length === 1
			//       ? i.tags[0] === filterVal[0]
			//       : filterVal.length === 2
			//       ? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
			//       : i.tags[0] === filterVal[0] ||
			//         i.tags[0] === filterVal[1] ||
			//         i.tags[0] === filterVal[2])
			//   )
			//     ++count;
			// });

			return count;
		} else {
			let count = 0;
			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[4] === sentiment &&
					i.speaker === patient &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});

			return count;
		}
	};

	const findValueForToT = (ll: any, ul: any, filterVal: any, therapyStatus?: any) => {
		if (therapyStatus) {
			let count = 0;

			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[3] > ll &&
					i.tags[3] < ul &&
					i.tags[2] === therapyStatus &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});
			return count;
		} else {
			let count = 0;

			transcriptData[0].searchResults.map((i: any) => {
				if (
					i.tags[3] > ll &&
					i.tags[3] < ul &&
					(filterVal.length === 1
						? i.tags[0] === filterVal[0]
						: filterVal.length === 2
						? i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1]
						: i.tags[0] === filterVal[0] || i.tags[0] === filterVal[1] || i.tags[0] === filterVal[2])
				)
					++count;
			});
			return count;
		}
	};

	if (transcriptData.length > 0) {
		let tempArr: any = [
			"Dosing Information",
			"Closing",
			"Speciality Pharmacy",
			"Common Side Effects",
			"Next Call Confirmation",
			"Introduction",
			"Lab Monitoring Guidance",
			"  Nurse Educator Role",
			"Others",
			"Patient Questionnaire",
			"Safety Recording",
			"Shipment",
		];

		for (let i = 0; i <= tempArr.length; i++) {
			if (
				findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true) +
					findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true) +
					+findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true) >
				0
			) {
				let obj: any = {};
				obj.Topic = tempArr[i];
				obj.Positive = findValueForSentimentGraph(tempArr[i], "Positive", searchFilterData, true);
				obj["positive Color"] = "hsl(251 70%, 50%)";
				obj.Neutral = findValueForSentimentGraph(tempArr[i], "Neutral", searchFilterData, true);
				obj["neutral Color"] = "hsl(41, 70%, 50%)";
				obj.Negative = findValueForSentimentGraph(tempArr[i], "Negative", searchFilterData, true);
				obj["negative Color"] = "hsl(41, 70%, 50%)";
				arrForFilteringChart.push(obj);
			}
		}
	}

	async function onSave(searchFilterData: any) {
		let data: any;
		var filters: any = { ...searchFilterData, ...searchTranscriptQuery };

		if (filters == undefined) {
			data = await ExploreServiceHelper.getExploreCardsData(1, null, null, null, null, null, null, null, null, null, null);
		} else {
			// console.log(filters);  Commenting
			data = await ExploreServiceHelper.getExploreCardsData(
				filters.experimentId == "" ? null : filters.experimentId,
				filters.startDate == "" ? null : filters.startDate,
				filters.endDate == "" ? null : filters.endDate,
				filters.speaker !== undefined ? filters.speaker : null,
				filters.topics && filters.topics[0] !== undefined ? filters.topics : null,
				filters.totStart !== undefined ? filters.totStart : null,
				filters.totEnd !== undefined ? filters.totEnd : null,
				filters.callTypeIds && filters.callTypeIds[0] !== undefined ? filters.callTypeIds : null,
				filters.therapyStatusIds && filters.therapyStatusIds[0] !== undefined ? filters.therapyStatusIds : null,
				filters.sentiment && filters.sentiment[0] !== undefined ? filters.sentiment : null,
				filters.emotions && filters.emotions.length > 0 ? filters.emotions : null
			);
		}
		// if (data) {
		// 	console.log(data); 
		// 	// setResponse(data);
		// }
	}
	useEffect(() => {
		// fetchDataForPage();
		fetchCards();
	}, [path]);

	useEffect(() => {
		if (fetchedData) {
			fetchFilters();
			fetchStoryData();
			// setSearchFilterData(["Good"]);
		}
	}, [fetchedData]);

	const scrollToTop = () => {
		topRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		// console.log("Selected Node - ", selectedNode);  Commenting
		if (selectedNode) {
			setKeywords(selectedNode.keywords.map((keyword: { name: any }) => keyword.name));
			fetchTranscripts({ searchWord: "headache" });
			setHeaderTitle(selectedNode.topic);
		}
	}, [selectedNode]);

	const headerEdit = (headerLabel: any) => {
		setHeaderTitle(headerLabel);
		ExploreServiceHelper.changeCardNameData(selectedNode.topicId, headerLabel);
	};

	useEffect(() => {
		// console.log("Edited  - ", headerTitle);  Commenting
		if (headerTitle) {
			selectedNode.topic = headerTitle;
			setUpdatedNode(selectedNode);
			setHeaderTitle(headerTitle);
		}
	}, [headerTitle]);

	// useEffect(() => {
	//   fetchTranscriptsForNode(22);
	// }, []);

	return (
		<div>
			<div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
				<div className="flex py-4 items-center">
					{/* {console.log("TRANSCRIPT DATA", transcriptData)} */}
					<div className="flex">
						<h2 className="text-xl font-semibold ">Node Graph</h2>
						<div className="flex flex-wrap ml-8">
							<FilterCard filters={filters} setFilters={setTranscriptFilters} filterActiveStatus={searchFilterActiveStatus} />
							<AntDrawer title="Filters" placement="right" onClose={onClose} visible={visible} destroyOnClose={true}>
								<Drawer
									filters={filters}
									setFilters={setTranscriptFilters}
									filterOptions={fetchedFilters}
									clearFilters={clearFilters}
									filterActiveStatus={searchFilterActiveStatus}
								/>
							</AntDrawer>
						</div>
					</div>
				</div>
				<NodeGraphFilters
					path={path}
					setPath={setPath}
					showDrawer={showDrawer}
					fetchTranscripts={addSearchFilters}
					searchWordRequired={searchWordRequired}
					searchString={searchString}
					setSearchString={setSearchString}
					searchFilterData={searchFilterData}
					setSearchFilterData={setSearchFilterData}
					// onSave={onSave}
					onSave={fetchCards}
				/>
			</div>
			<div
				className="nodegraph-page"
				style={{
					overflowY: "auto",
					overflowX: "hidden",
					height: "calc(100vh - 200px)",
				}}>
				<div className="bg-body h-full relative" ref={topRef}>
					<NodeGraphCanvas
						data={nodeGraphData}
						onEdit={() => {
							// console.log("ON EDIT CALLED");  Commenting
							setNodeGraphData(Math.random());
						}}
						onSelect={(node) => {
							// console.log("On Node Click", node, updatedNode);  Commenting
							fetchTranscriptsForNode(node.id);
							fetchStoryData();
							setSelectedNode(node);
							setTimeout(() => {
								const ele: any = document.querySelector("#pk-transcript");
								ele.parentElement.scrollBy({
									behavior: "smooth",
									left: 0,
									top: ele.offsetTop,
								});
							}, 200);
						}}
						updatedNode={updatedNode}
						nodeData={response}
					/>
				</div>

				{selectedNode && (
					<div id={"pk-transcript"} className="bg-body flex h-full px-4 pb-8">
						<div
							className="bg-white shadow-lg shadow-gray-900 "
							style={{
								overflowY: "auto",
								flexBasis: showResultSummary ? "65%" : "100%",
							}}>
							{/* {console.log("selectedNode", selectedNode, headerTitle)} */}

							<TranscriptsWrapper
								header={selectedNode.topic}
								subHeader={`100 Conversations`}
								hideSummaryTags={true}
								noSummary={true}
								// expanded={expanded}
								toggleScrollToTop={scrollToTop}
								loading={transcriptLoading}
								noSideSummary={transcriptData.length > 0 ? false : true}
								headerEditCallback={headerEdit}>
								{/* <div
										className="bg-white shadow-md shadow-gray-900 "
										style={{
										overflowY: "auto",
										zIndex: 99, 
										position: "relative",
										// height: `${transcriptData.length === 0 ? "fill-available" : "max-content"}`,
										flexBasis: showResultSummary ? "65%" : "100%",
										}}>
								</div> */}
								{transcriptLoading ? (
									<div>
										<Skeleton active className="py-4 px-8 border-b" />
										<Skeleton active className="py-4 px-8 border-b" />
										<Skeleton active className="py-4 px-8 border-b" />
									</div>
								) : (
									<div className={transcriptData.length > 0 ? "" : "flex items-center justify-around h-96"}>
										<SummaryCard
											data={transcriptData.length > 0 && transcriptData[0]["summary"] ? transcriptData[0]["summary"] : ""}
											// data={summaryDummyData}
											// searchString={"headache"}
											// isloaded={summaryDummyData.length>0?true:false}
											isloaded={transcriptData.length > 0 ? true : false}></SummaryCard>
										<List
											itemLayout="vertical"
											size="large"
											pagination={{
												hideOnSinglePage: true,
												style: {
													marginLeft: "2rem",
													marginBottom: "1.2rem",
													float: "left",
													paddingBottom: "2rem",
												},
												onChange: (page) => {
													console.log(page);
												},
												pageSize: 20,
											}}
											dataSource={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
											locale={{
												emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
											}}
											renderItem={(result: any, index) => (
												<Transcripts
													showTags={true}
													keywords={keywords}
													hideProgressBar={true}
													data={result}
													expanded={expanded}
													addToStoryApi={addToStorySearch}
													pinnedStoriesData={pinnedStories}
													fetchedStoryData={fetchedStoryData}
													fetchStoryData={fetchStoryData}
													addNewStory={addNewStory}
													changePinnedStories={changePinnedStories}
													transcriptData={transcriptData.length > 0 ? transcriptData[0].searchResults : []}
													searchString={searchString}
													setSearchString={setSearchString}
													fetchedFilters={fetchedFilters}
												/>
											)}
										/>
									</div>
								)}
							</TranscriptsWrapper>
						</div>
						{showResultSummary ? (
							<div className="bg-white shadow-md shadow-gray-900 ml-4 p-4" style={{ flexBasis: "40%", overflowY: "auto" }}>
								<p className="font-semibold text-base">
									Search Result Summary
									<Select
										clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
										className="ml-4 float-right w-60"
										placeholder="Select Quality"
										allowClear
										mode="multiple"
										// defaultValue={searchFilterData ? searchFilterData : "High"}
										onChange={(value: string) => {
											handleFilters(value);
										}}>
										<Select.Option value="Good">High</Select.Option>
										<Select.Option value="Average">Medium</Select.Option>
										<Select.Option value="Bad">Low</Select.Option>
									</Select>
								</p>
								<p className="font-semibold mt-5 text-sm mb-3">Therapy Status Distribution</p>
								<div className="flex h-60 border-2">
									<ResponsiveBar
										data={[
											{
												"Therapy Status": "Discontinued",
												Inbound: findValueForTherapyStatusGraph("Discontinued", "Inbound", searchFilterData),
												"inbound Color": "rgb(224,198,255)",
												Outbound: findValueForTherapyStatusGraph("Discontinued", "Outbound", searchFilterData),
												"outbound Color": "hsl(267, 100%, 88.8%)",
											},
											{
												"Therapy Status": "Therapy",
												Inbound: findValueForTherapyStatusGraph("On Therapy", "Inbound", searchFilterData),
												"inbound Color": "rgb(224,198,255)",
												Outbound: findValueForTherapyStatusGraph("On Therapy", "Outbound", searchFilterData),
												"outbound Color": "hsl(41, 70%, 50%)",
											},
											{
												"Therapy Status": "Therapy Repeat ",
												Inbound: findValueForTherapyStatusGraph("ON THERAPY REPEAT", "Inbound", searchFilterData),
												"inbound Color": "rgb(224,198,255)",
												Outbound: findValueForTherapyStatusGraph("ON THERAPY REPEAT", "Outbound", searchFilterData),
												"outbound Color": "hsl(41, 70%, 50%)",
											},
											{
												"Therapy Status": "In-Process",
												Inbound: findValueForTherapyStatusGraph("IN PROCESS", "Inbound", searchFilterData),
												"inbound Color": "rgb(224,198,255)",
												Outbound: findValueForTherapyStatusGraph("IN PROCESS", "Outbound", searchFilterData),
												"outbound Color": "hsl(41, 70%, 50%)",
											},
											{
												"Therapy Status": "Unknown",
												Inbound: findValueForTherapyStatusGraph("UNKNOWN", "Inbound", searchFilterData),
												"inbound Color": "rgb(224,198,255)",
												Outbound: findValueForTherapyStatusGraph("UNKNOWN", "Outbound", searchFilterData),
												"outbound Color": "hsl(41, 70%, 50%)",
											},
											{
												"Therapy Status": "Never Start",
												Inbound: findValueForTherapyStatusGraph("NEVER START", "Inbound", searchFilterData),
												"inbound Color": "rgb(224,198,255)",
												Outbound: findValueForTherapyStatusGraph("NEVER START", "Outbound", searchFilterData),
												"outbound Color": "hsl(41, 70%, 50%)",
											},
										]}
										keys={["Inbound", "Outbound"]}
										indexBy="Therapy Status"
										margin={{
											top: 20,
											right: 20,
											bottom: 90,
											left: 35,
										}}
										padding={0.3}
										valueScale={{ type: "linear" }}
										indexScale={{
											type: "band",
											round: true,
										}}
										// colors={{ scheme: "nivo" }}
										colors={getColor}
										defs={[
											{
												id: "dots",
												type: "patternDots",
												background: "inherit",
												color: "#38bcb2",
												size: 4,
												padding: 1,
												stagger: true,
											},
											{
												id: "lines",
												type: "patternLines",
												background: "inherit",
												color: "#eed312",
												rotation: -45,
												lineWidth: 6,
												spacing: 10,
											},
										]}
										borderColor={{
											from: "color",
											modifiers: [["darker", 1.6]],
										}}
										axisTop={null}
										axisRight={null}
										axisBottom={{
											format: (v) => {
												return v.length > 14 ? (
													<tspan>
														{v.substring(0, 12) + ".."}
														<p>{v}</p>
													</tspan>
												) : (
													v
												);
											},
											tickSize: 5,
											tickPadding: 5,
											tickRotation: 0,
											legend: <tspan>Therapy Status</tspan>,
											legendPosition: "middle",
											legendOffset: 40,
										}}
										axisLeft={{
											format: (e) => Math.floor(e) === e && e,
											tickSize: 5,
											tickPadding: 5,
											tickRotation: 0,
											legend: "food",
											legendPosition: "middle",
											legendOffset: -40,
										}}
										labelSkipWidth={12}
										labelSkipHeight={12}
										labelTextColor={{
											from: "color",
											modifiers: [["darker", 1.6]],
										}}
										legends={[
											{
												dataFrom: "keys",
												anchor: "bottom",
												direction: "row",
												justify: false,
												translateX: 30,
												translateY: 80,
												itemsSpacing: 2,
												itemWidth: 100,
												itemHeight: 20,
												itemDirection: "left-to-right",
												itemOpacity: 0.85,
												symbolSize: 20,
												effects: [
													{
														on: "hover",
														style: {
															itemOpacity: 1,
														},
													},
												],
											},
										]}
										role="application"
										ariaLabel="Therapy Status Distribution"
									/>
								</div>
								<p className="font-semibold mt-5 text-sm mb-3">
									Discussion Sentiment
									<span className="font-normal float-right">
										Select Level
										<Select
											clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
											className="ml-4 -mt-2 float-right w-32"
											placeholder="Select Quality"
											allowClear
											defaultValue={selectLevel ? selectLevel : "Speaker"}
											onChange={(value: string) => {
												handleFilters2(value);
											}}>
											<Select.Option value="Topic">Topic</Select.Option>
											<Select.Option value="Speaker">Speaker</Select.Option>
										</Select>
									</span>
								</p>
								<div className="flex h-60 border-2">
									{selectLevel === "Topic" ? (
										<ResponsiveBar
											layout="horizontal"
											colors={getColor}
											data={arrForFilteringChart.map((i: any) => i)}
											keys={["Positive", "Neutral", "Negative"]}
											indexBy="Topic"
											margin={{
												top: 10,
												right: 110,
												bottom: 30,
												left: 160,
											}}
											padding={0.3}
											valueScale={{ type: "linear" }}
											indexScale={{
												type: "band",
												round: true,
											}}
											defs={[
												{
													id: "dots",
													type: "patternDots",
													background: "inherit",
													color: "#38bcb2",
													size: 4,
													padding: 1,
													stagger: true,
												},
												{
													id: "lines",
													type: "patternLines",
													background: "inherit",
													color: "#eed312",
													rotation: -45,
													lineWidth: 6,
													spacing: 10,
												},
											]}
											borderColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											axisTop={null}
											axisRight={null}
											axisBottom={{
												tickSize: 0,
												tickPadding: 5,
												tickRotation: 0,
												legend: "",
												legendPosition: "middle",
												legendOffset: 38,
											}}
											axisLeft={{
												tickSize: 5,
												tickPadding: 5,
												tickRotation: 0,
												legendPosition: "middle",
												legendOffset: -40,
											}}
											labelSkipWidth={12}
											labelSkipHeight={12}
											labelTextColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											legends={[
												{
													dataFrom: "keys",
													anchor: "bottom-right",
													direction: "column",
													justify: false,
													translateX: 120,
													translateY: 0,
													itemsSpacing: 2,
													itemWidth: 100,
													itemHeight: 20,
													itemDirection: "left-to-right",
													itemOpacity: 0.85,
													symbolSize: 20,
													effects: [
														{
															on: "hover",
															style: {
																itemOpacity: 1,
															},
														},
													],
												},
											]}
											role="application"
											ariaLabel="Therapy Status Distribution"
										/>
									) : (
										<ResponsiveBar
											layout="horizontal"
											colors={getColor}
											data={[
												{
													Sentiment: "Patient",
													Positive: findValueForSentimentGraph("Patient", "Positive", searchFilterData, false),
													"positive Color": "hsl(251, 70%, 50%)",
													Neutral: findValueForSentimentGraph("Patient", "Neutral", searchFilterData, false),
													"neutral Color": "hsl(41, 70%, 50%)",
													Negative: findValueForSentimentGraph("Patient", "Negative", searchFilterData, false),
													"negative Color": "hsl(41, 70%, 50%)",
												},
												{
													Sentiment: "Agent",
													Positive: findValueForSentimentGraph("Agent", "Positive", searchFilterData, false),
													"positive Color": "hsl(251, 70%, 50%)",
													Neutral: findValueForSentimentGraph("Agent", "Neutral", searchFilterData, false),
													"neutral Color": "hsl(41, 70%, 50%)",
													Negative: findValueForSentimentGraph("Agent", "Negative", searchFilterData, false),
													"negative Color": "hsl(41, 70%, 50%)",
												},
											]}
											keys={["Positive", "Neutral", "Negative"]}
											indexBy="Sentiment"
											margin={{
												top: 20,
												right: 130,
												bottom: 50,
												left: 60,
											}}
											padding={0.3}
											valueScale={{ type: "linear" }}
											indexScale={{
												type: "band",
												round: true,
											}}
											defs={[
												{
													id: "dots",
													type: "patternDots",
													background: "inherit",
													color: "#38bcb2",
													size: 4,
													padding: 1,
													stagger: true,
												},
												{
													id: "lines",
													type: "patternLines",
													background: "inherit",
													color: "#eed312",
													rotation: -45,
													lineWidth: 6,
													spacing: 10,
												},
											]}
											borderColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											axisTop={null}
											axisRight={null}
											axisBottom={{
												tickSize: 0,
												tickPadding: 5,
												tickRotation: 0,
												legend: "",
												legendPosition: "middle",
												legendOffset: 38,
											}}
											axisLeft={{
												tickSize: 5,
												tickPadding: 5,
												tickRotation: 0,
												legendPosition: "middle",
												legendOffset: -40,
											}}
											labelSkipWidth={12}
											labelSkipHeight={12}
											labelTextColor={{
												from: "color",
												modifiers: [["darker", 1.6]],
											}}
											legends={[
												{
													dataFrom: "keys",
													anchor: "bottom-right",
													direction: "column",
													justify: false,
													translateX: 120,
													translateY: 0,
													itemsSpacing: 2,
													itemWidth: 100,
													itemHeight: 20,
													itemDirection: "left-to-right",
													itemOpacity: 0.85,
													symbolSize: 20,
													effects: [
														{
															on: "hover",
															style: {
																itemOpacity: 1,
															},
														},
													],
												},
											]}
											role="application"
											ariaLabel="Therapy Status Distribution"
										/>
									)}
								</div>

								<p className="font-semibold mt-5 text-sm mb-3">
									Time on Therapy Distribution
									<span className="font-normal float-right">
										Show Therapy Status
										<Switch className="ml-2" onChange={handleFilters3} />
									</span>
								</p>
								<div className="flex h-60 border-2">
									{therapyStatus ? (
										<ResponsiveLine
											data={[
												{
													id: "On Therapy",
													color: "hsl(331, 70%, 50%)",
													data: [
														{
															x: "30 days",
															y: findValueForToT(-99999, 30, searchFilterData, "On Therapy"),
														},
														{
															x: "60 days",
															y: findValueForToT(30, 60, searchFilterData, "On Therapy"),
														},
														{
															x: "90 days",
															y: findValueForToT(60, 90, searchFilterData, "On Therapy"),
														},
														{
															x: "180 days",
															y: findValueForToT(90, 180, searchFilterData, "On Therapy"),
														},
														{
															x: "360 days",
															y: findValueForToT(180, 360, searchFilterData, "On Therapy"),
														},
														{
															x: "360+ days",
															y: findValueForToT(360, 99999999, searchFilterData, "On Therapy"),
														},
													],
												},
												{
													id: "Discontinued",
													color: "hsl(281, 70%, 50%)",
													data: [
														{
															x: "30 days",
															y: findValueForToT(-99999, 30, searchFilterData, "Discontinued"),
														},
														{
															x: "60 days",
															y: findValueForToT(30, 60, searchFilterData, "Discontinued"),
														},
														{
															x: "90 days",
															y: findValueForToT(60, 90, searchFilterData, "Discontinued"),
														},
														{
															x: "180 days",
															y: findValueForToT(90, 180, searchFilterData, "Discontinued"),
														},
														{
															x: "360 days",
															y: findValueForToT(180, 360, searchFilterData, "Discontinued"),
														},
														{
															x: "360+ days",
															y: findValueForToT(360, 99999999, searchFilterData, "Discontinued"),
														},
													],
												},
												{
													id: "Therapy Repeat",
													color: "hsl(281, 70%, 50%)",
													data: [
														{
															x: "30 days",
															y: findValueForToT(-99999, 30, searchFilterData, "Therapy Repeat"),
														},
														{
															x: "60 days",
															y: findValueForToT(30, 60, searchFilterData, "Therapy Repeat"),
														},
														{
															x: "90 days",
															y: findValueForToT(60, 90, searchFilterData, "Therapy Repeat"),
														},
														{
															x: "180 days",
															y: findValueForToT(90, 180, searchFilterData, "Therapy Repeat"),
														},
														{
															x: "360 days",
															y: findValueForToT(180, 360, searchFilterData, "Therapy Repeat"),
														},
														{
															x: "360+ days",
															y: findValueForToT(360, 99999999, searchFilterData, "Therapy Repeat"),
														},
													],
												},
												{
													id: "In Process",
													color: "hsl(281, 70%, 50%)",
													data: [
														{
															x: "30 days",
															y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
														},
														{
															x: "60 days",
															y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
														},
														{
															x: "90 days",
															y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
														},
														{
															x: "180 days",
															y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
														},
														{
															x: "360 days",
															y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
														},
														{
															x: "360+ days",
															y: findValueForToT(-99999, 30, searchFilterData, "In Process"),
														},
													],
												},
												{
													id: "Unknown",
													color: "hsl(281, 70%, 50%)",
													data: [
														{
															x: "30 days",
															y: findValueForToT(-99999, 30, searchFilterData, "UNKNOWN"),
														},
														{
															x: "60 days",
															y: findValueForToT(30, 60, searchFilterData, "UNKNOWN"),
														},
														{
															x: "90 days",
															y: findValueForToT(60, 90, searchFilterData, "UNKNOWN"),
														},
														{
															x: "180 days",
															y: findValueForToT(90, 180, searchFilterData, "UNKNOWN"),
														},
														{
															x: "360 days",
															y: findValueForToT(180, 360, searchFilterData, "UNKNOWN"),
														},
														{
															x: "360+ days",
															y: findValueForToT(360, 99999, searchFilterData, "UNKNOWN"),
														},
													],
												},
												{
													id: "Never Start",
													color: "hsl(281, 70%, 50%)",
													data: [
														{
															x: "30 days",
															y: findValueForToT(-99999, 30, searchFilterData, "Never Start"),
														},
														{
															x: "60 days",
															y: findValueForToT(30, 60, searchFilterData, "Never Start"),
														},
														{
															x: "90 days",
															y: findValueForToT(60, 90, searchFilterData, "Never Start"),
														},
														{
															x: "180 days",
															y: findValueForToT(90, 180, searchFilterData, "Never Start"),
														},
														{
															x: "360 days",
															y: findValueForToT(180, 360, searchFilterData, "Never Start"),
														},
														{
															x: "360+ days",
															y: findValueForToT(360, 99999999, searchFilterData, "Never Start"),
														},
													],
												},
											]}
											margin={{
												top: 20,
												right: 130,
												bottom: 60,
												left: 60,
											}}
											xScale={{ type: "point" }}
											yScale={{
												type: "linear",
												min: "auto",
												max: "auto",
												stacked: false,
												reverse: false,
											}}
											yFormat=" >-.2f"
											axisTop={null}
											axisRight={null}
											enableGridX={false}
											pointSize={10}
											pointColor={{ theme: "background" }}
											pointBorderWidth={2}
											pointBorderColor={{
												from: "serieColor",
											}}
											pointLabelYOffset={-12}
											useMesh={true}
											axisLeft={{
												format: (e) => Math.floor(e) === e && e,
											}}
											axisBottom={{
												tickSize: 5,
												tickPadding: 5,
												tickRotation: 0,
												legend: <tspan className="font-semibold">Time on Therapy</tspan>,
												legendPosition: "middle",
												legendOffset: 40,
											}}
											legends={[
												{
													anchor: "bottom-right",
													direction: "column",
													justify: false,
													translateX: 100,
													translateY: 0,
													itemsSpacing: 0,
													itemDirection: "left-to-right",
													itemWidth: 80,
													itemHeight: 20,
													itemOpacity: 0.75,
													symbolSize: 12,
													symbolShape: "circle",
													symbolBorderColor: "rgba(0, 0, 0, .5)",
													effects: [
														{
															on: "hover",
															style: {
																itemBackground: "rgba(0, 0, 0, .03)",
																itemOpacity: 1,
															},
														},
													],
												},
											]}
										/>
									) : (
										<ResponsiveLine
											data={[
												{
													id: "Total",
													color: "hsl(331, 70%, 50%)",
													data: [
														{
															x: "30 days",
															y: findValueForToT(-99999, 30, searchFilterData),
														},
														{
															x: "60 days",
															y: findValueForToT(30, 60, searchFilterData),
														},
														{
															x: "90 days",
															y: findValueForToT(60, 90, searchFilterData),
														},
														{
															x: "180 days",
															y: findValueForToT(90, 180, searchFilterData),
														},
														{
															x: "360 days",
															y: findValueForToT(180, 360, searchFilterData),
														},
														{
															x: "360+ days",
															y: findValueForToT(360, 99999999, searchFilterData),
														},
													],
												},
											]}
											margin={{
												top: 20,
												right: 80,
												bottom: 60,
												left: 50,
											}}
											xScale={{ type: "point" }}
											yScale={{
												type: "linear",
												min: "auto",
												max: "auto",
												stacked: false,
												reverse: false,
											}}
											axisBottom={{
												tickSize: 5,
												tickPadding: 5,
												tickRotation: 0,
												legend: <tspan className="font-semibold">Time on Therapy</tspan>,
												legendPosition: "middle",
												legendOffset: 40,
											}}
											colors="#3287C4"
											yFormat=" >-.2f"
											axisTop={null}
											axisLeft={{
												format: (e) => Math.floor(e) === e && e,
											}}
											axisRight={null}
											enableGridX={false}
											pointSize={10}
											pointColor={{ theme: "background" }}
											pointBorderWidth={2}
											pointBorderColor={{
												from: "serieColor",
											}}
											pointLabelYOffset={-12}
											useMesh={true}
											legends={[
												{
													anchor: "bottom-right",
													direction: "column",
													justify: false,
													translateX: 100,
													translateY: 0,
													itemsSpacing: 0,
													itemDirection: "left-to-right",
													itemWidth: 80,
													itemHeight: 20,
													itemOpacity: 0.75,
													symbolSize: 12,
													symbolShape: "circle",
													symbolBorderColor: "rgba(0, 0, 0, .5)",
													effects: [
														{
															on: "hover",
															style: {
																itemBackground: "rgba(0, 0, 0, .03)",
																itemOpacity: 1,
															},
														},
													],
												},
											]}
										/>
									)}
								</div>

								<p className="font-semibold mt-5 text-sm mb-3">Location of Calls</p>
								<div
									className="border-2"
									style={{
										height: "240px",
										overflow: "hidden",
									}}>
									<div style={{ height: "476px" }}>
										<ResponsiveChoropleth
											data={transcriptData.length > 0 ? transcriptData[0].statesResults : []}
											features={usFeatures.features}
											margin={{
												top: 0,
												right: 0,
												bottom: 0,
												left: 0,
											}}
											colors={["#D8ECFB", "#99D3FD", "#3B9FE7", "#2A71A4"]}
											domain={[
												0,
												Math.max.apply(
													Math,
													transcriptData[0].statesResults.map(function (o: any) {
														return o.value;
													})
												),
											]}
											unknownColor="#666666"
											label="properties.name"
											projectionScale={140}
											projectionTranslation={[0.52, 0.24]}
											projectionRotation={[0, 0, 0]}
											enableGraticule={false}
											graticuleLineColor="#dddddd"
											borderWidth={0.5}
											borderColor="#152538"
										/>
									</div>
								</div>
								<p className="mt-7 font-semibold text-sm">Key Words and Concepts</p>
								<div className="flex h-60 mt-2 border-2">
									<ResponsiveBar
										layout="horizontal"
										colors={"#3B9FE7"}
										data={temp.reverse()}
										keys={["value"]}
										indexBy="text"
										margin={{
											top: 15,
											right: 30,
											bottom: 15,
											left: 200,
										}}
										padding={0.3}
										valueScale={{ type: "linear" }}
										borderColor={{
											from: "color",
											modifiers: [["darker", 1.6]],
										}}
										enableGridY={false}
										axisTop={null}
										axisRight={null}
										axisBottom={null}
										axisLeft={{
											tickSize: 0,
											tickPadding: 5,
											tickRotation: 0,
											legendPosition: "middle",
											legendOffset: -40,
										}}
										labelSkipWidth={12}
										labelSkipHeight={20}
										labelTextColor={{
											from: "color",
											modifiers: [["darker", 1.6]],
										}}
										role="application"
										ariaLabel="Therapy Status Distribution"
									/>
								</div>
							</div>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
}

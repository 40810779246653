export const usData = [
    {
      "id": "01",
      "value": 685849
    },
    {
      "id": "06",
      "value": 733895
    },
    {
      "id": "12",
      "value": 847004
    },
    {
      "id": "18",
      "value": 229583
    },
    {
        "id": "34",
        "value": 204245
    },
    {
      "id": "42",
      "value": 229544
    },
    {
        "id": "02",
        "value": 40323
    },
    {
      "id": "18",
      "value": 229584
    },
    {
        "id": "49",
        "value": 202234
    },
    {
      "id": "38",
      "value": 229583
    },
    {
        "id": "28",
        "value": 2029244
    }
]
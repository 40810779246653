import React from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import SubHeaderComponent from "../../../Components/SubHeaderComponent";
import StoryMode from "../../SearchEngine/OldStories/storyMode";
import "../style.css";
import DataTable from "./SubComponents/DataTable";
// import * as NodeGraphServiceHelper from "../../SearchEngine/ServiceHelpers/nodeGraph";
// import { storyTypes, dummyDataTable } from "../data";
// import { Modal } from "antd";
// import AddStoryModalContent from "../../Components/AddStoryModal";
// import DeleteAlert from "../../Components/DeleteAlert";
// import TableWrapper from "../../Components/TableWrapper";
// import * as StoriesServiceHelper from "./../SearchEngine/ServiceHelpers/stories";
// import * as Messages from "../../Components/Messages";

// const DUMMY_DATA = [
// 	{
// 		id: 11,
// 		"Story Name": "Query about Alcohol Consumption",
// 		"Story Type": "General Tracker",
// 		"Date Created": "12 Aug 2020",
// 		"# of Annotated Selections": 12,
// 		Status: "In Draft",
// 	},
// 	{
// 		id: 22,
// 		"Story Name": "Worried about Side Effects",
// 		"Story Type": "Issues",
// 		"Date Created": "15 Sept 2020",
// 		"# of Annotated Selections": 42,
// 		Status: "Published",
// 	},
// 	{
// 		id: 33,
// 		"Story Name": "Unhappy with Meal Restrictions",
// 		"Story Type": "Issues",
// 		"Date Created": "19 Aug 2020",
// 		"# of Annotated Selections": 15,
// 		Status: "In Draft",
// 	},
// 	{
// 		id: 44,
// 		"Story Name": "Receiving too many Calls",
// 		"Story Type": "General Tracker",
// 		"Date Created": "22 Oct 2020",
// 		"# of Annotated Selections": 26,
// 		Status: "In Draft",
// 	},
// 	{
// 		id: 55,
// 		"Story Name": "Unhappy with Pill Box",
// 		"Story Type": "Issues",
// 		"Date Created": "15 Jun 2020",
// 		"# of Annotated Selections": 6,
// 		Status: "Published",
// 	},
// 	{
// 		id: 66,
// 		"Story Name": "Unhappy with Pill Box",
// 		"Story Type": "General Tracker",
// 		"Date Created": "15 Jun 2020",
// 		"# of Annotated Selections": 6,
// 		Status: "Published",
// 	},
// 	{
// 		id: 77,
// 		"Story Name": "Unhappy with Pill Box",
// 		"Story Type": "Issues",
// 		"Date Created": "15 Jun 2020",
// 		"# of Annotated Selections": 6,
// 		Status: "Published",
// 	},
// 	{
// 		id: 88,
// 		"Story Name": "Unhappy with Pill Box",
// 		"Story Type": "Issues",
// 		"Date Created": "15 Jun 2020",
// 		"# of Annotated Selections": 6,
// 		Status: "Published",
// 	},
// ];

/**
 * Renders the Stories Table. This function also handles the APIs for this table.
 *
 * @returns Stories Table View.
 */
function StoriesTable({data}: any): JSX.Element {
  console.log("Checking...", data);
  const [fetching, setFetching] = React.useState(true);
  const [fetchedData, setFetchedData] = React.useState<
    {
      [id: string]: string | number;
    }[]
  >([]);
//   const [isModalVisible, setIsModalVisible] = React.useState(false);
//   const [alertVisible, setAlertVisible] = React.useState(false);
//   const [modalContent, setModalContent] = React.useState(<></>);
//   const [selectDelete, setSelectDelete] = React.useState("");

	React.useEffect(() => {
		if (data) {
			setFetchedData(data);
			setFetching(false);
		} else {			
			setFetching(true);		
		  }
	}, [data]);

	return (
		<>
			{!fetching ? (
				<>
					<div className="bg-body py-4">
						<DataTable
							data={fetchedData }
							loading={false}
						/>
					</div>
				</>
			) : (
				<>
					<div className="shadow-lg relative">
						<SubHeaderComponent title="Stories" />
					</div>
					<div className="stories-body bg-body p-4">
						<DataTable data={{}} loading={true} />
					</div>
				</>
			)}
		</>
	);
}
/**
 * Renders the Stories Tab View. This function sets up the routes within the Stories Tab.
 *
 * @returns Stories Tab View Routes.
 */
export function PatientDisconTable({data}: any) {
  return (
    <>
      <Routes>
        <Route key={"Stories 0"} path="/" element={<StoriesTable data={data} />} />
        <Route key="Stories - Stories Mode" path="story-mode/:storyId/*" element={<StoryMode />} />
      </Routes>
    </>
  );
}

import { request } from "./../App";

export const getAnnotations = async (id: string) => {
  const fetchUrl = `originalStory/GetAnnotations?id=${id}`;
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const deleteAnnotations = async (annotationId: number, storyId: string, elasticSearchId: string) => {
  const fetchUrl = `originalStory/DeleteAnnotatedSelection?id=${annotationId}&storyId=${storyId}&elasticSearchId=${elasticSearchId}`;
  const data = await request
    .post(fetchUrl, {}, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const getHighlights = async (id: number) => {
  const fetchUrl = `originalStory/GetEditAnnotatedSelection?id=${id}`;
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const editHighlights = async (editData: { id: number; highlightText: string; Tags: string[]; highlightedString: string }) => {
  const fetchUrl = "originalStory/EditAnnotatedSelection";
  const data = await request
    .put(fetchUrl, editData, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getOriginalStorybyId = async (storyId: string) => {
	const fetchUrl = `originalStory/GetOriginalStoryById?id=${storyId}`;
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

import React, { useState, useEffect } from "react";
import TranscriptData from "../MultiFormatFileRendering/TranscriptData";
import axios from "axios";
import { message } from "antd";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import CSVRender from "../MultiFormatFileRendering/CSVRender";
import ByteImageRender from "../MultiFormatFileRendering/ByteImageRender";

export interface fullTranscript {
  highlight?: boolean;
  renderText?: string;
  speaker: string;
  text: string;
  dialogueNo: number;
}

interface transModalPayloadData {
  transcriptData: any;
  presignedURL: string;
  format: string;
}

export function TranscriptModalData(props: {
  id?: number;
  fullTranscriptData?: fullTranscript[];
  keywords?: string[];
  transPayloadData: transModalPayloadData;
  highlightedDialogueNo?: number[];
}) {
  const [fetchedData, setFetchedData] = React.useState<any>([]);
  const [showKeyWords, setShowKeywords] = React.useState<boolean>(!!(props.keywords && props.keywords.length));
  const [loading, setLoading] = React.useState<boolean>(true);

  async function fetchDataFromS3() {
    try {
      // console.log("S3 Presigned URL: ", props.transPayloadData.presignedURL); // Commented out console.log
      const headers = {
        'Access-Control-Allow-Origin': process.env.PUBLIC_URL, // Replace with your allowed origin
      };
      const data: any = await axios.get(props.transPayloadData.presignedURL, { headers });

      if (data) {
        // console.log("S3 Fetched Data: ", data); // Commented out console.log
        // OLD APPROACH - Just using pre-signed url for image
        // const response = props.transPayloadData.format === "image" ? { url: props.transPayloadData.presignedURL } : data.data;

        const response = data.data;
        setFetchedData(response);
        setLoading(false);
      } else {
        console.error("Error fetching transcript data from S3");
      }
    } catch (error) {
      // message.error("Error fetching S3 Transcript Data");
      console.error("Error fetching S3 Transcript Data:", error);
    }
  }

  useEffect(() => {
    if (showKeyWords) {
      const pattern = new RegExp(`(${props.keywords?.join("|")})`, "gi");
      setFetchedData((prevData: any) =>
        prevData.map((d: any) => {
          return {
            ...d,
            renderText: `<strong>${d.speaker}</strong>: ${d.text.replace(pattern, (match: any) => `<span>${match}</span>`)}`,
            highlight: d.text.includes("<span>"),
          };
        })
      );
    }
  }, [props.keywords, showKeyWords]);

  useEffect(() => {
    //      console.log("Inside TranscriptModal Component with format: ", props.transPayloadData.format); // Commented out console.log
    if (props.transPayloadData.format === "json") setFetchedData(props.transPayloadData.transcriptData);
    else fetchDataFromS3();
  }, [props.transPayloadData.format]);

  // if (showKeyWords) {
  //   const pattern = new RegExp(`(${props.keywords?.join("|")})`, "gi");
  //   fetchedData.forEach((d) => {
  //     d.renderText = `<strong>${d.speaker}</strong>: `;
  //     d.renderText += d.text.replace(pattern, (match) => `<span>${match}</span>`);
  //     d.highlight = d.renderText.includes("<span>");
  //   });
  // }

  // let dummyFlagVar = 'image';
  const renderContent = () => {
    switch (props.transPayloadData.format) {
      // switch (dummyFlagVar) {
      case "json":
        return (
          <div className="text-base pb-2 px-2 h-108 overflow-auto">
            {fetchedData.length > 0 &&
              fetchedData.map((detail: fullTranscript) => (
                <TranscriptData key={detail.dialogueNo} detail={detail} showKeywords={showKeyWords} highlightedDialogueNo={props.highlightedDialogueNo} />
              ))}
            {fetchedData.length === 0 && <p>Loading...</p>}
          </div>
        );
      case "image":
        return fetchedData && props.transPayloadData.presignedURL ? <img src={props.transPayloadData.presignedURL} alt="Transcript Data" /> : <ByteImageRender s3Path={props.transPayloadData} />
        // OLD APPROACH - Just using pre-signed url for image
        // return fetchedData.url ? <img src={fetchedData.url} alt="S3 Image" /> : <div>Loading image...</div>;
      case "pdf":
        return fetchedData && props.transPayloadData.presignedURL ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <iframe title="PDF Transcript Viewer" src={props.transPayloadData.presignedURL} width="95%" height="540px" />
          </div> 
        ) : "Loading..."; 
      case "text":
        return fetchedData ? <Markdown remarkPlugins={[breaks, gfm]}>{fetchedData}</Markdown> : "Loading...";
      case "csv":
        // return fetchedData && !loading ? <CSVDataRender data={fetchedData} /> : "Loading...";
        return fetchedData && !loading ? <CSVRender data={fetchedData} /> : "Loading...";
      default:
        return (
          <div className="text-base pb-2 px-2 h-108 overflow-auto">
            {fetchedData.length > 0 &&
              fetchedData.map((detail: fullTranscript) => (
                <TranscriptData key={detail.dialogueNo} detail={detail} showKeywords={showKeyWords} highlightedDialogueNo={props.highlightedDialogueNo} />
              ))}
            {fetchedData.length === 0 && <p>Loading...</p>}
          </div>
        );
    }
  };

  return (
    <div className="text-base pb-2 px-2 h-108 overflow-auto">
      <div>{renderContent()}</div>
    </div>
  );
}

import { Discover } from "./Discover"
import { DiscoverModuleTabs } from "../SearchEngine/data";
import SubNavigation from "../../Components/SubNavigation";
import { Search } from "../SearchEngine/Search";
import { StoriesView } from "../Stories/StoriesView";
// import React from "react";
// import { Route, Routes } from "react-router-dom";
// import NodeGraph from "./components/NodeGraph";
// import myTreeData from "./data.json";

export default function DiscoverModule() {
  const components = [
    <Discover />,
    <Search />,
    <StoriesView />,
  ]

  const tabs = DiscoverModuleTabs();
  return (
    <>
      <SubNavigation tabs={tabs} />
      <Discover />
      {/* <div className="">
        <Routes>
          {tabs.map((subTab, index) => (
            <Route
              key={index}
              path={index === 0 ? "/" : subTab.subTab.split(" ").join("_") + "/*"}
              element={components[index]}
            />
          ))}
        </Routes>
      </div> */}
    </>
  );
};

import * as ReportTypesComponentProps from "../../interfaces/main";
/**
 * Transform the Data from the Default Type to the type accepted by Pie Chart of Nivo.
 *
 * @param data - Raw Data
 * @param series - Series details
 * @param colDim - Array containing the Series names
 * @returns Transformed data
 */
export default function transformData(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"],
	colDim: number
) {
	let res: any = []; //Check this type

	series.map((s, idx) => {
		return res.push({
			id: s.name,
			label: s.name,
			color: s.color,
			value: data[s.dim][colDim],
		});
	});

	return res;
}

import ReportTypes from "../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { chartAreaLayout } from "../../libraries/report-types/charts/interfaces/main";
import NeedleChart from "../NeedleChart";

/**
 * This function is used to render the Needle  bar Chart
 * 
 * @param data - This props contains the data of the chart
 * @param noHeader - This props is used to check whether header is present or not
 * @param range - This props represent the range of the chart
 * @param unit - This props represent the unit of  the data
 *
 * @returns three cards in a grid form
 */

export default function BarChartWithBg(props: {
	series: any;
	data: any;
	backgroundDivisions: number;
	noHeader: boolean;
	range: number[];
	unit: string;
}) {
	return (
		<div className="bg-white p-2">
			{!props.noHeader && (
				<div className="text-text-gray-90 font-bold text-sm">
					{`${props.data[0]}`}
				</div>
			)}
			<div className="flex align-middle justify-between">
				<div className="w-2/12 text-4xl font-semibold text-text-gray-90 mt-px">
					{`${Math.round(props.data[1])}${props.unit}`}
				</div>
				<div className="w-10/12">
					<NeedleChart
						data={[
							{
								id: props.data[0],
								ranges: props.range,
								measures: [0],
								markers: [Math.round(props.data[1])],
							},
						]}
						minValue={props.range[0]}
					/>
					{/* <svg width="100%" height="100%">
						<rect
							className="chartSvg2"
							// x="50%"
							// y="-50%"
						/>
					</svg> */}
					{/* <ReportTypes
						// header={props.data[1].chartName[indexElement]}
						hideShadow
						borderedHeading
						hideRibbon={true}
						minHeight="5px"
						height="4.2em"
						bodyPadding="0px 20px"
						bodyChartContent={["0%", "50%", "100%"]}
						title={{ title: "xx" }}
						chartType={ReportTypesComponentInterface.chartType.bar}
						series={props.series}
						data={props.data}
						axis={{
							primaryX: {
								enable: false,
								dim: 0,
								minValue: 0,
								maxValue: 100,
								tickValues: [],
							},
							primaryY: {
								enable: false,
								tickSize: 0,

								tickValues: [],
							},
						}}
						chartArea={{
							layout: chartAreaLayout.horizontal,
							backgroundDivision:
								props.backgroundDivisions === 2 ? 2 : 3,
						}}
						chartConfig={{
							cartesian: {
								grid: {
									enableGridX: true,
									enableGridY: false,
									// gridYValues: 5,
									gridXValues: [],
								},
								gaps: { barGap: 0.5, groupGap: 0 },
							},
						}}
						style={{
							margin: {
								top: 0,
								right: 0,
								bottom: 0,
								left: 0,
							},
						}}
					/> */}
				</div>
			</div>
		</div>
	);
}

export const Icon = () => (
	<svg
		width="34"
		height="42"
		viewBox="0 0 34 42"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M16.674 27.3C25.242 27.3 32.319 33.705 33.348 42H0C1.029 33.705 8.106 27.3 16.674 27.3ZM12.873 32.088C9.849 33.033 7.308 35.091 5.754 37.8H16.674L12.873 32.088ZM20.475 32.088L16.674 37.8H27.594C26.019 35.091 23.478 33.033 20.475 32.088ZM29.274 0V12.6C29.274 19.551 23.625 25.2 16.674 25.2C9.723 25.2 4.074 19.551 4.074 12.6V0H29.274ZM8.274 12.6C8.274 17.241 12.033 21 16.674 21C21.315 21 25.074 17.241 25.074 12.6H8.274ZM25.074 4.2H8.274V8.4H25.074V4.2Z"
			fill="#ec7200"
		/>
	</svg>
);


/**
 * This function is used to render the circle wih a number written in it
 * 
 * @param  value - This props represent the value of the card circle
 * @param header - This props is used to check whether header is present or not
 *
 * @returns the circle card with a number written inside
 */

export default function CardHeaderCircle(props: {
	value: string;
	header?: boolean;
}) {
	const bg: { [id: string]: string } = {
		"30": "bg-border-blue-10",
		"60": "bg-border-blue-20",
		"90": "bg-border-blue-30",
		"180": "bg-border-blue-40",
		"180+": "bg-border-blue-50",
	};
	return (
		<div className="mt-8">
			{props.header ? (
				<div className="text-text-gray-90 font-semibold text-base px-4 py-1">
					Days on Therapy
				</div>
			) : (
				<div className="py-1 h-8"></div>
			)}
			<div className={`relative w-full ${bg[props.value]} h-1.5`}>
				<div
					className={`absolute w-20 h-20 rounded-full ${
						bg[props.value]
					} -top-10 right-4`}
				></div>
				<div className="absolute w-16 h-16 rounded-full bg-white -top-8 right-6">
					<div className="flex items-center justify-center text-text-gray-90 font-bold h-full text-2xl">
						{props.value}
					</div>
				</div>
			</div>
		</div>
	);
}

import { FilterInterface } from "..";
import * as DashboardService from "../../../services/Dashboard";

export const getCustomerSatisfaction: (
	filters: FilterInterface,
    filterState: any
) => Promise<unknown> = async (filters: FilterInterface, filterState: any) => {
	const data = await DashboardService.getCustomerSatisfaction(filters, filterState);
    var smColors = [["#3287C4"], ["#3287C4"], ["#3287C4"]];
    var csData: any = data;
    csData.surveyMetrics.forEach((survey: any, index: number) => {
        if(Object.keys(survey.customerTopChart).length > 0) {
            csData.surveyMetrics[index].topChart = survey.customerTopChart;
        }
        if(Object.keys(survey.customerSatisfactionData).length > 0) {
            csData.surveyMetrics[index].chartData = [survey.customerSatisfactionData];
        }
        else {
            csData.surveyMetrics[index].chartData = [];
        }
        csData.surveyMetrics[index].chartColor = smColors[index];
    })

    csData.callExperience.forEach((call: any, index: number) => {
        if(Object.keys(call.customerTopChart).length > 0) {
            csData.callExperience[index].topChart = call.customerTopChart;
        }
        if(Object.keys(call.customerSatisfactionData).length > 0) {
            csData.callExperience[index].chartData = [call.customerSatisfactionData];
        }
        else {
            csData.callExperience[index].chartData = [];
        }
        csData.callExperience[index].chartColor = smColors[index];
    })

	return csData;
};

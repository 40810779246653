import React, { useState } from "react";
import { Button, Checkbox, List, Modal, Popconfirm, Popover, Slider, Tooltip } from "antd";
import { icons } from "../../GlobalThemes";
import "./style.css";
import DOMPurify from "dompurify";
import AppContext from "../../store";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";
import { TranscriptModalData } from "../SearchEngineCards/TranscriptModal";
// import { AnnotatedSelectionInterface } from "../../Pages/SearchEngine/Stories/AnnotatedSelections";

const marks: { [id: number]: string } = {
	0: "0",
	16.6: "30",
	33.3: "60",
	50: "90",
	66.6: "180",
	83.3: "360",
	100: "360+",
};
function PopoverContent(props: {
	columnName: string;
	options: string[];
	filtersApplied: {
		[id: string]: string[];
	};
	setFilter?: (
		filtersApplied: {
			[id: string]: string[];
		},
		columnName: string,
		filterValue: string[]
	) => void;
}) {
	// const [checked, setChecked] = React.useState(
	// 	props.filtersApplied[props.columnName]
	// );
	return (
		<div className="">
			{props.options.map((option) => (
				<div>
					<Checkbox
						checked={props.filtersApplied[props.columnName]?.find((c: any) => c === option) ? true : false}
						onChange={(e) => {
							if (!e.target.checked) {
								const temp = [...props.filtersApplied[props.columnName]];
								let index = temp?.indexOf(option);
								temp?.splice(index, 1);
								if (props.setFilter) props.setFilter(props.filtersApplied, props.columnName, temp);
							} else {
								const temp = [...props.filtersApplied[props.columnName]];
								temp.push(option);
								if (props.setFilter) props.setFilter(props.filtersApplied, props.columnName, temp);
							}
						}}>
						{option}
					</Checkbox>
				</div>
			))}
		</div>
	);
}
function ShowMoreContent(props: { data: string[] }) {
	return (
		<div>
			{props.data.map((tag, index) => {
				if (index >= 3) return <p>{tag}</p>;
			})}
		</div>
	);
}
function AccordionItem(props: {
	id: number;
	headerInfo?: string[];
	data: {
		id: number;
		"Annotated Selection": string;
		"Date Added on": string;
		Source: string;
		Tags: string[];
		Status: string;
		highlightText: string;
		highlightedString?: string;
		Description: {
			// tags: string[];
			// dialogueAppearedAt: number;
			// dialogueLength: number;
			// transcriptLength: number;
			dialogueNo: number;
			s3path?: string;
			transcripts: {
				dialogueNo: number;
				speaker: string;
				text: string;
			}[];
		};
	};
	callback: (idx: number) => void;
	active: boolean[];
	showDrawer?: (id: number, header: string, transcriptId: number) => void;
	deleteTranscript?: (id: number) => void;
	createdBy?: string;
	storyType?: number;
}) {
	const context = React.useContext(AppContext);
	const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
	const [fullTranscriptData, setFullTranscriptData] = useState();

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	const handleClickGetFullTrans = async () => {
		setIsModalVisible(true);
		const transData: any = await SearchServiceHelper.getFullTranscript(props.data.Description.s3path ? props.data.Description.s3path : "");
		setFullTranscriptData(transData);
	};
	return (
		<div className="accordion-item">
			<div
				className="bg-white py-2  border-b-2 cursor-pointer"
				onClick={() => {
					props.callback(props.id);
				}}>
				<div className="flex justify-start items-center">
					<div>{props.active[props.id] ? icons.find((l) => l.id === "Accordion Collapse")?.icon : icons.find((l) => l.id === "Accordion Expand")?.icon}</div>
					{props.headerInfo ? (
						<div className="panel-header text-text-gray-80 text-base mt-1 ml-4">
							{Object.entries(props.data).map(([key, value]: any) => {
								if (key === "Tags") {
									return (
										<div className="flex flex-wrap justify-start items-center text-sm text-acordion-tag-text">
											{value.map((tag: string, index: number) => {
												if (index > 2) return <></>;
												if (tag === "Updating")
													return (
														<div className="text-text-gray-80 text-base">
															{icons.find((l) => l.id === "HourGlass")?.icon}
															Updating
														</div>
													);
												return <div className="bg-acordion-tag border-2 rounded-sm border-acordion-tag-border mx-2 px-2">{tag}</div>;
											})}
											{value.length - 3 > 0 && (
												<div className="bg-acordion-tag border-2 rounded-sm border-acordion-tag-border mx-2 px-2">
													<Tooltip title={<ShowMoreContent data={value} />}>{`${value.length - 3} more`}</Tooltip>
												</div>
											)}
										</div>
									);
								} else if (key === "Status") {
									return (
										<div className="flex justify-start">
											{value === "Pending" ? (
												<>
													{icons.find((l) => l.id === "Pending")?.icon}
													{value}
												</>
											) : value === "Complete" ? (
												<>
													{icons.find((l) => l.id === "Complete")?.icon}
													{value}
												</>
											) : (
												<>
													{icons.find((l) => l.id === "HourGlass")?.icon}
													{value}
												</>
											)}
										</div>
									);
								} else if (key === "Annotated Selection") {
									const firstLine = props.data.highlightText.includes("<br>") ? props.data.highlightText.split("<br>")[0] : props.data.highlightText;
									return (
										<div className="annotationHead">
											{/* {props.data.Description.transcripts.map((transcript: any) => {
                        if (transcript.dialogueNo === props.data.Description.dialogueNo)
                          return (
                            <span className="text-sm text-text-gray-90 px-2 py-3">
                              {`${transcript.speaker} : ${transcript.text}`}
                            </span>
                          );
                        else {
                          return <></>;
                        }
                      })} */}
											<div className="text-sm text-text-gray-90 px-2" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(firstLine) }} />
										</div>
									);
								} else if (key === "Date Added on") {
									return (
										<div className="px-4">
											<>{value}</>
										</div>
									);
								} else if (key === "Source") {
									return (
										<div className="px-4">
											<>{value}</>
										</div>
									);
								}
								// else if (key === "ToT") {
								//   return (
								//     <div className="px-4">
								//       <>
								//         {value >= 365
								//           ? value > 730
								//             ? Math.floor(value / 365) +
								//               " years " +
								//               Math.floor((value % 365) / 30) +
								//               " months"
								//             : Math.floor(value / 365) +
								//               " year " +
								//               Math.floor((value % 365) / 30) +
								//               " months"
								//           : value > 30
								//           ? value > 60
								//             ? value % 30 === 0
								//               ? Math.floor(value / 30) + " months "
								//               : Math.floor(value / 30) + " months " + (value % 30) + " days"
								//             : value % 30 === 0
								//             ? Math.floor(value / 30) + " months "
								//             : Math.floor(value / 30) + " Month " + (value % 30) + " days"
								//           : value + " days"}
								//       </>
								//     </div>
								//   );
								// } else if (key === "therapyStatus") {
								//   return (
								//     <div className="px-4">
								//       <>{value}</>
								//     </div>
								//   );
								// }
							})}
							<div className="flex justify-start items-center text-text-teal gap-2 relative">
								<>
									{props.storyType === 2 && props.data.Description.s3path ? (
										<div
											className="z-10 relative text-text-teal justify-center icon-width mr-2"
											onClick={(e) => {
												handleClickGetFullTrans();
												e.stopPropagation();
											}}>
											{icons.find((l) => l.id === "File")?.icon}
										</div>
									) : (
										<div
											className="z-10 relative text-text-teal justify-center icon-width mr-2"
											onClick={(e) => {
												if (props.showDrawer) props.showDrawer(props.id, props.data["Annotated Selection"], props.data.id);
												e.stopPropagation();
											}}>
											{icons.find((l) => l.id === "Edit")?.icon}
										</div>
									)}
									<div onClick={(e) => e.stopPropagation()}>
										<Popconfirm
											title="Are you sure to delete this Transcript?"
											placement="topRight"
											className="text-text-teal justify-center icon-width"
											onConfirm={() => {
												if (props.deleteTranscript) props.deleteTranscript(props.data.id);
											}}
											onCancel={() => {}}
											okText="Yes"
											cancelText="No"
											disabled={context.userName !== props.createdBy}>
											<span style={{ color: context.userName !== props.createdBy ? "#00000040" : "" }}>
												{icons.find((l) => l.id === "DeleteOutlined")?.icon}
											</span>
										</Popconfirm>
									</div>
								</>
							</div>
						</div>
					) : (
						<div className="text-text-gray-80 text-base mt-1">
							<div className="px-2">
								<>
									{/* {console.log("Data", props.data)} */}
									{Object.entries(props.data).map(([key, value]: any) => {
										if (key === "Annotated Selection") {
											return (
												<div className="px-4">
													<>{value}</>
												</div>
											);
										}
									})}
								</>
							</div>
						</div>
					)}
				</div>
			</div>
			{props.active[props.id] && (
				<div className="bg-acordion-body highlight-text-container border-t border-b pl-12 pr-28 py-2">
					{/* <div className="flex items-center gap-2">
            <div className="flex flex-wrap justify-start items-center text-sm text-acordion-description-tag-text">
              {props.data.Description.tags.map((tag: string) => {
                return (
                  <div className="bg-acordion-description-tag border-2 rounded-sm border-acordion-description-tag-border mx-2 px-2">
                    {tag}
                  </div>
                );
              })}
            </div>
            {props.data.Description.dialogueLength *
              props.data.Description.transcriptLength *
              props.data.Description.dialogueAppearedAt >
              0 && (
              <div className="flex justify-start items-center text-text-gray-50 ">
                {icons.find((l) => l.id === "Time")?.icon}
                <div className="w-60">
                  <ProgressBar
                    duration={props.data.Description.dialogueLength}
                    total={props.data.Description.transcriptLength}
                    start={props.data.Description.dialogueAppearedAt}
                  />
                </div>
              </div>
            )}
          </div> */}
					{/* <div>
            {props.data.Description.transcripts.map((transcript) => {
              if (
                transcript.dialogueNo === props.data.Description.dialogueNo - 1 ||
                transcript.dialogueNo === props.data.Description.dialogueNo + 1
              )
                return (
                  <p className="text-base text-text-gray-40 px-2 py-2">
                    {`${transcript.speaker} : ${transcript.text}`}
                  </p>
                );
              if (transcript.dialogueNo === props.data.Description.dialogueNo)
                return (
                  <p className="text-base text-text-gray-90 px-2 py-3">
                    {`${transcript.speaker} : ${transcript.text}`}
                  </p>
                );
              else return <></>;
            })}
          </div> */}
					<div id="highlight-text-container" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.data.highlightText) }} />
				</div>
			)}
			<Modal
				title={props.data["Annotated Selection"]}
				width={1500}
				bodyStyle={{
					height: "600px",
					overflowY: "auto",
				}}
				centered
				open={isModalVisible}
				onOk={handleOk}
				onCancel={handleCancel}
				className="modal-footer-left text-left"
				footer={[
					<Button type="primary" className="green-button items-start mr-6" onClick={handleCancel}>
						Close
					</Button>,
				]}>
				{fullTranscriptData && <TranscriptModalData id={props.data?.id || 0} transPayloadData={fullTranscriptData} />}
			</Modal>
		</div>
	);
}

function AccordionHeader(props: {
	headerInfo: string[];
	minToT?: any;
	maxToT?: any;
	marks: any;
	setData?: any;
	sliderFilter?: any;
	data: any;
	filters?: {
		columnName: string;
		options: string[];
	}[];
	sorted?: number;
	sortDate?: (arr: any, columnName: string) => void;
	filtersApplied?: {
		[id: string]: string[];
	};

	setFilter?: (
		filtersApplied: {
			[id: string]: string[];
		},
		columnName: string,
		filterValue: string[]
	) => void;
}) {
	const dataNew: any = props.data;

	const handleSlider = (e: any) => {
		console.log(e, props.data, marks[e[0]], marks[e[1]]);
		let temp_data: any = dataNew;
		let new_data: any = [];

		temp_data.map((i: any) => {
			if (e[1] !== 100) {
				if (i.ToT > marks[e[0]] && i.ToT < marks[e[1]]) {
					new_data.push(i);
				}
			} else {
				if (i.ToT > marks[e[0]]) {
					new_data.push(i);
				}
			}
		});
		props.setData(new_data);
	};

	React.useEffect(() => {}, [props.data]);
	return (
		<>
			<div className="bg-white py-2  border-b-2 border-acordion-header-border">
				<div className="flex justify-start items-center">
					<div className="panel-header text-text-gray-80 text-sm font-semibold mt-1 ml-12">
						{props.headerInfo.map((key) => {
							if (key !== "Description") {
								if (key === "Date Added on")
									return (
										<div className="flex justify-start items-center">
											{key}
											<div
												className="text-text-gray-40 cursor-pointer"
												onClick={() => {
													if (props.sortDate) props.sortDate(props.data, "Date Added on");
												}}>
												{props.sorted === 0
													? icons.find((l) => l.id === "Unsorted")?.icon
													: props.sorted === 1
													? icons.find((l) => l.id === "Ascending")?.icon
													: icons.find((l) => l.id === "Descending")?.icon}
											</div>
										</div>
									);
								return (
									<div className="flex justify-start items-center">
										{key}
										{props.filters?.find((filter) => filter.columnName === key) && props.filtersApplied && (
											<>
												<Popover
													overlayStyle={{
														width: key === "ToT" ? "20vw" : "18vw",
													}}
													content={
														key === "ToT" ? (
															<>
																<Slider
																	range
																	marks={marks}
																	onChange={props.sliderFilter}
																	step={null}
																	defaultValue={[0, 100]}
																	min={0}
																	max={100}
																	tooltipVisible={false}
																/>
															</>
														) : (
															<PopoverContent
																columnName={key}
																options={props.filters.find((filter) => filter.columnName === key)?.options || [""]}
																filtersApplied={props.filtersApplied}
																setFilter={props.setFilter}
															/>
														)
													}
													title="Filter"
													// onVisibleChange={}
												>
													<div
														className={
															props.filtersApplied[key].length === 0 ? "text-text-gray-40 cursor-pointer ml-2" : "text-text-teal cursor-pointer ml-2"
														}>
														{icons.find((l) => l.id === "Filter-Small")?.icon}
													</div>
												</Popover>
											</>
										)}
									</div>
								);
							} else return <></>;
						})}
						<div>Actions</div>
					</div>
				</div>
			</div>
		</>
	);
}
export default function Collapse(props: {
	headerInfo?: string[];
	setData?: any;
	data: {
		id: number;
		"Annotated Selection": string;
		"Date Added on": string;
		Source: string;
		Tags: string[];
		Status: string;
		// therapyStatus: string;
		// ToT: number;
		highlightText: string;
		highlightedString?: string;
		Description: {
			tags: string[];
			dialogueAppearedAt: number;
			dialogueLength: number;
			transcriptLength: number;
			dialogueNo: number;
			s3path?: string;
			transcripts: {
				dialogueNo: number;
				speaker: string;
				text: string;
			}[];
		};
	}[];
	minToT?: any;
	maxToT?: any;
	filters?: {
		columnName: string;
		options: string[];
	}[];
	sorted?: number;
	sliderFilter?: any;
	sortData?: (arr: any, columnName: string) => void;
	filtersApplied?: {
		[id: string]: string[];
	};
	setFilter?: (
		filtersApplied: {
			[id: string]: string[];
		},
		columnName: string,
		filterValue: string[]
	) => void;
	showDrawer?: (id: number, header: string, transcriptId: number) => void;
	deleteTranscript?: (id: number) => void;
	height?: string;
	createdBy?: string;
	storyType?: number;
	// updateTags: (
	// 	updatedData: AnnotatedSelectionInterface[],
	// 	filters: {
	// 		columnName: string;
	// 		options: string[];
	// 	}[]
	// ) => {
	// 	columnName: string;
	// 	options: string[];
	// }[];
}) {
	const temp: boolean[] = [];
	for (let i = 0; i < props.data.length; i++) temp.push(false);
	const [active, setActive] = React.useState(temp);

	function callback(idx: number) {
		const temp: boolean[] = [...active];
		if (temp[idx]) temp[idx] = false;
		else {
			for (let i = 0; i < temp.length; i++) {
				temp[i] = false;
			}
			temp[idx] = true;
		}
		setActive(temp);
	}
	React.useEffect(() => {
		for (let i = 0; i < props.data.length; i++) temp.push(false);
		setActive(temp);
	}, [props.data]);

	// console.log(props.minToT, props.maxToT);

	return (
		<>
			{/* {console.log("BLa", props.headerInfo, props.data)} */}
			{props.headerInfo && (
				<AccordionHeader
					minToT={props.minToT}
					maxToT={props.maxToT}
					headerInfo={props.headerInfo}
					data={props.data}
					setData={props.setData}
					filters={props.filters}
					sorted={props.sorted}
					sortDate={props.sortData}
					filtersApplied={props.filtersApplied}
					setFilter={props.setFilter}
					sliderFilter={props.sliderFilter}
					marks={marks}
				/>
			)}

			<div
				style={{
					height: props.height || "60vh",
					overflowY: "auto",
					// paddingBottom: "10px",
				}}>
				<List
					itemLayout="vertical"
					size="default"
					pagination={{
						// style: {
						// 	marginLeft: "2rem",
						// 	marginBottom: "1.2rem",
						// 	float: "left",
						// },
						onChange: (page) => {
							console.log(page);
						},
						pageSize: 10,
					}}
					dataSource={props.data}
					renderItem={(row, index) => (
						<AccordionItem
							headerInfo={props.headerInfo}
							id={index}
							data={row}
							callback={callback}
							active={active}
							showDrawer={props.showDrawer}
							deleteTranscript={props.deleteTranscript}
							createdBy={props.createdBy}
							storyType={props.storyType}
						/>
					)}
				/>
			</div>
		</>
	);
}

export const Icon = () => (
	<svg
		width="18"
		height="18"
		viewBox="0 0 18 18"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.6667 0.666626C15.1267 0.666626 15.5 1.03996 15.5 1.49996V4.63079L13.8333 6.29746V2.33329H2.16667V15.6666H13.8333V13.3683L15.5 11.7016V16.5C15.5 16.96 15.1267 17.3333 14.6667 17.3333H1.33333C0.873333 17.3333 0.5 16.96 0.5 16.5V1.49996C0.5 1.03996 0.873333 0.666626 1.33333 0.666626H14.6667ZM16.1483 6.33996L17.3267 7.51829L10.845 14L9.665 13.9983L9.66667 12.8216L16.1483 6.33996ZM8.83333 8.99996V10.6666H4.66667V8.99996H8.83333ZM11.3333 5.66663V7.33329H4.66667V5.66663H11.3333Z"
			fill="#454250"
		/>
	</svg>
);

import React, { useEffect, useState } from "react";
import { Skeleton, message } from "antd";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";

export default function ByteImageRender(props: { s3Path: any }) {
	const [imageSrc, setImageSrc] = useState<string | null>(null);
	const [byteArrayData, setByteArrayData] = useState<any>(null);

	// console.log("Byte Image Render: ", props.s3Path);

	async function fetchByteImageData() {
		try {
			const testingS3Path = "client-demo2/searchgen_test/kural.png";
			const data: any = await SearchServiceHelper.getS3ImageByteArray(testingS3Path);
			// console.log(" Response: ", data);

			if (data) {
				console.log("Image ByteArray fetched successfully");
				setByteArrayData(data);
			} else {
				console.log("Error in fetching Image Byte Data.");
			}
		} catch (error) {
			message.error("Error in fetching Transcript Image Data");
			console.log("Error in fetching Image Byte Data: ", error);
		}
	}

	useEffect(() => {
		fetchByteImageData();
	}, [props.s3Path]);

	useEffect(() => {
		if (byteArrayData && byteArrayData.length > 0) {
			const binaryData = atob(byteArrayData);

			// Create a Uint8Array from the binary data
			const uint8Array = new Uint8Array(binaryData.length);
			for (let i = 0; i < binaryData.length; i++) {
				uint8Array[i] = binaryData.charCodeAt(i);
			}

			// Convert the Uint8Array to a Blob
			const blob = new Blob([uint8Array], { type: "image/jpeg" });

			// Create a URL for the Blob
			const imageUrl = window.URL.createObjectURL(blob);
			// console.log(" Image URL is: ", imageUrl);

			// Set the image source
			setImageSrc(imageUrl);
		}
	}, [byteArrayData]);

	return (
		<>
			{imageSrc ? (
				<img
					src={imageSrc}
					alt="Transcript Data"
					style={{ width: "100%", height: "100%" }}
				/>
			) : (
				<>
					<Skeleton active></Skeleton>
					<Skeleton active></Skeleton>
					<Skeleton active></Skeleton>
				</>
			)}
		</>
	);
}

import millify from "millify";
import { millifyConfig } from "../../../GlobalThemes";
/**
 * This is the Number card which is used to print the data of the card
 *
 * @remarks - This card is used in POCard to print the number data
 *
 * @param numberHeading - The props used to print the heading of the card, if present
 * @param dataNumber - The props is the main number data
 * @param dataname - The props is the data name
 * @param suffixElement - The props is used to print the unit or any other suffix of the number
 *
 * @returns the number with data name and suffix element
 */

export function NumberData(props: {
	numberHeading?: string;
	dataNumber?: number | string;
	dataName?: string;
	suffixElement?: string;
}) {
	return (
		<div className="mr-6">
			{props.numberHeading ? (
				<h4 className="font-semibold mb-5">{props.numberHeading}</h4>
			) : null}
			{/* {console.log("SuffixElement", props.suffixElement)} */}
			<div className="font-semibold text-4xl">
				{props.suffixElement !== "mins" ? (
					<>
						<span>
							{Number(props.dataNumber)
								? millify(Number(props.dataNumber), {
										...millifyConfig,
								  })
								: props.dataNumber}
						</span>
						<span className="text-base font-normal ml-1">
							{props.suffixElement}
						</span>
					</>
				) : (
					<>
						<span>
							{Number(props.dataNumber)
								? millify(
										Math.floor(Number(props.dataNumber)),
										{
											...millifyConfig,
										}
								  )
								: props.dataNumber}
						</span>
						<span className="text-base font-normal ml-1">
							{props.suffixElement}
						</span>
						{Math.round(
							(Number(props.dataNumber) -
								Math.floor(Number(props.dataNumber))) *
								60
						) > 0 && (
							<>
								&nbsp;
								<span>
									{Number(props.dataNumber)
										? millify(
												Math.round(
													(Number(props.dataNumber) -
														Math.floor(
															Number(
																props.dataNumber
															)
														)) *
														60
												),
												{
													...millifyConfig,
												}
										  )
										: props.dataNumber}
								</span>
								<span className="text-base font-normal ml-1">
									{"secs"}
								</span>
							</>
						)}
					</>
				)}
			</div>
			<div className="text-sm text-gray-500 ml-1">
				<span>{props.dataName}</span>
			</div>
		</div>
	);
}

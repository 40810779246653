import TableWrapper from "../../../../Components/TableWrapper";
import ReportTypes from "../../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../../libraries/report-types/charts/interfaces/main";
import { transformTableData } from "../../../Dashboard/Processing";
import { icons } from "../../../../GlobalThemes";
import { Empty } from "antd";
import React from "react";
import { emptyDataTableColumns, dataTableEmptyData } from "../../data";

export default function DataTable(props: { data: any; loading?: boolean }) {
  const [searchedData, setsearchedData] = React.useState(props.data);
  const grouped_columns = [
    {
      title: "Patient ID",
      accessor: "Patient_ID",
      sortType: "alphanumeric",
      width: 180,
      Cell: (props: any) => {
        return <div>{props.cell.value}</div>;
      },
    },
    {
      title: "Current Therapy",
      accessor: "Current_Therapy",
      sort: true,
      sortType: "basic",

      Cell: (props: any) => {
        // if (props.cell.value !== "Updating")
        //   return (
        //     <div className="text-text-gray-80 text-base">
        //       {icons.find((l:any) => l.id === "HourGlass")?.icon}
        //       Updating
        //     </div>
        //   );
        return <div>{props.cell.value}</div>;
      },
    },
    {
      title: "Discon Risk",
      accessor: "Discon_Risk",
      sort: true,
      Cell: (props: any) => {
        return (
          <div style={{ display: "flex" }}>
            <div style={{ textAlign: "center", background: "white" }}>
              {
                icons.find(
                  (l) =>
                    l.id === (props.cell.value === "High" ? "Minus Circle" : "Check Circle Fill")
                )?.icon
              }
            </div>
            <div style={{ textAlign: "center", paddingLeft: "15px" }}>{props.cell.value}</div>
          </div>
        );
      },
    },
    {
      title: "Top Unmet Needs",
      accessor: "Top_Unmet_Needs",
      width: 300,
      sort: true,
      Cell: (props: any) => {
        return <div style={{ textAlign: "left" }}>{props.cell.value}</div>;
      },
    },
    {
      title: "Therapy Start Date",
      accessor: "Therapy_Start_Date",
      // sort: true,
      // sortType: "datetime",
    },

    {
      title: "Time On Therapy",
      accessor: "Time_On_Therapy",
      sort: true,
      Cell: (props: any) => {
        return (
          <div
            style={{
              textAlign: "left",
            }}
          >
            <div className="flex">
              {icons.find((l: any) => l.id === props.cell.value)?.icon}
              &nbsp;
              {props.cell.value}
            </div>
          </div>
        );
      },
    },
    {
      title: "Out of Stock Date",
      accessor: "Out_Of_Stock_Date",
      // sort: true,
      // sortType: "datetime",
    },
  ];

  React.useEffect(() => {
    setsearchedData(props.data);
  }, [props.data]);
  return (
    <>
      {!props.loading ? (
        <>
          <>
            {searchedData.length > 0 ? (
              <>
                <ReportTypes
                  hideShadow
                  hideRibbon
                  chartType={ReportTypesComponentInterface.chartType.simpleTable}
                  series={[]}
                  chartConfig={{
                    table: {
                      tableData: {
                        column: grouped_columns,
                        data: transformTableData(
                          searchedData,
                          [],
                          [
                            {
                              id: "Patient_ID",
                              link: "patient-id",
                            },
                          ],
                          undefined,
                          true
                        ).data,
                      },
                      grid: {
                        enableGridX: false,
                        enableGridY: false,
                      },
                      noSearchField: true,
                      noPagination: true,
                      // maxHeight: 394,
                      maxHeight: "57vh",
                    },
                  }}
                />
              </>
            ) : (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                className="flex h-64 items-center justify-center"
              />
            )}
          </>
        </>
      ) : (
        <>
          <TableWrapper loading={true} minHeight="37em">
            <ReportTypes
              hideShadow
              hideRibbon
              chartType={ReportTypesComponentInterface.chartType.simpleTable}
              series={[]}
              chartConfig={{
                table: {
                  tableData: {
                    column: emptyDataTableColumns,
                    data: dataTableEmptyData,
                  },

                  noSearchField: true,
                  noPagination: true,
                  maxHeight: 394,
                },
              }}
            />
          </TableWrapper>
        </>
      )}
    </>
  );
}

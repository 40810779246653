import React from "react";
import { icons } from "../../../GlobalThemes";
import ReportTypes from "../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import { ColumnFilter } from "../../../libraries/report-types/charts/table/simpleTable/columnFilter";
import {
	transformBarChartData,
	transformBubbleChartData,
	transformTableChartData,
	transformTableData,
} from "../Processing";
import { Empty, Modal, Drawer } from "antd";
import TableModal from "../Components/Modal";
import { POCard } from "../../../Components/ReportCards/POCard";
import { RenderCollapse } from "./PatientSentiment";
import { Header } from "../../../Components/ReportCards/CommonCardComponents/Header";

const grouped_columns = [
	{
		title: "Story Name",
		accessor: "Story_Name",
		Filter: ColumnFilter,
		sort: true,
		width: 200,
	},
	{
		title: "Story Type",
		accessor: "Story_Type",
		Filter: ColumnFilter,
		sort: true,
		width: 200,
	},
	{
		title: "#Mentions",
		accessor: "#Mentions",
		Filter: ColumnFilter,
		sort: true,
		Cell: (props: any) => {
			return (
				<div style={{ textAlign: "center" }}>{props.cell.value}</div>
			);
		},
		width: 150,
	},
	{
		title: "Mentions Over Time",
		accessor: "Mentions_Over_Time",
		Filter: ColumnFilter,
		sort: true,
		// width: 100,
	},
	// {
	// 	title: "QoQ Growth",
	// 	accessor: "QoQ_Growth",
	// 	Filter: ColumnFilter,
	// 	sort: true,
	// 	Cell: (props: any) => {
	// 		return (
	// 			<div
	// 				style={{
	// 					textAlign: "center",
	// 					color:
	// 						props.cell.value.split("%")[0] > 0
	// 							? "green"
	// 							: "red",
	// 					fontWeight: 600,
	// 					letterSpacing: "1px",
	// 				}}
	// 			>
	// 				{props.cell.value.split("%")[0] > 0 ? (
	// 					<>
	// 						{icons.find((l) => l.id === "TriangleUp")?.icon}{" "}
	// 						{props.cell.value}
	// 					</>
	// 				) : (
	// 					<>
	// 						{icons.find((l) => l.id === "TriangleDown")?.icon}{" "}
	// 						{props.cell.value}
	// 					</>
	// 				)}
	// 			</div>
	// 		);
	// 	},
	// 	width: 160,
	// },
	{
		title: "Consumer Sentiment",
		accessor: "Consumer_Sentiment",
		Filter: ColumnFilter,
		sort: true,
		// width: 10,
	},
];

/**
 * This page renders story opportunities and unmet needs summary
 *
 * @param data - This props is used to send the data in both the cards
 *
 * @returns the story opportunities scatter card and unmet needs summary list
 */

export function PatientNeeds(props: { data: any }) {
	const [fetchedData, setFetchedData] = React.useState(props.data);
	const [visible, setVisible] = React.useState(false);
	const [modalData, setModalData] = React.useState(<></>);
	const [drawerBody, setDrawerBody] = React.useState(<></>);
	const [drawerHeader, setdrawerHeader] = React.useState("");
	const [modalHeader, setModalHeader] = React.useState("");
	const showModal = (storyName: string) => {
		console.log("Story Namme ", storyName);
		setVisible(true);
		setModalHeader(storyName);
		setModalData(
			<TableModal
				storyName={storyName}
				storyDetails={props.data[1].storyDetails[storyName] || []}
			/>
		);
	};

	const showDrawer = (story: any) => {
		console.log("Inside Story ", story);
		setdrawerHeader(story["Story Name"]);
		var storyDetail = Object.assign({}, story);
		storyDetail["id"] = story["Story Id"];
		console.log("New story ", storyDetail);
		setDrawerBody(<RenderCollapse storyDetail={storyDetail} />);
		setVisible(true);
	};

	const handleOk = () => {
		setVisible(false);
	};
	var card_1 = {
		cardData: true,
		header: fetchedData[0].chartName[0],
		iconName: "Report Patient",
		chartElement: [
			fetchedData[0].chartData.length === 0 ? (
				<div>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			) : (
				<ReportTypes
					// header={fetchedData[0].chartName}
					hideRibbon
					// footer="Footer Text"
					hideShadow
					minHeight="300px"
					height="60vh"
					bodyPadding="5px 24px"
					chartType={
						ReportTypesComponentInterface.chartType.scatterPlot
					}
					// series={series3}
					// data={chartData}
					{...transformBubbleChartData(
						fetchedData[0].chartData,
						fetchedData[0].chartColor
					)}
					axis={{
						primaryX: {
							dim: 0,
							legend: fetchedData[0].xAxisLabel[0],
							legendOffset: 40,
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							tickValues: 3,
							minValue: -1,
							maxValue: 1,
						},
						primaryY: {
							tickSize: 5,
							tickRotation: 0,
							legend: fetchedData[0].yAxisLabel[0],
							legendOffset: -60,
							// tickValues: 3,
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							minValue: 0,
						},
					}}
					chartArea={{ backgroundDivision: 3 }}
					chartConfig={{
						cartesian: {
							grid: {
								enableGridX: true,
								enableGridY: true,
								// gridYValues: 3,
								gridXValues: 3,
							},
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "needs",
					}}
					style={{
						margin: {
							top: 20,
							right: 10,
							bottom: 50,
							left: 70,
						},
						labelTextColor: "white",
						axisWidth: 0,
					}}
				/>
			),
		],
	};
	var card_2 = {
		cardData: true,
		header: fetchedData[1].chartName[0],
		iconName: "Report Patient",
		chartElement: [
			fetchedData[1].chartData.length === 0 ? (
				<div>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
				</div>
			) : (
				<ReportTypes
					// handleEdit={showModal}
					// handleSave={handleSave}
					header=""
					// hideActions={[false, false]}
					hideShadow
					hideRibbon
					minHeight="200px"
					chartType={
						ReportTypesComponentInterface.chartType.simpleTable
					}
					series={[]}
					chartConfig={{
						table: {
							tableData: {
								column: grouped_columns,
								// column: transformTableData(
								// 	fetchedData[1].chartData,
								// 	fetchedData[1].chartAccessor,
								// 	fetchedData[1].tableLinks
								// ).column,
								data: transformTableChartData(
									transformTableData(
										fetchedData[1].chartData,
										fetchedData[1].chartAccessor,
										fetchedData[1].tableLinks,
										showDrawer,
										false,
										true
									).data,
									fetchedData[1].chartAccessor,
									transformBarChartData,
									fetchedData[1].chartColor
								),
								// ...transformTableData(props.data[2].chartData),
							},
							noSearchField: true,
							noPagination: true,
						},
					}}
				/>
			),
		],
	};
	return (
		<div className="w-full">
			{/* <Modal
				destroyOnClose={true}
				title={modalHeader}
				centered
				open={visible}
				onOk={() => setVisible(false)}
				onCancel={() => setVisible(false)}
				width={1100}
				footer={null}
			>
				{modalData}
			</Modal> */}
			<Drawer
				title={drawerHeader}
				placement="right"
				onClose={() => setVisible(false)}
				open={visible}
				destroyOnClose={true}
				width={600}
			>
				{drawerBody}
			</Drawer>
			<div className="mt-5">
				<POCard {...card_1} />
			</div>
			<div className="mt-5">
				<POCard {...card_2} />
			</div>
		</div>
	);
}

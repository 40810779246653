import { filterValues } from "../Pages/NodeGraph/NodeGraph";
import { request } from "./../App";

export const getNodeJsonsData = async (s3path: string) => {
	const fetchUrl = "discover/GetNodeJsons?s3path=" + s3path;
	const data = await request
		.post(fetchUrl, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getStudiesDataForDiscover = async () => {
	const fetchUrl = "discover/GetAllStudiesForDiscover";
	const data = await request
		.get(fetchUrl, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getWavesDataForStudyId = async (studyId: number) => {
	const fetchUrl = "discover/GetAllWavesForStudy?studyId=" + studyId;
	const data = await request
		.get(fetchUrl, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getGeographyDataForStudyId = async (waveId: number) => {
	const fetchUrl = "discover/GetAllGeoForStudy?waveId=" + waveId;
	const data = await request
		.get(fetchUrl, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getTranscriptDetailsForNodeData = async (filters: {
	nodeId: string;
	studyId: number;
	waveId: number;
	geoId: number;
	studyIterId: number;
}) => {
	const fetchUrl = "discover/GetTranscriptDetailsForNode";
	const data = await request
		.post(fetchUrl, filters, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

export const getExperimentData = async () => {
	const fetchUrl = "Explore/GetExperiments";
	const data = await request
		.get(fetchUrl, {
			withCredentials: true,
			headers: {
				"Content-Type": "application/json",
			},
		})
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};

import { POCard } from "../../../Components/ReportCards/POCard";
import React, { useState, useEffect } from "react";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import {
	transformBarChartData,
	transformBubbleChartData,
	transformTableChartData,
	transformTableData,
} from "../Processing";
import { ColumnFilter } from "../../../libraries/report-types/charts/table/simpleTable/columnFilter";
import { Empty } from "antd";
import { changeSecondsFormat } from "../../../libraries/report-types/charts/cartesianChart/ScatterPlot/chart";

const grouped_columns = [
	{
		title: "Topic Name",
		accessor: "Topic_Name",

		Filter: ColumnFilter,
		sort: true,
		width: "12%",
	},
	{
		title: "% of Times Topics Discussed",
		accessor: "%_of_Times_Topics_Discussed",

		Filter: ColumnFilter,
		sort: true,
		Cell: (props: any) => {
			return (
				<div style={{ textAlign: "center" }}>
					{Math.round(props.cell.value * 100) / 100}%
				</div>
			);
		},
		width: "12%",
	},
	{
		title: "% of Times Topics Discussed by Patient",
		accessor: "%_of_Times_Topics_Discussed_by_patient",

		Filter: ColumnFilter,
		sort: true,
		Cell: (props: any) => {
			return (
				<div style={{ textAlign: "center" }}>
					{props.cell.value
						? `${Math.round(props.cell.value * 100) / 100}%`
						: ""}
				</div>
			);
		},
		width: "12%",
	},
	{
		title: "% of Times Topics Discussed by Agent",
		accessor: "%_of_Times_Topics_Discussed_by_agent",

		Filter: ColumnFilter,
		sort: true,
		Cell: (props: any) => {
			return (
				<div style={{ textAlign: "center" }}>
					{props.cell.value
						? `${Math.round(props.cell.value * 100) / 100}%`
						: ""}
				</div>
			);
		},
		width: "12%",
	},
	{
		title: "Average Time Spent on Topic",
		accessor: "Average_Time_Spent_on_Topic_(mins)",

		Filter: ColumnFilter,
		sort: true,
		Cell: (props: any) => {
			return (
				<div style={{ textAlign: "center" }}>
					{changeSecondsFormat(props.cell.value * 60)}
				</div>
			);
		},
		width: "12%",
	},
	{
		title: "# of Patients Discussing the Topic",
		accessor: "#_of_Patients_Discussing_the_Topic",
		Filter: ColumnFilter,
		sort: true,
		Cell: (props: any) => {
			return (
				<div style={{ textAlign: "center" }}>{props.cell.value}</div>
			);
		},
		width: "12%",
	},
	{
		title: "Monthly Trend",
		accessor: "Monthly_Trend",
		// Filter: ColumnFilter,
		// sort: true,
		width: "40%",
	},
];

/**
 * This page is used to render topics discussed data
 *
 * @param data - This is the data props
 *
 * @returns the topics discussed scatter chart and a list of topics discussed
 */

export function TopicEngagement(props: { data: any }) {
	var data = [
		{
			header: props.data[0].chartName,
			iconName: "Report Topic",
			cardData: false,
			cardBottomData: (
				<div className="ml-9 font-semibold">
					<span>{props.data[0].footer}</span>
				</div>
			),
			chartElement: [
				<ReportTypes
					// header={props.data[0].chartName[0]}
					title={{ title: "# of Patients Discussing the Topic" }}
					hideShadow
					hideRibbon
					// footer="Footer Text"
					minHeight="300px"
					height="58vh"
					bodyPadding="5px 24px"
					chartType={
						ReportTypesComponentInterface.chartType.scatterPlot
					}
					// series={series3}
					// data={chartData2}
					{...transformBubbleChartData(
						props.data[0].chartData,
						props.data[0].chartColor
					)}
					axis={{
						dualAxis: true,
						primaryX: {
							tickSize: 20,
							dim: 0,
							legendOffset: 50,
							legend: props.data[0].xAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
						},
						primaryY: {
							tickSize: 20,
							tickRotation: 0,
							legend: props.data[0].yAxisLabel[0],
							legendOffset: -80,
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
						},
					}}
					chartArea={{ backgroundDivision: 4, twoByTwo: true }}
					chartConfig={{
						cartesian: {
							grid: {
								enableGridX: true,
								enableGridY: true,
							},
						},
					}}
					style={{
						margin: {
							top: 20,
							right: 20,
							bottom: 65,
							left: 100,
						},
						labelTextColor: "white",
						axisWidth: 0,
					}}
					tooltip={{ isInteractive: true, format: "z" }}
				/>,
			],
		},
		{
			header: props.data[1].chartName,
			iconName: "Report Topic",
			cardData: false,
			cardBottomData: (
				<div className="ml-9 font-semibold">
					<span>{props.data[1].footer}</span>
				</div>
			),
			chartElement: [
				<ReportTypes
					// header="Topics Discussed Agent vs Patient"
					title={{ title: "Average time Spent on Topic (minutes)" }}
					hideShadow
					hideRibbon
					// footer="Footer Text"
					minHeight="300px"
					height="58vh"
					bodyPadding="5px 24px"
					chartType={
						ReportTypesComponentInterface.chartType.scatterPlot
					}
					{...transformBubbleChartData(
						props.data[1].chartData,
						props.data[1].chartColor
					)}
					axis={{
						dualAxis: true,
						primaryX: {
							tickSize: 20,
							dim: 0,
							legend: props.data[1].xAxisLabel[0],
							legendOffset: 50,
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
						},
						primaryY: {
							tickSize: 20,
							tickRotation: 0,
							legend: props.data[1].yAxisLabel[0],
							legendOffset: -80,

							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
						},
					}}
					chartConfig={{
						cartesian: {
							grid: {
								enableGridX: true,
								enableGridY: true,
								// gridYValues: [2, 9, 16],
								// gridXValues: 2,
							},
						},
					}}
					chartArea={{ backgroundDivision: 4, twoByTwo: true }}
					style={{
						margin: {
							top: 20,
							right: 20,
							bottom: 65,
							left: 100,
						},
						labelTextColor: "white",
						axisWidth: 0,
					}}
					tooltip={{ isInteractive: true, format: "z" }}
				/>,
			],
		},
	];

	var card_2 = {
		cardData: true,
		header: props.data[2].chartName,

		iconName: "Report Topic List",
		chartElement: [
			props.data[2].chartData.length > 0 ? (
				<ReportTypes
					// handleEdit={showModal}
					// handleSave={handleSave}
					header=""
					// hideActions={[false, false]}
					hideShadow
					hideRibbon
					minHeight="300px"
					chartType={
						ReportTypesComponentInterface.chartType.simpleTable
					}
					series={[]}
					chartConfig={{
						table: {
							tableData: {
								column: grouped_columns,
								data: transformTableChartData(
									transformTableData(
										props.data[2].chartData,
										props.data[2].chartAccessor
									).data,
									props.data[2].chartAccessor,
									transformBarChartData,
									props.data[2].chartColor
								),
								// ...transformTableData(props.data[2].chartData),
							},
							noSearchField: true,
							noPagination: true,
						},
					}}
				/>
			) : (
				<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
			),
		],
	};

	return (
		<div className="grid gap-6">
			<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-6">
				{data.map((card) => (
					<POCard {...card} />
				))}
			</div>
			<div>
				<POCard {...card_2} />
			</div>
		</div>
	);
}

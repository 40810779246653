import { ResponsiveBullet } from "@nivo/bullet";

/**
 * This function is used to render the needle chart
 * 
 * @remarks - This function is a part of BarChartWithBg function to render the needle chart
 * 
 * @param data - This props represent the data of the chart
 * @param minValue - This props represent the minimum value of the chart
 *
 * @returns the bullet chart with custom marker
 */

export default function NeedleChart(props: { data: any; minValue: number }) {
	const CustomMarker = (props: { x: number; size: number }) => (
		<svg
			// width="9"
			// height="32"
			// viewBox="0 0 9 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				transform={`translate(${props.x - 4},3)`}
				d="M4.50088 3.17564 C4.50088 13.6058 14.6247 32 4.49969 32C-5.62531 32 4.50088 13.6058 4.50088 3.17564C4.50206 12.1224 4.50088 -7.25456 4.50088 3.17564Z"
				fill="#2F2C3C"
			/>
		</svg>
	);
	const data = [
		{
			id: "temp.",
			ranges: [33.33, 66.66, 100],
			measures: [0],
			markers: [10],
		},
	];
	return (
		<div style={{ height: "60px" }}>
			<ResponsiveBullet
				data={props.data}
				rangeColors={
					props.minValue < 0
						? ["#82E6CF", "#E65428", "#FFD680"]
						: ["#E65428", "#FFD680", "#82E6CF"]
				}
				markerComponent={CustomMarker}
				margin={{ top: 20, right: 10, bottom: 25, left: 10 }}
				spacing={46}
				axisPosition="before"
				minValue={props.minValue}
			/>
		</div>
	);
}

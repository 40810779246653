import { Input, Skeleton, Button, Tooltip } from "antd";
import { icons } from "../../GlobalThemes";
import "./style.css";
import { ExportToExcel } from "../../Pages/SearchEngine/ExportToExcel";
import { DownloadOutlined } from "@ant-design/icons";

export default function TableWrapper(props: {
  title?: string;
  value?: number;
  createStory?: boolean;
  children?: JSX.Element;
  showModalButton?: any;
  showModal?: () => void;
  searchBar?: any;
  minHeight?: string;
  loading?: boolean;
  noShadow?: boolean;
  tagName?: string;
  exportData?: any;
  exportReport?: any;
}) {
  return (
    <>
      {!props.loading ? (
        <div
          className={
            props.noShadow !== undefined && props.noShadow === true
              ? "bg-white table-wrapper"
              : "bg-white p-4 shadow-lg table-wrapper h-full"
          }
          style={
            props.minHeight
              ? { minHeight: props.minHeight }
              : { minHeight: "-webkit-fill-available" }
          }
        >
          <div className="flex justify-between items-start">
            <div>
              {props.title === "Patient List" ? (
                <div className="text-text-gray-80 text-base font-semibold leading-normal mb-2 ml-4">
                  {`${props.title}`}{" "}
                </div>
              ) : props.title === "Call History" ? (
                <div className="text-lg font-bold">{`${props.title}`}</div>
              ) : props.title === "Total Story" ? (
                <div className="text-text-gray-80 text-base font-semibold leading-normal mb-2 ml-4">
                  {`${props.title} : ${props.value}`}{" "}
                </div>
              ) : (
                <div className="text-text-gray-80 text-base font-semibold leading-normal">
                  {`${props.title} : ${props.value}`}{" "}
                </div>
              )}

              {props.searchBar && (
                <div className="my-1">
                  <Input.Search
                    className="flex w-96 input-search"
                    placeholder="Search Story"
                    onChange={(e) => {
                      props.searchBar(e);
                    }}
                  />
                </div>
              )}
            </div>

            {props.showModalButton && (
              <div
                className="flex items-center cursor-pointer mb-2 mr-4"
                onClick={() => {
                  if (props.showModalButton) props.showModalButton();
                }}
              >
                <Button type="primary" className="green-button flex items-center">Create {props.tagName}</Button>
                {/* <div className="mr-2">{icons.find((l) => l.id === "Plus Filled Green")?.icon}</div>
                <div>
                  <p className="font-semibold text-base" style={{ color: "#27A6A4" }}>
                    Create New Story
                  </p>
                </div> */}
              </div>
            )}
            
            {props.exportData && (
              <div className="flex items-center cursor-pointer">
                  <ExportToExcel exportData={props.exportData} />
              </div>
            )}
            {props.exportReport !== null && props.exportReport !== undefined && (
              <div className="flex items-center cursor-pointer"
                onClick={() => {
                  if (props.showModal && props.exportReport) props.showModal();
                }}>
                <Tooltip title={props.exportReport ? "" : "Export not ready"}>
                  <Button type="primary" className="green-button flex items-center" icon={<DownloadOutlined />} disabled={!props.exportReport}>
                      Export
                  </Button>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="mt-0">{props.children}</div>
        </div>
      ) : (
        <>
          <div
            className="bg-white p-4 shadow-lg table-wrapper"
            style={{ minHeight: props.minHeight }}
          >
            <div className="w-48">
              <Skeleton.Button active size="default" shape="round" block={true} />
            </div>
            <div className="mt-0">{props.children}</div>
          </div>
        </>
      )}
    </>
  );
}

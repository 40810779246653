import { icons } from "../../GlobalThemes";

export default function NoChartData() {
	return (
		<>
			<div className="w-full h-full text-text-gray-40 bg-body flex flex-col justify-center items-center">
				<div>{icons.find((l) => l.id === "NoChartData")?.icon}</div>
				<div>No Data Available.</div>
			</div>
		</>
	);
}

import { Skeleton } from "antd";
import React from "react";
import { icons, userTypeEnum } from "../../../GlobalThemes";
import AppContext from "../../../store";

export const words = [
  {
    text: "told",
    value: 64,
  },
  {
    text: "mistake",
    value: 11,
  },
  {
    text: "thought",
    value: 16,
  },
  {
    text: "bad",
    value: 17,
  },
  {
    text: "correct",
    value: 10,
  },
  {
    text: "day",
    value: 54,
  },
  {
    text: "prescription",
    value: 12,
  },
  {
    text: "time",
    value: 77,
  },
  {
    text: "thing",
    value: 45,
  },
  {
    text: "left",
    value: 19,
  },
  {
    text: "pay",
    value: 13,
  },
  {
    text: "people",
    value: 32,
  },
  {
    text: "month",
    value: 22,
  },
  {
    text: "again",
    value: 35,
  },
  {
    text: "review",
    value: 24,
  },
  {
    text: "call",
    value: 38,
  },
  {
    text: "doctor",
    value: 70,
  },
  {
    text: "asked",
    value: 26,
  },
  {
    text: "finally",
    value: 14,
  },
  {
    text: "insurance",
    value: 29,
  },
  {
    text: "week",
    value: 41,
  },
  {
    text: "called",
    value: 49,
  },
  {
    text: "problem",
    value: 20,
  },
  {
    text: "going",
    value: 59,
  },
  {
    text: "help",
    value: 49,
  },
  {
    text: "felt",
    value: 45,
  },
  {
    text: "discomfort",
    value: 11,
  },
  {
    text: "lower",
    value: 22,
  },
  {
    text: "severe",
    value: 12,
  },
  {
    text: "free",
    value: 38,
  },
  {
    text: "better",
    value: 54,
  },
  {
    text: "muscle",
    value: 14,
  },
  {
    text: "neck",
    value: 41,
  },
  {
    text: "root",
    value: 24,
  },
  {
    text: "adjustment",
    value: 16,
  },
  {
    text: "therapy",
    value: 29,
  },
  {
    text: "injury",
    value: 20,
  },
  {
    text: "excruciating",
    value: 10,
  },
  {
    text: "chronic",
    value: 13,
  },
  {
    text: "chiropractor",
    value: 35,
  },
  {
    text: "treatment",
    value: 59,
  },
  {
    text: "tooth",
    value: 32,
  },
  {
    text: "chiropractic",
    value: 17,
  },
  {
    text: "dr",
    value: 77,
  },
  {
    text: "relief",
    value: 19,
  },
  {
    text: "shoulder",
    value: 26,
  },
  {
    text: "nurse",
    value: 17,
  },
  {
    text: "room",
    value: 22,
  },
  {
    text: "hour",
    value: 35,
  },
  {
    text: "wait",
    value: 38,
  },
  {
    text: "hospital",
    value: 11,
  },
  {
    text: "eye",
    value: 13,
  },
  {
    text: "test",
    value: 10,
  },
  {
    text: "appointment",
    value: 49,
  },
  {
    text: "medical",
    value: 19,
  },
  {
    text: "question",
    value: 20,
  },
  {
    text: "office",
    value: 64,
  },
  {
    text: "care",
    value: 54,
  },
  {
    text: "minute",
    value: 29,
  },
  {
    text: "waiting",
    value: 16,
  },
  {
    text: "patient",
    value: 59,
  },
  {
    text: "health",
    value: 49,
  },
  {
    text: "alternative",
    value: 24,
  },
  {
    text: "holistic",
    value: 19,
  },
  {
    text: "traditional",
    value: 20,
  },
  {
    text: "symptom",
    value: 29,
  },
  {
    text: "internal",
    value: 17,
  },
  {
    text: "prescribed",
    value: 26,
  },
  {
    text: "acupuncturist",
    value: 16,
  },
  {
    text: "pain",
    value: 64,
  },
  {
    text: "integrative",
    value: 10,
  },
  {
    text: "herb",
    value: 13,
  },
  {
    text: "sport",
    value: 22,
  },
  {
    text: "physician",
    value: 41,
  },
  {
    text: "herbal",
    value: 11,
  },
  {
    text: "eastern",
    value: 12,
  },
  {
    text: "chinese",
    value: 32,
  },
  {
    text: "acupuncture",
    value: 45,
  },
  {
    text: "prescribe",
    value: 14,
  },
  {
    text: "medication",
    value: 38,
  },
  {
    text: "western",
    value: 35,
  },
  {
    text: "sure",
    value: 38,
  },
];

export const DUMMY_DATA = [
  {
    numberOfPatients: 79,
    numberOfSearchResults: 3,
    searchResults: [
      {
        id: 0,
        name: "Transcripts from 12 June 2020 [18:50:23]",
        tags: ["Inbound", "Follow Up Call", "Nervous"],
        storiesAdded: [
          {
            id: 1,
            storyName: "Query about Alcohol Consumption",
          },
        ],
        dialogueNo: 34,
        callId: "393EBD7490B340B197BDD2552D4DF263",
        callDate: "2017-11-01T00:00:00",
        callTime: "0:12:20",
        callType: "Inbound",
        therapyStatus: "On Therapy",
        dialogueAppearedAt: 0.2,
        dialogueLength: 1.2,
        transcriptLength: 5,
        S3path: "ABC/transcript_sample_script.json",
        addedToStory: false,
        transcripts: [
          {
            dialogueNo: 1,
            speaker: "Patient",
            text: "Oh okay, sure.",
          },
          {
            dialogueNo: 2,
            speaker: "Agent",
            text: "Are there any other questions I can answer for you?",
          },
          {
            dialogueNo: 3,
            speaker: "Patient",
            text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
          },
          {
            dialogueNo: 4,
            speaker: "Agent",
            text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
          },
          {
            dialogueNo: 5,
            speaker: "Patient",
            text: "Alrigt. Thanks a lot",
          },
        ],
      },
      {
        id: 1,
        name: "Transcripts from 10 June 2020 [17:30:58]",
        tags: ["Inbound", "Follow Up Call", "Nervous"],
        dialogueNo: 34,
        callId: "393EBD7490B340B197BDD2552D4DF263",
        callDate: "2017-11-01T00:00:00",
        callTime: "0:12:20",
        callType: "Inbound",
        therapyStatus: "On Therapy",
        dialogueAppearedAt: 0.2,
        dialogueLength: 1.2,
        S3path: "ABC/transcript_sample_script.json",
        transcriptLength: 5,
        addedToStory: false,
        transcripts: [
          {
            dialogueNo: 1,
            speaker: "Patient",
            text: "Oh okay, sure.",
          },
          {
            dialogueNo: 2,
            speaker: "Agent",
            text: "Are there any other questions I can answer for you?",
          },
          {
            dialogueNo: 3,
            speaker: "Patient",
            text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
          },
          {
            dialogueNo: 4,
            speaker: "Agent",
            text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
          },
          {
            dialogueNo: 5,
            speaker: "Patient",
            text: "Alrigt. Thanks a lot",
          },
        ],
      },
      {
        id: 2,
        name: "Transcripts from 3 June 2020 [12:33:28]",
        tags: ["Inbound", "Follow Up Call", "Nervous"],
        dialogueAppearedAt: 0.2,
        dialogueNo: 34,
        callId: "393EBD7490B340B197BDD2552D4DF263",
        callDate: "2017-11-01T00:00:00",
        callTime: "0:12:20",
        callType: "Outbound",
        therapyStatus: "On Therapy",
        dialogueLength: 1.2,
        transcriptLength: 5,
        S3path: "ABC/transcript_sample_script.json",
        addedToStory: false,
        transcripts: [
          {
            dialogueNo: 1,
            speaker: "Patient",
            text: "Oh okay, sure.",
          },
          {
            dialogueNo: 2,
            speaker: "Agent",
            text: "Are there any other questions I can answer for you?",
          },
          {
            dialogueNo: 3,
            speaker: "Patient",
            text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
          },
          {
            dialogueNo: 4,
            speaker: "Agent",
            text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
          },
          {
            dialogueNo: 5,
            speaker: "Patient",
            text: "Alrigt. Thanks a lot",
          },
        ],
      },
    ],
  },
];

export const fetchedFilters: {
  filter: string;
  options?: string[] | undefined;
  optionsObject?:
    | {
        id: number;
        name: string;
        subgroup: {
          id: number;
          name: string;
        }[];
      }[]
    | undefined;
}[] = [
  {
    filter: "Call_Type",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "Inbound", subgroup: [] },
      { id: 2, name: "Outbound", subgroup: [] },
    ],
  },
  {
    filter: "Therapy_Status",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "On Therapy", subgroup: [] },
      { id: 2, name: "Never Started", subgroup: [] },
    ],
  },
  {
    filter: "Call_Intent",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "Introduction Call", subgroup: [] },
      { id: 2, name: "Follow-Up Call 1", subgroup: [] },
      { id: 3, name: "Follow-Up Call 2", subgroup: [] },
      { id: 4, name: "Follow-Up Call 3", subgroup: [] },
      { id: 5, name: "Inquiry Call", subgroup: [] },
      { id: 6, name: "Return Call", subgroup: [] },
    ],
  },
  {
    filter: "Emotions",
    optionsObject: [
      {
        id: 0,
        name: "All",
        subgroup: [],
      },
      {
        id: 1,
        name: "Joy",
        subgroup: [
          { id: 1, name: "Admiration" },
          { id: 2, name: "Amusement" },
          { id: 3, name: "Approval" },
          { id: 4, name: "Caring" },
          { id: 5, name: "Desire" },
          { id: 6, name: "Excitement" },
          { id: 7, name: "Gratitude" },
          { id: 8, name: "Joy" },
          { id: 9, name: "Love" },
          { id: 10, name: "Optimism" },
          { id: 11, name: "Pride" },
          { id: 12, name: "Relief" },
        ],
      },
      {
        id: 2,
        name: "Anger",
        subgroup: [
          { id: 1, name: "Anger" },
          { id: 2, name: "Annoyance" },
        ],
      },
      {
        id: 3,
        name: "Sadness",
        subgroup: [
          { id: 1, name: "Embarrassment" },
          { id: 2, name: "Disappointment" },
          { id: 3, name: "Remorse" },
          { id: 4, name: "Sadness" },
        ],
      },
      { id: 4, name: "Disgust", subgroup: [{ id: 1, name: "Disgust" }] },
      { id: 5, name: "Fear", subgroup: [{ id: 1, name: "Fear" }] },
      {
        id: 5,
        name: "Surprise",
        subgroup: [
          { id: 1, name: "Confusion" },
          { id: 2, name: "Curiosity" },
          { id: 3, name: "Surprise" },
          { id: 4, name: "Realization" },
        ],
      },
      { id: 6, name: "Neutral", subgroup: [{ id: 1, name: "Neutral" }] },
    ],
  },
  {
    filter: "Adherence",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "High", subgroup: [] },
      { id: 2, name: "Medium", subgroup: [] },
      { id: 3, name: "Low", subgroup: [] },
    ],
  },
];

export const storyTypes = ["General Tracker", "Issues"];
export const storyStatus = ["In Draft", "Publishing", "Published"];

export const fullTranscriptData = [
  {
    speaker: "Patient",
    text: "Hello?",
  },
  {
    speaker: "Agent",
    text: "Hello, may I speak to <NAME>?",
  },
  {
    speaker: "Patient",
    text: "Who am I speaking to?",
  },
  {
    speaker: "Agent",
    text: "My name is <NAME>, I'm a nurse educator and I work for Ashfield on behalf of Biogen.",
  },
  {
    speaker: "Patient",
    text: "Oh okay, sure.",
  },
  {
    speaker: "Agent",
    text: "Are there any other questions I can answer for you?",
  },
  {
    speaker: "Patient",
    text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
  },
  {
    speaker: "Agent",
    text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
  },
  {
    speaker: "Patient",
    text: "Alrigt. Thanks a lot.",
  },
];

export const storyList = [
  {
    chartData: [
      [
        {
          id: 1,
          selected: false,
          "Story Name": "Query about Alcohol Consumption",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 2,
          selected: false,
          "Story Name": "Worried about Side Effects",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 3,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 4,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 5,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 6,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 7,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
      ],
    ],
  },
];
export const stories_empty_grouped_columns = [
  {
    title: "Story Name",
    accessor: "Story_Name",
    width: 300,
    Cell: (props: any) => {
      if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
        return (
          <div className="text-text-gray-80 text-base">
            {icons.find((l) => l.id === "HourGlass")?.icon}
            Updating
          </div>
        );
      return <div>{props.cell.value}</div>;
    },
  },
  {
    title: "Story Type",
    accessor: "Story_Type",
    sort: true,
    Cell: (props: any) => {
      if (props.cell.value === "Updating")
        return (
          <div className="text-text-gray-80 text-base">
            {icons.find((l) => l.id === "HourGlass")?.icon}
            Updating
          </div>
        );
      return <div>{props.cell.value}</div>;
    },
  },
  {
    title: "Date Created",
    accessor: "Date_Created",
    sort: true,
  },
  {
    title: "# of Annotated Selections",
    accessor: "#_of_Annotated_Selections",
    sort: true,
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
  },
  {
    title: "Status",
    accessor: "Status",
    sort: true,
    Cell: (props: any) => {
      return (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div className="flex">
            {icons.find((l) => l.id === props.cell.value)?.icon}
            &nbsp;
            {props.cell.value}
          </div>
        </div>
      );
    },
  },
  {
    title: "Actions",
    accessor: "Actions",
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
    width: 40,
  },
];
export const storiesEmptyData = [
  {
    Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
];

export const annotated_selection_empty_grouped_columns = [
  {
    title: "Annotated Selection",
    accessor: "Annotated_Selection",
    width: 300,
  },
  {
    title: "Date Added on",
    accessor: "Date_Added_on",
  },
  {
    title: "Source",
    accessor: "Source",
  },
  // {
  //   title: "Therapy Status",
  //   accessor: "therapyStatus",
  // },
  // {
  //   title: "ToT",
  //   accessor: "ToT",
  // },
  {
    title: "Tags",
    accessor: "Tags",
  },

  {
    title: "Status",
    accessor: "Status",
  },
  {
    title: "Actions",
    accessor: "Actions",

    width: 40,
  },
];

export const annotatedSelectionEmptyData = [
  {
    Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
];
export const publishLimit = 2;

export const filterOptions = [
  {
    filterName: "Show Results",
    type: "radio",
    options: ["All", "Already added to a story", "Not added to a story"],
  },
  {
    filterName: "Speaker Type",
    type: "dropdown",
    options: ["Patient", "Agent"],
  },
  {
    filterName: "Time Range",
    type: "date-range",
    options: [],
  },
  {
    filterName: "Time on Therapy",
    type: "slider",
    options: [],
  },
];
export function SearchEngineTabs() {
  const context = React.useContext(AppContext);
  if (context.userType === userTypeEnum[1])
    return [
      {
        subTab: "Search",
        subSubTabs: [],
      },
    ];
  else
    return [
      {
        subTab: "Search",
        subSubTabs: [],
      },
    ];
}
export function StudyModuleTabs() {
    const context = React.useContext(AppContext);
    if(context.envId === 2)
      return [
        {
          subTab: "Manage Study",
          subSubTabs: [],
        },
        {
          subTab: "Manage Metadata",
          subSubTabs: [],
        },
        {
          subTab: "Configure Taxonomy",
          subSubTabs: [],
        },
      ];
    else if(context.envId === 1)
      return [
        {
          subTab: "Manage Study",
          subSubTabs: [],
        },
        {
          subTab: "Manage Metadata",
          subSubTabs: [],
        },
      ];
    else
      return [
        {
          subTab: "Manage Study",
          subSubTabs: [],
        },
        {
          subTab: "Manage Metadata",
          subSubTabs: [],
        },
        {
          subTab: "Configure Taxonomy",
          subSubTabs: [],
        },
      ];
}
export const searchFilterActiveStatus = {
  Sentiment: true,
  Start_Date: true,
  End_Date: true,
  Call_Type: true,
  Therapy_Status: true,
  Topics: true,
  Emotions: true,
  totStart: true,
  totEnd: true,
};

import React, { useState, useContext , useEffect} from "react";
import { icons } from "../../../../GlobalThemes";
import AppContext from "../../../../store";
import moment, { Moment } from "moment";
import { Form, DatePicker } from "antd";


interface DateInputProps {
  initialValue: [moment.Moment | null, moment.Moment | null];
}

export default function DateInput(props: {
  startDate?: string;
  endDate?: string;
  studyIterId?: number;
  handleDateRangeChange: (studyIterId: number, updatedDateRange: Array<any>) => void;
}) {
  // console.log("aaaaaaaaaaaaaaa", initialValue)
  const app = useContext(AppContext);
  var dateFormat = "MMM YYYY";
  var filterData = {
    startDate: "",
    endDate: "",
    study: 0,
    searchWord: "",
    additional_filters:[]
  };

  const [dateRanges, setDateRanges] = React.useState<Array<any>>([]);
  const [form] = Form.useForm();

  // const [searchFilterData, setSearchFilterData] = useState<any>(filterData);

  const disableSelectedDates = (current: any) => {
    var c = current;
    //console.log(current);
    return (
      current < moment(moment(props.startDate).format("MMM YYYY")) ||
      current > moment(moment(props.endDate).format("MMM YYYY")).endOf("month") ||
      (dateRanges && dateRanges[0] && current.diff(dateRanges[0], "months") > 10) ||
      (dateRanges && dateRanges[1] && dateRanges[1].diff(current, "months") > 10)
    );
  };

  // useEffect(()=>{
  //   console.log("checking dates:",props);
  //   // if (dateRanges)
  //     // props.handleDateRangeChange(dateRanges);
  //   // setDateRanges([moment(moment(props.startDate).format("MMM YYYY")), moment(moment(props.endDate).format("MMM YYYY")])
  // },[])

  // const handleFilters = (filterType: string, filterValue: string | boolean | string[] | number) => {
  //   console.log("value:",filterValue);
  //   var filters: any = searchFilterData;
  //   filters[filterType] = filterValue || "";
  //   setSearchFilterData(filters);
  //   console.log("Date Input Comp handleFilters value -> ", searchFilterData)
  // };
  
  return (
    <Form form={form}>
      <DatePicker.RangePicker
        className=""
        clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
        // disabledDate={disableSelectedDates}
        // Use form.getFieldValue to get the value from the form data
        // value={[
        //   moment(form.getFieldValue("startDate"), "MMM YYYY"),
        //   moment(form.getFieldValue("endDate"), "MMM YYYY"),
        // ]}
        // value={[moment('2023/12/04', dateFormat),moment('2023/01/07', dateFormat)]} // Use 'initialValue' directly here

        defaultValue={[
          moment(moment(props.startDate).format("MMM YYYY")),
          moment(moment(props.endDate).format("MMM YYYY")),
        ]}
        format={dateFormat}
        separator={icons.find((l) => l.id === "Swap")?.icon}
        onChange={(e: any) => {
          // Use form.setFieldsValue to update t 
          form.setFieldsValue({
            startDate: moment(e[0]).format("MMM YYYY"),
            endDate: moment(e[1]).format("MMM YYYY"),
          });
          props.handleDateRangeChange(props.studyIterId !== undefined && props.studyIterId >= 0 ? props.studyIterId : -1, [moment(e[0]).format("MMM YYYY"), moment(e[1]).format("MMM YYYY")]);
          // setDateRanges([moment(e[0]).format("MMM YYYY"), moment(e[1]).format("MMM YYYY")]);
          setDateRanges(e);
        }}
        onCalendarChange={(value: any) => {
          setDateRanges(value);
        }}
        picker="month"
      />
    </Form>
  );
}



import React from "react";
import { useTable, useSortBy, useGlobalFilter, useFilters, usePagination } from "react-table";
// import { dataa } from "./data";
// import { grouped_columns } from "./columns";
import "./style.css";
import { GlobalFilter } from "./globalFilter.jsx";
import { AddCircleOutline } from "@styled-icons/material/AddCircleOutline";
// import { Sort } from "@styled-icons/boxicons-regular/Sort";
import { icons } from "../../../../../GlobalThemes";
/**
 * Renders the Responsive Table Component. The component makes use of react-table hooks.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @returns Table Component
 */
const SimpleTable = (props: {
  tableData?: any;
  rows?: number;
  addButton?: any;
  noSearch?: boolean;
  noPagination?: boolean;
  maxHeight?: number | string;
}) => {
  // const columns = React.useMemo(
  // 	() => props.tableData.column,
  // 	[props.tableData.column]
  // );F
  // const data = React.useMemo(
  // 	() => props.tableData.data,
  // 	[props.tableData.data]
  // );

  const {
    rows,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // footerGroups,
    //@ts-ignore
    page,
    //@ts-ignore
    nextPage,
    //@ts-ignore
    previousPage,
    //@ts-ignore
    canNextPage,
    //@ts-ignore
    canPreviousPage,
    //@ts-ignore
    pageOptions,
    //@ts-ignore
    gotoPage,
    //@ts-ignore
    pageCount,
    //@ts-ignore
    setPageSize,
    state,
    //@ts-ignore
    setGlobalFilter,
    //@ts-ignore
    prepareRow, //@ts-ignore
  } = useTable(
    {
      columns: props.tableData.column,
      data: props.tableData.data,
      initialState: {
        //@ts-ignore
        pageIndex: 0,
        pageSize: props.rows ? props.rows : 25,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  //@ts-ignore
  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <>
      {!props.noSearch && (
        <div className="tableSearch" style={{ margin: "4px 0" }}>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {props.addButton && (
            <>
              <button onClick={props.addButton}>
                <AddCircleOutline
                  size={20}
                  style={{
                    marginRight: "2px",
                    marginBottom: "2px",
                  }}
                />
                Add Abbreviations
              </button>
            </>
          )}
        </div>
      )}
      <div className="scrollTable" style={{ maxHeight: props.maxHeight || "auto" }}>
        <table
          {...getTableProps}
          style={{
            height: "100px",
          }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(
                      //@ts-ignore
                      column.sort
                        ? //@ts-ignore
                          column.getSortByToggleProps()
                        : ""
                    )}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                    }}
                  >
                    {column.render("title")}
                    <span>
                      {
                        //@ts-ignore
                        column.isSorted ? (
                          //@ts-ignore
                          column.isSortedDesc ? (
                            <span
                              className="text-text-gray-40"
                              {
                                //@ts-ignore
                                ...column.getSortByToggleProps()
                              }
                            >
                              {icons.find((l) => l.id === "Descending")?.icon}
                            </span>
                          ) : (
                            <span
                              className="text-text-gray-40"
                              {
                                //@ts-ignore
                                ...column.getSortByToggleProps()
                              }
                            >
                              {icons.find((l) => l.id === "Ascending")?.icon}
                            </span>
                          )
                        ) : //@ts-ignore
                        column.sort ? (
                          <>
                            <span
                              className="text-text-gray-40"
                              {
                                //@ts-ignore
                                ...column.getSortByToggleProps()
                              }
                            >
                              {icons.find((l) => l.id === "Unsorted")?.icon}
                            </span>
                          </>
                        ) : (
                          ""
                        )
                      }
                    </span>
                    {/* <div>
										{
											//@ts-ignore
											column.canFilter
												? column.render("Filter")
												: null
										}
									</div> */}
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {
              //@ts-ignore
              props.noPagination
                ? rows.map((row, i) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell: any) => {
                          return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                        })}
                      </tr>
                    );
                  })
                : page.map((row: any) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {
                          //@ts-ignore
                          row.cells.map((cell) => {
                            return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                          })
                        }
                      </tr>
                    );
                  })
            }
          </tbody>
          {/* <tfoot>
					{footerGroups.map((footerGroup) => (
						<tr {...footerGroup.getFooterGroupProps()}>
							{footerGroup.headers.map((column) => (
								<td {...column.getFooterProps}>
									{column.render("Footer")}
								</td>
							))}
						</tr>
					))}
				</tfoot> */}
        </table>
      </div>
      {!props.noPagination && (
        <div className="pagination text-sm">
          <div className="paginationLeft"></div>
          <div className="paginationRight space-x-2">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
              |
            </span>
            <span>
              <button
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
                style={{ borderColor: "white" }}
              >
                {"<"}
              </button>
              <button
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
                style={{ width: "60px" }}
              >
                Prev
              </button>
              <button
                onClick={() => nextPage()}
                disabled={!canNextPage}
                style={{ width: "60px" }}
              >
                Next
              </button>
              <button
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
                style={{ borderColor: "white" }}
              >
                {">"}
              </button>
            </span>
            <span>
              | Go to {" "}
              <input
                type="number"
                // defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value
                    ? Number(e.target.value) - 1
                    : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "50px" }}
              />{" "}
            </span>
          </div>
          {/* <div className="paginationLeft">
            <span>
              Page{" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{" "}
            </span>
            <span>
              | Go to page:{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const pageNumber = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(pageNumber);
                }}
                style={{ width: "50px" }}
              />{" "}
            </span>
            <select value={pageSize} onChange={(e) => setPageSize(Number(e.target.value))}>
              {[25, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>{" "}
          </div>
          <div className="paginationRight">
            <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {"<<"}
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              Prev
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              Next
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {">>"}
            </button>
          </div> */}
        </div>
      )}
    </>
  );
};
export default SimpleTable;

import { Divider, Drawer, Select, Skeleton, Space } from "antd";
import React from "react";
import Collapse from "../../../../Components/Collapse";
import { SentimentPatientCard } from "../../../../Components/ReportCards/CommonCardComponents/SentimentPatientCard";
import {
  SkeletonDetail,
  SkeletonSentiment,
} from "../../../../Components/SkeletonCard";
import { icons } from "../../../../GlobalThemes";
import ReportTypes from "../../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../../libraries/report-types/charts/interfaces/main";
import {
  transformBarChartData,
  transformTopChartData,
} from "../../../Dashboard/Processing";
import { findMaxGroupValue } from "../../../Dashboard/ProgramOverview/ProgramOverviewCalls";

type FetchData = {
  cardName: string;
  kpi: string[];
  month: string[];
  value: number[];
  unit: string[];
  vsPrevMonth: string[];
  vsPrevMonthArray: string[];
  topChart: {
    [id: string]: number;
  };
  chartName: string[];
  xAxisLabel: string[];
  yAxisLabel: string[];
  chartColor: string[];
  chartAccessor: string[];
  footer: string;
  storyData?: {
    id?: string;
    storyName?: string;
  }[];
  chartData: {
    [id: string]: {
      [id: string]: number;
    };
  }[];
};

export function OverAllDisconRate(props: { data: any }) {
  const [fetchedData, setFetchedData] = React.useState<FetchData | any>(null);
  const [loading, setLoading] = React.useState(true);
  const [view, setView] = React.useState(0);
  const [visible, setVisible] = React.useState(false);
  const [drawerHeader, setdrawerHeader] = React.useState("");
  const [drawerBody, setDrawerBody] = React.useState(<></>);
  const onClose = () => {
    setVisible(false);
  };

  const getTickValues = () => {
    let arr: any = [];
    const data = fetchedData?.chartData[view]
    for (const key in data) {
      arr.push(Object.values(data[key]))
    }
    return arr
  }

  React.useEffect(() => {
    setFetchedData(props.data);
    setLoading(false);
  }, []);

  // @ts-ignorez
  const format: string = v => `${v}%`
  // const findMaxValue=findMaxGroupValue(
  //   transformBarChartData(
  //     fetchedData?.chartData.length > 0 &&
  //       fetchedData !== undefined
  //       ? fetchedData?.chartData[view]
  //       : [],
  //     fetchedData?.chartData.length > 0 &&
  //       fetchedData !== undefined
  //       ? fetchedData?.chartColor
  //       : []
  //   )?.data
  // )
  // const maxValCal=findMaxValue<100 ?100 :findMaxValue
  // console.log('findMaxVal',findMaxValue)

  return (
    <>
      {!loading ? (
        <div className="bg-white w-full rate-graph">
          <ReportTypes
            header={
              <>
                <div className="flex gap-2 font-semibold text-base">
                  <p>{fetchedData?.cardName}</p>
                </div>
              </>
            }
            // labelFormat={format}
            // axisLeft={{ format }}
            hideShadow
            hideRibbon={false}
            minHeight="30px"
            height="15rem"
            // height="250px"
            bodyPadding="0px 0px"
            title={{ title: "xx" }}
            chartType={ReportTypesComponentInterface.chartType.line}
            series={
              transformBarChartData(
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartData[view]
                  : [],
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartColor
                  : []
              ).series
            }
            data={
              transformBarChartData(
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartData[view]
                  : [],
                fetchedData?.chartData.length > 0 && fetchedData !== undefined
                  ? fetchedData?.chartColor
                  : []
              ).data
            }
            tooltip={{
              isInteractive: true,
              format,
            }}
            axis={{
              primaryX: {
                dim: 0,
                tickSize: 0,
                tickValues: 0,
                tickRotation: 0,
                tikPadding: 10,
                legend: "",
                legendPosition:
                  ReportTypesComponentInterface.axisLegendPosition.middle,
                legendOffset: 20,
              },
              primaryY: {
                // labelFormat:{format},
                tickSize: 0,
                tickValues: 5,
                tickRotation: 0,
                legend: fetchedData?.yAxisLabel[0],
                legendPosition:
                  ReportTypesComponentInterface.axisLegendPosition.middle,
                legendOffset: -45,
                minValue: 0,
                maxValue: 100,
              },
            }}
            chartConfig={{
              cartesian: {
                line: {
                  lineWidth: 2,
                  pointSize: 7,
                },
                // groupMode: groupMode.grouped,
                grid: {
                  enableGridX: false,
                  enableGridY: true,
                  gridYValues: 5,
                  gridXValues: 0,
                },
              },
            }}
            style={{
              margin: {
                top: 20,
                right: 20,
                bottom: 30,
                left: 40,
              },
              axisWidth: 1,
              axisColor: "#E7E7E7",
              textColor: "#53565A",
              fontSize: 10
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </>
  );
}

import React, { useState } from "react";
import TableWrapper from "../../Components/TableWrapper";
import ReportTypes from "../../libraries/report-types";
import { icons, userTypeEnum } from "../../GlobalThemes";
import { SearchFilters } from "../../Components/PatientJourneyCards/SearchFilters";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { Route, Routes, useNavigate } from "react-router-dom";
import { patientJourneyData, patient_journey_empty_grouped_columns } from "../SearchEngine/data";
import PatientDetails from "../PatientJourney/PatientDetails";
import moment from "moment";
import { transformTableData } from "../Dashboard/Processing";
import * as PatientJourneyServiceHelper from "../SearchEngine/ServiceHelpers/patientJourney";
// import { FilterCard } from "../../Components/FilterCard";
// import useBreadcrumbs from "use-react-router-breadcrumbs";
// import { Link } from "react-router-dom";

const dummyD = [
	{
		patientId: 1,
		lh_id: "LH000001",
		currentTherapy: "LECTRAZINE",
		disconRisk: "Medium",
		topUnmetNeeds_1: "Sad/fear about side effects",
		topUnmetNeeds_2: "Curiosity on diet instruction",
		topUnmetNeeds_3: "Anger on insurance",
		topUnmetNeeds_4: null,
		topUnmetNeeds_5: null,
		topUnmetNeeds_6: null,
		topUnmetNeeds_7: null,
		topUnmetNeeds_8: null,
		topUnmetNeeds_9: null,
		topUnmetNeeds_10: null,
		timeOnTherapy: 731,
		therapyStartDate: "2019-12-17T00:00:00",
		outOfStockDate: "2021-09-18T00:00:00",
	},
	{
		patientId: 2,
		lh_id: "LH000002",
		currentTherapy: "LECTRAZINE",
		disconRisk: "Low",
		topUnmetNeeds_1: "Sad/fear about side effects",
		topUnmetNeeds_2: null,
		topUnmetNeeds_3: null,
		topUnmetNeeds_4: null,
		topUnmetNeeds_5: null,
		topUnmetNeeds_6: null,
		topUnmetNeeds_7: null,
		topUnmetNeeds_8: null,
		topUnmetNeeds_9: null,
		topUnmetNeeds_10: null,
		timeOnTherapy: 120,
		therapyStartDate: "2019-12-10T00:00:00",
		outOfStockDate: "2020-01-09T00:00:00",
	},
	{
		patientId: 13,
		lh_id: "LH0000013",
		currentTherapy: "LECTRAZINE",
		disconRisk: null,
		topUnmetNeeds_1: null,
		topUnmetNeeds_2: null,
		topUnmetNeeds_3: null,
		topUnmetNeeds_4: null,
		topUnmetNeeds_5: null,
		topUnmetNeeds_6: null,
		topUnmetNeeds_7: null,
		topUnmetNeeds_8: null,
		topUnmetNeeds_9: null,
		topUnmetNeeds_10: null,
		timeOnTherapy: 664,
		therapyStartDate: "2019-12-13T00:00:00",
		outOfStockDate: "2021-07-09T00:00:00",
	},
	{
		patientId: 26,
		lh_id: "LH0000026",
		currentTherapy: "LECTRAZINE",
		disconRisk: "High",
		topUnmetNeeds_1: "Sad/fear about side effects",
		topUnmetNeeds_2: "Curiosity on diet instruction",
		topUnmetNeeds_3: null,
		topUnmetNeeds_4: null,
		topUnmetNeeds_5: null,
		topUnmetNeeds_6: null,
		topUnmetNeeds_7: null,
		topUnmetNeeds_8: null,
		topUnmetNeeds_9: null,
		topUnmetNeeds_10: null,
		timeOnTherapy: 378,
		therapyStartDate: "2019-12-13T00:00:00",
		outOfStockDate: "2020-09-26T00:00:00",
	},
];

function PatientJourneyList(props: { data?: any }) {
	React.useEffect(() => {
		let linkUrl = window.location.href;
		let x = linkUrl.lastIndexOf("patientID=");
		let y: any = null;
		if (x !== -1) {
			y = linkUrl.substring(x + 10);
		}
		if (y) {
			setShowPatientDetails(4);
		}
	}, []);
	const [patientListPage, setPatientListPage] = React.useState<boolean>(false);
	const [fetching, setFetching] = React.useState(false);
	const [data, setData] = React.useState();
	const [fetchedData, setFetchedData] = React.useState<
		{
			[id: string]: string | number;
		}[]
	>([]);

	const navigate = useNavigate();
	const [patientsList, setPatientsList] = useState<any>();
	const [showPatientDetails, setShowPatientDetails] = useState<any>(false);
	const showPatientHistory = (patientID: any, lh_id: any) => {
		console.log(patientID);
		setPatientListPage(true);
		setShowPatientDetails(patientID);
		// navigate("/Patient_Journey/patient-details/patientID=" + patientID, { state: { lh_id: patientID } });
	};

	async function fetchdata() {
		const data: any = await PatientJourneyServiceHelper.getPatientListData();
		// const data: any = dummyD;
		if (data) {
			setData(data);
			let dummyData: any = [];
			let dataForList: any = [];

			data.map((i: any, index: any) => {
				let obj: any = {};
				obj.lh_id = i.lh_id;
				obj.currentTherapy = i.currentTherapy;
				obj.disconRisk = i.disconRisk;
				obj.outOfStockDate = moment(i.outOfStockDate).format("D MMM YYYY");
				obj.patientId = i.patientId;
				obj.therapyStartDate = moment(i.therapyStartDate).format("D MMM YYYY");
				obj.timeOnTherapy = i.timeOnTherapy;
				// obj.topUnmetNeeds = i.topUnmetNeeds;
				obj.topUnmetNeeds_1 = i.topUnmetNeeds_1;
				obj.topUnmetNeeds_2 = i.topUnmetNeeds_2;
				obj.topUnmetNeeds_3 = i.topUnmetNeeds_3;
				obj.topUnmetNeeds_4 = i.topUnmetNeeds_4;
				obj.topUnmetNeeds_5 = i.topUnmetNeeds_5;
				obj.topUnmetNeeds_6 = i.topUnmetNeeds_6;
				obj.topUnmetNeeds_7 = i.topUnmetNeeds_7;
				obj.topUnmetNeeds_8 = i.topUnmetNeeds_8;
				obj.topUnmetNeeds_9 = i.topUnmetNeeds_9;
				obj.topUnmetNeeds_10 = i.topUnmetNeeds_10;
				dataForList.push(obj);
			});

			// console.log(dataForList);
			dataForList.map((i: any) => {
				let obj: any = {};
				// obj.lh_id = (
				//   <span
				//     onClick={() => showPatientHistory(i.patientId, i.lh_id)}
				//     style={{ color: "#27A6A4", cursor: "pointer" }}
				//   >
				//     {/* <Route path="/Patient_Details" element={<PatientDetails />}> */}
				//     {i.lh_id}
				//     {/* </Route> */}
				//   </span>
				// );
				obj.id = i.patientId;
				obj.lh_id = i.lh_id;
				obj.currentTherapy = i.currentTherapy;
				obj.disconRisk = (
					<div
						style={{
							color: i.disconRisk === "High" ? "#B21111" : i.disconRisk === "Medium" ? "#8A640C" : "#27A6A7",
							font: "semibold",
						}}>
						{i.disconRisk === "High" ? (
							<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "ExclamationTriangle")?.icon}</span>
						) : i.disconRisk === "Medium" ? (
							<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "MinusCircle")?.icon}</span>
						) : i.disconRisk === "Low" ? (
							<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "Thumbs Up")?.icon}</span>
						) : (
							<></>
						)}

						<span>{i.disconRisk}</span>
					</div>
				);
				obj.topUnmetNeeds = (
					<ul style={{ listStyleType: "disc" }}>
						{i.topUnmetNeeds_1 && <li className="mt-2">{i.topUnmetNeeds_1}</li>}
						{i.topUnmetNeeds_2 && <li className="mt-2">{i.topUnmetNeeds_2}</li>}
						{i.topUnmetNeeds_3 && <li className="mt-2">{i.topUnmetNeeds_3}</li>}
						{i.topUnmetNeeds_4 && <li className="mt-2">{i.topUnmetNeeds_4}</li>}
						{i.topUnmetNeeds_5 && <li className="mt-2">{i.topUnmetNeeds_5}</li>}
						{i.topUnmetNeeds_6 && <li className="mt-2">{i.topUnmetNeeds_6}</li>}
						{i.topUnmetNeeds_7 && <li className="mt-2">{i.topUnmetNeeds_7}</li>}
						{i.topUnmetNeeds_8 && <li className="mt-2">{i.topUnmetNeeds_8}</li>}
						{i.topUnmetNeeds_9 && <li className="mt-2">{i.topUnmetNeeds_9}</li>}
						{i.topUnmetNeeds_10 && <li className="mt-2">{i.topUnmetNeeds_10}</li>}
					</ul>
				);
				obj.therapyStartDate = i.therapyStartDate;
				obj.timeOnTherapy = i.timeOnTherapy;
				obj.outOfStockDate = i.outOfStockDate;
				dummyData.push(obj);
			});
			setFetchedData(dummyData);
			setPatientsList(dummyData);

			setFetching(true);
		}
	}

	React.useEffect(() => {
		fetchdata();
		// setFetchedData(DUMMY_DATA);
	}, [showPatientDetails]);

	return (
		<>
			{fetching ? (
				<>
					{/* Patient Details Page */}
					{/* {showPatientDetails ? (
            <PatientDetails
              showPatientDetails={showPatientDetails}
              setShowPatientDetails={setShowPatientDetails}
              patientId={showPatientDetails}
            />
          ) : ( )}*/}
					<div>
						<div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
							<div className="flex py-3 items-center">
								<h2 className="text-xl font-semibold ">Patient Journey</h2>
							</div>
							<div className="pl-7">
								<SearchFilters
									data={data}
									showPatientHistory={showPatientHistory}
									patientsList={patientsList}
									setPatientsList={setPatientsList}
									fetchedData={fetchedData}
									setFetchedData={setFetchedData}
								/>
							</div>
						</div>
						<div className="bg-body p-4 w-screen" style={{ height: "calc(100vh - 13.3rem)" }}>
							<TableWrapper title="Patient List" value={1} loading={false}>
								<>
									<ReportTypes
										hideShadow
										hideRibbon
										chartType={ReportTypesComponentInterface.chartType.simpleTable}
										series={[]}
										chartConfig={{
											table: {
												tableData: {
													column: patient_journey_empty_grouped_columns,
													data: transformTableData(fetchedData, [], [{ id: "lh_id", link: "patient-details" }], undefined, true).data,
												},
												noSearchField: true,
												noPagination: false,
												maxHeight: "59vh",
											},
										}}
									/>
								</>
							</TableWrapper>
						</div>
					</div>
				</>
			) : (
				<>
					{/* Patient Journey Page */}
					<div className="w-screen px-4 shadow-lg" style={{ zIndex: 99, position: "relative" }}>
						<div className="flex py-3 items-center">
							<h2 className="text-xl font-semibold ">Patient Journey</h2>
						</div>
						<div className="pl-7">
							<SearchFilters
								data={data}
								showPatientHistory={showPatientHistory}
								patientsList={patientsList}
								setPatientsList={setPatientsList}
								fetchedData={fetchedData}
								setFetchedData={setFetchedData}
							/>
						</div>
					</div>
					<div className="bg-body p-4 w-screen" style={{ height: "calc(100vh - 13.3rem)" }}>
						<TableWrapper loading={true} minHeight="37em">
							<ReportTypes
								hideShadow
								hideRibbon
								chartType={ReportTypesComponentInterface.chartType.simpleTable}
								series={[]}
								chartConfig={{
									table: {
										tableData: {
											column: patient_journey_empty_grouped_columns,
											data: patientJourneyData,
										},
										noSearchField: true,
										noPagination: true,
										maxHeight: "59vh",
									},
								}}
							/>
						</TableWrapper>
					</div>
				</>
			)}
		</>
	);
}

export function PatientJourney() {
	return (
		<>
			<Routes>
				<Route key={"PatientJourney 0"} path="/" element={<PatientJourneyList />} />
				<Route key="Patient Journey - Patient Details" path="patient-details/:patientID/*" element={<PatientDetails />} />
			</Routes>
		</>
	);
}

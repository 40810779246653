export const Icon = () => (
	<svg
		width="21"
		height="20"
		viewBox="0 0 21 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M14.2431 2.51501L7.5051 9.25201L6.7981 11.373L5.7581 12.414L8.5861 15.243L9.6261 14.202L11.7481 13.495L18.4851 6.75701L14.2431 2.51501ZM20.6071 6.05001C20.7946 6.23753 20.8999 6.49184 20.8999 6.75701C20.8999 7.02217 20.7946 7.27648 20.6071 7.46401L12.8281 15.243L10.7081 15.95L9.2931 17.364C9.10557 17.5515 8.85127 17.6568 8.5861 17.6568C8.32094 17.6568 8.06663 17.5515 7.8791 17.364L3.6361 13.121C3.44863 12.9335 3.34332 12.6792 3.34332 12.414C3.34332 12.1488 3.44863 11.8945 3.6361 11.707L5.0501 10.293L5.7571 8.17201L13.5361 0.393006C13.7236 0.205535 13.9779 0.10022 14.2431 0.10022C14.5083 0.10022 14.7626 0.205535 14.9501 0.393006L20.6071 6.05001ZM14.2431 5.34301L15.6571 6.75701L10.7071 11.707L9.2931 10.293L14.2431 5.34301ZM3.2831 14.89L6.1111 17.719L4.6971 19.133L0.454102 17.719L3.2821 14.89H3.2831Z"
			fill="#B2B0B6"
		/>
		<line
			x1="0.707107"
			y1="-0.707107"
			x2="24.7071"
			y2="23.2929"
			stroke="#B2B0B6"
			stroke-width="2"
		/>
	</svg>
);

import React from "react";
import { OverAllDisconRate } from "./SubComponents/OverallDisconRate";
import { DisconRateRishBucket } from "./SubComponents/DisconRateRishBucket";
import * as NodeGraphServiceHelper from "../../SearchEngine/ServiceHelpers/nodeGraph";
// import { Header } from "../../Components/ReportCards/CommonCardComponents/Header";
// import { overAllDisconRate, disconRateRishBucket, disconRateSplitByTactic } from "./data";

// const DummData = {
// 	overAllDisconRate: [
// 		{
// 			cardName: "Over All Discon Rate",
// 			yAxisLabel: [],
// 			xAxisLabel: [],
// 			topChart: {},
// 			chartData: [
// 				{
// 					"Jul 21": {
// 						Positive: 90,
// 					},
// 					"Aug 21": {
// 						Positive: 60,
// 					},
// 					"Sep 21": {
// 						Positive: 70,
// 					},
// 					"Oct 21": {
// 						Positive: 20,
// 					},
// 					"Nov 21": {
// 						Positive: 14,
// 					},
// 					"Dec 21": {
// 						Positive: 26,
// 					},
// 					"Jan 21": {
// 						Positive: 36,
// 					},
// 					"Feb 21": {
// 						Positive: 45,
// 					},
// 					"Mar 21": {
// 						Positive: 28,
// 					},
// 					"Apr 21": {
// 						Positive: 41,
// 					},
// 					"May 21": {
// 						Positive: 80,
// 					},
// 				},
// 			],
// 			chartColor: ["#4472C4"],
// 		},
// 	],
// 	disconRateRishBucket: [
// 		{
// 			options: ["July 21", "Aug 21", "Sep 21", "Oct 21", "Nov 21", "Dec 21", "Jan 21", "Feb 21", "Mar 21", "Apr 21", "May 21", "June 21"],
// 			cardName: "Discon Rate - By risk Bucket",
// 			yAxisLabel: [],
// 			xAxisLabel: [],
// 			topChart: {},
// 			chartData: [
// 				{
// 					"Jul 21": {
// 						High: 40,
// 						Medium: 30,
// 						Low: 10,
// 					},
// 					"Aug 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"Aug 21": {
// 						High: 10,
// 						Medium: 20,
// 						Low: 30,
// 					},
// 					"Sep 21": {
// 						High: 100,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"Sep 21": {
// 						High: 70,
// 						Medium: 80,
// 						Low: 90,
// 					},
// 					"Oct 21": {
// 						High: 10,
// 						Medium: 40,
// 						Low: 60,
// 					},
// 				},
// 				{
// 					"Oct 21": {
// 						High: 40,
// 						Medium: 30,
// 						Low: 10,
// 					},
// 					"Nov 21": {
// 						High: 55,
// 						Medium: 100,
// 						Low: 90,
// 					},
// 				},
// 				{
// 					"Nov 21": {
// 						High: 100,
// 						Medium: 80,
// 						Low: 70,
// 					},
// 					"Dec 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"Dec 21": {
// 						High: 20,
// 						Medium: 30,
// 						Low: 40,
// 					},
// 					"Jan 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"Jan 21": {
// 						High: 50,
// 						Medium: 60,
// 						Low: 70,
// 					},
// 					"Feb 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"Feb 21": {
// 						High: 20,
// 						Medium: 10,
// 						Low: 70,
// 					},
// 					"Mar 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"Mar 21": {
// 						High: 10,
// 						Medium: 10,
// 						Low: 10,
// 					},
// 					"Apr 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"Apr 21": {
// 						High: 70,
// 						Medium: 90,
// 						Low: 60,
// 					},
// 					"May 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 				{
// 					"May 21": {
// 						High: 30,
// 						Medium: 40,
// 						Low: 50,
// 					},
// 					"June 21": {
// 						High: 60,
// 						Medium: 20,
// 						Low: 10,
// 					},
// 				},
// 				{
// 					"June 21": {
// 						High: 20,
// 						Medium: 80,
// 						Low: 90,
// 					},
// 					"July 21": {
// 						High: 80,
// 						Medium: 70,
// 						Low: 50,
// 					},
// 				},
// 			],
// 			chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
// 		},
// 	],
// 	disconRateSplitByTactic: [
// 		{
// 			options: ["July 21", "Aug 21", "Sep 21", "Oct 21", "Nov 21", "Dec 21", "Jan 21", "Feb 21", "Mar 21", "Apr 21", "May 21", "June 21"],
// 			cardName: "Discon Rate - Split By Tactic Activation",
// 			yAxisLabel: [],
// 			xAxisLabel: [],
// 			topChart: {},
// 			chartData: [
// 				{
// 					"Jul 21": {
// 						"More than One Tactic Scent": 40,
// 						"One Tactic Scent": 30,
// 						"No Tactic Scent": 10,
// 					},
// 					"Aug 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"Aug 21": {
// 						"More than One Tactic Scent": 10,
// 						"One Tactic Scent": 20,
// 						"No Tactic Scent": 30,
// 					},
// 					"Sep 21": {
// 						"More than One Tactic Scent": 100,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"Sep 21": {
// 						"More than One Tactic Scent": 70,
// 						"One Tactic Scent": 80,
// 						"No Tactic Scent": 90,
// 					},
// 					"Oct 21": {
// 						"More than One Tactic Scent": 10,
// 						"One Tactic Scent": 40,
// 						"No Tactic Scent": 60,
// 					},
// 				},
// 				{
// 					"Oct 21": {
// 						"More than One Tactic Scent": 40,
// 						"One Tactic Scent": 30,
// 						"No Tactic Scent": 10,
// 					},
// 					"Nov 21": {
// 						"More than One Tactic Scent": 55,
// 						"One Tactic Scent": 100,
// 						"No Tactic Scent": 90,
// 					},
// 				},
// 				{
// 					"Nov 21": {
// 						"More than One Tactic Scent": 100,
// 						"One Tactic Scent": 80,
// 						"No Tactic Scent": 70,
// 					},
// 					"Dec 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"Dec 21": {
// 						"More than One Tactic Scent": 20,
// 						"One Tactic Scent": 30,
// 						"No Tactic Scent": 40,
// 					},
// 					"Jan 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"Jan 21": {
// 						"More than One Tactic Scent": 50,
// 						"One Tactic Scent": 60,
// 						"No Tactic Scent": 70,
// 					},
// 					"Feb 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"Feb 21": {
// 						"More than One Tactic Scent": 20,
// 						"One Tactic Scent": 10,
// 						"No Tactic Scent": 70,
// 					},
// 					"Mar 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"Mar 21": {
// 						"More than One Tactic Scent": 10,
// 						"One Tactic Scent": 10,
// 						"No Tactic Scent": 10,
// 					},
// 					"Apr 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"Apr 21": {
// 						"More than One Tactic Scent": 70,
// 						"One Tactic Scent": 90,
// 						"No Tactic Scent": 60,
// 					},
// 					"May 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 				{
// 					"May 21": {
// 						"More than One Tactic Scent": 30,
// 						"One Tactic Scent": 40,
// 						"No Tactic Scent": 50,
// 					},
// 					"June 21": {
// 						"More than One Tactic Scent": 60,
// 						"One Tactic Scent": 20,
// 						"No Tactic Scent": 10,
// 					},
// 				},
// 				{
// 					"June 21": {
// 						"More than One Tactic Scent": 20,
// 						"One Tactic Scent": 80,
// 						"No Tactic Scent": 90,
// 					},
// 					"July 21": {
// 						"More than One Tactic Scent": 80,
// 						"One Tactic Scent": 70,
// 						"No Tactic Scent": 50,
// 					},
// 				},
// 			],
// 			chartColor: ["#C3C3C3", "#BACAE5", "#5980C7"],
// 		},
// 	],
// };

export default function DisconSummary() {
  const [overAllDisconRate, setOverAllDisconRate] = React.useState<any>(null);
  const [disconRateRishBucket, setDisconRateRishBucket] = React.useState<any>(null);
  const [disconRateSplitByTactic, setDisconRateSplitByTactic] = React.useState<any>(null);
  // const [fetchData, setFetchData] = React.useState<any>();

  async function fetchDataForPage() {
    const data: any = await NodeGraphServiceHelper.getNodeJsons(
      "kural/json_data/hnp_discon_summ.txt"
    );
    // const data: any = DummData;
    if (data) {
      setOverAllDisconRate(data["overAllDisconRate"]);
      setDisconRateRishBucket(data["disconRateRishBucket"]);
      setDisconRateSplitByTactic(data["disconRateSplitByTactic"]);
      // console.log(data["overAllDisconRate"][0], data["overAllDisconRate"]);
    }
  }

  React.useEffect(() => {
    fetchDataForPage();
  }, []);

  return (
    <>
      {overAllDisconRate && disconRateRishBucket && disconRateSplitByTactic && (
        <div className="discon-summary">
          <div className="grid lg:grid-cols-12 md:grid-cols-1 gap-4">
            <div className="bg-white shadow-md col-span-12 shadow-gray-900 p-4">
              <div className="flex justify-between">
                <OverAllDisconRate data={overAllDisconRate[0]} />
              </div>
            </div>
            <div className="bg-white shadow-md col-span-6 shadow-gray-900 p-4">
              <div className="w-full">
                <DisconRateRishBucket data={disconRateRishBucket[0]} />
              </div>
            </div>
            <div className="bg-white shadow-md col-span-6 shadow-gray-900 p-4">
              <div className="w-full">
                <DisconRateRishBucket data={disconRateSplitByTactic[0]} />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

import { POCard } from "../../../Components/ReportCards/POCard";
import { useState, useEffect } from "react";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import { groupMode } from "../../../libraries/report-types/charts/interfaces/cartesianChart";
import { NumberData } from "../../../Components/ReportCards/CommonCardComponents/NumberData";
import { ArrowComparison } from "../../../Components/ReportCards/CommonCardComponents/ArrowComparison";
import Checkbox from "antd/lib/checkbox/Checkbox";
import { transformBarChartData } from "../Processing";
import { Select } from "antd";
import SkeletonCard from "../../../Components/SkeletonCard";
import { FilterActiveInterface } from "..";
import { filterTabs } from "../ProgramOverview";
import { findMaxGroupValue } from "./ProgramOverviewCalls";

/**
 * This is the Reach subtab in Program Overview tab
 *
 * @param data - The props contains the data to be rendered in Reach tab
 * @param callVolumeRefresh - The props used to get the call volume data based on time on therapy
 *
 * @returns three cards with New Patient Volume and Call Volume for Patient
 */

export function ProgramOverviewReach(props: {
	data: any;
	callVolumeRefresh: (tot: string) => Promise<boolean>;
}) {
	const [indexChart, setIndexChart] = useState<number>(0);
	const [filterValues, setFilterValues] = useState<Array<string>>([
		"30 Days",
	]);
	console.log("DataReach", props.data);
	const [filteredChart, setFilteredChart] = useState(
		props.data[1].chartData.length > 0 ? props.data[1].chartData[2] : []
	);
	const [headerTime, setHeaderTime] = useState("30 Days");
	const [totLoading, setTotLoading] = useState(false);
	useEffect(() => {
		filterChartData(
			props.data[1].chartData.length > 0
				? props.data[1].chartData[2]
				: [],
			filterValues
		);
	}, []);

	async function handleTotFilter(value: string) {
		setTotLoading(true);
		let tot: string = "";
		if (value === ">180 Days") tot = "180+";
		else tot = value.split(" ")[0];
		const data = await props.callVolumeRefresh(tot);
		if (data) {
			setTotLoading(false);
		}
	}

	const filterArray = (checkboxState: boolean, checkboxValue: string) => {
		var values = filterValues;
		if (checkboxState === true) {
			values.push(checkboxValue);
		} else {
			const index = values.indexOf(checkboxValue);
			if (index > -1) {
				values.splice(index, 1);
			}
		}
		setFilterValues(values);
		filterChartData(
			props.data[1].chartData.length > 0
				? props.data[1].chartData[2]
				: [],
			values
		);
	};

	const filterChartData = (
		chartData: Array<any>,
		filteredValues: string[]
	) => {
		if (filteredValues.length === 0) {
			setFilteredChart(chartData);
		} else {
			var keys = Object.keys(chartData);
			var data: any = {};
			keys.forEach((key: any, index: number) => {
				data[key] = {};
				filteredValues.forEach((value: string, index1: number) => {
					data[key][value] = chartData[key][value];
				});
			});
			setFilteredChart(data);
		}
	};
	var data = [
		{
			header: props.data[0].cardName,
			cardTopData: (
				<div className="flex items-end mx-7 my-4 mb-14">
					<NumberData
						dataNumber={props.data[0].value}
						dataName={props.data[0].month}
					/>
					<ArrowComparison
						dataNumber={props.data[0].vsPrevMonth}
						dataName={
							props.data[0].timeframeDisplay === "Quarter"
								? "vs Prev. Quarter"
								: "vs Prev. Month"
						}
					/>
				</div>
			),
			iconName: "Report User",
			chartElement: [
				<ReportTypes
					hideShadow
					hideRibbon
					minHeight="30px"
					height="43vh"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.bar}
					series={
						transformBarChartData(
							props.data[0].chartData,
							props.data[0].chartColor
						).series
					}
					data={
						transformBarChartData(
							props.data[0].chartData,
							props.data[0].chartColor
						).data
					}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: props.data[0].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "normal",
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 20,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.69, groupGap: 0 },
						},
					}}
					style={{
						margin: {
							top: 30,
							right: 10,
							bottom: 65,
							left: 48,
						},
					}}
				/>,
			],
		},
	];
	var charts = [
		[
			<ReportTypes
				header={props.data[1].chartName[0]}
				hideShadow
				hideRibbon
				minHeight="30px"
				height="43vh"
				bodyPadding="0px 20px"
				title={{ title: "xx" }}
				chartType={ReportTypesComponentInterface.chartType.bar}
				series={
					transformBarChartData(
						props.data[1].chartData.length > 0
							? props.data[1].chartData[0]
							: [],
						props.data[1].chartData.length > 0
							? props.data[1].chartColor[0]
							: []
					).series
				}
				data={
					transformBarChartData(
						props.data[1].chartData.length > 0
							? props.data[1].chartData[0]
							: [],
						props.data[1].chartData.length > 0
							? props.data[1].chartColor[0]
							: []
					).data
				}
				axis={{
					primaryX: {
						dim: 0,
						tickValues: 5,
						tickRotation: -45,
						legend: "",
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: 22,
					},
					primaryY: {
						tickSize: 0,
						tickValues: 5,
						tickRotation: 0,
						legend: props.data[1].yAxisLabel[0],
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: -40,
					},
				}}
				chartConfig={{
					cartesian: {
						line: {
							pointSize: 20,
						},
						// groupMode: groupMode.grouped,
						grid: {
							enableGridX: false,
							enableGridY: true,
							gridYValues: 5,
							gridXValues: 5,
						},
						gaps: { barGap: 0.69, groupGap: 0 },
					},
				}}
				tooltip={{
					isInteractive: true,
					format: "normal",
				}}
				style={{
					margin: {
						top: 10,
						right: 10,
						bottom: 65,
						left: 48,
					},
				}}
			/>,
			<ReportTypes
				header={props.data[1].chartName[1]}
				hideShadow
				hideRibbon
				minHeight="30px"
				height="43vh"
				bodyPadding="0px 20px"
				title={{ title: "xx" }}
				chartType={ReportTypesComponentInterface.chartType.bar}
				series={
					transformBarChartData(
						props.data[1].chartData.length > 0
							? props.data[1].chartData[1]
							: [],
						props.data[1].chartData.length > 0
							? props.data[1].chartColor[1]
							: []
					).series
				}
				data={
					transformBarChartData(
						props.data[1].chartData.length > 0
							? props.data[1].chartData[1]
							: [],
						props.data[1].chartData.length > 0
							? props.data[1].chartColor[1]
							: []
					).data
				}
				axis={{
					primaryX: {
						dim: 0,
						tickValues: 5,
						tickRotation: -45,
						legend: "",
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: 22,
					},
					primaryY: {
						tickSize: 0,
						tickValues: 5,
						tickRotation: 0,
						legend: props.data[1].yAxisLabel[1],
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: -40,
						// minValue: 0,
						// maxValue: findMaxValue(
						// 	transformBarChartData(
						// 		props.data[1].chartData.length > 0
						// 			? props.data[1].chartData[1]
						// 			: [],
						// 		props.data[1].chartData.length > 0
						// 			? props.data[1].chartColor[1]
						// 			: []
						// 	).data
						// ),
					},
				}}
				tooltip={{
					isInteractive: true,
					format: "stack_percent",
				}}
				legend={{
					position: ReportTypesComponentInterface.legendPosition.top,
					direction:
						ReportTypesComponentInterface.legendDirection.row,
					symbolSize: 15,
					translateX: -2,
					itemWidth: 85,
					translateY: -40,
					symbolShape:
						ReportTypesComponentInterface.legendSymbolShape.circle,
				}}
				chartConfig={{
					cartesian: {
						line: {
							pointSize: 20,
						},
						// groupMode: groupMode.grouped,
						grid: {
							enableGridX: false,
							enableGridY: true,
							gridYValues: 5,
							gridXValues: 5,
						},
						gaps: { barGap: 0.69, groupGap: 0 },
					},
				}}
				style={{
					margin: {
						top: 45,
						right: 10,
						bottom: 65,
						left: 48,
					},
				}}
			/>,
		],
		[
			<ReportTypes
				header={props.data[1].chartName[2]}
				hideShadow
				minHeight="30px"
				height="43vh"
				hideRibbon={false}
				ribbonData={
					<div className="flex justify-between">
						<div className="mr-5">
							<span>Patient time of Therapy:</span>
						</div>
						<div>
							<Checkbox
								className="reach-check"
								checked={filterValues.includes("0 Days")}
								onChange={(e) => {
									filterArray(e.target.checked, "0 Days");
								}}
							/>
							<span className="ml-2">0 Days</span>
						</div>
						<div className="ml-3">
							<Checkbox
								className="reach-check"
								checked={filterValues.includes("30 Days")}
								onChange={(e) => {
									filterArray(e.target.checked, "30 Days");
								}}
							/>
							<span className="ml-2">30 Days</span>
						</div>
						<div className="ml-3">
							<Checkbox
								className="reach-check"
								checked={filterValues.includes("60 Days")}
								onChange={(e) => {
									filterArray(e.target.checked, "60 Days");
								}}
							/>
							<span className="ml-2">60 Days</span>
						</div>
						<div className="ml-3">
							<Checkbox
								className="reach-check"
								checked={filterValues.includes("90 Days")}
								onChange={(e) => {
									filterArray(e.target.checked, "90 Days");
								}}
							/>
							<span className="ml-2">90 Days</span>
						</div>
						<div className="ml-3">
							<Checkbox
								className="reach-check"
								checked={filterValues.includes("180 Days")}
								onChange={(e) => {
									filterArray(e.target.checked, "180 Days");
								}}
							/>
							<span className="ml-2">180 Days</span>
						</div>
						<div className="ml-3">
							<Checkbox
								className="reach-check"
								checked={filterValues.includes(">180 Days")}
								onChange={(e) => {
									filterArray(e.target.checked, ">180 Days");
								}}
							/>
							<span className="ml-2">&gt;180 Days</span>
						</div>
					</div>
				}
				// height="320px"
				bodyPadding="0px 20px"
				title={{ title: "xx" }}
				chartType={ReportTypesComponentInterface.chartType.bar}
				series={
					transformBarChartData(
						props.data[1].chartData.length > 0 ? filteredChart : [],
						props.data[1].chartData.length > 0
							? props.data[1].chartColor[2]
							: []
					).series
				}
				tooltip={{
					isInteractive: true,
					format: "stack",
				}}
				data={
					transformBarChartData(
						props.data[1].chartData.length ? filteredChart : [],
						props.data[1].chartData.length > 0
							? props.data[1].chartColor[2]
							: []
					).data
				}
				axis={{
					primaryX: {
						dim: 0,
						tickValues: 5,
						tickRotation: 0,
						legend: "",
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: 22,
					},
					primaryY: {
						tickSize: 0,
						tickValues: 5,
						tickRotation: 0,
						legend: props.data[1].yAxisLabel[2],
						legendPosition:
							ReportTypesComponentInterface.axisLegendPosition
								.middle,
						legendOffset: -40,
						minValue: 0,
						maxValue: findMaxGroupValue(
							transformBarChartData(
								props.data[1].chartData.length
									? filteredChart
									: [],
								props.data[1].chartData.length > 0
									? props.data[1].chartColor[2]
									: []
							).data
						),
					},
				}}
				legend={{
					position: ReportTypesComponentInterface.legendPosition.top,
					direction:
						ReportTypesComponentInterface.legendDirection.row,
					symbolSize: 15,
					translateX: -2,
					translateY: -37,
					symbolShape:
						ReportTypesComponentInterface.legendSymbolShape.circle,
				}}
				chartConfig={{
					cartesian: {
						line: {
							pointSize: 20,
						},
						groupMode: groupMode.grouped,
						grid: {
							enableGridX: false,
							enableGridY: true,
							gridYValues: 5,
							gridXValues: 5,
						},
						gaps: { barGap: 0.4, groupGap: 0 },
					},
				}}
				style={{
					margin: {
						top: 45,
						right: 10,
						bottom: 65,
						left: 48,
					},
				}}
			/>,
		],
	];
	var card_2 = {
		header: props.data[1].cardName + headerTime + " Patients",
		headerExtra: (
			<div>
				<p>Time on Therapy</p>
				<Select
					className="w-44 mt-3 h-9"
					// defaultValue="30 Days"
					value={filterValues[0]}
					onChange={(value: string) => {
						console.log(value);
						var filterData = [value];
						console.log(filterData);
						setFilterValues(filterData);
						setHeaderTime(value);
						filterChartData(
							props.data[1].chartData.length
								? props.data[1].chartData[2]
								: [],
							filterData
						);
						handleTotFilter(value);
					}}
				>
					<Select.Option value="0 Days">0 Days</Select.Option>
					<Select.Option value="30 Days">30 Days</Select.Option>
					<Select.Option value="60 Days">60 Days</Select.Option>
					<Select.Option value="90 Days">90 Days</Select.Option>
					<Select.Option value="180 Days">180 Days</Select.Option>
					<Select.Option value=">180 Days">
						&gt;180 Days
					</Select.Option>
				</Select>
			</div>
		),
		cardTopData: (
			<div className="flex items-end mx-7 my-4">
				<NumberData
					dataNumber={props.data[1].value[0]}
					dataName={props.data[1].month[0]}
				/>
				<ArrowComparison
					dataNumber={props.data[1].vsPrevMonth}
					dataName={
						props.data[1].timeframeDisplay === "Quarter"
							? "vs Prev. Quarter"
							: "vs Prev. Month"
					}
				/>
			</div>
		),
		iconName: "Bar Graph",
		cardExtra: true,
		cardExtraData: (
			<div className="mx-4 py-2 text-text text-base font-medium border-b">
				<span
					className={
						indexChart === 0
							? "tab-active hover:text-text-teal mr-5 cursor-pointer"
							: "hover:text-text-teal mr-5 cursor-pointer"
					}
					onClick={() => {
						setIndexChart(0);
					}}
				>
					Call Volume and Reach
				</span>
				<span
					className={
						indexChart === 1
							? "tab-active hover:text-text-teal mr-5 cursor-pointer"
							: "hover:text-text-teal cursor-pointer"
					}
					onClick={() => {
						setIndexChart(1);
					}}
				>
					Call Volume by Time on Therapy
				</span>
			</div>
		),
		chartElement: charts[indexChart],
	};
	return (
		<div className="grid xl:grid-cols-3 lg:grid-cols-1 gap-4">
			{data.map((card) => (
				<POCard {...card} />
			))}
			{!totLoading ? (
				<div className="col-span-2">
					<POCard {...card_2} />
				</div>
			) : (
				<div className="col-span-2">
					<SkeletonCard />
				</div>
			)}
		</div>
	);
}

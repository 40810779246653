import React from "react";
import CardHeaderCircle from "../../../../Components/CardHeaderCircle";
import NoChartData from "../../../../Components/NoChartData";
import { UnmetPatientCard } from "../../../../Components/ReportCards/CommonCardComponents/UnmetPatientCard";

// const DUMMY_DATA2: {
// 	unmetNeedsAndQuestionTime: {
// 		cardName: string;
// 		month: string[];
// 		value: number[];
// 		unit: string[];
// 		vsPrevMonth: number[];
// 		topChart: {};
// 		chartName: string[];
// 		xAxisLabel: string[];
// 		yAxisLabel: string[];
// 		footer: string;
// 		chartColor: string[];
// 		tableLinks: string[];
// 		chartAccessor: string[];
// 		chartData:
// 			| {
// 					"Feb 21": {
// 						"Series 1": number;
// 					};
// 					"Mar 21": {
// 						"Series 1": number;
// 					};
// 					"Apr 21": {
// 						"Series 1": number;
// 					};
// 					"May 21": {
// 						"Series 1": number;
// 					};
// 					"Jun 21": {
// 						"Series 1": number;
// 					};
// 					"Jul 21": {
// 						"Series 1": number;
// 					};
// 					"Aug 21": {
// 						"Series 1": number;
// 					};
// 					"Sep 21": {
// 						"Series 1": number;
// 					};
// 					"Oct 21": {
// 						"Series 1": number;
// 					};
// 					"Nov 21": {
// 						"Series 1": number;
// 					};
// 			  }[]
// 			| {
// 					"30": {
// 						[id: string]: string;
// 					};
// 					"60": {
// 						[id: string]: string;
// 					};
// 					"90": {
// 						[id: string]: string;
// 					};
// 					"180": {
// 						[id: string]: string;
// 					};
// 					"180+": {
// 						[id: string]: string;
// 					};
// 			  }[];
// 	}[];
// } = {
// 	unmetNeedsAndQuestionTime: [
// 		{
// 			cardName: "",
// 			month: [],
// 			value: [],
// 			unit: [],
// 			vsPrevMonth: [],
// 			topChart: {},
// 			chartName: ["Top 5 Unmet Needs"],
// 			xAxisLabel: [],
// 			yAxisLabel: [],
// 			footer: "",
// 			chartColor: [],
// 			tableLinks: [],
// 			chartAccessor: [],
// 			chartData: [
// 				{
// 					"30": {
// 						Access: "78%",
// 						"Side Effects": "64%",
// 						"COVID-19": "31%",
// 						"Dosing Instructions": "27%",
// 						"Dietary Guidance": "12%",
// 					},
// 					"60": {
// 						"Dietary Restriction": "61%",
// 						"Dosing Instructions": "59%",
// 						Access: "44%",
// 						"Vumerity App": "32%",
// 						"Side Effects": "27%",
// 					},
// 					"90": {
// 						"Side Effects": "45%",
// 						"COVID-19": "43%",
// 						Access: "29%",
// 						"Dosing Instructions": "22%",
// 						"Pill Box": "19%",
// 					},
// 					"180": {
// 						"Pill Box": "51%",
// 						Access: "38%",
// 						"Dosing Instructions": "37%",
// 						"COVID-19": "34%",
// 						"Side Effects": "16%",
// 					},
// 					"180+": {
// 						"COVID-19": "45%",
// 						Access: "42%",
// 						"Medication Expiration": "36%",
// 						"Side Effects": "32%",
// 						Billing: "27%",
// 					},
// 				},
// 			],
// 		},
// 		{
// 			cardName: "",
// 			month: [],
// 			value: [42, 12, 13, 41, 11],
// 			unit: ["secs", "secs", "secs", "secs", "secs"],
// 			vsPrevMonth: [],
// 			topChart: {},
// 			chartName: ["Average Time Spent in Asking Questions"],
// 			xAxisLabel: [],
// 			yAxisLabel: [],
// 			footer: "",
// 			chartColor: [],
// 			tableLinks: [],
// 			chartAccessor: [],
// 			chartData: [
// 				{
// 					"Feb 21": {
// 						"Series 1": 45,
// 					},
// 					"Mar 21": {
// 						"Series 1": 50,
// 					},
// 					"Apr 21": {
// 						"Series 1": 52,
// 					},
// 					"May 21": {
// 						"Series 1": 37,
// 					},
// 					"Jun 21": {
// 						"Series 1": 85,
// 					},
// 					"Jul 21": {
// 						"Series 1": 63,
// 					},
// 					"Aug 21": {
// 						"Series 1": 70,
// 					},
// 					"Sep 21": {
// 						"Series 1": 65,
// 					},
// 					"Oct 21": {
// 						"Series 1": 68,
// 					},
// 					"Nov 21": {
// 						"Series 1": 72,
// 					},
// 				},
// 				{
// 					"Feb 21": {
// 						"Series 1": 45,
// 					},
// 					"Mar 21": {
// 						"Series 1": 50,
// 					},
// 					"Apr 21": {
// 						"Series 1": 52,
// 					},
// 					"May 21": {
// 						"Series 1": 37,
// 					},
// 					"Jun 21": {
// 						"Series 1": 85,
// 					},
// 					"Jul 21": {
// 						"Series 1": 63,
// 					},
// 					"Aug 21": {
// 						"Series 1": 70,
// 					},
// 					"Sep 21": {
// 						"Series 1": 65,
// 					},
// 					"Oct 21": {
// 						"Series 1": 68,
// 					},
// 					"Nov 21": {
// 						"Series 1": 72,
// 					},
// 				},
// 				{
// 					"Feb 21": {
// 						"Series 1": 45,
// 					},
// 					"Mar 21": {
// 						"Series 1": 50,
// 					},
// 					"Apr 21": {
// 						"Series 1": 52,
// 					},
// 					"May 21": {
// 						"Series 1": 37,
// 					},
// 					"Jun 21": {
// 						"Series 1": 85,
// 					},
// 					"Jul 21": {
// 						"Series 1": 63,
// 					},
// 					"Aug 21": {
// 						"Series 1": 70,
// 					},
// 					"Sep 21": {
// 						"Series 1": 65,
// 					},
// 					"Oct 21": {
// 						"Series 1": 68,
// 					},
// 					"Nov 21": {
// 						"Series 1": 72,
// 					},
// 				},
// 				{
// 					"Feb 21": {
// 						"Series 1": 45,
// 					},
// 					"Mar 21": {
// 						"Series 1": 50,
// 					},
// 					"Apr 21": {
// 						"Series 1": 52,
// 					},
// 					"May 21": {
// 						"Series 1": 37,
// 					},
// 					"Jun 21": {
// 						"Series 1": 85,
// 					},
// 					"Jul 21": {
// 						"Series 1": 63,
// 					},
// 					"Aug 21": {
// 						"Series 1": 70,
// 					},
// 					"Sep 21": {
// 						"Series 1": 65,
// 					},
// 					"Oct 21": {
// 						"Series 1": 68,
// 					},
// 					"Nov 21": {
// 						"Series 1": 72,
// 					},
// 				},
// 				{
// 					"Feb 21": {
// 						"Series 1": 45,
// 					},
// 					"Mar 21": {
// 						"Series 1": 50,
// 					},
// 					"Apr 21": {
// 						"Series 1": 52,
// 					},
// 					"May 21": {
// 						"Series 1": 37,
// 					},
// 					"Jun 21": {
// 						"Series 1": 85,
// 					},
// 					"Jul 21": {
// 						"Series 1": 63,
// 					},
// 					"Aug 21": {
// 						"Series 1": 70,
// 					},
// 					"Sep 21": {
// 						"Series 1": 65,
// 					},
// 					"Oct 21": {
// 						"Series 1": 68,
// 					},
// 					"Nov 21": {
// 						"Series 1": 72,
// 					},
// 				},
// 			],
// 		},
// 	],
// };

function CardContent(props: {
	data: { [id: string]: string };
	header: string;
}) {
	return (
		<div className="p-4 py-8 ">
			{props.header ? (
				<div className="text-text-gray-90 text-lg font-bold">
					{props.header}
				</div>
			) : (
				<div className="py-3.5"></div>
			)}
			<div className="px-2">
				{Object.keys(props.data).length === 0 ? (
					<div className="h-48">
						<NoChartData />
					</div>
				) : (
					Object.entries(props.data).map(([key, value], index) => {
						return (
							<div className="flex items-center justify-between text-text-gray-90 text-base py-2 border-b font-medium">
								<div>{`${index + 1}. ${key}`}</div>
								<div>
									{Math.round(parseFloat(value))}%
								</div>
							</div>
						);
					})
				)}
			</div>
		</div>
	);
}
export default function UnmetNeedsAndQuestionTime(props: { data: any }) {
	const [fetchedData, setFetchedData] = React.useState<
		{
			cardName: string;
			month: string[];
			value: number[];
			unit: string[];
			vsPrevMonth: number[];
			topChart: {};
			chartName: string[];
			xAxisLabel: string[];
			yAxisLabel: string[];
			footer: string;
			chartColor: string[];
			tableLinks: string[];
			chartAccessor: string[];
			chartData:
				| {
						"Feb 21": {
							"Series 1": number;
						};
						"Mar 21": {
							"Series 1": number;
						};
						"Apr 21": {
							"Series 1": number;
						};
						"May 21": {
							"Series 1": number;
						};
						"Jun 21": {
							"Series 1": number;
						};
						"Jul 21": {
							"Series 1": number;
						};
						"Aug 21": {
							"Series 1": number;
						};
						"Sep 21": {
							"Series 1": number;
						};
						"Oct 21": {
							"Series 1": number;
						};
						"Nov 21": {
							"Series 1": number;
						};
				  }[]
				| {
						"30": {
							[id: string]: string;
						};
						"60": {
							[id: string]: string;
						};
						"90": {
							[id: string]: string;
						};
						"180": {
							[id: string]: string;
						};
						"180+": {
							[id: string]: string;
						};
				  }[];
		}[]
	>([]);
	const [loading, setLoading] = React.useState(true);
	React.useEffect(() => {
		setFetchedData(props.data);
		setLoading(false);
	}, []);
	return (
		<>
			{!loading ? (
				<div>
					<div className="flex">
						{/* {console.log("CHART DATA", fetchedData[0])} */}
						{fetchedData[0].chartData[0] &&
							Object.entries(fetchedData[0].chartData[0]).map(
								([key, value], index) => {
									return (
										<div className="w-1/5">
											<CardHeaderCircle
												value={key}
												header={
													index === 0 ? true : false
												}
											/>
											<div className="border-r h-auto">
												<CardContent
													data={value}
													header={
														index === 0
															? "Top 5 Unmet Needs"
															: ""
													}
												/>
											</div>
										</div>
									);
								}
							)}
					</div>
					<UnmetPatientCard data={fetchedData[1]} />
				</div>
			) : (
				<></>
			)}
		</>
	);
}

import { useEffect, useRef, useState } from "react";
import { Checkbox, Switch } from "antd";
import * as ExploreServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/explore";

import {
	scaleLinear,
	select,
	forceSimulation,
	forceCenter,
	forceX,
	forceY,
	forceManyBody,
	forceLink,
	drag,
	zoom,
	zoomIdentity,
	ZoomTransform,
	D3ZoomEvent,
	forceCollide,
} from "d3";

type ChildNode = {
	id: string;
	name: string;
	occurences: number;
	size: number;
	color: number;
	parentRadius: number;
	parentRingRadius: number;
	posX: number;
	posY: number;
	radius: number;
	keywordLine: Path2D;
	keywordRect: Path2D;
	angle: number;
	textAlign?: CanvasTextAlign;
	textCords: [number, number];
};

type Node = {
	topic: string;
	id: string;
	name: string;
	size: number;
	color: number;
	radius: number;
	ringRadius: number;
	x: number;
	y: number;
	children: ChildNode[];
	conversations?: number;
	selected: boolean;
	wrappedText: { text: string; dy: number }[];
};

type Links = {
	source: string;
	target: string;
};

type NodeGraphJson = {
	nodes: Node[];
	link: Links[];
};

type ChartObj = {
	resize: Function;
	toggleLinks: Function;
	toggleKeywords: Function;
	selectNodes: Function;
	handleParentEdit: Function;
};

type ChartConfig = {
	showLinks: boolean;
	showKeywords: boolean;
	chartHeight: number;
	chartWidth: number;
	chartRef: ChartObj | null;
};

type NodeGraphCanvas = {
	data: NodeGraphJson | null;
	onEdit: React.Dispatch<React.SetStateAction<any>>;
	onSelect: React.Dispatch<React.SetStateAction<any>>;
	updatedNode: Node | null;
	nodeData?: any;
	//filteredData: any;
};

export var RESPONSE: any = {
	data: {
		nodes: [],
		link: [],
	},
};

// export const DUMMYRESPONSE: any = {

//   data: {
//     nodes: [
//       {
//         topicId: 0,
//         topic: "denied claim lectrazine",
//         keywordCount: 1,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 1,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "lectrazine",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 1,
//         topic: "feeling really anxious",
//         keywordCount: 4,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 1,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "get",
//             occurences: 4,
//           },
//           {
//             name: "call",
//             occurences: 4,
//           },
//           {
//             name: "feel",
//             occurences: 4,
//           },
//           {
//             name: "time",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 2,
//         topic: "worried effects lectrazine",
//         keywordCount: 5,
//         conversations: 7,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0.7142857142857143,
//           neutral: 0.2857142857142857,
//         },
//         keywords: [
//           {
//             name: "lectrazine",
//             occurences: 6,
//           },
//           {
//             name: "effect",
//             occurences: 5,
//           },
//           {
//             name: "side",
//             occurences: 4,
//           },
//           {
//             name: "worried",
//             occurences: 4,
//           },
//           {
//             name: "cause",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 3,
//         topic: "covered medication",
//         keywordCount: 1,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0.3333333333333333,
//           negative: 0.6666666666666666,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "medication",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 4,
//         topic: "feeling okay little",
//         keywordCount: 4,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0.6666666666666666,
//           negative: 0.3333333333333333,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "little",
//             occurences: 2,
//           },
//           {
//             name: "though",
//             occurences: 2,
//           },
//           {
//             name: "feel",
//             occurences: 2,
//           },
//           {
//             name: "lot",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 5,
//         topic: "kind beverages lectrazine",
//         keywordCount: 5,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0,
//           neutral: 1,
//         },
//         keywords: [
//           {
//             name: "medication",
//             occurences: 3,
//           },
//           {
//             name: "kind",
//             occurences: 3,
//           },
//           {
//             name: "wonder",
//             occurences: 3,
//           },
//           {
//             name: "beverage",
//             occurences: 2,
//           },
//           {
//             name: "feel",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 6,
//         topic: "swallow water just",
//         keywordCount: 1,
//         conversations: 1,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0,
//           neutral: 1,
//         },
//         keywords: [
//           {
//             name: "water",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 7,
//         topic: "medication feeling okay",
//         keywordCount: 2,
//         conversations: 5,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0.8333333333333334,
//           neutral: 0.16666666666666666,
//         },
//         keywords: [
//           {
//             name: "medication",
//             occurences: 5,
//           },
//           {
//             name: "worried",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 8,
//         topic: "good lectrazine pills",
//         keywordCount: 2,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 1,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "lectrazine",
//             occurences: 3,
//           },
//           {
//             name: "pill",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 9,
//         topic: "try thanks advice",
//         keywordCount: 2,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 1,
//           negative: 0,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "much",
//             occurences: 2,
//           },
//           {
//             name: "help",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 10,
//         topic: "thank help okay",
//         keywordCount: 2,
//         conversations: 2,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 1,
//           negative: 0,
//           neutral: 0,
//         },
//         keywords: [
//           {
//             name: "alright",
//             occurences: 2,
//           },
//           {
//             name: "help",
//             occurences: 2,
//           },
//         ],
//       },
//       {
//         topicId: 11,
//         topic: "possible effects medication",
//         keywordCount: 2,
//         conversations: 3,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0.25,
//           neutral: 0.75,
//         },
//         keywords: [
//           {
//             name: "effect",
//             occurences: 3,
//           },
//           {
//             name: "side",
//             occurences: 3,
//           },
//         ],
//       },
//       {
//         topicId: 12,
//         topic: "pill day yes",
//         keywordCount: 5,
//         conversations: 2,
//         speakerTypes: ["patient"],
//         descriptionType: "All Documents",
//         date: "2022-12-23",
//         sentiments: {
//           positive: 0,
//           negative: 0,
//           neutral: 1,
//         },
//         keywords: [
//           {
//             name: "take",
//             occurences: 2,
//           },
//           {
//             name: "every",
//             occurences: 2,
//           },
//           {
//             name: "one",
//             occurences: 2,
//           },
//           {
//             name: "day",
//             occurences: 2,
//           },
//           {
//             name: "pill",
//             occurences: 2,
//           },
//         ],
//       },
//     ],
//     link: [
//       {
//         sourceTopicId: 0,
//         targetTopicId: 1,
//         weight: 2, // TBD
//       },
//       {
//         sourceTopicId: 2,
//         targetTopicId: 3,
//         weight: 2, // TBD
//       },
//       {
//         sourceTopicId: 3,
//         targetTopicId: 6,
//         weight: 2, // TBD
//       },
//       {
//         sourceTopicId: 4,
//         targetTopicId: 8,
//         weight: 2, // TBD
//       },
//     ],
//   },
// };

export default function NodeGraphCanvas(
	props: NodeGraphCanvas,
	nodeData?: any
) {
	// const RESPONSE=DUMMYRESPONSE;

	const [selection, setSelection] = useState<any>([]);
	const [options, setOptions] = useState<any>([]);
	const [newData, setFetchedData] = useState<any>([]);
	const [response, setResponse] = useState(RESPONSE);

	// async function fetchDataForPage(filters?: any) {

	//   let fetchedData: any;
	//   if (filters == undefined) {
	//     fetchedData = await ExploreServiceHelper.getExploreCardsData(
	//       1,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null
	//     );
	//   } else {
	//     console.log(filters);
	//     fetchedData = await ExploreServiceHelper.getExploreCardsData(
	//       1,
	//       filters.startDate !== "" ? null : filters.startDate,
	//       filters.endDate !== "" ? null : filters.endDate,
	//       filters.speaker !== undefined ? filters.speaker : null,
	//       filters.topics[0] !== undefined ? filters.topics[0] : null,
	//       filters.totStart !== undefined ? filters.totStart : null,
	//       filters.totEnd !== undefined ? filters.totEnd : null,
	//       filters.callTypeIds[0] !== undefined ? filters.callTypeIds[0] : null,
	//       filters.therapyStatusIds[0] !== undefined ? filters.therapyStatusIds[0] : null,
	//       filters.sentiment[0] !== undefined ? filters.sentiment[0] : null,
	//       filters.emotions.length > 0 ? filters.emotions : null
	//     );
	//   }

	//     // if (fetchedData.data.nodes.length!==0) {
	//       // setFetchedData(fetchedData);
	//       setResponse(fetchedData);
	//       console.log("CHECK HERE", fetchedData);
	//     // }
	//     // else
	//     // {
	//     //   setResponse(DUMMYRESPONSE);
	//     //   console.log("CHECK HERE", DUMMYRESPONSE);
	//     // }

	//   }

	//   const setTranscriptFilters = (filter: any) => {
	//   console.log("Filter", filter);
	//   var transcriptFilters: searchQuery = searchTranscriptQuery;
	//   const callType = findId("Call_Type", filter.Call_Type);
	//   transcriptFilters.callTypeIds = callType.grpIdx;

	//   const therapyStatus = findId("Therapy_Status", filter.Therapy_Status);
	//   transcriptFilters.therapyStatusIds = therapyStatus.grpIdx;

	//   const sentiment = findId("Sentiment", filter.Sentiment);
	//   transcriptFilters.sentiment = sentiment.grpIdx;

	//   const topics = findId("Topics", filter.Topics);
	//   transcriptFilters.topics = topics.grpIdx;

	//   const emotions = findId("Emotions", filter.Emotions);
	//   transcriptFilters.emotionsGroup = emotions.grpIdx;
	//   transcriptFilters.emotions = emotions.idx;

	//   transcriptFilters.totStart = filter.totStart;
	//   transcriptFilters.totEnd = filter.totEnd;

	//   console.log("TranscriptFilter", transcriptFilters);
	//   setFilters(filter);
	//   fetchDataForPage(transcriptFilters);
	// };

	// const RESPONSE=data;

	useEffect(() => {
		// fetchDataForPage();
		setResponse(props.nodeData);
		// console.log(props.nodeData); Commenting
	}, [props.nodeData]);

	const graphRef = useRef(null);
	const lineHeight = 12;

	const [config] = useState<ChartConfig>({
		showLinks: true,
		showKeywords: true,
		chartHeight: 0,
		chartWidth: 0,
		chartRef: null,
	});

	const resizeCanvas = (timeout = 2000) => {
		let timer: NodeJS.Timeout;
		return () => {
			clearTimeout(timer);
			timer = setTimeout(() => {
				config.chartRef?.resize();
			}, timeout);
		};
	};

	useEffect(() => {
		craeteJson();
		select(graphRef.current).selectAll("*").remove();
		config.chartRef = renderChart(json);
		const resize = resizeCanvas();
		window.addEventListener("resize", () => resize());
		return () => {
			window.removeEventListener("resize", () => {});
		};
	}, [response]);

	const renderChart = (data: NodeGraphJson) => {
		const graphCanvas: HTMLCanvasElement = select(
			graphRef.current as unknown as HTMLCanvasElement
		).node() as HTMLCanvasElement;
		const ctx: CanvasRenderingContext2D = graphCanvas.getContext(
			"2d"
		) as unknown as CanvasRenderingContext2D;
		const simulation = forceSimulation();
		const dpr = 1;
		const bsr = 1;
		const PIXEL_RATIO = dpr / bsr;
		let transform: ZoomTransform = zoomIdentity;
		let hoverNode: null = null;
		let refData: NodeGraphJson = {
			link: [],
			nodes: [],
		};

		const createHiDPICanvas = (
			can: HTMLCanvasElement,
			w: number,
			h: number,
			ratio: number
		) => {
			if (!ratio) {
				ratio = PIXEL_RATIO;
			}
			can.width = w * ratio;
			can.height = h * ratio;
			can.style.width = w + "px";
			can.style.height = h + "px";
			ctx.setTransform(ratio, 0, 0, ratio, 0, 0);
			return can;
		};

		const getGradient = (index: number) => {
			return [
				[
					// Green
					"#D7FFAC",
					"#B9F27D",
					"#A4D273",
				],
				[
					// Blue
					"#ACFAFF",
					"#7DF2EB",
					"#73C1D2",
				],
				[
					// Yellow
					"#F3FFAC",
					"#F0F27D",
					"#C5D273",
				],
			][index];
		};

		function renderNode(
			d: {
				color: number;
				x: number;
				y: number;
				ringRadius: number;
				radius: number;
				wrappedText: any[];
				children: any[];
			},
			i: any
		) {
			const colors = getGradient(d.color);

			// draw background circle
			ctx.lineWidth = 1;
			ctx.beginPath();
			ctx.setLineDash([5, 5]);
			ctx.arc(d.x, d.y, d.ringRadius, 0, 2 * Math.PI, true);
			ctx.fillStyle = "#FFFFFF";
			ctx.strokeStyle = "#00000099";
			ctx.fill();
			ctx.stroke();

			// create shadow for main node
			ctx.beginPath();
			ctx.shadowBlur = 12;
			ctx.shadowOffsetX = 1;
			ctx.shadowOffsetY = 1;
			ctx.shadowColor = "rgba(0, 0, 0, 0.2)";

			// create gradient for main node
			const clusterNodeGradient = ctx.createRadialGradient(
				d.x,
				+d.y,
				0,
				+d.x,
				+d.y,
				d.radius
			);
			clusterNodeGradient.addColorStop(0, colors[0]);
			clusterNodeGradient.addColorStop(0.68, colors[1]);
			clusterNodeGradient.addColorStop(1, colors[2]);

			// create main node
			ctx.arc(d.x, d.y, d.radius, 0, 2 * Math.PI, true);
			ctx.fillStyle = clusterNodeGradient;
			ctx.fill();
			ctx.fillStyle = "#000";
			ctx.textAlign = "center";

			// ctx.fillText(d.name, d.x, d.y);

			d.wrappedText.forEach((text) => {
				ctx.fillText(text.text, d.x, d.y + text.dy);
			});

			d.children.forEach((child) => {
				const x = d.x + child.posX;
				const y = d.y + child.posY;
				const r = child.radius;

				if (config.showKeywords) {
					ctx.shadowBlur = 0;
					ctx.shadowOffsetX = 0;
					ctx.shadowOffsetY = 0;

					// add keyword lines
					ctx.setLineDash([2, 2]);
					ctx.strokeStyle = "#1B2E3E";
					ctx.lineWidth = 0.5;

					const p2 = new Path2D();
					p2.addPath(child.keywordLine, { e: x, f: y });
					ctx.stroke(p2);

					// add keyword wrapper
					ctx.setLineDash([]);
					ctx.strokeStyle = "#000";
					ctx.fillStyle = "#FFF";
					const rect = new Path2D();
					rect.addPath(child.keywordRect, { e: x, f: y });
					ctx.fill(rect);
					ctx.stroke(rect);

					// add keyword text
					ctx.fillStyle = "#000";
					ctx.textAlign = child.textAlign || "center";
					ctx.fillText(
						child.name,
						x + child.textCords[0],
						y + child.textCords[1]
					);
				}
				// create shadow for child node
				ctx.shadowOffsetX = 1;
				ctx.shadowOffsetY = 1;
				ctx.shadowBlur = 14;
				ctx.shadowColor = "rgba(255, 255, 255, 0.5)";

				// create gradient for child node
				const keywordNodeGradient = ctx.createRadialGradient(
					x,
					y,
					0,
					x,
					y,
					r
				);
				keywordNodeGradient.addColorStop(0, colors[0]);
				keywordNodeGradient.addColorStop(0.4, colors[1]);
				keywordNodeGradient.addColorStop(1, colors[2]);

				// create child node
				ctx.strokeStyle = "#00000026";
				ctx.lineWidth = 1;
				ctx.beginPath();
				ctx.arc(x, y, r, 0, 2 * Math.PI, true);
				ctx.fillStyle = keywordNodeGradient;
				ctx.stroke();
				ctx.fill();
			});
		}

		function simulationUpdate(tempData: NodeGraphJson = data) {
			simulation.nodes();
			if (config.showLinks) {
				tempData.link.forEach(function (d) {
					const s = d.source as unknown as any;
					const t = d.target as unknown as any;
					ctx.beginPath();
					ctx.moveTo(s.x, s.y);
					ctx.lineTo(t.x, t.y);
					ctx.stroke();
				});
			}
			tempData.nodes.filter((node) => !node.selected).forEach(renderNode);
			ctx.fillStyle = "#FFFFFF";
			ctx.globalAlpha = 0.6;
			ctx.fillRect(
				-transform.x / transform.k,
				-transform.y / transform.k,
				config.chartWidth / transform.k,
				config.chartHeight / transform.k
			);
			ctx.globalAlpha = 1.0;
			tempData.nodes.filter((node) => node.selected).forEach(renderNode);
		}

		function updateGraph(tempData: NodeGraphJson) {
			refData = tempData;
			const nodeBound = tempData.nodes.length * 50;
			simulation
				.force(
					"center",
					forceCenter(config.chartWidth / 2, config.chartHeight / 2)
				)
				.force(
					"x",
					forceX()
						.x((d: any) => getRandomInt(nodeBound))
						.strength(0.2)
				)
				.force(
					"y",
					forceY()
						.y((d: any) => getRandomInt(nodeBound))
						.strength(0.25)
				)
				.force("charge", forceManyBody().strength(-50))
				.force(
					"link",
					forceLink()
						.strength(1)
						.id((d: any) => d.id)
				)
				.force(
					"collide",
					forceCollide((d: any) => d.ringRadius * 2)
				);

			select(graphRef.current as any)
				.call(
					drag()
						.subject(dragsubject)
						.on("start", dragstarted)
						.on("drag", dragged)
						.on("end", dragended) as any
				)
				.call(
					zoom()
						.scaleExtent([1 / 10, 8])
						.on("zoom", zoomed)
				);

			select(graphRef.current as any)
				.on("mousemove", (e: any) => nodeEvent(e, "hover"))
				// .on("mouseleave", (e) => nodeEvent(e, "hover"))
				.on("click", (e: any) => nodeEvent(e, "click"));

			let tooltipTimeout: any;

			const nodeEvent = (
				e: MouseEvent,
				type: "click" | "hover" | "node"
			) => {
				const box = graphCanvas.getBoundingClientRect();
				const ex = e.x - box.left;
				const ey = e.y - box.top;
				const cords = transform.invert([ex, ey]);
				for (let i = tempData.nodes.length - 1; i >= 0; --i) {
					const node: any = tempData.nodes[i];
					if (!node.selected) continue;
					const dx = Math.pow(cords[0] - node.x, 2);
					const dy = Math.pow(cords[1] - node.y, 2);
					const r = Math.pow(node.ringRadius, 2);
					if (dx + dy < r) {
						if (type == "hover") {
							if (hoverNode !== node) {
								hoverNode = node;
								getTooltip(node);
							}
						} else if (type == "click") {
							onNodeClick(node);
						}
						return node;
					}
				}
				hoverNode = null;
				hideToolTip();
			};

			function zoomed(
				e: D3ZoomEvent<HTMLCanvasElement, unknown> | ZoomTransform | any
			) {
				transform = e.transform || e;
				refreshUI();
			}

			function dragsubject(event: any, d: any) {
				const x = transform.invertX(event.x);
				const y = transform.invertY(event.y);
				for (let i = tempData.nodes.length - 1; i >= 0; --i) {
					const node: any = tempData.nodes[i];
					const dx = Math.pow(x - node.x, 2);
					const dy = Math.pow(y - node.y, 2);
					const r = Math.pow(node.radius, 2);
					if (dx + dy < r) {
						if (!node.selected) break;
						node.x = transform.applyX(node.x);
						node.y = transform.applyY(node.y);
						return node;
					}
				}
			}

			function dragstarted(event: any, d: any) {
				if (!event.active) simulation.alphaTarget(0.1).restart();
				event.subject.x = transform.invertX(event.x);
				event.subject.y = transform.invertY(event.y);
			}
			function dragged(event: any, d: any) {
				event.subject.x = transform.invertX(event.x);
				event.subject.y = transform.invertY(event.y);
			}
			function dragended(event: any, d: any) {
				if (!event.active) simulation.alphaTarget(0);
			}

			const getImg = (id: string, icon: string) =>
				`<img id="${id}" src="/icons/${icon}.svg" alt="${icon}"/>`;

			function getTooltip(d: any) {
				let tooltip = `
          <div class="node-tooltip">
            <ul class="list-unstyled">
              <li id="viewSection" class="flex align-center">
                <span class="mr-2">Topic</span>
                <strong>${d.topic}</strong>
                <svg id="edit"  class="align-center" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style="width:20px; height:12px;">
                <path d="M6.525 3.00055L8.9995 5.47563L3.2245 11.25H0.75V8.77497L6.525 2.99997V3.00055ZM7.34983 2.17572L8.58708 0.937882C8.69647 0.828524 8.84482 0.76709 8.9995 0.76709C9.15418 0.76709 9.30253 0.828524 9.41192 0.937882L11.0622 2.58813C11.1715 2.69752 11.233 2.84587 11.233 3.00055C11.233 3.15523 11.1715 3.30357 11.0622 3.41297L9.82433 4.65022L7.34983 2.17572Z" fill="white"/>
                </svg>
              </li>
              <li id="editSection" class="flex align-center" style="display: none; align-items: center;">
                <span class="mr-2">Topic</span>
                <strong>
                  <input id="topicInput" type="text" value="${
						d.topic
					}" style="">
                </strong>
                <svg id="save" class="align-center" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1787_67742)">
                <path d="M4.40172 7.79667L10.6686 1.52979L12 2.86119L11.4596 3.40189L10.3941 4.46693L9.06265 5.79835L7.99738 6.86411L4.40197 10.4595L3.07054 9.1281L0 6.11548L1.33142 4.78405L4.40172 7.79667Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_1787_67742">
                <rect width="12" height="12" fill="white"/>
                </clipPath>
                </defs>
                </svg>
                <svg id="cancel"  class="align-center" width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.70605 4.66688L11.3729 0L12.7061 1.33312L8.03918 6L12.7061 10.6669L11.3729 12L6.70605 7.33312L2.03918 12L0.706055 10.6669L5.37293 6L0.706055 1.33312L2.03918 0L6.70605 4.66688Z" fill="white"/>
                </svg>
              </li>
              <li class="flex align-center">
                <span class="mr-2">Keywords</span> <strong>${
					d.keywords.length
				}</strong>
              </li>
              <li class="flex align-center">
                <span class="mr-2">Conversations</span> <strong> ${
					d.conversations
				}</strong>
              </li>
            </ul>
            <ul class="list-unstyled bottom-list flex mt-3" >
              <li class="mr-3">
                <span>Positive</span>
                <strong class="positive" style="color:#43B969">	${d.sentiments.positive.toFixed(
					2
				)} %</strong>
              </li>
              <li class="mr-3">
                <span>Negative</span>
                <strong class="negative" style="color: #E45151">${d.sentiments.negative.toFixed(
					2
				)} %</strong>
              </li>
              <li class="mr-3">
                <span>Neutral</span>
                <strong class="neutral" style="color: #696868">${d.sentiments.neutral.toFixed(
					2
				)} %</strong>
              </li>
            </ul>
          </div>
            `;
				const x = d.x * transform.k + transform.x;
				const isLeft = window.innerWidth / 2 > x;
				const adj = d.radius * transform.k;
				showTip(
					tooltip,
					{
						left: x + (isLeft ? adj + 10 : -adj - 260),
						top: d.y * transform.k + transform.y - 20,
					},
					d,
					isLeft
				);
			}

			select("#node-tooltip").on("mouseleave", (e: any) => {
				if (hoverNode !== nodeEvent(e, "node")) {
					hoverNode = null;
					hideToolTip();
				}
			});

			function hideToolTip() {
				// hoverNode = null;
				select("#node-tooltip").html("");
			}

			function showTip(
				str: string,
				pos: { top: number; left: number } = { top: 0, left: 0 },
				node: any,
				isLeft: boolean
			) {
				const tip = select("#node-tooltip")
					.style("top", pos.top + "px")
					.style("left", pos.left + "px")
					.style("position", "absolute")
					.html(str);

				tip.select(".node-tooltip")
					.classed("left-arrow", isLeft)
					.classed("right-arrow", !isLeft);

				const handleSave = () => {
					const updatedTopicName = (
						tip.select("#topicInput").node() as HTMLInputElement
					).value;
					tip.select("#editSection").style("display", "none");
					tip.select("#viewSection")
						.style("display", "flex")
						.select("strong")
						.html(updatedTopicName);
					ExploreServiceHelper.changeCardNameData(
						node.topicId,
						updatedTopicName
					);
					node.topic = updatedTopicName;
					node.wrappedText = getWrappedTextData(ctx, node);
					refreshUI();
					props.onEdit(node);
				};

				const handleCancel = () => {
					(
						tip.select("#topicInput").node() as HTMLInputElement
					).value = node.topic;
					tip.select("#editSection").style("display", "none");
					tip.select("#viewSection").style("display", "flex");
				};

				const enableEdit = () => {
					tip.select("#editSection").style("display", "flex");
					tip.select("#viewSection").style("display", "none");
					tip.select("#save").on("click", handleSave);
					tip.select("#cancel").on("click", handleCancel);
				};

				tip.select("#edit").on("click", enableEdit);
			}

			simulation
				.nodes(tempData.nodes)
				.force("node", forceManyBody())
				.force(
					"link",
					forceLink((tempData as any).link as any)
						.strength(1)
						.id((d: any) => d.id)
				)
				.on("tick", refreshUI);
			// simulation.stop()
		}

		const refreshUI = () => {
			ctx.save();
			ctx.clearRect(0, 0, config.chartWidth, config.chartHeight);
			ctx.translate(transform.x, transform.y);
			ctx.scale(transform.k, transform.k);
			simulationUpdate();
			ctx.restore();
		};

		const handleParentEdit = (node: Node) => {
			node.wrappedText = getWrappedTextData(ctx, node);
			refreshUI();
			// props.onEdit(node);
		};

		const toggleLinks = () => {
			config.showLinks = !config.showLinks;
			refreshUI();
		};

		const toggleKeywords = () => {
			config.showKeywords = !config.showKeywords;
			refreshUI();
		};

		const resize = () => {
			const box = (
				graphCanvas.parentElement as HTMLDivElement
			).getBoundingClientRect();
			config.chartHeight = box.height;
			config.chartWidth = box.width;
			createHiDPICanvas(graphCanvas, box.width, box.height, 2);
			ctx.textBaseline = "middle";
			refreshUI();
		};

		const selectNodes = (selection: any[]) => {
			const nodes = refData.nodes;
			if (selection.length === nodes.length || selection.length === 0) {
				nodes.forEach((d) => (d.selected = true));
				refreshUI();
				return;
			}
			nodes.forEach((d) => (d.selected = false));
			const links: any = refData.link;
			selection.forEach((d) => {
				const foundNode = nodes.find((node) => node.id == d.id)!;
				foundNode.selected = true;
				const targets = links
					.filter((link: any) => link.source.id == d.id)
					.map((d: { target: { id: any } }) => d.target.id);
				targets.forEach((id: any) => {
					const foundNode = nodes.find((node) => node.id == id)!;
					foundNode.selected = true;
				});
			});
			refreshUI();
		};

		resize();
		updateGraph(data);

		return {
			toggleLinks,
			toggleKeywords,
			resize,
			updateGraph,
			selectNodes,
			handleParentEdit,
		};
	};

	function getRandomInt(max = 10) {
		return Math.floor(Math.random() * max) + 1;
	}

	function makeid(length: number) {
		let result = "";
		const characters =
			"ABCD EFGH IJKLM NOPQR STUVWXY  Zabcd efghijklmno pqrs tuvwxy z01234 56789";
		const charactersLength = characters.length;
		for (let i = 0; i < length; i++) {
			result += characters.charAt(
				Math.floor(Math.random() * charactersLength)
			);
		}
		return result;
	}

	const craeteJson = (data = response.data) => {
		const ctx: CanvasRenderingContext2D = (
			select(graphRef.current).node() as any as HTMLCanvasElement
		).getContext("2d") as CanvasRenderingContext2D;
		const nodes: Node[] = data
			? data.nodes.map(
					(
						node: {
							topicId: any;
							topic: any;
							keywords: {
								map: (
									arg0: (
										child: any,
										j: any
									) => {
										id: string;
										name: any;
										size: any;
										color: number;
										angle: number;
										posX: number;
										posY: number;
										parentRadius: number;
										parentRingRadius: number;
										radius: number;
										keywordLine: Path2D;
										keywordRect: Path2D;
										textCords: number[];
									}
								) => {
									id: string;
									name: string;
									size: number;
									color: number;
									parentRadius: number;
									parentRingRadius: number;
									posX: number;
									posY: number;
									radius: number;
									keywordLine: Path2D;
									keywordRect: Path2D;
									angle: number;
									textAlign?: CanvasTextAlign | undefined;
									textCords: [number, number];
								}[];
							};
						},
						i: number
					) => {
						const getNodeName = (name: any) => {
							let arr = name.split(" ");
							let newStr = arr.join("\n");
							return newStr;
						};
						const d: Node = {
							...node,
							id: node.topicId,
							name: getNodeName(node.topic),
							size: 0,
							color: i % 3,
							children: [],
							radius: 0,
							ringRadius: 0,
							x: 0,
							y: 0,
							wrappedText: [],
							selected: true,
						};

						let startAngle = getRandomInt(12) * 30;

						d.children = node.keywords.map(
							(child: any, j: string) => {
								const size = +child.occurences;
								d.size += size;
								startAngle += 30;
								return {
									id: "" + j,
									name: child.name,
									occurences: 0,
									size,
									color: i % 3,
									angle: startAngle,
									posX: Math.cos(
										((startAngle - 90) * Math.PI) / 180
									),
									posY: Math.sin(
										((startAngle - 90) * Math.PI) / 180
									),
									parentRadius: 0,
									parentRingRadius: 0,
									radius: 0,
									keywordLine: new Path2D(),
									keywordRect: new Path2D(),
									textCords: [0, 0],
								};
							}
						) as any;
						return d;
					}
			  )
			: new Array(100 || getRandomInt(500)).fill(0).map((_, i) => {
					const d: Node = {
						id: "" + i,
						topic: "",
						name: "#Cluster " + (i + 1),
						size: 0,
						color: i % 3,
						children: [],
						radius: 0,
						ringRadius: 0,
						x: 0,
						y: 0,
						wrappedText: [],
						selected: true,
					};
					let startAngle = getRandomInt(12) * 30;
					d.children = new Array(getRandomInt(8))
						.fill(0)
						.map((_, j) => {
							const size = getRandomInt(50);
							d.size += size;
							startAngle += 30;
							return {
								id: "" + j,
								name: makeid(getRandomInt(15)),
								occurences: 0,
								size,
								color: i % 3,
								angle: startAngle,
								posX: Math.cos(
									((startAngle - 90) * Math.PI) / 180
								),
								posY: Math.sin(
									((startAngle - 90) * Math.PI) / 180
								),
								parentRadius: 0,
								parentRingRadius: 0,
								radius: 0,
								keywordLine: new Path2D(),
								keywordRect: new Path2D(),
								textCords: [0, 0],
							};
						});
					return d;
			  });

		const angleMap: { [k: number]: [number, number][] } = {
			0: [[0, -40]],
			3: [[40, 0]],
			6: [[0, 40]],
			9: [[-40, 0]],
			1: [
				[0, -30],
				[30, -30],
			],
			5: [
				[0, 30],
				[30, 30],
			],
			7: [
				[0, 30],
				[-30, 30],
			],
			11: [
				[0, -30],
				[-30, -30],
			],
			2: [
				[0, -20],
				[30, -20],
			],

			4: [
				[0, 20],
				[30, 20],
			],
			8: [
				[0, 20],
				[-30, 20],
			],
			10: [
				[0, -20],
				[-30, -20],
			],
		};

		const sizes = nodes.map((d: { size: string | number }) => +d.size);
		const nodeScale = scaleLinear()
			.range([30, 60])
			.domain([Math.min(...sizes), Math.max(...sizes)]);
		nodes.forEach(
			(node: {
				children: any[];
				size: number;
				ringRadius: number;
				radius: number;
				x: number;
				y: number;
				name: string;
				topic: string;
				wrappedText: { text: string; dy: number }[];
			}) => {
				const values = node.children.map((d: { size: any }) => d.size);
				const radius = nodeScale(node.size);
				const gap = nodeScale(+node.size * 2) - radius;
				const correctedGap = gap < 15 ? 15 : gap;

				node.ringRadius = radius + correctedGap;
				node.radius = radius;
				node.wrappedText = getWrappedTextData(ctx, node);
				const min = correctedGap * 0.25;
				const max = correctedGap * 0.6;
				const scale = scaleLinear(
					[Math.min(...values), Math.max(...values)],
					[min, max]
				);

				node.children.forEach(
					(child: {
						parentRadius: any;
						parentRingRadius: any;
						posX: number;
						posY: number;
						radius: number;
						size: number;
						keywordLine: {
							moveTo: (arg0: number, arg1: number) => void;
							lineTo: (arg0: number, arg1: number) => void;
						};
						angle: number;
						textAlign: string;
						textCords: any[];
						name: string;
						keywordRect: Path2D;
					}) => {
						const path = new Path2D();
						child.parentRadius = node.radius;
						child.parentRingRadius = node.ringRadius;
						child.posX = child.posX * node.ringRadius;
						child.posY = child.posY * node.ringRadius;
						child.radius = scale(child.size);
						child.keywordLine.moveTo(0, 0);
						const key = (child.angle % 360) / 30;
						angleMap[key].forEach((d) => {
							child.keywordLine.lineTo(d[0], d[1]);
						});

						const endpoint = JSON.parse(
							JSON.stringify(
								angleMap[key][angleMap[key].length - 1].slice()
							)
						);

						child.textAlign =
							endpoint[0] === 0
								? "center"
								: endpoint[0] < 0
								? "end"
								: "start";
						child.textCords[0] =
							endpoint[0] +
							(endpoint[0] === 0 ? 0 : endpoint[0] < 0 ? -3 : 3);
						child.textCords[1] = endpoint[1];

						const textBackground = new Path2D();
						const metrics = ctx.measureText(child.name);
						const w = metrics.width;
						const r =
							(metrics.actualBoundingBoxAscent +
								metrics.actualBoundingBoxDescent) /
								2 +
							4;
						const x =
							child.textCords[0] +
							(child.textAlign === "center"
								? -(w / 2)
								: child.textAlign === "start"
								? 0
								: -w);
						const y = child.textCords[1];
						textBackground.arc(
							x,
							y,
							r,
							Math.PI * 0.5,
							Math.PI * 1.5
						);
						textBackground.lineTo(x + w, y - r);
						textBackground.arc(
							x + w,
							y,
							r,
							Math.PI * 1.5,
							Math.PI * 0.5
						);
						textBackground.lineTo(x, y + r);
						child.keywordRect = textBackground;
					}
				);
			}
		);

		const link = data
			? data.link.map((d: any) => {
					return {
						source: d.sourceTopicId,
						target: d.targetTopicId,
					};
			  })
			: new Array(nodes.length || getRandomInt(nodes.length))
					.fill(null)
					.map(() => {
						const link: Links = {
							source: nodes[getRandomInt(nodes.length) - 1].id,
							target: nodes[getRandomInt(nodes.length) - 1].id,
						};
						return link;
					});
		json.nodes = nodes;
		json.link = link;

		setOptions(
			nodes.map((d) => ({
				id: d.id,
				topic: d.topic,
				conversations: d.conversations,
			}))
		);
	};

	const json: NodeGraphJson = {
		nodes: [],
		link: [],
	};

	const wrapText = function (
		ctx: CanvasRenderingContext2D,
		text: string,
		x: number,
		y: number,
		maxWidth: number,
		lineHeight: number
	) {
		let words = text.split(" ");
		let line = "";
		let testLine = "";
		let lineArray: any = [];
		for (var n = 0; n < words.length; n++) {
			testLine += `${words[n]} `;
			let metrics = ctx.measureText(testLine);
			let testWidth = metrics.width;
			if (testWidth > maxWidth && n > 0) {
				lineArray.push([line, x, y]);
				y += lineHeight;
				line = `${words[n]} `;
				testLine = `${words[n]} `;
			} else {
				line += `${words[n]} `;
			}
			if (n === words.length - 1) {
				lineArray.push([line, x, y]);
			}
		}
		return lineArray;
	};

	const getWrappedTextData = (ctx: CanvasRenderingContext2D, node: any) => {
		const squareLength = Math.hypot(node.radius, node.radius);
		const nodeTexts = wrapText(
			ctx,
			node.topic,
			0,
			0,
			squareLength,
			lineHeight
		);
		const isOverflow = squareLength < nodeTexts.length * lineHeight;
		const lines = isOverflow
			? Math.floor(squareLength / lineHeight)
			: nodeTexts.length;
		const half = Math.floor(lines / 2);
		const start =
			lines == 1
				? 0
				: lines % 2 == 0
				? -lineHeight * half + lineHeight / 2
				: -lineHeight * half;
		return nodeTexts.slice(0, lines).map((text: any, index: number) => ({
			text:
				text[0].trim() +
				(isOverflow && index === lines - 1 ? "..." : ""),
			dy: start + index * lineHeight,
		}));
	};

	const toggleLinks = () => {
		config.chartRef?.toggleLinks();
	};

	const toggleKeywords = () => {
		config.chartRef?.toggleKeywords();
	};

	const onNodeClick = (node: any) => {
		props.onSelect(node);
	};

	const handleSelectionChange = (v: any) => {
		const index = selection.indexOf(v.target.value);
		index > -1
			? selection.splice(index, 1)
			: selection.push(v.target.value);
		setSelection([...selection]);
	};

	useEffect(() => {
		// console.log(props); Commenting
		if (props.updatedNode) {
			config.chartRef?.handleParentEdit(props.updatedNode);
		}
	}, [props.updatedNode]);

	useEffect(() => {
		config.chartRef?.selectNodes(selection);
	}, [selection]);

	const onCheckAllChange = (e: any) => {
		e.target.checked ? setSelection([...options]) : setSelection([]);
	};

	const allSelected = () =>
		selection.length && selection.length === options.length;

	return (
		<>
			<div id="node-tooltip"></div>

			<div className="graph-actions">
				<strong>
					Connections{" "}
					<Switch defaultChecked onClick={() => toggleLinks()} />
				</strong>
				<strong>
					Keywords{" "}
					<Switch defaultChecked onClick={() => toggleKeywords()} />
				</strong>
				{response.data.nodes.length && (
					<div className="check-tootip">
						<div className="check-head flex grid xl:grid-cols-12 align-center">
							<div className="col-span-7">
								<Checkbox
									indeterminate={
										!allSelected() && !!selection.length
									}
									checked={allSelected()}
									onChange={onCheckAllChange}>
									Clusters
								</Checkbox>
							</div>
							<div className="text-center col-span-5">
								Conversations
							</div>
						</div>
						<div className="check-body flex grid xl:grid-cols-12">
							<>
								{options.map((option: any) => (
									<>
										<div className="pb-2 col-span-12">
											<Checkbox
												value={option}
												checked={selection.includes(
													option
												)}
												onChange={
													handleSelectionChange
												}>
												<div className="flex justify-between flex-1">
													<span className="w-10/12">
														{option.topic}
													</span>
													<div className="d-inline-block text-center py-1 count-text">
														{option.conversations}
													</div>
												</div>
											</Checkbox>
										</div>
									</>
								))}
							</>
						</div>
					</div>
				)}
			</div>
			<div className="p4" style={{ width: "100%", height: "100%" }}>
				<canvas ref={graphRef} />
			</div>
		</>
	);
}

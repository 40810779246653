import * as StoriesService from "./../../../services/stories";

export const getAllStories: () => Promise<unknown> = async () => {
  const data = await StoriesService.getAllStories();
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};
export const addNewStory: (addData: { StoryName: string }) => Promise<unknown> = async (addData) => {
  const data = await StoriesService.addNewStory(addData);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};
export const deleteStory: (storyId: number) => Promise<unknown> = async (storyId) => {
  const data = await StoriesService.deleteStory(storyId);
  if (data === null || data === undefined) return null;
  return data;
};
export const edtStory: (editData: {
  id: string;
  "Story Name": string;
  // "Story Type": string;
}) => Promise<unknown> = async (editData) => {
  const data = await StoriesService.editStory(editData);
  if (data === null || data === undefined) return null;
  return data;
};

export const publishStory: (storyId: string) => Promise<unknown> = async (storyId) => {
  const data = await StoriesService.publishStory(storyId);
  if (data === null || data === undefined) return null;
  return data;
};

export const getStorybyId: (storyId: number) => Promise<unknown> = async (storyId) => {
  const data = await StoriesService.getStorybyId(storyId);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const deleteSavedSearch: (id: number) => Promise<unknown> = async (id) => {
  const data = await StoriesService.deleteSavedSearch(id);
  if (data === null || data === undefined) return null;
  return data;
};

export const getCollectionStatus: (storyId: number) => Promise<unknown> = async (
	storyId
  ) => {
	const data = await StoriesService.getCollectionStatus(storyId);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
  };
import moment from "moment";
import { request } from "../App";
import { FilterInterface } from "../Pages/Dashboard";

const getFilterObject = (filters: FilterInterface, filterState: any) => {
  var str = "";
  var obj: any = {};
  // console.log(
  // 	"Filters ",
  // 	filters,
  // 	filters.Start_Date !== undefined,
  // 	filters.Start_Date !== "",
  // 	filterState.Start_Date
  // );
  console.log("Filter State ", filters, filterState);
  if (filters.Timeframe_Display !== undefined && filters.Timeframe_Display !== "" && filters.Timeframe_Display && filterState.Timeframe_Display) {
    obj.timeframeDisplay = filters.Timeframe_Display;
  }

  if (filters.Start_Date !== undefined && filters.Start_Date !== "" && filterState.Start_Date) {
    console.log("In filter Start ", filters);
    obj.startDate = moment(filters.Start_Date).format();
    console.log("In filter Start End ", obj);
  }
  if (filters.End_Date !== undefined && filters.End_Date !== "" && filterState.End_Date) {
    if (filters.Timeframe_Display === "Quarter") {
      console.log("Inside Time Frame");
      obj.endDate = moment(filters.End_Date).add(3, "months");
    } else {
      obj.endDate = moment(filters.End_Date).add(1, "months");
    }
  }

  if (filters.Call_Type !== undefined && filters.Call_Type !== "" && filters.Call_Type && filterState.Call_Type) {
    obj.callType = filters.Call_Type;
  }

  if (filters.Therapy_Status !== undefined && filters.Therapy_Status !== "" && filters.Therapy_Status && filterState.Therapy_Status) {
    obj.therapyStatus = filters.Therapy_Status;
  }

  if (filters.Adherence !== undefined && filters.Adherence !== "" && filters.Adherence && filterState.Adherence) {
    obj.adherence = filters.Adherence;
  }

  if (filters.Call_Intent !== undefined && filters.Call_Intent !== "" && filters.Call_Intent && filterState.Call_Intent) {
    obj.callIntent = filters.Call_Intent;
  }

  return obj;
};

export const getProgramOverview = async (subTab: string, filters: FilterInterface, filterState: any) => {
  const str = getFilterObject(filters, filterState);
  let fetchUrl = "";
  if (subTab === "Calls") fetchUrl = "Dashboard/GetCallsPageData";
  else if (subTab === "Reach") fetchUrl = "Dashboard/GetReachPageData";
  else if (subTab === "Agent_Activity") fetchUrl = "Dashboard/GetAgentActivityPageData";
  // console.log(str);
  const data = await request
    .post(fetchUrl, str, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const callVolumeRefresh = async (totBucket: string) => {
  const fetchUrl = "Dashboard/RefreshCallVolume";

  const data = await request
    .post(
      fetchUrl,
      { totBucket },
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" },
      }
    )
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getEngagementData = async (subTab: string, filters: FilterInterface, filterState: any) => {
  let fetchUrl = "";
  console.log("In Services ", subTab);
  const obj = getFilterObject(filters, filterState);
  if (subTab === "Call_Participation") {
    fetchUrl = "Dashboard/GetCallParticipation";
  } else if (subTab === "Engagement_by_Topics") {
    fetchUrl = "Dashboard/GetEngagementByTopics";
  }
  const data = await request
    .post(fetchUrl, obj, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
  // return {
  // 	callParticipation: [],
  // 	engagementByTopics: [
  // 		{
  // 			cardName: "",
  // 			month: [],
  // 			value: [],
  // 			unit: [],
  // 			vsPrevMonth: 0,
  // 			chartName: ["Topics Discussed"],
  // 			yAxisLabel: ["Avg. Time Spent on a Topic (mins)"],
  // 			xAxisLabel: ["% of Times Topic Discussed"],
  // 			tableLinks: [],
  // 			chartAccessor: [],
  // 			footer: "Bubble Size - # of Patients Discussing the Topic",
  // 			kpi: [],
  // 			topChart: {},
  // 			singleChartData: [
  // 				{
  // 					x: 98.3,
  // 					y: 0.6,
  // 					z: 692,
  // 					introduction: 1,
  // 				},
  // 				{
  // 					x: 99.2,
  // 					y: 3.9,
  // 					z: 697,
  // 					others: 1,
  // 				},
  // 				{
  // 					x: 66.5,
  // 					y: 0.2,
  // 					z: 478,
  // 					"confirm next call": 1,
  // 				},
  // 				{
  // 					x: 70.2,
  // 					y: 1.4,
  // 					z: 499,
  // 					"common side-effects on vumerity": 1,
  // 				},
  // 				{
  // 					x: 82.3,
  // 					y: 0.1,
  // 					z: 587,
  // 					"safety recording": 1,
  // 				},
  // 				{
  // 					x: 92,
  // 					y: 0.9,
  // 					z: 649,
  // 					closing: 1,
  // 				},
  // 				{
  // 					x: 43,
  // 					y: 0.5,
  // 					z: 318,
  // 					"lab monitoring guidance": 1,
  // 				},
  // 				{
  // 					x: 72,
  // 					y: 0.34,
  // 					z: 519,
  // 					"vumerity dosing information": 1,
  // 				},
  // 				{
  // 					x: 59,
  // 					y: 0.2,
  // 					z: 425,
  // 					"nurse educator role": 1,
  // 				},
  // 				{
  // 					x: 20,
  // 					y: 0.1,
  // 					z: 150,
  // 					"specialty pharmacy": 1,
  // 				},
  // 				{
  // 					x: 35,
  // 					y: 0.31,
  // 					z: 258,
  // 					"patient questionnaire for the vumerity nurse educator program": 1,
  // 				},
  // 				{
  // 					x: 16,
  // 					y: 0.01,
  // 					z: 119,
  // 					"shipment/shipping": 1,
  // 				},
  // 			],
  // 			poChartData: {},
  // 			multiChartData: [],
  // 			complexChartData: [],
  // 			engagemnetByTopicChartData: [],
  // 			patientNeedsChartData: [],
  // 		},
  // 		{
  // 			cardName: "",
  // 			month: [],
  // 			value: [],
  // 			unit: [],
  // 			vsPrevMonth: 0,
  // 			chartName: ["Topics Discussed Agent Vs Patient"],
  // 			yAxisLabel: ["% of Times Discussed by Patient"],
  // 			xAxisLabel: ["% of Times Discussed by Agent"],
  // 			tableLinks: [],
  // 			chartAccessor: [],
  // 			footer: "Bubble Size - Average time Spent on Topic (minutes)",
  // 			kpi: [],
  // 			topChart: {},
  // 			singleChartData: [
  // 				{
  // 					x: 48,
  // 					y: 11,
  // 					z: 1,
  // 					introduction: 1,
  // 				},
  // 				{
  // 					x: 197,
  // 					y: 133,
  // 					z: 3,
  // 					others: 1,
  // 				},
  // 				{
  // 					x: 28,
  // 					y: 4,
  // 					z: 0,
  // 					"confirm next call": 1,
  // 				},
  // 				{
  // 					x: 78,
  // 					y: 38,
  // 					z: 1,
  // 					"common side-effects on vumerity": 1,
  // 				},
  // 				{
  // 					x: 30,
  // 					y: 3,
  // 					z: 0,
  // 					"safety recording": 1,
  // 				},
  // 				{
  // 					x: 53,
  // 					y: 7,
  // 					z: 1,
  // 					closing: 1,
  // 				},
  // 				{
  // 					x: 27,
  // 					y: 7,
  // 					z: 0,
  // 					"lab monitoring guidance": 1,
  // 				},
  // 				{
  // 					x: 70,
  // 					y: 15,
  // 					z: 1,
  // 					"vumerity dosing information": 1,
  // 				},
  // 				{
  // 					x: 45,
  // 					y: 3,
  // 					z: 0,
  // 					"nurse educator role": 1,
  // 				},
  // 				{
  // 					x: 12,
  // 					y: 1,
  // 					z: 0,
  // 					"specialty pharmacy": 1,
  // 				},
  // 				{
  // 					x: 31,
  // 					y: 5,
  // 					z: 0,
  // 					"patient questionnaire for the vumerity nurse educator program": 1,
  // 				},
  // 				{
  // 					x: 6,
  // 					y: 1,
  // 					z: 0,
  // 					"shipment/shipping": 1,
  // 				},
  // 			],
  // 			poChartData: {},
  // 			multiChartData: [],
  // 			complexChartData: [],
  // 			engagemnetByTopicChartData: [],
  // 			patientNeedsChartData: [],
  // 		},
  // 		{
  // 			cardName: "",
  // 			month: [],
  // 			value: [],
  // 			unit: [],
  // 			vsPrevMonth: 0,
  // 			chartName: ["List of Topics Discussed"],
  // 			yAxisLabel: [],
  // 			xAxisLabel: [],
  // 			tableLinks: [],
  // 			chartAccessor: [
  // 				{
  // 					id: "Last 10 Months Trend",
  // 					type: "line",
  // 				},
  // 			],
  // 			footer: "",
  // 			kpi: [],
  // 			topChart: {},
  // 			singleChartData: [],
  // 			poChartData: {},
  // 			multiChartData: [],
  // 			complexChartData: [],
  // 			engagemnetByTopicChartData: [
  // 				{
  // 					"Topic Name": "introduction",
  // 					"% of Topics Discussed": 13,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 101,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 349,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 131,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 139,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "others",
  // 					"% of Topics Discussed": 13,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 102,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 348,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 134,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 142,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "confirm next call",
  // 					"% of Topics Discussed": 9,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 78,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 255,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 81,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 73,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "common side-effects on vumerity",
  // 					"% of Topics Discussed": 9,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 70,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 272,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 90,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 84,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "safety recording",
  // 					"% of Topics Discussed": 11,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 89,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 318,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 101,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 98,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "closing",
  // 					"% of Topics Discussed": 12,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 98,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 328,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 122,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 128,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "lab monitoring guidance",
  // 					"% of Topics Discussed": 6,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 47,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 158,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 63,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 54,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "vumerity dosing information",
  // 					"% of Topics Discussed": 10,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 78,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 274,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 91,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 91,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "nurse educator role",
  // 					"% of Topics Discussed": 8,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 55,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 244,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 74,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 60,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "specialty pharmacy",
  // 					"% of Topics Discussed": 3,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 12,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 95,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 22,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 23,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name":
  // 						"patient questionnaire for the vumerity nurse educator program",
  // 					"% of Topics Discussed": 5,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 44,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 136,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 54,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 24,
  // 							},
  // 						},
  // 					],
  // 				},
  // 				{
  // 					"Topic Name": "shipment/shipping",
  // 					"% of Topics Discussed": 2,
  // 					"Average Time Spent on Topic (mins)": 0,
  // 					"# of Patients Discussing the Topic": 0,
  // 					"Last 10 Months Trend": [
  // 						{
  // 							"Mar 21": {
  // 								Series1: 15,
  // 							},
  // 							"Apr 21": {
  // 								Series1: 79,
  // 							},
  // 							"Jul 21": {
  // 								Series1: 16,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 11,
  // 							},
  // 						},
  // 					],
  // 				},
  // 			],
  // 			patientNeedsChartData: [],
  // 		},
  // 	],
  // };
};

export const getPatientExperienceData = async (subTab: string, filters: FilterInterface, filterState: any) => {
  const obj = getFilterObject(filters, filterState);
  var fetchUrl = "";
  if (subTab === "Needs") {
    fetchUrl = "Dashboard/GetPatientNeedsData";
  } else if (subTab === "Sentiment") {
    fetchUrl = "Dashboard/GetPatientSentimentData";
  } else if (subTab === "Journey") {
    fetchUrl = "Dashboard/GetPatientJourneyData";
    // return {
    // 	patientNeeds: [],
    // 	patientJourney: {
    // 		unmetNeedsAndQuestionTime: [
    // 			{
    // 				cardName: "",
    // 				month: [],
    // 				value: [],
    // 				unit: [],
    // 				vsPrevMonth: [],
    // 				topChart: {},
    // 				chartName: ["Top 5 Unmet Needs"],
    // 				xAxisLabel: [],
    // 				yAxisLabel: [],
    // 				footer: "",
    // 				chartColor: [],
    // 				tableLinks: [],
    // 				chartAccessor: [],
    // 				chartData: [
    // 					{
    // 						"30": {
    // 							Access: "78%",
    // 							"Side Effects": "64%",
    // 							"COVID-19": "31%",
    // 							"Dosing Instructions": "27%",
    // 							"Dietary Guidance": "12%",
    // 						},
    // 						"60": {
    // 							"Dietary Restriction": "61%",
    // 							"Dosing Instructions": "59%",
    // 							Access: "44%",
    // 							"Vumerity App": "32%",
    // 							"Side Effects": "27%",
    // 						},
    // 						"90": {
    // 							"Side Effects": "45%",
    // 							"COVID-19": "43%",
    // 							Access: "29%",
    // 							"Dosing Instructions": "22%",
    // 							"Pill Box": "19%",
    // 						},
    // 						"180": {
    // 							"Pill Box": "51%",
    // 							Access: "38%",
    // 							"Dosing Instructions": "37%",
    // 							"COVID-19": "34%",
    // 							"Side Effects": "16%",
    // 						},
    // 						"180+": {
    // 							"COVID-19": "45%",
    // 							Access: "42%",
    // 							"Medication Expiration": "36%",
    // 							"Side Effects": "32%",
    // 							Billing: "27%",
    // 						},
    // 					},
    // 				],
    // 			},
    // 			{
    // 				cardName: "",
    // 				month: [],
    // 				value: [42, 12, 13, 41, 11],
    // 				unit: ["secs", "secs", "secs", "secs", "secs"],
    // 				vsPrevMonth: [],
    // 				topChart: {},
    // 				chartName: ["Average Time Spent in Asking Questions"],
    // 				xAxisLabel: [],
    // 				yAxisLabel: [],
    // 				footer: "",
    // 				chartColor: [],
    // 				tableLinks: [],
    // 				chartAccessor: [],
    // 				chartData: [
    // 					{
    // 						"Feb 21": {
    // 							"Series 1": 45,
    // 						},
    // 						"Mar 21": {
    // 							"Series 1": 50,
    // 						},
    // 						"Apr 21": {
    // 							"Series 1": 52,
    // 						},
    // 						"May 21": {
    // 							"Series 1": 37,
    // 						},
    // 						"Jun 21": {
    // 							"Series 1": 85,
    // 						},
    // 						"Jul 21": {
    // 							"Series 1": 63,
    // 						},
    // 						"Aug 21": {
    // 							"Series 1": 70,
    // 						},
    // 						"Sep 21": {
    // 							"Series 1": 65,
    // 						},
    // 						"Oct 21": {
    // 							"Series 1": 68,
    // 						},
    // 						"Nov 21": {
    // 							"Series 1": 72,
    // 						},
    // 					},
    // 					{
    // 						"Feb 21": {
    // 							"Series 1": 45,
    // 						},
    // 						"Mar 21": {
    // 							"Series 1": 50,
    // 						},
    // 						"Apr 21": {
    // 							"Series 1": 52,
    // 						},
    // 						"May 21": {
    // 							"Series 1": 37,
    // 						},
    // 						"Jun 21": {
    // 							"Series 1": 85,
    // 						},
    // 						"Jul 21": {
    // 							"Series 1": 63,
    // 						},
    // 						"Aug 21": {
    // 							"Series 1": 70,
    // 						},
    // 						"Sep 21": {
    // 							"Series 1": 65,
    // 						},
    // 						"Oct 21": {
    // 							"Series 1": 68,
    // 						},
    // 						"Nov 21": {
    // 							"Series 1": 72,
    // 						},
    // 					},
    // 					{
    // 						"Feb 21": {
    // 							"Series 1": 45,
    // 						},
    // 						"Mar 21": {
    // 							"Series 1": 50,
    // 						},
    // 						"Apr 21": {
    // 							"Series 1": 52,
    // 						},
    // 						"May 21": {
    // 							"Series 1": 37,
    // 						},
    // 						"Jun 21": {
    // 							"Series 1": 85,
    // 						},
    // 						"Jul 21": {
    // 							"Series 1": 63,
    // 						},
    // 						"Aug 21": {
    // 							"Series 1": 70,
    // 						},
    // 						"Sep 21": {
    // 							"Series 1": 65,
    // 						},
    // 						"Oct 21": {
    // 							"Series 1": 68,
    // 						},
    // 						"Nov 21": {
    // 							"Series 1": 72,
    // 						},
    // 					},
    // 					{
    // 						"Feb 21": {
    // 							"Series 1": 45,
    // 						},
    // 						"Mar 21": {
    // 							"Series 1": 50,
    // 						},
    // 						"Apr 21": {
    // 							"Series 1": 52,
    // 						},
    // 						"May 21": {
    // 							"Series 1": 37,
    // 						},
    // 						"Jun 21": {
    // 							"Series 1": 85,
    // 						},
    // 						"Jul 21": {
    // 							"Series 1": 63,
    // 						},
    // 						"Aug 21": {
    // 							"Series 1": 70,
    // 						},
    // 						"Sep 21": {
    // 							"Series 1": 65,
    // 						},
    // 						"Oct 21": {
    // 							"Series 1": 68,
    // 						},
    // 						"Nov 21": {
    // 							"Series 1": 72,
    // 						},
    // 					},
    // 					{
    // 						"Feb 21": {
    // 							"Series 1": 45,
    // 						},
    // 						"Mar 21": {
    // 							"Series 1": 50,
    // 						},
    // 						"Apr 21": {
    // 							"Series 1": 52,
    // 						},
    // 						"May 21": {
    // 							"Series 1": 37,
    // 						},
    // 						"Jun 21": {
    // 							"Series 1": 85,
    // 						},
    // 						"Jul 21": {
    // 							"Series 1": 63,
    // 						},
    // 						"Aug 21": {
    // 							"Series 1": 70,
    // 						},
    // 						"Sep 21": {
    // 							"Series 1": 65,
    // 						},
    // 						"Oct 21": {
    // 							"Series 1": 68,
    // 						},
    // 						"Nov 21": {
    // 							"Series 1": 72,
    // 						},
    // 					},
    // 				],
    // 			},
    // 		],
    // 		sentimentBreakdown: [
    // 			{
    // 				cardName: "30",
    // 				month: [],
    // 				value: [],
    // 				unit: [],
    // 				vsPrevMonth: [],
    // 				topChart: {
    // 					positive: 45,
    // 					neutral: 44,
    // 					negative: 11,
    // 				},
    // 				chartName: ["Overall Negative Sentiments"],
    // 				xAxisLabel: [],
    // 				yAxisLabel: [],
    // 				footer: "",
    // 				chartColor: [],
    // 				tableLinks: [],
    // 				chartAccessor: [],
    // 				chartData: [
    // 					{
    // 						Mad: {
    // 							"Series 1": 45,
    // 						},
    // 						Frustrated: {
    // 							"Series 1": 50,
    // 						},
    // 						Disoriented: {
    // 							"Series 1": 52,
    // 						},
    // 						Confused: {
    // 							"Series 1": 37,
    // 						},
    // 						Sad: {
    // 							"Series 1": 85,
    // 						},
    // 					},
    // 				],
    // 			},
    // 			{
    // 				cardName: "60",
    // 				month: [],
    // 				value: [],
    // 				unit: [],
    // 				vsPrevMonth: [],
    // 				topChart: {
    // 					positive: 47,
    // 					neutral: 21,
    // 					negative: 32,
    // 				},
    // 				chartName: [],
    // 				xAxisLabel: [],
    // 				yAxisLabel: [],
    // 				footer: "",
    // 				chartColor: [],
    // 				tableLinks: [],
    // 				chartAccessor: [],
    // 				chartData: [
    // 					{
    // 						Mad: {
    // 							"Series 1": 45,
    // 						},
    // 						Frustrated: {
    // 							"Series 1": 50,
    // 						},
    // 						Disoriented: {
    // 							"Series 1": 52,
    // 						},
    // 						Confused: {
    // 							"Series 1": 37,
    // 						},
    // 						Sad: {
    // 							"Series 1": 85,
    // 						},
    // 					},
    // 				],
    // 			},
    // 			{
    // 				cardName: "90",
    // 				month: [],
    // 				value: [],
    // 				unit: [],
    // 				vsPrevMonth: [],
    // 				topChart: {
    // 					positive: 30,
    // 					neutral: 12,
    // 					negative: 58,
    // 				},
    // 				chartName: [],
    // 				xAxisLabel: [],
    // 				yAxisLabel: [],
    // 				footer: "",
    // 				chartColor: [],
    // 				tableLinks: [],
    // 				chartAccessor: [],
    // 				chartData: [
    // 					{
    // 						Mad: {
    // 							"Series 1": 45,
    // 						},
    // 						Frustrated: {
    // 							"Series 1": 50,
    // 						},
    // 						Disoriented: {
    // 							"Series 1": 52,
    // 						},
    // 						Confused: {
    // 							"Series 1": 37,
    // 						},
    // 						Sad: {
    // 							"Series 1": 85,
    // 						},
    // 					},
    // 				],
    // 			},
    // 			{
    // 				cardName: "180",
    // 				month: [],
    // 				value: [],
    // 				unit: [],
    // 				vsPrevMonth: [],
    // 				topChart: {
    // 					positive: 27,
    // 					neutral: 24,
    // 					negative: 49,
    // 				},
    // 				chartName: [],
    // 				xAxisLabel: [],
    // 				yAxisLabel: [],
    // 				footer: "",
    // 				chartColor: [],
    // 				tableLinks: [],
    // 				chartAccessor: [],
    // 				chartData: [
    // 					{
    // 						Mad: {
    // 							"Series 1": 45,
    // 						},
    // 						Frustrated: {
    // 							"Series 1": 50,
    // 						},
    // 						Disoriented: {
    // 							"Series 1": 52,
    // 						},
    // 						Confused: {
    // 							"Series 1": 37,
    // 						},
    // 						Sad: {
    // 							"Series 1": 85,
    // 						},
    // 					},
    // 				],
    // 			},
    // 			{
    // 				cardName: "180+",
    // 				month: [],
    // 				value: [],
    // 				unit: [],
    // 				vsPrevMonth: [],
    // 				topChart: {
    // 					positive: 19,
    // 					neutral: 20,
    // 					negative: 61,
    // 				},
    // 				chartName: [],
    // 				xAxisLabel: [],
    // 				yAxisLabel: [],
    // 				footer: "",
    // 				chartColor: [],
    // 				tableLinks: [],
    // 				chartAccessor: [],
    // 				chartData: [
    // 					{
    // 						Mad: {
    // 							"Series 1": 45,
    // 						},
    // 						Frustrated: {
    // 							"Series 1": 50,
    // 						},
    // 						Disoriented: {
    // 							"Series 1": 52,
    // 						},
    // 						Confused: {
    // 							"Series 1": 37,
    // 						},
    // 						Sad: {
    // 							"Series 1": 85,
    // 						},
    // 					},
    // 				],
    // 			},
    // 		],
    // 	},
    // 	patientSentiments: [],
    // };
  }
  const str = getFilterObject(filters, filterState);
  // console.log(str);
  const data = await request
    .post(fetchUrl, obj, { withCredentials: true })
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log("Error : ", err.message);
      return null;
    });
  return data;
  // return {
  // 	patientNeeds: [
  // 		{
  // 			cardName: "",
  // 			month: [],
  // 			value: [],
  // 			unit: [],
  // 			vsPrevMonth: 0,
  // 			chartName: ["Story Opportunities"],
  // 			yAxisLabel: ["# of Patients Mentioning Story"],
  // 			xAxisLabel: ["Sentiments (Net Promoter Score)"],
  // 			tableLinks: [],
  // 			chartAccessor: [],
  // 			footer: "",
  // 			kpi: [],
  // 			topChart: {},
  // 			singleChartData: [],
  // 			poChartData: {},
  // 			multiChartData: [],
  // 			complexChartData: [],
  // 			engagemnetByTopicChartData: [],
  // 			patientNeedsChartData: [],
  // 		},
  // 		{
  // 			cardName: "",
  // 			month: [],
  // 			value: [],
  // 			unit: [],
  // 			vsPrevMonth: 0,
  // 			chartName: ["Story Summary"],
  // 			yAxisLabel: [],
  // 			xAxisLabel: [],
  // 			tableLinks: [],
  // 			chartAccessor: [
  // 				{
  // 					id: "Mentions Over Time",
  // 					type: "line",
  // 				},
  // 				{
  // 					id: "Consumer Sentiment",
  // 					type: "bar",
  // 				},
  // 			],
  // 			footer: "",
  // 			kpi: [],
  // 			topChart: {},
  // 			singleChartData: [],
  // 			poChartData: {},
  // 			multiChartData: [],
  // 			complexChartData: [],
  // 			engagemnetByTopicChartData: [],
  // 			patientNeedsChartData: [
  // 				{
  // 					"Story Name": "Data",
  // 					"Story Type": "Issues",
  // 					"Story Id": "story_16",
  // 					"#Mentions": 10,
  // 					"Mentions Over Time": [
  // 						{
  // 							"Jul 21": {
  // 								Series1: 10,
  // 							},
  // 							"Aug 21": {
  // 								Series1: 12,
  // 							},
  // 							"Sep 21": {
  // 								Series1: 16,
  // 							},
  // 						},
  // 					],
  // 					qoqGrowth: null,
  // 					"Trend Score": 12,
  // 					"Consumer Sentiment": [
  // 						{
  // 							"Consumer Sentiment": {
  // 								Negative: 2,
  // 								Neutral: 3,
  // 								Positive: 5,
  // 							},
  // 						},
  // 					],
  // 				},
  // 			],
  // 		},
  // 	],
  // };
};

export const getPatientNeedsDetails = async (storyId: string | number) => {
  const fetchUrl = "Dashboard/GetStoryDetailsforPatientNeeds?storyId=" + storyId;
  const data = await request
    .post(fetchUrl, {}, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getCustomerSatisfaction = async (filters: FilterInterface, filterState: any) => {
  const obj = getFilterObject(filters, filterState);
  const fetchUrl = "Dashboard/GetCustomerSatisfaction";
  const data = await request
    .post(fetchUrl, obj, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getKPIs = async (filters: FilterInterface, filterState: any) => {
  const obj = getFilterObject(filters, filterState);
  console.log("Object KPI ", obj);
  const fetchUrl = "Dashboard/GetKpiPageData";
  const data = await request
    .post(fetchUrl, obj, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

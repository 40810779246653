import { request } from "./../App";

export const getAllStories = async () => {
  const fetchUrl = "story/Getstories";
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};
export const addNewStory = async (addData: { StoryName: string }) => {
  const fetchUrl = "story/AddNewStory";
  const data = await request
    .post(fetchUrl, addData, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const deleteStory = async (storyId: number) => {
  const fetchUrl = `story/DeleteStory?id=${storyId}`;
  const data = await request
    .delete(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const editStory = async (editData: {
  id: string;
  "Story Name": string;
  // "Story Type": string;
}) => {
  const fetchUrl = "story/EditStory";
  const data = await request
    .put(fetchUrl, editData, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

// export const addToStory = async (searchJson: any, storyId?: string) => {
// 	const fetchUrl = "search/AddToStory";
// 	const data = await request
// 		.post(fetchUrl, searchJson, { withCredentials: true })
// 		.then((response) => {
// 			// console.log(response.status);
// 			return response.data;
// 		})
// 		.catch((err) => {
// 			// console.log("Error : ", err.message);
// 			return null;
// 		});
// 	return data;
// };

export const publishStory = async (storyId: string) => {
  const fetchUrl = `story/GetPublishStory?id=${storyId}`;
  const data = await request
    .post(fetchUrl, {}, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getStorybyId = async (storyId: number) => {
  const fetchUrl = `story/GetStorybyId?id=${storyId}`;
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const deleteSavedSearch = async (id: number) => {
  const fetchUrl = `search/DeleteSavedSearch?id=${id}`;
  const data = await request
    .delete(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getCollectionStatus  = async (storyId: number) => {
	const fetchUrl = `story/GetCollectionStatus?storyId=${storyId}`;
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
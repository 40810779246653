import { icons } from "../../../GlobalThemes/index";

/**
 * This function is used to render the arrow comparison of the data
 *
 * @remarks - This function is used inside POCard to show the comparison with NumberData component data
 *
 * @param dataNumber - The props contain the comparison number in percent
 * @param dataName - The props is used to print the name of the comparison
 *
 * @returns the green or red arrow comparison of the data
 */

export function ArrowComparison(props: {
	dataNumber: number | string;
	dataName?: string;
}) {
	return (
		<div>
			<div className="flex items-end">
				<div className="mr-2">
					{
						icons.find(
							(l) =>
								l.id ===
								(props.dataNumber >= 0
									? "Up Arrow Filled"
									: "Down Arrow Filled")
						)?.icon
					}
				</div>
				<div className="text-green-800 font-semibold text-sm">
					<span
						style={{
							color:
								props.dataNumber >= 0 ? "#00AA67" : "#B21111",
						}}
					>
						{Math.abs(Number(props.dataNumber))}%
					</span>
				</div>
			</div>
			<div className="text-sm text-gray-500">
				<span>{props.dataName}</span>
			</div>
		</div>
	);
}

import {
  Button,
  Modal,
  Input,
  Select,
  Tooltip,
  Checkbox,
  Dropdown,
  Menu,
  Skeleton,
  Form,
  Tag,
} from "antd";
import { icons, userTypeEnum } from "../../../../GlobalThemes";
import React, { useState, useEffect } from "react";
import ReportTypes from "../../../../libraries/report-types";
import { transformTableData } from "../../../Dashboard/Processing";
import * as ReportTypesComponentInterface from "../../../../libraries/report-types/charts/interfaces/main";
import Tags from "../../../../Components/Tags";
import ProgressBar from "../../../../Components/Progress";
import TableWrapper from "../../../../Components/TableWrapper";
import { storyList, storyTypes } from "../../../SearchEngine/data";
import * as SearchServiceHelper from "../../../SearchEngine/ServiceHelpers/search";
import AppContext from "../../../../store";

export interface fullTranscript {
  highlight?: boolean;
  renderText?: string;
  speaker: string;
  text: string;
  dialogueNo: number;
}

/**
 * Renders the Modal to display the whole transcript.
 *
 * @param id - The props for the transcript Id
 * @param fullTranscriptData - The props for the whole transcript data
 *
 * @returns the whole transcript in a modal.
 */

function TranscriptModalData(props: {
  id: number;
  fullTranscriptData: fullTranscript[];
  keywords?: string[];
}) {
  const [fetchedData, setFetchedData] = React.useState<fullTranscript[]>(
    props.fullTranscriptData || []
  );
  const [showKeyWords, setShowKeywords] = React.useState<boolean>(
    !!(props.keywords && props.keywords.length)
  );

  if (showKeyWords) {
    const pattern = new RegExp(`(${props.keywords?.join("|")})`, "gi");
    fetchedData.forEach((d) => {
      d.renderText = `<strong>${d.speaker}</strong>: `;
      d.renderText += d.text.replace(
        pattern,
        (match) => `<span>${match}</span>`
      );
      d.highlight = d.renderText.includes("<span>");
    });
  }

  return (
    <div className="text-base pb-2 px-2 h-96 overflow-auto">
      {fetchedData.length > 0 &&
        fetchedData.map((detail) => {
          return showKeyWords ? (
            <p
              className={`m-0 py-3 text-sm text-text-gray-90 transcript-text ${detail.highlight ? "contains-keyword" : ""
                }`}
              key={detail.dialogueNo}
              dangerouslySetInnerHTML={{ __html: detail.renderText || "" }}
            ></p>
          ) : (
            <p
              className="m-0 py-3 text-sm text-text-gray-90"
              key={detail.dialogueNo}
            >
              <strong>{detail.speaker}</strong>: {detail.text}
            </p>
          );
        })}
      {fetchedData.length === 0 && <p>Loading...</p>}
    </div>
  );
}

/**
 * Renders the single transcript with heading, agent and patient transcript, and action to add in story.
 *
 * @param storyId - The props for story id if it is added to any story
 * @param data - The props for transcript data with name, transcripts, stories Added array, s3path, etc.
 * @param expanded - The props to expand the card to show more information
 * @param fetchedStoryData - The props represent the list of all the stories fetched from backend
 * @param pinnedStoriesData - The props represent the pinned stories data
 *
 * @param addToStoryApi - The function will be called to add transcript to story
 * @param changePinnedStories - The function is used to pin and unpin the stories
 * @param addNewStory - The function will be called to add new story to the list
 *
 * @returns the whole transcript in a modal.
 */

export function DriversSummaryTranscript(props: {
  storyId?: any;
  dataForNegativeSampling?: any;
  data?: any;
  // expanded?: boolean;
  showTags?: boolean;
  hideProgressBar?: boolean;
  fetchedStoryData?: any;
  fetchStoryData?: any;
  transcriptData?: any;
  storyDialogues?: any;
  searchString?: any;
  setSearchString?: any;
  keywords?: string[];
}) {
  var storyObject = {
    "Story Name": "",
    "Story Type": "",
  };
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [addStoryModal, setAddStoryModal] = useState<boolean>(false);
  const [createStoryModal, setCreateStoryModal] = useState<boolean>(false);
  const [rowId, setRowId] = useState(-1);
  const [existingCount, setExistingCount] = useState<number>(0);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [storyData, setStoryData] = useState<any>(storyObject);
  const [ids, setids] = useState<any>(true);
  const [loadFullTranscript, setLoadFullTranscript] = useState(false);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [fullTranscriptData, setFullTranscriptData] = useState<
    fullTranscript[]
  >([]);
  const [storyChange, setStoryChange] = useState<boolean>(false);
  const context = React.useContext(AppContext);
  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleCreateOk = () => {
    setCreateStoryModal(false);
    setAddStoryModal(true);
  };

  const handleCreateCancel = () => {
    setCreateStoryModal(false);
    setAddStoryModal(true);
  };
  const [pinStates, setPinStates] = useState<Array<any>>([]);
  const [addStates, setAddStates] = useState<Array<any>>([]);

  var grouped_columns = [
    {
      Cell: (props: any) => {
        return (
          <div
            onClick={() => {
              var pins = [...pinStates];
              pins[props.row.original.index] = !pins[props.row.original.index];
              setPinStates(pins);
            }}
          >
            {
              icons.find(
                (l) =>
                  l.id ===
                  (pinStates[props.row.original.index]
                    ? "Pin Filled"
                    : "Pin Angle")
              )?.icon
            }
          </div>
        );
      },
      title: " ",
      accessor: "_",
      width: 50,
    },
    {
      title: "Story Name",
      accessor: "Story_Name",
      width: 300,
    },
    {
      title: "Story Type",
      accessor: "Story_Type",
    },
    {
      title: "Date Created",
      accessor: "Date_Created",
    },
    {
      title: "# of Annotated Selections",
      accessor: "#_of_Annotated_Selections",
    },
    {
      title: "Status",
      accessor: "Status",
    },
    {
      title: "Add",
      accessor: "add",
      Cell: (props: any) => {
        var add = [...addStates];
        // console.log("Happ ", props);
        // console.log(add);
        return (
          <Checkbox
            checked={addStates[props.row.original.index]}
            className="reach-check"
            onChange={(e) => {
              // console.log("Row", props.row);
              // console.log(e.target.checked);
              add[props.row.original.index] = !add[props.row.original.index];
              setAddStates(add);
              setStoryChange(true);
            }}
          />
        );
      },
    },
  ];

  const [cStoryList, setCStoryList] = useState<any>(props.fetchedStoryData);
  const [addToStory, setAddToStory] = useState<any>(props.fetchedStoryData);

  const handleStoryOk = () => {
    setAddStoryModal(false);
  };

  const showCreateStory = () => {
    setAddStoryModal(false);
    setCreateStoryModal(true);
  };

  // const handleStoryCancel = () => {
  //   pinnedStories();
  //   var arr: any = [];
  //   arr.fill(false, cStoryList.length);
  //   setAddStoryModal(false);
  // };
  // const pinnedStories = () => {
  //   var pins = [...pinStates];
  //   var stories: any = [];
  //   pins.forEach((pin, index) => {
  //     if (pin) {
  //       stories.push(cStoryList[index]);
  //     }
  //   });
  //   if (props.changePinnedStories) {
  //     props.changePinnedStories(stories);
  //   }
  // };

  const addStoryChecklist = (storyData: any) => {
    var table: any = storyData;
    var transcriptData: any = [];
    if (storyData && storyData.length > 0) {
      storyData.forEach((singleData: any, index: number) => {
        if (
          props.data.storiesAdded !== undefined &&
          props.data.storiesAdded.length > 0
        ) {
          if (
            props.data.storiesAdded.find(
              (story: any, index: any) => story.storyId === singleData.id
            )
          ) {
            transcriptData[index] = true;
            table[index]["index"] = index;
          } else {
            transcriptData[index] = false;
            table[index]["index"] = index;
          }
        } else {
          transcriptData[index] = false;
          table[index]["index"] = index;
        }
      });
    }
    setExistingCount(transcriptData.length);
    setAddStates(transcriptData);
  };

  // useEffect(() => {
  //     var pinData: any = [];
  //     if (cStoryList) {
  //       cStoryList.forEach((singleData: any, index: number) => {
  //         if (
  //           props.pinnedStoriesData &&
  //           props.pinnedStoriesData.findIndex((story: any) => story.id === singleData.id) !== -1
  //         ) {
  //           pinData[index] = true;
  //         } else {
  //           pinData[index] = false;
  //         }
  //       });
  //       setPinStates(pinData);
  //     }
  //   }, [props.pinnedStoriesData]);

  useEffect(() => {
    // console.log(rowId);
  }, [rowId]);

  useEffect(() => { }, [props.data]);

  useEffect(() => {
    setAddToStory(props.fetchedStoryData);
    setCStoryList(props.fetchedStoryData);
    addStoryChecklist(props.fetchedStoryData);
  }, [props.fetchedStoryData]);

  const addToStoryShow = (storyData: any) => {
    var storyNames = "";
    const TooltipData = () => {
      return (
        <>
          {storyData.map((story: any) => {
            return (
              <>
                <p>{story.storyName}</p>
              </>
            );
          })}
        </>
      );
    };
    storyData.forEach((story: any, index: number) => {
      storyNames = storyNames + `${story.storyName}\n`;
    });
    // console.log(storyNames);
    if (storyData.length === 1) {
      return storyNames;
    } else {
      return (
        <Tooltip placement="top" title={<TooltipData />}>
          {`${storyData[0].storyName} +${storyData.length - 1}`}
        </Tooltip>
      );
    }
  };

  // const showPinnedStoriesName = (pinnedData: any) => {
  //   const menu = (
  //     <Menu>
  //       <Menu.Item
  //         onClick={() => {
  //           setAddStoryModal(true);
  //           setStoryChange(false);
  //         }}
  //       >
  //         {/* Choose a Different Story */}
  //       </Menu.Item>
  //     </Menu>
  //   );
  //   const TooltipData = () => {
  //     return (
  //       <>
  //         {pinnedData.map((pin: any) => {
  //           return (
  //             <>
  //               <p>{pin["Story Name"]}</p>
  //             </>
  //           );
  //         })}
  //       </>
  //     );
  //   };

  //   var pins = pinnedData;
  //   var ids: any = [];
  //   // var storyNames = "";
  //   pins.forEach((pin: any, index: number) => {
  //     ids.push(pin.id);
  //     // storyNames = storyNames + pin["Story Name"] + "\n";
  //   });
  //   var button = (
  //     <Button
  //       type="primary"
  //       className="green-button flex items-center"
  //       onClick={() => {
  //         setSkeletonLoader(true);
  //       }}
  //     >
  //       Add to{" "}
  //       {pins.length === 1 ? pins[0]["Story Name"] : `${pins[0]["Story Name"]} +${pins.length - 1}`}
  //     </Button>
  //   );
  //   var dropdown = (
  //     // <Dropdown overlay={menu}>
  //     <Button
  //       type="primary"
  //       className="green-button flex items-center pl-1"
  //       style={{ marginLeft: "1px" }}
  //       icon={icons.find((l) => l.id === "ArrowDown")?.icon}
  //       onClick={() => {
  //         setAddStoryModal(true);
  //         setStoryChange(false);
  //       }}
  //     ></Button>
  //     // </Dropdown>
  //   );
  //   if (pins.length === 1) {
  //     return (
  //       <div className="flex">
  //         {button}
  //         {dropdown}
  //       </div>
  //     );
  //   } else {
  //     return (
  //       <div className="flex">
  //         <Tooltip placement="top" title={<TooltipData />}>
  //           {button}
  //         </Tooltip>
  //         {dropdown}
  //       </div>
  //     );
  //   }
  // };

  const showSearchResults = (e: any) => {
    var exist = [...props.fetchedStoryData];
    console.log(exist);
    exist = props.fetchedStoryData.filter((story: any, index: number) =>
      story["Story Name"].toLowerCase().includes(e.target.value)
    );
    console.log(exist);
    setAddToStory(exist);
  };

  // const onFinish = (values: any) => {
  //   if (props.addNewStory) {
  //     props.addNewStory(values);
  //   }
  //   handleCreateCancel();
  // };

  const [defaultPin, setDefaultPin] = useState(false);

  useEffect(() => {
    console.log(rowId);
    console.log(rowId);
  }, []);

  const [storyDialogues, setStoryDialogues] = React.useState([]);
  const getNotAddedTranscriptDetails = (propsData: any, stories: any) => {
    // console.log("Values on adding transcript to story", propsData, stories, props);
    let arrForSelected: any = [];

    let listData: any = props.transcriptData
      ? props.transcriptData
      : props.dataForNegativeSampling;

    stories.map((s: any) => {
      let index: any;
      if (props.data) {
        index = listData.findIndex((element: any) => {
          if (element.callId === propsData.callId) {
            return true;
          }

          return false;
        });
      }

      let selectedIndex = index;
      if (index === 0) {
        let objSelected: any = {};
        objSelected["selected_call_id"] = propsData.callId;
        objSelected["selected_dialogue_no"] = propsData.dialogueNo;
        objSelected["source"] = propsData.source;

        objSelected["selected_index"] = selectedIndex;
        objSelected["story_id"] = s;
        objSelected["previous_call_id"] = null;
        objSelected["previous_dialogue_no"] = null;
        objSelected["previous_index"] = null;
        //@ts-ignore
        objSelected["search_query"] =
          propsData.source === 1 ? props.searchString : "";
        arrForSelected.push(objSelected);
      }
      console.log("----------------------------------", props.searchString);

      while (index !== 0) {
        let objSelected: any = {};
        objSelected["selected_call_id"] = propsData.callId;
        objSelected["selected_dialogue_no"] = propsData.dialogueNo;
        objSelected["source"] = propsData.source;

        objSelected["selected_index"] = selectedIndex;
        objSelected["story_id"] = s;

        let gotPreviousStory: any = false;

        while (gotPreviousStory !== true && index > 0) {
          --index;
          let flag: any = false;

          if (listData[index].storiesAdded.length !== 0) {
            listData[index].storiesAdded.map((story: any) => {
              if (story.storyId === s) {
                flag = true;
              }
            });

            if (flag === true) {
              gotPreviousStory = false;
            } else {
              gotPreviousStory = true;
            }
          } else {
            gotPreviousStory = true;
          }
        }
        objSelected["previous_call_id"] = listData[index].callId;
        objSelected["previous_dialogue_no"] = listData[index].dialogueNo;
        objSelected["previous_index"] = index;
        //@ts-ignore
        // objSelected["search_query"] = propsData.source === 1 ? context.searchWord : "";
        objSelected["search_query"] =
          propsData.source === 1 ? props.searchString : "";

        // console.log("-----------------------------------------------", index, context.searchWord);
        // }
        arrForSelected.push(objSelected);
      }
    });

    setStoryDialogues(arrForSelected);
    console.log("objToSend", arrForSelected);
    props.data.storyDialogues = [...arrForSelected];
  };

  return (
    <div>
      {skeletonLoader ? (
        <div className="px-8 pb-4 pt-8  border-b">
          <Skeleton active></Skeleton>
        </div>
      ) : (
        <div className="px-8 pb-4 pt-8  border-b">
          <div>
            <div
              className="pb-2 font-medium text-base cursor-pointer text-text-teal w-max"
              onClick={async () => {
                setLoadFullTranscript(true);
                setIsModalVisible(true);
                const transData: any =
                  await SearchServiceHelper.getFullTranscript(
                    props.data.s3path ? props.data.s3path : ""
                  );
                // console.log("Fetched : ", props.data.s3path);
                setFullTranscriptData(transData);
                setLoadFullTranscript(false);
              }}
            >
              <span>
                {[
                  ["Good", "green", "High"],
                  ["Bad", "red", "Low"],
                  ["Average", "yellow", "Medium"],
                  ["Positive", "green"],
                  ["Negative", "red"],
                  ["Neutral", "Default"],
                ]
                  .filter(d => (props.data?.tags.includes(d[0])))
                  //.filter((d) => d[0] === props.data?.tags[0])
                  .map((tag) => (
                    <Tag className="font-normal mr-4 text-xs" color={tag[1]}>
                      {tag[2] || tag[0]}
                    </Tag>
                  ))}
              </span>
              {props.data?.name}

            </div>
            {/* {(props.expanded || props.showTags) && (
              <div className="flex justify-start items-center">
                <div className="py-2">
                  <Tags data={props.data?.tags || []} />
                </div>
                {!props.hideProgressBar ? (
                  <div className="flex justify-start items-center text-text-gray-50 ">
                    {icons.find((l) => l.id === "Time")?.icon}
                    <div className="w-60">
                      <ProgressBar
                        start={props.data?.dialogueAppearedAt || 0}
                        total={props.data?.transcriptLength || 0}
                        duration={props.data?.dialogueLength || 0}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            )} */}
            <div className="text-base pb-2">
              {props.data.transcripts.map((detail: any, index: number) => {
                if (!showMore && (index == 0 || index == 4)) return <></>;
                if (props.data.dialogueNo !== detail.dialogueNo)
                  return (
                    <p className="text-text-gray-40 px-0 py-1">
                      {`${detail.speaker} : ${detail.text}`}
                    </p>
                  );
                else
                  return (
                    <p className="text-text-gray-90 px-0 py-1">
                      {`${detail.speaker} : ${detail.text}`}
                    </p>
                  );
              })}
              {console.log(props)}

              <div
                className="text-text-teal font-normal cursor-pointer w-max"
                onClick={() => {
                  setShowMore(!showMore);
                }}
              >
                {showMore ? <>Show less</> : <>Show more</>}
              </div>
            </div>
          </div>
          {context.userType !== "Business" ? <div></div> : null}
        </div>
      )}
      <Modal
        title={props.data?.name}
        width={600}
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        className="modal-footer-left text-left"
        footer={[
          <Button
            type="primary"
            className="green-button items-start"
            onClick={handleCancel}
          >
            Close
          </Button>,
        ]}
      >
        {loadFullTranscript ? (
          <div>
            <Skeleton active></Skeleton>
            <Skeleton active></Skeleton>
            <Skeleton active></Skeleton>
          </div>
        ) : (
          <TranscriptModalData
            id={props.data?.id || 0}
            fullTranscriptData={fullTranscriptData}
            keywords={props.keywords}
          />
        )}
      </Modal>
      <Modal
        title={null}
        width={1000}
        style={{ top: 80 }}
        open={addStoryModal}
        // onOk={handleStoryOk}
        // onCancel={handleStoryCancel}
        className="modal-footer-left text-left"
        footer={[
          <Button
            type="primary"
            className="green-button items-start"
            onClick={async () => {
              var stories: any = [];
              if (storyChange === true) {
                setSkeletonLoader(true);
                addStates.forEach((pin, index) => {
                  if (pin) {
                    stories.push(cStoryList[index].id);
                  }
                });
                getNotAddedTranscriptDetails(props.data, stories);
                // setids(false);
              }
              // handleStoryCancel();
            }}
          >
            Update
          </Button>,
          // <Button type="default" onClick={handleStoryCancel}>
          //   Cancel
          // </Button>,
        ]}
      >
        <p className="mt-2 mb-4 text-2xl font-semibold">Select Story</p>
        <p className="text-base mb-6" style={{ color: "#9C9AA1" }}>
          You can add the transcript to a story by selecting an existing story
          from the list below or by creating a new story and adding it to the
          new story.
        </p>
        {/* {props.fetchedStoryData ? (
          <div>
            <div className="mt-6">
              <div className="flex items-center">
                <p className="text-base font-semibold mr-4">
                  Existing Stories: {addToStory.length}
                </p>
                <div>
                  <Input.Search
                    className="flex w-96 input-search"
                    placeholder="Search Story"
                    onChange={(e) => {
                      console.log(e.target.value);
                      showSearchResults(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div
              className="flex items-center mt-7 cursor-pointer"
              onClick={() => {
                setCreateStoryModal(true);
                // handleStoryCancel();
              }}
            >
              <div className="mr-2">
                {icons.find((l) => l.id === "Plus Filled Green")?.icon}
              </div>
              <div>
                <p
                  className="font-semibold text-base"
                  style={{ color: "#27A6A4" }}
                >
                  Create New Story
                </p>
              </div>
            </div>
            <div>
              <ReportTypes
                hideShadow
                flexContent="flex-start"
                hideRibbon
                minHeight="300px"
                chartType={ReportTypesComponentInterface.chartType.simpleTable}
                series={[]}
                chartConfig={{
                  table: {
                    noPagination: true,
                    maxHeight: 350,
                    tableData: {
                      data: transformTableData(
                        addToStory.length > 0 ? addToStory : [],
                        []
                      ).data,
                      column: grouped_columns,
                    },
                    noSearchField: true,
                  },
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )} */}
      </Modal>
      {/* <Modal
        title="Create New Story"
        width={900}
        open={createStoryModal}
        destroyOnClose={true}
        onOk={handleCreateOk}
        onCancel={handleCreateCancel}
        className="modal-footer-left text-left"
        footer={null}
      >
        <p className="text-base" style={{ color: "#9C9AA1" }}>
          Please complete this section to create new story.
        </p>
        <div className="w-96">
          <Form
            name="newStory"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            layout="vertical"
            // onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="Story Name"
              label="Story Name"
              className="mt-4"
              rules={[
                {
                  required: true,
                  message: "Story name is required!",
                },
              ]}
            >
              <Input placeholder="Please enter a story name" />
            </Form.Item>
            <div>
              <Form.Item
                name="Story Type"
                label="Story Type"
                rules={[
                  {
                    required: true,
                    message: "Story Type is Required!",
                  },
                ]}
              >
                <Select placeholder="Please Enter a Story Type" allowClear>
                  {storyTypes.map((story:any) => (
                    <Select.Option value={story} key={story}>
                      {story}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <div className="flex">
              <Form.Item>
                <Button type="primary" htmlType="submit" className="green-button items-start">
                  Create
                </Button>
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="default" onClick={handleCreateCancel}>
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </Modal> */}
    </div>
  );
}

import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import React, { useCallback, useMemo, useState } from "react";
import Button from "antd/lib/button";
import Upload from "antd/lib/upload";
import message from "antd/lib/message";
import "./index.css";
import { downloadMetaFile } from "../../../SearchEngine/ServiceHelpers/study";

export default function ManageMetadata() {
	var mailTo = "mailto:Kural_support@zs.com";

	const [fileList, setFileList] = useState<any[]>([]);
	const [uploadStatus, setUploadStatus] = useState("idle"); // Possible values: 'idle', 'uploading', 'done', 'error'

	const handleDownloadTemplate = useCallback(async () => {
		const currentStudyId = sessionStorage.getItem("studyId");
		const loadingMessage = message.loading(`Downloading`, 1000);
		try {
			if (currentStudyId === undefined) throw new Error("Study ID is undefined.");

			const fileName = `MetaData_Template_${currentStudyId}.xlsx`;
			// const s3Path = `${studyId}/node_graph/excel/node_graph.xlsx`;
			const response: any = await downloadMetaFile(parseInt(currentStudyId ?? "-1", 10));

			if (response) {
				loadingMessage();
				// Automatically downloads the document
				const url = window.URL.createObjectURL(new Blob([response]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();

				message.success("Template file downloaded successfully.");
			} else {
				loadingMessage();
				message.error("Error downloading template file. Please try again later.");
			}
		} catch (error) {
			loadingMessage();
			console.error("Error downloading template file:", error);
			message.error("Error downloading template file. Please try again later.");
		} finally {
			loadingMessage();
		}
	}, []);

	let filePath: string = process.env.REACT_APP_API_URL + "Study/UploadMetadataToS3?s3path=Metadata/Incoming";
	filePath = filePath.replace(/\s/g, "_");

	const handleUploadChange = useCallback((info: any) => {
		setFileList(info.fileList);

		if (info.file.status === "done") {
			setUploadStatus("done");
			message.success(`${info.file.name} file uploaded successfully.`);
		} else if (info.file.status === "uploading") {
			setUploadStatus("uploading");
			// message.loading(`${info.file.name} file is uploading.`, 10);
		} else if (info.file.status === "removed") {
			message.success(`${info.file.name} file removed successfully.`);
		} else if (info.file.status === "error") {
			setUploadStatus("error");
			message.error(`${info.file.name} file upload failed.`);
			console.error(`Error uploading file ${info.file.name}:`, info.file.error); // Log the error
		}
	}, []);

	const beforeUploadHandler = useCallback((file: any) => {
		const isValidContentType = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"; //XLSX

		if (!isValidContentType) {
			message.error(`Invalid file type: ${file.type}. Please upload an XLSX file.`);
        	throw new Error(`Invalid file type: ${file.type}. Please upload an XLSX file.`);
			// return false;
		}

		const isValidSize = file.size <= 209715200; // 200MB in bytes
		if (!isValidSize) {
			message.error("File size exceeds the maximum allowed (200MB). Please upload a smaller file.");
			return false;
		}

		return true;
	}, []);

	// Define a function to convert a file to base64 string for previewing
	const getBase64 = useCallback((file: any) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		}), []);

	const handlePreview = useCallback(async (file: any) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		// Do something with the file preview, e.g., show it in a modal.
	}, [getBase64]);

	const handleRemove = useCallback((file: any) => {
		setUploadStatus("idle");
		setFileList((prevFileList) => prevFileList.filter((f) => f.uid !== file.uid));
		// Perform additional actions when a file is removed.
	}, []);

	const headers = useMemo(() => ({
        "X-Api-Key": process.env.REACT_APP_API_KEY ?? "0",
    }), []);

	return (
		<div className="p-8">
			<div>
				<p className="font-bold text-xl">Instruction Set</p>
				<p className="text-base my-5">
					1. Download the latest template file by clicking the download button
					<br /> 2. Add columns to the table for new metadata fields to be added, do not move the first 4 columns. The column header is the metadata field and fill the
					value for each respondent in the cells below.
					<br /> 3. Re-use the existing columns as far as possible, this will smoothen the cross-study analysis by unifying metadata fields and values
					<br /> 4. Once the metadata file is edited, upload the file using the Upload button.
				</p>
				<p>
					Please reach out to the Kural team (
					<Button
						type="link"
						className="text-text-teal"
						style={{ padding: 0 }}
						onClick={(e) => {
							window.location.href = mailTo;
							e.preventDefault();
						}}>
						Kural_support@zs.com
					</Button>
					) for an issue uploading metadata for your studies.​
				</p>
			</div>
			<div className="flex justify-between my-10 w-3/12">
				<Button type="primary" className="green-button flex items-center" icon={<DownloadOutlined />} disabled={false} onClick={() => handleDownloadTemplate()}>
					Download Template
				</Button>
				<Upload
					// customRequest={handleUpload}
					action={filePath}
					headers={headers}
					withCredentials={true}
					beforeUpload={beforeUploadHandler}
					onChange={handleUploadChange}
					onPreview={handlePreview}
					onRemove={handleRemove}
					fileList={fileList}
					accept=".xlsx"
					showUploadList={false}
					listType="text"
					disabled={uploadStatus === "uploading"}
					maxCount={1}>
					<Button type="primary" className="green-button flex items-center ml-5" icon={<UploadOutlined />} disabled={false}>
						Upload Metadata
					</Button>
				</Upload>
			</div>
		</div>
	);
}

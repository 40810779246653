import { request } from "./../App";

export const getAllStories = async (obj: {
	storyId?: number;
  studyId?: number | string[] | undefined;
	speaker?: string | undefined;
	startDate?: any;
	endDate?: any;
	// showResults?: boolean | undefined;
}) => {
  let str = "";
  const filters: any = {};
  Object.entries(obj).map(([key, value]) => {
    // console.log("Key, Val", key, value);
    if ((Array.isArray(value) && value.length > 0) || typeof value === "boolean") filters[key] = value;
    else if (!Array.isArray(value) && value) filters[key] = value;
  });
  const fetchUrl = `originalStory/GetStoryRecommendations?${str}`;
  const data = await request
    .post(fetchUrl, filters, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getStoryStatus  = async (storyId: number, storyType?: number ) => {
	const fetchUrl = storyType === undefined ?
	 `originalStory/GetStoryStatus?storyId=${storyId}`
	 : `originalStory/GetStoryStatus?storyId=${storyId}&storyType=${storyType}`;
	const data = await request
		.get(fetchUrl, { withCredentials: true })
		.then((response) => {
			// console.log(response.status);
			return response.data;
		})
		.catch((err) => {
			// console.log("Error : ", err.message);
			return null;
		});
	return data;
};
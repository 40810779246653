import * as AnnotatedSelectionsService from "./../../../services/annotatedSelections";

export const getAnnotations: (id: string) => Promise<unknown> = async (id: string) => {
  const data = await AnnotatedSelectionsService.getAnnotations(id);

  if (data === null || data === undefined) return null;
  return data;
};

export const deleteAnnotation: (id: number, storyId: string, elasticSearchId: string) => Promise<unknown> = async (id, storyId, elasticSearchId) => {
  const data = await AnnotatedSelectionsService.deleteAnnotations(id, storyId, elasticSearchId);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getHighlights: (id: number) => Promise<unknown> = async (id) => {
  const data = await AnnotatedSelectionsService.getHighlights(id);

  if (data === null || data === undefined) return null;
  return data;
};
export const editHighlights: (editData: { id: number; highlightText: string; Tags: string[]; highlightedString: string }) => Promise<unknown> = async (editData) => {
  const data = await AnnotatedSelectionsService.editHighlights(editData);

  if (data === null || data === undefined) return null;
  return data;
};

export const getOriginalStorybyId: (storyId: string) => Promise<unknown> = async (
	storyId
  ) => {
	const data = await AnnotatedSelectionsService.getOriginalStorybyId(storyId);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
  };
  
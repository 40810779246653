import { icons } from "../../GlobalThemes";

export default function NotFound() {
	return (
		<>
			<div className="w-screen h-96 p-4">
				<div className="h-full flex flex-col justify-center items-center">
					<div className="text-text-teal">
						{icons.find((l) => l.id === "Alert")?.icon}
					</div>
					<div className="text-xl">
						You are not authorized to view this resource.
					</div>
				</div>
			</div>
		</>
	);
}

import React, { useEffect, useState } from "react";
import {
  useNavigate,
  useParams,
  createSearchParams,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import DisconSummary from "../Components/DisconSummary";
import DisconPredictions from "../Components/DisconPredictions";
import DriversSummary from "../Components/DriversSummary";

export interface filterTabs {
  tab: string;
  filter: string[];
  subtab?: {
    name: string;
    filters: string[];
  }[];
}

/**
 * This page is the main program overview tab in the dashboard page. It also contains the routes to navigate between Calls, Reach and Agents Activity page
 *
 *  @param filterActiveStatus - Props used to pass the active filters as true
 *  @param setFilterActiveStatus - Function to set the status of active filters as true
 *  @param filters - This props is used to pass the filters in cards
 *
 * @returns the navigation to navigate between different subtabs
 */

export default function Discon() {
  const { subsubtab } = useParams();
  const [subTab, setSubTab] = useState("");
  var filters = {
    Timeframe_Display: undefined,
    Start_Date: "",
    End_Date: "",
    Call_Type: undefined,
    Therapy_Status: undefined,
    Call_Intent: undefined,
    Adherence: undefined,
  };
  let navigate = useNavigate();
  console.log("33333333333 Discon", subsubtab);
  React.useEffect(() => {
    if (subsubtab === undefined) navigate({ pathname: "Discon_Predictions" });
  });
  const components = [<DriversSummary />, <DisconPredictions />, <DisconSummary />];
  return (
    <>
      {subsubtab === "Discon_Predictions" ? (
        <DisconPredictions />
      ) : subsubtab === "Drivers_Summary" ? (
        <DriversSummary />
      ) : (
        subsubtab === "Discon_Summary" && <DisconSummary />
      )}
    </>
  );
}

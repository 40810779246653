export const Icon = () => (
	<svg
		width="22"
		height="21"
		viewBox="0 0 22 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M9.89985 0.0999756L19.7988 1.51498L21.2128 11.415L12.0208 20.607C11.8333 20.7944 11.579 20.8998 11.3138 20.8998C11.0487 20.8998 10.7944 20.7944 10.6068 20.607L0.706849 10.707C0.519378 10.5194 0.414063 10.2651 0.414062 9.99998C0.414063 9.73481 0.519378 9.4805 0.706849 9.29298L9.89985 0.0999756ZM10.6068 2.22198L2.82785 9.99998L11.3138 18.485L19.0918 10.707L18.0318 3.28198L10.6068 2.22198ZM12.7268 8.58598C12.3517 8.21069 12.141 7.70176 12.1411 7.17112C12.1411 6.90838 12.1929 6.64822 12.2935 6.40549C12.3941 6.16277 12.5415 5.94223 12.7273 5.75648C12.9132 5.57072 13.1338 5.42338 13.3765 5.32288C13.6193 5.22238 13.8795 5.17067 14.1422 5.17072C14.6728 5.17081 15.1817 5.38169 15.5568 5.75698C15.932 6.13226 16.1427 6.6412 16.1426 7.17183C16.1425 7.70246 15.9316 8.21133 15.5563 8.58648C15.1811 8.96162 14.6721 9.17233 14.1415 9.17223C13.6109 9.17214 13.102 8.96126 12.7268 8.58598Z"
			fill="#B2B0B6"
		/>
	</svg>
);

import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Table } from "antd";

export default function CSVRender(props: { data: any }) {
  const [csvData, setCsvData] = useState<any[]>([]);

  useEffect(() => {
    Papa.parse(props.data, {
      complete: (result: any) => {
        setCsvData(result.data);
      },
      header: true, // Treat the first row as headers
      dynamicTyping: true, // Automatically convert numeric values to numbers
    });
  }, []);

  const columns = Object.keys(csvData[0] || {}).map((key) => ({ title: key, dataIndex: key }));

  return (
      <Table dataSource={csvData} columns={columns}/>
  );
}

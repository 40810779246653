import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Drawer, Form, Select, Input, Spin, InputNumber, InputRef, Tag, Tooltip, message } from "antd";
import { ColumnsType } from "antd/es/table";
import { LoadingOutlined, PlusCircleFilled, PlusOutlined } from "@ant-design/icons";
import "./index.css";
import * as StudyServiceHelper from "../../../SearchEngine/ServiceHelpers/study";
import { jsonData, jsonData1, jsonData2 } from "../../../SearchEngine/data";
// import TextArea from "antd/lib/input/TextArea";

interface FlattenDataType {
  key: React.Key;
  conceptType: string;
  conceptCategory: string;
  concept: string;
  keywords?: string[];
  description?: string;
}

// interface PayloadDataType {
// 	study_id: number,
// 	s3_path: string,
// 	taxonomyJson: any[],
// }

interface EditableCellProps {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType?: "number" | "text";
  record: FlattenDataType;
  index?: number;
  children: React.ReactNode;
  colSpan?: number;
}

export default function ConfigureTaxonomy() {
  const [form] = Form.useForm();

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [taxonomyData, setTaxonomyData] = useState(jsonData);
  const [editingKey, setEditingKey] = useState(false);
  const [currentDrawerRecord, setCurrentDrawerRecord] = useState<FlattenDataType | null>(null);
  const [inputVisible, setInputVisible] = useState(false);
  const [keywordInputValue, setKeywordInputValue] = useState("");
  const [descriptionInputValue, setDescriptionInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);
  const [originalPayloadData, setOriginalPayloadData] = useState<any>();
  const [dataSource, setDataSource] = useState<FlattenDataType[]>([]);
  // const [canSaveData, setCanSaveData] = useState<boolean>(true);
  // const [tempData, setTempData] = useState<FlattenDataType[]>([]);
  let tempData = useRef<any>([]);
  const { Option } = Select;

  // Generate a key for each item to use as a React key
  let uniqueKey = 0;
  const generateKey = () => {
    uniqueKey += 1;
    return uniqueKey;
  };

  const flattenData = (data: any[], result: FlattenDataType[] = [], parent?: FlattenDataType, level = 0): FlattenDataType[] => {
    data.forEach((item) => {
      const key = generateKey();
      const newData: FlattenDataType = {
        key,
        conceptType: level === 0 ? item.name : parent?.conceptType || "",
        conceptCategory: level === 1 ? item.name : parent?.conceptCategory || "",
        concept: level === 2 ? item.name : "",
        keywords: item.keywords || [],
        description: item.description || "",
      };

      if (item.children) {
        flattenData(item.children, result, newData, level + 1);
      } else {
        result.push(newData);
      }
    });

    // console.log("Flattened Data: ", result);
    return result;
  };

  const createNestedData = (data: FlattenDataType[]) => {
    const transformedData: any = [];

    data.forEach((item: FlattenDataType) => {
      const conceptTypeIndex = transformedData.findIndex((category: any) => category.name === item.conceptType);

      if (conceptTypeIndex === -1) {
        // Concept Type not found, create a new one
        transformedData.push({
          name: item.conceptType,
          children: [
            {
              name: item.conceptCategory,
              children: item.concept
                ? [
                    {
                      name: item.concept,
                      keywords: item.keywords,
                      description: item.description,
                    },
                  ]
                : [],
            },
          ],
        });
      } else {
        // Concept Type found, check if Concept Category exists
        const conceptCategoryIndex = transformedData[conceptTypeIndex].children.findIndex((category: any) => category.name === item.conceptCategory);

        if (conceptCategoryIndex === -1) {
          // Concept Category not found, create a new one
          transformedData[conceptTypeIndex].children.push({
            name: item.conceptCategory,
            children: item.concept
              ? [
                  {
                    name: item.concept,
                    keywords: item.keywords,
                    description: item.description,
                  },
                ]
              : [],
          });
        } else {
          // Concept Category found, add Concept if it exists
          if (item.concept) {
            transformedData[conceptTypeIndex].children[conceptCategoryIndex].children.push({
              name: item.concept,
              keywords: item.keywords,
              description: item.description,
            });
          }
        }
      }
    });

    // console.log("Hierarchical Data: ", transformedData);
    return transformedData;
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#27a6a4",
        alignSelf: "center",
        flex: 1,
      }}
      spin
    />
  );
  const TypeVals = new Set();
  const CatgVals = new Set();
  const TypeCatgVals = new Set();

  useEffect(() => {
    fetchTaxonomyData();
  }, []);

  // API Request to fetch SM Wave Table Data
  async function fetchTaxonomyData() {
    try {
      setLoading(true);
      const currentStudyId = sessionStorage.getItem("studyId");
      // console.log("Study Id for API request is -> ", currentStudyId);

      if (currentStudyId) {
        const data: any = await StudyServiceHelper.getTaxonomy(parseInt(currentStudyId, 10));
        // DUMMY DATA HERE
        // const data: any = jsonData;

        if (data) {
          setOriginalPayloadData(data);
          setDataSource(flattenData(data.taxonomyJson));
          // setDataSource(flattenData(data));
          setLoading(false);
          // message.success("Successfully fetched Taxonomy data.");
          // console.log("Fetched Taxonomy data -> ", data);
        } else {
          console.error("Fetch Taxonomy data API call failed.");
          message.error("Error fetching Taxonomy data. Please try again.");
        }
      } else {
        console.error("Study Id not found.");
        // console.log("Study Id not found in sessionStorage.");
      }
    } catch (error) {
      console.error("Error fetching Taxonomy data:", error);
      message.error("Error fetching Taxonomy data. Please try again.");
    } finally {
      setLoading(false);
    }
  }

  // API Request to update taxonomy table content
  async function updateTaxonomyData(data: FlattenDataType[], action: string) {
    try {
      // console.log("Payload Data before transformation: ", action, data);
      let taxonomyHierarchicalData: any[] = createNestedData(data);
      // let taxonomyHierarchicalData: any[] = data;
      // if (action === "save") {
      // 	taxonomyHierarchicalData = createNestedData(data);
      // }

      const updatedS3Path = originalPayloadData.s3_path.replace(/v(\d+)/, (match: any, version: any) => `v${parseInt(version) + 1}`);
      // console.log("Updated S3 Path: ", updatedS3Path);
      setOriginalPayloadData({ ...originalPayloadData, s3_path: updatedS3Path });

      // console.log("Payload Data after transformation: ",action, taxonomyHierarchicalData);

      // const currentStudyId = sessionStorage.getItem("studyId");
      const structuredPayload: any = {
        // study_id: currentStudyId,
        study_id: originalPayloadData.study_id,
        // s3_path: "client-vapt/taxonomy/test/1",
        s3_path: updatedS3Path,
        taxonomyJson: taxonomyHierarchicalData,
      };

      const response: any = await StudyServiceHelper.saveTaxonomy(structuredPayload);
      // const response: any = true;

      if (response) {
        // message.success("Taxonomy data updated successfully");
        return response;
      } else {
        message.error("Failed to update taxonomy data");
        return response;
      }
    } catch (error) {
      console.error("Error updating taxonomy data:", error);
      message.error("Error updating taxonomy data. Please try again later.");
      return false;
    }
  }

  const handleEditableCellChange = (value: any, key: React.Key, dataIndex: any) => {
    // if (value === null || value === undefined || value === "") {
    // 	message.error("Name cannot be left blank.");
    // 	// message.error("Concept Type already exists, please use a unique name.");
    // } else {
    // console.log("Inside handleEditableCellChange value: ", value);
    // console.log("Inside handleEditableCellChange data: ", dataSource);
    let otherDataIndices: number[] = [];
    let isUnique: boolean = false;
    let originalValue: string = "";
    const dataIndexIndex = dataSource.findIndex((item: any) => item.key === key);

    if (dataIndexIndex !== -1) {
      if ((value === null || value === undefined || value === "") && dataIndex != "concept") {
        message.error("Name cannot be left blank.");
        // message.error("Concept Type already exists, please use a unique name.");
      } else if (dataIndex === "conceptType") {
        originalValue = dataSource[dataIndexIndex].conceptType;
        const conceptTypeValue = dataSource[dataIndexIndex].conceptType;

        // Find data indices of other objects with the same concept type value
        otherDataIndices = dataSource.reduce((indices: number[], item: any, currentIndex: number) => {
          if (item.conceptType === conceptTypeValue && currentIndex !== dataIndexIndex) {
            indices.push(currentIndex);
          }
          return indices;
        }, []);
        otherDataIndices.push(dataIndexIndex);

        isUnique = !dataSource.some((item: FlattenDataType, index: number) => {
          return item.conceptType === value && !otherDataIndices.includes(index);
        });

        if (!isUnique) {
          message.error("Concept Type already exists, please use a unique name.");
          // setCanSaveData(false);
          // return;
        }
      } else if (dataIndex === "conceptCategory") {
        originalValue = dataSource[dataIndexIndex].conceptCategory;
        const conceptTypeValue = dataSource[dataIndexIndex].conceptType;
        const conceptCategoryValue = dataSource[dataIndexIndex].conceptCategory;

        // Find data indices of other objects with the same concept type value
        otherDataIndices = dataSource.reduce((indices: number[], item: any, currentIndex: number) => {
          if (item.conceptType === conceptTypeValue && item.conceptCategory === conceptCategoryValue && currentIndex !== dataIndexIndex) {
            indices.push(currentIndex);
          }
          return indices;
        }, []);
        otherDataIndices.push(dataIndexIndex);

        isUnique = !dataSource.some((item: FlattenDataType, index: number) => {
          return item.conceptType === conceptTypeValue && item.conceptCategory === value && !otherDataIndices.includes(index);
        });

        if (!isUnique) {
          message.error("Concept Category already exists, please use unique name.");
          // setCanSaveData(false);
          // return;
        }
        // else {
        // 	setCanSaveData(true);
        // }
      } else if (dataIndex === "concept") {
        originalValue = dataSource[dataIndexIndex].concept;
        const conceptTypeValue = dataSource[dataIndexIndex].conceptType;
        const conceptCategoryValue = dataSource[dataIndexIndex].conceptCategory;

        otherDataIndices.push(dataIndexIndex);

        isUnique = !dataSource.some((item: FlattenDataType, index: number) => {
          return item.conceptType === conceptTypeValue && item.conceptCategory === conceptCategoryValue && item.concept === value && !otherDataIndices.includes(index);
        });

        if (!isUnique) {
          message.error("Concept already exists, please use unique name.");
          // setCanSaveData(false);
          // return;
        }
      }

      const newData = JSON.parse(JSON.stringify(dataSource));
      otherDataIndices.forEach((index: number) => {
        newData[index][dataIndex] = isUnique ? value : originalValue;
      });
      setDataSource(newData);
    }
  };

  const EditableCell: React.FC<EditableCellProps> = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
    return (
      <>
        {editing ? (
          <Input
            defaultValue={
              //@ts-ignore
              record[dataIndex]
            }
            key={record.key}
            style={{ width: "80%" }}
            onBlur={(e) => handleEditableCellChange(e.target.value, record.key, dataIndex)}
          />
        ) : (
          children
        )}
      </>
    );
  };

  const handleEdit = (action: any) => {
    // console.log("Handle edt called", action);
    // tempData.current => backup
    if (action === "edit") {
      // console.log("Edit Action: ", action);
      setEditingKey(true);
      // const t = JSON.parse(JSON.stringify(dataSource));
      // setTempData(t); // in case user cancels edit
      tempData.current = JSON.parse(JSON.stringify(dataSource));
    } else if (action === "cancel") {
      // console.log("Edit Action: ", action);
      setEditingKey(false);
      // setCanSaveData(true);
      setDataSource(tempData.current);
      tempData.current = [];
    } else if (action === "save") {
      // console.log("Save Action: ", action);
      // setDataSource(tempData.current);
      updateTaxonomyData(dataSource, action);
      tempData.current = [];
      setEditingKey(false);
    }

    // setEditingKey((prevEditingKey) => !prevEditingKey);
  };

  const isEditing = (record: FlattenDataType) => editingKey;

  function generateUniqueKey(initialData: FlattenDataType[]): number {
    let randomKey = Math.floor(Math.random() * (9999 - 0 + 1)) + 0;

    if (initialData != null) {
      while (initialData.some((wave) => wave.key === randomKey)) {
        randomKey = Math.floor(Math.random() * (9999 - 0 + 1)) + 0;
      }
    }
    return randomKey;
  }

  const addNewConceptType = async () => {
    try {
      setLoading(true);
      // const newConceptType = data!=null?`New Concept Type ${data.length + 1}`:`New Concept Type ${1}`;
      const newConceptType =
        dataSource && dataSource.length > 0
          ? (() => {
              let conceptTypeNumber = 2;
              let newName = `New Concept Type ${conceptTypeNumber}`;

              while (dataSource.some((item) => item.conceptType === newName)) {
                conceptTypeNumber++;
                newName = `New Concept Type ${conceptTypeNumber}`;
              }

              return newName;
            })()
          : "New Concept Type 1";

      const newData = [
        {
          key: dataSource != null ? generateUniqueKey(dataSource) : 1,
          conceptType: newConceptType,
          conceptCategory: "<Add Data>",
          concept: "<Add Data>",
          keywords: [],
          description: "",
        },
      ];

      const updatedData = dataSource != null ? [...dataSource, newData[0]] : [newData[0]];

      // console.log("Inside addNewWave function. New Data value -> ", updatedData);

      let response: boolean = await updateTaxonomyData(updatedData, "addConceptType");

      if (response) {
        setDataSource(updatedData);
        setLoading(false);
        fetchTaxonomyData();
        // setTempData(null);
        // fetchManageStudyData();
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error("Save failed:", err);
      setLoading(false);
    }
  };

  const addNewConceptCategory = async (conceptType: string) => {
    try {
      setLoading(true);
      const newConceptCategory =
        dataSource && dataSource.length > 0
          ? (() => {
              let conceptCatNumber = 2;
              let newName = `New Concept Category ${conceptCatNumber}`;
              while (dataSource.some((item) => item.conceptCategory === newName)) {
                conceptCatNumber++;
                newName = `New Concept Category ${conceptCatNumber}`;
              }

              return newName;
            })()
          : "New Concept Category 1";

      const newData = [
        {
          key: dataSource != null ? generateUniqueKey(dataSource) : 1,
          conceptType: conceptType,
          conceptCategory: newConceptCategory,
          concept: "<Add Data>",
          keywords: [],
          description: "",
        },
      ];

      const updatedData = dataSource != null ? [...dataSource, newData[0]] : [newData[0]];
      // console.log("Inside addConCat function. New Data value -> ", updatedData);

      let response: boolean = await updateTaxonomyData(updatedData, "addConceptCategory");

      if (response) {
        setDataSource(updatedData);
        setLoading(false);
        fetchTaxonomyData();
        // setTempData(null);
        // fetchManageStudyData();
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error("Save failed:", err);
      setLoading(false);
    }
  };

  const addNewConcept = async (conceptCategory: string, conceptType: string) => {
    try {
      setLoading(true);
      const newConcept =
        dataSource && dataSource.length > 0
          ? (() => {
              let conceptCatNumber = 2;
              let newName = `New Concept ${conceptCatNumber}`;
              while (dataSource.some((item) => item.concept === newName)) {
                conceptCatNumber++;
                newName = `New Concept ${conceptCatNumber}`;
              }

              return newName;
            })()
          : "New Concept 1";

      const newData = [
        {
          key: dataSource != null ? generateUniqueKey(dataSource) : 1,
          conceptType: conceptType,
          conceptCategory: conceptCategory,
          concept: newConcept,
          keywords: [],
          description: "",
        },
      ];

      const updatedData = dataSource != null ? [...dataSource, newData[0]] : [newData[0]];
      // console.log("Inside addConCat function. New Data value -> ", updatedData);

      let response: boolean = await updateTaxonomyData(updatedData, "addConcept");

      if (response) {
        setDataSource(updatedData);
        setLoading(false);
        fetchTaxonomyData();
        // setTempData(null);
        // fetchManageStudyData();
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error("Save failed:", err);
      setLoading(false);
    }
  };

  const handleCopyConceptType = async () => {
    try {
      setLoading(true);
      // console.log("Data before copy: ", dataSource);
      const lastConceptName = dataSource[dataSource.length - 1].conceptType;

      // const updatedData = [...dataSource];
      const newData: FlattenDataType[] = [];
      dataSource.map((el) => {
        if (el.conceptType === lastConceptName) {
          // updatedData.push({...el,
          // 	key: generateUniqueKey(dataSource),
          // 	conceptType: lastConceptName + ' copied',
          // });
          newData.push({
            ...el,
            key: generateUniqueKey(dataSource),
            conceptType: lastConceptName + " copied",
            conceptCategory: el.conceptCategory !== "<Add Data>" ? el.conceptCategory + " copied" : "<Add Data>",
            concept: el.concept !== "<Add Data>" ? el.concept + " copied" : "<Add Data>",
            keywords: [],
            description: "",
          });
        }
      });

      const updatedData = dataSource != null ? [...dataSource, ...newData] : [...newData];
      // console.log("Inside copy function. New Data value -> ", updatedData);

      let response: boolean = await updateTaxonomyData(updatedData, "addCopy");

      if (response) {
        setDataSource(updatedData);
        setLoading(false);
        fetchTaxonomyData();
        // console.log("Post copy data: ", updatedData);
      } else {
        setLoading(false);
      }
    } catch (err) {
      console.error("Save failed:", err);
      setLoading(false);
    }
  };

  // useEffect(() => {
  // 	console.log("TAXONOMY DATA", flattenData(taxonomyData));
  // 	setDataSource(flattenData(taxonomyData));
  // }, [taxonomyData]);
  // console.log(dataSource);

  useEffect(() => {
    TypeVals.clear();
    CatgVals.clear();
  }, []);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [keywordInputValue]);

  const showDrawer = (title: string, record: any) => {
    // console.log("Inside showDrawer record: ", record);
    setCurrentDrawerRecord(record);
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleDrawerSubmit = () => {
    // console.log("Updated Tags: ", currentDrawerRecord?.keywords);

    const updatedData = dataSource.map((item) => {
      if (item.key === currentDrawerRecord?.key) {
        return {
          ...item,
          keywords: currentDrawerRecord?.keywords,
          description: currentDrawerRecord?.description,
        };
      }
      return item;
    });
    // console.log("Updated Data: ", updatedData);

    setDataSource(updatedData);
    updateTaxonomyData(updatedData, "drawerSubmit");
    // setDataSource();
    setCurrentDrawerRecord(null);
    setDrawerOpen(!drawerOpen);
  };

  const handleDeleteKeyword = (removedTag: string) => {
    const newKeywords = currentDrawerRecord?.keywords?.filter((tag) => tag !== removedTag);
    setCurrentDrawerRecord((prev: any) => ({ ...prev, keywords: newKeywords }));
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleKeywordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setKeywordInputValue(e.target.value);
  };

  const handleKeywordInputConfirm = (e: any) => {
    e.preventDefault();
    if (keywordInputValue && currentDrawerRecord?.keywords?.indexOf(keywordInputValue) === -1) {
      setCurrentDrawerRecord((prev: any) => ({ ...prev, keywords: [...prev.keywords, keywordInputValue] }));
    }
    setInputVisible(false);
    setKeywordInputValue("");
  };

  const handleDescriptionInputChange = (e: any) => {
    setDescriptionInputValue(e.target.value);
  };

  const handleDescriptionInputConfirm = (e: any) => {
    e.preventDefault();
    if (descriptionInputValue) {
      setCurrentDrawerRecord((prev: any) => ({ ...prev, description: descriptionInputValue }));
    }
    setDescriptionInputValue("");
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    // @ts-ignore
    const newKeywords = [...currentDrawerRecord.keywords];
    newKeywords[editInputIndex] = editInputValue;
    setCurrentDrawerRecord((prev: any) => ({ ...prev, keywords: newKeywords }));
    setEditInputIndex(-1);
    setKeywordInputValue("");
  };

  const columns: ColumnsType<FlattenDataType> = [
    {
      title: "Concept Type",
      dataIndex: "conceptType",
      key: "conceptType",
      width: 600,
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <EditableCell editing={isEditing(record)} dataIndex="conceptType" title="Concept Type" inputType="text" record={record} index={0}>
            <span style={{ flex: 1 }}>{text}</span>
          </EditableCell>
          {/* <span style={{ flex: 1 }}>{text}</span> */}
          <PlusCircleFilled
            onClick={() => addNewConceptCategory(record.conceptType)}
            style={{
              fontSize: "20px",
              textAlign: "left",
              color: "#27a6a4",
            }}
          />
        </div>
      ),
      onCell: (record, index) => {
        if (TypeVals.has(record.conceptType)) {
          return { rowSpan: 0 };
        } else {
          const occurCount = dataSource.filter((data) => data.conceptType === record.conceptType).length;
          TypeVals.add(record.conceptType);
          return { rowSpan: occurCount };
        }
      },
    },
    {
      title: "Concept Category",
      dataIndex: "conceptCategory",
      key: "conceptCategory",
      width: 700,
      render: (text, record, index) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <EditableCell editing={isEditing(record)} dataIndex="conceptCategory" title="Concept Category" inputType="text" record={record} index={0}>
            <span style={{ flex: 1 }}>{text}</span>
          </EditableCell>
          <PlusCircleFilled
            onClick={() => addNewConcept(record.conceptCategory, record.conceptType)}
            style={{
              fontSize: "20px",
              textAlign: "left",
              color: "#27a6a4",
            }}
          />
        </div>
      ),
      onCell: (record, index) => {
        // console.log("Inside category cell renderer: record.conceptCategory, CatgVals, record.conceptType, TypeCatgVals: ", record.conceptCategory, CatgVals, record.conceptType, TypeCatgVals, CatgVals.has(record.conceptCategory) && TypeCatgVals.has(record.conceptType));
        if (CatgVals.has(record.conceptCategory) && TypeCatgVals.has(record.conceptType)) {
          // console.log("Inside If");
          return { rowSpan: 0 };
        } else {
          if (!TypeCatgVals.has(record.conceptType)) {
            CatgVals.clear();
          }
          // console.log("Inside Else");
          const occurCount = dataSource.filter((data) => data.conceptCategory === record.conceptCategory && data.conceptType === record.conceptType).length;
          TypeCatgVals.add(record.conceptType);
          CatgVals.add(record.conceptCategory);
          // console.log("occurCount: ", occurCount);
          return { rowSpan: occurCount };
        }
      },
    },
    {
      title: "Concept",
      dataIndex: "concept",
      key: "concept",
      // width: 250,
      render: (text, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <EditableCell editing={isEditing(record)} dataIndex="concept" title="Concept" inputType="text" record={record} index={0}>
            <span style={{ flex: 1 }}>
              <a href="#" onClick={() => showDrawer(text, record)}>
                {text}
              </a>
            </span>
          </EditableCell>
          {/* <EditOutlined
						onClick={() => {
							handleEdit();
						}}
					/> */}
        </div>
      ),
    },
  ];

  return (
    <div className="p-5">
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          {/* Buttons on the LEFT side */}
          <Button
            onClick={addNewConceptType}
            style={{
              marginBottom: 25,
              marginRight: 15,
              marginTop: 10,
            }}
          >
            Add Concept Type
          </Button>
          <Button
            onClick={handleCopyConceptType}
            style={{
              marginBottom: 25,
              marginRight: 15,
              marginTop: 10,
            }}
          >
            Copy Previous Concept Type
          </Button>
        </div>
        <div>
          {/* Buttons on the RIGHT side */}
          {editingKey ? (
            <Button
              danger
              onClick={() => {
                handleEdit("cancel");
              }}
              style={{
                marginBottom: 25,
                marginTop: 10,
                marginRight: 15,
                // color: "#27a6a4",
                // borderColor: "#27a6a4",
              }}
            >
              Cancel
            </Button>
          ) : (
            <></>
          )}
          <Button
            onClick={() => {
              handleEdit(editingKey ? "save" : "edit");
            }}
            // disabled={dataSource.length > 0 ? true : false}
            disabled={!dataSource || dataSource.length === 0}
            // disabled={ !dataSource || dataSource.length === 0 || !canSaveData }
            style={{
              marginBottom: 25,
              marginTop: 10,
              color: "#27a6a4",
              borderColor: "#27a6a4",
              background: !dataSource || dataSource.length === 0 ? "#e0e0e0" : "transparent",
              // background: !dataSource || dataSource.length === 0 || !canSaveData ? "#e0e0e0" : "transparent",
            }}
          >
            {/* {console.log("dataSource: ", dataSource)} */}
            {editingKey ? "Save" : "Edit"}
          </Button>
        </div>
      </div>

      <div>
        {loading && dataSource.length <= 0 ? (
          <div
            style={{
              textAlign: "center",
              marginTop: "220px",
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spin indicator={antIcon} />
            <p>Loading...</p>
          </div>
        ) : (
          <Form form={form} component={false}>
            <Table
              style={{ maxHeight: "71vh", overflowY: "auto" }}
              dataSource={dataSource}
              columns={columns}
              bordered
              pagination={false}
              // scroll={{ x: "1000", y: 600 }}
            />
          </Form>
        )}
      </div>

      <Drawer title={`Concept - ${currentDrawerRecord?.concept}`} placement="right" size="large" onClose={handleDrawerClose} open={drawerOpen} bodyStyle={{ padding: 40 }}>
        <Form layout="vertical" hideRequiredMark>
          <Form.Item name={"Keywords" + currentDrawerRecord?.key} label="Keywords">
            {currentDrawerRecord?.keywords?.map((tag, index) => {
              if (editInputIndex === index) {
                return (
                  <Input
                    ref={editInputRef}
                    key={tag}
                    size="middle"
                    className="tag-input"
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                  />
                );
              }

              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag className="edit-tag px-2 py-1 text-base" key={tag} closable={index !== -1} onClose={() => handleDeleteKeyword(tag)}>
                  <span
                    onDoubleClick={(e) => {
                      if (index !== -1) {
                        setEditInputIndex(index);
                        setEditInputValue(tag);
                        e.preventDefault();
                      }
                    }}
                  >
                    {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                  </span>
                </Tag>
              );
              return isLongTag ? (
                <Tooltip title={tag} key={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}

            {inputVisible && (
              <Input
                ref={inputRef}
                type="text"
                size="middle"
                className="tag-input"
                value={keywordInputValue}
                onChange={handleKeywordInputChange}
                onBlur={handleKeywordInputConfirm}
                onPressEnter={handleKeywordInputConfirm}
              />
            )}

            {!inputVisible && (
              <Tag className="site-tag-plus px-2 py-1 text-base" onClick={showInput}>
                <PlusOutlined /> New Keyword
              </Tag>
            )}
          </Form.Item>
          <Form.Item name={"Description" + currentDrawerRecord?.key} label="Description">
            <Input.TextArea
              style={{ height: 150 }}
              maxLength={500}
              defaultValue={currentDrawerRecord?.description}
              onChange={handleDescriptionInputChange}
              onBlur={handleDescriptionInputConfirm}
              onPressEnter={handleDescriptionInputConfirm}
            />
          </Form.Item>
          <div className="flex justify-end">
            <Form.Item>
              <Button type="default" onClick={handleDrawerClose}>
                Cancel
              </Button>
              <Button type="primary" htmlType="submit" className="ml-3" onClick={handleDrawerSubmit}>
                Submit
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
}

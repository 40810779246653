import SubNavigation from "../../Components/SubNavigation";
import { Search } from "./Search";
import { DiscoverModuleTabs } from "./data";

/**
 * This page is the main search engine page, which also contains the routes for search and story tab
 *
 * @returns the navigation to open search and stories tab
 */

export default function SearchEngine() {

  const tabs = DiscoverModuleTabs();
  return (
    <>
      <SubNavigation tabs={tabs} />
      <Search />
    </>
  );
}

import { Button, Select, Spin, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { icons } from "../../GlobalThemes";
import React, { useState } from "react";
import { searchQuery } from "../../Pages/SearchEngine/Search";
import * as NodeGraphServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/nodeGraph";
import { useDispatch, useSelector } from "react-redux";
import { selectStore, setDiscoverFilters, setDiscoverTreeData } from "../../state/slice/appSlice";

export interface IDiscoverFilterValues {
	study: string | undefined;
	studyId: number | string[] | undefined;
	wave: string | undefined;
	waveId: string | undefined;
	region: string | undefined;
	regionId: string | undefined;
	studyIterId: string | undefined;
}

/**
 *
 * @remarks - This function is the part of discover tab and will be rendered at the top of the page
 *
 * @param study
 * @param wave
 * @param region
 *
 * @returns the filters at discover tab
 */

export function DiscoverFilters(props: {
	showDrawer?: () => void;
	fetchTranscripts?: (queries: searchQuery) => void;
	searchWordRequired: boolean;
	searchString?: any;
	setSearchString?: any;
	fetchedFilters?: any;
	searchClick: (value: any) => void;
}) {
	const store = useSelector(selectStore);
	const dispatch = useDispatch();
	const initialSearchFilterData: IDiscoverFilterValues = {
		study: undefined,
		studyId: undefined,
		wave: undefined,
		waveId: undefined,
		region: undefined,
		regionId: undefined,
		studyIterId: undefined,
	};

	const [searchFilterData, setSearchFilterData] = useState<searchQuery>(initialSearchFilterData);
	const [fetchedRegionData, setFetchedRegionData] = React.useState<any>();
	const [fetchedWaveFilters, setFetchedWaveFilters] = React.useState<any>();
	const [fetchedRegionFilters, setFetchedRegionFilters] = React.useState<any>();
	const [loading, setLoading] = React.useState<boolean>(false);

	const antIcon = <LoadingOutlined style={{ fontSize: 24, color: "#27a6a4", alignSelf: "center", flex: 1 }} spin />;

	// API Request to fetch NG Wave Dropdown Data
	async function fetchDropdownWaveData(studyId: number) {
		setLoading(true);
		try {
			const data: any = await NodeGraphServiceHelper.getWavesForStudyId(studyId);

			if (data && data.length > 0) {
				setFetchedWaveFilters([
					{
						optionsObject: data.map((item: { waveId: number; waveName: string; studyIterId: number }) => ({
							id: item.waveId,
							name: item.waveName,
							studyIterId: item.studyIterId,
							subgroup: [],
						})),
						filter: "Wave Name",
						type: "select",
					},
				]);
				setFetchedRegionFilters(null);
				setSearchFilterData((prevFilterData) => ({
					...prevFilterData,
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				}));
				//message.success("Successfully fetched Wave Data");
				// console.log("Fetched Wave Dropdown Data -> ", data);
			} else {
				setFetchedRegionFilters(null);
				setSearchFilterData((prevFilterData) => ({
					...prevFilterData,
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				}));
				message.error("No waves found for the study.");
				console.log("Fetch Wave Data API returned empty data or no waves found for the study.");
			}
		} catch (error) {
			setFetchedRegionFilters(null);
			setSearchFilterData((prevFilterData) => ({
				...prevFilterData,
				region: undefined,
				regionId: undefined,
				studyIterId: undefined,
			}));
			message.error("Error fetching Wave Dropdown Data. Please try again later.");
			console.error("Error fetching Wave Dropdown Data:", error);
		} finally {
			setLoading(false); // Reset loading state after API call
		}
	}

	// API Request to fetch NG Region Dropdown Data
	async function fetchDropdownRegionData(waveId: number) {
		setLoading(true);
		try {
			const data: any = await NodeGraphServiceHelper.getStudyTypes(waveId);

			if (data && data.length > 0) {
				setFetchedRegionData(data);
				setFetchedRegionFilters([
					{
						optionsObject: data.map((item: { geoId: number; geoName: string; studyIterId: number }) => ({
							id: item.geoId,
							name: item.geoName,
							studyIterId: item.studyIterId,
							subgroup: [],
						})),
						filter: "Region Name",
						type: "select",
					},
				]);
				// message.success("Successfully fetched Region Data");
				// console.log("Fetched Region Dropdown Data -> ", data);
			} else {
				setFetchedRegionData(null);
				setFetchedRegionFilters(null);
				message.error("No regions found for the wave.");
				console.log("Fetch Region Data API returned empty data or no regions found for the wave.");
			}
		} catch (error) {
			setFetchedRegionData(null);
			setFetchedRegionFilters(null);
			message.error("Error fetching Region Dropdown Data. Please try again later.");
			console.error("Error fetching Region Dropdown Data:", error);
		} finally {
			setLoading(false); // Reset loading state after API call
		}
	}

	const handleFilters = (filterType: string, filterValue: string | boolean | string[], filterKey: number | void | null) => {
		// console.log("handleFilters value:", filterType, filterValue, filterKey);
		// console.log("fetchedRegionData value:", fetchedRegionData);
		let updatedFilters: any = { ...searchFilterData };

		updatedFilters[filterType] = filterValue;

		if (filterKey) {
			updatedFilters[filterType + "Id"] = filterKey;

			if (filterType === "region") {
				let studyIterIds = fetchedRegionData?.filter((e?: any) => e.geoId == filterKey);
				if (studyIterIds && studyIterIds.length > 0) {
					updatedFilters[filterType + "Id"] = filterKey;
					updatedFilters["studyIterId"] = studyIterIds[0].studyIterId;
				}
			}

			if (filterType === "study" && filterKey) {
				const temp = JSON.parse(JSON.stringify(updatedFilters));
				temp["wave"] = undefined;
				temp["waveId"] = undefined;
				temp["region"] = undefined;
				temp["regionId"] = undefined;
				temp["studyIterId"] = undefined;
				updatedFilters = temp;
				fetchDropdownWaveData(filterKey);
			}

			if (filterType === "wave" && filterKey) {
				updatedFilters = {
					...updatedFilters,
					region: undefined,
					regionId: undefined,
					studyIterId: undefined,
				};
				fetchDropdownRegionData(filterKey);
			}
		}

		// console.log("Search handle filters -> ", updatedFilters);
		setSearchFilterData(updatedFilters);
		dispatch(setDiscoverFilters(updatedFilters));
		filterType === "study" && filterKey && dispatch(setDiscoverTreeData(null));
	};

	// useEffect(() => {
	// 	// console.log(props.fetchedFilters);
	// 	setFiltersFetch(props.fetchedFilters);
	// }, [props.fetchedFilters]);

	const onClickSearch = () => {
		props.searchClick(searchFilterData);
		// console.log("onClickSearch Data Value", searchFilterData);
	};

	const renderLoadingSpinner = () => (
		<div style={{ textAlign: "center", margin: "40px 0", flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "transparent" }}>
			<Spin indicator={antIcon} />
			<p>Loading...</p>
		</div>
	);

	return (
		<div>
			<div className="flex justify-between items-center pb-4 mt-4">
				<div className="flex items-center">
					<div className="flex items-center mr-6">
						<span className="mr-2">Study:</span>
						<div style={{ width: "200px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder="Select Study Name"
								value={store.discoverTab.discoverFilters.study}
								allowClear
								onChange={(value: string, option: any) => {
									const key = option.key as number;
									handleFilters("study", value, key);
								}}
							>
								{loading
									? renderLoadingSpinner()
									: props.fetchedFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>

					<div className="flex items-center mr-6">
						<span className="mx-2">Wave:</span>
						<div style={{ width: "150px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder="Select Wave"
								value={store.discoverTab.discoverFilters.wave}
								allowClear
								onChange={(value: string, option: any) => {
									const key = option.key as number;
									handleFilters("wave", value, key);
								}}
							>
								{loading
									? renderLoadingSpinner()
									: fetchedWaveFilters &&
									  fetchedWaveFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>

					<div className="flex items-center mr-6">
						<span className="mx-2">Region:</span>
						<div style={{ width: "150px" }}>
							<Select
								clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
								className="w-full"
								placeholder="Select Region"
								value={store.discoverTab.discoverFilters.region}
								allowClear
								onChange={(value: string, option: any) => {
									const key = option.key as number;
									handleFilters("region", value, key);
								}}
							>
								{loading
									? renderLoadingSpinner()
									: fetchedRegionFilters &&
									  fetchedRegionFilters[0]?.optionsObject?.map((filter?: any) => (
											<Select.Option key={filter["id"]} value={filter["name"]}>
												{filter["name"]}
											</Select.Option>
									  ))}
							</Select>
						</div>
					</div>

					<Button type="primary" htmlType="submit" className="green-button flex items-center ml-4" onClick={() => onClickSearch()}>
						Show Insights
					</Button>
				</div>
			</div>
		</div>
	);
}

import { Skeleton } from "antd";
import React from "react";
import { icons, userTypeEnum } from "../../GlobalThemes";
import AppContext from "../../store";

export const words = [
	{
		text: "told",
		value: 64,
	},
	{
		text: "mistake",
		value: 11,
	},
	{
		text: "thought",
		value: 16,
	},
	{
		text: "bad",
		value: 17,
	},
	{
		text: "correct",
		value: 10,
	},
	{
		text: "day",
		value: 54,
	},
	{
		text: "prescription",
		value: 12,
	},
	{
		text: "time",
		value: 77,
	},
	{
		text: "thing",
		value: 45,
	},
	{
		text: "left",
		value: 19,
	},
	{
		text: "pay",
		value: 13,
	},
	{
		text: "people",
		value: 32,
	},
	{
		text: "month",
		value: 22,
	},
	{
		text: "again",
		value: 35,
	},
	{
		text: "review",
		value: 24,
	},
	{
		text: "call",
		value: 38,
	},
	{
		text: "doctor",
		value: 70,
	},
	{
		text: "asked",
		value: 26,
	},
	{
		text: "finally",
		value: 14,
	},
	{
		text: "insurance",
		value: 29,
	},
	{
		text: "week",
		value: 41,
	},
	{
		text: "called",
		value: 49,
	},
	{
		text: "problem",
		value: 20,
	},
	{
		text: "going",
		value: 59,
	},
	{
		text: "help",
		value: 49,
	},
	{
		text: "felt",
		value: 45,
	},
	{
		text: "discomfort",
		value: 11,
	},
	{
		text: "lower",
		value: 22,
	},
	{
		text: "severe",
		value: 12,
	},
	{
		text: "free",
		value: 38,
	},
	{
		text: "better",
		value: 54,
	},
	{
		text: "muscle",
		value: 14,
	},
	{
		text: "neck",
		value: 41,
	},
	{
		text: "root",
		value: 24,
	},
	{
		text: "adjustment",
		value: 16,
	},
	{
		text: "therapy",
		value: 29,
	},
	{
		text: "injury",
		value: 20,
	},
	{
		text: "excruciating",
		value: 10,
	},
	{
		text: "chronic",
		value: 13,
	},
	{
		text: "chiropractor",
		value: 35,
	},
	{
		text: "treatment",
		value: 59,
	},
	{
		text: "tooth",
		value: 32,
	},
	{
		text: "chiropractic",
		value: 17,
	},
	{
		text: "dr",
		value: 77,
	},
	{
		text: "relief",
		value: 19,
	},
	{
		text: "shoulder",
		value: 26,
	},
	{
		text: "nurse",
		value: 17,
	},
	{
		text: "room",
		value: 22,
	},
	{
		text: "hour",
		value: 35,
	},
	{
		text: "wait",
		value: 38,
	},
	{
		text: "hospital",
		value: 11,
	},
	{
		text: "eye",
		value: 13,
	},
	{
		text: "test",
		value: 10,
	},
	{
		text: "appointment",
		value: 49,
	},
	{
		text: "medical",
		value: 19,
	},
	{
		text: "question",
		value: 20,
	},
	{
		text: "office",
		value: 64,
	},
	{
		text: "care",
		value: 54,
	},
	{
		text: "minute",
		value: 29,
	},
	{
		text: "waiting",
		value: 16,
	},
	{
		text: "patient",
		value: 59,
	},
	{
		text: "health",
		value: 49,
	},
	{
		text: "alternative",
		value: 24,
	},
	{
		text: "holistic",
		value: 19,
	},
	{
		text: "traditional",
		value: 20,
	},
	{
		text: "symptom",
		value: 29,
	},
	{
		text: "internal",
		value: 17,
	},
	{
		text: "prescribed",
		value: 26,
	},
	{
		text: "acupuncturist",
		value: 16,
	},
	{
		text: "pain",
		value: 64,
	},
	{
		text: "integrative",
		value: 10,
	},
	{
		text: "herb",
		value: 13,
	},
	{
		text: "sport",
		value: 22,
	},
	{
		text: "physician",
		value: 41,
	},
	{
		text: "herbal",
		value: 11,
	},
	{
		text: "eastern",
		value: 12,
	},
	{
		text: "chinese",
		value: 32,
	},
	{
		text: "acupuncture",
		value: 45,
	},
	{
		text: "prescribe",
		value: 14,
	},
	{
		text: "medication",
		value: 38,
	},
	{
		text: "western",
		value: 35,
	},
	{
		text: "sure",
		value: 38,
	},
];

export const FindSimilarSearchDummyData = [
	{
		numberOfPatients: 79,
		numberOfSearchResults: 3,
		searchResults: [
			{
				name: "Asthma/Lectrazine/US South/BTK_Lectrazine_MSL_Note_98",
				tags: ["Channel:F2F", "Tier:2"],
				storiesAdded: [
					{
						storyId: 21,
						storyName: "Test Story 1",
					},
					{
						storyId: 16,
						storyName: "Test VG1 Story",
					},
				],
				source: 1,
				studyId: 8,
				trId: 1,
				studyName: "Asthma",
				geography: "US South",
				wave: "Lectrazine",
				fileName: "BTK_Lectrazine_MSL_Note_98",
				dialogueNo: 98,
				callId: null,
				callDate: "2023-10-05T11:44:33",
				callTime: null,
				s3path: "client-vapt/1/Processed_transcripts/1.json",
				addedToStory: true,
				score: -3.248046875,
				dialogueAppearedAt: 0,
				dialogueLength: 10,
				transcriptLength: 10,
				transcripts: [
					{
						dialogueNo: 97,
						text: "",
						speaker: "",
					},
					{
						dialogueNo: 98,
						text: "Lectrazine Asthma - CME/MUN pulmonologist states he was very happy to see the Asthma indication for Lectrazine because the use of steroid has been recognized for Asthma and he is comfortable using it and happy to have option for triple therapy in a single inhaler.",
						speaker: "Note",
					},
				],
				patientId: 0,
				speaker: "Note",
				context:
					'[{"dialogueNo": 97, "text": "", "speaker": ""}, {"dialogueNo": 98, "text": "Lectrazine Asthma - CME/MUN pulmonologist states he was very happy to see the Asthma indication for Lectrazine because the use of steroid has been recognized for Asthma and he is comfortable using it and happy to have option for triple therapy in a single inhaler.", "speaker": "Note"}]',
				elasticSearchId: "e3250a99ab72da47e7e435938719faf3",
				storyDialogues: [
					{
						selected_call_id: "e3250a99ab72da47e7e435938719faf3",
						selected_dialogue_no: 98,
						source: 1,
						selected_index: 0,
						story_id: 21,
						previous_call_id: null,
						previous_dialogue_no: null,
						previous_index: null,
						search_query: "what is the use",
					},
					{
						selected_call_id: "e3250a99ab72da47e7e435938719faf3",
						selected_dialogue_no: 98,
						source: 1,
						selected_index: 0,
						story_id: 16,
						previous_call_id: null,
						previous_dialogue_no: null,
						previous_index: null,
						search_query: "what is the use",
					},
				],
				callTypeId: null,
				therapyStatusId: null,
				notAddedStories: [],
			},
			{
				name: "Asthma/Lectrazine/US South/BTK_Lectrazine_MSL_Note_184",
				tags: ["Channel:F2F", "Tier:2"],
				storiesAdded: [
					{
						storyId: 16,
						storyName: "Test VG1 Story",
					},
				],
				source: 1,
				studyId: 8,
				trId: 1,
				studyName: "Asthma",
				geography: "US South",
				wave: "Lectrazine",
				fileName: "BTK_Lectrazine_MSL_Note_184",
				dialogueNo: 184,
				callId: null,
				callDate: "2023-10-05T11:44:33",
				callTime: null,
				s3path: "client-vapt/1/Processed_transcripts/1.json",
				addedToStory: true,
				score: -3.9375,
				dialogueAppearedAt: 0,
				dialogueLength: 10,
				transcriptLength: 10,
				transcripts: [
					{
						dialogueNo: 183,
						text: "",
						speaker: "",
					},
					{
						dialogueNo: 184,
						text: "Use of steroid in Asthma . HCP adds on steroid to ISC/steroid if pt has poor lung  function, exacerbations or increased blueness. HCP would add an steroid in a second inhaler if he feels the one is not getting to the lungs.",
						speaker: "Note",
					},
				],
				patientId: 0,
				speaker: "Note",
				context:
					'[{"dialogueNo": 183, "text": "", "speaker": ""}, {"dialogueNo": 184, "text": "Use of steroid in Asthma . HCP adds on steroid to ISC/steroid if pt has poor lung  function, exacerbations or increased blueness. HCP would add an steroid in a second inhaler if he feels the one is not getting to the lungs.", "speaker": "Note"}]',
				elasticSearchId: "f2d420c3f10a15c757ad003cd4e30aca",
				storyDialogues: [
					{
						selected_call_id: "f2d420c3f10a15c757ad003cd4e30aca",
						selected_dialogue_no: 184,
						source: 1,
						selected_index: 0,
						story_id: 16,
						previous_call_id: null,
						previous_dialogue_no: null,
						previous_index: null,
						search_query: "what is the use",
					},
				],
				callTypeId: null,
				therapyStatusId: null,
				notAddedStories: [],
			},
			{
				name: "Asthma/Lectrazine/US West/BTK_Lectrazine_MSL_Note_48",
				tags: ["Channel:F2F", "Tier:2"],
				storiesAdded: [
					{
						storyId: 21,
						storyName: "Test Story 1",
					},
					{
						storyId: 16,
						storyName: "Test VG1 Story",
					},
				],
				source: 1,
				studyId: 8,
				trId: 1,
				studyName: "Asthma",
				geography: "US West",
				wave: "Lectrazine",
				fileName: "BTK_Lectrazine_MSL_Note_48",
				dialogueNo: 48,
				callId: null,
				callDate: "2023-10-05T11:44:33",
				callTime: null,
				s3path: "client-vapt/1/Processed_transcripts/1.json",
				addedToStory: true,
				score: -4.6015625,
				dialogueAppearedAt: 0,
				dialogueLength: 10,
				transcriptLength: 10,
				transcripts: [
					{
						dialogueNo: 47,
						text: "",
						speaker: "",
					},
					{
						dialogueNo: 48,
						text: "COPD MUN likes the option to use an steroid/SABA or steroid for PRN use because he feels like not all patients with Asthma need medication all the time, especially corticosteroids.",
						speaker: "Note",
					},
				],
				patientId: 0,
				speaker: "Note",
				context:
					'[{"dialogueNo": 47, "text": "", "speaker": ""}, {"dialogueNo": 48, "text": "COPD MUN likes the option to use an steroid/SABA or steroid for PRN use because he feels like not all patients with Asthma need medication all the time, especially corticosteroids.", "speaker": "Note"}]',
				elasticSearchId: "ab7130cfacbd0eb4cc4e07bfc01c199a",
				storyDialogues: [
					{
						selected_call_id: "ab7130cfacbd0eb4cc4e07bfc01c199a",
						selected_dialogue_no: 48,
						source: 1,
						selected_index: 0,
						story_id: 21,
						previous_call_id: null,
						previous_dialogue_no: null,
						previous_index: null,
						search_query: "need to use",
					},
					{
						selected_call_id: "ab7130cfacbd0eb4cc4e07bfc01c199a",
						selected_dialogue_no: 48,
						source: 1,
						selected_index: 0,
						story_id: 16,
						previous_call_id: null,
						previous_dialogue_no: null,
						previous_index: null,
						search_query: "need to use",
					},
				],
				callTypeId: null,
				therapyStatusId: null,
				notAddedStories: [],
			},
			{
				name: "Test AS Study/Lectrazine/US West/BTK_Lectrazine_MSL_Note_10",
				tags: ["Channel:Virtual", "Tier:2"],
				storiesAdded: [
					{
						storyId: 16,
						storyName: "Test VG1 Story",
					},
				],
				source: 1,
				studyId: 11,
				trId: 1,
				studyName: "Test AS Study",
				geography: "US West",
				wave: "Lectrazine",
				fileName: "BTK_Lectrazine_MSL_Note_10",
				dialogueNo: 10,
				callId: null,
				callDate: "2023-10-05T11:44:33",
				callTime: null,
				s3path: "client-vapt/1/Processed_transcripts/1.json",
				addedToStory: true,
				score: -5.578125,
				dialogueAppearedAt: 0,
				dialogueLength: 10,
				transcriptLength: 10,
				transcripts: [
					{
						dialogueNo: 9,
						text: "",
						speaker: "",
					},
					{
						dialogueNo: 10,
						text: "Allergist HCP stated: I use steroids in patients that are still symptomatic after a med dose steroid or if they have a history of smoking.",
						speaker: "Note",
					},
				],
				patientId: 0,
				speaker: "Note",
				context:
					'[{"dialogueNo": 9, "text": "", "speaker": ""}, {"dialogueNo": 10, "text": "Allergist HCP stated: I use steroids in patients that are still symptomatic after a med dose steroid or if they have a history of smoking.", "speaker": "Note"}]',
				elasticSearchId: "49ca8c9f060bb2facefd9325567e556",
				storyDialogues: [
					{
						selected_call_id: "49ca8c9f060bb2facefd9325567e556",
						selected_dialogue_no: 10,
						source: 1,
						selected_index: 0,
						story_id: 16,
						previous_call_id: null,
						previous_dialogue_no: null,
						previous_index: null,
						search_query: "need to use",
					},
				],
				callTypeId: null,
				therapyStatusId: null,
				notAddedStories: [],
			},
		],
	},
];

export const fetchedFilters: {
	filter: string;
	options?: string[] | undefined;
	optionsObject?:
		| {
				id: number;
				name: string;
				subgroup: {
					id: number;
					name: string;
				}[];
		  }[]
		| undefined;
}[] = [
	{
		filter: "Call_Type",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "Inbound", subgroup: [] },
			{ id: 2, name: "Outbound", subgroup: [] },
		],
	},
	{
		filter: "Therapy_Status",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "On Therapy", subgroup: [] },
			{ id: 2, name: "Never Started", subgroup: [] },
		],
	},
	{
		filter: "Call_Intent",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "Introduction Call", subgroup: [] },
			{ id: 2, name: "Follow-Up Call 1", subgroup: [] },
			{ id: 3, name: "Follow-Up Call 2", subgroup: [] },
			{ id: 4, name: "Follow-Up Call 3", subgroup: [] },
			{ id: 5, name: "Inquiry Call", subgroup: [] },
			{ id: 6, name: "Return Call", subgroup: [] },
		],
	},
	{
		filter: "Emotions",
		optionsObject: [
			{
				id: 0,
				name: "All",
				subgroup: [],
			},
			{
				id: 1,
				name: "Joy",
				subgroup: [
					{ id: 1, name: "Admiration" },
					{ id: 2, name: "Amusement" },
					{ id: 3, name: "Approval" },
					{ id: 4, name: "Caring" },
					{ id: 5, name: "Desire" },
					{ id: 6, name: "Excitement" },
					{ id: 7, name: "Gratitude" },
					{ id: 8, name: "Joy" },
					{ id: 9, name: "Love" },
					{ id: 10, name: "Optimism" },
					{ id: 11, name: "Pride" },
					{ id: 12, name: "Relief" },
				],
			},
			{
				id: 2,
				name: "Anger",
				subgroup: [
					{ id: 1, name: "Anger" },
					{ id: 2, name: "Annoyance" },
				],
			},
			{
				id: 3,
				name: "Sadness",
				subgroup: [
					{ id: 1, name: "Embarrassment" },
					{ id: 2, name: "Disappointment" },
					{ id: 3, name: "Remorse" },
					{ id: 4, name: "Sadness" },
				],
			},
			{ id: 4, name: "Disgust", subgroup: [{ id: 1, name: "Disgust" }] },
			{ id: 5, name: "Fear", subgroup: [{ id: 1, name: "Fear" }] },
			{
				id: 5,
				name: "Surprise",
				subgroup: [
					{ id: 1, name: "Confusion" },
					{ id: 2, name: "Curiosity" },
					{ id: 3, name: "Surprise" },
					{ id: 4, name: "Realization" },
				],
			},
			{ id: 6, name: "Neutral", subgroup: [{ id: 1, name: "Neutral" }] },
		],
	},
	{
		filter: "Adherence",
		optionsObject: [
			{ id: 0, name: "All", subgroup: [] },
			{ id: 1, name: "High", subgroup: [] },
			{ id: 2, name: "Medium", subgroup: [] },
			{ id: 3, name: "Low", subgroup: [] },
		],
	},
];

export const storyTypes = ["General Tracker", "Issues"];
export const storyStatus = ["In Draft", "Publishing", "Published"];

export const fullTranscriptData = [
	{
		speaker: "Patient",
		text: "Hello?",
	},
	{
		speaker: "Agent",
		text: "Hello, may I speak to <NAME>?",
	},
	{
		speaker: "Patient",
		text: "Who am I speaking to?",
	},
	{
		speaker: "Agent",
		text: "My name is <NAME>, I'm a nurse educator and I work for Ashfield on behalf of Biogen.",
	},
	{
		speaker: "Patient",
		text: "Oh okay, sure.",
	},
	{
		speaker: "Agent",
		text: "Are there any other questions I can answer for you?",
	},
	{
		speaker: "Patient",
		text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
	},
	{
		speaker: "Agent",
		text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
	},
	{
		speaker: "Patient",
		text: "Alrigt. Thanks a lot.",
	},
];

export const storyList = [
	{
		chartData: [
			[
				{
					id: 1,
					selected: false,
					"Story Name": "Query about Alcohol Consumption",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 2,
					selected: false,
					"Story Name": "Worried about Side Effects",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 3,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 4,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 5,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 6,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
				{
					id: 7,
					selected: false,
					"Story Name": "Unhappy with meal restrictions",
					"Story Type": "Type",
					"Date Created": "12th Nov 2021",
					"# of Annotated Selections": 12,
					Status: "Published",
				},
			],
		],
	},
];
export const oldstories_empty_grouped_columns = [
  {
    title: "Story Name",
    accessor: "storyName",
    width: 570,
    Cell: (props: any) => {
      if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
        return (
          <div className="text-text-gray-80 text-base">
            {icons.find((l) => l.id === "HourGlass")?.icon}
            Updating
          </div>
        );
      return <div>{props.cell.value}</div>;
    },
  },
  {
    title: "Story Type",
    accessor: "storyType",
    sort: true,
    width: 150,
  },
  {
    title: "Date Created",
    accessor: "dateCreated",
    sort: true,
    width: 150,
  },
  {
    title: "# of Annotated Selections",
    accessor: "noofAnnotationsSelections",
    sort: true,
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
    width: 130,
  },
  {
    title: "",
    accessor: "Actions",
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
    width: 10,
  },
];
export const stories_empty_grouped_columns = [
	{
		title: "Collection Name",
		accessor: "storyName",
		width: 720,
		Cell: (props: any) => {
			if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
				return (
					<div className="text-text-gray-80 text-base">
						{icons.find((l) => l.id === "HourGlass")?.icon}
						Updating
					</div>
				);
			return <div>{props.cell.value}</div>;
		},
	},
	{
		title: "Date Created",
		accessor: "dateCreated",
		sort: true,
		width: 150,
	},
	{
		title: "# of Saved Searches",
		accessor: "noofSavedSearches",
		sort: true,
		Cell: (props: any) => {
			return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
		},
		width: 125,
	},
	{
		title: "",
		accessor: "Actions",
		Cell: (props: any) => {
			return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
		},
		width: 10,
	},
];
export const storiesEmptyData = [
  {
    storyName: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    storyType: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    dateCreated: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    noofSavedSearches: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    storyName: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    storyType: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    dateCreated: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    noofSavedSearches: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    storyName: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    storyType: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    dateCreated: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    noofSavedSearches: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
];

export const patient_journey_empty_grouped_columns = [
	{
		title: "Patient ID",
		accessor: "lh_id",
		key: "lh_id",
		sort: true,
		sortType: "alphanumeric",
	},
	{
		title: "Current Therapy",
		accessor: "currentTherapy",
		// sort: true,
		// sortType: "basic",
	},
	{
		title: "Discon Risk",
		accessor: "disconRisk",
		// sort: true,
		// sortType: "basic",
	},

	{
		title: "Top Unmet Needs",
		accessor: "topUnmetNeeds",
		width: 300,
		// sort: true,
		// sortType: "basic",
	},

	{
		title: "Time on Therapy",
		accessor: "timeOnTherapy",
		sort: true,
		sortType: "basic",
	},
	{
		title: "Therapy Start Date",
		accessor: "therapyStartDate",
		// sort: true,
		// sortType: "basic",
	},

	{
		title: "Out of Stock Date",
		accessor: "outOfStockDate",
		// sort: true,
		// sortType: "basic",
	},
];

export const dummyData_patientJourney = [
	{
		Patient_ID: "LH123456",
		Current_Therapy: "ZSumab",
		Discon_Risk: "Low",
		Top_Unmet_Needs: "Sad/fear about side effects, Curiosity on diet instruction, Anger on insurance",
		Therapy_Status_Date: "1 Feb 2022",
		Time_on_Therapy: "270",
		Out_of_Stock_Date: "1 Nov 2022",
	},
	{
		Patient_ID: "LH123457",
		Current_Therapy: "ZSumab",
		Discon_Risk: "Medium",
		Top_Unmet_Needs: "Sad/fear about side effects, Curiosity on diet instruction, Anger on insurance",
		Therapy_Status_Date: "1 Feb 2022",
		Time_on_Therapy: "270",
		Out_of_Stock_Date: "1 Nov 2022",
	},
	{
		Patient_ID: "LH123458",
		Current_Therapy: "ZSumab",
		Discon_Risk: "Medium",
		Top_Unmet_Needs: "Sad/fear about side effects, Patient participation on dosing",
		Therapy_Status_Date: "1 Feb 2022",
		Time_on_Therapy: "270",
		Out_of_Stock_Date: "1 Nov 2022",
	},
	{
		Patient_ID: "LH123459",
		Current_Therapy: "ZSumab",
		Discon_Risk: "High",
		Top_Unmet_Needs: "f Patient participation on dosing, Curiosity on diet instruction, Anger on insurance",
		Therapy_Status_Date: "1 Feb 2022",
		Time_on_Therapy: "270",
		Out_of_Stock_Date: "1 Nov 2022",
	},
	{
		Patient_ID: "LH123256",
		Current_Therapy: "ZSumab",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Sad/fear about side effects, Patient participation on dosing, Curiosity on diet instruction, Anger on insurance",
		Therapy_Status_Date: "1 Feb 2022",
		Time_on_Therapy: "270",
		Out_of_Stock_Date: "1 Nov 2022",
	},
	{
		Patient_ID: "LH121456",
		Current_Therapy: "ZSumab",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Sad/fear about side effects, Patient participation on dosing, Curiosity on diet instruction, Anger on insurance",
		Therapy_Status_Date: "1 Feb 2022",
		Time_on_Therapy: "270",
		Out_of_Stock_Date: "1 Nov 2022",
	},
	{
		Patient_ID: "LH123156",
		Current_Therapy: "ZSumab",
		Discon_Risk: "High",
		Top_Unmet_Needs: "Sad/fear about side effects, Patient participation on dosing, Curiosity on diet instruction, Anger on insurance",
		Therapy_Status_Date: "1 Feb 2022",
		Time_on_Therapy: "270",
		Out_of_Stock_Date: "1 Nov 2022",
	},
];

export const patientJourneyData = [
	{
		lh_id: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		currentTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		disconRisk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		topUnmetNeeds: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		therapyStartDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		timeOnTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		outOfStockDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		lh_id: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		currentTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		disconRisk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		topUnmetNeeds: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		therapyStartDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		timeOnTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		outOfStockDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		lh_id: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		currentTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		disconRisk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		topUnmetNeeds: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		therapyStartDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		timeOnTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		outOfStockDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		lh_id: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		currentTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		disconRisk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		topUnmetNeeds: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		therapyStartDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		timeOnTherapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		outOfStockDate: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
];

export const patientJourneyEmptyData = [
	{
		Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Therapy_Status_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Time_on_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Out_of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		// Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		// Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Therapy_Status_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Time_on_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Out_of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		// Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		// Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Therapy_Status_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Time_on_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Out_of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		// Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		// Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
];

export const annotated_selection_empty_grouped_columns = [
	{
		title: "Annotated Selection",
		accessor: "Annotated_Selection",
		width: 300,
	},
	{
		title: "Date Added on",
		accessor: "Date_Added_on",
	},
	{
		title: "Source",
		accessor: "Source",
	},
	{
		title: "Tags",
		accessor: "Tags",
	},

	{
		title: "Status",
		accessor: "Status",
	},
	{
		title: "Actions",
		accessor: "Actions",

		width: 40,
	},
];

export const annotatedSelectionEmptyData = [
	{
		Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
	{
		Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		"#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
		Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
	},
];
export const publishLimit = 2;

export const filterOptions = [
	// {
	//   filterName: "Show Results",
	//   type: "radio",
	//   options: ["All", "Already added to a story", "Not added to a story"],
	// },
	{
		filterName: "Study Name",
		type: "dropdown",
		options: [],
	},
	{
		filterName: "Speaker Type",
		type: "dropdown",
		options: [],
	},
	{
		filterName: "Time Range",
		type: "date-range",
		options: [],
	},
	{
		filterName: "Filter",
		type: "select",
		options: [],
	},
	// {
	//   filterName: "Time on Therapy",
	//   type: "slider",
	//   options: [],
	// },
];

export function DashboardCards() {
	const context = React.useContext(AppContext);

	switch (context.userType) {
		case userTypeEnum[0]:
			switch (context.envId) {
				case 1:
				case 2:
					return [
						{
							programName: "Apps",
							cards: [
								{
									name: "Discover",
									link: "/discover/",
									type: 1,
									image: 1,
								},
								{
									name: "Search Engine",
									link: "/search",
									type: 1,
									image: 2,
								},
								{
									name: "Simulations",
									link: "",
									type: 1,
									image: 3,
								},
								{
									name: "Cognitive",
									link: "",
									type: 1,
									image: 4,
								},
								{
									name: "Messaging",
									link: "",
									type: 1,
									image: 5,
								},
								{
									name: "...",
									link: "",
									type: 1,
									image: 6,
								},
							],
						},
					];
				case 3:
					return [
						{
							programName: "Apps",
							cards: [
								{
									name: "Discover and Search",
									link: "/Node_Graph",
									type: 1,
									image: 2,
								},
								{
									name: "Dashboard",
									link: "/dashboard",
									type: 1,
									image: 1,
								},
								{
									name: "High Need Patients",
									link: "/highpatientneeds",
									type: 1,
									image: 3,
								},
								{
									name: "Cognitive",
									link: "",
									type: 1,
									image: 4,
								},
								{
									name: "Messaging",
									link: "",
									type: 1,
									image: 5,
								},
								{
									name: "...",
									link: "",
									type: 1,
									image: 6,
								},
							],
						},
					];
			}
			break;
		case userTypeEnum[1]:
		case userTypeEnum[2]:
			return [
				{
					programName: "Apps",
					cards: [
						{
							name: "Search Engine",
							link: "/search",
							type: 1,
							image: 2,
						},
						{
							name: "Discover",
							link: "/discover/",
							type: 1,
							image: 2,
						},
					],
				},
			];
	}

	return []; // default return, if no conditions met
}

export function DiscoverModuleTabs() {
	const context = React.useContext(AppContext);
	if (context.userType === userTypeEnum[0]) {
		if (context.envId === 1)
			return [
				{
					subTab: "Discover",
					subSubTabs: [],
				},
				{
					subTab: "Search",
					subSubTabs: [],
				},
				{
					subTab: "Collection",
					subSubTabs: [],
				},
				{
					subTab: "Stories",
					subSubTabs: [],
				},
			];
		else if (context.envId === 2)
			return [
				{
					subTab: "Discover",
					subSubTabs: [],
				},
				{
					subTab: "Search",
					subSubTabs: [],
				},
				{
					subTab: "Stories",
					subSubTabs: [],
				},
			];
		else if (context.envId === 3)
			return [
				{
					subTab: "Node Graph",
					subSubTabs: [],
				},
				{
					subTab: "Search",
					subSubTabs: [],
				},
				{
					subTab: "Stories",
					subSubTabs: [],
				},
				{
					subTab: "Patient Journey",
					subSubTabs: [],
				},
			];
	} else if (context.userType === userTypeEnum[1])
		return [
			{
				subTab: "Discover",
				subSubTabs: [],
			},
			{
				subTab: "Search",
				subSubTabs: [],
			},
		];
	else if (context.userType === userTypeEnum[2])
		return [
			{
				subTab: "Search",
				subSubTabs: [],
			},
		];
	else
		return [
			{
				subTab: "Discover",
				subSubTabs: [],
			},
			{
				subTab: "Search",
				subSubTabs: [],
			},
			{
				subTab: "Collection",
				subSubTabs: [],
			},
			{
				subTab: "Stories",
				subSubTabs: [],
			},
			{
				subTab: "Node Graph",
				subSubTabs: [],
			},
			{
				subTab: "Patient Journey",
				subSubTabs: [],
			},
		];
}

export const timelineChartDummyData = [
	{
		callId: "1",
		unmetNeeds: "Curiosity on diet instruction",
		topic: "",
		programName: "Nurse Educator Program",
		callType: "Outbound",
		isCallSuccessful: true,
		callStartDate: "1 Feb 2022",
		callStartTime: "[9:20:53]",
	},
	{
		callId: "2",
		unmetNeeds: "Curiosity on diet instruction",
		topic: "",
		programName: "Nurse Educator Program",
		callType: "Outbound",
		isCallSuccessful: true,
		callStartDate: "16 Mar 2022",
		callStartTime: "[17:00:01]",
	},
	{
		callId: "3",
		unmetNeeds: "",
		topic: "",
		programName: "",
		callType: "Unsucessful",
		isCallSuccessful: false,
		callStartDate: "16 Mar 2022",
		callStartTime: "[17:00:01]",
	},
	{
		callId: "4",
		unmetNeeds: "Anger on insurance",
		topic: "",
		programName: "Speciality Pharmacy",
		callType: "Outbound",
		isCallSuccessful: true,
		callStartDate: "14 Apr 2022",
		callStartTime: "[18:10:20]",
	},
];

export const searchFilterActiveStatus = {
	Sentiment: true,
	Start_Date: true,
	End_Date: true,
	Call_Type: true,
	Therapy_Status: true,
	Topics: true,
	Emotions: true,
	totStart: true,
	totEnd: true,
};

export const transcriptStoriesDummyData = [
	{
		id: "story_98",
		"Story Name": "insurance",
		"Date Created": "30 Sep 2022",
		"Story Type": "General Tracker",
		"# of Annotated Selections": 2,
		Status: "In Draft",
		index: 11,
	},
	{
		id: "story_97",
		"Story Name": "alcohol",
		"Date Created": "30 Sep 2022",
		"Story Type": "General Tracker",
		"# of Annotated Selections": 18,
		Status: "In Draft",
		index: 12,
	},
];

export const transcrtiptFilters = [
	{
		optionsObject: [
			{
				id: "1",
				name: "Inbound",
				subgroup: [],
			},
			{
				id: "2",
				name: "Outbound",
				subgroup: [],
			},
		],
		filter: "Call_Type",
		type: "select",
	},
	{
		optionsObject: [
			{
				id: "1",
				name: "ON THERAPY",
				subgroup: [],
			},
			{
				id: "2",
				name: "DISCONTINUED",
				subgroup: [],
			},
			{
				id: "3",
				name: "NEVER START",
				subgroup: [],
			},
			{
				id: "-1",
				name: "UNKNOWN",
				subgroup: [],
			},
			{
				id: "4",
				name: "IN PROCESS",
				subgroup: [],
			},
			{
				id: "5",
				name: "ON THERAPY REPEAT",
				subgroup: [],
			},
		],
		filter: "Therapy_Status",
		type: "select",
	},
	{
		optionsObject: [
			{
				id: "1",
				name: "positive",
				subgroup: [],
			},
			{
				id: "2",
				name: "negative",
				subgroup: [],
			},
			{
				id: "3",
				name: "neutral",
				subgroup: [],
			},
		],
		filter: "Sentiment",
		type: "select",
	},
	{
		optionsObject: [
			{
				id: "topic_0",
				name: "Closing",
				subgroup: [],
			},
			{
				id: "topic_1",
				name: "Common Side Effects",
				subgroup: [],
			},
			{
				id: "topic_2",
				name: "Next Call Confirmation",
				subgroup: [],
			},
			{
				id: "topic_3",
				name: "Introduction",
				subgroup: [],
			},
			{
				id: "topic_4",
				name: "Lab Monitoring Guidance",
				subgroup: [],
			},
			{
				id: "topic_5",
				name: "Nurse Educator Role",
				subgroup: [],
			},
			{
				id: "topic_6",
				name: "Others",
				subgroup: [],
			},
			{
				id: "topic_7",
				name: "Patient Questionnaire",
				subgroup: [],
			},
			{
				id: "topic_8",
				name: "Safety Recording",
				subgroup: [],
			},
			{
				id: "topic_9",
				name: "Shipment",
				subgroup: [],
			},
			{
				id: "topic_10",
				name: "Specialty Pharmacy",
				subgroup: [],
			},
			{
				id: "topic_11",
				name: "Dosing Information",
				subgroup: [],
			},
		],
		filter: "Topics",
		type: "select",
	},
	{
		optionsObject: [
			{
				id: "1",
				name: "joy-ekman",
				subgroup: [
					{
						id: "1",
						name: "admiration",
						subgroup: [],
					},
					{
						id: "2",
						name: "amusement",
						subgroup: [],
					},
					{
						id: "3",
						name: "approval",
						subgroup: [],
					},
					{
						id: "4",
						name: "caring",
						subgroup: [],
					},
					{
						id: "5",
						name: "desire",
						subgroup: [],
					},
					{
						id: "6",
						name: "excitement",
						subgroup: [],
					},
					{
						id: "7",
						name: "gratitude",
						subgroup: [],
					},
					{
						id: "8",
						name: "joy",
						subgroup: [],
					},
					{
						id: "9",
						name: "love",
						subgroup: [],
					},
					{
						id: "10",
						name: "optimism",
						subgroup: [],
					},
					{
						id: "11",
						name: "pride",
						subgroup: [],
					},
					{
						id: "12",
						name: "relief",
						subgroup: [],
					},
				],
			},
			{
				id: "2",
				name: "anger-ekman",
				subgroup: [
					{
						id: "13",
						name: "anger",
						subgroup: [],
					},
					{
						id: "14",
						name: "annoyance",
						subgroup: [],
					},
					{
						id: "15",
						name: "disapproval",
						subgroup: [],
					},
				],
			},
			{
				id: "3",
				name: "sadness-ekman",
				subgroup: [
					{
						id: "16",
						name: "disappointment",
						subgroup: [],
					},
					{
						id: "17",
						name: "embarrassment",
						subgroup: [],
					},
					{
						id: "18",
						name: "grief",
						subgroup: [],
					},
					{
						id: "19",
						name: "remorse",
						subgroup: [],
					},
					{
						id: "20",
						name: "sadness",
						subgroup: [],
					},
				],
			},
			{
				id: "4",
				name: "disgust-ekman",
				subgroup: [
					{
						id: "21",
						name: "disgust",
						subgroup: [],
					},
				],
			},
			{
				id: "5",
				name: "fear-ekman",
				subgroup: [
					{
						id: "22",
						name: "fear",
						subgroup: [],
					},
					{
						id: "23",
						name: "nervousness",
						subgroup: [],
					},
				],
			},
			{
				id: "6",
				name: "surprise-ekman",
				subgroup: [
					{
						id: "24",
						name: "confusion",
						subgroup: [],
					},
					{
						id: "25",
						name: "curiosity",
						subgroup: [],
					},
					{
						id: "26",
						name: "surprise",
						subgroup: [],
					},
					{
						id: "27",
						name: "realization",
						subgroup: [],
					},
				],
			},
			{
				id: "7",
				name: "neutral-ekman",
				subgroup: [
					{
						id: "28",
						name: "neutral",
						subgroup: [],
					},
				],
			},
		],
		filter: "Emotions",
		type: "tree",
	},
	// {
	//   filter: "TOT",
	//   optionsObject: [],
	//   type: "slider",
	// },
];

export const nodeGraphTranscriptData = {
	transcript_data: [
		{
			topicId: 0,
			topicName: "denied claim lectrazine",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 25 May 2022 [20:15:09]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 4,
					callId: "O1388T9ZZ5BCLGTD",
					callDate: "2022-05-25",
					callTime: "20:15:09",
					dialogueAppearedAt: 16.1,
					dialogueLength: 15.18,
					transcriptLength: 75.44,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 2, "text": "i\'m just so frustrated. my insurance company denied my claim for lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me what happened ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "i\'ve been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they\'re saying it\'s not medically necessary and they won\'t pay for it.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "that doesn\'t sound right. let me see if i can help you figure this out.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "i just don\'t understand why they would deny it. it\'s not like it\'s a luxury medication or anything.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
					elasticSearchId: "O1388T9ZZ5BCLGTD_4",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
							highLights: ["lectrazine"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. can you tell me what happened ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
							highLights: ["lectrazine"],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "that doesn't sound right. let me see if i can help you figure this out.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
							highLights: [],
							dialogueNo: 6,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 09 May 2022 [21:20:36]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 3,
					callId: "WW8PUBYMYJOW4S8N",
					callDate: "2022-05-09",
					callTime: "21:20:36",
					dialogueAppearedAt: 39.56,
					dialogueLength: 11.5,
					transcriptLength: 118.68,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve been hearing a lot of bad things about it.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'ve been having a lot of headaches and dizziness since i started taking lectrazine. it\'s really starting to affect my quality of life.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "i\'m sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you\'re still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "i\'m really getting worried about these side effects. is there anything i can do to reduce them ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
					elasticSearchId: "WW8PUBYMYJOW4S8N_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
							highLights: ["lectrazine"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
							highLights: ["lectrazine"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
							highLights: ["lectrazine"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
							highLights: ["lectrazine"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
							highLights: [],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 25 May 2022 [15:30:20]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 2,
					callId: "F1T9KO8WBYD609GF",
					callDate: "2022-05-25",
					callTime: "15:30:20",
					dialogueAppearedAt: 3.68,
					dialogueLength: 6.44,
					transcriptLength: 119.14,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how can i help you ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m just so frustrated. my insurance company denied my claim for lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me what happened ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "they said that it\'s not a covered medication. but i\'ve been taking it for years and it\'s the only thing that helps me.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
					elasticSearchId: "F1T9KO8WBYD609GF_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how can i help you ?",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
							highLights: ["lectrazine"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. can you tell me what happened ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 1,
			topicName: "feeling really anxious",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 17 May 2022 [11:44:17]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 3,
					callId: "U27WXBUWZB8TNX14",
					callDate: "2022-05-17",
					callTime: "11:44:17",
					dialogueAppearedAt: 18.4,
					dialogueLength: 16.1,
					transcriptLength: 104.88,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m just so frustrated. i feel like i\'m getting calls all the time from the nurse educator.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "i\'m sorry to hear that you\'re feeling frustrated. can you tell me more about why you feel that way ?", "speaker": "Agent"}, {"dialogueNo": 3, "text": "it just seems like every time i turn around, i\'m getting a call from you. i know you\'re just trying to help, but it\'s really getting to be a bit much.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "i understand how you feel. we just want to make sure that you\'re getting the best possible care. that\'s why we like to stay in touch with our patients.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "but i feel like i can\'t even take a step without you calling me. it\'s really starting to interfere with my life.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
					elasticSearchId: "U27WXBUWZB8TNX14_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
							highLights: ["calls", "feel", "getting", "time"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
							highLights: ["feel", "feeling"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
							highLights: ["call", "getting", "time"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
							highLights: ["feel", "getting"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
							highLights: ["calling", "feel"],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 23 May 2022 [14:15:27]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 6,
					callId: "2VN0YRE1QMBTAEUC",
					callDate: "2022-05-23",
					callTime: "14:15:27",
					dialogueAppearedAt: 64.4,
					dialogueLength: 15.64,
					transcriptLength: 138.46,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [
						{
							storyId: "story_13",
							storyName: "dummy2",
						},
					],
					addedToStory: "true",
					context:
						'[{"dialogueNo": 4, "text": "i ordered it from the company\'s website. they said it would take about a week to ship, but it\'s already been almost two weeks.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "well, it\'s possible that there was a delay in shipping. have you contacted the company to see if they can give you any information about where your order is ?", "speaker": "Agent"}, {"dialogueNo": 6, "text": "yeah, i\'ve tried to call them a few times, but i can\'t get through to anyone. i\'ve also sent them a few emails, but i haven\'t gotten any response.", "speaker": "Patient"}, {"dialogueNo": 7, "text": "well, it\'s possible that they\'re just very busy and haven\'t been able to get back to you yet. i would suggest trying to contact them again, and if you still can\'t get through, you can try to reach out to their customer service department.", "speaker": "Agent"}, {"dialogueNo": 8, "text": "yeah, i\'ll try that. i\'m just really worried that something might have happened to my medication. i need it to manage my condition.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/2VN0YRE1QMBTAEUC.json",
					elasticSearchId: "2VN0YRE1QMBTAEUC_6",
					transcripts: [
						{
							speaker: "Patient",
							text: "i ordered it from the company's website. they said it would take about a week to ship, but it's already been almost two weeks.",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "well, it's possible that there was a delay in shipping. have you contacted the company to see if they can give you any information about where your order is ?",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "yeah, i've tried to call them a few times, but i can't get through to anyone. i've also sent them a few emails, but i haven't gotten any response.",
							highLights: ["call", "get", "gotten", "times"],
							dialogueNo: 6,
						},
						{
							speaker: "Agent",
							text: "well, it's possible that they're just very busy and haven't been able to get back to you yet. i would suggest trying to contact them again, and if you still can't get through, you can try to reach out to their customer service department.",
							highLights: ["get"],
							dialogueNo: 7,
						},
						{
							speaker: "Patient",
							text: "yeah, i'll try that. i'm just really worried that something might have happened to my medication. i need it to manage my condition.",
							highLights: [],
							dialogueNo: 8,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 17 May 2022 [11:44:17]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 5,
					callId: "U27WXBUWZB8TNX14",
					callDate: "2022-05-17",
					callTime: "11:44:17",
					dialogueAppearedAt: 49.22,
					dialogueLength: 11.04,
					transcriptLength: 104.88,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 3, "text": "it just seems like every time i turn around, i\'m getting a call from you. i know you\'re just trying to help, but it\'s really getting to be a bit much.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "i understand how you feel. we just want to make sure that you\'re getting the best possible care. that\'s why we like to stay in touch with our patients.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "but i feel like i can\'t even take a step without you calling me. it\'s really starting to interfere with my life.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "i\'m sorry to hear that. we\'ll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?", "speaker": "Agent"}, {"dialogueNo": 7, "text": "i don\'t know. it\'s just really frustrating. i feel like i can\'t even have a normal life anymore.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
					elasticSearchId: "U27WXBUWZB8TNX14_5",
					transcripts: [
						{
							speaker: "Patient",
							text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
							highLights: ["call", "getting", "time"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
							highLights: ["feel", "getting"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
							highLights: ["calling", "feel"],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
							highLights: ["calls"],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
							highLights: ["feel"],
							dialogueNo: 7,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 4,
					name: "Transcript from 23 May 2022 [12:11:21]",
					tags: ["Others", "Inbound", "UNKNOWN", "Negative"],
					dialogueNo: 4,
					callId: "UP3DS2H374JBNPNW",
					callDate: "2022-05-23",
					callTime: "12:11:21",
					dialogueAppearedAt: 20.7,
					dialogueLength: 9.66,
					transcriptLength: 98.44,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 2, "text": "not great. i\'m still waiting for my lectrazine medication to arrive and it\'s been over a week.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me a little bit more about your symptoms ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "i\'m feeling really anxious and my heart is racing. i can\'t sleep or eat and i\'m just really frustrated.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "that sounds very difficult. i\'ll see if i can find out what\'s going on with your medication and try to get you some relief.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "thank you.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/UP3DS2H374JBNPNW.json",
					elasticSearchId: "UP3DS2H374JBNPNW_4",
					transcripts: [
						{
							speaker: "Patient",
							text: "not great. i'm still waiting for my lectrazine medication to arrive and it's been over a week.",
							highLights: [],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. can you tell me a little bit more about your symptoms ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "i'm feeling really anxious and my heart is racing. i can't sleep or eat and i'm just really frustrated.",
							highLights: ["feeling"],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "that sounds very difficult. i'll see if i can find out what's going on with your medication and try to get you some relief.",
							highLights: ["get"],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "thank you.",
							highLights: [],
							dialogueNo: 6,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
				{
					searchId: 5,
					name: "Transcript from 17 May 2022 [11:44:17]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 7,
					callId: "U27WXBUWZB8TNX14",
					callDate: "2022-05-17",
					callTime: "11:44:17",
					dialogueAppearedAt: 77.74,
					dialogueLength: 9.66,
					transcriptLength: 104.88,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 5, "text": "but i feel like i can\'t even take a step without you calling me. it\'s really starting to interfere with my life.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "i\'m sorry to hear that. we\'ll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?", "speaker": "Agent"}, {"dialogueNo": 7, "text": "i don\'t know. it\'s just really frustrating. i feel like i can\'t even have a normal life anymore.", "speaker": "Patient"}, {"dialogueNo": 8, "text": "i\'m sorry to hear that. we\'ll do our best to make the calls less frequent. but in the meantime, is there anything we can do to help make the calls more bearable for you ?", "speaker": "Agent"}]',
					s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
					elasticSearchId: "U27WXBUWZB8TNX14_7",
					transcripts: [
						{
							speaker: "Patient",
							text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
							highLights: ["calling", "feel"],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
							highLights: ["calls"],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
							highLights: ["feel"],
							dialogueNo: 7,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. we'll do our best to make the calls less frequent. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
							highLights: ["calls"],
							dialogueNo: 8,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 6,
					name: "Transcript from 17 May 2022 [11:44:17]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 1,
					callId: "U27WXBUWZB8TNX14",
					callDate: "2022-05-17",
					callTime: "11:44:17",
					dialogueAppearedAt: 0,
					dialogueLength: 8.74,
					transcriptLength: 104.88,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m just so frustrated. i feel like i\'m getting calls all the time from the nurse educator.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "i\'m sorry to hear that you\'re feeling frustrated. can you tell me more about why you feel that way ?", "speaker": "Agent"}, {"dialogueNo": 3, "text": "it just seems like every time i turn around, i\'m getting a call from you. i know you\'re just trying to help, but it\'s really getting to be a bit much.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
					elasticSearchId: "U27WXBUWZB8TNX14_1",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
							highLights: ["calls", "feel", "getting", "time"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
							highLights: ["feel", "feeling"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
							highLights: ["call", "getting", "time"],
							dialogueNo: 3,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 2,
			topicName: "worried effects lectrazine",
			numberOfSearchResults: 7,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 04 May 2022 [18:32:15]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 1,
					callId: "SR0ANN6MVSM2XHRA",
					callDate: "2022-05-04",
					callTime: "18:32:15",
					dialogueAppearedAt: 0,
					dialogueLength: 11.5,
					transcriptLength: 126.5,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve read that it can cause weight gain, hair loss, and headaches.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "yes, those are all potential side effects of lectrazine. however, they are all relatively rare. most people who take lectrazine don\'t experience any serious side effects.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "but what if i\'m one of the people who does experience side effects ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/SR0ANN6MVSM2XHRA.json",
					elasticSearchId: "SR0ANN6MVSM2XHRA_1",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm really worried about the side effects of lectrazine. i've read that it can cause weight gain, hair loss, and headaches.",
							highLights: ["cause", "effects", "lectrazine", "side", "worried"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "yes, those are all potential side effects of lectrazine. however, they are all relatively rare. most people who take lectrazine don't experience any serious side effects.",
							highLights: ["effects", "lectrazine", "side"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "but what if i'm one of the people who does experience side effects ?",
							highLights: ["effects", "side"],
							dialogueNo: 3,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 04 May 2022 [10:31:55]",
					tags: ["Common Side Effects", "Inbound", "UNKNOWN", "Negative"],
					dialogueNo: 1,
					callId: "GY5L641CE6CYTX5M",
					callDate: "2022-05-04",
					callTime: "10:31:55",
					dialogueAppearedAt: 0,
					dialogueLength: 9.2,
					transcriptLength: 84.18,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve heard that it can cause some serious problems.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "yes, lectrazine can cause some serious side effects, but it\'s important to remember that it\'s also a very effective medication. if you\'re concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i don\'t know if i\'m comfortable taking a medication that could have such serious side effects.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/GY5L641CE6CYTX5M.json",
					elasticSearchId: "GY5L641CE6CYTX5M_1",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm really worried about the side effects of lectrazine. i've heard that it can cause some serious problems.",
							highLights: ["cause", "effects", "lectrazine", "side", "worried"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "yes, lectrazine can cause some serious side effects, but it's important to remember that it's also a very effective medication. if you're concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.",
							highLights: ["cause", "effects", "lectrazine", "side"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i don't know if i'm comfortable taking a medication that could have such serious side effects.",
							highLights: ["effects", "side"],
							dialogueNo: 3,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 09 May 2022 [21:20:36]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 1,
					callId: "WW8PUBYMYJOW4S8N",
					callDate: "2022-05-09",
					callTime: "21:20:36",
					dialogueAppearedAt: 0,
					dialogueLength: 9.66,
					transcriptLength: 118.68,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve been hearing a lot of bad things about it.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'ve been having a lot of headaches and dizziness since i started taking lectrazine. it\'s really starting to affect my quality of life.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
					elasticSearchId: "WW8PUBYMYJOW4S8N_1",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
							highLights: ["effects", "lectrazine", "side", "worried"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
							highLights: ["cause", "effects", "lectrazine", "side"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
							highLights: ["lectrazine"],
							dialogueNo: 3,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 4,
					name: "Transcript from 10 May 2022 [11:13:55]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 1,
					callId: "NEAEBCB3J820JCG7",
					callDate: "2022-05-10",
					callTime: "11:13:55",
					dialogueAppearedAt: 0,
					dialogueLength: 7.82,
					transcriptLength: 58.42,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'ve been taking lectrazine for a while now and i\'m starting to notice some adverse effects.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "what kind of adverse effects are you noticing ?", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'m having a lot of trouble sleeping, and i\'m really tired during the day. i\'m also having a lot of headaches.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/NEAEBCB3J820JCG7.json",
					elasticSearchId: "NEAEBCB3J820JCG7_1",
					transcripts: [
						{
							speaker: "Patient",
							text: "i've been taking lectrazine for a while now and i'm starting to notice some adverse effects.",
							highLights: ["effects", "lectrazine"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "what kind of adverse effects are you noticing ?",
							highLights: ["effects"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i'm having a lot of trouble sleeping, and i'm really tired during the day. i'm also having a lot of headaches.",
							highLights: [],
							dialogueNo: 3,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 5,
					name: "Transcript from 13 May 2022 [11:37:35]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 2,
					callId: "8VHNCXKTEO2KE9JF",
					callDate: "2022-05-13",
					callTime: "11:37:35",
					dialogueAppearedAt: 3.22,
					dialogueLength: 7.36,
					transcriptLength: 59.34,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hello, how are you today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m feeling okay, but i\'m a little concerned about the adverse events of lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "yes, lectrazine can cause some side effects, but they are usually mild and go away on their own.", "speaker": "Agent"}, {"dialogueNo": 4, "text": "what are the most common side effects ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/8VHNCXKTEO2KE9JF.json",
					elasticSearchId: "8VHNCXKTEO2KE9JF_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hello, how are you today ?",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "i'm feeling okay, but i'm a little concerned about the adverse events of lectrazine.",
							highLights: ["lectrazine"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "yes, lectrazine can cause some side effects, but they are usually mild and go away on their own.",
							highLights: ["cause", "effects", "lectrazine", "side"],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "what are the most common side effects ?",
							highLights: ["effects", "side"],
							dialogueNo: 4,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 6,
					name: "Transcript from 10 May 2022 [20:54:19]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 1,
					callId: "GSADKQ7M04XE0EZJ",
					callDate: "2022-05-10",
					callTime: "20:54:19",
					dialogueAppearedAt: 0,
					dialogueLength: 6.44,
					transcriptLength: 25.76,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, i\'m worried about the potential side effects of the drug lectrazine.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "hi. don\'t worry, the potential side effects of lectrazine are usually mild and go away on their own. however, if you experience any serious side effects, please contact your doctor immediately.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "ok, thank you.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/GSADKQ7M04XE0EZJ.json",
					elasticSearchId: "GSADKQ7M04XE0EZJ_1",
					transcripts: [
						{
							speaker: "Patient",
							text: "hi, i'm worried about the potential side effects of the drug lectrazine.",
							highLights: ["effects", "lectrazine", "side", "worried"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "hi. don't worry, the potential side effects of lectrazine are usually mild and go away on their own. however, if you experience any serious side effects, please contact your doctor immediately.",
							highLights: ["effects", "lectrazine", "side"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "ok, thank you.",
							highLights: [],
							dialogueNo: 3,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 7,
					name: "Transcript from 04 May 2022 [15:19:30]",
					tags: ["Others", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 3,
					callId: "R0NENK69XQCQ8VFH",
					callDate: "2022-05-04",
					callTime: "15:19:30",
					dialogueAppearedAt: 9.2,
					dialogueLength: 5.52,
					transcriptLength: 90.62,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, i\'m worried about the side effects of lectrazine.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "hi, what are you worried about specifically ?", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'ve heard that it can cause weight gain and hair loss.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "well, those are potential side effects, but they\'re not necessarily common. many people taking lectrazine don\'t experience any side effects at all.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "but what if i do experience them ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/R0NENK69XQCQ8VFH.json",
					elasticSearchId: "R0NENK69XQCQ8VFH_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "hi, i'm worried about the side effects of lectrazine.",
							highLights: ["effects", "lectrazine", "side", "worried"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "hi, what are you worried about specifically ?",
							highLights: ["worried"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i've heard that it can cause weight gain and hair loss.",
							highLights: ["cause"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "well, those are potential side effects, but they're not necessarily common. many people taking lectrazine don't experience any side effects at all.",
							highLights: ["effects", "lectrazine", "side"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "but what if i do experience them ?",
							highLights: [],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 3,
			topicName: "covered medication",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 25 May 2022 [15:30:20]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 4,
					callId: "F1T9KO8WBYD609GF",
					callDate: "2022-05-25",
					callTime: "15:30:20",
					dialogueAppearedAt: 16.1,
					dialogueLength: 11.5,
					transcriptLength: 119.14,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 2, "text": "i\'m just so frustrated. my insurance company denied my claim for lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me what happened ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "they said that it\'s not a covered medication. but i\'ve been taking it for years and it\'s the only thing that helps me.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "well, let\'s see if we can figure out what\'s going on. can you give me your insurance information ?", "speaker": "Agent"}, {"dialogueNo": 6, "text": "sure.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
					elasticSearchId: "F1T9KO8WBYD609GF_4",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
							highLights: [],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. can you tell me what happened ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
							highLights: ["medication"],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "well, let's see if we can figure out what's going on. can you give me your insurance information ?",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "sure.",
							highLights: [],
							dialogueNo: 6,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 12 May 2022 [15:40:06]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 3,
					callId: "JP6WSQ3VO38RD2YK",
					callDate: "2022-05-12",
					callTime: "15:40:06",
					dialogueAppearedAt: 36.8,
					dialogueLength: 9.2,
					transcriptLength: 64.4,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m really worried about the potential adverse effects of lectrazine. i\'ve heard that it can cause serious problems like heart attacks and strokes. is this true ?", "speaker": "Patient"}, {"dialogueNo": 2, "text": "there is always the potential for adverse effects with any medication, but the risk is usually very low with lectrazine. serious problems like heart attacks and strokes are very rare. the most common side effects of lectrazine are mild and include headache, nausea, and dizziness.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'m still really worried about taking this medication. are there any other risks i should be aware of ?", "speaker": "Patient"}, {"dialogueNo": 4, "text": "there are always risks associated with taking any medication, but the risks with lectrazine are very low. the most important thing is that you take the medication as directed and follow up with your doctor as recommended.", "speaker": "Agent"}]',
					s3path: "kural/processed_files/JP6WSQ3VO38RD2YK.json",
					elasticSearchId: "JP6WSQ3VO38RD2YK_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm really worried about the potential adverse effects of lectrazine. i've heard that it can cause serious problems like heart attacks and strokes. is this true ?",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "there is always the potential for adverse effects with any medication, but the risk is usually very low with lectrazine. serious problems like heart attacks and strokes are very rare. the most common side effects of lectrazine are mild and include headache, nausea, and dizziness.",
							highLights: ["medication"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i'm still really worried about taking this medication. are there any other risks i should be aware of ?",
							highLights: ["medication"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "there are always risks associated with taking any medication, but the risks with lectrazine are very low. the most important thing is that you take the medication as directed and follow up with your doctor as recommended.",
							highLights: ["medication"],
							dialogueNo: 4,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 25 May 2022 [20:15:09]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 6,
					callId: "O1388T9ZZ5BCLGTD",
					callDate: "2022-05-25",
					callTime: "20:15:09",
					dialogueAppearedAt: 39.1,
					dialogueLength: 9.2,
					transcriptLength: 75.44,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 4, "text": "i\'ve been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they\'re saying it\'s not medically necessary and they won\'t pay for it.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "that doesn\'t sound right. let me see if i can help you figure this out.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "i just don\'t understand why they would deny it. it\'s not like it\'s a luxury medication or anything.", "speaker": "Patient"}, {"dialogueNo": 7, "text": "well, sometimes insurance companies make decisions that don\'t make a lot of sense. but we can usually appeal those decisions.", "speaker": "Agent"}, {"dialogueNo": 8, "text": "really ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
					elasticSearchId: "O1388T9ZZ5BCLGTD_6",
					transcripts: [
						{
							speaker: "Patient",
							text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "that doesn't sound right. let me see if i can help you figure this out.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
							highLights: ["medication"],
							dialogueNo: 6,
						},
						{
							speaker: "Agent",
							text: "well, sometimes insurance companies make decisions that don't make a lot of sense. but we can usually appeal those decisions.",
							highLights: [],
							dialogueNo: 7,
						},
						{
							speaker: "Patient",
							text: "really ?",
							highLights: [],
							dialogueNo: 8,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 4,
			topicName: "feeling okay little",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 10 May 2022 [11:13:55]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 3,
					callId: "NEAEBCB3J820JCG7",
					callDate: "2022-05-10",
					callTime: "11:13:55",
					dialogueAppearedAt: 11.96,
					dialogueLength: 11.04,
					transcriptLength: 58.42,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'ve been taking lectrazine for a while now and i\'m starting to notice some adverse effects.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "what kind of adverse effects are you noticing ?", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'m having a lot of trouble sleeping, and i\'m really tired during the day. i\'m also having a lot of headaches.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "those are all common side effects of lectrazine. are you experiencing any other symptoms ?", "speaker": "Agent"}, {"dialogueNo": 5, "text": "yes, i\'m also having a lot of stomach pain and cramping.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/NEAEBCB3J820JCG7.json",
					elasticSearchId: "NEAEBCB3J820JCG7_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i've been taking lectrazine for a while now and i'm starting to notice some adverse effects.",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "what kind of adverse effects are you noticing ?",
							highLights: [],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i'm having a lot of trouble sleeping, and i'm really tired during the day. i'm also having a lot of headaches.",
							highLights: ["lot"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "those are all common side effects of lectrazine. are you experiencing any other symptoms ?",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "yes, i'm also having a lot of stomach pain and cramping.",
							highLights: ["lot"],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 17 May 2022 [19:35:23]",
					tags: ["Others", "Inbound", "UNKNOWN", "Positive"],
					dialogueNo: 2,
					callId: "8Y15TAMODO0ICF8X",
					callDate: "2022-05-17",
					callTime: "19:35:23",
					dialogueAppearedAt: 3.68,
					dialogueLength: 4.14,
					transcriptLength: 51.98,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how are you feeling today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m feeling okay, a little anxious though.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "that\'s understandable. are you taking your lectrazine medication as prescribed ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "yes, i am.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/8Y15TAMODO0ICF8X.json",
					elasticSearchId: "8Y15TAMODO0ICF8X_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how are you feeling today ?",
							highLights: ["feeling"],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "i'm feeling okay, a little anxious though.",
							highLights: ["feeling", "little", "though"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "that's understandable. are you taking your lectrazine medication as prescribed ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "yes, i am.",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 20 May 2022 [12:36:17]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 2,
					callId: "MZRFO0YWJPG4ZBCT",
					callDate: "2022-05-20",
					callTime: "12:36:17",
					dialogueAppearedAt: 3.68,
					dialogueLength: 4.14,
					transcriptLength: 42.78,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how are you feeling today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m feeling okay, a little tired though.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "that\'s to be expected. lectrazine can cause fatigue as a side effect. are you having any other problems ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "no, not really. just wondering what kind of beverages i can have while i\'m on this medication.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/MZRFO0YWJPG4ZBCT.json",
					elasticSearchId: "MZRFO0YWJPG4ZBCT_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how are you feeling today ?",
							highLights: ["feeling"],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "i'm feeling okay, a little tired though.",
							highLights: ["feeling", "little", "though"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "that's to be expected. lectrazine can cause fatigue as a side effect. are you having any other problems ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "no, not really. just wondering what kind of beverages i can have while i'm on this medication.",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 5,
			topicName: "kind beverages lectrazine",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 20 May 2022 [13:46:44]",
					tags: ["Others", "Inbound", "UNKNOWN", "Neutral"],
					dialogueNo: 2,
					callId: "C84A5M6B57JR21D1",
					callDate: "2022-05-20",
					callTime: "13:46:44",
					dialogueAppearedAt: 3.68,
					dialogueLength: 9.2,
					transcriptLength: 37.26,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how are you feeling today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m feeling okay, but i\'m wondering what kind of beverages i can have while i\'m on lectrazine medication.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "that\'s a good question. let me check with the doctor and see what he says. i\'ll be right back.", "speaker": "Agent"}, {"dialogueNo": 4, "text": "thank you.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/C84A5M6B57JR21D1.json",
					elasticSearchId: "C84A5M6B57JR21D1_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how are you feeling today ?",
							highLights: ["feeling"],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "i'm feeling okay, but i'm wondering what kind of beverages i can have while i'm on lectrazine medication.",
							highLights: ["beverages", "feeling", "kind", "medication", "wondering"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "that's a good question. let me check with the doctor and see what he says. i'll be right back.",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "thank you.",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 25 May 2022 [13:46:11]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 2,
					callId: "DK81OEG78R6TIOA8",
					callDate: "2022-05-25",
					callTime: "13:46:11",
					dialogueAppearedAt: 3.68,
					dialogueLength: 9.2,
					transcriptLength: 40.48,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how are you feeling today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m feeling okay, but i\'m wondering what kind of food i can eat while i\'m on lectrazine medication.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "that\'s a good question. let me check with the doctor and see what he says. i\'ll be right back.", "speaker": "Agent"}, {"dialogueNo": 4, "text": "okay, thank you.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/DK81OEG78R6TIOA8.json",
					elasticSearchId: "DK81OEG78R6TIOA8_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how are you feeling today ?",
							highLights: ["feeling"],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "i'm feeling okay, but i'm wondering what kind of food i can eat while i'm on lectrazine medication.",
							highLights: ["feeling", "kind", "medication", "wondering"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "that's a good question. let me check with the doctor and see what he says. i'll be right back.",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "okay, thank you.",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 20 May 2022 [12:36:17]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 4,
					callId: "MZRFO0YWJPG4ZBCT",
					callDate: "2022-05-20",
					callTime: "12:36:17",
					dialogueAppearedAt: 17.48,
					dialogueLength: 9.2,
					transcriptLength: 42.78,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [
						{
							storyId: "story_14",
							storyName: "alcohol",
						},
					],
					addedToStory: "true",
					context:
						'[{"dialogueNo": 2, "text": "i\'m feeling okay, a little tired though.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "that\'s to be expected. lectrazine can cause fatigue as a side effect. are you having any other problems ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "no, not really. just wondering what kind of beverages i can have while i\'m on this medication.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "well, you should avoid alcohol and caffeine while you\'re taking lectrazine. other than that, you can pretty much drink whatever you want.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "okay, that\'s good to know. thanks.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/MZRFO0YWJPG4ZBCT.json",
					elasticSearchId: "MZRFO0YWJPG4ZBCT_4",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm feeling okay, a little tired though.",
							highLights: ["feeling"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "that's to be expected. lectrazine can cause fatigue as a side effect. are you having any other problems ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "no, not really. just wondering what kind of beverages i can have while i'm on this medication.",
							highLights: ["beverages", "kind", "medication", "wondering"],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "well, you should avoid alcohol and caffeine while you're taking lectrazine. other than that, you can pretty much drink whatever you want.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "okay, that's good to know. thanks.",
							highLights: [],
							dialogueNo: 6,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 6,
			topicName: "swallow water just",
			numberOfSearchResults: 1,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 25 May 2022 [15:30:20]",
					tags: ["Others", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 7,
					callId: "BNZ5Z47EQY4Y8TNY",
					callDate: "2022-05-25",
					callTime: "15:30:20",
					dialogueAppearedAt: 30.36,
					dialogueLength: 11.5,
					transcriptLength: 55.2,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 5, "text": "yes, i take them with a glass of water.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "have you been having any trouble swallowing the pills with water ?", "speaker": "Agent"}, {"dialogueNo": 7, "text": "no, i can swallow them with water just fine. it\'s just that they\'re so big, it\'s hard to get them down.", "speaker": "Patient"}, {"dialogueNo": 8, "text": "i see. well, let me see if i can find a smaller pill for you.", "speaker": "Agent"}, {"dialogueNo": 9, "text": "that would be great. thank you.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/BNZ5Z47EQY4Y8TNY.json",
					elasticSearchId: "BNZ5Z47EQY4Y8TNY_7",
					transcripts: [
						{
							speaker: "Patient",
							text: "yes, i take them with a glass of water.",
							highLights: ["water"],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "have you been having any trouble swallowing the pills with water ?",
							highLights: ["water"],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "no, i can swallow them with water just fine. it's just that they're so big, it's hard to get them down.",
							highLights: ["water"],
							dialogueNo: 7,
						},
						{
							speaker: "Agent",
							text: "i see. well, let me see if i can find a smaller pill for you.",
							highLights: [],
							dialogueNo: 8,
						},
						{
							speaker: "Patient",
							text: "that would be great. thank you.",
							highLights: [],
							dialogueNo: 9,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 25 May 2022 [20:15:09]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 3,
					callId: "44566M0XUP028RGH",
					callDate: "2022-05-25",
					callTime: "20:15:09",
					dialogueAppearedAt: 19.32,
					dialogueLength: 11.5,
					transcriptLength: 61.64,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m having a hard time swallowing these lectrazine pills. they\'re just too big.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "yes, the lectrazine pills can be quite large. but, they\'re designed that way so that they can be taken just once a day.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i know, but it\'s still hard for me to swallow them. do you have any tips on how to make it easier ?", "speaker": "Patient"}, {"dialogueNo": 4, "text": "well, first of all, make sure that you take the pill with plenty of water. that will help it to go down more easily. secondly, you can try crushing the pill and mixing it with a little bit of food or drink. that might make it easier to take.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "okay, i\'ll try that. thanks for the advice.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/44566M0XUP028RGH.json",
					elasticSearchId: "44566M0XUP028RGH_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm having a hard time swallowing these lectrazine pills. they're just too big.",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "yes, the lectrazine pills can be quite large. but, they're designed that way so that they can be taken just once a day.",
							highLights: [],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i know, but it's still hard for me to swallow them. do you have any tips on how to make it easier ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "well, first of all, make sure that you take the pill with plenty of water. that will help it to go down more easily. secondly, you can try crushing the pill and mixing it with a little bit of food or drink. that might make it easier to take.",
							highLights: ["water"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "okay, i'll try that. thanks for the advice.",
							highLights: [],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 25 May 2022 [15:30:20]",
					tags: ["Dosing Information", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 5,
					callId: "BNZ5Z47EQY4Y8TNY",
					callDate: "2022-05-25",
					callTime: "15:30:20",
					dialogueAppearedAt: 19.78,
					dialogueLength: 5.06,
					transcriptLength: 55.2,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 3, "text": "yes, i take one pill every day.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "and how have you been taking them ? with water ?", "speaker": "Agent"}, {"dialogueNo": 5, "text": "yes, i take them with a glass of water.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "have you been having any trouble swallowing the pills with water ?", "speaker": "Agent"}, {"dialogueNo": 7, "text": "no, i can swallow them with water just fine. it\'s just that they\'re so big, it\'s hard to get them down.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/BNZ5Z47EQY4Y8TNY.json",
					elasticSearchId: "BNZ5Z47EQY4Y8TNY_5",
					transcripts: [
						{
							speaker: "Patient",
							text: "yes, i take one pill every day.",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "and how have you been taking them ? with water ?",
							highLights: ["water"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "yes, i take them with a glass of water.",
							highLights: ["water"],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "have you been having any trouble swallowing the pills with water ?",
							highLights: ["water"],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "no, i can swallow them with water just fine. it's just that they're so big, it's hard to get them down.",
							highLights: ["water"],
							dialogueNo: 7,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 7,
			topicName: "medication feeling okay",
			numberOfSearchResults: 5,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 04 May 2022 [10:31:55]",
					tags: ["Common Side Effects", "Inbound", "UNKNOWN", "Negative"],
					dialogueNo: 3,
					callId: "GY5L641CE6CYTX5M",
					callDate: "2022-05-04",
					callTime: "10:31:55",
					dialogueAppearedAt: 33.58,
					dialogueLength: 7.82,
					transcriptLength: 84.18,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve heard that it can cause some serious problems.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "yes, lectrazine can cause some serious side effects, but it\'s important to remember that it\'s also a very effective medication. if you\'re concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i don\'t know if i\'m comfortable taking a medication that could have such serious side effects.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "it\'s understandable to feel that way. can you tell me more about your concerns ?", "speaker": "Agent"}, {"dialogueNo": 5, "text": "i\'ve heard that lectrazine can cause problems with your heart and i don\'t want to risk that.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/GY5L641CE6CYTX5M.json",
					elasticSearchId: "GY5L641CE6CYTX5M_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm really worried about the side effects of lectrazine. i've heard that it can cause some serious problems.",
							highLights: ["worried"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "yes, lectrazine can cause some serious side effects, but it's important to remember that it's also a very effective medication. if you're concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.",
							highLights: ["medication"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "i don't know if i'm comfortable taking a medication that could have such serious side effects.",
							highLights: ["medication"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "it's understandable to feel that way. can you tell me more about your concerns ?",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "i've heard that lectrazine can cause problems with your heart and i don't want to risk that.",
							highLights: [],
							dialogueNo: 5,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 04 May 2022 [20:51:24]",
					tags: ["Common Side Effects", "Inbound", "UNKNOWN", "Negative"],
					dialogueNo: 5,
					callId: "3RPP6PETFB2CYJFW",
					callDate: "2022-05-04",
					callTime: "20:51:24",
					dialogueAppearedAt: 52.44,
					dialogueLength: 6.9,
					transcriptLength: 174.73,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 3, "text": "what are the potential side effects ?", "speaker": "Patient"}, {"dialogueNo": 4, "text": "the potential side effects of lectrazine include headache, nausea, vomiting, diarrhea, constipation, stomach pain, loss of appetite, weight loss, tiredness, trouble sleeping, anxiety, irritability, and dizziness.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "that\'s a lot of potential side effects! i\'m really worried about taking this medication.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "yes, it\'s important to be aware of the potential side effects. but again, everyone reacts differently to medication. some people may experience no side effects, while others may only experience one or two of the potential side effects.", "speaker": "Agent"}, {"dialogueNo": 7, "text": "is there anything i can do to reduce the risk of side effects ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/3RPP6PETFB2CYJFW.json",
					elasticSearchId: "3RPP6PETFB2CYJFW_5",
					transcripts: [
						{
							speaker: "Patient",
							text: "what are the potential side effects ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "the potential side effects of lectrazine include headache, nausea, vomiting, diarrhea, constipation, stomach pain, loss of appetite, weight loss, tiredness, trouble sleeping, anxiety, irritability, and dizziness.",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "that's a lot of potential side effects! i'm really worried about taking this medication.",
							highLights: ["medication", "worried"],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "yes, it's important to be aware of the potential side effects. but again, everyone reacts differently to medication. some people may experience no side effects, while others may only experience one or two of the potential side effects.",
							highLights: ["medication"],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "is there anything i can do to reduce the risk of side effects ?",
							highLights: [],
							dialogueNo: 7,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 04 May 2022 [18:32:15]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 5,
					callId: "SR0ANN6MVSM2XHRA",
					callDate: "2022-05-04",
					callTime: "18:32:15",
					dialogueAppearedAt: 48.76,
					dialogueLength: 7.82,
					transcriptLength: 126.5,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 3, "text": "but what if i\'m one of the people who does experience side effects ?", "speaker": "Patient"}, {"dialogueNo": 4, "text": "well, if you do experience any side effects, it\'s important to contact your doctor or healthcare provider. they will be able to help you manage any side effects that you may experience.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "i\'m just really worried that i\'m going to gain a lot of weight on this medication.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "like i said, weight gain is a potential side effect of lectrazine. however, it is not a common side effect. and, even if you do gain some weight, it is usually not a lot of weight.", "speaker": "Agent"}, {"dialogueNo": 7, "text": "but what if i gain a lot of weight ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/SR0ANN6MVSM2XHRA.json",
					elasticSearchId: "SR0ANN6MVSM2XHRA_5",
					transcripts: [
						{
							speaker: "Patient",
							text: "but what if i'm one of the people who does experience side effects ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "well, if you do experience any side effects, it's important to contact your doctor or healthcare provider. they will be able to help you manage any side effects that you may experience.",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "i'm just really worried that i'm going to gain a lot of weight on this medication.",
							highLights: ["medication", "worried"],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "like i said, weight gain is a potential side effect of lectrazine. however, it is not a common side effect. and, even if you do gain some weight, it is usually not a lot of weight.",
							highLights: [],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "but what if i gain a lot of weight ?",
							highLights: [],
							dialogueNo: 7,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 4,
					name: "Transcript from 18 May 2022 [12:05:51]",
					tags: ["Others", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 2,
					callId: "S8485JVBQY6HYDJP",
					callDate: "2022-05-18",
					callTime: "12:05:51",
					dialogueAppearedAt: 3.68,
					dialogueLength: 5.98,
					transcriptLength: 35.42,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how are you feeling today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m feeling okay, but i have a question about my medication.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "sure, what\'s your question ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "is it okay to drink alcohol while taking lectrazine ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/S8485JVBQY6HYDJP.json",
					elasticSearchId: "S8485JVBQY6HYDJP_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how are you feeling today ?",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "i'm feeling okay, but i have a question about my medication.",
							highLights: ["medication"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "sure, what's your question ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "is it okay to drink alcohol while taking lectrazine ?",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 5,
					name: "Transcript from 05 May 2022 [16:16:26]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 7,
					callId: "KDX8UN2QX8FB21MP",
					callDate: "2022-05-05",
					callTime: "16:16:26",
					dialogueAppearedAt: 68.08,
					dialogueLength: 5.52,
					transcriptLength: 104.88,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 5, "text": "i don\'t know. i would probably feel really bad and maybe even want to hurt myself.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "okay, those are valid concerns. but it\'s important to remember that lectrazine is just a medication. it\'s not going to control your thoughts or your emotions. you\'re still in control of those.", "speaker": "Agent"}, {"dialogueNo": 7, "text": "but what if the medication makes me feel out of control ?", "speaker": "Patient"}, {"dialogueNo": 8, "text": "that can happen, but it\'s rare. and if it does happen, we can work with you to help you manage it.", "speaker": "Agent"}, {"dialogueNo": 9, "text": "i don\'t know. i\'m just really worried about it.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/KDX8UN2QX8FB21MP.json",
					elasticSearchId: "KDX8UN2QX8FB21MP_7",
					transcripts: [
						{
							speaker: "Patient",
							text: "i don't know. i would probably feel really bad and maybe even want to hurt myself.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "okay, those are valid concerns. but it's important to remember that lectrazine is just a medication. it's not going to control your thoughts or your emotions. you're still in control of those.",
							highLights: ["medication"],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "but what if the medication makes me feel out of control ?",
							highLights: ["medication"],
							dialogueNo: 7,
						},
						{
							speaker: "Agent",
							text: "that can happen, but it's rare. and if it does happen, we can work with you to help you manage it.",
							highLights: [],
							dialogueNo: 8,
						},
						{
							speaker: "Patient",
							text: "i don't know. i'm just really worried about it.",
							highLights: ["worried"],
							dialogueNo: 9,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 6,
					name: "Transcript from 27 May 2022 [11:42:35]",
					tags: ["Dosing Information", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 6,
					callId: "5R3O667MNNDC6HWK",
					callDate: "2022-05-27",
					callTime: "11:42:35",
					dialogueAppearedAt: 23.92,
					dialogueLength: 3.22,
					transcriptLength: 76.82,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 4, "text": "okay, so i should take one pill every day ?", "speaker": "Patient"}, {"dialogueNo": 5, "text": "yes, that\'s right.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "what if i miss a dose ?", "speaker": "Patient"}, {"dialogueNo": 7, "text": "if you miss a dose, you can take it as soon as you remember. however, if it is close to the time for your next dose, you can skip the missed dose and just take your next dose at the regular time.", "speaker": "Agent"}, {"dialogueNo": 8, "text": "what are the side effects of lectrazine ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/5R3O667MNNDC6HWK.json",
					elasticSearchId: "5R3O667MNNDC6HWK_6",
					transcripts: [
						{
							speaker: "Patient",
							text: "okay, so i should take one pill every day ?",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "yes, that's right.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "what if i miss a dose ?",
							highLights: [],
							dialogueNo: 6,
						},
						{
							speaker: "Agent",
							text: "if you miss a dose, you can take it as soon as you remember. however, if it is close to the time for your next dose, you can skip the missed dose and just take your next dose at the regular time.",
							highLights: [],
							dialogueNo: 7,
						},
						{
							speaker: "Patient",
							text: "what are the side effects of lectrazine ?",
							highLights: [],
							dialogueNo: 8,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 8,
			topicName: "good lectrazine pills",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 23 May 2022 [12:11:21]",
					tags: ["Others", "Inbound", "UNKNOWN", "Negative"],
					dialogueNo: 2,
					callId: "UP3DS2H374JBNPNW",
					callDate: "2022-05-23",
					callTime: "12:11:21",
					dialogueAppearedAt: 3.68,
					dialogueLength: 8.74,
					transcriptLength: 98.44,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how are you feeling today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "not great. i\'m still waiting for my lectrazine medication to arrive and it\'s been over a week.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me a little bit more about your symptoms ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "i\'m feeling really anxious and my heart is racing. i can\'t sleep or eat and i\'m just really frustrated.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/UP3DS2H374JBNPNW.json",
					elasticSearchId: "UP3DS2H374JBNPNW_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how are you feeling today ?",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "not great. i'm still waiting for my lectrazine medication to arrive and it's been over a week.",
							highLights: ["lectrazine"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. can you tell me a little bit more about your symptoms ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "i'm feeling really anxious and my heart is racing. i can't sleep or eat and i'm just really frustrated.",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 27 May 2022 [11:42:35]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 2,
					callId: "9JUQYD1A5WNKLC9M",
					callDate: "2022-05-27",
					callTime: "11:42:35",
					dialogueAppearedAt: 3.68,
					dialogueLength: 8.28,
					transcriptLength: 51.06,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "hi, how are you doing today ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "not too good. these lectrazine pills are huge and i can\'t seem to get them down.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. let me see if i can help.", "speaker": "Agent"}, {"dialogueNo": 4, "text": "thank you.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/9JUQYD1A5WNKLC9M.json",
					elasticSearchId: "9JUQYD1A5WNKLC9M_2",
					transcripts: [
						{
							speaker: "Agent",
							text: "hi, how are you doing today ?",
							highLights: [],
							dialogueNo: 1,
						},
						{
							speaker: "Patient",
							text: "not too good. these lectrazine pills are huge and i can't seem to get them down.",
							highLights: ["lectrazine", "pills"],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "i'm sorry to hear that. let me see if i can help.",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "thank you.",
							highLights: [],
							dialogueNo: 4,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 25 May 2022 [15:30:20]",
					tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 1,
					callId: "BNZ5Z47EQY4Y8TNY",
					callDate: "2022-05-25",
					callTime: "15:30:20",
					dialogueAppearedAt: 0,
					dialogueLength: 6.9,
					transcriptLength: 55.2,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m having a hard time swallowing these lectrazine pills. they\'re just too big.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "have you been taking them as prescribed ?", "speaker": "Agent"}, {"dialogueNo": 3, "text": "yes, i take one pill every day.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/BNZ5Z47EQY4Y8TNY.json",
					elasticSearchId: "BNZ5Z47EQY4Y8TNY_1",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm having a hard time swallowing these lectrazine pills. they're just too big.",
							highLights: ["lectrazine", "pills"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "have you been taking them as prescribed ?",
							highLights: [],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "yes, i take one pill every day.",
							highLights: ["pill"],
							dialogueNo: 3,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 9,
			topicName: "try thanks advice",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 04 May 2022 [15:19:30]",
					tags: ["Others", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 9,
					callId: "R0NENK69XQCQ8VFH",
					callDate: "2022-05-04",
					callTime: "15:19:30",
					dialogueAppearedAt: 67.62,
					dialogueLength: 8.74,
					transcriptLength: 90.62,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 7, "text": "i\'m just worried that i\'ll start taking this medication and then have to deal with all of these side effects.", "speaker": "Patient"}, {"dialogueNo": 8, "text": "it\'s understandable to be worried about potential side effects, but it\'s important to remember that lectrazine is generally a very safe and effective medication.", "speaker": "Agent"}, {"dialogueNo": 9, "text": "i guess i\'ll just have to trust that it will work for me and hope for the best.", "speaker": "Patient"}, {"dialogueNo": 10, "text": "that\'s a good attitude to have. just remember to stay in close communication with your doctor or pharmacist and let them know if you do experience any side effects.", "speaker": "Agent"}]',
					s3path: "kural/processed_files/R0NENK69XQCQ8VFH.json",
					elasticSearchId: "R0NENK69XQCQ8VFH_9",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm just worried that i'll start taking this medication and then have to deal with all of these side effects.",
							highLights: [],
							dialogueNo: 7,
						},
						{
							speaker: "Agent",
							text: "it's understandable to be worried about potential side effects, but it's important to remember that lectrazine is generally a very safe and effective medication.",
							highLights: [],
							dialogueNo: 8,
						},
						{
							speaker: "Patient",
							text: "i guess i'll just have to trust that it will work for me and hope for the best.",
							highLights: [],
							dialogueNo: 9,
						},
						{
							speaker: "Agent",
							text: "that's a good attitude to have. just remember to stay in close communication with your doctor or pharmacist and let them know if you do experience any side effects.",
							highLights: [],
							dialogueNo: 10,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 25 May 2022 [20:15:09]",
					tags: ["Others", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 10,
					callId: "O1388T9ZZ5BCLGTD",
					callDate: "2022-05-25",
					callTime: "20:15:09",
					dialogueAppearedAt: 70.38,
					dialogueLength: 5.06,
					transcriptLength: 75.44,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 8, "text": "really ?", "speaker": "Patient"}, {"dialogueNo": 9, "text": "yes, let me help you fill out the paperwork. it\'s not complicated, but it can be a little time-consuming.", "speaker": "Agent"}, {"dialogueNo": 10, "text": "thank you so much. i really appreciate your help.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
					elasticSearchId: "O1388T9ZZ5BCLGTD_10",
					transcripts: [
						{
							speaker: "Patient",
							text: "really ?",
							highLights: [],
							dialogueNo: 8,
						},
						{
							speaker: "Agent",
							text: "yes, let me help you fill out the paperwork. it's not complicated, but it can be a little time-consuming.",
							highLights: ["help"],
							dialogueNo: 9,
						},
						{
							speaker: "Patient",
							text: "thank you so much. i really appreciate your help.",
							highLights: ["help", "much"],
							dialogueNo: 10,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 10 May 2022 [11:13:55]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 7,
					callId: "NEAEBCB3J820JCG7",
					callDate: "2022-05-10",
					callTime: "11:13:55",
					dialogueAppearedAt: 52.9,
					dialogueLength: 5.52,
					transcriptLength: 58.42,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 5, "text": "yes, i\'m also having a lot of stomach pain and cramping.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "that\'s not a common side effect of lectrazine, but it is a possible one. if you\'re experiencing severe stomach pain or cramping, you should stop taking the medication and call your doctor.", "speaker": "Agent"}, {"dialogueNo": 7, "text": "okay, i\'ll do that. thank you for your help.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/NEAEBCB3J820JCG7.json",
					elasticSearchId: "NEAEBCB3J820JCG7_7",
					transcripts: [
						{
							speaker: "Patient",
							text: "yes, i'm also having a lot of stomach pain and cramping.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "that's not a common side effect of lectrazine, but it is a possible one. if you're experiencing severe stomach pain or cramping, you should stop taking the medication and call your doctor.",
							highLights: [],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "okay, i'll do that. thank you for your help.",
							highLights: ["help"],
							dialogueNo: 7,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 4,
					name: "Transcript from 25 May 2022 [20:15:09]",
					tags: ["Others", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 5,
					callId: "44566M0XUP028RGH",
					callDate: "2022-05-25",
					callTime: "20:15:09",
					dialogueAppearedAt: 56.58,
					dialogueLength: 5.06,
					transcriptLength: 61.64,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 3, "text": "i know, but it\'s still hard for me to swallow them. do you have any tips on how to make it easier ?", "speaker": "Patient"}, {"dialogueNo": 4, "text": "well, first of all, make sure that you take the pill with plenty of water. that will help it to go down more easily. secondly, you can try crushing the pill and mixing it with a little bit of food or drink. that might make it easier to take.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "okay, i\'ll try that. thanks for the advice.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/44566M0XUP028RGH.json",
					elasticSearchId: "44566M0XUP028RGH_5",
					transcripts: [
						{
							speaker: "Patient",
							text: "i know, but it's still hard for me to swallow them. do you have any tips on how to make it easier ?",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "well, first of all, make sure that you take the pill with plenty of water. that will help it to go down more easily. secondly, you can try crushing the pill and mixing it with a little bit of food or drink. that might make it easier to take.",
							highLights: ["help"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "okay, i'll try that. thanks for the advice.",
							highLights: [],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 5,
					name: "Transcript from 17 May 2022 [19:35:23]",
					tags: ["Dosing Information", "Inbound", "UNKNOWN", "Positive"],
					dialogueNo: 10,
					callId: "8Y15TAMODO0ICF8X",
					callDate: "2022-05-17",
					callTime: "19:35:23",
					dialogueAppearedAt: 48.3,
					dialogueLength: 3.68,
					transcriptLength: 51.98,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 8, "text": "okay, i\'ll try to avoid it.", "speaker": "Patient"}, {"dialogueNo": 9, "text": "if you do drink, make sure to drink in moderation and to stay hydrated.", "speaker": "Agent"}, {"dialogueNo": 10, "text": "i will. thanks for the advice.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/8Y15TAMODO0ICF8X.json",
					elasticSearchId: "8Y15TAMODO0ICF8X_10",
					transcripts: [
						{
							speaker: "Patient",
							text: "okay, i'll try to avoid it.",
							highLights: [],
							dialogueNo: 8,
						},
						{
							speaker: "Agent",
							text: "if you do drink, make sure to drink in moderation and to stay hydrated.",
							highLights: [],
							dialogueNo: 9,
						},
						{
							speaker: "Patient",
							text: "i will. thanks for the advice.",
							highLights: [],
							dialogueNo: 10,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 10,
			topicName: "thank help okay",
			numberOfSearchResults: 2,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 26 May 2022 [12:11:54]",
					tags: ["Dosing Information", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 10,
					callId: "UPZ2ANPEVNWZG44T",
					callDate: "2022-05-26",
					callTime: "12:11:54",
					dialogueAppearedAt: 65.78,
					dialogueLength: 6.44,
					transcriptLength: 72.22,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 8, "text": "are there any other things i should be aware of while taking this medication ?", "speaker": "Patient"}, {"dialogueNo": 9, "text": "yes, it\'s important to drink plenty of fluids while taking lectrazine to prevent dehydration. you should also avoid alcohol and caffeine while taking this medication.", "speaker": "Agent"}, {"dialogueNo": 10, "text": "alright, i\'ll keep that in mind. thank you for your help.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/UPZ2ANPEVNWZG44T.json",
					elasticSearchId: "UPZ2ANPEVNWZG44T_10",
					transcripts: [
						{
							speaker: "Patient",
							text: "are there any other things i should be aware of while taking this medication ?",
							highLights: [],
							dialogueNo: 8,
						},
						{
							speaker: "Agent",
							text: "yes, it's important to drink plenty of fluids while taking lectrazine to prevent dehydration. you should also avoid alcohol and caffeine while taking this medication.",
							highLights: [],
							dialogueNo: 9,
						},
						{
							speaker: "Patient",
							text: "alright, i'll keep that in mind. thank you for your help.",
							highLights: ["alright", "help"],
							dialogueNo: 10,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 11 May 2022 [17:42:06]",
					tags: ["Others", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 5,
					callId: "TEWL7ZZXE9K06XJ4",
					callDate: "2022-05-11",
					callTime: "17:42:06",
					dialogueAppearedAt: 52.44,
					dialogueLength: 5.52,
					transcriptLength: 57.96,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 3, "text": "that sounds really scary. i\'m thinking about taking the drug, but i\'m worried about the side effects.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "i understand your concerns. however, you should speak with your doctor before making any decisions. they can help you weigh the risks and benefits of taking lectrazine.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "alright, i\'ll do that. thank you for your help.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/TEWL7ZZXE9K06XJ4.json",
					elasticSearchId: "TEWL7ZZXE9K06XJ4_5",
					transcripts: [
						{
							speaker: "Patient",
							text: "that sounds really scary. i'm thinking about taking the drug, but i'm worried about the side effects.",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "i understand your concerns. however, you should speak with your doctor before making any decisions. they can help you weigh the risks and benefits of taking lectrazine.",
							highLights: ["help"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "alright, i'll do that. thank you for your help.",
							highLights: ["alright", "help"],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 20 May 2022 [12:36:17]",
					tags: ["Dosing Information", "Outbound", "UNKNOWN", "Positive"],
					dialogueNo: 6,
					callId: "MZRFO0YWJPG4ZBCT",
					callDate: "2022-05-20",
					callTime: "12:36:17",
					dialogueAppearedAt: 38.64,
					dialogueLength: 4.14,
					transcriptLength: 42.78,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 4, "text": "no, not really. just wondering what kind of beverages i can have while i\'m on this medication.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "well, you should avoid alcohol and caffeine while you\'re taking lectrazine. other than that, you can pretty much drink whatever you want.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "okay, that\'s good to know. thanks.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/MZRFO0YWJPG4ZBCT.json",
					elasticSearchId: "MZRFO0YWJPG4ZBCT_6",
					transcripts: [
						{
							speaker: "Patient",
							text: "no, not really. just wondering what kind of beverages i can have while i'm on this medication.",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "well, you should avoid alcohol and caffeine while you're taking lectrazine. other than that, you can pretty much drink whatever you want.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "okay, that's good to know. thanks.",
							highLights: [],
							dialogueNo: 6,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 11,
			topicName: "possible effects medication",
			numberOfSearchResults: 3,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 10 May 2022 [11:13:55]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 5,
					callId: "NEAEBCB3J820JCG7",
					callDate: "2022-05-10",
					callTime: "11:13:55",
					dialogueAppearedAt: 30.36,
					dialogueLength: 5.98,
					transcriptLength: 58.42,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 3, "text": "i\'m having a lot of trouble sleeping, and i\'m really tired during the day. i\'m also having a lot of headaches.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "those are all common side effects of lectrazine. are you experiencing any other symptoms ?", "speaker": "Agent"}, {"dialogueNo": 5, "text": "yes, i\'m also having a lot of stomach pain and cramping.", "speaker": "Patient"}, {"dialogueNo": 6, "text": "that\'s not a common side effect of lectrazine, but it is a possible one. if you\'re experiencing severe stomach pain or cramping, you should stop taking the medication and call your doctor.", "speaker": "Agent"}, {"dialogueNo": 7, "text": "okay, i\'ll do that. thank you for your help.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/NEAEBCB3J820JCG7.json",
					elasticSearchId: "NEAEBCB3J820JCG7_5",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm having a lot of trouble sleeping, and i'm really tired during the day. i'm also having a lot of headaches.",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "those are all common side effects of lectrazine. are you experiencing any other symptoms ?",
							highLights: ["effects", "side"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "yes, i'm also having a lot of stomach pain and cramping.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Agent",
							text: "that's not a common side effect of lectrazine, but it is a possible one. if you're experiencing severe stomach pain or cramping, you should stop taking the medication and call your doctor.",
							highLights: ["effect", "side"],
							dialogueNo: 6,
						},
						{
							speaker: "Patient",
							text: "okay, i'll do that. thank you for your help.",
							highLights: [],
							dialogueNo: 7,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 26 May 2022 [12:11:54]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 6,
					callId: "UPZ2ANPEVNWZG44T",
					callDate: "2022-05-26",
					callTime: "12:11:54",
					dialogueAppearedAt: 28.06,
					dialogueLength: 4.6,
					transcriptLength: 72.22,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 4, "text": "okay, so i should take one pill every day ?", "speaker": "Patient"}, {"dialogueNo": 5, "text": "yes, that\'s right. you can take it with or without food.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "what are the possible side effects of this medication ?", "speaker": "Patient"}, {"dialogueNo": 7, "text": "some common side effects of lectrazine include headache, dizziness, and fatigue. if you experience any of these side effects, please let your doctor know.", "speaker": "Agent"}, {"dialogueNo": 8, "text": "are there any other things i should be aware of while taking this medication ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/UPZ2ANPEVNWZG44T.json",
					elasticSearchId: "UPZ2ANPEVNWZG44T_6",
					transcripts: [
						{
							speaker: "Patient",
							text: "okay, so i should take one pill every day ?",
							highLights: [],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "yes, that's right. you can take it with or without food.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "what are the possible side effects of this medication ?",
							highLights: ["effects", "side"],
							dialogueNo: 6,
						},
						{
							speaker: "Agent",
							text: "some common side effects of lectrazine include headache, dizziness, and fatigue. if you experience any of these side effects, please let your doctor know.",
							highLights: ["effects", "side"],
							dialogueNo: 7,
						},
						{
							speaker: "Patient",
							text: "are there any other things i should be aware of while taking this medication ?",
							highLights: [],
							dialogueNo: 8,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 3,
					name: "Transcript from 13 May 2022 [11:37:35]",
					tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
					dialogueNo: 6,
					callId: "8VHNCXKTEO2KE9JF",
					callDate: "2022-05-13",
					callTime: "11:37:35",
					dialogueAppearedAt: 29.9,
					dialogueLength: 3.68,
					transcriptLength: 59.34,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 4, "text": "what are the most common side effects ?", "speaker": "Patient"}, {"dialogueNo": 5, "text": "the most common side effects are headaches, dizziness, and nausea.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "are there any more serious side effects ?", "speaker": "Patient"}, {"dialogueNo": 7, "text": "some people have reported more serious side effects, such as seizures and liver damage, but these are very rare.", "speaker": "Agent"}, {"dialogueNo": 8, "text": "should i be worried about taking lectrazine ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/8VHNCXKTEO2KE9JF.json",
					elasticSearchId: "8VHNCXKTEO2KE9JF_6",
					transcripts: [
						{
							speaker: "Patient",
							text: "what are the most common side effects ?",
							highLights: ["effects", "side"],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "the most common side effects are headaches, dizziness, and nausea.",
							highLights: ["effects", "side"],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "are there any more serious side effects ?",
							highLights: ["effects", "side"],
							dialogueNo: 6,
						},
						{
							speaker: "Agent",
							text: "some people have reported more serious side effects, such as seizures and liver damage, but these are very rare.",
							highLights: ["effects", "side"],
							dialogueNo: 7,
						},
						{
							speaker: "Patient",
							text: "should i be worried about taking lectrazine ?",
							highLights: [],
							dialogueNo: 8,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 4,
					name: "Transcript from 04 May 2022 [20:51:24]",
					tags: ["Common Side Effects", "Inbound", "UNKNOWN", "Neutral"],
					dialogueNo: 3,
					callId: "3RPP6PETFB2CYJFW",
					callDate: "2022-05-04",
					callTime: "20:51:24",
					dialogueAppearedAt: 31.28,
					dialogueLength: 3.22,
					transcriptLength: 174.73,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [
						{
							storyId: "story_11",
							storyName: "side effects",
						},
					],
					addedToStory: "true",
					context:
						'[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve been hearing a lot of bad things about it.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "yes, there are some potential side effects with lectrazine. but, it\'s important to remember that everyone reacts differently to medication. some people may experience no side effects at all, while others may experience one or more of the potential side effects.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "what are the potential side effects ?", "speaker": "Patient"}, {"dialogueNo": 4, "text": "the potential side effects of lectrazine include headache, nausea, vomiting, diarrhea, constipation, stomach pain, loss of appetite, weight loss, tiredness, trouble sleeping, anxiety, irritability, and dizziness.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "that\'s a lot of potential side effects! i\'m really worried about taking this medication.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/3RPP6PETFB2CYJFW.json",
					elasticSearchId: "3RPP6PETFB2CYJFW_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
							highLights: ["effects", "side"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "yes, there are some potential side effects with lectrazine. but, it's important to remember that everyone reacts differently to medication. some people may experience no side effects at all, while others may experience one or more of the potential side effects.",
							highLights: ["effects", "side"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "what are the potential side effects ?",
							highLights: ["effects", "side"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "the potential side effects of lectrazine include headache, nausea, vomiting, diarrhea, constipation, stomach pain, loss of appetite, weight loss, tiredness, trouble sleeping, anxiety, irritability, and dizziness.",
							highLights: ["effects", "side"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "that's a lot of potential side effects! i'm really worried about taking this medication.",
							highLights: ["effects", "side"],
							dialogueNo: 5,
						},
					],
					callTypeId: 1,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
		{
			topicId: 12,
			topicName: "pill day yes",
			numberOfSearchResults: 2,
			searchResults: [
				{
					searchId: 1,
					name: "Transcript from 27 May 2022 [11:42:35]",
					tags: ["Dosing Information", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 4,
					callId: "5R3O667MNNDC6HWK",
					callDate: "2022-05-27",
					callTime: "11:42:35",
					dialogueAppearedAt: 16.56,
					dialogueLength: 5.06,
					transcriptLength: 76.82,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 2, "text": "i was wondering if you could tell me the dosing information for lectrazine medication ?", "speaker": "Patient"}, {"dialogueNo": 3, "text": "sure. the recommended dose for lectrazine is 10 mg once daily.", "speaker": "Agent"}, {"dialogueNo": 4, "text": "okay, so i should take one pill every day ?", "speaker": "Patient"}, {"dialogueNo": 5, "text": "yes, that\'s right.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "what if i miss a dose ?", "speaker": "Patient"}]',
					s3path: "kural/processed_files/5R3O667MNNDC6HWK.json",
					elasticSearchId: "5R3O667MNNDC6HWK_4",
					transcripts: [
						{
							speaker: "Patient",
							text: "i was wondering if you could tell me the dosing information for lectrazine medication ?",
							highLights: [],
							dialogueNo: 2,
						},
						{
							speaker: "Agent",
							text: "sure. the recommended dose for lectrazine is 10 mg once daily.",
							highLights: [],
							dialogueNo: 3,
						},
						{
							speaker: "Patient",
							text: "okay, so i should take one pill every day ?",
							highLights: ["day", "every", "one", "pill", "take"],
							dialogueNo: 4,
						},
						{
							speaker: "Agent",
							text: "yes, that's right.",
							highLights: [],
							dialogueNo: 5,
						},
						{
							speaker: "Patient",
							text: "what if i miss a dose ?",
							highLights: [],
							dialogueNo: 6,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
				{
					searchId: 2,
					name: "Transcript from 25 May 2022 [15:30:20]",
					tags: ["Dosing Information", "Outbound", "UNKNOWN", "Neutral"],
					dialogueNo: 3,
					callId: "BNZ5Z47EQY4Y8TNY",
					callDate: "2022-05-25",
					callTime: "15:30:20",
					dialogueAppearedAt: 10.58,
					dialogueLength: 4.14,
					transcriptLength: 55.2,
					patientId: -1,
					speaker: "Patient",
					storiesAdded: [],
					addedToStory: "false",
					context:
						'[{"dialogueNo": 1, "text": "i\'m having a hard time swallowing these lectrazine pills. they\'re just too big.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "have you been taking them as prescribed ?", "speaker": "Agent"}, {"dialogueNo": 3, "text": "yes, i take one pill every day.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "and how have you been taking them ? with water ?", "speaker": "Agent"}, {"dialogueNo": 5, "text": "yes, i take them with a glass of water.", "speaker": "Patient"}]',
					s3path: "kural/processed_files/BNZ5Z47EQY4Y8TNY.json",
					elasticSearchId: "BNZ5Z47EQY4Y8TNY_3",
					transcripts: [
						{
							speaker: "Patient",
							text: "i'm having a hard time swallowing these lectrazine pills. they're just too big.",
							highLights: ["pills"],
							dialogueNo: 1,
						},
						{
							speaker: "Agent",
							text: "have you been taking them as prescribed ?",
							highLights: ["taking"],
							dialogueNo: 2,
						},
						{
							speaker: "Patient",
							text: "yes, i take one pill every day.",
							highLights: ["day", "every", "one", "pill", "take"],
							dialogueNo: 3,
						},
						{
							speaker: "Agent",
							text: "and how have you been taking them ? with water ?",
							highLights: ["taking"],
							dialogueNo: 4,
						},
						{
							speaker: "Patient",
							text: "yes, i take them with a glass of water.",
							highLights: ["take"],
							dialogueNo: 5,
						},
					],
					callTypeId: 2,
					therapyStatusId: -1,
				},
			],
			emotionsCounts: [
				{
					text: "headaches",
					value: 5711,
				},
				{
					text: "slight headache",
					value: 1130,
				},
			],
			headers: {
				"x-time":
					'{"query_expansion_time": 0.23832941055297852, "retriever_time": 0.645418643951416, "ranker_time": 0.7286033630371094, "df_prepare_time": 0.008182287216186523, "process_time": 1.665311574935913}',
				"x-search-config":
					"{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
				"x-auth-time": "0.3819572925567627",
				"x-process-time": "2.050896167755127",
			},
		},
	],
};

export const transcriptDetailConversation = [
	{
		speaker: "Patient",
		text: "okay.",
		dialogueNo: 1,
		renderText: "<strong>Patient</strong>: okay.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "hi is this christopher ?",
		dialogueNo: 2,
		renderText: "<strong>Agent</strong>: hi is this christopher ?",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "hello it is.",
		dialogueNo: 3,
		renderText: "<strong>Patient</strong>: hello it is.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "how's this connection ?",
		dialogueNo: 4,
		renderText: "<strong>Agent</strong>: how's this connection ?",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "it is, it's much better thank you.",
		dialogueNo: 5,
		renderText: "<strong>Patient</strong>: it is, it's much better thank you.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "okay, perfect. i know. ",
		dialogueNo: 6,
		renderText: "<strong>Agent</strong>: okay, perfect. i <span>know</span>. ",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "i then then i started hearing you uh it wasn't good but anyway um again my name is john, i'm a nurse educator with on behalf of biogen and while i don't work directly for your health care provider doctor i will be partnering with you too to provide ongoing educational support on the phone with your vumerity diroximel fumarate so this is a quick check in and by the way this call is being recorded for quality and training purposes, this is a quick check in to see how everything's going since you started the two pills in the morning and two pills in the evening.",
		dialogueNo: 7,
		renderText:
			"<strong>Agent</strong>: i then then i started hearing you uh it wasn't <span>go</span>od but anyway um again my name is john, i'm a nurse educator with on behalf of biogen and while i don't work directly for your health care provider doctor i will be partnering with you too to provide on<span>go</span>ing educational support on the phone with your vumerity diroximel fumarate so this is a quick check in and by the way this call is being recorded for quality and training purposes, this is a quick check in to see how every<span>thing</span>'s <span>go</span>ing since you started the two pills in the morning and two pills in the evening.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "okay.",
		dialogueNo: 8,
		renderText: "<strong>Agent</strong>: okay.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "uh-hum well um i've had a little bit of trouble, i haven't been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don't know what it's caused from um my vision is getting worse i think.",
		dialogueNo: 9,
		renderText:
			"<strong>Patient</strong>: uh-hum well um i've had a little bit of trouble, i haven't been taking the two pills in the morning and two in the evening every <span>day</span> because i have been having trouble with headaches and i don't <span>know</span> what it's caused from um my vision is <span>get</span>ting worse i <span>think</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "oh.",
		dialogueNo: 10,
		renderText: "<strong>Agent</strong>: oh.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "um but my my headaches i've been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn't touch it without it hurting and they it's you know i i just didn't know what to do with it and anyway um this went on and it's been i've been having a trip, the headaches every day since the my head is no longer sore but you know it's well if i push on it it's kind of sore but i you know i don't know i just it really and i was going to call the doctor here a local doctor and then i thought well it'll get better but and then i was gone for a day uh overnight and i well i'll wait till i get back and it's still the headache is still there.",
		dialogueNo: 11,
		renderText:
			"<strong>Patient</strong>: um but my my headaches i've been waking up happened about a week a<span>go</span>, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn't touch it without it hurting and they it's you <span>know</span> i i just didn't <span>know</span> what to do with it and anyway um this went on and it's been i've been having a trip, the headaches every <span>day</span> since the my head is no longer sore but you <span>know</span> it's well if i push on it it's kind of sore but i you <span>know</span> i don't <span>know</span> i just it really and i was <span>go</span>ing to call the doctor here a local doctor and then i thought well it'll <span>get</span> better but and then i was <span>go</span>ne for a <span>day</span> uh overnight and i well i'll wait till i <span>get</span> back and it's still the headache is still there.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?",
		dialogueNo: 12,
		renderText: "<strong>Agent</strong>: mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "yes yes it is.",
		dialogueNo: 13,
		renderText: "<strong>Patient</strong>: yes yes it is.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "okay and did they did they start when you're taking one pill in the morning and one pill in the evening ? okay.",
		dialogueNo: 14,
		renderText: "<strong>Agent</strong>: okay and did they did they start when you're taking one pill in the morning and one pill in the evening ? okay.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "uh no it was after i was taking the two pills morning and night and then i i decided well i'll try to be my own doctor and i just didn't take the two pills.",
		dialogueNo: 15,
		renderText:
			"<strong>Patient</strong>: uh no it was after i was taking the two pills morning and night and then i i decided well i'll try to be my own doctor and i just didn't <span>take</span> the two pills.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "mhm. mhm.",
		dialogueNo: 16,
		renderText: "<strong>Agent</strong>: mhm. mhm.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "i was cut down to one, you know one morning 1 night but i'm still getting the headache, so i don't know, mm.",
		dialogueNo: 17,
		renderText:
			"<strong>Patient</strong>: i was cut down to one, you <span>know</span> one morning 1 night but i'm still <span>get</span>ting the headache, so i don't <span>know</span>, mm.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "okay so you're still getting the headaches ?",
		dialogueNo: 18,
		renderText: "<strong>Agent</strong>: okay so you're still <span>get</span>ting the headaches ?",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "yeah i have one right now but um i don't know, i didn't know if it was from the medication or something else, so. okay.",
		dialogueNo: 19,
		renderText:
			"<strong>Patient</strong>: yeah i have one right now but um i don't <span>know</span>, i didn't <span>know</span> if it was from the medication or some<span>thing</span> else, so. okay.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "right, did you start anything else new at the same time by any chance ? yeah. okay, i'm so sorry that you're going through that and i do i am gonna get a send a note to new york my field nurse so that they can send a note to your health care provider, but i do suggest that you um do contact them and just let them know. ",
		dialogueNo: 20,
		renderText:
			"<strong>Agent</strong>: right, did you start any<span>thing</span> else new at the same time by any chance ? yeah. okay, i'm so sorry that you're <span>go</span>ing through that and i do i am <span>go</span>nna <span>get</span> a send a note to new york my field nurse so that they can send a note to your health care provider, but i do suggest that you um do contact them and just let them <span>know</span>. ",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "um and um are you taking anything for the headache ?",
		dialogueNo: 21,
		renderText: "<strong>Agent</strong>: um and um are you taking any<span>thing</span> for the headache ?",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "no i did not i i'd b profen but somewhat um it's not 438% but you know, like i say my my vision has been getting worse.",
		dialogueNo: 22,
		renderText:
			"<strong>Patient</strong>: no i did not i i'd b profen but somewhat um it's not 438% but you <span>know</span>, like i <span>say</span> my my vision has been <span>get</span>ting worse.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "okay, is that is that helping at all ? okay.",
		dialogueNo: 23,
		renderText: "<strong>Agent</strong>: okay, is that is that helping at all ? okay.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "john well for quite some time but i don't know, it just kind of reminded me of the uh same time that i was diagnosed with m. s. because i had the got the real bad headache and then i was i but i lost my vision in my right eye at that time.",
		dialogueNo: 24,
		renderText:
			"<strong>Patient</strong>: john well for quite some time but i don't <span>know</span>, it just kind of reminded me of the uh same time that i was diagnosed with m. s. because i had the <span>go</span>t the real <span>bad</span> headache and then i was i but i lost my vision in my right eye at that time.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "mhm mhm mhm.",
		dialogueNo: 25,
		renderText: "<strong>Agent</strong>: mhm mhm mhm.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "well this time i didn't i haven't lost the vision in either i but it still hurts, so i don't know that it kind of reminded me of that, but i don't know, that's what i was wondering.",
		dialogueNo: 26,
		renderText:
			"<strong>Patient</strong>: well this time i didn't i haven't lost the vision in either i but it still hurts, so i don't <span>know</span> that it kind of reminded me of that, but i don't <span>know</span>, that's what i was wondering.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "so yeah, see so maybe that so when you get when you start having the same symptoms you had at the time of diagnosis, that could be a relapse. so yeah, i mean i can't diagnose that or give medical advice, but that's why i when something like that happens, i would call my health care provider. ",
		dialogueNo: 27,
		renderText:
			"<strong>Agent</strong>: so yeah, see so maybe that so when you <span>get</span> when you start having the same symptoms you had at the time of diagnosis, that could be a relapse. so yeah, i <span>mean</span> i can't diagnose that or give medical advice, but that's why i when some<span>thing</span> like that happens, i would call my health care provider. ",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "um but so you feel so your vision has been getting worse for a while but do you feel it got worse since you were taking the vumerity okay okay.um well thank you so much for taking the time to share this information with me and speak with me today. um the other thing i wanted to ask you are you are you eating when you take the medication ? ",
		dialogueNo: 28,
		renderText:
			"<strong>Agent</strong>: um but so you <span>feel</span> so your vision has been <span>get</span>ting worse for a while but do you <span>feel</span> it <span>go</span>t worse since you were taking the vumerity okay okay.um well thank you so much for taking the time to share this information with me and speak with me to<span>day</span>. um the other <span>thing</span> i wanted to ask you are you are you eating when you <span>take</span> the medication ? ",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "i wonder if that would help because sometimes the eating with the medication does help with some of the common side effects in the beginning.and just the headaches, does the headaches coincide at any, in any way with any of that ? thank you. i'm sorry i lost you for a minute. ",
		dialogueNo: 29,
		renderText:
			"<strong>Agent</strong>: i wonder if that would help because sometimes the eating with the medication does help with some of the common side effects in the beginning.and just the headaches, does the headaches coincide at any, in any way with any of that ? thank you. i'm sorry i lost you for a minute. ",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "no, i don't think it was worse since i've been taking the or so uh sometimes i'll eat when i take the morning once, but uh i don't eat with the the nighttime ones well been trying to, you know, it seems like if i oh okay it seems like if i put uh you know kind of put my hand over my my eye hurts and if i put my hand over the i and it gets warm that seems to help a little bit but not completely uh so i don't know.",
		dialogueNo: 30,
		renderText:
			"<strong>Patient</strong>: no, i don't <span>think</span> it was worse since i've been taking the or so uh sometimes i'll eat when i <span>take</span> the morning once, but uh i don't eat with the the nighttime ones well been trying to, you <span>know</span>, it seems like if i oh okay it seems like if i put uh you <span>know</span> kind of put my hand over my my eye hurts and if i put my hand over the i and it <span>get</span>s warm that seems to help a little bit but not completely uh so i don't <span>know</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "uh-huh yeah.",
		dialogueNo: 31,
		renderText: "<strong>Agent</strong>: uh-huh yeah.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "i'm just baffled.",
		dialogueNo: 32,
		renderText: "<strong>Patient</strong>: i'm just baffled.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "john yeah i well i am gonna report this and i was wondering do you have the lot number and the expiration date of your medication because we do report that with this.",
		dialogueNo: 33,
		renderText:
			"<strong>Agent</strong>: john yeah i well i am <span>go</span>nna report this and i was wondering do you have the lot number and the expiration date of your medication because we do report that with this.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "yeah.",
		dialogueNo: 34,
		renderText: "<strong>Patient</strong>: yeah.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "but i i i i do suggest you contact your healthcare provider because maybe you know it's something you know maybe he might say stay on the one pill twice a day for a while, just until you get used to it, you know he might have other suggestions.",
		dialogueNo: 35,
		renderText:
			"<strong>Agent</strong>: but i i i i do suggest you contact your healthcare provider because maybe you <span>know</span> it's some<span>thing</span> you <span>know</span> maybe he might <span>say</span> stay on the one pill twice a <span>day</span> for a while, just until you <span>get</span> used to it, you <span>know</span> he might have other suggestions.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "okay.",
		dialogueNo: 36,
		renderText: "<strong>Patient</strong>: okay.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "i don't know.",
		dialogueNo: 37,
		renderText: "<strong>Agent</strong>: i don't <span>know</span>.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "okay. i'm looking for the the numbers on here. john wow okay. well there's one number at the top that's an rx number or it says only and it's n as in john, d as in christopher as in new york. 921 -921-03.",
		dialogueNo: 38,
		renderText:
			"<strong>Patient</strong>: okay. i'm looking for the the numbers on here. john wow okay. well there's one number at the top that's an rx number or it <span>say</span>s only and it's n as in john, d as in christopher as in new york. 921 -921-03.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yeah i didn't i don't i don't think that's it it would be maybe a 318 number or 10.",
		dialogueNo: 39,
		renderText: "<strong>Agent</strong>: yeah i didn't i don't i don't <span>think</span> that's it it would be maybe a 318 number or 10.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "right, okay. let me look further here. hold on, mm. usually it'll say like a lot number or something but oh well, so far that's the only number i'm seeing mm. there's gotta be another one on here.",
		dialogueNo: 40,
		renderText:
			"<strong>Patient</strong>: right, okay. let me look further here. hold on, mm. usually it'll <span>say</span> like a lot number or some<span>thing</span> but oh well, so far that's the only number i'm seeing mm. there's <span>go</span>tta be another one on here.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yeah sometimes it's not there though, that's why when i ask you to look and we go it could sometimes they put a sticker over it and you can't see it but oh okay no worries, no worries then okay take your time.",
		dialogueNo: 41,
		renderText:
			"<strong>Agent</strong>: yeah sometimes it's not there though, that's why when i ask you to look and we <span>go</span> it could sometimes they put a sticker over it and you can't see it but oh okay no worries, no worries then okay <span>take</span> your time.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "that's what i was wondering because they've got um the sticker over this. oh, i don't know. wait now. let me let me let me just let me look a little no, i'm not seeing anything else, so i don't know. um now there was they sent like i've got like three bottles that were sent off the same time. ah but they're all the same. ",
		dialogueNo: 42,
		renderText:
			"<strong>Patient</strong>: that's what i was wondering because they've <span>go</span>t um the sticker over this. oh, i don't <span>know</span>. wait now. let me let me let me just let me look a little no, i'm not seeing any<span>thing</span> else, so i don't <span>know</span>. um now there was they sent like i've <span>go</span>t like three bottles that were sent off the same time. ah but they're all the same. ",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "i don't see anything where it says lot number or i don't know.",
		dialogueNo: 43,
		renderText: "<strong>Patient</strong>: i don't see any<span>thing</span> where it <span>say</span>s lot number or i don't <span>know</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "alright no worries, no worries, don't worry about it. well what i'd like to do christopher is i'd like to check in with you next week if i could do you think you're gonna contact your health care provider just to see what they say ?",
		dialogueNo: 44,
		renderText:
			"<strong>Agent</strong>: alright no worries, no worries, don't worry about it. well what i'd like to do christopher is i'd like to check in with you next week if i could do you <span>think</span> you're <span>go</span>nna contact your health care provider just to see what they <span>say</span> ?",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "okay. yeah. yeah. i'm i'm pretty sure i will because it really baffles me. i just don't understand.",
		dialogueNo: 45,
		renderText: "<strong>Patient</strong>: okay. yeah. yeah. i'm i'm pretty sure i will because it really baffles me. i just don't understand.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "yeah. right.",
		dialogueNo: 46,
		renderText: "<strong>Agent</strong>: yeah. right.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "you know if i was used i you know, but i normally don't get headaches and um this one i'm sure it's uh connected with my i because my i does hurt, you know. so i'm assuming that that's what it is, but i don't know so, but i am going to call doctor mclaughlin's office and talk to the nurse and see what she says. ",
		dialogueNo: 47,
		renderText:
			"<strong>Patient</strong>: you <span>know</span> if i was used i you <span>know</span>, but i normally don't <span>get</span> headaches and um this one i'm sure it's uh connected with my i because my i does hurt, you <span>know</span>. so i'm assuming that that's what it is, but i don't <span>know</span> so, but i am <span>go</span>ing to call doctor mclaughlin's office and talk to the nurse and see what she <span>say</span>s. ",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "and john i was tempted to go to like uh the the doctor here.",
		dialogueNo: 48,
		renderText: "<strong>Patient</strong>: and john i was tempted to <span>go</span> to like uh the the doctor here.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "mhm.",
		dialogueNo: 49,
		renderText: "<strong>Agent</strong>: mhm.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "my doctor. john, i can't even think of his name now.",
		dialogueNo: 50,
		renderText: "<strong>Patient</strong>: my doctor. john, i can't even <span>think</span> of his name now.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "your primary primary. john.",
		dialogueNo: 51,
		renderText: "<strong>Agent</strong>: your primary primary. john.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "christopher, my my primary doctor here in new york, but i i didn't know, you know, if i should go there i should i should go to the new york or you know, it's because i'm in new york work and my new neurologist doctor christopher is in omaha's aw. and so, you know, you know, it's just, i don't know.",
		dialogueNo: 52,
		renderText:
			"<strong>Patient</strong>: christopher, my my primary doctor here in new york, but i i didn't <span>know</span>, you <span>know</span>, if i should <span>go</span> there i should i should <span>go</span> to the new york or you <span>know</span>, it's because i'm in new york work and my new neurologist doctor christopher is in omaha's aw. and so, you <span>know</span>, you <span>know</span>, it's just, i don't <span>know</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yeah i know i know what you're saying, yeah yeah so maybe go ahead i'm sorry.",
		dialogueNo: 53,
		renderText: "<strong>Agent</strong>: yeah i <span>know</span> i <span>know</span> what you're <span>say</span>ing, yeah yeah so maybe <span>go</span> ahead i'm sorry.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "so i'll just i'll call doctor mclaughlin's office and see what they say. and go from there i guess otherwise. i'll just wait and see if it goes away, but i i will do that.",
		dialogueNo: 54,
		renderText:
			"<strong>Patient</strong>: so i'll just i'll call doctor mclaughlin's office and see what they <span>say</span>. and <span>go</span> from there i guess otherwise. i'll just wait and see if it <span>go</span>es away, but i i will do that.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yeah i would i would call and then i'm john send a note in and then hopefully we'll talk next week and see because you know if you're in pain, you know you should definitely call them and if there's any changes, you know, you should definitely just let them know in case they john prescribe something or you know what i'm saying so and i don't i don't want you to be in pain and even if you know maybe.",
		dialogueNo: 55,
		renderText:
			"<strong>Agent</strong>: yeah i would i would call and then i'm john send a note in and then hopefully we'll talk next week and see because you <span>know</span> if you're in pain, you <span>know</span> you should definitely call them and if there's any changes, you <span>know</span>, you should definitely just let them <span>know</span> in case they john prescribe some<span>thing</span> or you <span>know</span> what i'm <span>say</span>ing so and i don't i don't want you to be in pain and even if you <span>know</span> maybe.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "well it it's not, you know, excruciating pain. it's just very, it's annoying.",
		dialogueNo: 56,
		renderText: "<strong>Patient</strong>: well it it's not, you <span>know</span>, excruciating pain. it's just very, it's annoying.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yeah. yeah.",
		dialogueNo: 57,
		renderText: "<strong>Agent</strong>: yeah. yeah.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "it's, you know, it hurts, but you know, i don't know.",
		dialogueNo: 58,
		renderText: "<strong>Patient</strong>: it's, you <span>know</span>, it hurts, but you <span>know</span>, i don't <span>know</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yeah.",
		dialogueNo: 59,
		renderText: "<strong>Agent</strong>: yeah.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "i don't know.",
		dialogueNo: 60,
		renderText: "<strong>Patient</strong>: i don't <span>know</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yeah well christopher i hope things get better and i am gonna check in with you next week. um what day is better for you next wednesday or thursday, either one.",
		dialogueNo: 61,
		renderText:
			"<strong>Agent</strong>: yeah well christopher i hope <span>thing</span>s <span>get</span> better and i am <span>go</span>nna check in with you next week. um what <span>day</span> is better for you next wednes<span>day</span> or thurs<span>day</span>, either one.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "uh i really either one i don't have anything on the calendar.",
		dialogueNo: 62,
		renderText: "<strong>Patient</strong>: uh i really either one i don't have any<span>thing</span> on the calendar.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "okay.",
		dialogueNo: 63,
		renderText: "<strong>Agent</strong>: okay.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "mhm.",
		dialogueNo: 64,
		renderText: "<strong>Patient</strong>: mhm.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "okay. alright well and when i call you i'll call you from this number. the id. if you want to say that number in your contacts to help you recognize my call and then if you do need to talk prior to that next call, feel free to give us a call at that same number. ",
		dialogueNo: 65,
		renderText:
			"<strong>Agent</strong>: okay. alright well and when i call you i'll call you from this number. the id. if you want to <span>say</span> that number in your contacts to help you recognize my call and then if you do need to talk prior to that next call, <span>feel</span> free to give us a call at that same number. ",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "uh our hours of operation are monday through friday 8:30 a.m. to 10 o'clock pm.",
		dialogueNo: 66,
		renderText: "<strong>Agent</strong>: uh our hours of operation are mon<span>day</span> through fri<span>day</span> 8:30 a.m. to 10 o'clock pm.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "so uh no, i don't think so.",
		dialogueNo: 67,
		renderText: "<strong>Patient</strong>: so uh no, i don't <span>think</span> so.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "and that's eastern standard time. um but do you have any other questions for me ?",
		dialogueNo: 68,
		renderText: "<strong>Agent</strong>: and that's eastern standard time. um but do you have any other questions for me ?",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "um will i need to ask for you if i would happen to call in or ? okay.",
		dialogueNo: 69,
		renderText: "<strong>Patient</strong>: um will i need to ask for you if i would happen to call in or ? okay.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "yeah you can ask for me but anyone can really help you but uh yeah i'm i'm here a lot so yeah my name is john, they'll try to connect you with me.",
		dialogueNo: 70,
		renderText:
			"<strong>Agent</strong>: yeah you can ask for me but anyone can really help you but uh yeah i'm i'm here a lot so yeah my name is john, they'll try to connect you with me.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "and what what was your name again jenny ? okay.",
		dialogueNo: 71,
		renderText: "<strong>Patient</strong>: and what what was your name again jenny ? okay.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "yeah.",
		dialogueNo: 72,
		renderText: "<strong>Agent</strong>: yeah.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "oh okay. all right, okay.",
		dialogueNo: 73,
		renderText: "<strong>Patient</strong>: oh okay. all right, okay.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "yeah now would you like me to send you a copy of the prescribing information ? i can mail it to you or you can view it on dot com ?",
		dialogueNo: 74,
		renderText: "<strong>Agent</strong>: yeah now would you like me to send you a copy of the prescribing information ? i can mail it to you or you can view it on dot com ?",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "ah i think maybe i have um a copy when they sent the medication and i don't know.",
		dialogueNo: 75,
		renderText: "<strong>Patient</strong>: ah i <span>think</span> maybe i have um a copy when they sent the medication and i don't <span>know</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "yes it did come with the medication. okay perfect perfect.",
		dialogueNo: 76,
		renderText: "<strong>Agent</strong>: yes it did come with the medication. okay perfect perfect.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "yeah. yeah.",
		dialogueNo: 77,
		renderText: "<strong>Patient</strong>: yeah. yeah.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "well john i hope you're feeling better and um since we did discuss vumerity i do have to offer you the safety recording, it's up to you, it's up to you and i think i'm pretty sure you listen to it before.",
		dialogueNo: 78,
		renderText:
			"<strong>Agent</strong>: well john i hope you're <span>feel</span>ing better and um since we did discuss vumerity i do have to offer you the safety recording, it's up to you, it's up to you and i <span>think</span> i'm pretty sure you listen to it before.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "oh um i have yes, i listened to a lot of them.",
		dialogueNo: 79,
		renderText: "<strong>Patient</strong>: oh um i have yes, i listened to a lot of them.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "yeah. yeah. yeah. yeah. christopher well thank you.",
		dialogueNo: 80,
		renderText: "<strong>Agent</strong>: yeah. yeah. yeah. yeah. christopher well thank you.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "so yeah, so i that won't be necessary.",
		dialogueNo: 81,
		renderText: "<strong>Patient</strong>: so yeah, so i that won't be necessary.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "okay perfect. christopher well thank you again christopher and i hope you have a wonderful day and i hope the headaches go away.",
		dialogueNo: 82,
		renderText:
			"<strong>Agent</strong>: okay perfect. christopher well thank you again christopher and i hope you have a wonderful <span>day</span> and i hope the headaches <span>go</span> away.",
		highlight: true,
	},
	{
		speaker: "Patient",
		text: "i certainly hope so too. and thank you. and you have a good day.",
		dialogueNo: 83,
		renderText: "<strong>Patient</strong>: i certainly hope so too. and thank you. and you have a <span>go</span>od <span>day</span>.",
		highlight: true,
	},
	{
		speaker: "Agent",
		text: "thank you so much and i'll talk to you next week.",
		dialogueNo: 84,
		renderText: "<strong>Agent</strong>: thank you so much and i'll talk to you next week.",
		highlight: false,
	},
	{
		speaker: "Patient",
		text: "also okay. alright.",
		dialogueNo: 85,
		renderText: "<strong>Patient</strong>: also okay. alright.",
		highlight: false,
	},
	{
		speaker: "Agent",
		text: "thanks. bye.",
		dialogueNo: 86,
		renderText: "<strong>Agent</strong>: thanks. bye.",
		highlight: false,
	},
];

export const StoriesViewDummyData = [
	{
		id: 11,
		storyName: "Query about Alcohol Consumption",
		dateCreated: "2023-09-27T18:30:02.6663",
		noofSavedSearches: 12,
	},
	{
		id: 22,
		storyName: "Worried about Side Effects",
		dateCreated: "2023-09-27T18:20:37.5796",
		noofSavedSearches: 42,
	},
	{
		id: 33,
		storyName: "Unhappy with Meal Restrictions",
		dateCreated: "2020/08/08",
		noofSavedSearches: 15,
	},
	{
		id: 44,
		storyName: "Receiving too many Calls",
		dateCreated: "2020/05/05",
		noofSavedSearches: 26,
	},
	{
		id: 55,
		storyName: "Unhappy with Pill Box",
		dateCreated: "2020/12/12",
		noofSavedSearches: 6,
	},
	{
		id: 66,
		storyName: "Analysis Done",
		dateCreated: "2023-09-27T18:20:37.5796",
		noofSavedSearches: 6,
	},
	{
		id: 77,
		storyName: "Report is Generated",
		dateCreated: "2022/03/15",
		noofSavedSearches: 9,
	},
	{
		id: 88,
		storyName: "We are Killing",
		dateCreated: "2023-09-27T18:30:02.6663",
		noofSavedSearches: 11,
	},
];

export const OldStoriesViewDummyData = [
	{
		id: 100,
		storyName: "Query about Alcohol Consumption",
		dateCreated: "2023-09-27T18:30:02.6663",
		noofAnnotatedSelections: 12,
	},
	{
		id: 22,
		storyName: "Worried about Side Effects",
		dateCreated: "2023-09-27T18:20:37.5796",
		noofAnnotatedSelections: 42,
	},
	{
		id: 33,
		storyName: "Unhappy with Meal Restrictions",
		dateCreated: "2020/08/08",
		noofAnnotatedSelections: 15,
	},
	{
		id: 44,
		storyName: "Receiving too many Calls",
		dateCreated: "2020/05/05",
		noofAnnotatedSelections: 26,
	},
	{
		id: 55,
		storyName: "Unhappy with Pill Box",
		dateCreated: "2020/12/12",
		noofAnnotatedSelections: 6,
	},
	{
		id: 66,
		storyName: "Analysis Done",
		dateCreated: "2023-09-27T18:20:37.5796",
		noofAnnotatedSelections: 6,
	},
	{
		id: 77,
		storyName: "Report is Generated",
		dateCreated: "2022/03/15",
		noofAnnotatedSelections: 9,
	},
	{
		id: 88,
		storyName: "We are Killing",
		dateCreated: "2023-09-27T18:30:02.6663",
		noofAnnotatedSelections: 11,
	},
];

export const SelectedStoriesDummyData = [
	{
		id: 1,
		searchQuery: "Saved Search 1",
		searchSummary:
			"Calls can refer to different things, such as the Apple TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede Álvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets.Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device. Troubleshooting tips are available if issues arise while using the calling feature. Recent calls cannot be managed or deleted from the PC.Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls. The service is available on Apple devices and other smartphones.",
		createdOn: "2023-12-10T11:20:37.5796",
	},
	{
		id: 2,
		searchQuery: "Saved Search 2",
		searchSummary:
			"Can refer to different things, such as the Samsung TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede Álvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets.Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device. Troubleshooting tips are available if issues arise while using the calling feature. Recent calls cannot be managed or deleted from the PC.Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls. The service is available on Apple devices and other smartphones.",
		createdOn: "2023-12-11T11:20:37.5796",
	},
	{
		id: 3,
		searchQuery: "Saved Search 3",
		searchSummary:
			"Calls can refer to different things, such as the Apple TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede Álvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets.Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device. Troubleshooting tips are available if issues arise while using the calling feature. Recent calls cannot be managed or deleted from the PC.Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls. The service is available on Apple devices and other smartphones.",
		createdOn: "2023-10-29T18:20:37.5796",
	},
	{
		id: 4,
		searchQuery: "Saved Search 4",
		searchSummary:
			"Calls can refer to different things, such as the Apple TV+ series, making and receiving phone calls from a PC, using Google Voice, or making free internet calls with PopTox.The Apple TV+ series Calls is a horror show directed by Fede Álvarez. The show features a series of seemingly unrelated phone calls, each featuring characters involved in stories that involve strange, supernatural phenomena. The show is effective in setting the mood, with a separate Spanish-language version planned for Latin American markets.Making and receiving phone calls from a PC is possible with the Phone Link app from Microsoft. The app requires Bluetooth capability on both the PC and the Android device. Troubleshooting tips are available if issues arise while using the calling feature. Recent calls cannot be managed or deleted from the PC.Google Voice allows users to place and receive calls from anywhere using smartphones and the web. The service offers voicemail transcription and the ability to forward calls to any device while blocking spam calls. The service is available on Apple devices and other smartphones.",
		createdOn: "2023-09-27T18:20:37.5796",
	},
];

export const StoryModeDummyData = [
	{
		id: "100",
		storyName: "Query about Alcohol Consumption",
		status: "In Draft",
		numberOfHighlightedTranscripts: 0,
		headerInfo: ["Annotated Selection", "Date Added on", "Source", "Tags", "Status"],
		filteredColumns: ["Source", "Tags", "Status"],
		annotatedSelections: [
			{
				id: 1,
				"Annotated Selection": "Transcript from 12 June 2020 [5:33:23]",
				"Date Added on": "12 Nov 2021",
				Source: "Search Based",
				Tags: ["Inquiry", "Concern", "Covid", "Question", "Issues", "Medication Issues"],
				Status: "Pending",
				Description: {
					tags: ["Inbound", "Follow Up Call", "Nervous"],
					dialogueNo: 33,
					dialogueAppearedAt: 0.2,
					dialogueLength: 1.2,
					transcriptLength: 5,
					transcripts: [
						{
							dialogueNo: 32,
							speaker: "Agent",
							text: "Are there any other questions I can answer for you?",
						},
						{
							dialogueNo: 33,
							speaker: "Patient",
							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
						},
						{
							dialogueNo: 34,
							speaker: "Agent",
							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
						},
					],
				},
			},
			{
				id: 2,
				"Annotated Selection": "Transcript from 10 May 2020 [12:48:35]",
				"Date Added on": "03 Nov 2021",
				Source: "System Recommended",
				Tags: ["Concern", "Risk", "Covid", "Question", "Issues"],
				Status: "Pending",
				Description: {
					tags: ["Inbound", "Follow Up Call", "Nervous"],
					dialogueAppearedAt: 0.2,
					dialogueLength: 1.2,
					transcriptLength: 5,
					dialogueNo: 33,
					transcripts: [
						{
							dialogueNo: 32,
							speaker: "Agent",
							text: "Are there any other questions I can answer for you?",
						},
						{
							dialogueNo: 33,
							speaker: "Patient",
							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
						},
						{
							dialogueNo: 34,
							speaker: "Agent",
							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
						},
					],
				},
			},
			{
				id: 3,
				"Annotated Selection": "Transcript from 04 Jan 2020 [01:03:12]",
				"Date Added on": "01 Nov 2021",
				Source: "Search Based",
				Tags: ["Concern", "Covid", "Question"],
				Status: "Complete",
				Description: {
					tags: ["Inbound", "Follow Up Call", "Nervous"],
					dialogueAppearedAt: 0.2,
					dialogueLength: 1.2,
					transcriptLength: 5,
					dialogueNo: 33,
					transcripts: [
						{
							dialogueNo: 32,
							speaker: "Agent",
							text: "Are there any other questions I can answer for you?",
						},
						{
							dialogueNo: 33,
							speaker: "Patient",
							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
						},
						{
							dialogueNo: 34,
							speaker: "Agent",
							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
						},
					],
				},
			},
			{
				id: 4,
				"Annotated Selection": "Transcript from 01 Jan 2020 [01:00:45]",
				"Date Added on": "02 Nov 2021",
				Source: "Search Based",
				Tags: ["Medication Issues", "Concern", "Covid", "Question"],
				Status: "Complete",
				Description: {
					tags: ["Inbound", "Follow Up Call", "Nervous"],
					dialogueAppearedAt: 0.2,
					dialogueLength: 1.2,
					transcriptLength: 5,
					dialogueNo: 33,
					transcripts: [
						{
							dialogueNo: 32,
							speaker: "Agent",
							text: "Are there any other questions I can answer for you?",
						},
						{
							dialogueNo: 33,
							speaker: "Patient",
							text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
						},
						{
							dialogueNo: 34,
							speaker: "Agent",
							text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
						},
					],
				},
			},
		],
	},
];

export const jsonData1 = [
	{
		name: "Disease Characteristic",
		children: [
			{
				name: "Severity",
				children: [{ name: "Severity" }],
			},
			{
				name: "Symptoms",
				children: [{ name: "Fatigue" }, { name: "Body Pains" }],
			},
		],
	},
	{
		name: "Drug Characteristic",
		children: [
			{
				name: "Efficacy",
				children: [{ name: "OSS" }, { name: "PSR" }],
			},
			{
				name: "Safety",
				children: [{ name: "Nausea" }, { name: "Diarrhea" }],
			},
			{
				name: "Childless Category",
				children: [],
			},
		],
	},
	// { name: "Childless Type" },
	// ... and so on
];

export const jsonData2 = [
	{
		name: "Disease Characteristic 2",
		children: [
			{
				name: "Severity",
				children: [{ name: "Severity" }],
			},
			{
				name: "Symptoms",
				children: [
					{
						name: "Fatigue",
					},
					{
						name: "Body Pains",
					},
				],
			},
		],
	},
	{
		name: "Disease Characteristic 3",
		children: [
			{
				name: "Severity2",
				children: [
					{
						name: "Severity 1",
					},
					{
						name: "Level 4",
					},
				],
			},
			{
				name: "Symptoms 1",
				children: [
					{
						name: "Fatigue 1",
					},
					{
						name: "Body Pains 1",
					},
				],
			},
			{
				name: "Childless Category",
				children: [],
			},
		],
	},
];

export const jsonData = {
	s3_path: "client-vapt/taxonomy/test/2v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1v1",
	study_id: 13,
	taxonomyJson: [
		{
			name: "Disease Characteristic 4",
			children: [
				{
					name: "Severity12",
					children: [
						{
							name: "Severity23",
							keywords: ["keyword1", "keyword2", "keyword3"],
							description: "This is false",
						},
						{
							name: "Level 11",
							keywords: ["keyword4", "keyword5", "keyword6"],
							description: "this is true",
						},
						{
							name: "Level 22",
							keywords: ["keyword7", "keyword8", "keyword9"],
							description: "this is boolan",
						},
					],
				},
				{
					name: "Symptoms1",
					children: [
						{
							name: "Fatigue1",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
						{
							name: "Body Pains1",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
						{
							name: "Knee Pain1",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
					],
				},
			],
		},
		{
			name: "Disease Characteristic 3",
			children: [
				{
					name: "Severity",
					children: [
						{
							name: "Severity 1",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
						{
							name: "Level 4",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
						{
							name: "Level 5",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
					],
				},
				{
					name: "Symptoms",
					children: [
						{
							name: "Fatigue",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
						{
							name: "Body Pains",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
						{
							name: "Knee Pain",
							keywords: ["abcdefg", "hijklmn", "opqrst"],
							description: "This is false",
						},
					],
				},
			],
		},
	],
};

export const TranscriptInputdataSource2 = {
	fileTypes: [
		{ fileTypeId: 1, fileTypeName: "HCP Details" },
		{ fileTypeId: 2, fileTypeName: "Rep Details" },
		{ fileTypeId: 3, fileTypeName: "Therapy Details" },
		{ fileTypeId: 4, fileTypeName: "MSL Notes" },
	],
	studyTranscriptData: [
		// {
		//   fileTypeId: 1,
		//   studyIterId: 348,
		//   transcriptId: 2096,
		//   transcriptName: "client-sandbox/348/transcript/2023_COPD_Demand_Estimation_-_Respondent__12__CA__-_2023.06.09_10.30AM_B_transcript.txt",
		//   filename: null,
		//   s3Path: "client-sandbox/348/transcript/2023_COPD_Demand_Estimation_-_Respondent__12__CA__-_2023.06.09_10.30AM_B_transcript.txt",
		//   trProcessStatus: "UPLOAD_SUCCESS",
		//   parseStatus: null,
		//   isUploaded: true,
		//   isQCed: true,
		//   isProcessed: true,
		//   uploadErrorText: " ",
		//   qCErrorText: "",
		//   activeFlag: null,
		//   uploadedOn: "2023-11-30",
		//   processStartTime: "-10675199.02:48:05.4775808",
		//   processEndTime: "-10675199.02:48:05.4775808",
		//   createdOn: "2023-11-30T08:17:02.699088",
		//   createdBy: "as33895",
		//   updatedOn: "2023-11-30T08:22:54",
		//   updatedBy: null,
		// },
		// {
		//   fileTypeId: 2,
		//   studyIterId: 348,
		//   transcriptId: 2084,
		//   transcriptName: "client-sandbox/348/transcript/08.16.2019_Interview_9am.docx",
		//   filename: null,
		//   s3Path: "client-sandbox/348/transcript/08.16.2019_Interview_9am.docx",
		//   trProcessStatus: "UPLOAD_SUCCESS",
		//   parseStatus: null,
		//   isUploaded: true,
		//   isQCed: true,
		//   isProcessed: true,
		//   uploadErrorText: " ",
		//   qCErrorText: "",
		//   activeFlag: null,
		//   uploadedOn: "2023-11-30",
		//   processStartTime: "-10675199.02:48:05.4775808",
		//   processEndTime: "-10675199.02:48:05.4775808",
		//   createdOn: "2023-11-30T08:17:02.467935",
		//   createdBy: "as33895",
		//   updatedOn: "2023-11-30T08:25:13",
		//   updatedBy: null,
		// },
		// {
		//   fileTypeId: 3,
		//   studyIterId: 348,
		//   transcriptId: 2081,
		//   transcriptName: "client-sandbox/348/transcript/2023_COPD_Demand_Estimation_-_Respondent__13__CA__-_2023.06.13_5.30PM_A_transcript.txt",
		//   filename: null,
		//   s3Path: "client-sandbox/348/transcript/2023_COPD_Demand_Estimation_-_Respondent__13__CA__-_2023.06.13_5.30PM_A_transcript.txt",
		//   trProcessStatus: "UPLOAD_SUCCESS",
		//   parseStatus: null,
		//   isUploaded: true,
		//   isQCed: true,
		//   isProcessed: true,
		//   uploadErrorText: " ",
		//   qCErrorText: "",
		//   activeFlag: null,
		//   uploadedOn: "2023-11-30",
		//   processStartTime: "-10675199.02:48:05.4775808",
		//   processEndTime: "-10675199.02:48:05.4775808",
		//   createdOn: "2023-11-30T08:17:02.403039",
		//   createdBy: "as33895",
		//   updatedOn: "2023-11-30T08:25:25",
		//   updatedBy: null,
		// },
		// {
		//   fileTypeId: 4,
		//   studyIterId: 348,
		//   transcriptId: 2092,
		//   transcriptName: "client-sandbox/348/transcript/2023_COPD_Demand_Estimation_-_Respondent__10__US__-_2023.06.12_7.00PM_A_transcript.txt",
		//   filename: null,
		//   s3Path: "client-sandbox/348/transcript/2023_COPD_Demand_Estimation_-_Respondent__10__US__-_2023.06.12_7.00PM_A_transcript.txt",
		//   trProcessStatus: "UPLOAD_SUCCESS",
		//   parseStatus: null,
		//   isUploaded: true,
		//   isQCed: true,
		//   isProcessed: true,
		//   uploadErrorText: " ",
		//   qCErrorText: "",
		//   activeFlag: null,
		//   uploadedOn: "2023-11-30",
		//   processStartTime: "-10675199.02:48:05.4775808",
		//   processEndTime: "-10675199.02:48:05.4775808",
		//   createdOn: "2023-11-30T08:17:02.569806",
		//   createdBy: "as33895",
		//   updatedOn: "2023-11-30T08:23:40",
		//   updatedBy: null,
		// },
	],
};

export const NotificationCenterDummyData = [
	{
		id: 1,
		title: "Notification 1",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 2,
		title: "Notification 2",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 3,
		title: "Notification 3",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 4,
		title: "Notification 4",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 5,
		title: "Notification 5",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 6,
		title: "Notification 6",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 7,
		title: "Notification 7",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 8,
		title: "Notification 8",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 9,
		title: "Notification 9",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
	{
		id: 10,
		title: "Notification 10",
		description:
			"There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything.",
	},
];

export const SummaryCardDummyData = [
	{
		id: 11,
		storyName: "Query about Alcohol Consumption",
		dateCreated: "2023-09-27",
		noofSavedSearches: 12,
	},
	{
		id: 22,
		storyName: "Worried about Side Effects",
		dateCreated: "2023-09-27",
		noofSavedSearches: 42,
	},
	{
		id: 33,
		storyName: "Unhappy with Meal Restrictions",
		dateCreated: "2020/08/08",
		noofSavedSearches: 15,
	},
];

import * as ReportTypesComponentProps from "./../interfaces/main";
/**
 * Transform the Data from the Default Type to the type accepted by Choropleth Chart of Nivo.
 *
 * @param data - Raw Data
 * @param series - Series details
 *
 * @returns Transformed data
 */
export default function transformData(
	data: ReportTypesComponentProps.Interface["data"],
	series: ReportTypesComponentProps.Interface["series"]
) {
	let res: any = []; //Check this type
	let labelDim: number = series[0].labelCol || 0;
	series.map((s, idx) => {
		return data[s.dim].map((value: number, index: number) => {
			if (idx === 0) {
				return res.push({
					id: data[labelDim][index],
					value: value,
				});
			} else {
				return res;
			}
		});
	});

	return res;
}

import React, { useEffect, useRef } from "react";
import { POCard } from "../../../../Components/ReportCards/POCard";
import { Header } from "../../../../Components/ReportCards/CommonCardComponents/Header";
import { transformBarChartData, transformTopChartData } from "../../../Dashboard/Processing";
import * as ReportTypesComponentInterface from "../../../../libraries/report-types/charts/interfaces/main";
import { PieChartLegends } from "../../../../Components/ReportCards/CommonCardComponents/PieChartLegends";
import ReportTypes from "../../../../libraries/report-types";
// import fetchedData from '../../Jsons/patientNeedDriver.json;
import { Radio } from "antd";

const fetchedData = [
  {
    chartName: ["Patient Needs Drivers"],
    yAxisLabel: [],
    xAxisLabel: [""],
    chartData: [
      {
        "Dosing Discussion": {
          value: 90,
        },
        "Sadness for Side Effect": {
          value: 75,
        },
        "Anger on Insurance": {
          value: 70,
        },
        "MBTI: Introversion": {
          value: 60,
        },
        "Curisoity in Dosing": {
          value: 30,
        },
      },
    ],
    chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#EAF6FF", "#FFF1F1"],
  },
  {
    chartName: ["Patient Needs Drivers"],
    yAxisLabel: [],
    xAxisLabel: [""],
    chartData: [
      {
        "Dosing Discussion": {
          value: 90,
        },
        "Sadness for Side Effect": {
          value: 75,
        },
        "Anger on Insurance": {
          value: 70,
        },
        "MBTI: Introversion": {
          value: 60,
        },
        "Curisoity in Dosing": {
          value: 30,
        },
      },
    ],
    chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#FFFEE8", "#FFF1F1"],
  },
  {
    chartName: ["Patient Needs Drivers"],
    yAxisLabel: [],
    xAxisLabel: [""],
    topChart: {},
    chartData: [
      {
        "Dosing Discussion": {
          value: 90,
        },
        "Sadness for Side Effect": {
          value: 75,
        },
        "Anger on Insurance": {
          value: 70,
        },
        "MBTI: Introversion": {
          value: 60,
        },
        "Curisoity in Dosing": {
          value: 30,
        },
      },
    ],
    chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#FFF1F1", "#FFF1F1"],
  },
];

//@ts-ignore
const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
  const MAX_LINE_LENGTH = 16;
  const MAX_LINES = 2;
  const LENGTH_OF_ELLIPSIS = 3;
  const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
  const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
  const groupWordsByLength = new RegExp(`([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`, "gm");
  const splitValues = value
    .replace(trimWordsOverLength, "$1...")
    .match(groupWordsByLength)
    .slice(0, 2)
    .map((val: any, i: any) => {
      // console.log("VALUE", groupWordsByLength);
      if (value.length > 17)
        return (
          <tspan
            key={val}
            dy={15 * i - 1}
            x={-10}
            style={{
              fill: "#454250",
              fontSize: "0.675rem",
              fontWeight: 600,
            }}
          >
            {val}
          </tspan>
        );
      else
        return (
          <tspan
            key={val}
            dy={10 * i + 2}
            x={-10}
            style={{
              fill: "#454250",
              fontSize: "0.675rem",
              fontWeight: 600,
            }}
          >
            {val}
          </tspan>
        );
    });

  return (
    <g transform={`translate(${x},${y})`}>
      <text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
        {splitValues}
      </text>
    </g>
  );
};

export default function PatientNeedsDrivers(props: { onBarRectangleClick: any; data?: any }) {
  let tempR = props.data;

  const chartDataSorted = tempR.chartData.sort(function (a: any, b: any) {
    return a.value - b.value;
  });

  // console.log('99999999999999999',chartDataSorted)

  const transformSeries = (data: any = chartDataSorted, key: string = "") => {
    const output = JSON.parse(
      JSON.stringify(new Array(3).fill(new Array(3).fill(0).map((d) => [])))
    ); //.map((d) => new Array(3).fill(0).slice(0, 0));
    JSON.parse(JSON.stringify(data)).forEach((d: any) => {
      const index = d.type == "High" ? 0 : d.type == "Medium" ? 1 : 2;
      const flag = d.key === key;
      console.log("ssssssss", d.value);
      output[index][0].push(d.key);
      output[index][1].push(flag ? 0 : d.value);
      output[index][2].push(flag ? d.value : 0);
    });
    console.log("output1111111111111111", output);
    // output[0][0].reverse();
    // output[0][1].reverse();

    // output[1][0].reverse();
    // output[1][1].reverse();

    // output[2][0].reverse();
    // output[2][1].reverse();
    return output;
  };

  const [temp, setTemp] = React.useState(props.data);
  const [loading, setLoading] = React.useState(false);
  const [chartData, setChartData] = React.useState<any>(getChartData(""));
  const [view, setView] = React.useState(1);
  const onFilterChange = (e: any) => {
    setView(e.target.value);
  };
  const [filteredData, setFilteredData] = React.useState({});
  const topRef = useRef<any>(null);
  const mediumRef = [useRef<any>(null), useRef<any>(null), useRef<any>(null)];

  useEffect(() => {
    const top =
      (mediumRef[view - 1].current as HTMLDivElement).offsetTop -
      (topRef.current as HTMLDivElement).offsetTop;
    topRef.current.scrollTo({
      behavior: "smooth",
      top,
    });
  }, [view]);

  useEffect(() => {
    setChartData(getChartData());
  }, [temp]);

  function getChartData(id?: string) {
    const colors = ["#EAF6FF", "#FFFEE8", "#FFF1F1"];
    const tempValues = [0];
    const graphData: any = {
      data: transformSeries(temp.chartData, id).map((d: any, i: number) => {
        tempValues.push(...d[1], ...d[2]);
        return {
          series: d,
          background: colors[i],
        };
      }),
    };
    graphData.maxValue = Math.max(...tempValues) * 1.1;

    return graphData;
  }
  const onDriverSelect = (d: any) => {
    props.onBarRectangleClick(d.data.id);
    setChartData(getChartData(d.data.id));
  };

  let data = [
    {
      header: "",
      cardTopData: (
        <div className="flex">
          <PieChartLegends
            legendData={
              transformTopChartData(fetchedData[0].topChart, fetchedData[0].chartColor).series
            }
          />
        </div>
      ),
      chartElement: [
        <div className="">
          {
            <div className="flex justify-between">
              <p className="text-base font-semibold mb-3">{fetchedData[0].chartName[0]}</p>
              <Radio.Group
                className="flex flex-end ml-8"
                defaultValue={"Top 10"}
                onChange={(e) => onFilterChange(e)}
                value={view}
              >
                {[
                  ["Top 10", "top"],
                  ["Medium 10", "medium"],
                  ["Low 10", "low"],
                ].map((element, index) => (
                  <Radio value={index + 1} className={"radio-custom " + element[1]}>
                    {element[0]}
                  </Radio>
                ))}
              </Radio.Group>
            </div>
          }
          {chartData && (
            <div className="w-full overflow-y-auto" style={{ maxHeight: 600 }} ref={topRef}>
              {chartData.data.map((data: any, index: number) => (
                <div className="w-full drivers-chart" style={{}} ref={mediumRef[index]}>
                  <ReportTypes
                    header={""}
                    hideShadow
                    hideRibbon
                    minHeight="0px"
                    height={`${
                      !loading
                        ? data.series[0].length * 60 //Object.keys(data.chartData[0]).length * 60
                        : 0
                    }px`}
                    bodyPadding="0px 0px"
                    title={{
                      title: "", //data.chartName[3],
                    }}
                    onBarSelect={(d: any, e: any) => {
                      onDriverSelect(d);
                      console.log("bar clicked", d);
                    }}
                    chartType={ReportTypesComponentInterface.chartType.bar}
                    series={[
                      {
                        dim: 1,
                        color: "#6D9689CC",
                        name: "normal",
                      },
                      {
                        dim: 2,
                        color: "#0B654ACC",
                        name: "selected",
                      },
                    ]}
                    data={data.series}
                    axis={{
                      primaryX: {
                        enable: false,
                        dim: 0,
                        tickValues: 5,
                        minValue: -chartData.maxValue,
                        maxValue: chartData.maxValue,
                      },
                      primaryY: {
                        tickSize: 5,
                        tickValues: 5,
                        tickRotation: 0,
                        legendOffset: -40,
                        renderTick: HorizontalTick,
                      },
                    }}
                    animate={false}
                    label={{
                      format: (d: any) => {
                        return ``;
                      },
                      skipSmallValue: true,
                      skipWidth: 20,
                    }}
                    tooltip={{
                      isInteractive: true,
                      format: "normal",
                    }}
                    chartArea={{
                      layout: ReportTypesComponentInterface.chartAreaLayout.horizontal,
                      background: data.background,
                    }}
                    chartConfig={{
                      cartesian: {
                        // groupMode: groupMode.grouped,
                        grid: {
                          enableGridX: true,
                          enableGridY: false,
                          gridYValues: 5,
                          gridXValues: 5,
                        },
                        gaps: { barGap: 0.5 },
                      },
                    }}
                    style={{
                      margin: {
                        top: 0,
                        right: 10,
                        bottom: 0,
                        left: 110,
                      },
                      labelTextColor: "white",
                      axisWidth: 1,
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>,
      ],
    },
  ];
  return <POCard {...data[0]} />;
}

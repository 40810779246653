import * as Study from "./../../../services/study";

export const getStudies: () => Promise<unknown> = async () => {
  const data = await Study.getAllStudies();
  if (data === null || data === undefined) return null;
  return data;
};

export const getStudyTypes: () => Promise<unknown> = async () => {
  const data = await Study.getStudyTypeData();
  if (data === null || data === undefined) return null;
  return data;
};

export const getGeographies: () => Promise<unknown> = async () => {
  const data = await Study.getGeographyData();
  if (data === null || data === undefined) return null;
  return data;
};

export const deleteStudy: (studyId: number) => Promise<unknown> = async (studyId: number) => {
  // console.log(studyId);
  const data = await Study.deleteStudyData(studyId);
  if (data === null || data === undefined) return null;
  return data;
};

export const deleteUploadedGuide: (guideId: number) => Promise<unknown> = async (guideId: number) => {
  // console.log(guideId);
  const data = await Study.deleteUploadedGuideFile(guideId);
  if (data === null || data === undefined) return null;
  return data;
};

export const deleteUploadedTranscript: (trId: number) => Promise<unknown> = async (trId: number) => {
  // console.log(trId);
  const data = await Study.deleteUploadedTranscriptFile(trId);
  if (data === null || data === undefined) return null;
  return data;
};

export const getStudyDetails: (studyId: number) => Promise<unknown> = async (studyId: number) => {
  const data = await Study.getStudyDetailsData(studyId);
  if (data === null || data === undefined) return null;
  return data;
};

export const addNewStudy: (study: { studyTypeId: number; studyName: string; studyTypeName: string; studyDescription: string }) => Promise<unknown> = async (study) => {
  const data = await Study.addNewStudyData(study);
  if (data === null || data === undefined) return null;
  return data;
};

export const addNewWaveRow: (wave: {
  waveId: number;
  waveName: string;
  studyId: number;
  geoData: {
    studyIterId: number;
    geoId: number;
    geoName: string;
    start_dt: string;
    end_dt: string;
  }[];
}) => Promise<unknown> = async (wave) => {
  const data = await Study.addNewWaveRowData(wave);
  if (data === null || data === undefined) return null;
  return data;
};

export const deleteWaveRow: (oDeleteWave: { waveId: number; studyIterIds: number[] }) => Promise<unknown> = async (oDeleteWave) => {
  const data = await Study.deleteWaveRowData(oDeleteWave);
  if (data === null || data === undefined) return null;
  return data;
};

// export const s3Upload: (dgData: {
//   studyIterId: number;
//   dgId: number;
//   dgS3Path: string;
// }, file: IForm) => Promise<unknown> = async (dgData, file) => {
// 	const data = await Study.s3UploadData(dgData, file);
// 	if (data === null || data === undefined) return null;
// 	return data;
// };

export const getTranscriptDetailsForStudy: (studyIterId: number) => Promise<unknown> = async (studyIterId: number) => {
  const data = await Study.getTranscriptDetailsForStudyData(studyIterId);
  if (data === null || data === undefined) return null;
  return data;
};

export const triggerNodeGraph: (studyIterId: number) => Promise<unknown> = async (studyIterId: number) => {
  const data = await Study.triggerNodeGraphData(studyIterId);
  if (data === null || data === undefined) return null;
  return data;
};

export const downloadS3File: (filePath: string) => Promise<unknown> = async (filePath: string) => {
  const data = await Study.downloadS3FileData(filePath);
  if (data === null || data === undefined) return null;
  return data;
};

export const getTaxonomy: (studyId: number) => Promise<unknown> = async (studyId: number) => {
  const data = await Study.getTaxonomyData(studyId);
  if (data === null || data === undefined) return null;
  return data;
};

export const saveTaxonomy: (configureTaxonomy: { study_id: number; s3_path: string; taxonomyJson: any[] }) => Promise<unknown> = async (configureTaxonomy) => {
  const data = await Study.saveTaxonomyData(configureTaxonomy);
  if (data === null || data === undefined) return null;
  return data;
};

export const getNotificationMessages: () => Promise<unknown> = async () => {
  const data = await Study.getNotificationMessagesData();
  if (data === null || data === undefined) return null;
  return data;
};

export const deleteNotification: (ids: number[]) => Promise<unknown> = async (ids: number[]) => {
  const data = await Study.deleteNotificationData(ids);
  if (data === null || data === undefined) return null;
  return data;
};

export const downloadMetaFile: (studyId: number) => Promise<unknown> = async (studyId: number) => {
  const data = await Study.downloadMetaFileData(studyId);
  if (data == null ) return null;
  return data;
};
export const Icon = () => (
	<svg
		width="18"
		height="20"
		viewBox="0 0 18 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12.9291 5.53165V4.46022C12.9291 4.36201 12.8487 4.28165 12.7505 4.28165H4.17909C4.08088 4.28165 4.00052 4.36201 4.00052 4.46022V5.53165C4.00052 5.62987 4.08088 5.71022 4.17909 5.71022H12.7505C12.8487 5.71022 12.9291 5.62987 12.9291 5.53165ZM4.17909 7.49594C4.08088 7.49594 4.00052 7.57629 4.00052 7.67451V8.74594C4.00052 8.84415 4.08088 8.92451 4.17909 8.92451H8.28624C8.38445 8.92451 8.46481 8.84415 8.46481 8.74594V7.67451C8.46481 7.57629 8.38445 7.49594 8.28624 7.49594H4.17909ZM12.572 10.0852C9.90901 10.0852 7.75052 12.2437 7.75052 14.9067C7.75052 17.5696 9.90901 19.7281 12.572 19.7281C15.2349 19.7281 17.3934 17.5696 17.3934 14.9067C17.3934 12.2437 15.2349 10.0852 12.572 10.0852ZM14.9715 17.3062C14.3309 17.9468 13.4782 18.2995 12.572 18.2995C11.6657 18.2995 10.813 17.9468 10.1724 17.3062C9.53177 16.6656 9.17909 15.8129 9.17909 14.9067C9.17909 14.0004 9.53177 13.1477 10.1724 12.5071C10.813 11.8665 11.6657 11.5138 12.572 11.5138C13.4782 11.5138 14.3309 11.8665 14.9715 12.5071C15.6121 13.1477 15.9648 14.0004 15.9648 14.9067C15.9648 15.8129 15.6121 16.6656 14.9715 17.3062ZM14.5586 13.2102H13.5697C13.5117 13.2102 13.4581 13.237 13.4246 13.2839L12.0072 15.2437L11.4916 14.5317C11.4751 14.5087 11.4533 14.49 11.428 14.4771C11.4028 14.4643 11.3748 14.4578 11.3465 14.458H10.3621C10.217 14.458 10.1322 14.6232 10.217 14.7415L11.8644 17.0205C11.9358 17.1187 12.0809 17.1187 12.1523 17.0205L14.7014 13.4937C14.7885 13.3754 14.7036 13.2102 14.5586 13.2102ZM7.39338 17.5852H2.21481V1.87094H14.7148V9.54951C14.7148 9.64772 14.7952 9.72808 14.8934 9.72808H16.1434C16.2416 9.72808 16.322 9.64772 16.322 9.54951V0.97808C16.322 0.58299 16.0028 0.263794 15.6077 0.263794H1.32195C0.926862 0.263794 0.607666 0.58299 0.607666 0.97808V18.4781C0.607666 18.8732 0.926862 19.1924 1.32195 19.1924H7.39338C7.49159 19.1924 7.57195 19.112 7.57195 19.0138V17.7638C7.57195 17.6656 7.49159 17.5852 7.39338 17.5852Z"
			fill="#454250"
		/>
	</svg>
);

import { Skeleton, Tag } from "antd";
import { icons } from "../../GlobalThemes";
import React from "react";
import AppContext from "../../store";

/**
 * It is a wrapper of transcript list with result summary toggle and header of search page
 *
 * @param header - The props get the header of the search tab
 * @param noSummary - The props represent if summary is present
 * @param expanded - The props represent if more information on transcript card is visible
 * @param toggleExpand - The function is used to change the expanded property
 * @param toggleSummary - The function is used to change the result summary view
 * @param children - The props contain the children of the wrapper, such as Trancript card
 * @param showSummary - The props represent if summary is visible
 * @param loading - The props represent if page is loading and will render the skeleton in place of the data
 *
 * @returns The transcrpt wrapper with the list of transcripts in it
 */

export default function TranscriptsWrapper(props: {
  header: string;
  subHeader?: string;
  noSummary?: boolean;
  expanded?: boolean;
  toggleExpand?: React.Dispatch<React.SetStateAction<boolean>>;
  toggleSummary?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: JSX.Element;
  showSummary?: boolean;
  loading?: boolean;
  noSideSummary?: boolean;
  goodSearch?: any;
  badSearch?: any;
  averageSearch?: any;
  showKeywords?: boolean;
  hideSummaryTags?: boolean;
}) {
  const context = React.useContext(AppContext);
//  console.log("CHECK HERE : ", props);
  return (
    <>
      {!props.loading ? (
        <div>
          {!props.noSideSummary && (
            <div className="py-4 px-4 border-b">
              <div className="flex justify-between">
                <p className="font-semibold text-base ml-1" style={{ color: "" }}>
                  {props.header}
                  {/* <span className="ml-4 font-medium">
                    {!props.hideSummaryTags && props.goodSearch && (
                      <>
                        {props.goodSearch === 0 &&
                        props.badSearch === 0 &&
                        props.averageSearch === 0 ? (
                          <></>
                        ) : (
                          <>
                            <Tag color="green">High : {props.goodSearch}</Tag>
                            <Tag color="yellow">Medium : {props.averageSearch}</Tag>
                            <Tag color="red">Low : {props.badSearch}</Tag>
                          </>
                        )}
                      </>
                    )}
                  </span> */}
                </p>

                {/* {!props.noSideSummary && (
                  <div className="flex">
                    <div
                      style={{ color: "#27A6A4" }}
                      className="flex text-base cursor-pointer"
                      onClick={() => {
                        if (props.toggleExpand) props.toggleExpand(!props.expanded);
                      }}
                    >
                      {props.expanded ? "Collapse Results" : "Expand Results"}
                    </div>
                  </div>
                )} */}
                {!props.noSideSummary && context.envId === 3 && (//change to 3
                  <div className="flex">
                    {!props.noSummary && (
                      <div
                        className="flex pl-5 border-l-2 ml-5 cursor-pointer"
                        onClick={() => {
                          if (props.toggleSummary) {
                            props.toggleSummary(!props.showSummary);
                          }
                        }}
                      >
                        <div className="mr-2">
                          {icons.find((l) => l.id === "Bar Graph Green")?.icon}
                        </div>
                        <span style={{ color: "#27A6A4" }} className="flex text-base">
                          {props.showSummary ? "Hide Result Summary" : "Show Result Summary"}
                        </span>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
          <div>{props.children}</div>
        </div>
      ) : (
        <>
          <div className="py-3 px-8 border-b">
            <div className="flex justify-between">
              <div className="w-64">
                <Skeleton.Button active size="default" block={true} />
              </div>
              <div className="flex">
                <div className="w-80">
                  <Skeleton.Button active size="default" block={true} />
                </div>
              </div>
            </div>
          </div>
          <div>{props.children}</div>
        </>
      )}
    </>
  );
}

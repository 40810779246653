import { ResponsiveLine } from "@nivo/line";
import * as ReportTypesComponentProps from "../../interfaces/main";
import { removeMinuteString } from "../ScatterPlot/chart";
import transformData from "./transform";

const theme = (
	enableAxis?: boolean,
	axisWidth?: number,
	axisColor?: string,
	textColor?: string,
	background?: string,
	fontSize?: number
) => {
	return {
		background: background || "white",
		textColor: textColor || "black",
		fontSize: fontSize || 11,
		axis: {
			domain: {
				line: {
					stroke: enableAxis ? axisColor || "black" : "",
					strokeWidth: enableAxis
						? axisWidth !== undefined
							? axisWidth
							: 1
						: 0,
				},
			},
			textColor: axisColor || "black",
			tickColor: axisColor || "black",
			legend: {
				text: {
					fill: "#454250",
					fontSize: "0.875rem",
					fontWeight: 700,
				},
			},
			ticks: {
				text: {
					fill: "#454250",
					fontSize: "0.675rem",
					fontWeight: 600,
				},
			},
		},
	};
};

const customTooltip = (node: any, format: any) => {
	console.log(node);
	return (
		<div
			style={{
				color: "white",
				background: "#333333ba",
				padding: "12px 16px",
				position:'absolute',
				right: 0,
				width:'max-content'
			}}
		>
			<p><strong>{node.point.data ? removeMinuteString(node.point.data.x): 0}</strong>: {node.point.data ? (format === 'round' || format === 'round_right') ? (Math.round(node.point.data.y *10)/10) : node.point.data.y : 0}</p>
		</div>
	);
};

const customTooltipLeft = (node: any, format: any) => {
	console.log(node);
	return (
		<div
			style={{
				color: "white",
				background: "#333333ba",
				padding: "12px 16px",
				position:'absolute',
				left: 0,
				width:'max-content'
			}}
		>
			<p><strong>{node.point.data ? removeMinuteString(node.point.data.x): 0}</strong>: {node.point.data ? (format === 'round' || format === 'round_left') ? (Math.round(node.point.data.y *10)/10) : node.point.data.y : 0}</p>
		</div>
	);
};

/**
 * Renders the Line Chart Component. The component makes use of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the Line Chart Component
 *
 * @returns Line Chart Component of Nivo
 */
const LineChart: React.FC<ReportTypesComponentProps.Interface> = (
	props: ReportTypesComponentProps.Interface
): JSX.Element => (
	<>{console.log('222222222222222',props)}
		<ResponsiveLine
			data={transformData(
				props.data,
				props.series,
				props.axis?.primaryX?.dim || 0
			)}
			colors={(d) => d.color}
			curve={props.chartConfig.cartesian?.line?.curve}
			isInteractive={props.tooltip ? true : false} // tooltip format to be added
			// animate={props.animate}
			tooltip={(node) =>
				props.tooltip?.format ? 
					props.tooltip?.format === 'left' || props.tooltip?.format === 'round_left' ?
						customTooltipLeft(node, props.tooltip?.format)
					: customTooltip(node, props.tooltip?.format)
					: null
			}
			enablePoints={props.chartConfig.cartesian?.line?.enablePoints}
			enableCrosshair={props.crosshair || false}
			pointSize={props.chartConfig.cartesian?.line?.pointSize}
			pointColor={props.chartConfig.cartesian?.line?.pointColor}
			pointBorderWidth={
				props.chartConfig.cartesian?.line?.pointBorderWidth
			}
			pointBorderColor={props.chartConfig.cartesian?.line?.pointColor}
			useMesh={true}
			enablePointLabel={props.label ? true : false}
			// format        to be added
			theme={theme(
				props.axis?.primaryX?.enable ||
					props.axis?.primaryY?.enable !== undefined
					? props.axis?.primaryX?.enable ||
							props.axis?.primaryY?.enable
					: true,
				props.style?.axisWidth,
				props.style?.axisColor,
				props.style?.textColor,
				props.chartArea?.background,
				props.style?.fontSize
			)}
			layers={[
				"grid",
				"markers",
				"areas",
				"axes",
				"crosshair",
				"lines",
				"points",
				"slices",
				"mesh",
				"legends",
			]}
			enableArea={props.chartConfig.cartesian?.line?.enableArea}
			margin={
				props.style?.margin || {
					top: 20,
					right: 80,
					bottom: 50,
					left: 50,
				}
			}
			yScale={{
				type: "linear",
				min:
					props.axis?.primaryY?.minValue !== undefined
						? props.axis?.primaryY?.minValue
						: "auto",
				max:
					props.axis?.primaryY?.maxValue !== undefined
						? props.axis?.primaryY?.maxValue
						: "auto",
			}}
			axisRight={props.axisRight}
			enableGridX={props.chartConfig.cartesian?.grid?.enableGridX}
			enableGridY={props.chartConfig.cartesian?.grid?.enableGridY}
			gridXValues={props.chartConfig.cartesian?.grid?.gridXValues}
			gridYValues={props.chartConfig.cartesian?.grid?.gridYValues}
			axisBottom={props.axis?.primaryX}
			axisLeft={props.axis?.primaryY}
			lineWidth={props.chartConfig.cartesian?.line?.lineWidth}
			// animate={props.animate ? props.animate : true}
			legends={
				props.legend
					? [
							{
								anchor:
									props.legend?.position || "bottom-right",
								direction: props.legend?.direction || "column",
								itemHeight: 20,
								itemWidth: props.legend?.itemWidth || 110,
								toggleSerie: true,
								translateX: props.legend?.translateX || 120,
								translateY: props.legend?.translateY || 6,
								symbolShape: props.legend.symbolShape,
							},
					  ]
					: []
			}
		/>
	</>
);
export default LineChart;

export enum areaBlendMode {
	normal = "normal",
	multiply = "multiply",
	screen = "screen",
	overlay = "overlay",
}
export enum groupMode {
	stacked = "stacked",
	grouped = "grouped",
}
export enum lineCurveType {
	basis = "basis",
	cardinal = "cardinal",
	catmullRom = "catmullRom",
	linear = "linear",
	monotoneX = "monotoneX",
	monotoneY = "monotoneY",
	natural = "natural",
	step = "step",
	stepAfter = "stepAfter",
	stepBefore = "stepBefore",
}

/**
 * Main Interface for Chart Config of Cartesian Component
 */
export interface Interface {
	line?: {
		curve?: lineCurveType;
		lineWidth?: number;
		enableArea?: boolean;
		areaOpacity?: number;
		areaBlendMode?: areaBlendMode;
		enablePoints?: boolean;
		pointSize?: number;
		pointColor?: string;
		useMesh?: boolean;
		pointBorderWidth?: number;
		pointBorderColor?: string;
	};
	groupMode?: groupMode;
	grid?: {
		enableGridX?: boolean;
		enableGridY?: boolean;
		gridXValues?: number | number[];
		gridYValues?: number | number[];
	};

	gaps?: {
		barGap?: number;
		groupGap?: number;
	};
}

import TableWrapper from "../../../Components/TableWrapper";
import ReportTypes from "../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import { transformTableData } from "../../Dashboard/Processing";
import { StoryType, icons } from "../../../GlobalThemes";
import { Empty, Popconfirm } from "antd";
import React from "react";
import { oldstories_empty_grouped_columns, stories_empty_grouped_columns, storiesEmptyData } from "../data";
import type { ColumnsType, FilterValue, SorterResult } from 'antd/es/table/interface';
import type { TableProps } from 'antd';
import AppContext from "../../../store";
// import AddStoryModalContent from "../../../Components/AddStoryModal";
// import { SelectedStories } from "../../Stories/SelectedStories";

/**
 * Renders the Stories Table, along with the various Search and Sort options.
 *
 * @param data - Data to be shown in the Accordion.
 * @param editStory - Callback function for handling updation of story data.
 * @param deleteStory - Callback function for handling deletion a story.
 * @param addNewStory - Callback function for handling addition of a story.
 * @param loading - To specify whether data is getting fetched or not.
 *
 * @returns Stories View.
 */

interface DataType {
  storyName: string;
}

export default function OldStories(props: {
  data: any;
  editStory?: (storyId: number) => void;
  deleteStory?: (storyId: number) => void;
  addNewStory?: (storyName: string) => void;
  selectedSort?: string;
  loading?: boolean;
}) {
  const context = React.useContext(AppContext);
  const [searchedData, setsearchedData] = React.useState(props.data);
  const [sortedInfo, setSortedInfo] = React.useState<SorterResult<DataType>>({});

  // const handleChange: TableProps<DataType>["onChange"] = (sorter) => {
  //   // console.log('Various parameters', sorter);
  //   setSortedInfo(sorter as SorterResult<DataType>);
  // };

  // const setSort = () => {
  //   setSortedInfo({
  //     order: "descend",
  //     columnKey: "Story_Name",
  //   });
  // };

  function handleDelete(storyId: number) {
    if (props.deleteStory) props.deleteStory(storyId);
  }
  function handleEdit(storyId: number) {
    if (props.editStory) props.editStory(storyId);
  }

  const grouped_columns = [
    {
      title: "Story Name",
      accessor: "storyName",
      key: "storyName",
      width: 570,
      ellipsis: true,
      Cell: (props: any) => {
        // console.log("props1", props);
        if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
          return (
            <div className="text-text-gray-80 text-base">
              {icons.find((l) => l.id === "HourGlass")?.icon}
              Updating
            </div>
          );
        return <div>{props.cell.value}</div>;
      },
    },
    {
      title: "Story Type",
      accessor: "storyType",
      sort: true,
      width: 150,
      Cell: (props: any) => {
        let storyType = props.cell.value;
        if (storyType === StoryType.Reports) {
          storyType = "Reports";
        } else {
          storyType = context.envId !== 2 ? "Transcripts" : "Notes";
        }
        return <div>{storyType}</div>;
      },
    },
    {
      title: "Date Created",
      accessor: "dateCreated",
      sort: true,
      Cell: (props: any) => {
        const date = new Date(props.cell.value);
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return (
          <>
            <div style={{ textAlign: "left" }}>{formattedDate}</div>
          </>
        );
      },
      width: 150,
    },
    {
      title: "# of Annotated Selections",
      accessor: "noofAnnotationsSelections",
      sort: true,
      // sorter: (a, b) => a.age - b.age,
      Cell: (props: any) => {
        return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
      },
      width: 130,
    },
    {
      title: "",
      accessor: "Actions",
      Cell: (props: any) => {
        const createdData = props.row.original.createdBy;
        return (
          <div className="flex items-center gap-2">
            <div
              title="Delete Story"
              className="text-text-teal flex justify-center cursor-pointer icon-width"
              onClick={() => {
                handleEdit(searchedData.stories[props.cell.row.index].id);
              }}
            >
              {icons.find((l) => l.id === "Edit")?.icon}
            </div>
            <Popconfirm
              title="Sure to delete this story?"
              placement="topRight"
              arrowPointAtCenter
              className="text-text-teal flex justify-center cursor-pointer icon-width"
              onConfirm={() => {
                handleDelete(searchedData.stories[props.cell.row.index].id);
              }}
              okText="Yes"
              cancelText="No"
              disabled={context.userName !== createdData}
            >
              <span style={{ color: context.userName !== createdData ? "#00000040" : "" }}>{icons.find((l) => l.id === "DeleteOutlined")?.icon}</span>
            </Popconfirm>
          </div>
        );
      },
      width: 10,
    },
  ];

  // const handleSearch = (e: any) => {
  //   // console.log("Man United Suck", searchedData);
  //   if (!e.target.value) {
  //     setsearchedData(props.data);
  //   } else {
  //     let temp = { ...props.data };
  //     temp.stories = temp.stories.filter((story: any) => story["storyName"].toLowerCase().includes(e.target.value.toLowerCase()));
  //     setsearchedData(temp);
  //   }
  // };

  React.useEffect(() => {
    setsearchedData(props.data);
  }, [props.data]);

  return (
    <>
      {!props.loading ? (
        <>
          <TableWrapper
            title="Total Story"
            value={searchedData.stories.length}
            createStory
            showModalButton={props.addNewStory}
            tagName={"Story"}
            // showModal={showModal}
            // searchBar={handleSearch}
          >
            <>
              {searchedData.stories.length > 0 ? (
                <>
                  <ReportTypes
                    // onChange={handleChange}
                    hideShadow
                    hideRibbon
                    chartType={ReportTypesComponentInterface.chartType.simpleTable}
                    series={[]}
                    chartConfig={{
                      table: {
                        tableData: {
                          column: grouped_columns,
                          data: transformTableData(
                            searchedData.stories,
                            [],
                            [
                              {
                                id: "storyName",
                                link: "story-mode",
                              },
                            ],
                            undefined,
                            true
                          ).data,
                        },
                        grid: {
                          enableGridX: false,
                          enableGridY: false,
                        },
                        noSearchField: true,
                        noPagination: false,
                        // maxHeight: 394,
                        maxHeight: "61vh",
                      },
                    }}
                  />
                </>
              ) : (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="flex h-64 items-center justify-center" />
              )}
            </>
          </TableWrapper>
        </>
      ) : (
        <>
          <TableWrapper loading={true} minHeight="37em">
            <ReportTypes
              hideShadow
              hideRibbon
              chartType={ReportTypesComponentInterface.chartType.simpleTable}
              series={[]}
              chartConfig={{
                table: {
                  tableData: {
                    column: oldstories_empty_grouped_columns,
                    data: storiesEmptyData,
                  },

                  noSearchField: true,
                  noPagination: true,
                  maxHeight: "61vh",
                },
              }}
            />
          </TableWrapper>
        </>
      )}
    </>
  );
}

import * as cartesianInterface from "./cartesianChart";
import * as radialInterface from "./radialChart";
import * as tableInterface from "./table";
import * as geoMapInterface from "./geoMap";

export enum chartType {
  bar,
  line,
  pie,
  heatMap,
  iconMap,
  simpleTable,
  geoMap,
  scatterPlot,
}
export enum xAxisPosition {
  top = "top",
  bottom = "bottom",
}
export enum axisLegendPosition {
  start = "start",
  middle = "middle",
  end = "end",
}
export enum yAxisPosition {
  left = "left",
  right = "right",
}
export enum yAxisScaleType {
  linear = "linear",
  log = "symlog",
}
export interface xAxis {
  enable?: boolean;
  position?: xAxisPosition;
  dim: number;
  tikPadding?: number;
  tickRotation?: number;
  legend?: string;
  legendOffset?: number;
  legendPosition?: axisLegendPosition;
  tickSize?: number;
  tickValues?: number | number[];
  minValue?: number | "auto";
  maxValue?: number | "auto";
  renderTick?: any;
}
export interface yAxis {
  enable?: boolean;
  position?: yAxisPosition;
  scale?: yAxisScaleType; //change to nivo name
  minValue?: number | "auto";
  maxValue?: number | "auto";
  reverse?: boolean;

  tickSize?: number;
  tikPadding?: number;
  tickRotation?: number;
  legend?: string;
  legendOffset?: number;
  legendPosition?: axisLegendPosition;
  tickValues?: number | number[];
  renderTick?: any;
}
export enum legendPosition {
  topLeft = "top-left",
  top = "top",
  topRight = "top-right",
  left = "left",
  center = "center",
  right = "right",
  bottomLeft = "bottom-left",
  bottom = "bottom",
  bottomRight = "bottom-right",
}
export enum legendDirection {
  row = "row",
  col = "column",
}
export enum legendSymbolShape {
  circle = "circle",
}
export enum labelPositionHorizontal {
  left = "left",
  middle = "middle",
  right = "right",
}
export enum labelPositionVertical {
  top = "top",
  middle = "middle",
  bottom = "bottom",
}
export enum chartAreaLayout {
  horizontal = "horizontal",
  vertical = "vertical",
}
export enum fillType {
  solid = "solid",
  gradient = "gradient",
}
export enum seriesPlotAxis {
  primary = "primaryY",
  econdary = "secondaryY",
}
export { cartesianInterface };
export { radialInterface };
export { tableInterface };
export { geoMapInterface };

/**
 * Main Interface for ReportTypes Component
 */
export interface Interface {
	title?: {
		title: string;
		subTitle?: string;
	};
	chartType: chartType;
	crosshair?: boolean;
	legend?: {
		//enable ?: boolean;
		position?: legendPosition;
		direction?: legendDirection;
		symbolShape?: legendSymbolShape;
		symbolSize?: number;
		translateX?: number;
		translateY?: number;
		itemWidth?: number;
	};
	label?: {
		//enable ?: boolean
		format?: any;
		position?: {
			horzontal?: labelPositionHorizontal;
			vertical?: labelPositionVertical;
		};
		skipSmallValue?: boolean;
		skipWidth?: number;
	};
	chartArea?: {
		background?: any;
		backgroundDivision?: 2 | 3 | 4;
		twoByTwo?: boolean;
		layout?: chartAreaLayout;
	};
	axis?: {
		primaryX: xAxis | null;
		secondaryX?: xAxis;
		primaryY?: yAxis | null;
		secondaryY?: yAxis;
		dualAxis?: boolean;
	};
	style?: {
		margin?: {
			top?: number;
			bottom?: number;
			left?: number;
			right?: number;
		};
		borderColor?: string;
		borderWidth?: number;
		borderRadius?: number;
		labelTextColor?: string;
		textColor?: string;
		fontSize?: number;
		axisWidth?: number;
		axisColor?: string;
		fillType?: fillType;
		arcLinkColor?: string;
	};
	tooltip?: {
		isInteractive?: boolean;
		format?: string; //define {parameters}
		valueFormat?: any;
	};
	animate?: boolean;
	chartConfig: {
		cartesian?: cartesianInterface.Interface;
		radial?: radialInterface.Interface;
		table?: tableInterface.Interface;
		geoMap?: geoMapInterface.Interface;
	};
	series: {
		labelCol?: number;
		name: string;
		dim: number;
		color?: string;
		sorted?: boolean;
		axis?: seriesPlotAxis;
	}[];
	data?: any;
	onBarSelect?: any;
	yScale?: any;
	axisRight?: {
		tickValues?: number | number[];
	  orient?: string;
	  tickSize: number;
	  tickPadding: number;
	  tickRotation: number;
	  legend: string;
	  legendOffset: number;
	  minValue:number;
	  maxValue:number;
	};
}

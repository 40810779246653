import { icons } from "../../GlobalThemes";
import { FilterActiveInterface, FilterInterface } from "../../Pages/Dashboard";
import { FilterCard } from "../FilterCard";
import { Breadcrumb, Skeleton } from "antd";
import { Link } from "react-router-dom";
import "./style.css";
export default function SubHeaderComponent(props: {
	title?: string;
	showDrawer?: () => void;
	filters?: FilterInterface;
	setFilters?: React.Dispatch<React.SetStateAction<FilterInterface>>;
	filterActiveStatus?: FilterActiveInterface;
	// setFilterActiveStatus?: React.Dispatch<
	// 	React.SetStateAction<FilterActiveInterface>
	// >;
	breadcrumb?: { item: string; link: string }[];
	status?: string;
	extra?: JSX.Element;
	loading?: boolean;
}) {
	return (
		<div className="w-screen px-5 sub-header">
			<div className="flex pt-3 pb-2 items-center justify-between">
				<div className="flex items-center justify-start">
					<div className="flex items-center justify-start">
						{props.breadcrumb && (
							<div className=" breadcrumb">
								<Breadcrumb>
									{props.breadcrumb.map((item) => (
										<Breadcrumb.Item>
											{item.link ? (
												<Link to={item.link}>
													{item.item}
												</Link>
											) : (
												item.item
											)}
										</Breadcrumb.Item>
									))}
								</Breadcrumb>
							</div>
						)}
						{props.title && (
							<h2 className="text-xl font-semibold ">
								{props.title}
							</h2>
						)}
						{props.loading && (
							<div className="w-56">
								<Skeleton.Button
									active
									size="default"
									// shape="round"
									block={true}
								/>
							</div>
						)}
						{props.showDrawer && (
							<div
								title="Filters"
								className="ml-2 cursor-pointer text-text-gray-60"
								onClick={props.showDrawer}
							>
								{icons.find((l) => l.id === "Filter")?.icon}
							</div>
						)}
						{props.status && (
							<div className="ml-4">
								<div
									className={`${
										props.status === "Published"
											? "bg-body-green"
											: props.status === "Publishing"
											? "bg-body-yellow"
											: "bg-body"
									} px-2 py-1  rounded-sm ${
										props.status === "Published"
											? "border-border-green"
											: props.status === "Publishing"
											? "border-border-orange"
											: ""
									} border-0`}
								>
									<div className="text-text-gray-80 font-semibold flex items-center gap-2">
										{/* Status :{" "} */}
										<div>
											{
												icons.find(
													(l) => l.id === props.status
												)?.icon
											}
										</div>
										<span
											className={`font-medium ${
												props.status === "Published"
													? "text-text-teal"
													: props.status ===
													  "Publishing"
													? "text-text-orange"
													: "text-text-gray-70"
											}`}
										>
											{props.status}
										</span>
									</div>
								</div>
							</div>
						)}
					</div>
					{props.filters && (
						<div className="flex flex-wrap">
							<FilterCard
								filters={props.filters}
								setFilters={props.setFilters}
								filterActiveStatus={props.filterActiveStatus}
							/>
						</div>
					)}
					{/* {props.status && (
						<div className="text-text-gray-70 font-normal text-sm ml-4 mt-1 px-2 py-1 bg-body-green rounded-md">
							Status : {props.status}
						</div>
					)} */}
				</div>
				{props.extra && <div>{props.extra}</div>}
				{props.extra && props.loading && (
					<div className="w-96">
						<Skeleton.Button
							active
							size="default"
							// shape="round"
							block={true}
						/>
					</div>
				)}
			</div>
			{/* {props.status && (
				<div className="text-black font-normal text-base">
					Status : {props.status}
				</div>
			)} */}
		</div>
	);
}

import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FilterActiveInterface, FilterInterface } from ".";
import SkeletonCard from "../../Components/SkeletonCard";
import { fetchedFilters, pageWiseFilters } from "./data";
import { POAgentActivity } from "./ProgramOverview/POAgentActivity";
import {
	CardInterface,
	ProgramOverviewCalls,
} from "./ProgramOverview/ProgramOverviewCalls";
import { ProgramOverviewReach } from "./ProgramOverview/ProgramOverviewReach";
import * as ProgramOverviewServiceHelper from "./ServiceHelpers/ProgramOverview";

export interface filterTabs {
	tab: string;
	filter: string[];
	subtab?: {
		name: string;
		filters: string[];
	}[];
}

/**
 * This page is the main program overview tab in the dashboard page. It also contains the routes to navigate between Calls, Reach and Agents Activity page
 *
 *  @param filterActiveStatus - Props used to pass the active filters as true
 *  @param setFilterActiveStatus - Function to set the status of active filters as true
 *  @param filters - This props is used to pass the filters in cards
 *
 * @returns the navigation to navigate between different subtabs
 */

export default function ProgramOverview(props: {
	filterActiveStatus: FilterActiveInterface;
	setFilterActiveStatus: React.Dispatch<
		React.SetStateAction<FilterActiveInterface>
	>;
	filters: FilterInterface;
}) {
	const { subsubtab } = useParams();
	const [POData, setPOData] = useState<any>({});
	const [loading, setLoading] = useState(true);
	const [subTab, setSubTab] = useState("");
	var filters = {
		Timeframe_Display: undefined,
		Start_Date: "",
		End_Date: "",
		Call_Type: undefined,
		Therapy_Status: undefined,
		Call_Intent: undefined,
		Adherence: undefined,
	};
	let navigate = useNavigate();
	useEffect(() => {
		console.log("Program Overview tabs ", subsubtab);
		addSubTabFilters(subsubtab || "");
		fetchProgramOverviewData();
	}, [props.filters, subsubtab]);

	React.useEffect(() => {
		if (subsubtab === undefined) navigate({ pathname: "Calls" });
	});
	const callVolumeRefresh = async (tot: string) => {
		const data: any = await ProgramOverviewServiceHelper.callVolumeRefresh(
			tot
		);
		if (data) {
			const temp = POData;
			temp.reach[1] = data;
			setPOData(temp);
			return true;
		} else {
			return false;
		}
	};
	function findId(filter: string, value: any) {
		let grpIdx: number = 0;
		let idx: number = 0;
		fetchedFilters.map((f) => {
			f.optionsObject.map((group) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx = group.id;
						grpIdx = group.id;
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (
							Array.isArray(value) &&
							value.find((f) => f === group.name)
						) {
							grpIdx = group.id;
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find(
									(sg) => sg.name === v
								);
								if (ob) {
									grpIdx = group.id;
									idx = ob.id;
								}

								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	}

	const fetchProgramOverviewData = async () => {
		setLoading(true);
		var poFilters: FilterInterface = Object.assign({}, props.filters);
		console.log("PP", props.filters);
		poFilters.Call_Type = findId(
			"Call_Type",
			props.filters.Call_Type
		).grpIdx;
		poFilters.Therapy_Status = findId(
			"Therapy_Status",
			props.filters.Therapy_Status
		).grpIdx;
		poFilters.Call_Intent = findId(
			"Call_Intent",
			props.filters.Call_Intent
		).grpIdx;
		poFilters.Adherence = findId(
			"Adherence",
			props.filters.Adherence
		).grpIdx;
		const data: any = await ProgramOverviewServiceHelper.getProgramOverview(
			subsubtab || "",
			poFilters,
			props.filterActiveStatus
		);

		if (data) {
			setPOData(data);
			setSubTab(subsubtab || "");
			setLoading(false);
		} else {
			setPOData({});
		}
	};

	const addSubTabFilters = (tab_name: string) => {
		const obj: FilterActiveInterface = props.filterActiveStatus;
		for (let key in obj) {
			obj[key] = false;
		}
		var filters = pageWiseFilters.find(
			(page) => page.tab === "Program Overview"
		);
		if (filters?.filter.length === 0) {
			filters.subtab &&
				filters.subtab
					.find((page) => page.name === tab_name)
					?.filters.map((filter) => (obj[filter] = true));
		} else {
			filters && filters.filter.map((filter) => (obj[filter] = true));
		}
		props.setFilterActiveStatus(obj);
	};

	// const DUMMY_DATA = [
	// 	{
	// 		calls: [
	// 			{
	// 				cardName: "Average Patient Volume",
	// 				month: ["November 2021"],
	// 				value: ["173"],
	// 				unit: [""],
	// 				vsPrevMonth: "3.5",
	// 				topChart: {},
	// 				chartName: ["Total Patient Volume"],
	// 				xAxisLabel: [],
	// 				yAxisLabel: ["# of Patients"],
	// 				footer: "",
	// 				chartColor: ["#3B9FE7"],
	// 				tableLinks: [],
	// 				chartAccessor: [],
	// 				chartData: [
	// 					{
	// 						"Feb 21": {
	// 							"Series 1": 80,
	// 						},
	// 						"Mar 21": {
	// 							"Series 1": 115,
	// 						},
	// 						"Apr 21": {
	// 							"Series 1": 230,
	// 						},
	// 						"May 21": {
	// 							"Series 1": 65,
	// 						},
	// 						"Jun 21": {
	// 							"Series 1": 85,
	// 						},
	// 						"Jul 21": {
	// 							"Series 1": 125,
	// 						},
	// 						"Aug 21": {
	// 							"Series 1": 80,
	// 						},
	// 						"Sep 21": {
	// 							"Series 1": 80,
	// 						},
	// 						"Oct 21": {
	// 							"Series 1": 115,
	// 						},
	// 						"Nov 21": {
	// 							"Series 1": 65,
	// 						},
	// 					},
	// 				],
	// 			},
	// 			{
	// 				cardName: "Average Duration of Calls",
	// 				month: ["November 2021"],
	// 				value: ["7"],
	// 				unit: "mins",
	// 				vsPrevMonth: "3.5",
	// 				topChart: {},
	// 				chartName: ["Average Duration of Calls"],
	// 				xAxisLabel: [],
	// 				yAxisLabel: ["Duration of Call (minutes)"],
	// 				footer: "",
	// 				chartColor: ["#3B9FE7"],
	// 				tableLinks: [],
	// 				chartAccessor: [],
	// 				chartData: [
	// 					{
	// 						"Feb 21": {
	// 							"Series 1": 7,
	// 						},
	// 						"Mar 21": {
	// 							"Series 1": 11,
	// 						},
	// 						"Apr 21": {
	// 							"Series 1": 23,
	// 						},
	// 						"May 21": {
	// 							"Series 1": 6,
	// 						},
	// 						"Jun 21": {
	// 							"Series 1": 1,
	// 						},
	// 						"Jul 21": {
	// 							"Series 1": 14,
	// 						},
	// 						"Aug 21": {
	// 							"Series 1": 8,
	// 						},
	// 						"Sep 21": {
	// 							"# of Patients": 8,
	// 						},
	// 						"Oct 21": {
	// 							"Series 1": 11,
	// 						},
	// 						"Nov 21": {
	// 							"Series 1": 6,
	// 						},
	// 					},
	// 				],
	// 			},
	// 			{
	// 				cardName: ["Average Calls Attempted"],
	// 				month: ["November 2021"],
	// 				value: ["247"],
	// 				unit: "",
	// 				vsPrevMonth: "-4.7",
	// 				topChart: {},
	// 				chartName: [
	// 					"Total Calls Attempted",
	// 					"% Of Successful Calls",
	// 				],
	// 				xAxisLabel: [],
	// 				yAxisLabel: ["# of Calls", "% of Successful Calls"],
	// 				chartColor: ["#7232CF", "#E0C6FF"],
	// 				tableLinks: [],
	// 				chartAccessor: [],
	// 				footer: "",
	// 				chartData: [
	// 					{
	// 						"Feb 21": {
	// 							"Successful Calls": 40,
	// 							"Total Calls": 120,
	// 						},
	// 						"Mar 21": {
	// 							"Successful Calls": 90,
	// 							"Total Calls": 120,
	// 						},
	// 						"Apr 21": {
	// 							"Successful Calls": 55,
	// 							"Total Calls": 155,
	// 						},
	// 						"May 21": {
	// 							"Successful Calls": 25,
	// 							"Total Calls": 40,
	// 						},
	// 						"Jun 21": {
	// 							"Successful Calls": 85,
	// 							"Total Calls": 110,
	// 						},
	// 						"Jul 21": {
	// 							"Successful Calls": 40,
	// 							"Total Calls": 75,
	// 						},
	// 						"Aug 21": {
	// 							"Successful Calls": 85,
	// 							"Total Calls": 75,
	// 						},
	// 						"Sep 21": {
	// 							"Successful Calls": 45,
	// 							"Total Calls": 85,
	// 						},
	// 						"Oct 21": {
	// 							"Successful Calls": 145,
	// 							"Total Calls": 35,
	// 						},
	// 						"Nov 21": {
	// 							"Successful Calls": 35,
	// 							"Total Calls": 130,
	// 						},
	// 					},
	// 					{
	// 						"Feb 21": {
	// 							"Successful Calls": 45,
	// 							"Total Calls": 55,
	// 						},
	// 						"Mar 21": {
	// 							"Successful Calls": 30,
	// 							"Total Calls": 70,
	// 						},
	// 						"Apr 21": {
	// 							"Successful Calls": 30,
	// 							"Total Calls": 70,
	// 						},
	// 						"May 21": {
	// 							"Successful Calls": 75,
	// 							"Total Calls": 25,
	// 						},
	// 						"Jun 21": {
	// 							"Successful Calls": 15,
	// 							"Total Calls": 85,
	// 						},
	// 						"Jul 21": {
	// 							"Successful Calls": 30,
	// 							"Total Calls": 70,
	// 						},
	// 						"Aug 21": {
	// 							"Successful Calls": 60,
	// 							"Total Calls": 40,
	// 						},
	// 						"Sep 21": {
	// 							"Successful Calls": 90,
	// 							"Total Calls": 10,
	// 						},
	// 						"Oct 21": {
	// 							"Successful Calls": 30,
	// 							"Total Calls": 70,
	// 						},
	// 						"Nov 21": {
	// 							"Successful Calls": 35,
	// 							"Total Calls": 65,
	// 						},
	// 					},
	// 				],
	// 			},
	// 		],
	// 		reach: [
	// 			{
	// 				cardName: "New Patient Volume",
	// 				month: ["November 2021"],
	// 				value: ["173"],
	// 				unit: [""],
	// 				vsPrevMonth: "3.5",
	// 				topChart: {},
	// 				chartName: ["Total New Patient Volume"],
	// 				footer: "",
	// 				xAxisLabel: [],
	// 				yAxisLabel: ["# of Patients"],
	// 				chartColor: ["#3B9FE7"],
	// 				tableLinks: [],
	// 				chartAccessor: [],
	// 				chartData: [
	// 					{
	// 						"Feb 21": {
	// 							"Series 1": 80,
	// 						},
	// 						"Mar 21": {
	// 							"Series 1": 115,
	// 						},
	// 						"Apr 21": {
	// 							"Series 1": 230,
	// 						},
	// 						"May 21": {
	// 							"Series 1": 65,
	// 						},
	// 						"Jun 21": {
	// 							"Series 1": 85,
	// 						},
	// 						"Jul 21": {
	// 							"Series 1": 125,
	// 						},
	// 						"Aug 21": {
	// 							"Series 1": 80,
	// 						},
	// 						"Sep 21": {
	// 							"Series 1": 80,
	// 						},
	// 						"Oct 21": {
	// 							"Series 1": 115,
	// 						},
	// 						"Nov 21": {
	// 							"Series 1": 65,
	// 						},
	// 					},
	// 				],
	// 			},
	// 			{
	// 				cardName: "Call Volume for ",
	// 				month: ["November 2021"],
	// 				value: ["247"],
	// 				unit: [""],
	// 				vsPrevMonth: "3.5",
	// 				chartName: [
	// 					"Patient Call Volume",
	// 					"Call Reach",
	// 					"Call Volume Comparison",
	// 				],
	// 				topChart: {},
	// 				xAxisLabel: [],
	// 				yAxisLabel: ["# of Calls", "% of Patients", "Call Volume"],
	// 				chartColor: [
	// 					["#3B9FE7"],
	// 					["#300C64", "#7232CF", "#BB86FF", "#E8D9FF"],
	// 					[
	// 						"#3B9FE7",
	// 						"#2c78d4",
	// 						"#2557cc",
	// 						"#1a32ba",
	// 						"#1b11ab",
	// 						"#140c8a",
	// 					],
	// 				],
	// 				tableLinks: [],
	// 				chartAccessor: [],
	// 				chartData: [
	// 					{
	// 						"Feb 21": {
	// 							"Series 1": 7,
	// 						},
	// 						"Mar 21": {
	// 							"Series 1": 11,
	// 						},
	// 						"Apr 21": {
	// 							"Series 1": 23,
	// 						},
	// 						"May 21": {
	// 							"Series 1": 6,
	// 						},
	// 						"Jun 21": {
	// 							"Series 1": 1,
	// 						},
	// 						"Jul 21": {
	// 							"Series 1": 14,
	// 						},
	// 						"Aug 21": {
	// 							"Series 1": 8,
	// 						},
	// 						"Sep 21": {
	// 							"Series 1": 8,
	// 						},
	// 						"Oct 21": {
	// 							"Series 1": 11,
	// 						},
	// 						"Nov 21": {
	// 							"Series 1": 6,
	// 						},
	// 					},
	// 					{
	// 						"Feb 21": {
	// 							"0 Calls": 38,
	// 							"1 Call": 24,
	// 							"2 Calls": 16,
	// 							"3 Calls": 22,
	// 						},
	// 						"Mar 21": {
	// 							"0 Calls": 56,
	// 							"1 Call": 24,
	// 							"2 Calls": 15,
	// 							"3 Calls": 5,
	// 						},
	// 						"Apr 21": {
	// 							"0 Calls": 25,
	// 							"1 Call": 33,
	// 							"2 Calls": 20,
	// 							"3 Calls": 22,
	// 						},
	// 						"May 21": {
	// 							"0 Calls": 30,
	// 							"1 Call": 28,
	// 							"2 Calls": 18,
	// 							"3 Calls": 24,
	// 						},
	// 						"Jun 21": {
	// 							"0 Calls": 56,
	// 							"1 Call": 8,
	// 							"2 Calls": 20,
	// 							"3 Calls": 16,
	// 						},
	// 						"Jul 21": {
	// 							"0 Calls": 42,
	// 							"1 Call": 36,
	// 							"2 Calls": 16,
	// 							"3 Calls": 6,
	// 						},
	// 						"Aug 21": {
	// 							"0 Calls": 50,
	// 							"1 Call": 32,
	// 							"2 Calls": 10,
	// 							"3 Calls": 8,
	// 						},
	// 						"Sep 21": {
	// 							"0 Calls": 22,
	// 							"1 Call": 18,
	// 							"2 Calls": 40,
	// 							"3 Calls": 20,
	// 						},
	// 						"Oct 21": {
	// 							"0 Calls": 30,
	// 							"1 Call": 28,
	// 							"2 Calls": 18,
	// 							"3 Calls": 24,
	// 						},
	// 						"Nov 21": {
	// 							"0 Calls": 18,
	// 							"1 Call": 42,
	// 							"2 Calls": 26,
	// 							"3 Calls": 14,
	// 						},
	// 					},
	// 					{
	// 						"Feb 21": {
	// 							"0 Days": 40,
	// 							"30 Days": 90,
	// 							"60 Days": 70,
	// 							"90 Days": 62,
	// 							"180 Days": 30,
	// 							">180 Days": 50,
	// 						},
	// 						"Mar 21": {
	// 							"0 Days": 30,
	// 							"30 Days": 78,
	// 							"60 Days": 88,
	// 							"90 Days": 60,
	// 							"180 Days": 60,
	// 							">180 Days": 58,
	// 						},
	// 						"Apr 21": {
	// 							"0 Days": 22,
	// 							"30 Days": 72,
	// 							"60 Days": 56,
	// 							"90 Days": 68,
	// 							"180 Days": 53,
	// 							">180 Days": 62,
	// 						},
	// 						"May 21": {
	// 							"0 Days": 33,
	// 							"30 Days": 88,
	// 							"60 Days": 60,
	// 							"90 Days": 80,
	// 							"180 Days": 42,
	// 							">180 Days": 68,
	// 						},
	// 						"Jun 21": {
	// 							"0 Days": 41,
	// 							"30 Days": 87,
	// 							"60 Days": 46,
	// 							"90 Days": 76,
	// 							"180 Days": 68,
	// 							">180 Days": 70,
	// 						},
	// 						"Jul 21": {
	// 							"0 Days": 43,
	// 							"30 Days": 94,
	// 							"60 Days": 83,
	// 							"90 Days": 49,
	// 							"180 Days": 62,
	// 							">180 Days": 64,
	// 						},
	// 						"Aug 21": {
	// 							"0 Days": 29,
	// 							"30 Days": 78,
	// 							"60 Days": 36,
	// 							"90 Days": 80,
	// 							"180 Days": 45,
	// 							">180 Days": 72,
	// 						},
	// 						"Sep 21": {
	// 							"0 Days": 32,
	// 							"30 Days": 85,
	// 							"60 Days": 49,
	// 							"90 Days": 82,
	// 							"180 Days": 32,
	// 							">180 Days": 78,
	// 						},
	// 						"Oct 21": {
	// 							"0 Days": 31,
	// 							"30 Days": 82,
	// 							"60 Days": 41,
	// 							"90 Days": 84,
	// 							"180 Days": 38,
	// 							">180 Days": 80,
	// 						},
	// 						"Nov 21": {
	// 							"0 Days": 38,
	// 							"30 Days": 86,
	// 							"60 Days": 58,
	// 							"90 Days": 81,
	// 							"180 Days": 44,
	// 							">180 Days": 82,
	// 						},
	// 					},
	// 				],
	// 			},
	// 		],
	// 		agentActivity: [
	// 			{
	// 				cardName: "",
	// 				month: [""],
	// 				value: [""],
	// 				unit: [""],
	// 				vsPrevMonth: "",
	// 				chartName: [
	// 					"Agent Activity On Program",
	// 					"Agent Activity on Program - Table view",
	// 				],
	// 				topChart: {},
	// 				xAxisLabel: [],
	// 				yAxisLabel: ["# of Calls / Day"],
	// 				footer: "",
	// 				chartColor: ["#3B9FE7"],
	// 				tableLinks: [],
	// 				chartAccessor: [],
	// 				chartData: [
	// 					[
	// 						{ x: 1, y: 3 },
	// 						{ x: -2, y: 1 },
	// 						{ x: 3, y: 5 },
	// 						{ x: -4, y: -4 },
	// 						{ x: 5, y: 1 },
	// 						{ x: 3, y: 6 },
	// 						{ x: 7, y: -4 },
	// 						{ x: 5, y: -4 },
	// 						{ x: -2, y: 2 },
	// 						{ x: 8, y: -1 },
	// 						{ x: -6, y: -3 },
	// 						{ x: 4, y: -3 },
	// 						{ x: 2, y: 2 },
	// 						{ x: -4, y: 1 },
	// 						{ x: -3, y: -5 },
	// 						{ x: 6, y: 4 },
	// 						{ x: 5, y: 2 },
	// 						{ x: -5, y: -4 },
	// 						{ x: 5, y: 3 },
	// 					],
	// 					[
	// 						[
	// 							{
	// 								"Agent ID": "00001",
	// 								"Calls per Day": 5,
	// 								"Inbound Calls": 8,
	// 								"Outbound Calls": 3,
	// 								"Avg Call Duration (min)": 8,
	// 							},
	// 							{
	// 								"Agent ID": "00002",
	// 								"Calls per Day": 5,
	// 								"Inbound Calls": 8,
	// 								"Outbound Calls": 3,
	// 								"Avg Call Duration (min)": 8,
	// 							},
	// 							{
	// 								"Agent ID": "00003",
	// 								"Calls per Day": 5,
	// 								"Inbound Calls": 8,
	// 								"Outbound Calls": 3,
	// 								"Avg Call Duration (min)": 8,
	// 							},
	// 							{
	// 								"Agent ID": "00004",
	// 								"Calls per Day": 5,
	// 								"Inbound Calls": 8,
	// 								"Outbound Calls": 3,
	// 								"Avg Call Duration (min)": 8,
	// 							},
	// 							{
	// 								"Agent ID": "00005",
	// 								"Calls per Day": 5,
	// 								"Inbound Calls": 8,
	// 								"Outbound Calls": 3,
	// 								"Avg Call Duration (min)": 8,
	// 							},
	// 						],
	// 					],
	// 				],
	// 			},
	// 			{
	// 				cardName: "All Agents",
	// 				kpi: ["Total Calls", "Average Duration of Calls"],
	// 				month: ["November 2021", "November 2021"],
	// 				value: ["2377", "16"],
	// 				unit: ["", "mins"],
	// 				vsPrevMonth: "",
	// 				topChart: {
	// 					"Outbound Calls": 58,
	// 					"Inbound Calls": 42,
	// 				},
	// 				chartName: [
	// 					"Trends Of Total Calls",
	// 					"Avg. duration of Calls",
	// 				],
	// 				yAxisLabel: ["# of Calls", "Avg. Duration of Calls"],
	// 				xAxisLabel: [],
	// 				footer: "",
	// 				chartColor: ["#7232CF", "#E0C6FF"],
	// 				tableLinks: [],
	// 				chartAccessor: [],
	// 				chartData: [
	// 					{
	// 						"Feb 21": {
	// 							"Outbound Calls": 30,
	// 							"Inbound Calls": 90,
	// 						},
	// 						"Mar 21": {
	// 							"Outbound Calls": 80,
	// 							"Inbound Calls": 150,
	// 						},
	// 						"Apr 21": {
	// 							"Outbound Calls": 50,
	// 							"Inbound Calls": 170,
	// 						},
	// 						"May 21": {
	// 							"Outbound Calls": 10,
	// 							"Inbound Calls": 40,
	// 						},
	// 						"Jun 21": {
	// 							"Outbound Calls": 65,
	// 							"Inbound Calls": 145,
	// 						},
	// 						"Jul 21": {
	// 							"Outbound Calls": 25,
	// 							"Inbound Calls": 85,
	// 						},
	// 						"Aug 21": {
	// 							"Outbound Calls": 140,
	// 							"Inbound Calls": 40,
	// 						},
	// 						"Sep 21": {
	// 							"Outbound Calls": 56,
	// 							"Inbound Calls": 190,
	// 						},
	// 						"Oct 21": {
	// 							"Outbound Calls": 68,
	// 							"Inbound Calls": 200,
	// 						},
	// 						"Nov 21": {
	// 							"Outbound Calls": 25,
	// 							"Inbound Calls": 150,
	// 						},
	// 					},
	// 					{
	// 						"Feb 21": {
	// 							"Outbound Calls": 60,
	// 							"Inbound Calls": 90,
	// 						},
	// 						"Mar 21": {
	// 							"Outbound Calls": 80,
	// 							"Inbound Calls": 120,
	// 						},
	// 						"Apr 21": {
	// 							"Outbound Calls": 50,
	// 							"Inbound Calls": 170,
	// 						},
	// 						"May 21": {
	// 							"Outbound Calls": 110,
	// 							"Inbound Calls": 10,
	// 						},
	// 						"Jun 21": {
	// 							"Outbound Calls": 75,
	// 							"Inbound Calls": 125,
	// 						},
	// 						"Jul 21": {
	// 							"Outbound Calls": 125,
	// 							"Inbound Calls": 45,
	// 						},
	// 						"Aug 21": {
	// 							"Outbound Calls": 10,
	// 							"Inbound Calls": 140,
	// 						},
	// 						"Sep 21": {
	// 							"Outbound Calls": 156,
	// 							"Inbound Calls": 90,
	// 						},
	// 						"Oct 21": {
	// 							"Outbound Calls": 168,
	// 							"Inbound Calls": 20,
	// 						},
	// 						"Nov 21": {
	// 							"Outbound Calls": 85,
	// 							"Inbound Calls": 50,
	// 						},
	// 					},
	// 				],
	// 			},
	// 		],
	// 	},
	// ];

	// React.useEffect(() => {
	// 	const obj: FilterActiveInterface = props.filterActiveStatus;
	// 	for (let key in obj) {
	// 		obj[key] = false;
	// 	}
	// 	var filters = pageWiseFilters.find((page) => page.tab === "Program Overview");
	// 	if(filters?.filter.length === 0) {
	// 		filters.subtab?.filters.map((filter) => (obj[filter] = true));
	// 	}
	// 	props.setFilterActiveStatus(obj);
	// }, []);
	return (
		<>
			{/* {console.log("Filtersss", props.filters)} */}
			{!loading &&
			POData[
				(subsubtab?.split("_").join("")[0].toLowerCase() || "") +
					subsubtab?.split("_").join("").slice(1) || "calls"
			] &&
			POData[
				(subsubtab?.split("_").join("")[0].toLowerCase() || "") +
					subsubtab?.split("_").join("").slice(1) || "calls"
			].length > 0 ? (
				<>
					{subTab === "Calls" && POData.calls.length > 0 ? (
						<ProgramOverviewCalls data={POData.calls} />
					) : subTab === "Reach" && POData.reach.length > 0 ? (
						<ProgramOverviewReach
							data={POData.reach}
							callVolumeRefresh={callVolumeRefresh}
						/>
					) : subTab === "Agent_Activity" &&
					  POData.agentActivity.length > 0 ? (
						<POAgentActivity data={POData.agentActivity} />
					) : (
						<h1>Program Overview : {subsubtab}</h1>
					)}
				</>
			) : (
				<>
					{subsubtab === "Calls" ? (
						<div className="grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-6">
							<SkeletonCard />
							<SkeletonCard />
							<SkeletonCard />
						</div>
					) : subsubtab === "Reach" ? (
						<div className="grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-1 gap-6">
							<SkeletonCard />
							<div className="col-span-2">
								<SkeletonCard />
							</div>
						</div>
					) : (
						<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-6">
							<SkeletonCard />
							<SkeletonCard />
						</div>
					)}
				</>
			)}
		</>
	);
}

import React, { useState, useEffect } from "react";
import { Card, message } from "antd";
import Markdown from "react-markdown";
import gfm from "remark-gfm";
import breaks from "remark-breaks";
import { Collapse, Tooltip } from "antd";
import { LikeOutlined, DislikeOutlined, PlusCircleFilled, LikeFilled, DislikeFilled } from "@ant-design/icons";
import "./SummaryCard.css";
import SelectStoryModalContent from "../SelectStoryModal";
import * as StoriesServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/stories";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";
import { transcriptDataInter } from "../../Pages/SearchEngine/Search";
import AppContext from "../../store";
import { useSelector, useDispatch } from "react-redux";
import { selectStore, setSearchLikeStatus, setSearchDislikeStatus } from "../../state/slice/appSlice";
import "../Markdown/style.css";

// import { icons, userTypeEnum } from "../../GlobalThemes";
// import AppContext from "../../store";
// import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
// import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// import { dark } from "react-syntax-highlighter/dist/esm/styles/prism";
// import { Modal } from "antd";
// import { SummaryCardDummyData } from "../Pages/SearchEngine/data";

export default function SummaryCard(props: {
	storyId?: any;
	dataForNegativeSampling?: any;
	data: any;
	isloaded: boolean;
	expanded?: boolean;
	showTags?: boolean;
	hideProgressBar?: boolean;
	fetchedStoryData?: any;
	fetchStoryData?: any;
	addToStoryApi?: (transcriptData: any, id: any[]) => Promise<boolean>;
	changePinnedStories?: (stories: any) => void;
	addNewStory?: (addData: any) => void;
	pinnedStoriesData?: any;
	transcriptData?: any;
	storyDialogues?: any;
	searchString?: any;
	setSearchString?: any;
	keywords?: string[];
	apiResponseData?: transcriptDataInter | null;
}) {
	const context = React.useContext(AppContext);
	const dispatch = useDispatch();
	const store = useSelector(selectStore);

	const [showMore, setShowMore] = useState<boolean>(true);
	const [isLiked, setIsLiked] = useState<boolean>(store.searchTab.searchLikeStatus);
	const [isDisliked, setIsDisliked] = useState<boolean>(store.searchTab.searchDislikeStatus);
	const [isModalVisible, setIsModalVisible] = React.useState<boolean>(false);
	const [fetchedStoryData, setFetchedStoryData] = useState<any>([]);

	async function fetchStoryData() {
		try {
			setIsModalVisible(true);
			const data: any = await StoriesServiceHelper.getAllStories();

			if (data) {
				setFetchedStoryData(data);
			}
		} catch {
			console.error("Some error occured while fetching data.");
		}
	}

	const TempData = `1. First item\n2. Second item\n3. Third item\n`;

	// Function to handle the "Like/ DisLike" button click
	const handleFeedbackClick = async (action: string) => {
		try {
			const summaryPayload: any = {
				searchQuery: props.searchString ?? "",
				isLike: action === "like" ? true : false,
				searchJson: props.apiResponseData,
			};

			const data: any = await SearchServiceHelper.saveSearchFeedback(summaryPayload);

			if (data) {
				// console.log("Feedback response saved successfully.");
				if (action === "like") {
					setIsLiked(true);
					setIsDisliked(false);
					dispatch(setSearchLikeStatus(true));
					dispatch(setSearchDislikeStatus(false));
				} else {
					setIsLiked(false);
					setIsDisliked(true);
					dispatch(setSearchLikeStatus(false));
					dispatch(setSearchDislikeStatus(true));
				}
			} else {
				message.error("Error in saving feedback response");
				console.log("Error in saving feedback response.");
			}
		} catch (error) {
			console.log("Error in saving feedback response: ", error);
		}
	};

	return (
		<>
			{props.isloaded && props.data !== "" ? (
				<Collapse defaultActiveKey={["1"]} expandIconPosition="right" ghost>
					<Collapse.Panel style={{ paddingBottom: "0px" }} header={<span className="w-11/12 pb-2 font-semibold text-base text-text-black"></span>} key="1">
						<Card bordered={false} className={""} style={{ paddingTop: "0px Important" }}>
							<div className="flex" style={{ paddingTop: "0px Important", justifyContent: "space-between", alignItems: "center" }}>
								<SelectStoryModalContent
									data={fetchedStoryData}
									gptSummaryData={props.data}
									fetchStoryData={fetchStoryData}
									responseData={props.apiResponseData}
									searchQuery={props.searchString}
									isModalOpen={isModalVisible}
									setIsModalOpen={setIsModalVisible}
									tagName={"Collection"}
								/>
								<span className="w-11/12 font-semibold text-base text-text-teal">{props.searchString}</span>
								{context.envId === 1 ? (
									<Tooltip
										title={props.data && props.data.trim() !== "" ? "" : "Data is currently unavailable for adding to the story. Please check back later."}
										placement="top"
									>
										<div
											className="flex items-center w-32 mr-5"
											style={{ cursor: "pointer" }}
											onClick={() => {
												if (props.data && props.data.trim() !== "") fetchStoryData();
											}}
										>
											<PlusCircleFilled className="text-text-teal mr-2" style={{ fontSize: "large" }} />
											<span className="font-semibold text-text-teal" style={{ minWidth: "110px" }}>
												Add to Collection
											</span>
										</div>
									</Tooltip>
								) : (
									<></>
								)}
								{isLiked ? (
									<LikeFilled
										className="w-12"
										style={{
											cursor: "pointer",
											fontSize: "large",
											color: "teal",
										}}
										onClick={() => handleFeedbackClick("like")}
									/>
								) : (
									<LikeOutlined
										className="w-12"
										style={{
											cursor: "pointer",
											fontSize: "large",
										}}
										onClick={() => handleFeedbackClick("like")}
									/>
								)}
								{isDisliked ? (
									<DislikeFilled
										className="w-12"
										style={{
											cursor: "pointer",
											fontSize: "large",
											color: "teal",
										}}
										onClick={() => handleFeedbackClick("disLike")}
									/>
								) : (
									<DislikeOutlined
										className="w-12"
										style={{
											cursor: "pointer",
											fontSize: "large",
										}}
										onClick={() => handleFeedbackClick("disLike")}
									/>
								)}
							</div>
							<div className="text-base pb-2">
								{!showMore && props.data.length > 840 ? (
									<Markdown remarkPlugins={[breaks, gfm]}>{props.data?.slice(0, 840)}</Markdown>
								) : (
									<Markdown remarkPlugins={[breaks, gfm]}>{props.data}</Markdown>
								)}
								<div
									className="text-text-teal font-normal cursor-pointer w-max pt-2"
									onClick={() => {
										setShowMore(!showMore);
									}}
								>
									{showMore ? <>Show less</> : <>Show more</>}
								</div>
							</div>
						</Card>
					</Collapse.Panel>
				</Collapse>
			) : (
				<></>
			)}
		</>
	);
}

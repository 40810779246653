import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FilterActiveInterface, FilterInterface } from ".";
import SkeletonCard from "../../Components/SkeletonCard";
import { fetchedFilters, pageWiseFilters } from "./data";
import { CallParticipation } from "./Engagement/CallParticipation";
import { TopicEngagement } from "./Engagement/TopicEngagement";
import { getEngagementData } from "./ServiceHelpers/Engagement";

const DUMMY_DATA = [
	{
		callParticipation: [
			{
				cardName: "Average Call Participation",
				month: [],
				value: [],
				unit: [],
				vsPrevMonth: "",
				topChart: {
					"Time Spent by Agent Speaking on Calls": 58,
					"Time Spent by Patients Speaking on Calls": 42,
				},
				chartName: ["Call Participation Trend"],
				xAxisLabel: [],
				yAxisLabel: ["Avg % of Call"],
				chartColor: ["#3B9FE7", "#FECA98"],
				chartAccessor: [],
				footer: "",
				chartData: [
					{
						"Feb 21": {
							Agent: 30,
							Patient: 70,
						},
						"Mar 21": {
							Agent: 60,
							Patient: 40,
						},
						"Apr 21": {
							Agent: 42,
							Patient: 58,
						},
						"May 21": {
							Agent: 42,
							Patient: 58,
						},
						"Jun 21": {
							Agent: 41,
							Patient: 59,
						},
						"Jul 21": {
							Agent: 48,
							Patient: 52,
						},
						"Aug 21": {
							Agent: 50,
							Patient: 50,
						},
						"Sep 21": {
							Agent: 48,
							Patient: 52,
						},
						"Oct 21": {
							Agent: 42,
							Patient: 58,
						},
						"Nov 21": {
							Agent: 36,
							Patient: 64,
						},
					},
				],
			},
			{
				cardName: "Average % of Time Spent in Asking Questions",
				month: ["November 2021"],
				value: ["25%"],
				unit: [],
				vsPrevMonth: "",
				chartName: ["Call Participation Trend"],
				xAxisLabel: [],
				yAxisLabel: ["Avg % of Call"],
				chartColor: ["#3B9FE7"],
				chartAccessor: [],
				footer: "",
				chartData: [
					{
						"Feb 21": {
							"% of Time Spent by Agent Asking Question": 15,
						},
						"Mar 21": {
							"% of Time Spent by Agent Asking Question": 19,
						},
						"Apr 21": {
							"% of Time Spent by Agent Asking Question": 13,
						},
						"May 21": {
							"% of Time Spent by Agent Asking Question": 38,
						},
						"Jun 21": {
							"% of Time Spent by Agent Asking Question": 16,
						},
						"Jul 21": {
							"% of Time Spent by Agent Asking Question": 17,
						},
						"Aug 21": {
							"% of Time Spent by Agent Asking Question": 26,
						},
						"Sep 21": {
							"% of Time Spent by Agent Asking Question": 39,
						},
						"Oct 21": {
							"% of Time Spent by Agent Asking Question": 42,
						},
						"Nov 21": {
							"% of Time Spent by Agent Asking Question": 36,
						},
					},
				],
			},
		],
		engagementByTopics: [
			{
				cardName: "",
				month: [],
				value: [],
				unit: [],
				vsPrevMonth: "",
				chartName: ["Topics Discussed"],
				xAxisLabel: ["% of Times Topic Discussed"],
				yAxisLabel: ["Avg. Time Spent on a Topic (mins)"],
				footer: "Bubble Size - # of Patients Discussing the Topic",
				chartColor: ["#3B9FE7"],
				chartAccessor: [],
				chartData: [
					{ x: 21, y: 3, z: 3 },
					{ x: 22, y: 8, z: 6 },
					{ x: 23, y: 5, z: 2 },
					{ x: 24, y: 10, z: 1 },
					{ x: 25, y: 6, z: 8 },
					{ x: 26, y: 15, z: 4 },
					{ x: 27, y: 2, z: 9 },
					{ x: 28, y: 12, z: 2 },
					{ x: 29, y: 7, z: 2 },
					{ x: 30, y: 16, z: 5 },
					{ x: 31, y: 11, z: 7 },
					{ x: 32, y: 13, z: 4 },
					{ x: 33, y: 9, z: 8 },
					{ x: 34, y: 6, z: 10 },
					{ x: 35, y: 15, z: 2 },
					{ x: 36, y: 5, z: 9 },
					{ x: 37, y: 4, z: 10 },
					{ x: 38, y: 10, z: 3 },
				],
			},
			{
				cardName: "",
				month: [],
				value: [],
				unit: [],
				vsPrevMonth: "",
				chartName: ["Topics Discussed Agent Vs Patient"],
				xAxisLabel: ["% of Times Discussed by Agent"],
				yAxisLabel: ["% of Times Discussed by Patient"],
				footer: "Bubble Size - Average time Spent on Topic (minutes)",
				chartColor: ["#3B9FE7"],
				chartAccessor: [],
				chartData: [
					{ x: 21, y: 3, z: 3 },
					{ x: 22, y: 8, z: 6 },
					{ x: 23, y: 5, z: 2 },
					{ x: 24, y: 10, z: 1 },
					{ x: 25, y: 6, z: 8 },
					{ x: 26, y: 15, z: 4 },
					{ x: 27, y: 3, z: 9 },
					{ x: 28, y: 12, z: 2 },
					{ x: 29, y: 7, z: 2 },
					{ x: 30, y: 9, z: 5 },
					{ x: 31, y: 11, z: 7 },
					{ x: 32, y: 13, z: 4 },
					{ x: 33, y: 9, z: 8 },
					{ x: 34, y: 6, z: 10 },
					{ x: 35, y: 15, z: 2 },
					{ x: 36, y: 5, z: 9 },
					{ x: 37, y: 4, z: 10 },
					{ x: 38, y: 10, z: 3 },
				],
			},
			{
				cardName: "",
				month: [],
				value: [],
				unit: [],
				vsPrevMonth: "",
				chartName: ["List of Topics Discussed"],
				yAxisLabel: [],
				footer: "",
				chartColor: ["#3B9FE7"],
				chartAccessor: [{ id: "Last 10 Months Trend", type: "line" }],
				chartData: [
					{
						"Topic Name": "Topic 1",
						"% of Topics Discussed": 4,
						"Average Time Spent on Topic (mins)": 3,
						"# of Patients Discussing the Topic": 9,
						"Last 10 Months Trend": [
							{
								"Feb 21": {
									"Series 1": 211,
								},
								"Mar 21": {
									"Series 1": 231,
								},
								"Apr 21": {
									"Series 1": 202,
								},
								"May 21": {
									"Series 1": 221,
								},
								"Jun 21": {
									"Series 1": 227,
								},
								"Jul 21": {
									"Series 1": 214,
								},
								"Aug 21": {
									"Series 1": 224,
								},
								"Sep 21": {
									"Series 1": 232,
								},
								"Oct 21": {
									"Series 1": 229,
								},
								"Nov 21": {
									"Series 1": 232,
								},
							},
						],
					},
					{
						"Topic Name": "Topic 1",
						"% of Topics Discussed": 4,
						"Average Time Spent on Topic (mins)": 3,
						"# of Patients Discussing the Topic": 9,
						"Last 10 Months Trend": [
							{
								"Feb 21": {
									"Series 1": 221,
								},
								"Mar 21": {
									"Series 1": 202,
								},
								"Apr 21": {
									"Series 1": 213,
								},
								"May 21": {
									"Series 1": 216,
								},
								"Jun 21": {
									"Series 1": 226,
								},
								"Jul 21": {
									"Series 1": 213,
								},
								"Aug 21": {
									"Series 1": 206,
								},
								"Sep 21": {
									"Series 1": 219,
								},
								"Oct 21": {
									"Series 1": 220,
								},
								"Nov 21": {
									"Series 1": 232,
								},
							},
						],
					},
					{
						"Topic Name": "Topic 1",
						"% of Topics Discussed": 4,
						"Average Time Spent on Topic (mins)": 3,
						"# of Patients Discussing the Topic": 9,
						"Last 10 Months Trend": [
							{
								"Feb 21": {
									"Series 1": 200,
								},
								"Mar 21": {
									"Series 1": 231,
								},
								"Apr 21": {
									"Series 1": 210,
								},
								"May 21": {
									"Series 1": 205,
								},
								"Jun 21": {
									"Series 1": 235,
								},
								"Jul 21": {
									"Series 1": 212,
								},
								"Aug 21": {
									"Series 1": 204,
								},
								"Sep 21": {
									"Series 1": 216,
								},
								"Oct 21": {
									"Series 1": 212,
								},
								"Nov 21": {
									"Series 1": 220,
								},
							},
						],
					},
				],
			},
		],
	},
];

/**
 * This page is used to navigate between Call Participation and Engagement by Topics tabs
 *
 *  @param filterActiveStatus - Props used to pass the active filters as true
 *  @param setFilterActiveStatus - Function to set the status of active filters as true
 *  @param filters - Props used to pass the filters with values
 *
 * @returns the navigation to navigate between different subtabs
 */

export default function Engagement(props: {
	filterActiveStatus: FilterActiveInterface;
	setFilterActiveStatus: React.Dispatch<
		React.SetStateAction<FilterActiveInterface>
	>;
	filters: FilterInterface;
}) {
	const { subsubtab } = useParams();
	const [engagementData, setEngagementData] = React.useState<any>({});
	const [loading, setLoading] = React.useState<boolean>(true);
	const [subTab, setSubTab] = React.useState("");

	let navigate = useNavigate();
	// var filters = {
	// 	Timeframe_Display: undefined,
	// 	Start_Date: "",
	// 	End_Date: "",
	// 	Call_Type: undefined,
	// 	Therapy_Status: undefined,
	// 	Call_Intent: undefined,
	// 	Adherence: undefined,
	// };
	React.useEffect(() => {
		if (subsubtab === undefined) navigate("Call_Participation");
	});

	React.useEffect(() => {
		addSubTabFilters(subsubtab || "");
		fetchEngagementData();
	}, [props.filters, subsubtab]);

	function findId(filter: string, value: any) {
		let grpIdx: number = 0;
		let idx: number = 0;
		fetchedFilters.map((f) => {
			f.optionsObject.map((group) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx = group.id;
						grpIdx = group.id;
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (
							Array.isArray(value) &&
							value.find((f) => f === group.name)
						) {
							grpIdx = group.id;
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find(
									(sg) => sg.name === v
								);
								if (ob) {
									grpIdx = group.id;
									idx = ob.id;
								}

								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	}

	const fetchEngagementData = async () => {
		setLoading(true);
		var poFilters: FilterInterface = Object.assign({}, props.filters);
		poFilters.Call_Type = findId(
			"Call_Type",
			props.filters.Call_Type
		).grpIdx;
		poFilters.Therapy_Status = findId(
			"Therapy_Status",
			props.filters.Therapy_Status
		).grpIdx;
		poFilters.Call_Intenty = findId(
			"Call_Intent",
			props.filters.Call_Intent
		).grpIdx;
		poFilters.Adherence = findId(
			"Adherence",
			props.filters.Adherence
		).grpIdx;
		const data: any = await getEngagementData(
			subsubtab || "",
			poFilters,
			props.filterActiveStatus
		);

		if (data) {
			setEngagementData(data);
			setSubTab(subsubtab || "");
			setLoading(false);
		}
	};

	const addSubTabFilters = (tab_name: string) => {
		const obj: FilterActiveInterface = props.filterActiveStatus;
		for (let key in obj) {
			obj[key] = false;
		}
		var filters = pageWiseFilters.find((page) => page.tab === "Patient Engagement");
		if (filters?.filter.length === 0) {
			filters.subtab &&
				filters.subtab
					.find((page) => page.name === tab_name)
					?.filters.map((filter) => (obj[filter] = true));
		} else {
			filters && filters.filter.map((filter) => (obj[filter] = true));
		}
		props.setFilterActiveStatus(obj);
	};

	return (
		<>
			{!loading ? (
				subTab === "Call_Participation" &&
				engagementData.callParticipation.length > 0 ? (
					<CallParticipation
						data={engagementData.callParticipation}
					/>
				) : subTab === "Engagement_by_Topics" &&
				  engagementData.engagementByTopics.length > 0 ? (
					<TopicEngagement data={engagementData.engagementByTopics} />
				) : (
					<h1>Patient Engagement : {subTab}</h1>
				)
			) : (
				<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-6">
					<SkeletonCard />
					<SkeletonCard />
				</div>
			)}
		</>
	);
}

import { POCard } from "../../../Components/ReportCards/POCard";
import React, { useState, useEffect } from "react";
import * as ReportTypesComponentInterface from "../../../libraries/report-types/charts/interfaces/main";
import ReportTypes from "../../../libraries/report-types";
import { NumberData } from "../../../Components/ReportCards/CommonCardComponents/NumberData";
import { PieChartLegends } from "../../../Components/ReportCards/CommonCardComponents/PieChartLegends";

import { transformBarChartData, transformTopChartData } from "../Processing";
import { ArrowComparison } from "../../../Components/ReportCards/CommonCardComponents/ArrowComparison";

/**
 * This page is used to render the average call participation card
 *
 * @param data - This is the data props of the card
 *
 * @returns the average call participation and average time spent in asking questions cards
 */

export function CallParticipation(props: { data: any }) {
	var data = [
		{
			header: props.data[0].cardName,
			iconName: "Report Call Participation",
			cardTopData: (
				<div className="flex">
					<div className="flex w-36">
						<ReportTypes
							hideShadow
							hideRibbon
							minHeight="30px"
							height="7rem"
							// height="170px"
							bodyPadding="0px 20px"
							title={{ title: "xx" }}
							chartType={
								ReportTypesComponentInterface.chartType.pie
							}
							// series={series4}
							// data={chartData}
							{...transformTopChartData(
								props.data[0].topChart,
								props.data[0].chartColor,
								true
							)}
							chartConfig={{}}
							style={{
								labelTextColor: "white",
								margin: {
									top: 10,
									right: 0,
									bottom: 10,
									left: 0,
								},
								// labelTextColor: "black",
							}}
							tooltip={{
								isInteractive: true,
								valueFormat: (value: any) => {
									return `${value}%`;
								},
							}}
						/>
					</div>
					<PieChartLegends
						legendData={
							transformTopChartData(
								props.data[0].topChart,
								props.data[0].chartColor,
								true
							).series
						}
					/>
				</div>
			),

			chartElement: [
				<ReportTypes
					header=""
					hideShadow
					hideRibbon
					minHeight="30px"
					height="40vh"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.bar}
					{...transformBarChartData(
						props.data[0].chartData,
						props.data[0].chartColor
					)}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: props.data[0].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "stack_percent",
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 20,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.7, groupGap: 0 },
						},
					}}
					style={{
						margin: {
							top: 30,
							right: 10,
							bottom: 55,
							left: 48,
						},
					}}
				/>,
			],
		},
		{
			header: props.data[1].cardName,
			iconName: "Report Question",
			cardTopData: (
				<div className="flex items-end mx-7 my-4">
					<NumberData
						dataNumber={props.data[1].value}
						dataName={props.data[1].month}
						suffixElement={props.data[1].unit}
					/>
					<ArrowComparison
						dataNumber={props.data[1].vsPrevMonth}
						dataName={
							props.data[0].timeframeDisplay === "Quarter"
								? "vs Prev. Quarter"
								: "vs Prev. Month"
						}
					/>
				</div>
			),
			chartElement: [
				<ReportTypes
					header=""
					hideShadow
					hideRibbon
					minHeight="30px"
					height="44vh"
					bodyPadding="0px 20px"
					title={{ title: "xx" }}
					chartType={ReportTypesComponentInterface.chartType.bar}
					{...transformBarChartData(
						props.data[1].chartData,
						props.data[1].chartColor
					)}
					axis={{
						primaryX: {
							dim: 0,
							tickValues: 5,
							tickRotation: -45,
							legend: "",
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: 22,
						},
						primaryY: {
							tickSize: 0,
							tickValues: 5,
							tickRotation: 0,
							legend: props.data[1].yAxisLabel[0],
							legendPosition:
								ReportTypesComponentInterface.axisLegendPosition
									.middle,
							legendOffset: -40,
							// maxValue: 100,
						},
					}}
					tooltip={{
						isInteractive: true,
						format: "unmet",
					}}
					chartConfig={{
						cartesian: {
							line: {
								pointSize: 20,
							},
							// groupMode: groupMode.grouped,
							grid: {
								enableGridX: false,
								enableGridY: true,
								gridYValues: 5,
								gridXValues: 5,
							},
							gaps: { barGap: 0.6, groupGap: 0 },
						},
					}}
					style={{
						margin: {
							top: 45,
							right: 10,
							bottom: 65,
							left: 48,
						},
					}}
				/>,
			],
		},
	];

	return (
		<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-6">
			{data.map((card) => (
				<POCard {...card} />
			))}
		</div>
	);
}

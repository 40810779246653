import { searchQuery } from "../Search";
import * as ExploreService from "./../../../services/explore";

interface ClustersData {
	experimentId?: number;
	clusterId?: number;
	startDate?: string;
	endDate?: string;
	speaker?: string;
	topic?: string;
	totStart?: string;
	totEnd?: string;
	callTypeId?: string;
	therapyStatusId?: string;
	sentiment_id?: string;
	emotion_id?: string[];
}

// export const getExperiments: () => Promise<unknown> = async () => {
// 	const data = await ExploreService.getExperiments();
// 	if (data === null || data === undefined) return null;
// 	return data;
// };

export const getExploreCardsData: (
	experimentId: any,
	clusterId: any,
	startDate?: any,
	endDate?: any,
	speaker?: any,
	topic?: any,
	totStart?: any,
	totEnd?: any,
	callTypeId?: any,
	therapyStatusId?: any,
	sentiment_id?: any,
	emotion_id?: any
) => Promise<unknown> = async (
	experimentId?: any,
	clusterId?: any,
	startDate?: any,
	endDate?: any,
	speaker?: any,
	topic?: any,
	totStart?: any,
	totEnd?: any,
	callTypeId?: any,
	therapyStatusId?: any,
	sentiment_id?: any,
	emotion_id?: any
) => {
	const data = await ExploreService.getExploreCards(
		experimentId,
		clusterId,
		startDate,
		endDate,
		speaker,
		topic,
		totStart,
		totEnd,
		callTypeId,
		therapyStatusId,
		sentiment_id,
		emotion_id
	);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const changeCardNameData: (
	clusterId: any,
	clusterName: any
) => Promise<unknown> = async (clusterId: any, clusterName: any) => {
	const data = await ExploreService.changeCardName(clusterId, clusterName);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getClusterDetailsData: (
	clusterInfo: ClustersData
) => Promise<unknown> = async (clusterInfo) => {
	const data = await ExploreService.getClusterDetails(clusterInfo);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getNodeJsonsExplore: (s3path: string) => Promise<unknown> = async (
	s3path: string
) => {
	const data = await ExploreService.getNodeJsons(s3path);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

// export const getAllClusters: (filters: filterValues) => Promise<unknown> = async (filters: filterValues) => {
//   const data = await NodeGraph.getAllClusters(filters);
//   // console.log("api", data);
//   if (data === null || data === undefined) return null;
//   return data;
// };
// export const editClusterName: (editData: { clusterId: number; clusterName: string }) => Promise<unknown> = async (editData: { clusterId: number; clusterName: string }) => {
//   const data = await NodeGraph.editClusterName(editData);
//   // console.log("api", data);
//   if (data === null || data === undefined) return null;
//   return data;
// };
// export const getClusterDetails: (filters: filterValues) => Promise<unknown> = async (filters: filterValues) => {
//   const data = await NodeGraph.getClusterDetails(filters);
//   // console.log("api", data);
//   if (data === null || data === undefined) return null;
//   return data;
// };

import * as PatientList from "./../../../services/patientJourney";

export const getPatientListData: () => Promise<unknown> = async () => {
  const data = await PatientList.getPatientList();
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getPatientDetailsData: (patientId: number) => Promise<unknown> = async (patientId: number) => {
  const data = await PatientList.getPatientDetails(patientId);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

export const getCallTranscriptDetailsData: (callId: string) => Promise<unknown> = async (callId: string) => {
  const data = await PatientList.getCallTranscriptDetails(callId);
  // console.log("api", data);
  if (data === null || data === undefined) return null;
  return data;
};

import React from "react";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";
import DeleteAlert from "../../../Components/DeleteAlert";
import SubHeaderComponent from "../../../Components/SubHeaderComponent";
import SubNavigation from "../../../Components/SubNavigation";
import { icons } from "../../../GlobalThemes";
import AnnotatedSelection from "../OldStories/AnnotatedSelections";
import AnnotatedSelectionLoading from "../OldStories/AnnotatedSelections/loading";
import FindSimilarConversations from "../OldStories/FindSimilarConversations";
import * as AnnotatedSelectionsHelper from "../ServiceHelpers/annotatedSelections";
import * as OldStoriesServiceHelper from "../ServiceHelpers/oldStories";
import * as Messages from "../../../Components/Messages";
import AppContext from "../../../store";
// import StoriesSearch from "../Stories/Search/newIndex";
// import moment from "moment";
// import { publishLimit } from "../data";
// import { useLocation } from "react-router-dom";
// import { ExportToExcel, exportDataInterface } from "../ExportToExcel";
// import { Modal } from "antd";

/**
 * Renders the extra SubHeader Components inside the SubHeader.
 *
 * @param id - Id of the Story inside which the Subheader is getting rendered.
 * @param handleDelete - Callback function for handling deletion of story.
 * @param handlePublish - Callback function for handling Publishing of a story.
 * @param numberOfHiglightedTranscripts - Number of Transcripts Highlighted.
 * @param status - Status of the Story - In Draft, Publishing or Published.
 *
 * @returns Stories View.
 */
function SubHeaderExtra(props: {
	id: number;
	handleDelete: (id?: any) => void;
	createdBy: string;
	// status: string;
	// numberOfHiglightedTranscripts: number;
	// handlePublish: () => void;
	// exportData: exportDataInterface;
}) {
	const context = React.useContext(AppContext);
	return (
		<div className="flex justify-start mr-4">
			{context.userName !== props.createdBy ? (
				<></>
			) : (
				<div
					className="flex items-center text-text-gray-70 text-sm cursor-pointer gap-1"
					onClick={() => {
						props.handleDelete(props.id);
					}}>
					{icons.find((l) => l.id === "DeleteOutlined")?.icon} Delete Story
				</div>
			)}
			{/* <ExportToExcel exportData={props.exportData} /> */}

			{/* <div>
				<button
				className={`${props.numberOfHiglightedTranscripts >= publishLimit
					? "bg-text-teal text-white cursor-pointer border-text-teal border-2"
					: "bg-body text-text-gray-60 cursor-not-allowed border-2"
					} px-2 py-1 font-normal`}
				onClick={() => {
					if (props.numberOfHiglightedTranscripts >= publishLimit) props.handlePublish();
				}}
				>
				{icons.find((l) => l.id === "Publish")?.icon}
				{props.status === "In Draft" ? <>Publish Story</> : <>Republish Story</>}
				</button>
			</div> */}
		</div>
	);
}
/**
 * Renders the SubHeader Component inside the Stories Tab and specifies the routes inside the Stories Tab.
 *
 * @returns SubHeader Component and Routes inside Stories Tab.
 */

export interface storyModeData {
	id: number;
	storyName: string;
	storyType: number;
	status: string;
	createdBy: string;
	numberOfHighlightedTranscripts: number;
};

export default function StoryMode() {
	const { storyId } = useParams();
	const navigate = useNavigate();
	const [fetching, setFetching] = React.useState<boolean>(false);
	const [fetchedData, setFetchedData] = React.useState<storyModeData>(
		{
			id: 0,
			storyName: "",
			storyType: 1,
			status: "",
			createdBy: "",
			numberOfHighlightedTranscripts: 0,
		}
	);
	const [visible, setVisible] = React.useState(false);
	const [numberOfHighlightedTranscripts, setNumberOfHighlightedTranscripts] = React.useState(0);
	const [selectDelete, setSelectDelete] = React.useState(0);
	// const [exportData, setExportData] = React.useState<any>();

	const subTabs = [
		{
			subTab: "Annotated Selections",
			subSubTabs: [],
		},
		// {
		//   subTab: "Search",
		//   subSubTabs: [],
		// },
		{
			subTab: "Find Similar Documents",
			subSubTabs: [],
		},
	];

	function handleDelete(storyId: number) {
		setVisible(true);
		setSelectDelete(storyId);
	}

	function handleDeleteStoryApi() {
		setVisible(false);
		if (selectDelete) {
			deleteStory(selectDelete);
		}
		setSelectDelete(0);
	}

	function addTranscript(transcriptData: any) {
		fetchData();
		// const obj = {
		// 	id: transcriptData.id,
		// 	"Annotated Selection": transcriptData.name,
		// 	"Date Added on": transcript.call_date,
		// 	"Status":"Pending"
		// }
		// const temp = [...fetchedData];
		// temp.annotatedSelections.push(transcriptData);
		// setFetchedData(temp);
	}

	function addHighlightedTranscript() {
		// setNumberOfHighlightedTranscripts(numberOfHighlightedTranscripts + 1);
		// fetchedData.annotatedSelection.find(sel=>sel.))
	}

	function removeHighlightedTranscript() {
		// setNumberOfHighlightedTranscripts(numberOfHighlightedTranscripts - 1);
	}

	// function updateStatusCount(data: any) {
	//   let count = 0;
	//   // console.log("Data", data);
	//   setFetchedData(data); //test
	//   data.annotatedSelections.map((d: any) => {
	//     if (d.Status === "Complete") count++;
	//   });
	//   setNumberOfHighlightedTranscripts(count);
	// }

	// function handlePublishStoryApi() {
	//   setVisible(false);
	//   if (storyId) publishStory(storyId);
	// }

	async function deleteStory(storyId: number) {
		const key = "updatable";
		Messages.loading("Deleting Story", key);
		const data: any = await OldStoriesServiceHelper.deleteStory(storyId);
		if (data === 200) {
			Messages.success("Story Deleted Sucessfully", key);
			navigate("/Stories");
		} else {
			Messages.error("Failed!", key);
		}
	}

	async function fetchData() {
		setFetching(false);
		const data: any = await AnnotatedSelectionsHelper.getOriginalStorybyId(String(storyId));
		if (data) {
			setFetchedData(data);
			setFetching(true);
			// const exportData: exportDataInterface = {
			//   storyId: data.id,
			//   storyName: data.storyName,
			//   storyStatus: data.status,
			//   transcripts: data.annotatedSelections,
			// };
			// updateStatusCount(data);
			// setExportData(exportData);
			// setNumberOfHighlightedTranscripts(
			// 	data.numberOfHighlightedTranscripts
			// );
		}
	}

	React.useEffect(() => {
		fetchData();
	}, []);

	return (
		<>
			<DeleteAlert visible={visible} setVisible={setVisible} onYes={handleDeleteStoryApi} />
			{fetching ? (
				<>
					<div className="pt-2 px-2">
						<SubHeaderComponent
							breadcrumb={[
								{ item: "Stories", link: "/stories/" },
								{ item: fetchedData.storyName, link: "" },
							]}
							// status={fetchedData.status}
							extra={
								<SubHeaderExtra
									id={fetchedData.id}
									handleDelete={handleDelete}
									createdBy={fetchedData.createdBy}
									// status={fetchedData.status}
									// numberOfHiglightedTranscripts={numberOfHighlightedTranscripts}
									// handlePublish={handlePublishStoryApi}
									// exportData={exportData}
								/>
							}
						/>
						<SubNavigation tabs={subTabs} url={`/stories/story-mode/${storyId}`} redirectFlag={true} />
					</div>
					<div className="bg-body p-4" style={{ height: "76vh" }}>
						<Routes>
							<Route
								key={"Annotated Selections"}
								path="/"
								element={
									<AnnotatedSelection
										storyId={fetchedData.id}
										storyName={fetchedData.storyName}
										storyType={fetchedData.storyType}
										createdBy={fetchedData.createdBy}
										addHighlightedTranscript={addHighlightedTranscript}
										removeHighlightedTranscript={removeHighlightedTranscript}
									/>
								}
							/>
							{/* <Route key={"Search"} path="/Search" element={<StoriesSearch storyId={fetchedData.id} addTranscript={addTranscript} />} /> */}
							<Route
								key={"Find Similar Documents"}
								path="/Find_Similar_Documents"
								element={<FindSimilarConversations storyId={fetchedData.id} storyType={fetchedData.storyType} addTranscript={addTranscript} />}
							/>
						</Routes>
					</div>
				</>
			) : (
				<>
					<div className="pt-2 px-2">
						<SubHeaderComponent loading={true} extra={<></>} />
						<SubNavigation tabs={subTabs} />
					</div>
					<div className="bg-body p-4" style={{ height: "76vh", overflowY: "auto" }}>
						<Routes>
							<Route key={"Annotated Selections"} path="/" element={<AnnotatedSelectionLoading />} />
							{/* <Route key={"Search"} path="/Search" element={<StoriesSearch storyId={0} addTranscript={addTranscript} />} /> */}
							<Route key={"Find Similar Documents"} path="/Find_Similar_Documents" element={<FindSimilarConversations storyId={0} addTranscript={addTranscript} />} />
						</Routes>
					</div>
				</>
			)}
		</>
	);
}

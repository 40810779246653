import React, { useEffect, useRef } from "react";

interface fullTranscript {
  highlight?: boolean;
  renderText?: string;
  speaker: string;
  text: string;
  dialogueNo: number;
}

interface TranscriptDataProps {
  detail: fullTranscript;
  showKeywords: boolean;
  highlightedDialogueNo?: number[];
}

const TranscriptData: React.FC<TranscriptDataProps> = ({ detail, showKeywords, highlightedDialogueNo }) => {
  const isHighlighted = highlightedDialogueNo?.includes(detail.dialogueNo);
  const elementRef = useRef<HTMLParagraphElement | null>(null);

  useEffect(() => {
    if (isHighlighted && elementRef.current) {
      elementRef.current.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, [isHighlighted]);

  return showKeywords ? (
    <p
      className={`m-0 py-3 text-sm text-text-gray-90 transcript-text ${detail.highlight ? "contains-keyword" : ""}`}
      dangerouslySetInnerHTML={{ __html: detail.renderText || "" }}
    ></p>
  ) : detail.speaker === null ? (
    <p ref={elementRef} className="m-0 py-3 text-sm text-text-gray-90">
      <span style={{ backgroundColor: isHighlighted ? "#ffe68c" : "inherit" }}>{detail.text}</span>
    </p>
  ) : (
    <p ref={elementRef} className="m-0 py-3 text-sm text-text-gray-90">
      <span style={{ backgroundColor: isHighlighted ? "#ffe68c" : "inherit" }}>
        <strong>{detail.speaker}</strong>: {detail.text}
      </span>
    </p>
  );
};

export default TranscriptData;

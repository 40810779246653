import React, { useState, useEffect } from "react";
import { Form, Input, Select, Button, message } from "antd";
import * as StudyServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/study";

const { Option } = Select;

interface StudyData {
	studyTypeId: number;
	studyName: string;
	studyTypeName: string;
	studyDescription: string;
}

const CreateStudyForm = ({
	handleOk,
	handleCancel,
}: {
	handleOk: (values: StudyData) => void;
	handleCancel: () => void;
}) => {
	const [form] = Form.useForm();
	const [studyTypeOptions, setStudyTypeOptions] = useState<any[]>([]);
	const [studyList, setStudyList] = useState<any[]>([]);
	const [studyData, setStudyData] = useState<StudyData>({
		studyTypeId: undefined!,
		studyName: "",
		studyTypeName: "",
		studyDescription: "",
	});

	async function fetchDataForPage() {
		// })
		const data: any = await StudyServiceHelper.getStudyTypes();
		if (data) {
			// console.log(data);
			setStudyTypeOptions(data);
		}
		const studyListData: any = await StudyServiceHelper.getStudies();
		if (studyListData) {
			// console.log(studyListData);
			setStudyList(studyListData);
		}
	}

	useEffect(() => {
		form.resetFields();
		fetchDataForPage();
		// const options = ["agrim", "sharma"];
		// setStudyTypeOptions(options);
		// axios.get("your_backend_api_url")
		//   .then((response) => {
		//     const options = response.data.dropdownOptions;

		//     setStudyTypeOptions(options);
		//   })
		//   .catch((error) => {
		//     console.error("Error fetching dropdown options:", error);
		//   });
	}, []);

	const handleFinish = async (values: StudyData) => {
		// console.log("Study Form handleFinish Triggered.")
		// setStudyData(values);
		// const studyNameExists = studyList.some(
		//   (study) => study.studyName === values.studyName
		// );

		// if (studyNameExists) {
		//   message.error('Study Name already exists!');
		// } else {
		// StudyServiceHelper.addNewStudy(values);
		// handleOk(values);
		// console.log("Study Form handleFinish Triggered.", studyData);
		// console.log("Values:", values);
		// }

		try {
			const response: any = await StudyServiceHelper.addNewStudy(values);
			if (!response) throw new Error("");
			handleOk(values);
			// console.log("Study Form handleFinish Success.", studyData);
		} catch (error) {
			console.error("Error adding new study:", error);
			message.error("Error adding new study data. Please try again.");
		}
	};

	form.setFieldsValue(studyData);

	return (
		<Form form={form} onFinish={handleFinish} layout="vertical">
			<div className="flex">
				<Form.Item
					label="Study Name"
					name="studyName"
					rules={[
						{
							required: true,
							message: "Please enter a study name",
						},
						() => ({
							validator(_, value) {
								const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
								if (specialCharsRegex.test(value)) {
									return Promise.reject(new Error('Special characters not allowed in the study name'));
								}

								const studyNameExists = studyList.some((study) => study.studyName === value);
								if (studyNameExists) {
									return Promise.reject(new Error("Study name already exists!"));
								}
                
								return Promise.resolve();
							},
						}),
					]}
					className="flex-1">
					<Input style={{ width: "100%" }} placeholder="Study Name" />
				</Form.Item>
			</div>

			<div className="flex">
				<Form.Item
					label="Study Type"
					name="studyTypeId"
					rules={[
						{
							required: true,
							message: "Please select a study type",
						},
					]}
					className="flex-1">
					<Select
						style={{ width: "200px" }}
						placeholder="Study Type"
						defaultActiveFirstOption={true}>
						{studyTypeOptions.map((option) => (
							<Option
								key={option.studyTypeId}
								value={option.studyTypeId}>
								{option.studyTypeName}
							</Option>
							// <Option key={option} value={option}>
							//   {option}
							// </Option>
						))}
					</Select>
				</Form.Item>
			</div>

			<div>
				<Form.Item
					label="Study Description"
					name="studyDescription"

					rules={[
						// {
						// 	required: true,
						// 	message: "Please enter study description",
						// },
						() => ({
							validator(_, value) {
								const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
								if (specialCharsRegex.test(value)) {
									return Promise.reject(new Error('Special characters not allowed in the study description'));
								}
                
								return Promise.resolve();
							},
						}),
					]}
				>
					<Input.TextArea
						rows={10}
						style={{ width: "100%" }}
						placeholder="Study Description"
					/>
				</Form.Item>
			</div>

			<div className="flex justify-end">
				<Form.Item>
					<Button type="default" onClick={handleCancel}>
						Cancel
					</Button>
					<Button type="primary" htmlType="submit" className="ml-3">
						Submit
					</Button>
				</Form.Item>
			</div>
		</Form>
	);
};

export default CreateStudyForm;

import { CSVDownload, CSVLink } from "react-csv";
import { icons } from "../../../GlobalThemes";
import { transformExportData } from "./processing";
import { DownloadOutlined } from "@ant-design/icons";
import { Button } from "antd";

const csvData = [
	[
		"Story Id",
		"Story Name",
		"Story Status",
		"Transcript",
		"Transcript Added On",
		"Dialogue Appeared At",
		"Dialogue Length",
		"Transcript Length",
		"Transcript Text",
		"Source",
		"Transript Tags",
		"Transcript Status",
		"Highlighted Text",
	],
	[
		"Story_1",
		"Flushing Related Issues",
		"In Draft",
		"Transcript from 29 Jul 2021 [09:18:39]",
		"15 Feb 2022",
		"274.31 minutes",
		"13.42 minutes",
		"446.52 minutes",
		"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
		"Search Based",
		"Covid, Question",
		"Pending",
		"",
	],
	[
		"Story_1",
		"Flushing Related Issues",
		"In Draft",
		"Transcript from 23 Jul 2021 [17:12:45]",
		"11 Feb 2022",
		"274.31 minutes",
		"13.42 minutes",
		"446.52 minutes",
		"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
		"Search Based",
		"",
		"Complete",
		"so you did have some flushing on the community on the tecfidera thymethyl and that sounds like that flushing was related to your therapy.",
	],
	[
		"Story_1",
		"Flushing Related Issues",
		"In Draft",
		"Transcript from 16 Jul 2021 [16:39:58]",
		"15 Feb 2022",
		"274.31 minutes",
		"13.42 minutes",
		"446.52 minutes",
		"Patient : id of for 10, so 2022 10 ? Agent : mm okay so a lot of numbers id expiration is nan christopher do you feel that the um flushing or the redness on your ears that you experience is related to the viewmeridy okay. Patient : uh i would i would assume so but i do not know that for sure.",
		"System Recommended",
		"",
		"Complete",
		"",
		"",
	],
];

export interface exportDataInterface {
	storyId: string;
	storyName: string;
	transcripts: {
		"Annotated Selection": string;
		"Date Added on": string;
		Source: string;
		Status: string;
		Tags: string[];
		highlightText: string;
		highlightedString: string;
		Description: {
			dialogueNo: number;
		};
	}[];
}

export function ExportToExcel(props: { exportData: exportDataInterface }) {
	return (
		<CSVLink data={transformExportData(props.exportData)} target="_blank" filename={props.exportData.storyName}>
			{/* <div className="flex items-center text-text-gray-70 text-sm cursor-pointer">
				{icons.find((l) => l.id === "ExportCsv")?.icon} Export Data
			</div> */}
			<Button type="primary" htmlType="submit" className="green-button flex items-center ml-auto" icon={<DownloadOutlined />}>
				Export
			</Button>
		</CSVLink>
	);
}

import { Button, Checkbox, DatePicker, Input, Modal, Select, Switch } from "antd";
import { icons } from "../../GlobalThemes";
import React, { useState, useContext } from "react";
import moment from "moment";
// import AppContext from "../../store";
// import { searchQuery } from "../../Pages/SearchEngine/Search";
// import { getAllTranscripts } from "../../Pages/SearchEngine/ServiceHelpers/search";
// import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
// import { Route } from "react-router-dom";
// import PatientDetails from "../../Pages/PatientJourney/PatientDetails";

const { Search } = Input;
export function SearchFilters(props: { data: any; patientsList: any; setPatientsList: any; fetchedData: any; setFetchedData: any; showPatientHistory: any }) {
	var filterData = {
		patientID: "",
		unmetNeed: "",
		discontinuationRisk: "",
	};
	const [searchFilterData, setSearchFilterData] = useState<any>(filterData);

	const onSearch = (value: string) => {
		//    console.log(value); // Commented out console.log
		// let data = props.patientsList;
		// props.setPatientsList(data.filter((i: any) => i.Patient_ID === value));
	};

	const handleFilters = (filterType: string, filterValue: string | boolean) => {
		var filters: any = searchFilterData;
		filters[filterType] = filterValue;
		//    console.log(filters); // Commented out console.log

		let tempData: any = [...props.data];
		let temp: any = [];
		tempData.map((i: any) => {
			if (filterType === "patientID") {
				if (i.lh_id.includes(searchFilterData.patientID)) {
					let obj: any = {};
					obj.id = i.patientId;
					obj.lh_id = i.lh_id;
					obj.currentTherapy = i.currentTherapy;
					obj.disconRisk = (
						<div
							style={{
								color: i.disconRisk === "High" ? "#B21111" : i.disconRisk === "Medium" ? "#8A640C" : "#27A6A7",
								font: "semibold",
							}}>
							{i.disconRisk === "High" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "ExclamationTriangle")?.icon}</span>
							) : i.disconRisk === "Medium" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "MinusCircle")?.icon}</span>
							) : i.disconRisk === "Low" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "Thumbs Up")?.icon}</span>
							) : (
								<></>
							)}

							<span>{i.disconRisk}</span>
						</div>
					);
					obj.topUnmetNeeds = (
						<ul style={{ listStyleType: "disc" }}>
							{i.topUnmetNeeds_1 && <li className="mt-2">{i.topUnmetNeeds_1}</li>}
							{i.topUnmetNeeds_2 && <li className="mt-2">{i.topUnmetNeeds_2}</li>}
							{i.topUnmetNeeds_3 && <li className="mt-2">{i.topUnmetNeeds_3}</li>}
							{i.topUnmetNeeds_4 && <li className="mt-2">{i.topUnmetNeeds_4}</li>}
							{i.topUnmetNeeds_5 && <li className="mt-2">{i.topUnmetNeeds_5}</li>}
							{i.topUnmetNeeds_6 && <li className="mt-2">{i.topUnmetNeeds_6}</li>}
							{i.topUnmetNeeds_7 && <li className="mt-2">{i.topUnmetNeeds_7}</li>}
							{i.topUnmetNeeds_8 && <li className="mt-2">{i.topUnmetNeeds_8}</li>}
							{i.topUnmetNeeds_9 && <li className="mt-2">{i.topUnmetNeeds_9}</li>}
							{i.topUnmetNeeds_10 && <li className="mt-2">{i.topUnmetNeeds_10}</li>}
						</ul>
					);
					obj.therapyStartDate = moment(i.therapyStartDate).format("DD MMM YYYY");
					obj.timeOnTherapy = i.timeOnTherapy;
					obj.outOfStockDate = moment(i.outOfStockDate).format("DD MMM YYYY");
					temp.push(obj);
				}
			} else if (filterType === "discontinuationRisk") {
				if (i.disconRisk === filterValue) {
					let obj: any = {};
					obj.id = i.patientId;
					obj.lh_id = i.lh_id;
					obj.currentTherapy = i.currentTherapy;
					obj.disconRisk = (
						<div
							style={{
								color: i.disconRisk === "High" ? "#B21111" : i.disconRisk === "Medium" ? "#8A640C" : "#27A6A7",
								font: "semibold",
							}}>
							{i.disconRisk === "High" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "ExclamationTriangle")?.icon}</span>
							) : i.disconRisk === "Medium" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "MinusCircle")?.icon}</span>
							) : i.disconRisk === "Low" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "Thumbs Up")?.icon}</span>
							) : (
								<></>
							)}

							<span>{i.disconRisk}</span>
						</div>
					);
					obj.topUnmetNeeds = (
						<ul style={{ listStyleType: "disc" }}>
							{i.topUnmetNeeds_1 && <li className="mt-2">{i.topUnmetNeeds_1}</li>}
							{i.topUnmetNeeds_2 && <li className="mt-2">{i.topUnmetNeeds_2}</li>}
							{i.topUnmetNeeds_3 && <li className="mt-2">{i.topUnmetNeeds_3}</li>}
							{i.topUnmetNeeds_4 && <li className="mt-2">{i.topUnmetNeeds_4}</li>}
							{i.topUnmetNeeds_5 && <li className="mt-2">{i.topUnmetNeeds_5}</li>}
							{i.topUnmetNeeds_6 && <li className="mt-2">{i.topUnmetNeeds_6}</li>}
							{i.topUnmetNeeds_7 && <li className="mt-2">{i.topUnmetNeeds_7}</li>}
							{i.topUnmetNeeds_8 && <li className="mt-2">{i.topUnmetNeeds_8}</li>}
							{i.topUnmetNeeds_9 && <li className="mt-2">{i.topUnmetNeeds_9}</li>}
							{i.topUnmetNeeds_10 && <li className="mt-2">{i.topUnmetNeeds_10}</li>}
						</ul>
					);
					obj.therapyStartDate = moment(i.therapyStartDate).format("DD MMM YYYY");
					obj.timeOnTherapy = i.timeOnTherapy;
					obj.outOfStockDate = moment(i.outOfStockDate).format("DD MMM YYYY");
					temp.push(obj);
				}
			} else if (filterType === "unmetNeed") {
				if (
					i.topUnmetNeeds_1 === filterValue ||
					i.topUnmetNeeds_2 === filterValue ||
					i.topUnmetNeeds_3 === filterValue ||
					i.topUnmetNeeds_4 === filterValue ||
					i.topUnmetNeeds_5 === filterValue ||
					i.topUnmetNeeds_6 === filterValue ||
					i.topUnmetNeeds_7 === filterValue ||
					i.topUnmetNeeds_8 === filterValue ||
					i.topUnmetNeeds_9 === filterValue ||
					i.topUnmetNeeds_10 === filterValue
				) {
					let obj: any = {};
					obj.id = i.patientId;
					obj.lh_id = i.lh_id;
					obj.currentTherapy = i.currentTherapy;
					obj.disconRisk = (
						<div
							style={{
								color: i.disconRisk === "High" ? "#B21111" : i.disconRisk === "Medium" ? "#8A640C" : "#27A6A7",
								font: "semibold",
							}}>
							{i.disconRisk === "High" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "ExclamationTriangle")?.icon}</span>
							) : i.disconRisk === "Medium" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "MinusCircle")?.icon}</span>
							) : i.disconRisk === "Low" ? (
								<span className="mr-2 -mt-1">{icons.find((l: any) => l.id === "Thumbs Up")?.icon}</span>
							) : (
								<></>
							)}

							<span>{i.disconRisk}</span>
						</div>
					);
					obj.topUnmetNeeds = (
						<ul style={{ listStyleType: "disc" }}>
							{i.topUnmetNeeds_1 && <li className="mt-2">{i.topUnmetNeeds_1}</li>}
							{i.topUnmetNeeds_2 && <li className="mt-2">{i.topUnmetNeeds_2}</li>}
							{i.topUnmetNeeds_3 && <li className="mt-2">{i.topUnmetNeeds_3}</li>}
							{i.topUnmetNeeds_4 && <li className="mt-2">{i.topUnmetNeeds_4}</li>}
							{i.topUnmetNeeds_5 && <li className="mt-2">{i.topUnmetNeeds_5}</li>}
							{i.topUnmetNeeds_6 && <li className="mt-2">{i.topUnmetNeeds_6}</li>}
							{i.topUnmetNeeds_7 && <li className="mt-2">{i.topUnmetNeeds_7}</li>}
							{i.topUnmetNeeds_8 && <li className="mt-2">{i.topUnmetNeeds_8}</li>}
							{i.topUnmetNeeds_9 && <li className="mt-2">{i.topUnmetNeeds_9}</li>}
							{i.topUnmetNeeds_10 && <li className="mt-2">{i.topUnmetNeeds_10}</li>}
						</ul>
					);
					obj.therapyStartDate = moment(i.therapyStartDate).format("DD MMM YYYY");
					obj.timeOnTherapy = i.timeOnTherapy;
					obj.outOfStockDate = moment(i.outOfStockDate).format("DD MMM YYYY");
					temp.push(obj);
				}
			}
		});

		//    console.log("CHECK:", temp, props.patientsList, props.fetchedData); // Commented out console.log
		if (temp.length !== 0) {
			props.setFetchedData(temp);
		} else {
			props.setFetchedData(props.patientsList);
		}
		setSearchFilterData(filters);
	};

	return (
		<div className="flex justify-between items-center pb-4">
			<div className="flex align-middle">
				<div className="flex items-center mr-8">
					<Search
						placeholder="Search by Patient ID"
						allowClear
						onChange={(e: any) => {
							handleFilters("patientID", e.target.value);
						}}
						style={{ width: 400 }}
					/>
				</div>
				<div className="flex items-center mr-8">
					<span className="mr-2">Unmet Need:</span>
					<Select
						clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
						className="w-64"
						placeholder="Unmet Need"
						allowClear
						// value={searchFilterData["unmetNeed"]}
						onChange={(value: string) => {
							handleFilters("unmetNeed", value);
						}}>
						<Select.Option value="Sad/fear about side effects">Sad/fear about side effects</Select.Option>
						<Select.Option value="Patient participation on dosing">Patient participation on dosing</Select.Option>
						<Select.Option value="Curiosity on diet Instruction">Curiosity on diet Instruction</Select.Option>
						<Select.Option value="Anger on insurance">Anger on insurance</Select.Option>
					</Select>
				</div>
				<div className="flex items-center mr-8">
					<span className="mr-2">Discontinuation Risk:</span>
					<Select
						clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
						className="w-52"
						placeholder="Discontinuation Risk"
						allowClear
						// value={searchFilterData["discontinuationRisk"]}
						onChange={(value: string) => {
							handleFilters("discontinuationRisk", value);
						}}>
						<Select.Option value="High">High</Select.Option>
						<Select.Option value="Medium">Medium</Select.Option>
						<Select.Option value="Low">Low</Select.Option>
					</Select>
				</div>
			</div>
			<div className="flex items-center ml-5"></div>
		</div>
	);
}

import {
	Button,
	Checkbox,
	DatePicker,
	Input,
	Modal,
	Select,
	Switch,
} from "antd";
import { icons } from "../../GlobalThemes";
import React, { useState, useContext } from "react";
import * as Messages from "../../Components/Messages";
import moment from "moment";
import AppContext from "../../store";
import { searchQuery } from "../SearchEngine/Search";
import { getAllTranscripts } from "../SearchEngine/ServiceHelpers/search";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import * as NodeGraphServiceHelper from "./../SearchEngine/ServiceHelpers/nodeGraph";
import * as ExploreServiceHelper from "./../SearchEngine/ServiceHelpers/explore";

export interface filterValues {
	startDate: string;
	endData: string;
	speaker: string;
	searchWord: string;
}

/**
 * Renders the search filters at the top of search page, which includes speaker type, time period and search bar.
 *
 * @remarks - This function is the part of search tab and will be rendered at the top of the page
 *
 * @param showDrawer - The props used to show and hide the drawer for advanced filters
 * @param fetchTranscripts - This function is  used to fetch the transcripts based on the added inputs
 * @param searchWordRequired - This props check search word is present or not
 *
 * @returns the filters at search tab
 */

	// const data: any = [
	//   {
	//     transcriptStoriesDummyData: [
	//       {
	//         id: "story_98",
	//         "Story Name": "insurance",
	//         "Date Created": "30 Sep 2022",
	//         "Story Type": "General Tracker",
	//         "# of Annotated Selections": 2,
	//         Status: "In Draft",
	//         index: 11,
	//       },
	//       {
	//         id: "story_97",
	//         "Story Name": "alcohol",
	//         "Date Created": "30 Sep 2022",
	//         "Story Type": "General Tracker",
	//         "# of Annotated Selections": 18,
	//         Status: "In Draft",
	//         index: 12,
	//       },
	//     ],
	//   },
	//   {
	//     transcriptFilters: [
	//       {
	//         optionsObject: [
	//           {
	//             id: "1",
	//             name: "Inbound",
	//             subgroup: [],
	//           },
	//           {
	//             id: "2",
	//             name: "Outbound",
	//             subgroup: [],
	//           },
	//         ],
	//         filter: "Call_Type",
	//         type: "select",
	//       },
	//       {
	//         optionsObject: [
	//           {
	//             id: "1",
	//             name: "ON THERAPY",
	//             subgroup: [],
	//           },
	//           {
	//             id: "2",
	//             name: "DISCONTINUED",
	//             subgroup: [],
	//           },
	//           {
	//             id: "3",
	//             name: "NEVER START",
	//             subgroup: [],
	//           },
	//           {
	//             id: "-1",
	//             name: "UNKNOWN",
	//             subgroup: [],
	//           },
	//           {
	//             id: "4",
	//             name: "IN PROCESS",
	//             subgroup: [],
	//           },
	//           {
	//             id: "5",
	//             name: "ON THERAPY REPEAT",
	//             subgroup: [],
	//           },
	//         ],
	//         filter: "Therapy_Status",
	//         type: "select",
	//       },
	//       {
	//         optionsObject: [
	//           {
	//             id: "1",
	//             name: "positive",
	//             subgroup: [],
	//           },
	//           {
	//             id: "2",
	//             name: "negative",
	//             subgroup: [],
	//           },
	//           {
	//             id: "3",
	//             name: "neutral",
	//             subgroup: [],
	//           },
	//         ],
	//         filter: "Sentiment",
	//         type: "select",
	//       },
	//     ],
	//   },
	//   {
	//     nodeGraphTranscriptData: {
	//       transcript_data: [
	//         {
	//           topicId: 0,
	//           topicName: "denied claim lectrazine",
	//           numberOfSearchResults: 3,
	//           searchResults: [
	//             {
	//               searchId: 1,
	//               name: "Transcript from 25 May 2022 [20:15:09]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 4,
	//               callId: "O1388T9ZZ5BCLGTD",
	//               callDate: "2022-05-25",
	//               callTime: "20:15:09",
	//               dialogueAppearedAt: 16.1,
	//               dialogueLength: 15.18,
	//               transcriptLength: 75.44,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 2,
	//                   text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i'm sorry to hear that. can you tell me what happened ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "that doesn't sound right. let me see if i can help you figure this out.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 6,
	//                   text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
	//               elasticSearchId: "O1388T9ZZ5BCLGTD_4",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. can you tell me what happened ?",
	//                   highLights: [],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "that doesn't sound right. let me see if i can help you figure this out.",
	//                   highLights: [],
	//                   dialogueNo: 5,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
	//                   highLights: [],
	//                   dialogueNo: 6,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 2,
	//               name: "Transcript from 09 May 2022 [21:20:36]",
	//               tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 3,
	//               callId: "WW8PUBYMYJOW4S8N",
	//               callDate: "2022-05-09",
	//               callTime: "21:20:36",
	//               dialogueAppearedAt: 39.56,
	//               dialogueLength: 11.5,
	//               transcriptLength: 118.68,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
	//               elasticSearchId: "WW8PUBYMYJOW4S8N_3",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
	//                   highLights: [],
	//                   dialogueNo: 5,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 3,
	//               name: "Transcript from 25 May 2022 [15:30:20]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 2,
	//               callId: "F1T9KO8WBYD609GF",
	//               callDate: "2022-05-25",
	//               callTime: "15:30:20",
	//               dialogueAppearedAt: 3.68,
	//               dialogueLength: 6.44,
	//               transcriptLength: 119.14,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "hi, how can i help you ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i'm sorry to hear that. can you tell me what happened ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
	//               elasticSearchId: "F1T9KO8WBYD609GF_2",
	//               transcripts: [
	//                 {
	//                   speaker: "Agent",
	//                   text: "hi, how can i help you ?",
	//                   highLights: [],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. can you tell me what happened ?",
	//                   highLights: [],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
	//                   highLights: [],
	//                   dialogueNo: 4,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//           ],
	//           emotionsCounts: [
	//             {
	//               text: "headaches",
	//               value: 5711,
	//             },
	//             {
	//               text: "slight headache",
	//               value: 1130,
	//             },
	//           ],
	//           headers: {
	//             "x-time": {
	//               query_expansion_time: 0.23832941055297852,
	//               retriever_time: 0.645418643951416,
	//               ranker_time: 0.7286033630371094,
	//               df_prepare_time: 0.008182287216186523,
	//               process_time: 1.665311574935913,
	//             },
	//             "x-search-config": {
	//               query_type: "keyword",
	//               expanded_query:
	//                 "[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
	//             },
	//             "x-auth-time": "0.3819572925567627",
	//             "x-process-time": "2.050896167755127",
	//           },
	//         },
	//         {
	//           topicId: 1,
	//           topicName: "feeling really anxious",
	//           numberOfSearchResults: 3,
	//           searchResults: [
	//             {
	//               searchId: 1,
	//               name: "Transcript from 17 May 2022 [11:44:17]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 3,
	//               callId: "U27WXBUWZB8TNX14",
	//               callDate: "2022-05-17",
	//               callTime: "11:44:17",
	//               dialogueAppearedAt: 18.4,
	//               dialogueLength: 16.1,
	//               transcriptLength: 104.88,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
	//               elasticSearchId: "U27WXBUWZB8TNX14_3",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
	//                   highLights: ["calls", "feel", "getting", "time"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
	//                   highLights: ["feel", "feeling"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
	//                   highLights: ["call", "getting", "time"],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
	//                   highLights: ["feel", "getting"],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
	//                   highLights: ["calling", "feel"],
	//                   dialogueNo: 5,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 2,
	//               name: "Transcript from 23 May 2022 [14:15:27]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 6,
	//               callId: "2VN0YRE1QMBTAEUC",
	//               callDate: "2022-05-23",
	//               callTime: "14:15:27",
	//               dialogueAppearedAt: 64.4,
	//               dialogueLength: 15.64,
	//               transcriptLength: 138.46,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [
	//                 {
	//                   storyId: "story_13",
	//                   storyName: "dummy2",
	//                 },
	//               ],
	//               addedToStory: "true",
	//               context: [
	//                 {
	//                   dialogueNo: 4,
	//                   text: "i ordered it from the company's website. they said it would take about a week to ship, but it's already been almost two weeks.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "well, it's possible that there was a delay in shipping. have you contacted the company to see if they can give you any information about where your order is ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 6,
	//                   text: "yeah, i've tried to call them a few times, but i can't get through to anyone. i've also sent them a few emails, but i haven't gotten any response.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 7,
	//                   text: "well, it's possible that they're just very busy and haven't been able to get back to you yet. i would suggest trying to contact them again, and if you still can't get through, you can try to reach out to their customer service department.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 8,
	//                   text: "yeah, i'll try that. i'm just really worried that something might have happened to my medication. i need it to manage my condition.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/2VN0YRE1QMBTAEUC.json",
	//               elasticSearchId: "2VN0YRE1QMBTAEUC_6",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i ordered it from the company's website. they said it would take about a week to ship, but it's already been almost two weeks.",
	//                   highLights: [],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "well, it's possible that there was a delay in shipping. have you contacted the company to see if they can give you any information about where your order is ?",
	//                   highLights: [],
	//                   dialogueNo: 5,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "yeah, i've tried to call them a few times, but i can't get through to anyone. i've also sent them a few emails, but i haven't gotten any response.",
	//                   highLights: ["call", "get", "gotten", "times"],
	//                   dialogueNo: 6,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "well, it's possible that they're just very busy and haven't been able to get back to you yet. i would suggest trying to contact them again, and if you still can't get through, you can try to reach out to their customer service department.",
	//                   highLights: ["get"],
	//                   dialogueNo: 7,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "yeah, i'll try that. i'm just really worried that something might have happened to my medication. i need it to manage my condition.",
	//                   highLights: [],
	//                   dialogueNo: 8,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 3,
	//               name: "Transcript from 17 May 2022 [11:44:17]",
	//               tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 5,
	//               callId: "U27WXBUWZB8TNX14",
	//               callDate: "2022-05-17",
	//               callTime: "11:44:17",
	//               dialogueAppearedAt: 49.22,
	//               dialogueLength: 11.04,
	//               transcriptLength: 104.88,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 3,
	//                   text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 6,
	//                   text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 7,
	//                   text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
	//               elasticSearchId: "U27WXBUWZB8TNX14_5",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
	//                   highLights: ["call", "getting", "time"],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i understand how you feel. we just want to make sure that you're getting the best possible care. that's why we like to stay in touch with our patients.",
	//                   highLights: ["feel", "getting"],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
	//                   highLights: ["calling", "feel"],
	//                   dialogueNo: 5,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
	//                   highLights: ["calls"],
	//                   dialogueNo: 6,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
	//                   highLights: ["feel"],
	//                   dialogueNo: 7,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 4,
	//               name: "Transcript from 23 May 2022 [12:11:21]",
	//               tags: ["Others", "Inbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 4,
	//               callId: "UP3DS2H374JBNPNW",
	//               callDate: "2022-05-23",
	//               callTime: "12:11:21",
	//               dialogueAppearedAt: 20.7,
	//               dialogueLength: 9.66,
	//               transcriptLength: 98.44,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 2,
	//                   text: "not great. i'm still waiting for my lectrazine medication to arrive and it's been over a week.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i'm sorry to hear that. can you tell me a little bit more about your symptoms ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "i'm feeling really anxious and my heart is racing. i can't sleep or eat and i'm just really frustrated.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "that sounds very difficult. i'll see if i can find out what's going on with your medication and try to get you some relief.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 6,
	//                   text: "thank you.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/UP3DS2H374JBNPNW.json",
	//               elasticSearchId: "UP3DS2H374JBNPNW_4",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "not great. i'm still waiting for my lectrazine medication to arrive and it's been over a week.",
	//                   highLights: [],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. can you tell me a little bit more about your symptoms ?",
	//                   highLights: [],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm feeling really anxious and my heart is racing. i can't sleep or eat and i'm just really frustrated.",
	//                   highLights: ["feeling"],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "that sounds very difficult. i'll see if i can find out what's going on with your medication and try to get you some relief.",
	//                   highLights: ["get"],
	//                   dialogueNo: 5,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "thank you.",
	//                   highLights: [],
	//                   dialogueNo: 6,
	//                 },
	//               ],
	//               callTypeId: 1,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 5,
	//               name: "Transcript from 17 May 2022 [11:44:17]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 7,
	//               callId: "U27WXBUWZB8TNX14",
	//               callDate: "2022-05-17",
	//               callTime: "11:44:17",
	//               dialogueAppearedAt: 77.74,
	//               dialogueLength: 9.66,
	//               transcriptLength: 104.88,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 5,
	//                   text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 6,
	//                   text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 7,
	//                   text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 8,
	//                   text: "i'm sorry to hear that. we'll do our best to make the calls less frequent. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
	//                   speaker: "Agent",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
	//               elasticSearchId: "U27WXBUWZB8TNX14_7",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "but i feel like i can't even take a step without you calling me. it's really starting to interfere with my life.",
	//                   highLights: ["calling", "feel"],
	//                   dialogueNo: 5,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. we'll try to cut back on the calls a bit. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
	//                   highLights: ["calls"],
	//                   dialogueNo: 6,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i don't know. it's just really frustrating. i feel like i can't even have a normal life anymore.",
	//                   highLights: ["feel"],
	//                   dialogueNo: 7,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. we'll do our best to make the calls less frequent. but in the meantime, is there anything we can do to help make the calls more bearable for you ?",
	//                   highLights: ["calls"],
	//                   dialogueNo: 8,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 6,
	//               name: "Transcript from 17 May 2022 [11:44:17]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 1,
	//               callId: "U27WXBUWZB8TNX14",
	//               callDate: "2022-05-17",
	//               callTime: "11:44:17",
	//               dialogueAppearedAt: 0,
	//               dialogueLength: 8.74,
	//               transcriptLength: 104.88,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/U27WXBUWZB8TNX14.json",
	//               elasticSearchId: "U27WXBUWZB8TNX14_1",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm just so frustrated. i feel like i'm getting calls all the time from the nurse educator.",
	//                   highLights: ["calls", "feel", "getting", "time"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that you're feeling frustrated. can you tell me more about why you feel that way ?",
	//                   highLights: ["feel", "feeling"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "it just seems like every time i turn around, i'm getting a call from you. i know you're just trying to help, but it's really getting to be a bit much.",
	//                   highLights: ["call", "getting", "time"],
	//                   dialogueNo: 3,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//           ],
	//           emotionsCounts: [
	//             {
	//               text: "headaches",
	//               value: 5711,
	//             },
	//             {
	//               text: "slight headache",
	//               value: 1130,
	//             },
	//           ],
	//           headers: {
	//             "x-time": {
	//               query_expansion_time: 0.23832941055297852,
	//               retriever_time: 0.645418643951416,
	//               ranker_time: 0.7286033630371094,
	//               df_prepare_time: 0.008182287216186523,
	//               process_time: 1.665311574935913,
	//             },
	//             "x-search-config": {
	//               query_type: "keyword",
	//               expanded_query:
	//                 "[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
	//             },
	//             "x-auth-time": "0.3819572925567627",
	//             "x-process-time": "2.050896167755127",
	//           },
	//         },
	//         {
	//           topicId: 2,
	//           topicName: "worried effects lectrazine",
	//           numberOfSearchResults: 7,
	//           searchResults: [
	//             {
	//               searchId: 1,
	//               name: "Transcript from 04 May 2022 [18:32:15]",
	//               tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 1,
	//               callId: "SR0ANN6MVSM2XHRA",
	//               callDate: "2022-05-04",
	//               callTime: "18:32:15",
	//               dialogueAppearedAt: 0,
	//               dialogueLength: 11.5,
	//               transcriptLength: 126.5,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i'm really worried about the side effects of lectrazine. i've read that it can cause weight gain, hair loss, and headaches.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "yes, those are all potential side effects of lectrazine. however, they are all relatively rare. most people who take lectrazine don't experience any serious side effects.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "but what if i'm one of the people who does experience side effects ?",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/SR0ANN6MVSM2XHRA.json",
	//               elasticSearchId: "SR0ANN6MVSM2XHRA_1",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm really worried about the side effects of lectrazine. i've read that it can cause weight gain, hair loss, and headaches.",
	//                   highLights: ["cause", "effects", "lectrazine", "side", "worried"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "yes, those are all potential side effects of lectrazine. however, they are all relatively rare. most people who take lectrazine don't experience any serious side effects.",
	//                   highLights: ["effects", "lectrazine", "side"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "but what if i'm one of the people who does experience side effects ?",
	//                   highLights: ["effects", "side"],
	//                   dialogueNo: 3,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 2,
	//               name: "Transcript from 04 May 2022 [10:31:55]",
	//               tags: ["Common Side Effects", "Inbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 1,
	//               callId: "GY5L641CE6CYTX5M",
	//               callDate: "2022-05-04",
	//               callTime: "10:31:55",
	//               dialogueAppearedAt: 0,
	//               dialogueLength: 9.2,
	//               transcriptLength: 84.18,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i'm really worried about the side effects of lectrazine. i've heard that it can cause some serious problems.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "yes, lectrazine can cause some serious side effects, but it's important to remember that it's also a very effective medication. if you're concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i don't know if i'm comfortable taking a medication that could have such serious side effects.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/GY5L641CE6CYTX5M.json",
	//               elasticSearchId: "GY5L641CE6CYTX5M_1",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm really worried about the side effects of lectrazine. i've heard that it can cause some serious problems.",
	//                   highLights: ["cause", "effects", "lectrazine", "side", "worried"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "yes, lectrazine can cause some serious side effects, but it's important to remember that it's also a very effective medication. if you're concerned about the side effects, we can talk about them and see if there are any other options that might be a better fit for you.",
	//                   highLights: ["cause", "effects", "lectrazine", "side"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i don't know if i'm comfortable taking a medication that could have such serious side effects.",
	//                   highLights: ["effects", "side"],
	//                   dialogueNo: 3,
	//                 },
	//               ],
	//               callTypeId: 1,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 3,
	//               name: "Transcript from 09 May 2022 [21:20:36]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 1,
	//               callId: "WW8PUBYMYJOW4S8N",
	//               callDate: "2022-05-09",
	//               callTime: "21:20:36",
	//               dialogueAppearedAt: 0,
	//               dialogueLength: 9.66,
	//               transcriptLength: 118.68,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
	//               elasticSearchId: "WW8PUBYMYJOW4S8N_1",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
	//                   highLights: ["effects", "lectrazine", "side", "worried"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
	//                   highLights: ["cause", "effects", "lectrazine", "side"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 3,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 4,
	//               name: "Transcript from 10 May 2022 [11:13:55]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 1,
	//               callId: "NEAEBCB3J820JCG7",
	//               callDate: "2022-05-10",
	//               callTime: "11:13:55",
	//               dialogueAppearedAt: 0,
	//               dialogueLength: 7.82,
	//               transcriptLength: 58.42,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i've been taking lectrazine for a while now and i'm starting to notice some adverse effects.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "what kind of adverse effects are you noticing ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i'm having a lot of trouble sleeping, and i'm really tired during the day. i'm also having a lot of headaches.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/NEAEBCB3J820JCG7.json",
	//               elasticSearchId: "NEAEBCB3J820JCG7_1",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i've been taking lectrazine for a while now and i'm starting to notice some adverse effects.",
	//                   highLights: ["effects", "lectrazine"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "what kind of adverse effects are you noticing ?",
	//                   highLights: ["effects"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm having a lot of trouble sleeping, and i'm really tired during the day. i'm also having a lot of headaches.",
	//                   highLights: [],
	//                   dialogueNo: 3,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 5,
	//               name: "Transcript from 13 May 2022 [11:37:35]",
	//               tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Neutral"],
	//               dialogueNo: 2,
	//               callId: "8VHNCXKTEO2KE9JF",
	//               callDate: "2022-05-13",
	//               callTime: "11:37:35",
	//               dialogueAppearedAt: 3.22,
	//               dialogueLength: 7.36,
	//               transcriptLength: 59.34,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "hello, how are you today ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "i'm feeling okay, but i'm a little concerned about the adverse events of lectrazine.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "yes, lectrazine can cause some side effects, but they are usually mild and go away on their own.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "what are the most common side effects ?",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/8VHNCXKTEO2KE9JF.json",
	//               elasticSearchId: "8VHNCXKTEO2KE9JF_2",
	//               transcripts: [
	//                 {
	//                   speaker: "Agent",
	//                   text: "hello, how are you today ?",
	//                   highLights: [],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm feeling okay, but i'm a little concerned about the adverse events of lectrazine.",
	//                   highLights: ["lectrazine"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "yes, lectrazine can cause some side effects, but they are usually mild and go away on their own.",
	//                   highLights: ["cause", "effects", "lectrazine", "side"],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "what are the most common side effects ?",
	//                   highLights: ["effects", "side"],
	//                   dialogueNo: 4,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 6,
	//               name: "Transcript from 10 May 2022 [20:54:19]",
	//               tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 1,
	//               callId: "GSADKQ7M04XE0EZJ",
	//               callDate: "2022-05-10",
	//               callTime: "20:54:19",
	//               dialogueAppearedAt: 0,
	//               dialogueLength: 6.44,
	//               transcriptLength: 25.76,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "hi, i'm worried about the potential side effects of the drug lectrazine.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "hi. don't worry, the potential side effects of lectrazine are usually mild and go away on their own. however, if you experience any serious side effects, please contact your doctor immediately.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "ok, thank you.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/GSADKQ7M04XE0EZJ.json",
	//               elasticSearchId: "GSADKQ7M04XE0EZJ_1",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "hi, i'm worried about the potential side effects of the drug lectrazine.",
	//                   highLights: ["effects", "lectrazine", "side", "worried"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "hi. don't worry, the potential side effects of lectrazine are usually mild and go away on their own. however, if you experience any serious side effects, please contact your doctor immediately.",
	//                   highLights: ["effects", "lectrazine", "side"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "ok, thank you.",
	//                   highLights: [],
	//                   dialogueNo: 3,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 7,
	//               name: "Transcript from 04 May 2022 [15:19:30]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Neutral"],
	//               dialogueNo: 3,
	//               callId: "R0NENK69XQCQ8VFH",
	//               callDate: "2022-05-04",
	//               callTime: "15:19:30",
	//               dialogueAppearedAt: 9.2,
	//               dialogueLength: 5.52,
	//               transcriptLength: 90.62,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "hi, i'm worried about the side effects of lectrazine.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "hi, what are you worried about specifically ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i've heard that it can cause weight gain and hair loss.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "well, those are potential side effects, but they're not necessarily common. many people taking lectrazine don't experience any side effects at all.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "but what if i do experience them ?",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/R0NENK69XQCQ8VFH.json",
	//               elasticSearchId: "R0NENK69XQCQ8VFH_3",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "hi, i'm worried about the side effects of lectrazine.",
	//                   highLights: ["effects", "lectrazine", "side", "worried"],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "hi, what are you worried about specifically ?",
	//                   highLights: ["worried"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i've heard that it can cause weight gain and hair loss.",
	//                   highLights: ["cause"],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "well, those are potential side effects, but they're not necessarily common. many people taking lectrazine don't experience any side effects at all.",
	//                   highLights: ["effects", "lectrazine", "side"],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "but what if i do experience them ?",
	//                   highLights: [],
	//                   dialogueNo: 5,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//           ],
	//           emotionsCounts: [
	//             {
	//               text: "headaches",
	//               value: 5711,
	//             },
	//             {
	//               text: "slight headache",
	//               value: 1130,
	//             },
	//           ],
	//           headers: {
	//             "x-time": {
	//               query_expansion_time: 0.23832941055297852,
	//               retriever_time: 0.645418643951416,
	//               ranker_time: 0.7286033630371094,
	//               df_prepare_time: 0.008182287216186523,
	//               process_time: 1.665311574935913,
	//             },
	//             "x-search-config": {
	//               query_type: "keyword",
	//               expanded_query:
	//                 "[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
	//             },
	//             "x-auth-time": "0.3819572925567627",
	//             "x-process-time": "2.050896167755127",
	//           },
	//         },
	//         {
	//           topicId: 3,
	//           topicName: "covered medication",
	//           numberOfSearchResults: 3,
	//           searchResults: [
	//             {
	//               searchId: 1,
	//               name: "Transcript from 25 May 2022 [15:30:20]",
	//               tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Positive"],
	//               dialogueNo: 4,
	//               callId: "F1T9KO8WBYD609GF",
	//               callDate: "2022-05-25",
	//               callTime: "15:30:20",
	//               dialogueAppearedAt: 16.1,
	//               dialogueLength: 11.5,
	//               transcriptLength: 119.14,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 2,
	//                   text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i'm sorry to hear that. can you tell me what happened ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "well, let's see if we can figure out what's going on. can you give me your insurance information ?",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 6,
	//                   text: "sure.",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
	//               elasticSearchId: "F1T9KO8WBYD609GF_4",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
	//                   highLights: [],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "i'm sorry to hear that. can you tell me what happened ?",
	//                   highLights: [],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
	//                   highLights: ["medication"],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "well, let's see if we can figure out what's going on. can you give me your insurance information ?",
	//                   highLights: [],
	//                   dialogueNo: 5,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "sure.",
	//                   highLights: [],
	//                   dialogueNo: 6,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 2,
	//               name: "Transcript from 12 May 2022 [15:40:06]",
	//               tags: ["Common Side Effects", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 3,
	//               callId: "JP6WSQ3VO38RD2YK",
	//               callDate: "2022-05-12",
	//               callTime: "15:40:06",
	//               dialogueAppearedAt: 36.8,
	//               dialogueLength: 9.2,
	//               transcriptLength: 64.4,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 1,
	//                   text: "i'm really worried about the potential adverse effects of lectrazine. i've heard that it can cause serious problems like heart attacks and strokes. is this true ?",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 2,
	//                   text: "there is always the potential for adverse effects with any medication, but the risk is usually very low with lectrazine. serious problems like heart attacks and strokes are very rare. the most common side effects of lectrazine are mild and include headache, nausea, and dizziness.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 3,
	//                   text: "i'm still really worried about taking this medication. are there any other risks i should be aware of ?",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 4,
	//                   text: "there are always risks associated with taking any medication, but the risks with lectrazine are very low. the most important thing is that you take the medication as directed and follow up with your doctor as recommended.",
	//                   speaker: "Agent",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/JP6WSQ3VO38RD2YK.json",
	//               elasticSearchId: "JP6WSQ3VO38RD2YK_3",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm really worried about the potential adverse effects of lectrazine. i've heard that it can cause serious problems like heart attacks and strokes. is this true ?",
	//                   highLights: [],
	//                   dialogueNo: 1,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "there is always the potential for adverse effects with any medication, but the risk is usually very low with lectrazine. serious problems like heart attacks and strokes are very rare. the most common side effects of lectrazine are mild and include headache, nausea, and dizziness.",
	//                   highLights: ["medication"],
	//                   dialogueNo: 2,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i'm still really worried about taking this medication. are there any other risks i should be aware of ?",
	//                   highLights: ["medication"],
	//                   dialogueNo: 3,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "there are always risks associated with taking any medication, but the risks with lectrazine are very low. the most important thing is that you take the medication as directed and follow up with your doctor as recommended.",
	//                   highLights: ["medication"],
	//                   dialogueNo: 4,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//             {
	//               searchId: 3,
	//               name: "Transcript from 25 May 2022 [20:15:09]",
	//               tags: ["Others", "Outbound", "UNKNOWN", "Negative"],
	//               dialogueNo: 6,
	//               callId: "O1388T9ZZ5BCLGTD",
	//               callDate: "2022-05-25",
	//               callTime: "20:15:09",
	//               dialogueAppearedAt: 39.1,
	//               dialogueLength: 9.2,
	//               transcriptLength: 75.44,
	//               patientId: -1,
	//               speaker: "Patient",
	//               storiesAdded: [],
	//               addedToStory: "false",
	//               context: [
	//                 {
	//                   dialogueNo: 4,
	//                   text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 5,
	//                   text: "that doesn't sound right. let me see if i can help you figure this out.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 6,
	//                   text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
	//                   speaker: "Patient",
	//                 },
	//                 {
	//                   dialogueNo: 7,
	//                   text: "well, sometimes insurance companies make decisions that don't make a lot of sense. but we can usually appeal those decisions.",
	//                   speaker: "Agent",
	//                 },
	//                 {
	//                   dialogueNo: 8,
	//                   text: "really ?",
	//                   speaker: "Patient",
	//                 },
	//               ],
	//               s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
	//               elasticSearchId: "O1388T9ZZ5BCLGTD_6",
	//               transcripts: [
	//                 {
	//                   speaker: "Patient",
	//                   text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
	//                   highLights: [],
	//                   dialogueNo: 4,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "that doesn't sound right. let me see if i can help you figure this out.",
	//                   highLights: [],
	//                   dialogueNo: 5,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
	//                   highLights: ["medication"],
	//                   dialogueNo: 6,
	//                 },
	//                 {
	//                   speaker: "Agent",
	//                   text: "well, sometimes insurance companies make decisions that don't make a lot of sense. but we can usually appeal those decisions.",
	//                   highLights: [],
	//                   dialogueNo: 7,
	//                 },
	//                 {
	//                   speaker: "Patient",
	//                   text: "really ?",
	//                   highLights: [],
	//                   dialogueNo: 8,
	//                 },
	//               ],
	//               callTypeId: 2,
	//               therapyStatusId: -1,
	//             },
	//           ],
	//           emotionsCounts: [
	//             {
	//               text: "headaches",
	//               value: 5711,
	//             },
	//             {
	//               text: "slight headache",
	//               value: 1130,
	//             },
	//           ],
	//           headers: {
	//             "x-time": {
	//               query_expansion_time: 0.23832941055297852,
	//               retriever_time: 0.645418643951416,
	//               ranker_time: 0.7286033630371094,
	//               df_prepare_time: 0.008182287216186523,
	//               process_time: 1.665311574935913,
	//             },
	//             "x-search-config": {
	//               query_type: "keyword",
	//               expanded_query:
	//                 "[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]",
	//             },
	//             "x-auth-time": "0.3819572925567627",
	//             "x-process-time": "2.050896167755127",
	//           },
	//         },
	//       ],
	//     },
	//   },
	// ];

export function NodeGraphFilters(props: {
	showDrawer: () => void;
	fetchTranscripts: (queries: searchQuery) => void;
	searchWordRequired: boolean;
	searchString?: any;
	setSearchString?: any;
	path?: any;
	setPath?: any;
	searchFilterData: any;
	setSearchFilterData: any;
	onSave: any;
}) {
	const { RangePicker } = DatePicker;
	const context = useContext(AppContext);
	// var filterData = {
	//   startDate: "",
	//   endDate: "",
	//   speaker: "",
	//   searchWord: "",
	// };
	var dateFormat = "MMM YYYY";
	const newDateFormat = "DD/MM/YYYY";
	// const [searchFilterData, setSearchFilterData] = useState<searchQuery>(filterData);
	const [dateRanges, setDateRanges] = React.useState<Array<any>>([]);
	const [loadingState, setloadingState] = React.useState(false);
	const [modalVisible, setModalVisible] = React.useState(false);
	const [fetchedData, setFetchedData] = React.useState<any>(null);

	async function fetchDataForPage() {
		// add s3 parameter value
		// const data: any = await NodeGraphServiceHelper.getNodeJsons(
		//   "kural/json_data/nodegraph_experiment_list.txt"
		// );
		const data: any = await NodeGraphServiceHelper.getExperiments();
		if (data) {
			setFetchedData(data);
			// console.log("CHECK HERE", fetchedData); Commenting
			// console.log(data[0]["experimentId"]); Commenting
			context.setExpIdForNodeGraphHere(data[0]["experimentId"]);
		}
	}

	React.useEffect(() => {
		fetchDataForPage();
	}, []);

	const handleFilters = async (
		filterType: string,
		filterValue: string | boolean
	) => {
		var filters: any = props.searchFilterData;

		if (filterType == "startDate" || filterType == "endDate") {
			filters[filterType] = filterValue;
			// console.log(filters[filterType]); Commenting out
		} else {
			filters[filterType] = filterValue;
		}

		props.setSearchFilterData(filters);

		// props.setPath(filterValue);
	};

	// async function onSave()
	// {
	//   let data: any;
	//   var filters: any = searchFilterData;

	//   if (filters == undefined) {
	//     data = await ExploreServiceHelper.getExploreCardsData(
	//       1,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null,
	//       null
	//     );
	//   } else {
	//     console.log(filters);
	//     data = await ExploreServiceHelper.getExploreCardsData(
	//       filters.experimentId == "" ? null : filters.experimentId,
	//       filters.startDate == "" ? null : filters.startDate,
	//       filters.endDate == "" ? null : filters.endDate,
	//       filters.speaker !== undefined ? filters.speaker : null,
	//       filters.topics&&filters.topics[0] !== undefined ? filters.topics[0] : null,
	//       filters.totStart !== undefined ? filters.totStart : null,
	//       filters.totEnd !== undefined ? filters.totEnd : null,
	//       filters.callTypeIds&&filters.callTypeIds[0] !== undefined ? filters.callTypeIds[0] : null,
	//       filters.therapyStatusIds&&filters.therapyStatusIds[0] !== undefined ? filters.therapyStatusIds[0] : null,
	//       filters.sentiment&&filters.sentiment[0] !== undefined ? filters.sentiment[0] : null,
	//       filters.emotions&&filters.emotions.length > 0 ? filters.emotions : null
	//     );
	//   }
	//   if (data) {
	//     console.log(data);
	//     // setResponse(data);
	//   }
	// }
	const disableSelectedDates = (current: any) => {
		var c = current;
		//console.log(current);
		return (
			current < moment(context.startDate, dateFormat) ||
			current > moment(context.endDate, dateFormat).endOf("month") ||
			(dateRanges &&
				dateRanges[0] &&
				current.diff(dateRanges[0], "months") > 10) ||
			(dateRanges &&
				dateRanges[1] &&
				dateRanges[1].diff(current, "months") > 10)
		);
	};

	function ModalContent() {
		const [deepSearch, setDeepSearch] = React.useState(
			props.searchFilterData["deepSearch"]
		);
		const [testButton1, setTestButton1] = React.useState(
			props.searchFilterData["testButton1"]
		);
		const [testButton2, setTestButton2] = React.useState(
			props.searchFilterData["testButton2"]
		);
		const [testButton3, setTestButton3] = React.useState(
			props.searchFilterData["testButton3"]
		);
		const [testButton4, setTestButton4] = React.useState(
			props.searchFilterData["testButton4"]
		);
		const [testButton5, setTestButton5] = React.useState(
			props.searchFilterData["testButton5"]
		);

		return (
			<>
				{/* <Checkbox
					onChange={(e) => {
						handleFilters("deepSearch", e.target.checked);
					}}
				>
					Deep Search
				</Checkbox> */}
				<div className="text-2xl text-text-gray-100 font-normal my-4">
					Search Settings
				</div>
				<div className="text-base text-text-gray-50 my-6">
					Please select the search settings.
				</div>
				<div className="w-full ">
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">
							Enable Deep Search
						</div>

						<Switch
							onChange={(checked) => {
								setDeepSearch(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={deepSearch}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">
							Test Button 1
						</div>

						<Switch
							onChange={(checked) => {
								setTestButton1(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton1}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">
							Test Button 2
						</div>

						<Switch
							onChange={(checked) => {
								setTestButton2(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton2}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">
							Test Button 3
						</div>

						<Switch
							onChange={(checked) => {
								setTestButton3(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton3}
						/>
					</div>

					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">
							Test Button 4
						</div>

						<Switch
							onChange={(checked) => {
								setTestButton4(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton4}
						/>
					</div>
					<div className="flex align-middle justify-between px-8 py-2">
						<div className="font-base text-text-gray-90 font-semibold">
							Test Button 5
						</div>

						<Switch
							onChange={(checked) => {
								setTestButton5(checked);
							}}
							checkedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CheckOutlined />
								</div>
							}
							unCheckedChildren={
								<div
									style={{
										position: "absolute",
										top: -2,
									}}>
									<CloseOutlined />
								</div>
							}
							defaultChecked={testButton5}
						/>
					</div>
					<div className="flex justify-end pt-4">
						<button
							type="button"
							className="bg-text-teal text-white text-base border-text-teal border-2 px-4 py-2"
							onClick={() => {
								if (deepSearch !== undefined)
									handleFilters("deepSearch", deepSearch);
								if (testButton1 !== undefined)
									handleFilters("testButton1", testButton1);
								if (testButton2 !== undefined)
									handleFilters("testButton2", testButton2);
								if (testButton3 !== undefined)
									handleFilters("testButton3", testButton3);
								if (testButton4 !== undefined)
									handleFilters("testButton4", testButton4);
								if (testButton5 !== undefined)
									handleFilters("testButton5", testButton5);
								setModalVisible(false);
							}}>
							Save Settings
						</button>
					</div>
				</div>
			</>
		);
	}

	const onClickSearch = () => {
		Messages.loading("Fetching Clusters", "updatable");
		props.fetchTranscripts(props.searchFilterData);
		// console.log(props.searchFilterData); //Commenting out
		props.setSearchString(props.searchFilterData.searchWord);
		props.onSave(props.searchFilterData);
	};
	return (
		<>
			{fetchedData && (
				<div>
					<Modal
						// title="Search Settings"
						open={modalVisible}
						onOk={() => setModalVisible(false)}
						onCancel={() => setModalVisible(false)}
						footer={null}>
						<ModalContent />
					</Modal>
					<div className="flex justify-between items-center pb-4">
						<div className="flex align-middle">
							<div className="flex items-center mr-8">
								<span className="mr-2">Speaker Type:</span>
								<Select
									clearIcon={
										icons.find((f) => f.id === "FormClear")
											?.icon
									}
									className="w-32"
									placeholder="Speaker Type"
									allowClear
									onChange={(value: string) => {
										handleFilters("speaker", value);
									}}>
									<Select.Option value="Agent">
										Agent
									</Select.Option>
									<Select.Option value="Patient">
										Patient
									</Select.Option>
								</Select>
							</div>
							<div className="flex items-center mr-8">
								<span className="mr-2">Experiment</span>
								<Select
									clearIcon={
										icons.find((f) => f.id === "FormClear")
											?.icon
									}
									className="w-32"
									placeholder="Experiment"
									allowClear
									defaultActiveFirstOption={true}
									// defaultValue={context.expIdForNodeGraph}
									defaultValue={
										fetchedData[0]["experimentName"]
									}
									onChange={(value: string) => {
										handleFilters("experimentId", value);
									}}>
									{fetchedData.map((d: any) => (
										<Select.Option
											value={d["experimentId"]}>
											{d["experimentName"]}
										</Select.Option>
									))}
									{/* <Select.Option value="York Good Food">York Good Food</Select.Option>
              <Select.Option value="Symptoms pml Weakness">Symptoms pml Weakness</Select.Option>
              <Select.Option value="Diarrhea Constantly">Diarrhea Constantly</Select.Option> */}
								</Select>
							</div>
							<div className="flex items-center mr-8">
								<span className="mr-2">Time Period:</span>
								{context.envId === 3 ? (
									<RangePicker
									className=""
									format={newDateFormat}
									// defaultPickerValue={[moment(context.startDate, "DD MMM YYYY"),moment(context.endDate, "DD MMM YYYY"),]}
									disabledDate={disableSelectedDates}
									onChange={(e: any) => {
										if (e === null) {
											handleFilters("startDate", "");
											handleFilters("endDate", "");
										} else {
											handleFilters("startDate", moment(e[0]).format("DD MMM YYYY"));
											handleFilters("endDate", moment(e[1]).format("DD MMM YYYY"));
										}
									}}
									allowClear
								/>
								) : 
								(
								<RangePicker
									className=""
									clearIcon={
										icons.find((f) => f.id === "FormClear")
											?.icon
									}
									disabledDate={disableSelectedDates}
									defaultPickerValue={[
										moment(context.startDate, "DD MMM YYYY"),
										moment(context.endDate, "DD MMM YYYY"),
									]}
									format={dateFormat}
									separator={
										icons.find((l) => l.id === "Swap")?.icon
									}
									onChange={(e: any) => {
										if(e === null) {
											handleFilters("startDate", "");
											handleFilters("endDate","");
										}
										else{
											handleFilters("startDate",moment(e[0]).format("MMM YYYY"));
											handleFilters("endDate",moment(e[1]).format("MMM YYYY"));
										}
									}}
									onCalendarChange={(value: any) => {
										setDateRanges(value);
									}}
									picker="month"
								/>
								)}
							</div>
							{/* <div className={props.searchWordRequired ? "" : "flex items-center"}>
                {props.searchWordRequired ? (
                  <div style={{ marginTop: "-20px" }}>
                    <p className="text-sm text-text-red">Keyword is Required</p>
                  </div>
                ) : null}
                <Input
                  className="flex input-search lg:w-96 md:w-48"
                  placeholder="Search for transcript dialogues with keywords and tags"
                  onChange={(e) => {
                    handleFilters("searchWord", e.target.value);
                  }}
                  onKeyDown={(e: any) => {
                    if (e.key === "Enter") {
                      props.fetchTranscripts(searchFilterData);
                      props.setSearchString(searchFilterData.searchWord);
                    }
                  }}
                />
              </div> */}
							<div className="flex items-center ml-5">
								<Button
									type="primary"
									loading={loadingState}
									className="green-button flex items-center"
									onClick={() => onClickSearch()}>
									Search
								</Button>
							</div>

							<div>
								<div
									className="flex items-center cursor-pointer w-44 ml-6 mt-1"
									onClick={() => {
										props.showDrawer();
									}}>
									{/* <div className="mr-2">{icons.find((l) => l.id === "FilterGreen")?.icon}</div>
                  <div style={{ color: "#27A6A4" }} className="flex text-base">
                    Advanced Filters
                  </div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

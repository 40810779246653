import { request } from "./../App";

export const getAllStudies = async () => {
  const fetchUrl = "study/GetAllStudies";
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getStudyTypeData = async () => {
  const fetchUrl = "study/GetStudyTypeData";
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getGeographyData = async () => {
  const fetchUrl = "study/GetGeography";
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getStudyDetailsData = async (studyId: number) => {
  const fetchUrl = "Study/GetStudyDetails?studyId=" + studyId;
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const addNewStudyData = async (study: { studyTypeId: number; studyName: string; studyTypeName: string; studyDescription: string }) => {
  const fetchUrl = "study/AddNewStudy";
  const data = await request
    .post(fetchUrl, study, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const addNewWaveRowData = async (wave: {
  waveId: number;
  waveName: string;
  studyId: number;
  geoData: {
    studyIterId: number;
    geoId: number;
    geoName: string;
    start_dt: string;
    end_dt: string;
  }[];
}) => {
  const postUrl = "study/AddNewWave";
  const data = await request
    .post(postUrl, wave, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const deleteWaveRowData = async (oDeleteWave: { waveId: number; studyIterIds: number[] }) => {
  const postUrl = "study/DeleteWave";
  const data = await request
    .post(postUrl, oDeleteWave, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const deleteStudyData = async (studyId: number) => {
  const postUrl = "study/DeleteStudy?studyId=" + studyId;
  const data = await request
    .post(postUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const deleteUploadedGuideFile = async (guideId: number) => {
  const postUrl = "study/DeleteUploadedGuide?guideId=" + guideId;
  const data = await request
    .post(postUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const deleteUploadedTranscriptFile = async (trId: number) => {
  const postUrl = "study/DeleteUploadedTranscript?trId=" + trId;
  const data = await request
    .post(postUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

// export const s3UploadData = async (dgData: {
//   studyIterId: number;
//   dgId: number;
//   dgS3Path: string;
// }, file: IForm) => {
// 	const postUrl = "study/UploadToS3";
// 	const data = await request
// 		.post(postUrl, {dgData, file}, { withCredentials: true })
// 		// .post(postUrl, dgData, file, { withCredentials: true })
// 		.then((response) => {
// 			// console.log(response.status);
// 			return response.data;
// 		})
// 		.catch((err) => {
// 			// console.log("Error : ", err.message);
// 			return null;
// 		});
// 	return data;
// };

export const getTranscriptDetailsForStudyData = async (studyIterId: number) => {
  const fetchUrl = "study/GetTranscriptDetails?studyIterId=" + studyIterId;
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const triggerNodeGraphData = async (studyIterId: number) => {
  const postUrl = "study/TriggerNodeGraph?studyIterId=" + studyIterId;
  const data = await request
    .post(postUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const downloadS3FileData = async (filePath: string) => {
  const getUrl = "study/DownloadFromS3?filePath=" + filePath;
  const data = await request
    .get(getUrl, { withCredentials: true, responseType: "blob" })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getTaxonomyData = async (studyId: number) => {
  const fetchUrl = "Study/GetTaxonomybyStudyID?study_id=" + studyId;
  const data = await request
    .get(fetchUrl, {
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const saveTaxonomyData = async (configureTaxonomy: { study_id: number; s3_path: string; taxonomyJson: any[] }) => {
  const postUrl = "study/SaveTaxonomytoS3";
  const data = await request
    .post(postUrl, configureTaxonomy, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const getNotificationMessagesData = async () => {
  const fetchUrl = "Study/GetNotificationMessages";
  const data = await request
    .get(fetchUrl, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return data;
};

export const deleteNotificationData = async (ids: number[]) => {
  const fetchUrl = `Study/DeleteNotification?ids=${ids.join(",")}`;
  const response = await request
    .post(fetchUrl, ids, { withCredentials: true })
    .then((response) => {
      // console.log(response.status);
      return response.data;
    })
    .catch((err) => {
      // console.log("Error : ", err.message);
      return null;
    });
  return response;
};

export const downloadMetaFileData = async (studyId: number) => {
  const getUrl = `Study/DownloadMetadataFromS3?studyId=${studyId}`;
  
  try {
    const response = await request.get(getUrl, { withCredentials: true, responseType: "blob" });
    return response.data;
  } catch (err: any) {
    console.error("Error : ", err.message);
    return null;
  }
};
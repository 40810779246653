import { Divider, Skeleton } from "antd";

export default function SkeletonCard() {
	return (
		<div className="bg-white shadow-md shadow-gray-900 p-4 w-full">
			<Skeleton paragraph={{ rows: 1 }} title={false} active />
			<div className="w-64 my-4">
				<Skeleton.Button active size="default" block={true} />
			</div>
			<div className="w-64 my-8">
				<Skeleton.Input
					style={{ width: 400, height: 300 }}
					active
					size="default"
				/>
			</div>
		</div>
	);
}

export function SkeletonFull() {
	return (
		<div className="bg-white shadow-md shadow-gray-900 p-4 w-full">
			<Skeleton.Input
				className="width-full"
				style={{ height: "43vh" }}
				active
			/>
		</div>
	);
}

export function SkeletonJourney() {
	return (
		<div className="bg-white shadow-md shadow-gray-900 p-4 w-full">
			<div className="mb-4 w-full">
				<div className="flex w-full">
					<div className="w-1/5 m-2">
						<Skeleton.Input
							className="width-full"
							active
							style={{ height: "43vh" }}
						/>
					</div>
					<div className="w-1/5 m-2 h-full">
						<Skeleton.Input
							className="width-full"
							active
							style={{ height: "43vh" }}
						/>
					</div>
					<div className="w-1/5 m-2">
						<Skeleton.Input
							className="width-full"
							active
							style={{ height: "43vh" }}
						/>
					</div>
					<div className="w-1/5 m-2">
						<Skeleton.Input
							className="width-full"
							active
							style={{ height: "43vh" }}
						/>
					</div>
					<div className="w-1/5 m-2">
						<Skeleton.Input
							className="width-full"
							active
							style={{ height: "43vh" }}
						/>
					</div>
				</div>
			</div>
			<div className="w-full">
				<Skeleton.Input
					className="width-full"
					active
					style={{ height: "20vh" }}
				/>
			</div>
		</div>
	);
}

export function SkeletonSentiment() {
	return (
		<div className="bg-white shadow-md shadow-gray-900 p-4 w-full">
			<div className="grid lg:grid-cols-2 sm:grid-cols-1 md:grid-cols-1 gap-6">
				<div>
					<Skeleton.Input className="width-full" active />
				</div>
			</div>
		</div>
	);
}

export function SkeletonDetail() {
	return (
		<div className="mt-5">
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
			<br />
			<Divider className="my-3" />
			<Skeleton.Input style={{ width: 400, height: 25 }} active />
		</div>
	);
}

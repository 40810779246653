import { Modal, Input } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AddStoryModalContent from "../../Components/AddStoryModal";
import OldStories from "../SearchEngine/OldStories/index";
import StoryMode from "../SearchEngine/OldStories/storyMode";
import "../SearchEngine/style.css";
import * as OldStoriesServiceHelper from "../SearchEngine/ServiceHelpers/oldStories";
import * as Messages from "../../Components/Messages";
// import { OldStoriesViewDummyData } from "../SearchEngine/data";
// import DeleteAlert from "../../Components/DeleteAlert";

/**
 * Renders the Stories Table. This function also handles the APIs for this table.
 *
 * @returns Stories Table View.
 */
function OldStoriesTable(): JSX.Element {
  const [fetching, setFetching] = React.useState(true);
  const [fetchedData, setFetchedData] = React.useState<
    {
      [id: string]: string | number | Date;
    }[]
  >([]);
  const [originalData, setOriginalData] = React.useState<
    {
      [id: string]: string | number | Date;
    }[]
  >([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(<></>);
  // const [filteredData, setFilteredData] = React.useState([]);
  // const [alertVisible, setAlertVisible] = React.useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function showModal() {
    setIsModalVisible(true);
    setModalContent(<AddStoryModalContent handleCancel={handleCancel} onSave={handleAddApi} tagName={"Story"} data={fetchedData}/>);
  }
  function handleEdit(storyId: number) {
    setIsModalVisible(true);
    setModalContent(<AddStoryModalContent handleCancel={handleCancel} editData={fetchedData.find((story) => story.id === storyId)} onSave={handleEditApi} tagName={"Story"} data={fetchedData}/>);
  }

  function handleEditApi(editData: any) {
    setIsModalVisible(false);
    const temp = [...fetchedData];
    const idx = fetchedData.findIndex((story) => story.id === editData.id);

    if (idx !== -1) {
      const editDataTemp = {
        id: String(temp[idx].id),
        StoryName: String(editData["StoryName"]),
      };
      editStory(editDataTemp);
      temp[idx]["storyName"] = "Updating...";
      setFetchedData(temp);
    }
  }

  function handleAddApi(addData: any) {
    setIsModalVisible(false);
    if (addData) {
      addNewStory(addData);
      setTimeout(() => {
        fetchdata();
      }, 1000);
    }
  }

  async function editStory(editData: { id: string; StoryName: string }) {
    const key = "updatable";
    Messages.loading("Updating Story", key);
    const data: any = await OldStoriesServiceHelper.edtStory(editData);
    if (data) {
      const temp = [...fetchedData];
      const searchId = parseInt(editData.id);
      const idx = fetchedData.findIndex((story) => story.id === searchId);
      temp[idx]["storyName"] = data["storyName"];
      // temp[idx]["Story Type"] = data["Story Type"];
      setFetchedData(temp);
      Messages.success("Story Updated Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function deleteStory(storyId: number) {
    const key = "updatable";
    Messages.loading("Deleting Story", key);
    const data: any = await OldStoriesServiceHelper.deleteStory(storyId);
    if (data === 200) {
      const temp = [...fetchedData];
      const idx = temp.findIndex((d) => d.id === storyId);
      temp.splice(idx, 1);
      setFetchedData(temp);
      Messages.success("Story Deleted Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function addNewStory(addData: { StoryName: string }) {
    //    console.log(addData);
    const key = "updatable";
    Messages.loading("Adding Story", key);
    const data: any = await OldStoriesServiceHelper.addNewStory(addData);
    if (data) {
      const temp = [data, ...fetchedData];
      // temp.push(data);
      setFetchedData(temp);
      Messages.success("Story Added Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }

  const onSearch = (word: any) => {
    if (word !== "") {
      setFetchedData((prevData) => originalData.filter((item) => item["storyName"].toString().toLowerCase().includes(word.target.value.toLowerCase())));
    } else {
      // setFetchedData(DUMMY_DATA);
      // fetchdata();
      setFetchedData(originalData);
    }
  };

  async function fetchdata() {
    const data: any = await OldStoriesServiceHelper.getAllStories();
    // const data = DUMMY_DATA;
    if (data) {
      setFetchedData(data);
      setOriginalData(data);
      setFetching(false);
    }
  }

  // console.log(fetchedStoryData);

  React.useEffect(() => {
    fetchdata();
    // setFetchedData(OldStoriesViewDummyData);
  }, []);
  return (
    <>
      {!fetching ? (
        <div>
          <div className="flex shadow-lg relative p-4 pl-11">
            <Input.Search className="w-7/12 input-search" placeholder="Search Story Name" enterButton onChange={onSearch} />
          </div>
          <div className="stories-body bg-body p-4">
            <Modal title={null} open={isModalVisible} centered onOk={handleOk} onCancel={handleCancel} footer={null} destroyOnClose={true}>
              {modalContent}
            </Modal>
            <OldStories
              data={{ stories: fetchedData }}
              deleteStory={deleteStory}
              addNewStory={showModal}
              editStory={handleEdit}
              // handleSort={handleSort}
            />
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="flex shadow-lg relative p-4 pl-11">
              <Input.Search className="w-7/12 input-search" placeholder="Search Story Name" enterButton onChange={onSearch} />
            </div>
            <div className="stories-body bg-body p-4">
              <OldStories data={{}} loading={true} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
/**
 * Renders the Stories Tab View. This function sets up the routes within the Stories Tab.
 *
 * @returns Stories Tab View Routes.
 */
export function OldStoriesView() {
  return (
    <>
      <Routes>
        <Route key={"Stories 0"} path="/" element={<OldStoriesTable />} />
        <Route key="Stories - Stories Mode" path="story-mode/:storyId/*" element={<StoryMode />} />
      </Routes>
    </>
  );
}

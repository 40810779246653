import { ResponsiveChoropleth } from "@nivo/geo";
import { Header } from "../../../Components/ReportCards/CommonCardComponents/Header";
// import { usData } from "../../../libraries/report-types/charts/geoMap/dataUS"
import { usFeatures } from "../../../libraries/report-types/charts/geoMap/geoUSFeatures";

/**
 * This card is used to render the map
 *
 * @remarks - This card is a part of KPIs tab
 *
 * @returns the map with heading and labels
 */

export function CallsByState(props: { data: any }) {
	return (
		<div className="p-4">
			<Header iconName="Roadmap" header="# of Calls by State" />
			<div
				className="mt-4 h-64 overflow-hidden"
				style={{ overflow: "hidden" }}
			>
				<div style={{ height: "476px" }}>
					<ResponsiveChoropleth
						data={props.data.chartData}
						features={usFeatures.features}
						margin={{
							top: 0,
							right: 0,
							bottom: 0,
							left: 0,
						}}
						colors={["#D8ECFB", "#99D3FD", "#3B9FE7", "#2A71A4"]}
						domain={[
							0,
							Math.max.apply(
								Math,
								props.data.chartData.map(function (o: any) {
									return o.value;
								})
							),
						]}
						unknownColor="#666666"
						label="properties.name"
						valueFormat=".2s"
						projectionScale={160}
						projectionTranslation={[0.4, 0.25]}
						projectionRotation={[0, 0, 0]}
						enableGraticule={false}
						graticuleLineColor="#dddddd"
						borderWidth={0.5}
						borderColor="#152538"
						legends={[
							{
								anchor: "right",
								direction: "column",
								justify: false,
								translateX: -40,
								translateY: -130,
								symbolShape: "circle",
								itemsSpacing: 0,
								itemWidth: 94,
								itemHeight: 25,
								itemDirection: "left-to-right",
								itemTextColor: "#444444",
								itemOpacity: 0.85,
								symbolSize: 18,
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
}

// TODO // import { Save } from "@styled-icons/fa-regular/Save";
import { Star } from "@styled-icons/fa-regular/Star";

export default function SaveIcon(props: { handleClick?: () => void }) {
	return (
		// TODO // <Save
		<Star
			title="Save Current Settings"
			size={24}
			onClick={() => {
				if (props.handleClick) props.handleClick();
			}}
			style={{ color: "#32A29B" }}
		/>
	);
}

import { Radio } from "antd";
import React from "react";
import { icons } from "../../../GlobalThemes";
import SentimentBreakdown from "./PatientJourney/SentimentBreakdown";
import UnmetNeedsAndQuestionTime from "./PatientJourney/UnmetNeedsAndQuestionTime";

/**
 * This page is the main page to navigate between sentiment breakdown and unmet needs
 * 
 * @param data - This data props will send data for both sentiment breakdown and unmet needs as an array
 *
 * @returns the navigation to navigate between different subtabs
 */

export function PatientJourney(props: { data: any }) {
	const [view, setView] = React.useState(1);
	const onChange = (e: any) => setView(e.target.value);
	return (
		<div className="bg-white w-full shadow-xl overflow-hidden ">
			<div className="flex gap-8 items-center px-4 pt-4">
				<div className="flex items-center gap-2 text-text-gray-90 text-2xl font-bold">
					{icons.find((l) => l.id === "Patient Journey")?.icon}
					Patient Journey
				</div>
				<Radio.Group
					defaultValue={"Unmet Needs"}
					onChange={onChange}
					value={view}
				>
					{[
						"Unmet Needs",
						"Sentiments",
					].map((element, index) => (
						<Radio value={index + 1} className="radio-custom">
							{element}
						</Radio>
					))}
				</Radio.Group>
			</div>
			<div>
				{view === 1 ? (
					<>
						<UnmetNeedsAndQuestionTime
							data={props.data.unmetNeedsAndQuestionTime}
						/>
					</>
				) : (
					<>
						<SentimentBreakdown
							data={props.data.sentimentBreakdown}
						/>
					</>
				)}
			</div>
		</div>
	);
}

import { FilterInterface } from "..";
import * as DashboardService from "../../../services/Dashboard";

// export const getProgramOverview: (
// 	filters: FilterInterface,
// 	filterState: any
// ) => Promise<unknown> = async (filters: FilterInterface, filterState: any) => {
// 	const data = await DashboardService.getProgramOverview(
// 		filters,
// 		filterState
// 	);
// 	console.log("api", data);
// 	const callsColors = [["#3B9FE7"], ["#3B9FE7"], ["#7232CF", "#E0C6FF"]];
// 	const reachColors = [
// 		["#3B9FE7"],
// 		[
// 			["#3B9FE7"],
// 			["#300C64", "#7232CF", "#BB86FF", "#E8D9FF"],
// 			["#3B9FE7", "#2c78d4", "#2557cc", "#1a32ba", "#1b11ab", "#140c8a"],
// 		],
// 	];
// 	const agentActvityColors = [["#3B9FE7"], ["#7232CF", "#E0C6FF"]];
// 	if (data === null || data === undefined) return null;
// 	var poData: any = data;
// 	data.calls.forEach((po: any, index: number) => {
// 		if (po.multiChartData.length > 0) {
// 			poData.calls[index].chartData = po.multiChartData;
// 		} else if (Object.keys(po.poChartData).length > 0) {
// 			poData.calls[index].chartData = [po.poChartData];
// 		} else if (Object.keys(po.singleChartData).length > 0) {
// 			poData.calls[index].chartData = [po.singleChartData];
// 		}
// 		poData.calls[index].chartColor = callsColors[index];
// 	});
// 	data.reach.forEach((po: any, index: number) => {
// 		console.log(po, Object.keys(po.poChartData).length);
// 		if (po.multiChartData.length > 0) {
// 			poData.reach[index].chartData = po.multiChartData;
// 		} else if (Object.keys(po.poChartData).length > 0) {
// 			poData.reach[index].chartData = [po.poChartData];
// 		} else if (Object.keys(po.singleChartData).length > 0) {
// 			poData.reach[index].chartData = [po.singleChartData];
// 		}
// 		poData.reach[index].chartColor = reachColors[index];
// 	});

// 	data.agentActivity.forEach((po: any, index: number) => {
// 		if (po.multiChartData.length > 0) {
// 			poData.agentActivity[index].chartData = po.multiChartData;
// 		} else if (Object.keys(po.poChartData).length > 0) {
// 			poData.agentActivity[index].chartData = [po.poChartData];
// 		} else if (po.complexChartData.length > 0) {
// 			poData.agentActivity[index].chartData = po.complexChartData;
// 		}
// 		poData.agentActivity[index].chartColor = agentActvityColors[index];
// 	});

// 	return poData;
// };

export const getProgramOverview: (
	subTab: string,
	filters: FilterInterface,
	filterState: any
) => Promise<unknown> = async (
	subTab: string,
	filters: FilterInterface,
	filterState: any
) => {
	const data = await DashboardService.getProgramOverview(
		subTab,
		filters,
		filterState
	);
	if (data === null || data === undefined) return null;
	if (data) {
		const callsColors = [["#3B9FE7"], ["#3B9FE7"], ["#7232CF", "#E0C6FF"]];
		const reachColors = [
			["#3B9FE7"],
			[
				["#3B9FE7"],
				["#300C64", "#7232CF", "#BB86FF", "#E8D9FF"],
				[
					"#3B9FE7",
					"#2c78d4",
					"#2557cc",
					"#1a32ba",
					"#1b11ab",
					"#140c8a",
				],
			],
		];
		const agentActvityColors = [["#3B9FE7"], ["#7232CF", "#E0C6FF"]];
		var poData: any = data;
		data.calls.forEach((po: any, index: number) => {
			if (po.multiChartData.length > 0) {
				poData.calls[index].chartData = po.multiChartData;
			} else if (Object.keys(po.poChartData).length > 0) {
				poData.calls[index].chartData = [po.poChartData];
			} else if (Object.keys(po.poChartDataDouble).length > 0) {
				poData.calls[index].chartData = [po.poChartDataDouble];
			} else if (Object.keys(po.singleChartData).length > 0) {
				poData.calls[index].chartData = [po.singleChartData];
			}
			else {
				poData.calls[index].chartData = [];
			}
			poData.calls[index].chartColor = callsColors[index];
		});
		data.reach.forEach((po: any, index: number) => {
			console.log(po, Object.keys(po.poChartData).length);
			if (po.multiChartData.length > 0) {
				poData.reach[index].chartData = po.multiChartData;
			} else if (Object.keys(po.poChartData).length > 0) {
				poData.reach[index].chartData = [po.poChartData];
			} else if (Object.keys(po.singleChartData).length > 0) {
				poData.reach[index].chartData = [po.singleChartData];
			}
			else {
				poData.reach[index].chartData = [];
			}
			poData.reach[index].chartColor = reachColors[index];
		});

		data.agentActivity.forEach((po: any, index: number) => {
			if (po.agentChartData.length > 0) {
				poData.agentActivity[index].chartData = po.agentChartData;
			} else if (Object.keys(po.poChartData).length > 0) {
				poData.agentActivity[index].chartData = [po.poChartData];
			} else if (po.complexChartData.length > 0) {
				poData.agentActivity[index].chartData = po.complexChartData;
			}
			else {
				poData.agentActivity[index].chartData = [];
			}
			poData.agentActivity[index].chartColor = agentActvityColors[index];
		});

		console.log("Return", poData);
		return poData;
	}
};

export const callVolumeRefresh: (tot: string) => Promise<unknown> = async (
	tot: string
) => {
	const data = await DashboardService.callVolumeRefresh(tot);
	if (data === null || data === undefined) return null;
	if (data) {
		data.chartData = data.multiChartData;
		data.chartColor = [
			["#3B9FE7"],
			["#300C64", "#7232CF", "#BB86FF", "#E8D9FF"],
			["#3B9FE7", "#2c78d4", "#2557cc", "#1a32ba", "#1b11ab", "#140c8a"],
		];
		return data;
	}
};

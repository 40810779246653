import { Button, Modal, Input, Checkbox } from "antd";
import React, { useState, useEffect } from "react";
import { icons } from "../../GlobalThemes";
import AddStoryModalContent from "../AddStoryModal";
import ReportTypes from "../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { transformTableData } from "../../Pages/Dashboard/Processing";
import "./index.css";
import * as StoriesServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/stories";
import * as SearchServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/search";
import * as Messages from "../../Components/Messages";
import * as OldStoriesServiceHelper from "../../Pages/SearchEngine/ServiceHelpers/oldStories";

/**
 * This function is used to render the Modal to Select a story
 *
 * @param handleCancel - This props is used to close the modal
 * @param onSave - This props is used to save the story data
 * @returns the modal to add serch to a story
 */

export default function SelectStoryModalContent(props: {
  data: any;
  gptSummaryData?: any;
  fetchStoryData: () => void;
  responseData: any;
  searchQuery: string;
  isModalOpen: boolean;
  setIsModalOpen: any;
  tagName?: string;
}) {
  // const temp = transformTableData(props.data);
  const [addToStory, setAddToStory] = useState<any>(props.data);
  const [pinStates, setPinStates] = useState<Array<any>>([]);
  const [addStates, setAddStates] = useState<Array<any>>([]);
  const [existingCount, setExistingCount] = useState<number>(0);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(<></>);
  const [isButtonEnabled, setIsButtonEnabled] = React.useState(false);
  const [fetchedData, setFetchedData] = React.useState<{ [id: string]: string | number | Date }[]>([]);
  const [fetching, setFetching] = React.useState(true);
  const [originalData, setOriginalData] = React.useState<{ [id: string]: string | number | Date }[]>([]);

  var grouped_columns = [
    // {
    //   Cell: (props: any) => {
    //     return (
    //       <div
    //         onClick={() => {
    //           var pins = [...pinStates];

    //           pins[props.row.original.index] = !pins[props.row.original.index];
    //           setPinStates(pins);
    //         }}
    //       >
    //         {
    //           icons.find(
    //             (l) =>
    //               l.id ===
    //               (pinStates[props.row.original.index]
    //                 ? "Pin Filled"
    //                 : "Pin Angle")
    //           )?.icon
    //         }
    //       </div>
    //     );
    //   },
    //   title: " ",
    //   accessor: "_",
    //   width: 20,
    // },
    {
      Cell: (props: any) => {
        var add = [...addStates];
        return (
          <Checkbox
            checked={addStates[props.row.original.index]}
            className="reach-check"
            onChange={(e) => {
              add[props.row.original.index] = !add[props.row.original.index];
              setAddStates(add);
              //setStoryChange(true);
              const allFalse = add.every((value) => value === false);
              setIsButtonEnabled(!allFalse);
            }}
          />
        );
      },
      title: " ",
      accessor: "add",
      width: 20,
    },
    {
      title: `${props.tagName} Name`,
      accessor: "storyName",
      width: 300,
    },
    {
      title: "Date Created",
      accessor: "dateCreated",
      sort: true,
      Cell: (props: any) => {
        const date = new Date(props.cell.value);
        const formattedDate = date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });
        return (
          <>
            <div style={{ textAlign: "left" }}>{formattedDate}</div>
          </>
        );
      },
    },
    {
      title: "# of Saved Searches",
      accessor: "noofSavedSearches",
      Cell: (props: any) => {
        return <div style={{ textAlign: "left" }}>{props.cell.value}</div>;
      },
    },
  ];

  const addStoryChecklist = (storyData: any) => {
    var table: any = storyData;
    var transcriptData: any = [];
    if (storyData && storyData.length > 0) {
      storyData.forEach((singleData: any, index: number) => {
        if (props.data.storiesAdded !== undefined && props.data.storiesAdded.length > 0) {
          if (props.data.storiesAdded.find((story: { storyId: any }, index: any) => story.storyId === singleData.id)) {
            transcriptData[index] = true;
            table[index]["index"] = index;
          } else {
            transcriptData[index] = false;
            table[index]["index"] = index;
          }
        } else {
          transcriptData[index] = false;
          table[index]["index"] = index;
        }
      });
    }
    setExistingCount(transcriptData.length);
    setAddStates(transcriptData);
  };

  useEffect(() => {
    setFetchedData(props.data);
    setOriginalData(props.data);
    addStoryChecklist(props.data);
    setAddToStory(props.data);
  }, [props.data]);

  function showNewStoryModal() {
    setIsModalVisible(true);
    setModalContent(
      <AddStoryModalContent handleCancel={handleCancel} onSave={handleAddApi} tagName={props.tagName} data={fetchedData}/>
    );
  }

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleParentCancel = () => {
    props.setIsModalOpen(false);
  };

  function handleAddApi(addData: any) {
    setIsModalVisible(false);
    if (addData) {
      addNewStory(addData);
      props.fetchStoryData();
    }
  }

  const handleAddToStory = async () => {
    try {
      const trueIndices = addStates.map((value: boolean, index: number) => (value ? index : -1)).filter((index) => index !== -1);
      const selectedStoryIds = trueIndices.map((index: number) => fetchedData[index].id);
      // console.log("selectedStoryIds: ",selectedStoryIds);

      const rData: any = {
        searchQuery: props.searchQuery ?? "",
        summaryJson: {
          ...props.responseData,
          gptSummary: props.gptSummaryData,
        },
        storyIds: selectedStoryIds,
      };

      Messages.loading("Adding...", "key1");
      const data: any = await SearchServiceHelper.addToStory(rData);

      if (data) {
        const responseStoryIds = ~~data === 0 ? data.split("|").map((id: string) => parseInt(id, 10)) : [parseInt(data, 10)];

        const successStoryIds: number[] = [];
        const failedStoryIds: number[] = [];

        selectedStoryIds.forEach((storyId: any) => {
          if (responseStoryIds?.includes(storyId)) {
            successStoryIds.push(storyId);
          } else {
            failedStoryIds.push(storyId);
          }
        });

        const successfullyDeployedStoryNames = successStoryIds.map((id) => {
          const story = fetchedData.find((data) => data.id === id);
          return story ? story.storyName : null;
        });

        const failedDeployedStoryNames = failedStoryIds.map((id) => {
          const story = fetchedData.find((data) => data.id === id);
          return story ? story.storyName : null;
        });

        // console.log("Successfully Deployed Story Names:", successfullyDeployedStoryNames.join(", "));
        // console.log("Failed Deployed Story Names:", failedDeployedStoryNames.join(", "));

        if (failedDeployedStoryNames.length > 0) {
          Messages.error("Failed to add story: " + failedDeployedStoryNames.join(", "), "key1");
        } else {
          Messages.success("Sucessfull added to story: " + successfullyDeployedStoryNames.join(", "), "key1");
        }

        props.setIsModalOpen(false);
        setIsButtonEnabled(false);
      } else {
        Messages.error("Failed to add story.", "key1");
      }
    } catch (error) {
      console.log("Error in saving story:", error);
    }
  };

  async function addNewStory(addData: { StoryName: string }) {
    //    console.log(addData);
    const key = "updatable";

    if (props.tagName === "Collection") {
      Messages.loading("Adding Collection", key);
      const data: any = await StoriesServiceHelper.addNewStory(addData);
      if (data) {
        const temp = [data, ...fetchedData];
        setFetchedData(temp);
        Messages.success("Story Added Successfully", key);
      } else {
        Messages.error("Failed!", key);
      }
    } else if (props.tagName === "Story") {
      Messages.loading("Adding Story", key);
      const data: any = await OldStoriesServiceHelper.addNewStory(addData);
      if (data) {
        const temp = [data, ...fetchedData];
        setFetchedData(temp);
        Messages.success("Story Added Successfully", key);
      } else {
        Messages.error("Failed!", key);
      }
    }
  }

  const onSearch = (word: any) => {
    if (word !== "") {
      setFetchedData((prevData) => originalData.filter((item) => item["storyName"].toString().toLowerCase().includes(word.target.value.toLowerCase())));
    } else {
      setFetchedData(originalData);
    }
  };

  return (
    <Modal
      title={null}
      open={props.isModalOpen}
      width={1000}
      bodyStyle={{
        height: "650px",
        // overflow: "auto",
      }}
      centered
      onCancel={handleParentCancel}
      footer={null}
      destroyOnClose={true}
    >
      <div>
        <div className="mx-4">
          <div className="text-2xl text-text-gray-100 font-normal my-4">Select {props.tagName}</div>
          {props.tagName === "Story" ? (
            <p className="text-base mb-6" style={{ color: "#9C9AA1" }}>
              You can add the transcript to a story by selecting an existing story from the list below or by creating a new story and adding it to the new story.
            </p>
          ) : (
            <p className="text-base mb-6" style={{ color: "#9C9AA1" }}>
              You can add the transcript to a collection by selecting an existing collection from the list below or by creating a new collection and adding it to the new
              collection.
            </p>
          )}
          <div className="flex mt-6">
            <div className="flex items-center">
              <p className="text-base font-semibold mr-4">
                Existing {props.tagName}: {addToStory.length}
              </p>
              <div>
                <Input.Search className="flex w-96 input-search" placeholder={`Search ${props.tagName} Name`} onChange={onSearch} />
              </div>
            </div>
            <div className="flex items-center ml-auto cursor-pointer">
              <Modal
                title={null}
                open={isModalVisible}
                bodyStyle={{
                  overflowY: "auto",
                }}
                centered
                onCancel={handleCancel}
                footer={null}
                destroyOnClose={true}
              >
                {modalContent}
              </Modal>
              <div
                className="flex items-center"
                onClick={() => {
                  showNewStoryModal();
                }}
              >
                <div className="mr-2">{icons.find((l) => l.id === "Plus Filled Green")?.icon}</div>
                <p className="font-semibold text-base" style={{ color: "#27A6A4" }}>
                  Create New {props.tagName}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4">
          <ReportTypes
            hideShadow
            flexContent="flex-start"
            hideRibbon
            minHeight="330px"
            chartType={ReportTypesComponentInterface.chartType.simpleTable}
            series={[]}
            chartConfig={{
              table: {
                tableData: {
                  column: grouped_columns,
                  data: transformTableData(fetchedData).data,
                },
                noPagination: true,
                noSearchField: true,
                maxHeight: 330,
              },
            }}
          />
        </div>
        <div className="flex justify-end">
          <Button type="default" className="rounded-sm flex items-center" onClick={handleParentCancel}>
            Cancel
          </Button>
          <Button type="primary" disabled={!isButtonEnabled} className="green-button mx-3 rounded-sm flex items-center" onClick={() => handleAddToStory()}>
            Add to {props.tagName}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export interface pieLegends {
        dim?: number;
        color?: string;
        name?: string;
}

/**
 * This is the legend card which is used to render legends in any chart separately
 * 
 * @remarks - This card is used in POCard to print the legends
 * 
 * @param legendData - This is an array of legend name and colors
 *
 * @returns the legend
 */

export function PieChartLegends(props: {
    legendData: Array<pieLegends>
}) {
    return (
        <div className="flex items-center">
			<div>
                {props.legendData.map(legend => 
                    <div className="flex items-center">
                        <div
                            className="w-4 h-4 mr-2 rounded-full"
                            style={{
                                backgroundColor: `${legend.color}`,
                            }}
                        >
                        </div>
                        <div>{legend.name}</div>
                    </div>
                )}
		    </div>
		</div>
    )
}
import { Slider, Tooltip } from "antd";
import "./style.css";
export default function ProgressBar(props: {
	start: number;
	total: number;
	duration: number;
}) {
	const changeSecondsFormat = (time: number) => {
		if (time < 60) return `${Math.floor(time)}s`;
		else {
			var m = Math.floor(time / 60);
			var s = Math.floor(time % 60);
			return `${m}m ${s}s`;
		}
	};
	function TooltipText(props: {
		start: number;
		total: number;
		duration: number;
	}) {
		return (
			<div>
				<p>Dialogue appeared at: {changeSecondsFormat(props.start)}</p>
				<p>Dialogue Length: {changeSecondsFormat(props.duration)}</p>
				<p>Transcript Length: {changeSecondsFormat(props.total)}</p>
			</div>
		);
	}
	return (
		<Tooltip
			title={
				<TooltipText
					start={props.start}
					total={props.total}
					duration={props.duration}
				/>
			}
		>
			<div className="customProgressItem">
				<Slider
					range
					value={[
						(props.start * 100) / props.total,
						((props.start + props.duration) * 100) / props.total,
					]}
					tooltipVisible={false}
				/>
			</div>
		</Tooltip>
	);
}

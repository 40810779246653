import React, { useEffect } from "react";
import { DatePicker, Radio, Select, Skeleton, Slider, Space } from "antd";
import moment from "moment";
import AppContext from "../../store";
import { icons } from "../../GlobalThemes";
import { getSearchEmployee } from "../../services/search";
import { searchQuery } from "../../Pages/SearchEngine/Search";

/**
 * This function is used to render the filters in tag form
 *
 * @param filterOptions - This props represent the array of filters with all the options
 * @param filters - This props represents the array of filter with name and value
 * @param setFilters - Ths props is used to change the filters on the page
 *
 * @returns the filters in card form
 */

const marks: { [id: number]: string } = {
	0: "0",
	16.6: "30",
	33.3: "60",
	50: "90",
	66.6: "180",
	83.3: "360",
	100: "360+",
	// 100: {
	//   style: {
	// 	color: '#f50',
	//   },
	//   label: <strong>100°C</strong>,
	// },
};
export default function FilterResultCard(props: {
	filterOptions: {
		filterName: string;
		type: string;
		options: string[];
	}[];
	filters: {
		filter: string;
		value: string;
	}[];
	setFilters: React.Dispatch<
		React.SetStateAction<
			{
				filter: string;
				value: string;
			}[]
		>
	>;
	loading?: boolean;
	fetchedFilters?: any;
	fetchTranscripts: (queries: searchQuery) => void;
}) {
	const { RangePicker } = DatePicker;
	const context = React.useContext(AppContext);
	const [radioValue, setRadioValue] = React.useState("All");
	const [selectValue, setSelectValue] = React.useState("");
	// const [dateSelected, setDateSelected] = React.useState({
	// 	startDate: "",
	// 	endDate: "",
	// });
	var filterData = {
		startDate: "",
		endDate: "",
		studyIds: -1,
		searchWord: "",
		metaDataIds: [],
		pptSearch: false,
	};
	const dateFormat = "MMM YYYY";
	const newDateFormat = "DD/MM/YYYY";
	const [searchFilterData, setSearchFilterData] = React.useState<searchQuery>(filterData);
	const [searchedItems, setSearchedItems] = React.useState<any[]>([]);
	const [dateRanges, setDateRanges] = React.useState<Array<any>>([]);
	// const [totRange, setTotRange] = React.useState<[number, number]>();
	const disableSelectedDates = (current: any) => {
		if (!searchedItems || searchedItems.length === 0) {
			// Handle the case where searchedItems is undefined or empty
			return false; // Or whatever default behavior you need
		}
		return current < moment(searchedItems[0].startDate, dateFormat) || current > moment(searchedItems[0].endDate, dateFormat).endOf("month");
	};

	const { Option } = Select;

	async function fetchSearchMetaData() {
		var data;
		let studyIds = searchFilterData["studyIds"];

		if (studyIds !== undefined && studyIds !== null) {
			if (Array.isArray(studyIds)) {
				if (studyIds.length > 0) {
					let studyIdsString = studyIds.join("|");
					data = await getSearchEmployee(studyIdsString);
				} else {
					data = await getSearchEmployee(-1);
				}
			} else {
				data = await getSearchEmployee(studyIds);
			}
		} else {
			data = await getSearchEmployee(-1);
		}

		if (data) {
			setSearchedItems(data);
		}
	}

	useEffect(() => {
		fetchSearchMetaData();
	}, []);

	const handleFilters = (filterType: string, filterValue: string | boolean | string[] | number) => {
		// Add regex check for special characters to filterValue
		const specialCharsRegex = /[#$%&^*@!~(){}[\]<>/\\]/;
		if (typeof filterValue === "string" && specialCharsRegex.test(filterValue)) {
			// setErrorMessage("Special characters not allowed");
			console.log("Special characters not allowed");
			return;
		}
		// else {
		// 	// setErrorMessage("");
		// 	console.log("");
		// }

		var filters: any = searchFilterData;
		filters[filterType] = filterValue;
		setSearchFilterData(filters);
	}; //not working

	function handleSubmit() {
		const obj: {
			filter: string;
			value: string | any;
		}[] = [];
		props.filterOptions.map((filter) => {
			// if (filter.type === "radio")
			// 	obj.push({
			// 		filter: filter.filterName,
			// 		value: radioValue,
			// 	});
			if (filter.type === "dropdown" && filter.filterName === "Study Name") {
				obj.push({
					filter: filter.filterName,
					value: searchFilterData["studyIds"],
				});
			} else if (filter.type === "dropdown" && filter.filterName === "Speaker Type") {
				obj.push({
					filter: filter.filterName,
					value: searchFilterData["speaker"],
				});
			}
			// else if (filter.type === "slider") {
			// 	obj.push({
			// 		filter: "totStart",
			// 		// value: moment(dateRanges[0]).format(),
			// 		value: totRange ? marks[totRange[0]] : undefined,
			// 	});
			// 	obj.push({
			// 		filter: "totEnd",
			// 		// value: moment(dateRanges[1]).format(),
			// 		value: totRange ? marks[totRange[1]] : undefined,
			// 	});
			// }
			// else if (filter.type === "select") {
			// 	obj.push({
			// 		filter: filter.filterName,
			// 		value: searchFilterData["metaDataIds"],
			// 	});
			// }
			else {
				obj.push({
					filter: "Start Date",
					// value: moment(dateRanges[0]).format(),
					value: searchFilterData["startDate"],
				});
				obj.push({
					filter: "End Date",
					// value: moment(dateRanges[1]).format(),
					value: searchFilterData["endDate"],
				});
			}
		});
		props.setFilters(obj);
		props.fetchTranscripts(searchFilterData);
		// console.log("Here is the obj:", obj );
		// console.log("Here is the searchData:", searchFilterData );
	}
	return (
		<>
			{!props.loading ? (
				<div>
					<div className="flex items-center justify-between mr-2 mt-2 border-b">
						<div className="text-text-gray-100 font-semibold text-base py-2">Filter Results</div>
						<div
							className="text-sm text-blue-500 cursor-pointer"
							onClick={() => {
								handleSubmit();
							}}
						>
							Apply Filter
						</div>
					</div>
					{props.filterOptions.map((filter) => {
						if (filter.type === "radio") {
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-1">{filter.filterName}</div>
										<div>
											<Radio.Group
												onChange={(e) => {
													setRadioValue(e.target.value);
												}}
												value={radioValue}
											>
												<Space direction="vertical" size="small">
													{filter.options.map((option) => (
														<Radio className="radio-custom" value={option}>
															{option}
														</Radio>
													))}
												</Space>
											</Radio.Group>
										</div>
									</div>
								</>
							);
						} else if (filter.type === "dropdown" && context.envId === 3 && filter.filterName === "Speaker Type") {
							//change to 3
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-2">{filter.filterName}</div>
										<div>
											<Select
												allowClear
												clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
												placeholder={`Select ${filter.filterName}`}
												style={{ width: "95%" }}
												onChange={(value: string) => {
													handleFilters("speaker", value);
												}}
											>
												<Select.Option value="Agent">Agent</Select.Option>
												<Select.Option value="Patient">Patient</Select.Option>
											</Select>
										</div>
									</div>
								</>
							);
						} else if (filter.type === "dropdown" && context.envId !== 3 && filter.filterName !== "Speaker Type") {
							//change to 3
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-2">{filter.filterName}</div>
										<div>
											<Select
												allowClear
												clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
												placeholder={`Select ${filter.filterName}`}
												style={{ width: "95%" }}
												onChange={(value: string) => {
													handleFilters("studyIds", value);
													fetchSearchMetaData();
												}}
											>
												{/* {filter.options.map(
												(option) => (
													<Option value={option}>
														{option}
													</Option>
												)
											)} */}
												{props.fetchedFilters[0]?.optionsObject?.map((filter?: any) => (
													<Select.Option key={filter["id"]} value={filter["id"]}>
														{filter["name"]}
													</Select.Option>
												))}
											</Select>
										</div>
									</div>
								</>
							);
						} else if (filter.type === "date-range" && context.envId !== 1) {
							return (
								<>
									<div className="py-2">
										<div className="text-text-gray-90 font-semibold text-sm pb-2">{filter.filterName}</div>
										{context.envId === 3 ? (
											<RangePicker
												className=""
												format={newDateFormat}
												style={{ width: "95%" }}
												defaultPickerValue={[moment(context.endDate, "DD MMM YYYY").subtract(1, "months"), moment(context.endDate, "DD MMM YYYY")]}
												disabledDate={disableSelectedDates}
												onChange={(e: any) => {
													if (e === null) {
														handleFilters("startDate", "");
														handleFilters("endDate", "");
													} else {
														handleFilters("startDate", moment(e[0]).format("DD MMM YYYY"));
														handleFilters("endDate", moment(e[1]).format("DD MMM YYYY"));
													}
												}}
												allowClear
											/>
										) : (
											<RangePicker
												className=""
												clearIcon={icons.find((f) => f.id === "FormClear")?.icon}
												disabledDate={disableSelectedDates}
												defaultPickerValue={[moment(context.startDate, "DD MMM YYYY"), moment(context.endDate, "DD MMM YYYY")]}
												format={dateFormat}
												style={{ width: "95%" }}
												separator={icons.find((l) => l.id === "Swap")?.icon}
												onChange={(e: any) => {
													if (e === null) {
														handleFilters("startDate", "");
														handleFilters("endDate", "");
													} else {
														handleFilters("startDate", moment(e[0]).format("MMM YYYY"));
														handleFilters("endDate", moment(e[1]).format("MMM YYYY"));
													}
												}}
												onCalendarChange={(value: any) => {
													setDateRanges(value);
												}}
												picker="month"
											/>
										)}
									</div>
								</>
							);
						}
						// else if (filter.type === "select") {
						// 	return (
						// 		<>
						// 			<div className="py-2">
						// 				<div className="text-text-gray-90 font-semibold text-sm pb-2">
						// 					{filter.filterName}
						// 				</div>
						// 				<div>
						// 				<Select
						// 					clearIcon={
						// 						icons.find((f) => f.id === "FormClear")?.icon
						// 					}
						// 					style={{ width: "95%" }}
						// 					placeholder="Select Filters"
						// 					allowClear
						// 					mode="multiple"
						// 					onChange={(e, option) =>
						// 						handleFilters(
						// 							"metaDataIds",
						// 							Array.isArray(option) ? option.map(({ key, value }: any) => key) : [option.key]
						// 						)
						// 					}
						// 					options={searchedItems.map((item) => ({
						// 						key: item["id"],
						// 						value: item["name"],
						// 					}))}
						// 				>
						// 				</Select>
						// 				</div>
						// 			</div>
						// 		</>
						// 	);
						// }
						// else if (filter.type === "slider") {
						// 	return (
						// 		<>
						// 			<div className="py-2">
						// 				<div className="text-text-gray-90 font-semibold text-sm pb-2">
						// 					{filter.filterName}
						// 				</div>
						// 				<div className="px-1">
						// 					<Slider
						// 						range
						// 						marks={marks}
						// 						step={null}
						// 						// defaultValue={[0, 0]}
						// 						min={0}
						// 						max={100}
						// 						tooltipVisible={false}
						// 						value={totRange}
						// 						onChange={(value) =>
						// 							setTotRange(value)
						// 						}
						// 					/>
						// 				</div>
						// 			</div>
						// 		</>
						// 	);
						// }
					})}
				</div>
			) : (
				<>
					<div>
						<div className="flex items-center justify-between mr-2 mt-2 border-b">
							<div className="text-text-gray-100 font-semibold text-base py-2">Filter Results</div>
							<div
								className="text-sm text-blue-500 cursor-pointer"
								onClick={() => {
									handleSubmit();
								}}
							>
								Apply Filter
							</div>
						</div>
						<div className="py-4 pr-2">
							<div>
								<Skeleton active paragraph={{ rows: 3 }} />
							</div>
							<div className="py-6">
								<Skeleton.Button active size="default" block={true} />
							</div>
							<div className="py-3">
								<Skeleton.Button active size="default" block={true} />
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

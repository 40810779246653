export const usFeatures = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [16.78299504022661, -16.510831687535358],
              [16.95675298153168, -15.847374997471835],
              [16.54219304576793, -14.835258745873821],
              [16.88560331356441, -14.53858257494059],
              [16.82655918286234, -13.582686861662204],
              [17.924062626069855, -12.2694443873486],
              [18.333830278228028, -10.912631203075648],
              [18.721669911654153, -10.661269841849444],
              [18.56150480755671, -10.403579058977286],
              [20.688029826933494, -10.272420520962006],
              [23.688146762779922, -10.040001937420099],
              [23.96226333185856, -10.330761261546757],
              [23.875584492960506, -13.886102727368893],
              [23.838698732061964, -15.840396131340109],
              [23.765833025404625, -19.395419299239286],
              [24.330223320592097, -23.91569582124712],
              [24.188310388601636, -24.045705434001597],
              [23.255010511690415, -23.92328311821042],
              [22.770678569400417, -23.98100189050343],
              [21.715088469322225, -24.452551868674817],
              [21.458714516795226, -24.68923074988875],
              [21.119010995001275, -24.382544838292233],
              [20.99405517198199, -23.91226317136058],
              [20.529771142460575, -23.291262663695562],
              [20.707091700999378, -22.288559503895677],
              [19.100921546457364, -22.394003919601424],
              [15.91477863580476, -22.562301489647815],
              [15.896235387813123, -22.211576398644922],
              [16.148443157213286, -21.726646383679196],
              [16.11920394400613, -20.963570393902707],
              [16.408192551114702, -20.610834950783776],
              [16.523155722516037, -19.98502980985088],
              [16.86246615413821, -19.794749296021337],
              [17.2438465374954, -19.20833020175649],
              [16.91161192120068, -18.929474761892777],
              [17.523735985654938, -18.469815662127445],
              [17.044575001940004, -18.136701556091538],
              [16.872294206466457, -17.5987313566403],
              [16.78299504022661, -16.510831687535358]
            ]
          ]
        },
        "id": "MS",
        "properties": {
          "state_fips": "28",
          "id": "MS",
          "name": "Mississippi"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [38.74756619692505, -3.4345687301460317],
              [42.02664976732406, -3.0476994275743183],
              [43.87470513887009, -2.7416718921397982],
              [46.225607612961156, -2.3159531699078624],
              [49.95919749027881, -1.5801725958069435],
              [52.40608858801938, -1.0466237554925508],
              [52.519421473907386, -2.0710062066861257],
              [51.88134714095506, -2.566210187813185],
              [51.59970035378916, -2.5055272544311245],
              [51.346093724522554, -2.901030562299624],
              [51.612493089656375, -3.1229332936549685],
              [52.302902473872194, -2.802744228378356],
              [52.76121548087249, -2.7424511263363307],
              [53.10723683324961, -2.9602231916902975],
              [53.3054618527825, -3.5072245451879622],
              [53.24130153973777, -3.8750609088836097],
              [52.84442353024689, -4.12510377385451],
              [52.626327708313454, -4.656202187939699],
              [51.87985862183061, -4.8848208939032025],
              [51.57850125526886, -6.052336240833463],
              [51.88523620150068, -5.928644221394309],
              [52.328356506784196, -6.252552786167185],
              [51.75309488743669, -6.847712637078008],
              [51.11461889189187, -6.95984985888972],
              [50.59820068458625, -7.1830394036967675],
              [50.191723183798686, -7.733071243393202],
              [49.437765232052094, -8.541828385706951],
              [49.132457561956336, -9.329438756456735],
              [49.04220956215606, -9.92392666188845],
              [48.00131959792634, -10.097567305409639],
              [47.63660025389561, -10.323292581433622],
              [44.38560508767512, -7.983732125820961],
              [41.674545284912014, -8.39090215781372],
              [41.65597109457591, -8.038872437686448],
              [41.20583559574702, -7.585759326078427],
              [40.92456044861147, -7.500519554772036],
              [37.9424307907524, -7.79996389173352],
              [37.657971707034754, -7.794303260609844],
              [36.991163531705105, -8.216648201075278],
              [36.01780084569047, -8.679480020950605],
              [34.78830468376393, -8.886551923209018],
              [33.09079842315201, -9.096696582941675],
              [33.0791020686985, -8.376053795212146],
              [33.69830195016968, -8.06765601107707],
              [33.672341987045684, -7.733924409413833],
              [34.19810423216075, -7.208996660765053],
              [34.85345157533117, -7.1207040520962135],
              [35.404811889872136, -6.547315051172395],
              [35.979843862863824, -6.32488451329411],
              [36.1055171621274, -5.84891522076963],
              [36.715467934581255, -5.3308945121099995],
              [37.14854735822719, -5.44618566919809],
              [37.50031218500711, -5.004358925531298],
              [38.11584664233599, -4.9609046327594415],
              [38.33147712820748, -4.373168573521223],
              [38.76021779514268, -3.8007680996321227],
              [38.74756619692505, -3.4345687301460317]
            ]
          ]
        },
        "id": "NC",
        "properties": {
          "state_fips": "37",
          "id": "NC",
          "name": "North Carolina"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-11.897496640110537, -4.144728390537844],
              [-9.625989948957674, -4.322306454015575],
              [-6.5270588503073235, -4.479787412922507],
              [-3.9528325640552455, -4.591690344434655],
              [-0.8825874721184028, -4.6936125927047065],
              [2.2587061207601584, -4.746587478726149],
              [4.701053564049302, -4.7600360619849],
              [7.982342382585424, -4.73606731767284],
              [8.004447953855829, -6.247889935116514],
              [8.507059010903065, -9.587954119250016],
              [8.486516333187714, -11.088304586474178],
              [8.457764901201173, -14.881255530886104],
              [7.483609389266512, -14.550486930183505],
              [7.025936992561677, -14.10479984304442],
              [4.336711422908349, -14.308910015778507],
              [4.262093530496799, -14.54145924876522],
              [3.6567039512167296, -14.49282056827269],
              [3.147468163095418, -14.260079106364438],
              [2.5602164581673383, -14.253241509729593],
              [2.4256509947809723, -13.93143533377985],
              [1.7997911220358742, -14.593193191907597],
              [1.7872247421422438, -14.127195698685169],
              [1.084795879507491, -14.26948758649035],
              [0.5760753894495325, -13.8105123042458],
              [0.08882349971908199, -14.229156670040867],
              [-0.1008506254341699, -13.806631881337522],
              [-0.43779793772398135, -13.757282267233117],
              [-0.6430071959856194, -13.415903516308436],
              [-1.120039035680624, -13.275131369442581],
              [-1.4287046286392786, -13.552163166271471],
              [-1.7003486461831445, -13.249584993046303],
              [-2.1106863640588935, -13.310980246738369],
              [-2.682196385823697, -13.048652194583157],
              [-3.146897628220278, -13.043605436160641],
              [-3.1848982786364295, -12.670135924568545],
              [-4.084628039265288, -12.399865586631902],
              [-4.371049688934539, -12.504568756290746],
              [-4.821628251428149, -11.971350860893038],
              [-5.0974632022922055, -11.92532163085346],
              [-4.986976115261868, -9.306802332985074],
              [-4.850549055252481, -6.0700670967124],
              [-9.207071834100617, -5.844945746833606],
              [-12.009462916473145, -5.653391641052503],
              [-11.897496640110537, -4.144728390537844]
            ]
          ]
        },
        "id": "OK",
        "properties": {
          "state_fips": "40",
          "id": "OK",
          "name": "Oklahoma"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [52.00515500853682, 3.2550747634487194],
                [52.89413480515804, 3.637787835509635],
                [52.34917349063981, 2.6160472395335734],
                [52.314688201620534, 1.7099261522795597],
                [52.11169908482075, 0.8812445164960043],
                [51.81623535934154, 0.5844887747909071],
                [51.58908960982857, 0.9673552146594551],
                [51.5901922533261, 1.9168372139725998],
                [51.901350310722655, 2.6723275116334557],
                [52.00515500853682, 3.2550747634487194]
              ]
            ],
            [
              [
                [37.638906609567364, -0.6917822075100795],
                [37.84290644005165, -1.2392886997659294],
                [38.46707574364875, -1.5982638128275324],
                [38.73978432940819, -1.5408147675346544],
                [39.14305303048242, -1.077067092866308],
                [39.511142401351606, -1.334857739812985],
                [40.1296568192477, -1.049934933077868],
                [40.96613571409995, -0.36927987575201787],
                [41.53562851822768, -0.16854685994725854],
                [41.45267670648203, 0.3812326313459957],
                [41.67108965121038, 0.9809648175554595],
                [41.980629889099134, 1.3947522223037137],
                [42.04301846713796, 1.8319579071703416],
                [42.475557900957575, 2.8279726526360545],
                [42.95046217728395, 2.9901152989544832],
                [43.351020120563504, 2.9213156616043223],
                [43.54093048859733, 3.2044246364070887],
                [43.844070711479596, 4.358002324684199],
                [44.673958271974996, 4.862302483895705],
                [45.01046760583861, 5.5496557190313265],
                [44.97167031462047, 6.461878765100661],
                [46.333215716081575, 5.693178814587074],
                [46.469616905662704, 6.302403552836395],
                [46.82350529240118, 6.324718887275159],
                [47.00573171303321, 5.867286201001868],
                [48.053448276683326, 5.421670017084433],
                [48.32206311073, 5.035062630198357],
                [48.20862546625864, 4.528337726865749],
                [47.87558556309199, 3.870488540780359],
                [48.139168994411236, 3.5752699410267335],
                [48.61483410126315, 3.828376490259802],
                [48.84425938436218, 3.356086668072625],
                [49.6866442426178, 3.325104639014021],
                [50.58755973510634, 2.503245170998901],
                [50.65810636857281, 1.5631890121307568],
                [50.9996692052655, 1.2183101084297887],
                [50.74995067771205, 0.6326322969854634],
                [51.12696293798944, 0.34350854818166054],
                [51.14247037217211, -0.020328615160976365],
                [51.86954502160393, -0.011519692036448731],
                [52.40608858801938, -1.0466237554925508],
                [49.95919749027881, -1.5801725958069435],
                [46.225607612961156, -2.3159531699078624],
                [43.87470513887009, -2.7416718921397982],
                [42.02664976732406, -3.0476994275743183],
                [38.74756619692505, -3.4345687301460317],
                [35.88715554392314, -3.833825074660812],
                [34.02250691655816, -4.0623920067885955],
                [35.10307018548461, -3.495073819314354],
                [35.34366227160019, -3.110541745218553],
                [35.78407735248558, -2.9443673590837944],
                [36.05704478359079, -2.1995499709239335],
                [36.863825882488506, -1.6350223111435227],
                [37.638906609567364, -0.6917822075100795]
              ]
            ]
          ]
        },
        "id": "VA",
        "properties": {
          "state_fips": "51",
          "id": "VA",
          "name": "Virginia"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [35.804373567978594, 1.7470422411462354],
              [36.41333418211467, 1.9183873231839006],
              [36.66077611855411, 2.400470253363779],
              [36.507435617540466, 2.9924124016777345],
              [36.82091468999626, 3.7419333292389285],
              [37.428458992170505, 3.6472748625051077],
              [37.39083636449946, 4.291891153137941],
              [37.96713102501548, 5.090357227938142],
              [38.50566472695698, 5.102943081426357],
              [39.1716991663747, 5.927655058300104],
              [39.14201233611475, 6.329154102128504],
              [39.31919536161853, 7.024430845712754],
              [39.30981277067432, 7.58684939300059],
              [39.46512839784377, 8.111081857363365],
              [39.190711401704945, 8.850501835662646],
              [39.4885346782259, 9.098625557225562],
              [39.938072997373844, 6.360770269525436],
              [42.28826667869593, 6.758987673107011],
              [42.53494503947297, 5.219293032257399],
              [42.93136449789904, 5.5762775751512],
              [43.307623236410784, 6.172568013806953],
              [43.6100615993818, 6.131453049257768],
              [43.82442893574222, 6.627450894066925],
              [44.669353399131694, 6.56165230695596],
              [44.718013894257496, 6.900854449572126],
              [45.23817727399968, 7.219242548998128],
              [45.97193323904229, 6.952559382280363],
              [46.469616905662704, 6.302403552836395],
              [46.333215716081575, 5.693178814587074],
              [44.97167031462047, 6.461878765100661],
              [45.01046760583861, 5.5496557190313265],
              [44.673958271974996, 4.862302483895705],
              [43.844070711479596, 4.358002324684199],
              [43.54093048859733, 3.2044246364070887],
              [43.351020120563504, 2.9213156616043223],
              [42.95046217728395, 2.9901152989544832],
              [42.475557900957575, 2.8279726526360545],
              [42.04301846713796, 1.8319579071703416],
              [41.980629889099134, 1.3947522223037137],
              [41.67108965121038, 0.9809648175554595],
              [41.45267670648203, 0.3812326313459957],
              [41.53562851822768, -0.16854685994725854],
              [40.96613571409995, -0.36927987575201787],
              [40.1296568192477, -1.049934933077868],
              [39.511142401351606, -1.334857739812985],
              [39.14305303048242, -1.077067092866308],
              [38.73978432940819, -1.5408147675346544],
              [38.46707574364875, -1.5982638128275324],
              [37.84290644005165, -1.2392886997659294],
              [37.638906609567364, -0.6917822075100795],
              [36.87552875054483, -0.4190280135125206],
              [35.823417144153126, 0.88455688986617],
              [35.804373567978594, 1.7470422411462354]
            ]
          ]
        },
        "id": "WV",
        "properties": {
          "state_fips": "54",
          "id": "WV",
          "name": "West Virginia"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [9.59444707285703, -16.721071546138567],
              [12.709307861006511, -16.65007726206172],
              [16.78299504022661, -16.510831687535358],
              [16.872294206466457, -17.5987313566403],
              [17.044575001940004, -18.136701556091538],
              [17.523735985654938, -18.469815662127445],
              [16.91161192120068, -18.929474761892777],
              [17.2438465374954, -19.20833020175649],
              [16.86246615413821, -19.794749296021337],
              [16.523155722516037, -19.98502980985088],
              [16.408192551114702, -20.610834950783776],
              [16.11920394400613, -20.963570393902707],
              [16.148443157213286, -21.726646383679196],
              [15.896235387813123, -22.211576398644922],
              [15.91477863580476, -22.562301489647815],
              [19.100921546457364, -22.394003919601424],
              [20.707091700999378, -22.288559503895677],
              [20.529771142460575, -23.291262663695562],
              [20.99405517198199, -23.91226317136058],
              [21.119010995001275, -24.382544838292233],
              [21.458714516795226, -24.68923074988875],
              [20.725889489124697, -25.13967264108262],
              [20.69311329841303, -25.43912529250786],
              [21.333790821429282, -25.594332119845262],
              [21.57338212505747, -25.108134883323217],
              [22.04790696066368, -25.421672387754633],
              [21.79213514394906, -25.976150600183715],
              [21.18120788645951, -26.356393356236424],
              [21.19265205258511, -26.619856032570446],
              [21.759752684582786, -26.986368679688496],
              [22.475432570578306, -27.10609032978622],
              [22.98732797456603, -27.568084299044884],
              [22.87990247628091, -27.829820416071932],
              [22.224395688883206, -27.974731334715663],
              [21.76492727086756, -27.539111621723606],
              [20.938264873760666, -27.25807354554405],
              [20.447449149587108, -26.888729201970257],
              [20.121009401388974, -27.49700685201403],
              [20.287199828728774, -27.713041787589155],
              [19.853382802018437, -28.040549873261384],
              [19.598485239287243, -27.51157022218247],
              [19.23805195295656, -27.29946359265059],
              [18.721484223927696, -27.855090456279633],
              [18.208521920544747, -28.17171993252112],
              [17.907296037356623, -27.856016905630625],
              [17.036740862974668, -27.694449224147853],
              [16.864868224175687, -27.00377368287063],
              [16.554503072019436, -27.08279743165889],
              [16.081540018140053, -26.300367486212878],
              [15.703860813283866, -26.3037789498741],
              [15.40844599825263, -26.049900225836545],
              [14.595117307009252, -26.409151683685096],
              [14.750276489904087, -26.818415274719435],
              [14.29660690851911, -26.996773287700762],
              [13.43388516809479, -26.843477557056445],
              [12.049010606906066, -26.363969168748767],
              [11.282605899709454, -26.38889451199679],
              [10.334681482829273, -26.645263588180363],
              [10.105489546045897, -26.270124648342687],
              [10.566724376004009, -25.650694061744062],
              [10.670112655381578, -25.269133801117945],
              [10.545820434734628, -24.14250205771973],
              [10.82572973872479, -23.650804917053236],
              [11.037967597960394, -22.95903869201811],
              [10.971444357467997, -22.50017744501222],
              [10.615441071626906, -21.793785364246723],
              [10.307415245139678, -20.65458238252745],
              [10.033240985048607, -20.085164657804775],
              [9.658418522652601, -19.779277787054447],
              [9.59444707285703, -16.721071546138567]
            ]
          ]
        },
        "id": "LA",
        "properties": {
          "state_fips": "22",
          "id": "LA",
          "name": "Louisiana"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [29.93180904959207, 23.883210035998616],
                [30.415255309719235, 24.324475387758838],
                [30.77633069212829, 24.019124229237583],
                [30.14836863222812, 23.78354252796406],
                [29.93180904959207, 23.883210035998616]
              ]
            ],
            [
              [
                [25.05182567143418, 10.631525097170131],
                [25.461519337468573, 11.070722007197702],
                [25.936472344269298, 12.215790681743258],
                [26.102684225438303, 12.949797685864526],
                [26.123329123102188, 13.613360651937297],
                [25.90313673174294, 14.721146590303446],
                [25.12768603895666, 16.25504575347636],
                [25.280614243489637, 16.98805611766432],
                [25.046655356252558, 17.574570611386605],
                [25.484079703535585, 18.482960916865323],
                [25.449878873458857, 19.563652595176997],
                [25.74111090682033, 19.701640323718117],
                [25.738052043743423, 20.192786660631732],
                [26.270974006971805, 20.381484744064124],
                [26.70415058653134, 20.793685214761062],
                [26.67912751191286, 19.902260669581466],
                [26.928400690052634, 19.84520393449283],
                [27.14468572539463, 20.42038240390925],
                [27.081816844527772, 21.43299300942865],
                [27.739153786908624, 21.779221914110266],
                [27.507618747004464, 22.374488763132476],
                [27.824261237452646, 22.830054141632992],
                [28.26474828877525, 23.05833842617715],
                [28.849865101158407, 22.783939354130695],
                [29.369619284016338, 22.792629342034225],
                [29.630321620629303, 22.445853868685518],
                [31.250081979289995, 21.9372686363389],
                [31.42846031633438, 21.553529951429425],
                [31.191674848693843, 21.19485354611981],
                [31.544331174677623, 20.80679857907876],
                [31.698834475847768, 20.325410473500675],
                [31.72337350266521, 19.202697099876715],
                [31.30859018849555, 18.806329135549987],
                [31.11107314980365, 18.0607031658354],
                [30.797023617446996, 18.0179323598909],
                [30.62925955146828, 17.309529125166122],
                [30.746249242890777, 17.072519302321233],
                [31.26952071930353, 16.85878590147601],
                [31.68008687162706, 17.350456458811223],
                [32.018781025861415, 18.139491527846893],
                [32.690874555316455, 18.515125154172367],
                [33.128297301992795, 18.33427421873411],
                [33.41945045877296, 17.89627977412846],
                [33.91343061804899, 16.130474627984896],
                [34.25616458436874, 15.425324299413363],
                [34.17270024592675, 14.285644119990222],
                [33.89296333731944, 14.133505848010167],
                [33.531920823648306, 14.302370012906344],
                [33.411547382255215, 13.40814575435313],
                [33.016290183518905, 13.008481423127474],
                [33.00444018947943, 12.326079464128613],
                [32.71525496387647, 12.037794628867166],
                [32.50145465752773, 11.401118950793487],
                [29.53320806346307, 10.918146511168347],
                [29.510639463542617, 11.110195167287358],
                [27.335874424228578, 10.861218441006635],
                [25.05182567143418, 10.631525097170131]
              ]
            ],
            [
              [
                [18.35591640679258, 28.364208231838866],
                [18.511228402894908, 28.567020254019113],
                [19.732502808223927, 29.363265192565095],
                [19.522330638424144, 28.8663282517453],
                [18.35591640679258, 28.364208231838866]
              ]
            ],
            [
              [
                [16.245935079450483, 24.375068677013225],
                [17.03054516180366, 24.74354187115508],
                [17.377631253266628, 25.125130447621842],
                [18.25947257712044, 25.326741525734306],
                [19.88712782957918, 26.63059871478599],
                [20.167529789904883, 27.019412154339065],
                [20.642820826078058, 27.32153504702878],
                [21.412969028872155, 27.432012037028848],
                [21.406824191369225, 27.1698360333263],
                [20.592511650880034, 26.514794666110205],
                [20.224660021501045, 25.851720944564583],
                [21.36024009735936, 25.756688489779314],
                [21.836570974539136, 25.58410925987237],
                [22.573863853135485, 24.658749345949403],
                [23.355984846519135, 24.799690323827534],
                [24.08802227993143, 24.528133010620124],
                [24.242197557752014, 24.792937477627646],
                [25.038436452230563, 25.40090987024721],
                [26.383730473093443, 25.56645837656894],
                [26.821461994222737, 25.832130614636107],
                [27.34709056881411, 25.945228462213528],
                [27.317335024511912, 25.17787251765894],
                [27.926705546535395, 25.066055440453013],
                [29.26141116252991, 25.378383464402134],
                [29.311706445673266, 24.41445762996112],
                [29.853177196766325, 23.849021207357836],
                [29.08730287216236, 23.713063225780076],
                [28.30128755291927, 23.91368889059423],
                [28.025167478086956, 23.329704892980942],
                [27.5987069407291, 23.692475182917597],
                [26.903049603101124, 23.864808678335443],
                [26.496657300259898, 23.82284092154032],
                [26.26856483219281, 23.476803621532937],
                [25.70911857378455, 23.26291410722706],
                [25.137306636869745, 23.296239065815918],
                [24.48164257742519, 22.901877638852],
                [23.950794807850258, 22.870686171269945],
                [23.865174346564338, 22.442831886788746],
                [23.390239337691234, 22.3951729008984],
                [23.052558702185998, 21.912558156829462],
                [22.467174719684657, 20.443584872855304],
                [22.143711046063864, 20.66498891576888],
                [22.284394020566275, 21.161201474064917],
                [21.781300262286038, 21.187794886266154],
                [21.950547412084546, 21.917209403157067],
                [21.691539839941854, 22.3513157628297],
                [21.236703582226845, 22.405138608198556],
                [21.20223157245666, 22.79053692144042],
                [19.748173524731442, 22.97572595027239],
                [19.07762632860434, 23.28928341517512],
                [16.90308256027786, 23.733823446235398],
                [16.67390729830344, 24.20954428235219],
                [16.245935079450483, 24.375068677013225]
              ]
            ]
          ]
        },
        "id": "MI",
        "properties": {
          "state_fips": "26",
          "id": "MI",
          "name": "Michigan"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [60.268890146692144, 15.783721998035302],
                [60.668072608446295, 16.313874927433424],
                [61.105414174823814, 16.031528152947857],
                [60.268890146692144, 15.783721998035302]
              ]
            ],
            [
              [
                [54.017938229477814, 18.524528062735385],
                [55.746031742211564, 18.892291044867257],
                [58.23795343205689, 19.442313428325704],
                [58.483227458164414, 19.893491385925522],
                [59.10877086604009, 20.219049585534336],
                [59.33900205345369, 19.71873614022264],
                [59.08216994200266, 18.44233575774642],
                [59.71651434134544, 18.449297285057042],
                [60.72799249130761, 17.20142509499737],
                [61.47846464249053, 17.399975841643865],
                [61.94673059817433, 17.280027478450727],
                [60.92694476223117, 16.76842331471107],
                [60.48909317684485, 16.422800646111668],
                [60.31026389417641, 16.98101507098393],
                [59.90345191000462, 16.313898782991657],
                [59.54338524858075, 16.057541416192215],
                [59.243129053859946, 16.531084441045763],
                [59.152892305549365, 16.617387160126675],
                [58.877123209866475, 16.762278584333508],
                [58.57783971877393, 17.424740513731017],
                [57.691637090097046, 17.159758207982208],
                [57.67682276772191, 17.20409365415845],
                [54.03811225564949, 16.37644806409819],
                [53.96738786373184, 16.47276844064959],
                [54.017938229477814, 18.524528062735385]
              ]
            ]
          ]
        },
        "id": "MA",
        "properties": {
          "state_fips": "25",
          "id": "MA",
          "name": "Massachusetts"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-38.59930748694645, 27.082220389802444],
              [-38.504743330904454, 27.562519876353143],
              [-38.5688075718609, 28.378192014287784],
              [-37.64769967356898, 32.43280811476976],
              [-36.871337456975496, 35.72581764463631],
              [-34.95634411750104, 35.319057767924974],
              [-35.593031306322516, 32.40213267166301],
              [-35.11973464601563, 31.459117918394675],
              [-35.269896342726554, 31.064450582139095],
              [-34.97350747877123, 30.24356870435077],
              [-34.58869984510096, 30.035965890231154],
              [-34.024875256381634, 28.844344857992155],
              [-33.56777764017963, 28.278176989270733],
              [-33.523902345795065, 27.968686163339676],
              [-32.92480883410635, 27.877732286292193],
              [-33.631943225447245, 26.440576579002965],
              [-33.50204386236016, 25.682522587138045],
              [-33.93032783341209, 25.43690578928385],
              [-34.02358054224982, 24.88335082054892],
              [-33.6540099326341, 24.434282650975376],
              [-32.85957183426461, 24.898240860326858],
              [-32.46503443432498, 24.640484501389267],
              [-32.505894424323046, 24.054789589201903],
              [-32.24896193382729, 23.17445681503216],
              [-32.02966842627365, 22.914844575347473],
              [-32.14226758604925, 22.34850188189774],
              [-31.730025059740726, 22.14339754775842],
              [-31.433816121077406, 21.653577459077283],
              [-31.422930529996428, 20.96887849036002],
              [-31.090504581200214, 20.818615839878667],
              [-30.795140877816323, 21.010707093608673],
              [-30.12477053909803, 20.722159707865174],
              [-29.851831528787983, 21.041237033057335],
              [-29.488407403945743, 20.83446196959391],
              [-28.980730805956306, 20.878148645786517],
              [-28.259025983600544, 20.711277237951514],
              [-28.11361046277446, 21.149486519179753],
              [-27.77751766301119, 21.193508812496063],
              [-27.300043511945255, 20.346581339061963],
              [-27.748699154377015, 17.45500677658761],
              [-28.465293212779216, 13.003957521918709],
              [-30.885366548523038, 13.390009380661867],
              [-34.69729677553158, 14.051718411801874],
              [-34.99847978250568, 14.125491021182894],
              [-37.14799828611833, 14.555272901530328],
              [-40.0435938969196, 15.162887751962236],
              [-41.462842571718724, 15.486875318724378],
              [-41.04967690129294, 17.320607991412103],
              [-40.266663660759185, 20.7811656931892],
              [-39.75658932786014, 21.746109098576312],
              [-40.049813777638434, 22.0276989574934],
              [-40.32841088373518, 22.59717475597361],
              [-39.68443050592998, 23.51817867031481],
              [-39.431748522116294, 23.58022699872773],
              [-39.0957194402707, 24.233955696917036],
              [-38.778146588774966, 24.521529060778988],
              [-38.54197488164806, 25.022494913027508],
              [-37.920323223057096, 25.781697956032318],
              [-38.08092836605654, 26.31059972619458],
              [-38.438265157634596, 26.530861581931397],
              [-38.59930748694645, 27.082220389802444]
            ]
          ]
        },
        "id": "ID",
        "properties": {
          "state_fips": "16",
          "id": "ID",
          "name": "Idaho"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [43.789900663559216, -38.84396779607019],
                [43.917783610518356, -38.558934164377945],
                [44.69465816135459, -37.978094168367264],
                [45.166337372085316, -38.36827346836858],
                [44.14874509899747, -38.80301132039274],
                [43.789900663559216, -38.84396779607019]
              ]
            ],
            [
              [
                [32.83356453237446, -21.198056454805744],
                [33.28613298837624, -22.01416061359685],
                [37.74572097010864, -21.717136666674868],
                [40.10702285197361, -21.552205619368607],
                [40.31261842457676, -22.154019295218113],
                [40.622941056760524, -22.095670948179702],
                [40.612848275816276, -21.40245920425795],
                [40.47493412993106, -20.958369301411025],
                [40.66814310771933, -20.689191559307837],
                [41.289438039135916, -20.846732879242374],
                [42.006604739793715, -20.843370008051963],
                [42.375893284101345, -22.15325545967805],
                [42.923419727044084, -23.51430814348808],
                [43.96010434274891, -25.242339443608884],
                [45.43611857593358, -27.07040170825138],
                [45.30154980559579, -27.580116662252223],
                [45.48192894834567, -28.103548408249573],
                [46.15745697790297, -29.103324567802375],
                [46.648472948055755, -30.05330778530208],
                [47.27759019169402, -31.067558215585443],
                [47.55420404430009, -31.68193889569266],
                [47.637941849790614, -32.24304510279471],
                [47.6982741587717, -33.750694143071016],
                [47.800453285519886, -34.696010453031455],
                [47.530274246532805, -34.8496872162121],
                [47.38019942535139, -35.632099670719136],
                [47.67881125187477, -35.964219355585826],
                [47.394488861112144, -36.69211824261875],
                [47.16625194751403, -36.42577453362366],
                [45.53426755261406, -36.96372079762531],
                [45.24615432638071, -36.69710622624084],
                [45.32960466791873, -36.34254165384931],
                [44.71249306717001, -35.41256897488708],
                [43.771625176619786, -34.95283690188322],
                [43.56137559355236, -35.09998142772737],
                [43.15336613742498, -34.457739150567186],
                [42.846053675705605, -33.65617508065363],
                [42.09031014509814, -33.245725134072536],
                [42.162870783878525, -32.50408270944311],
                [41.66020443828583, -32.69327217702822],
                [40.73758941125345, -31.446966704609018],
                [40.123004816280556, -30.722521822685316],
                [40.29647718850691, -30.627872293264865],
                [40.819723979961225, -29.62774722125112],
                [40.30761247717749, -29.720982479332612],
                [39.96526664018861, -30.135788956364443],
                [39.58989657352158, -29.655334427892754],
                [39.84270624873527, -27.679953787157523],
                [39.49838964663902, -26.871088146270523],
                [39.594489037631526, -26.616303477378153],
                [39.11277671825985, -25.93764973715929],
                [38.67805068362592, -25.939731651262655],
                [38.23866581848229, -25.653262903107347],
                [38.00890590270704, -25.292917414759216],
                [37.506719323712865, -25.042878175546456],
                [37.4298868099243, -24.681660047630572],
                [37.04784892678693, -24.498631426452185],
                [36.61571924898235, -23.975057518313946],
                [35.66213636283103, -23.55164016320488],
                [34.88914556460995, -23.845727369523743],
                [34.91365067927927, -24.256241755240417],
                [34.41594633479223, -24.28331752319548],
                [33.58532378144696, -24.90854869275445],
                [32.47370409791676, -25.191501839602992],
                [32.4794467627646, -24.84011501958446],
                [32.163429096997554, -24.458224175364094],
                [30.664006954865684, -23.69109562544611],
                [29.553902006839017, -23.430021097706117],
                [28.585835859990844, -23.469323625464725],
                [26.610981829607624, -23.989862433763648],
                [26.805475303272665, -23.595907559090072],
                [26.79072050690084, -22.79379632173997],
                [26.15582531910656, -22.279244498351126],
                [26.212989351571395, -21.88020879636533],
                [28.96824181878167, -21.63504160499057],
                [32.83356453237446, -21.198056454805744]
              ]
            ]
          ]
        },
        "id": "FL",
        "properties": {
          "state_fips": "12",
          "id": "FL",
          "name": "Florida"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-12.846566357571382, 14.129378623228591],
              [-10.845464375754029, 13.97026985998942],
              [-7.5645079045766614, 13.736698331805892],
              [-5.399161125711419, 13.625675669537959],
              [-2.869626090516946, 13.517721123818646],
              [-0.7480368073494428, 13.453046030983577],
              [-0.6839913201890881, 13.297889659712064],
              [0.43403319062631374, 12.73635590643213],
              [0.669331100746406, 13.02702736824475],
              [1.0149865689817608, 12.944473508351132],
              [1.8465667750125836, 13.007166472520412],
              [3.1950068727699765, 12.347386282858816],
              [3.7237301085876835, 11.85481433624285],
              [3.935022691804466, 10.877971904532389],
              [4.548333726142891, 9.780773660424464],
              [4.497939209175574, 8.967012577670909],
              [4.8526083336482255, 8.755252427740619],
              [4.868700561265953, 7.945425642815447],
              [5.125626248150805, 7.004468601859078],
              [4.953377738904997, 6.5434749706535955],
              [5.232302341705314, 6.100502194766141],
              [5.5862219378461315, 5.281159863558857],
              [5.888813385131851, 5.067028191133494],
              [6.278187771645446, 4.334962006278721],
              [4.009422116673445, 4.332252162909866],
              [0.5168218340117896, 4.3754942416699585],
              [-2.514090072751898, 4.455391256422575],
              [-5.469733046639801, 4.573583852752221],
              [-9.06871204032343, 4.777017978361577],
              [-8.875992269316527, 7.793223250682945],
              [-13.35821337181433, 8.12316048686218],
              [-13.13150854570941, 10.782735358925931],
              [-12.846566357571382, 14.129378623228591]
            ]
          ]
        },
        "id": "NE",
        "properties": {
          "state_fips": "31",
          "id": "NE",
          "name": "Nebraska"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-36.871337456975496, 35.72581764463631],
              [-37.64769967356898, 32.43280811476976],
              [-38.5688075718609, 28.378192014287784],
              [-38.504743330904454, 27.562519876353143],
              [-38.59930748694645, 27.082220389802444],
              [-42.803933205564505, 28.072139429102457],
              [-43.13386766795387, 27.948622021399764],
              [-44.16151164406833, 28.12024443907829],
              [-45.486108171465034, 27.90746922786149],
              [-46.1041420047517, 27.988092213297087],
              [-46.330612279254034, 28.187395249813868],
              [-47.31783157273707, 28.148147377286385],
              [-48.162390329182955, 28.61667087653551],
              [-48.740146143030636, 28.713774558612407],
              [-49.75347158116293, 28.505945590699525],
              [-50.70224738536131, 29.11054302885818],
              [-50.54982888836556, 30.001587052558662],
              [-50.62785300617719, 30.40104821364249],
              [-50.966002953849994, 30.810166805703574],
              [-51.32821720618793, 30.790877834954728],
              [-51.76445924036776, 31.269453256410927],
              [-52.03018639790395, 31.487478649656225],
              [-52.4339803538616, 31.40773104586041],
              [-52.59211326772592, 31.667830167127804],
              [-52.236375523787814, 32.19212613850058],
              [-52.312408361428226, 33.29996881895742],
              [-52.235433411799605, 34.56170329164724],
              [-52.327906169949294, 34.94966224657992],
              [-52.21218304995938, 35.95649576604511],
              [-52.478733070641546, 36.529975935152954],
              [-52.42137324022248, 37.310880781282755],
              [-51.925475486368015, 37.76197391616062],
              [-51.100728877111074, 36.93702086259753],
              [-49.9409139559122, 36.41513749251763],
              [-49.39649947202608, 36.40291552424205],
              [-49.09633086145357, 36.104878268285816],
              [-48.54508589323929, 35.970069779761666],
              [-48.61280580694481, 35.27764789728565],
              [-48.43158487526202, 34.84796225591484],
              [-48.635375359959305, 34.1962092310534],
              [-49.02478133825242, 33.5253150430574],
              [-48.43374183411597, 33.647797127951314],
              [-48.436988707766645, 34.34048462917152],
              [-48.19854754864316, 34.97453852515755],
              [-47.68051049134928, 35.47733323249578],
              [-47.859434188717756, 35.761222036651034],
              [-47.81764089706124, 36.870191739753764],
              [-47.60961109660884, 37.208039012827456],
              [-47.67832728238085, 37.75957970494913],
              [-47.985485732691224, 37.92480921089338],
              [-47.9190670714824, 38.50833202416636],
              [-45.99277144876564, 37.96053280837934],
              [-42.62231474167161, 37.0846824154313],
              [-40.37089493879241, 36.53373775945171],
              [-36.871337456975496, 35.72581764463631]
            ]
          ]
        },
        "id": "WA",
        "properties": {
          "state_fips": "53",
          "id": "WA",
          "name": "Washington"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-26.155319692909288, -2.638911998594156],
              [-24.283515873169563, -2.8896389223507097],
              [-19.459550118592276, -3.476849106399473],
              [-15.060585799028553, -3.908935429140613],
              [-11.897496640110537, -4.144728390537844],
              [-12.009462916473145, -5.653391641052503],
              [-12.103530716925503, -5.646305720997103],
              [-12.3998386475178, -9.618896423120205],
              [-12.66698469619733, -13.167911116123353],
              [-12.94387195728717, -16.17623688560007],
              [-13.167253798008106, -19.166209400380694],
              [-16.870026425883825, -18.864867441714686],
              [-19.242998561036487, -18.639006215997696],
              [-22.12650566034852, -18.335610909637737],
              [-22.21557796072745, -18.730420945211463],
              [-21.971840592272592, -19.007867908298827],
              [-26.206758857938176, -18.500688553203368],
              [-26.380926182437847, -19.836858623545396],
              [-28.510697164133013, -19.556816197113193],
              [-27.90715025468659, -15.265702653129024],
              [-27.55582138654281, -12.7396544489806],
              [-27.001608012111717, -8.750591991661937],
              [-26.52203554123075, -5.284138084908625],
              [-26.155319692909288, -2.638911998594156]
            ]
          ]
        },
        "id": "NM",
        "properties": {
          "state_fips": "35",
          "id": "NM",
          "name": "New Mexico"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-12.347973328358991, 20.092622534418496],
              [-12.081323464827218, 22.902169142087033],
              [-8.189843211893686, 22.610876677079624],
              [-5.601487692179513, 22.458067742558256],
              [-3.1288882334961667, 22.337534417654375],
              [-0.3403090932374091, 22.234620459136977],
              [3.525472802829407, 22.170775006921495],
              [3.489907279911993, 21.8456664735374],
              [2.902251663595858, 21.195855553361966],
              [3.2702557917126995, 20.610672788872748],
              [3.747414774991417, 20.272846415339586],
              [3.7380871050776623, 17.770455007642106],
              [3.7212180617901867, 14.892315613087801],
              [3.461040151328742, 14.108212240774916],
              [3.752070359879543, 13.751549760439115],
              [3.3018915697624376, 12.599393160620059],
              [3.7237301085876835, 11.85481433624285],
              [3.1950068727699765, 12.347386282858816],
              [1.8465667750125836, 13.007166472520412],
              [1.0149865689817608, 12.944473508351132],
              [0.669331100746406, 13.02702736824475],
              [0.43403319062631374, 12.73635590643213],
              [-0.6839913201890881, 13.297889659712064],
              [-0.7480368073494428, 13.453046030983577],
              [-2.869626090516946, 13.517721123818646],
              [-5.399161125711419, 13.625675669537959],
              [-7.5645079045766614, 13.736698331805892],
              [-10.845464375754029, 13.97026985998942],
              [-12.846566357571382, 14.129378623228591],
              [-12.56267077019553, 17.498092198744992],
              [-12.347973328358991, 20.092622534418496]
            ]
          ]
        },
        "id": "SD",
        "properties": {
          "state_fips": "46",
          "id": "SD",
          "name": "South Dakota"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-12.009462916473145, -5.653391641052503],
              [-9.207071834100617, -5.844945746833606],
              [-4.850549055252481, -6.0700670967124],
              [-4.986976115261868, -9.306802332985074],
              [-5.0974632022922055, -11.92532163085346],
              [-4.821628251428149, -11.971350860893038],
              [-4.371049688934539, -12.504568756290746],
              [-4.084628039265288, -12.399865586631902],
              [-3.1848982786364295, -12.670135924568545],
              [-3.146897628220278, -13.043605436160641],
              [-2.682196385823697, -13.048652194583157],
              [-2.1106863640588935, -13.310980246738369],
              [-1.7003486461831445, -13.249584993046303],
              [-1.4287046286392786, -13.552163166271471],
              [-1.120039035680624, -13.275131369442581],
              [-0.6430071959856194, -13.415903516308436],
              [-0.43779793772398135, -13.757282267233117],
              [-0.1008506254341699, -13.806631881337522],
              [0.08882349971908199, -14.229156670040867],
              [0.5760753894495325, -13.8105123042458],
              [1.084795879507491, -14.26948758649035],
              [1.7872247421422438, -14.127195698685169],
              [1.7997911220358742, -14.593193191907597],
              [2.4256509947809723, -13.93143533377985],
              [2.5602164581673383, -14.253241509729593],
              [3.147468163095418, -14.260079106364438],
              [3.6567039512167296, -14.49282056827269],
              [4.262093530496799, -14.54145924876522],
              [4.336711422908349, -14.308910015778507],
              [7.025936992561677, -14.10479984304442],
              [7.483609389266512, -14.550486930183505],
              [8.457764901201173, -14.881255530886104],
              [8.701363227075026, -15.15140268390355],
              [9.263032678945367, -15.014472865769573],
              [9.560095527802753, -15.118876234806176],
              [9.59444707285703, -16.721071546138567],
              [9.658418522652601, -19.779277787054447],
              [10.033240985048607, -20.085164657804775],
              [10.307415245139678, -20.65458238252745],
              [10.615441071626906, -21.793785364246723],
              [10.971444357467997, -22.50017744501222],
              [11.037967597960394, -22.95903869201811],
              [10.82572973872479, -23.650804917053236],
              [10.545820434734628, -24.14250205771973],
              [10.670112655381578, -25.269133801117945],
              [10.566724376004009, -25.650694061744062],
              [10.105489546045897, -26.270124648342687],
              [10.334681482829273, -26.645263588180363],
              [9.665086612716557, -26.748632267494315],
              [8.644797199716397, -27.209213694230755],
              [8.498343902382954, -27.031667978108523],
              [7.9329559808436745, -27.18094932107347],
              [8.078981939028278, -26.49732828967374],
              [7.270219448278146, -26.867780344283133],
              [7.598221076143755, -27.452956285016],
              [7.588295320769236, -27.834612568688513],
              [6.887120759105363, -28.38458740039838],
              [7.002531937842447, -28.532848612541294],
              [6.324456428701078, -29.1306372878814],
              [3.9881184347660827, -30.33793791523854],
              [2.8905203073549277, -30.766633422712502],
              [1.9293484440334878, -31.60018679765637],
              [1.533310857479806, -32.196099401167956],
              [0.9129216423895352, -33.658722752452306],
              [0.7494542225479144, -35.488451810392945],
              [1.1858653704853896, -36.65337558126874],
              [1.3672318169092863, -37.6341370442437],
              [0.5621488337897711, -37.83477562272487],
              [0.22574425819303867, -37.47881005825814],
              [-0.42851519281626205, -37.293083620954114],
              [-1.4103469836652225, -37.256862136589476],
              [-1.9800834939722927, -36.79643290822383],
              [-2.5399380465860006, -36.75109450639177],
              [-2.9567287896143255, -36.353229779083335],
              [-3.6436451325764767, -36.25253014551171],
              [-3.8592120009030735, -35.83920704257846],
              [-4.103085285873252, -34.93549151955918],
              [-4.561552863759419, -34.39351324607751],
              [-4.4920748731651, -33.948829623117035],
              [-4.67008843546664, -33.66317193235183],
              [-4.60096148901211, -33.05957208387941],
              [-4.914661131762397, -32.56907594064918],
              [-5.226576068428983, -32.48637186029066],
              [-5.6273991258368135, -32.07557141698084],
              [-5.659017753260643, -31.803348675784207],
              [-6.130200259030776, -31.045476206958845],
              [-6.6703916709393845, -30.608788437213363],
              [-6.761364704602348, -30.175028113797765],
              [-7.26252036153993, -28.984126686136705],
              [-7.517195375364928, -28.62858708963487],
              [-7.556947998353676, -28.1598367753351],
              [-7.857040699819083, -27.709338424074318],
              [-9.144864001749871, -26.637892746199952],
              [-9.105208816351443, -26.476734156283598],
              [-9.773990638415649, -25.9689098374225],
              [-11.089943195865542, -25.924238662033808],
              [-11.66320638530595, -25.617436279403442],
              [-12.70748974831888, -26.011627679719744],
              [-13.054981286872854, -26.57882689546716],
              [-13.170107942853042, -27.055896280354133],
              [-14.079690543861664, -28.138497145137976],
              [-14.74752928422163, -27.891452985980635],
              [-15.072930004623332, -27.514990832733147],
              [-15.507686024156522, -27.40580976092317],
              [-15.656524512224012, -27.144171236098863],
              [-16.25893327547793, -26.956818651413514],
              [-17.464710356704593, -25.875185782536406],
              [-17.80200461981704, -25.040368493190712],
              [-17.76110644197656, -24.24407492711517],
              [-18.14746429699236, -23.5758797229635],
              [-18.19840669209355, -23.032866432117487],
              [-18.947382410403208, -22.256655278162313],
              [-19.389018142204314, -22.084446817591903],
              [-19.85593409136048, -21.330326522614342],
              [-20.25650149265237, -21.043922638987112],
              [-20.653605671232597, -20.40850531504018],
              [-21.29046921274412, -19.989915548542626],
              [-21.617139622058573, -19.200520582455276],
              [-21.971840592272592, -19.007867908298827],
              [-22.21557796072745, -18.730420945211463],
              [-22.12650566034852, -18.335610909637737],
              [-19.242998561036487, -18.639006215997696],
              [-16.870026425883825, -18.864867441714686],
              [-13.167253798008106, -19.166209400380694],
              [-12.94387195728717, -16.17623688560007],
              [-12.66698469619733, -13.167911116123353],
              [-12.3998386475178, -9.618896423120205],
              [-12.103530716925503, -5.646305720997103],
              [-12.009462916473145, -5.653391641052503]
            ]
          ]
        },
        "id": "TX",
        "properties": {
          "state_fips": "48",
          "id": "TX",
          "name": "Texas"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-56.83576201777243, 19.535684759880127],
              [-55.002177125811805, 18.989843958785833],
              [-52.93498256577929, 18.424849517184427],
              [-50.05464920169558, 17.580168113122973],
              [-47.863802953491714, 17.010183457362565],
              [-48.47764653806216, 14.646324277992639],
              [-49.40073302310197, 11.057924246288183],
              [-50.132232568069156, 8.257963542615714],
              [-47.99601200778363, 5.044785367454413],
              [-46.40564387017499, 2.656801612896701],
              [-44.29641737334643, -0.5258839352510203],
              [-41.620278809484795, -4.548056843135525],
              [-40.40820700521479, -6.369020877324143],
              [-40.481352976131866, -6.772138800550999],
              [-40.19008928433031, -7.302216439085663],
              [-40.032182021455995, -8.138307511925149],
              [-39.62495422213533, -8.673295186121145],
              [-39.97930172604547, -9.002654568225951],
              [-40.40886081787929, -9.122463348872992],
              [-40.80004478123533, -9.604032712813462],
              [-40.84382627094647, -10.300050090180394],
              [-40.996823852822935, -10.71456132731708],
              [-41.570376830387204, -11.072535976216384],
              [-41.53438924434338, -11.474858745980217],
              [-41.71181923263008, -11.970142530374117],
              [-41.26458951072335, -12.305478258796333],
              [-41.27363105870055, -12.830942744613091],
              [-41.46738367228917, -13.062941896700298],
              [-41.96423974477215, -13.082194765336743],
              [-44.39710587787111, -12.796343686775097],
              [-47.98984854809193, -12.371847780250324],
              [-48.19844838241847, -11.812772757755445],
              [-48.07324275316538, -11.303018996395759],
              [-48.09422253815309, -10.533587915865342],
              [-48.3830355364848, -9.813752466832046],
              [-48.91668858695825, -9.045529819636526],
              [-49.6851304076558, -8.177618281635988],
              [-50.01826047985038, -8.27741165392022],
              [-50.23241082106718, -7.449598279974555],
              [-50.455924301322554, -7.1286341089551755],
              [-51.05413672716703, -7.112000980113303],
              [-51.93017678161298, -6.4419204927524625],
              [-51.9903107681119, -6.050784036450405],
              [-52.56089960258325, -5.444879566441404],
              [-53.3147783201033, -5.279047865219216],
              [-53.5831655424475, -5.042924538407771],
              [-54.6929312739385, -4.793092025053286],
              [-55.00423640762911, -4.306926848549643],
              [-54.80041348018972, -3.9617395819369943],
              [-54.80983563463396, -3.3430967870024406],
              [-54.59344999838707, -2.8999135616752163],
              [-54.67047996632537, -2.536147447660159],
              [-55.07518936479558, -2.187944641703792],
              [-54.88882374206486, -1.7510250351009446],
              [-55.157640121446306, -1.4988553277919066],
              [-55.59750604765727, -0.7408627081583525],
              [-56.23095747084642, 1.0995326804761016],
              [-56.447925299945744, 1.307913701929246],
              [-56.51866133566599, 2.109302498007598],
              [-55.90108033995478, 2.921549943988009],
              [-56.083872957825136, 3.5201292155850687],
              [-56.41999640794051, 3.5204364969537476],
              [-56.71577653398729, 3.847470579563243],
              [-56.98806563494903, 4.460420728608702],
              [-56.842692378987174, 4.933641848699524],
              [-56.97046336139317, 5.480716008054319],
              [-56.748998036568096, 6.234619012510204],
              [-56.48192812429554, 6.254722387449765],
              [-56.55692205835404, 5.5867656613544305],
              [-56.0646932417627, 5.157067956374298],
              [-56.0782626412765, 5.7608043267590405],
              [-56.33701736062903, 6.119270589495445],
              [-56.367462582785336, 6.700149580828388],
              [-56.003604621635205, 7.0371949013244715],
              [-56.40082408883787, 7.18389390931674],
              [-56.68262148817261, 6.339081901031479],
              [-57.07868167665564, 6.685297394123429],
              [-57.49549550511336, 7.24130391851434],
              [-57.374595790017, 7.900202976671769],
              [-57.56324790568005, 8.580751649190008],
              [-57.93009625300524, 9.072940668433864],
              [-58.376796135848345, 10.068059700557594],
              [-58.29535888369232, 10.691846094563049],
              [-58.336761098135234, 11.643750829497435],
              [-58.023751185916765, 12.194623566910566],
              [-57.969210501991505, 13.065563995431482],
              [-58.29216047731797, 14.013832336322254],
              [-58.70206429670125, 14.6349680550277],
              [-58.637640547864066, 15.190239671586882],
              [-57.71370545656209, 16.27258945963557],
              [-57.54169046859858, 16.842293894691743],
              [-57.012819305667854, 17.833334592961855],
              [-56.96446344752904, 18.763330399807266],
              [-57.122515501076606, 18.930939488258858],
              [-56.83576201777243, 19.535684759880127]
            ]
          ]
        },
        "id": "CA",
        "properties": {
          "state_fips": "06",
          "id": "CA",
          "name": "California"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [23.688146762779922, -10.040001937420099],
              [26.936424328684332, -9.761467432475836],
              [29.98574725772395, -9.469654846334356],
              [31.00302583614632, -13.104250557312513],
              [31.72951634056848, -15.685769673488055],
              [32.00497683099631, -16.33678244042983],
              [32.408601529602734, -16.90073239435239],
              [32.59133556510962, -17.555482187863035],
              [32.290406619350165, -17.851607530291503],
              [32.1884334576128, -18.688965608032717],
              [32.281575320924375, -19.172014919593366],
              [32.54759916566467, -19.605376172713683],
              [32.460788014873586, -20.448027485294205],
              [32.83356453237446, -21.198056454805744],
              [28.96824181878167, -21.63504160499057],
              [26.212989351571395, -21.88020879636533],
              [26.15582531910656, -22.279244498351126],
              [26.79072050690084, -22.79379632173997],
              [26.805475303272665, -23.595907559090072],
              [26.610981829607624, -23.989862433763648],
              [26.262095458660546, -24.11226579392096],
              [25.57825744739585, -23.694104267704304],
              [25.46818472345833, -22.9875760391393],
              [25.082528706971537, -23.247145774793502],
              [25.069231692031572, -23.832562072686727],
              [24.330223320592097, -23.91569582124712],
              [23.765833025404625, -19.395419299239286],
              [23.838698732061964, -15.840396131340109],
              [23.875584492960506, -13.886102727368893],
              [23.96226333185856, -10.330761261546757],
              [23.688146762779922, -10.040001937420099]
            ]
          ]
        },
        "id": "AL",
        "properties": {
          "state_fips": "01",
          "id": "AL",
          "name": "Alabama"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [29.98574725772395, -9.469654846334356],
              [33.09079842315201, -9.096696582941675],
              [34.78830468376393, -8.886551923209018],
              [36.01780084569047, -8.679480020950605],
              [35.58290316423658, -9.381286968200001],
              [35.58910430143443, -9.69372540052471],
              [36.49086886755267, -10.225814672319757],
              [36.731159544980834, -10.14809515338659],
              [37.319900636915605, -11.090549246606322],
              [37.799255555484955, -11.647721061289655],
              [38.61743517952277, -12.095648410001962],
              [38.817676678251786, -12.459682992068467],
              [39.55546467208096, -12.861505995998035],
              [39.66628567564529, -13.267474321428407],
              [40.25717986425694, -13.811145642781772],
              [40.832578406726036, -14.057643968415666],
              [41.08534159458135, -14.540754059905076],
              [41.20939118096392, -15.180976298394016],
              [41.80573183139409, -15.545807375946572],
              [42.09047336228367, -16.049534238901764],
              [42.16191867969604, -16.505007366723746],
              [42.79457178027499, -16.70679022004171],
              [42.743266198778, -17.09926882910552],
              [42.32838517697023, -17.727414451119785],
              [42.309968017410036, -18.358805659719323],
              [42.08202054188701, -18.839086929683802],
              [42.18783612201876, -19.13366027144187],
              [41.905543266280326, -19.616929037038737],
              [42.006604739793715, -20.843370008051963],
              [41.289438039135916, -20.846732879242374],
              [40.66814310771933, -20.689191559307837],
              [40.47493412993106, -20.958369301411025],
              [40.612848275816276, -21.40245920425795],
              [40.622941056760524, -22.095670948179702],
              [40.31261842457676, -22.154019295218113],
              [40.10702285197361, -21.552205619368607],
              [37.74572097010864, -21.717136666674868],
              [33.28613298837624, -22.01416061359685],
              [32.83356453237446, -21.198056454805744],
              [32.460788014873586, -20.448027485294205],
              [32.54759916566467, -19.605376172713683],
              [32.281575320924375, -19.172014919593366],
              [32.1884334576128, -18.688965608032717],
              [32.290406619350165, -17.851607530291503],
              [32.59133556510962, -17.555482187863035],
              [32.408601529602734, -16.90073239435239],
              [32.00497683099631, -16.33678244042983],
              [31.72951634056848, -15.685769673488055],
              [31.00302583614632, -13.104250557312513],
              [29.98574725772395, -9.469654846334356]
            ]
          ]
        },
        "id": "GA",
        "properties": {
          "state_fips": "13",
          "id": "GA",
          "name": "Georgia"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [38.83112164761595, 13.085221179656006],
              [40.33499173873101, 14.230645996570919],
              [40.47532933476248, 13.427210934075491],
              [42.40865909640454, 13.764450689741105],
              [44.6767307151626, 14.19209508494302],
              [47.90644913807532, 14.840631810609738],
              [50.03554636498203, 15.300580854897381],
              [50.32669949710781, 14.96795385483783],
              [50.668055683422466, 14.959817124309831],
              [50.96912082377844, 14.330604605790324],
              [51.4265572369558, 13.878055739760248],
              [51.902582957298506, 13.735874474563497],
              [51.19446732995568, 12.44390146159069],
              [51.4406874615432, 12.13567024991132],
              [51.244888528145324, 11.79536736601554],
              [51.32790908699685, 11.190508173125801],
              [51.73060785649641, 10.790638192692475],
              [52.500679730989525, 10.335947399313243],
              [52.46094670589766, 10.05563871161947],
              [51.91183998278616, 9.263250435597586],
              [51.34413831185778, 8.79722310712019],
              [51.322452315831, 8.806999377407154],
              [50.87242960032202, 8.793393027077443],
              [50.56165098569023, 8.38008203887622],
              [46.800527116881064, 7.596743747115674],
              [42.28826667869593, 6.758987673107011],
              [39.938072997373844, 6.360770269525436],
              [39.4885346782259, 9.098625557225562],
              [38.83112164761595, 13.085221179656006]
            ]
          ]
        },
        "id": "PA",
        "properties": {
          "state_fips": "42",
          "id": "PA",
          "name": "Pennsylvania"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [5.232302341705314, 6.100502194766141],
              [8.115725638409339, 6.08310062060036],
              [10.889837752746782, 6.175625870690891],
              [14.342323006527405, 6.402325557921876],
              [14.832450323074378, 5.810609268679748],
              [15.075091264997008, 5.723741186360552],
              [14.898367851119213, 5.285318652562521],
              [14.953385623683802, 4.682478227465437],
              [15.28208488009568, 3.777251591521966],
              [16.032574742695502, 3.015755320063848],
              [16.830760885817682, 2.3985182169455803],
              [17.036902288370243, 1.4205723283903227],
              [17.51537320281753, 1.5777832800662779],
              [18.31577739618122, 1.2665368898347271],
              [18.113218966286027, 0.8366194473500428],
              [18.180828302830314, 0.4984175561358074],
              [17.854624669730057, -0.1930081594727179],
              [17.87440796480471, -0.6887820814416525],
              [18.99444629139633, -1.663679786397937],
              [19.12094229207524, -1.5469928412412368],
              [19.944729538126083, -2.1601692206243652],
              [19.965287679394898, -2.6140794604387354],
              [20.215168766590644, -3.0171493348344476],
              [20.01245905267168, -3.369998553490645],
              [20.608542199568824, -4.218946995851972],
              [20.98814880524312, -4.222124578597507],
              [21.044722198478354, -4.505954689634359],
              [20.853142009280027, -5.484854932980091],
              [20.41565486933432, -5.729125175356537],
              [20.253550457373642, -5.74491641413193],
              [20.125223117481447, -5.752340137305425],
              [19.990626671400605, -6.3342577563858],
              [20.070302301519533, -6.880498200568146],
              [19.762370997143343, -7.284807043877931],
              [18.215719328729932, -7.396496609312152],
              [18.91115799702093, -6.4226337351001135],
              [18.65891295824288, -5.850683288161278],
              [15.676019215716805, -6.013701780498804],
              [11.567710732996542, -6.172884118652616],
              [8.004447953855829, -6.247889935116514],
              [7.982342382585424, -4.73606731767284],
              [7.94104145383076, -1.0449873599853543],
              [7.9113034750434394, 1.6820133069140546],
              [7.411088122778722, 1.9537063051410715],
              [7.176433552270321, 2.479781495118154],
              [6.7592684991595835, 2.9262232583999523],
              [7.243614499405691, 3.8389123073680302],
              [6.798604538949407, 3.9207983417875436],
              [6.278187771645446, 4.334962006278721],
              [5.888813385131851, 5.067028191133494],
              [5.5862219378461315, 5.281159863558857],
              [5.232302341705314, 6.100502194766141]
            ]
          ]
        },
        "id": "MO",
        "properties": {
          "state_fips": "29",
          "id": "MO",
          "name": "Missouri"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-24.508073939597942, 9.362382377682238],
              [-21.335966722004095, 8.953275365628336],
              [-18.196656430914253, 8.578014202756238],
              [-15.152903258374172, 8.272870747965198],
              [-13.35821337181433, 8.12316048686218],
              [-8.875992269316527, 7.793223250682945],
              [-9.06871204032343, 4.777017978361577],
              [-9.28327737845553, 1.1810088760423323],
              [-9.42939639919243, -1.1404876757091695],
              [-9.625989948957674, -4.322306454015575],
              [-11.897496640110537, -4.144728390537844],
              [-15.060585799028553, -3.908935429140613],
              [-19.459550118592276, -3.476849106399473],
              [-24.283515873169563, -2.8896389223507097],
              [-26.155319692909288, -2.638911998594156],
              [-25.664175964529647, 0.857672140777817],
              [-25.660539960321472, 1.1960163084304347],
              [-25.288070622587128, 3.745459053858551],
              [-24.97560136150446, 5.990873734227155],
              [-24.508073939597942, 9.362382377682238]
            ]
          ]
        },
        "id": "CO",
        "properties": {
          "state_fips": "08",
          "id": "CO",
          "name": "Colorado"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-34.99847978250568, 14.125491021182894],
              [-34.69729677553158, 14.051718411801874],
              [-30.885366548523038, 13.390009380661867],
              [-28.465293212779216, 13.003957521918709],
              [-28.943725537052813, 10.013623430336263],
              [-27.73392345873961, 9.814508362894804],
              [-24.508073939597942, 9.362382377682238],
              [-24.97560136150446, 5.990873734227155],
              [-25.288070622587128, 3.745459053858551],
              [-25.660539960321472, 1.1960163084304347],
              [-25.664175964529647, 0.857672140777817],
              [-26.155319692909288, -2.638911998594156],
              [-28.106439135428744, -2.3625382354443416],
              [-31.05590438951002, -1.9019679727102852],
              [-35.34317205424363, -1.172778985918284],
              [-37.872053320732256, -0.7010455947039098],
              [-37.01421497110824, 3.759737423158983],
              [-36.43475883841339, 6.731456783913471],
              [-35.699525738529566, 10.505875933951494],
              [-34.99847978250568, 14.125491021182894]
            ]
          ]
        },
        "id": "UT",
        "properties": {
          "state_fips": "49",
          "id": "UT",
          "name": "Utah"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [19.762370997143343, -7.284807043877931],
              [20.070302301519533, -6.880498200568146],
              [19.990626671400605, -6.3342577563858],
              [20.125223117481447, -5.752340137305425],
              [20.253550457373642, -5.74491641413193],
              [20.41565486933432, -5.729125175356537],
              [23.665169084883935, -5.486124759294474],
              [23.578241070857104, -4.94361970920724],
              [24.10595173297125, -5.03522794355232],
              [27.710170666573646, -4.6494058859923895],
              [28.906461733275172, -4.605547970730573],
              [30.414771905972835, -4.428010019746294],
              [31.970227450411173, -4.334157564539993],
              [33.9933881658244, -4.121763623944154],
              [34.02250691655816, -4.0623920067885955],
              [35.88715554392314, -3.833825074660812],
              [38.74756619692505, -3.4345687301460317],
              [38.76021779514268, -3.8007680996321227],
              [38.33147712820748, -4.373168573521223],
              [38.11584664233599, -4.9609046327594415],
              [37.50031218500711, -5.004358925531298],
              [37.14854735822719, -5.44618566919809],
              [36.715467934581255, -5.3308945121099995],
              [36.1055171621274, -5.84891522076963],
              [35.979843862863824, -6.32488451329411],
              [35.404811889872136, -6.547315051172395],
              [34.85345157533117, -7.1207040520962135],
              [34.19810423216075, -7.208996660765053],
              [33.672341987045684, -7.733924409413833],
              [33.69830195016968, -8.06765601107707],
              [33.0791020686985, -8.376053795212146],
              [33.09079842315201, -9.096696582941675],
              [29.98574725772395, -9.469654846334356],
              [26.936424328684332, -9.761467432475836],
              [23.688146762779922, -10.040001937420099],
              [20.688029826933494, -10.272420520962006],
              [18.56150480755671, -10.403579058977286],
              [18.86886446491323, -10.01469940403107],
              [18.987632406458054, -9.32614121571611],
              [18.823670385524736, -9.097947581990928],
              [19.410461073927905, -8.46852625232758],
              [19.614525446606805, -8.023900870519622],
              [19.762370997143343, -7.284807043877931]
            ]
          ]
        },
        "id": "TN",
        "properties": {
          "state_fips": "47",
          "id": "TN",
          "name": "Tennessee"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-12.347973328358991, 20.092622534418496],
              [-12.56267077019553, 17.498092198744992],
              [-12.846566357571382, 14.129378623228591],
              [-13.13150854570941, 10.782735358925931],
              [-13.35821337181433, 8.12316048686218],
              [-15.152903258374172, 8.272870747965198],
              [-18.196656430914253, 8.578014202756238],
              [-21.335966722004095, 8.953275365628336],
              [-24.508073939597942, 9.362382377682238],
              [-27.73392345873961, 9.814508362894804],
              [-28.943725537052813, 10.013623430336263],
              [-28.465293212779216, 13.003957521918709],
              [-27.748699154377015, 17.45500677658761],
              [-27.300043511945255, 20.346581339061963],
              [-27.056761634406243, 21.887077000181463],
              [-26.3282557206729, 21.746479183090173],
              [-22.96622884249326, 21.295954076642772],
              [-20.66228430255766, 20.976234159619153],
              [-17.647302402666234, 20.601897697882293],
              [-14.691952080157293, 20.311751354336053],
              [-12.347973328358991, 20.092622534418496]
            ]
          ]
        },
        "id": "WY",
        "properties": {
          "state_fips": "56",
          "id": "WY",
          "name": "Wyoming"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [40.33499173873101, 14.230645996570919],
              [41.51560892226969, 15.304646740648302],
              [41.662268651117714, 15.751517155409848],
              [42.009217064224664, 16.059733858984842],
              [41.580485305700414, 16.634240528646565],
              [41.34109777443343, 17.411526915618666],
              [42.48726904479612, 17.988343482137388],
              [43.30638320128654, 18.144530081522873],
              [44.05981280507555, 18.183479834431957],
              [44.506623809228984, 17.971113677083032],
              [45.39112888690814, 18.28643366664496],
              [45.82251369431486, 18.31525130128879],
              [46.32635490017728, 18.650793752667695],
              [46.819721677429115, 19.29338971931613],
              [47.226399397578064, 19.536962368405206],
              [47.00489453257517, 20.22718402244965],
              [47.18574134341197, 20.516304577446142],
              [46.547277299366975, 21.01446020962129],
              [46.6087281144824, 21.31539218644351],
              [47.34015447516535, 21.985553913909243],
              [47.55304417211835, 22.482043506685834],
              [48.30060217931097, 23.623269273558513],
              [48.898662122765835, 24.2024880590195],
              [50.59837165049921, 24.613471688319713],
              [52.24102803048951, 25.06046609559884],
              [52.42368828898382, 23.89895124007304],
              [52.70988773475773, 23.53685468212621],
              [52.83077106399223, 22.944814553140887],
              [52.729324742003364, 22.21630856982989],
              [53.49882302296086, 20.804451165791598],
              [54.017938229477814, 18.524528062735385],
              [53.96738786373184, 16.47276844064959],
              [54.03811225564949, 16.37644806409819],
              [54.43471616183223, 14.135432026487853],
              [54.64175309437856, 13.928850393184108],
              [54.18698796305469, 13.472473166601775],
              [54.42199100570848, 13.17022871082572],
              [55.415240807281876, 13.1639773023099],
              [55.76605223909092, 13.428820267985609],
              [56.65315049549722, 13.705747649887092],
              [57.08767625139027, 14.170628271358792],
              [57.821856339971845, 14.030823458618483],
              [57.11696251301055, 13.389167687961582],
              [55.65647586031797, 12.368225537683351],
              [54.11090221366495, 11.72426163162446],
              [53.810753217843036, 12.170232850294127],
              [53.877505833917496, 13.07852254518689],
              [51.902582957298506, 13.735874474563497],
              [51.4265572369558, 13.878055739760248],
              [50.96912082377844, 14.330604605790324],
              [50.668055683422466, 14.959817124309831],
              [50.32669949710781, 14.96795385483783],
              [50.03554636498203, 15.300580854897381],
              [47.90644913807532, 14.840631810609738],
              [44.6767307151626, 14.19209508494302],
              [42.40865909640454, 13.764450689741105],
              [40.47532933476248, 13.427210934075491],
              [40.33499173873101, 14.230645996570919]
            ]
          ]
        },
        "id": "NY",
        "properties": {
          "state_fips": "36",
          "id": "NY",
          "name": "New York"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-9.06871204032343, 4.777017978361577],
              [-5.469733046639801, 4.573583852752221],
              [-2.514090072751898, 4.455391256422575],
              [0.5168218340117896, 4.3754942416699585],
              [4.009422116673445, 4.332252162909866],
              [6.278187771645446, 4.334962006278721],
              [6.798604538949407, 3.9207983417875436],
              [7.243614499405691, 3.8389123073680302],
              [6.7592684991595835, 2.9262232583999523],
              [7.176433552270321, 2.479781495118154],
              [7.411088122778722, 1.9537063051410715],
              [7.9113034750434394, 1.6820133069140546],
              [7.94104145383076, -1.0449873599853543],
              [7.982342382585424, -4.73606731767284],
              [4.701053564049302, -4.7600360619849],
              [2.2587061207601584, -4.746587478726149],
              [-0.8825874721184028, -4.6936125927047065],
              [-3.9528325640552455, -4.591690344434655],
              [-6.5270588503073235, -4.479787412922507],
              [-9.625989948957674, -4.322306454015575],
              [-9.42939639919243, -1.1404876757091695],
              [-9.28327737845553, 1.1810088760423323],
              [-9.06871204032343, 4.777017978361577]
            ]
          ]
        },
        "id": "KS",
        "properties": {
          "state_fips": "20",
          "id": "KS",
          "name": "Kansas"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-36.6949066527914, -34.81243444240748],
                [-36.457509634126744, -34.333301895665315],
                [-35.43995369066947, -35.160604917561145],
                [-35.25837735112324, -35.60985526296322],
                [-35.73984190758412, -35.87377056567491],
                [-36.09177181105425, -35.56002204633219],
                [-36.4129803704785, -34.800524762044255],
                [-36.6949066527914, -34.81243444240748]
              ]
            ],
            [
              [
                [-37.30389434452681, -34.0445959023462],
                [-37.09041106385311, -33.69667941900846],
                [-36.55820111975531, -33.7031269394586],
                [-36.31416178505356, -34.10812861960989],
                [-37.02340305012702, -34.369385031668216],
                [-37.30389434452681, -34.0445959023462]
              ]
            ],
            [
              [
                [-38.15388683795494, -33.72517771385728],
                [-37.65757116340677, -33.70624844285308],
                [-37.38458679740117, -34.18977092598879],
                [-37.8405284296208, -34.195144661636725],
                [-38.15388683795494, -33.72517771385728]
              ]
            ],
            [
              [
                [-38.81751003360843, -33.186544539919275],
                [-38.48770342918846, -32.82930964555518],
                [-37.952595264301586, -32.93971938924348],
                [-38.173789021394754, -33.39932809395266],
                [-38.51072195274968, -33.572100518199235],
                [-38.81751003360843, -33.186544539919275]
              ]
            ],
            [
              [
                [-48.04281610389614, -34.39018358531245],
                [-47.46068040563937, -33.78392918496821],
                [-47.30592472187585, -34.28098006984885],
                [-47.74837071265665, -34.48094379362353],
                [-48.04281610389614, -34.39018358531245]
              ]
            ],
            [
              [
                [-48.81429234621338, -35.1237722506189],
                [-48.21584220730413, -34.56495951862989],
                [-47.48499012126696, -34.50941573066997],
                [-47.4278853399795, -35.03800381256706],
                [-48.349484743656845, -35.747910956097655],
                [-48.81429234621338, -35.1237722506189]
              ]
            ],
            [
              [
                [-54.998466904177924, -37.437306007018705],
                [-54.720089022263856, -37.19842957248298],
                [-54.08301513131703, -37.121205355791346],
                [-53.984405343331154, -37.4608290050267],
                [-54.998466904177924, -37.437306007018705]
              ]
            ],
            [
              [
                [-55.3106411634176, -31.474570143265638],
                [-54.63241713137739, -31.414975276953435],
                [-54.71486951711758, -31.99973956811899],
                [-55.3106411634176, -31.474570143265638]
              ]
            ],
            [
              [
                [-57.01317361370386, -38.38344836196202],
                [-56.55188235458481, -38.3130227232157],
                [-56.28032655608688, -37.82237934888605],
                [-55.96680967756039, -38.00286650127755],
                [-56.242338129615035, -38.35301379492395],
                [-57.01317361370386, -38.38344836196202]
              ]
            ],
            [
              [
                [-54.43945092195006, -25.89280957482341],
                [-53.242296400257764, -25.359289009994],
                [-52.70222067792636, -25.230405449920724],
                [-52.43829162190757, -25.36453891618833],
                [-52.51735022056361, -25.784217054232187],
                [-51.70069504185493, -25.931618985098318],
                [-51.56226506263376, -25.740430689901558],
                [-51.91332226619658, -25.01152499175835],
                [-52.31923984791796, -24.745484032802892],
                [-52.44514256555168, -24.170589413703347],
                [-53.25960278637067, -23.296841863028845],
                [-53.01810907587561, -22.774254913482423],
                [-52.24875172466886, -22.84278880778539],
                [-51.82059150267283, -22.50485394294182],
                [-51.67821536236385, -22.02727566124625],
                [-51.195118379780986, -21.542917231206417],
                [-50.900262161063154, -21.5772851165559],
                [-50.35537687115035, -21.150143804380384],
                [-49.783421518397105, -21.154589739137066],
                [-49.23837861265335, -20.707830600610947],
                [-48.569995468147184, -21.19562179423758],
                [-47.73977951822097, -21.19392319053528],
                [-47.730386092086675, -21.48632127000986],
                [-47.3389815424795, -21.638385471093237],
                [-46.746192529339844, -21.463794151359544],
                [-46.076297700593194, -21.730124242059055],
                [-45.417013182324354, -21.700915204066618],
                [-45.05057934359892, -21.859887678430248],
                [-44.489570613662096, -21.578435803055896],
                [-43.575387659648285, -21.942669599077846],
                [-42.94206096455123, -25.11014237437888],
                [-42.242750444326475, -28.590522452129413],
                [-41.672166166059505, -31.42494674601067],
                [-41.014699830743936, -31.259645651547658],
                [-39.682685557124664, -32.11266771127074],
                [-39.51282650484923, -32.427259979707635],
                [-39.12998459381964, -32.05578986225666],
                [-39.06152051484804, -31.52774685217728],
                [-38.744018152786154, -31.270183044747526],
                [-38.32751579799351, -31.709020207263926],
                [-37.245401975892754, -32.3026407102865],
                [-36.06307821743566, -33.612614462163506],
                [-35.336780416346926, -33.87403261484685],
                [-34.69651632291473, -33.95425090600526],
                [-34.31286108335601, -34.73771749136872],
                [-34.38718751464484, -35.163709488964045],
                [-35.39106290312172, -35.08019656278827],
                [-35.67388449574575, -34.64064322462514],
                [-36.14634015564974, -34.43393727546635],
                [-36.10094420995864, -33.92888072706162],
                [-36.56000657864718, -33.58862925454531],
                [-36.90909572508655, -33.56127364725834],
                [-37.034726398112866, -33.198680719771616],
                [-37.54127905924679, -32.658322092299244],
                [-38.41950753947667, -32.65656700765939],
                [-38.883566500068994, -32.93739560403411],
                [-39.559360124887064, -32.74675927928609],
                [-39.76540723022495, -32.52020169006258],
                [-41.19091809109078, -31.97720525486708],
                [-42.41408397583803, -31.795074281481533],
                [-43.09831242407728, -32.00801538601931],
                [-44.40115845076766, -31.51195813388965],
                [-44.680609002712245, -31.228347092588344],
                [-45.13263016879475, -31.236378470748996],
                [-45.35313526369344, -31.492997458815964],
                [-45.32167033781976, -32.22882277677482],
                [-45.95494436323639, -32.309804324784125],
                [-46.14183329280519, -32.61538171233206],
                [-47.1576177836175, -33.23510691960565],
                [-47.243680562353035, -32.60536954540368],
                [-47.01246657854585, -32.10334035028118],
                [-47.09089342957889, -31.315636931252804],
                [-47.46931053001868, -31.81691542328666],
                [-47.86121288026218, -32.687724629385954],
                [-48.244698468827536, -32.87895597407448],
                [-48.485888568531905, -33.24680035861941],
                [-47.997517251367526, -33.54017569994586],
                [-48.45484071796391, -34.250709821658944],
                [-49.264356612987, -34.67422501330087],
                [-49.68480297268186, -35.26986614061936],
                [-50.16607725469029, -35.659224084343684],
                [-50.62708489988485, -35.79089077347618],
                [-50.951124003871385, -36.42485652007815],
                [-51.67736601824551, -36.577993474144336],
                [-52.244832831493596, -36.86044248546918],
                [-52.831104455222004, -36.92244232188761],
                [-53.191837424478635, -37.17946840049706],
                [-53.79298815473599, -37.06681386886683],
                [-53.24206174886151, -36.53891285106137],
                [-52.623743975476806, -36.300762322821555],
                [-52.18077059478802, -36.47015870471733],
                [-52.05413114615136, -36.049279594430296],
                [-51.04659881506414, -35.455857001376415],
                [-50.43242734648813, -34.85018668247467],
                [-50.166386936451126, -33.54955403777656],
                [-50.58437239377409, -33.74175347184257],
                [-50.94166552624215, -33.47025176959082],
                [-51.263785127232836, -33.59884328782949],
                [-51.86802354352054, -33.24040626730909],
                [-52.64867444395009, -33.52890187268724],
                [-52.660904545125746, -32.89204637636048],
                [-52.44414995083392, -32.674111204489854],
                [-52.82265780591608, -32.12450729768823],
                [-53.58588101891131, -32.20159252938369],
                [-54.14044490556415, -31.309189778092993],
                [-53.87626717928915, -31.06956067678268],
                [-54.29484904468442, -30.488964869510596],
                [-54.30920389207731, -29.97314075967047],
                [-53.47992455339672, -28.92624061088697],
                [-53.0827146101621, -28.65464647139506],
                [-52.640906372692086, -28.9269769613501],
                [-52.27724962156728, -28.578559034407725],
                [-51.69535665306575, -28.581425519245755],
                [-51.48679914335003, -28.255229077983778],
                [-51.56484181052733, -27.14432027437266],
                [-52.35140271652846, -27.46098846952882],
                [-53.03173920908343, -27.309098177603992],
                [-53.32483566469255, -27.38427514037045],
                [-53.834909204722386, -27.140715744868864],
                [-53.97145543082319, -26.613377478962697],
                [-54.43945092195006, -25.89280957482341]
              ]
            ]
          ]
        },
        "id": "AK",
        "properties": {
          "state_fips": "02",
          "id": "AK",
          "name": "Alaska"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-47.863802953491714, 17.010183457362565],
              [-45.06846628265557, 16.302709261826934],
              [-41.462842571718724, 15.486875318724378],
              [-40.0435938969196, 15.162887751962236],
              [-37.14799828611833, 14.555272901530328],
              [-34.99847978250568, 14.125491021182894],
              [-35.699525738529566, 10.505875933951494],
              [-36.43475883841339, 6.731456783913471],
              [-37.01421497110824, 3.759737423158983],
              [-37.872053320732256, -0.7010455947039098],
              [-38.31780140515791, -3.1041347404961384],
              [-38.62950485211584, -3.5229770207675335],
              [-38.879945172911064, -3.546791759883612],
              [-39.119753947013045, -3.1035459508327197],
              [-39.7315782983981, -2.985433314017677],
              [-40.055502715557964, -3.0987135725575228],
              [-40.035336281496704, -3.6674101883255035],
              [-40.22544324038213, -4.900898963440484],
              [-40.18262231367647, -5.991821639718877],
              [-40.40820700521479, -6.369020877324143],
              [-41.620278809484795, -4.548056843135525],
              [-44.29641737334643, -0.5258839352510203],
              [-46.40564387017499, 2.656801612896701],
              [-47.99601200778363, 5.044785367454413],
              [-50.132232568069156, 8.257963542615714],
              [-49.40073302310197, 11.057924246288183],
              [-48.47764653806216, 14.646324277992639],
              [-47.863802953491714, 17.010183457362565]
            ]
          ]
        },
        "id": "NV",
        "properties": {
          "state_fips": "32",
          "id": "NV",
          "name": "Nevada"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [15.075091264997008, 5.723741186360552],
              [15.200768168611317, 6.430679869627689],
              [15.698486195718013, 6.63565758942464],
              [15.737274201431992, 7.068976533336327],
              [16.020234397766046, 7.426863852303323],
              [16.029200059741974, 7.946907565125828],
              [15.632687769430953, 8.364171780661746],
              [15.753106621342157, 8.88305220893522],
              [16.618164251359723, 9.08483204862378],
              [17.291747716893084, 9.50395460755834],
              [17.327545098902128, 9.948846486827224],
              [17.59706551401836, 10.17342819217037],
              [17.594473195580413, 11.120788346953626],
              [17.071676450565164, 11.41778604354878],
              [16.85410006811815, 11.882997917880136],
              [16.469007345093125, 12.237221056314997],
              [20.542689229733853, 12.456558397123937],
              [22.699553025107598, 12.633163612286982],
              [22.677495903435613, 12.053595234030128],
              [23.071765577179967, 11.404678154900903],
              [23.519085996275997, 10.331683364348805],
              [23.841328759997197, 6.67717038076991],
              [24.138046567204313, 3.221766380166703],
              [23.903401794901264, 2.55623698544811],
              [24.372129025231736, 1.5319003045580601],
              [23.57466554390122, -0.41968117773720415],
              [23.321337571694933, -0.8502217095218341],
              [23.394657473035046, -1.5518421262089754],
              [23.12375933542605, -1.9838265836410254],
              [23.3511932970707, -2.5470564232789736],
              [22.443967171008225, -2.8826926776959576],
              [22.375879251094233, -3.2005000755272035],
              [22.62332264026559, -3.580711832206857],
              [22.414942706769033, -3.861061349916647],
              [21.44424766115622, -3.4535127976145743],
              [21.180455012417752, -3.512969973573724],
              [20.85824316766749, -4.064287454261075],
              [20.98814880524312, -4.222124578597507],
              [20.608542199568824, -4.218946995851972],
              [20.01245905267168, -3.369998553490645],
              [20.215168766590644, -3.0171493348344476],
              [19.965287679394898, -2.6140794604387354],
              [19.944729538126083, -2.1601692206243652],
              [19.12094229207524, -1.5469928412412368],
              [18.99444629139633, -1.663679786397937],
              [17.87440796480471, -0.6887820814416525],
              [17.854624669730057, -0.1930081594727179],
              [18.180828302830314, 0.4984175561358074],
              [18.113218966286027, 0.8366194473500428],
              [18.31577739618122, 1.2665368898347271],
              [17.51537320281753, 1.5777832800662779],
              [17.036902288370243, 1.4205723283903227],
              [16.830760885817682, 2.3985182169455803],
              [16.032574742695502, 3.015755320063848],
              [15.28208488009568, 3.777251591521966],
              [14.953385623683802, 4.682478227465437],
              [14.898367851119213, 5.285318652562521],
              [15.075091264997008, 5.723741186360552]
            ]
          ]
        },
        "id": "IL",
        "properties": {
          "state_fips": "17",
          "id": "IL",
          "name": "Illinois"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [52.24102803048951, 25.06046609559884],
              [54.369684251549934, 25.56295837626989],
              [56.02136302405732, 26.01546060713528],
              [56.11175854698335, 25.718067599084883],
              [55.97248085575551, 25.17478608835031],
              [56.278556054446824, 24.77265342089067],
              [55.87896209996233, 23.964247811129],
              [55.45067864515575, 23.73705226731728],
              [55.618503667142434, 23.065779668730954],
              [55.52115561067974, 22.16596995952841],
              [55.294733391718424, 21.413683479878287],
              [55.439020511399974, 20.113211853224612],
              [55.56551250966346, 19.724610801905385],
              [55.44337327302354, 19.206740994363997],
              [55.746031742211564, 18.892291044867257],
              [54.017938229477814, 18.524528062735385],
              [53.49882302296086, 20.804451165791598],
              [52.729324742003364, 22.21630856982989],
              [52.83077106399223, 22.944814553140887],
              [52.70988773475773, 23.53685468212621],
              [52.42368828898382, 23.89895124007304],
              [52.24102803048951, 25.06046609559884]
            ]
          ]
        },
        "id": "VT",
        "properties": {
          "state_fips": "50",
          "id": "VT",
          "name": "Vermont"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-34.95634411750104, 35.319057767924974],
              [-33.31659318514526, 34.97771579487742],
              [-30.355092114731818, 34.402304605244964],
              [-26.053048655333505, 33.65584230333725],
              [-23.43516607623554, 33.25820695236762],
              [-21.12552273350493, 32.93169842211294],
              [-18.042850198036703, 32.540536272825015],
              [-15.28953433799164, 32.232850143350134],
              [-11.318090763061143, 31.8619317292712],
              [-11.727695012400378, 27.024492079996968],
              [-12.081323464827218, 22.902169142087033],
              [-12.347973328358991, 20.092622534418496],
              [-14.691952080157293, 20.311751354336053],
              [-17.647302402666234, 20.601897697882293],
              [-20.66228430255766, 20.976234159619153],
              [-22.96622884249326, 21.295954076642772],
              [-26.3282557206729, 21.746479183090173],
              [-27.056761634406243, 21.887077000181463],
              [-27.300043511945255, 20.346581339061963],
              [-27.77751766301119, 21.193508812496063],
              [-28.11361046277446, 21.149486519179753],
              [-28.259025983600544, 20.711277237951514],
              [-28.980730805956306, 20.878148645786517],
              [-29.488407403945743, 20.83446196959391],
              [-29.851831528787983, 21.041237033057335],
              [-30.12477053909803, 20.722159707865174],
              [-30.795140877816323, 21.010707093608673],
              [-31.090504581200214, 20.818615839878667],
              [-31.422930529996428, 20.96887849036002],
              [-31.433816121077406, 21.653577459077283],
              [-31.730025059740726, 22.14339754775842],
              [-32.14226758604925, 22.34850188189774],
              [-32.02966842627365, 22.914844575347473],
              [-32.24896193382729, 23.17445681503216],
              [-32.505894424323046, 24.054789589201903],
              [-32.46503443432498, 24.640484501389267],
              [-32.85957183426461, 24.898240860326858],
              [-33.6540099326341, 24.434282650975376],
              [-34.02358054224982, 24.88335082054892],
              [-33.93032783341209, 25.43690578928385],
              [-33.50204386236016, 25.682522587138045],
              [-33.631943225447245, 26.440576579002965],
              [-32.92480883410635, 27.877732286292193],
              [-33.523902345795065, 27.968686163339676],
              [-33.56777764017963, 28.278176989270733],
              [-34.024875256381634, 28.844344857992155],
              [-34.58869984510096, 30.035965890231154],
              [-34.97350747877123, 30.24356870435077],
              [-35.269896342726554, 31.064450582139095],
              [-35.11973464601563, 31.459117918394675],
              [-35.593031306322516, 32.40213267166301],
              [-34.95634411750104, 35.319057767924974]
            ]
          ]
        },
        "id": "MT",
        "properties": {
          "state_fips": "30",
          "id": "MT",
          "name": "Montana"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [3.7212180617901867, 14.892315613087801],
              [5.7539788243886365, 14.891079528179583],
              [9.476921541270828, 14.942532789529407],
              [11.65391241650118, 15.004470975893014],
              [15.05703135237084, 15.150067890296958],
              [15.11437564689272, 14.697474321659383],
              [15.44133239797866, 14.427939277596456],
              [15.199902286905134, 14.03904493328543],
              [15.3026963432927, 13.384671708977764],
              [15.536902988821941, 12.875866676312603],
              [16.27709124771212, 12.625469703862654],
              [16.469007345093125, 12.237221056314997],
              [16.85410006811815, 11.882997917880136],
              [17.071676450565164, 11.41778604354878],
              [17.594473195580413, 11.120788346953626],
              [17.59706551401836, 10.17342819217037],
              [17.327545098902128, 9.948846486827224],
              [17.291747716893084, 9.50395460755834],
              [16.618164251359723, 9.08483204862378],
              [15.753106621342157, 8.88305220893522],
              [15.632687769430953, 8.364171780661746],
              [16.029200059741974, 7.946907565125828],
              [16.020234397766046, 7.426863852303323],
              [15.737274201431992, 7.068976533336327],
              [15.698486195718013, 6.63565758942464],
              [15.200768168611317, 6.430679869627689],
              [15.075091264997008, 5.723741186360552],
              [14.832450323074378, 5.810609268679748],
              [14.342323006527405, 6.402325557921876],
              [10.889837752746782, 6.175625870690891],
              [8.115725638409339, 6.08310062060036],
              [5.232302341705314, 6.100502194766141],
              [4.953377738904997, 6.5434749706535955],
              [5.125626248150805, 7.004468601859078],
              [4.868700561265953, 7.945425642815447],
              [4.8526083336482255, 8.755252427740619],
              [4.497939209175574, 8.967012577670909],
              [4.548333726142891, 9.780773660424464],
              [3.935022691804466, 10.877971904532389],
              [3.7237301085876835, 11.85481433624285],
              [3.3018915697624376, 12.599393160620059],
              [3.752070359879543, 13.751549760439115],
              [3.461040151328742, 14.108212240774916],
              [3.7212180617901867, 14.892315613087801]
            ]
          ]
        },
        "id": "IA",
        "properties": {
          "state_fips": "19",
          "id": "IA",
          "name": "Iowa"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [36.01780084569047, -8.679480020950605],
              [36.991163531705105, -8.216648201075278],
              [37.657971707034754, -7.794303260609844],
              [37.9424307907524, -7.79996389173352],
              [40.92456044861147, -7.500519554772036],
              [41.20583559574702, -7.585759326078427],
              [41.65597109457591, -8.038872437686448],
              [41.674545284912014, -8.39090215781372],
              [44.38560508767512, -7.983732125820961],
              [47.63660025389561, -10.323292581433622],
              [47.2464038759171, -10.55411160762038],
              [46.78766975504237, -11.12912203967346],
              [46.513647598271135, -11.656799633146063],
              [46.43576395976432, -12.505044759561857],
              [46.08392706063311, -13.185015019620119],
              [45.567327871654356, -13.261077863933034],
              [45.598553446933565, -13.583683276077556],
              [44.94264870494769, -14.171590362220547],
              [44.715055006106745, -14.652322321734887],
              [43.95654386670061, -15.168755947712357],
              [43.58708105160978, -15.281818017225188],
              [43.733743200883374, -15.679276923363778],
              [42.86240283932247, -16.44302667577174],
              [42.79457178027499, -16.70679022004171],
              [42.16191867969604, -16.505007366723746],
              [42.09047336228367, -16.049534238901764],
              [41.80573183139409, -15.545807375946572],
              [41.20939118096392, -15.180976298394016],
              [41.08534159458135, -14.540754059905076],
              [40.832578406726036, -14.057643968415666],
              [40.25717986425694, -13.811145642781772],
              [39.66628567564529, -13.267474321428407],
              [39.55546467208096, -12.861505995998035],
              [38.817676678251786, -12.459682992068467],
              [38.61743517952277, -12.095648410001962],
              [37.799255555484955, -11.647721061289655],
              [37.319900636915605, -11.090549246606322],
              [36.731159544980834, -10.14809515338659],
              [36.49086886755267, -10.225814672319757],
              [35.58910430143443, -9.69372540052471],
              [35.58290316423658, -9.381286968200001],
              [36.01780084569047, -8.679480020950605]
            ]
          ]
        },
        "id": "SC",
        "properties": {
          "state_fips": "45",
          "id": "SC",
          "name": "South Carolina"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [56.02136302405732, 26.01546060713528],
              [56.092429441358306, 26.714366103298936],
              [56.64795662774252, 27.077308338402638],
              [57.26276662037511, 25.24141560281879],
              [58.422160821463926, 21.497404785199677],
              [58.81876519772843, 21.275668558124394],
              [59.19931581462301, 20.825825261831618],
              [59.10877086604009, 20.219049585534336],
              [58.483227458164414, 19.893491385925522],
              [58.23795343205689, 19.442313428325704],
              [55.746031742211564, 18.892291044867257],
              [55.44337327302354, 19.206740994363997],
              [55.56551250966346, 19.724610801905385],
              [55.439020511399974, 20.113211853224612],
              [55.294733391718424, 21.413683479878287],
              [55.52115561067974, 22.16596995952841],
              [55.618503667142434, 23.065779668730954],
              [55.45067864515575, 23.73705226731728],
              [55.87896209996233, 23.964247811129],
              [56.278556054446824, 24.77265342089067],
              [55.97248085575551, 25.17478608835031],
              [56.11175854698335, 25.718067599084883],
              [56.02136302405732, 26.01546060713528]
            ]
          ]
        },
        "id": "NH",
        "properties": {
          "state_fips": "33",
          "id": "NH",
          "name": "New Hampshire"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-41.96423974477215, -13.082194765336743],
              [-41.46738367228917, -13.062941896700298],
              [-41.27363105870055, -12.830942744613091],
              [-41.26458951072335, -12.305478258796333],
              [-41.71181923263008, -11.970142530374117],
              [-41.53438924434338, -11.474858745980217],
              [-41.570376830387204, -11.072535976216384],
              [-40.996823852822935, -10.71456132731708],
              [-40.84382627094647, -10.300050090180394],
              [-40.80004478123533, -9.604032712813462],
              [-40.40886081787929, -9.122463348872992],
              [-39.97930172604547, -9.002654568225951],
              [-39.62495422213533, -8.673295186121145],
              [-40.032182021455995, -8.138307511925149],
              [-40.19008928433031, -7.302216439085663],
              [-40.481352976131866, -6.772138800550999],
              [-40.40820700521479, -6.369020877324143],
              [-40.18262231367647, -5.991821639718877],
              [-40.22544324038213, -4.900898963440484],
              [-40.035336281496704, -3.6674101883255035],
              [-40.055502715557964, -3.0987135725575228],
              [-39.7315782983981, -2.985433314017677],
              [-39.119753947013045, -3.1035459508327197],
              [-38.879945172911064, -3.546791759883612],
              [-38.62950485211584, -3.5229770207675335],
              [-38.31780140515791, -3.1041347404961384],
              [-37.872053320732256, -0.7010455947039098],
              [-35.34317205424363, -1.172778985918284],
              [-31.05590438951002, -1.9019679727102852],
              [-28.106439135428744, -2.3625382354443416],
              [-26.155319692909288, -2.638911998594156],
              [-26.52203554123075, -5.284138084908625],
              [-27.001608012111717, -8.750591991661937],
              [-27.55582138654281, -12.7396544489806],
              [-27.90715025468659, -15.265702653129024],
              [-28.510697164133013, -19.556816197113193],
              [-30.912782644879208, -19.21299100520583],
              [-33.623743727834714, -18.800464874435463],
              [-35.26520278710426, -17.85899467937213],
              [-38.43964370057883, -16.034052046097152],
              [-42.32866997318434, -13.698055862704281],
              [-41.96423974477215, -13.082194765336743]
            ]
          ]
        },
        "id": "AZ",
        "properties": {
          "state_fips": "04",
          "id": "AZ",
          "name": "Arizona"
        }
      },
      {
        "type": "Feature",
        "geometry": null,
        "id": "DC",
        "properties": {
          "state_fips": "11",
          "id": "DC",
          "name": "District of Columbia"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [51.13328829581077, 8.218128393334274],
              [51.34413831185778, 8.79722310712019],
              [51.91183998278616, 9.263250435597586],
              [52.46094670589766, 10.05563871161947],
              [52.500679730989525, 10.335947399313243],
              [51.73060785649641, 10.790638192692475],
              [51.32790908699685, 11.190508173125801],
              [51.244888528145324, 11.79536736601554],
              [51.4406874615432, 12.13567024991132],
              [51.19446732995568, 12.44390146159069],
              [51.902582957298506, 13.735874474563497],
              [53.877505833917496, 13.07852254518689],
              [53.810753217843036, 12.170232850294127],
              [53.56118642516484, 11.910875516357898],
              [53.55348878562253, 11.912855546685968],
              [53.457607774538474, 11.328795841421963],
              [54.057659990010364, 11.302782546229981],
              [54.19910114439476, 10.989286478361104],
              [54.30399157801949, 9.36429850847617],
              [54.01217918427206, 8.276225501658894],
              [53.5065829599944, 7.554433574355962],
              [53.27387447730498, 6.717072905587518],
              [52.901345508528095, 6.6617040645491805],
              [52.94981798126446, 7.161493985948085],
              [52.2630218581544, 7.242719615105862],
              [51.2942597785608, 7.7291462094552355],
              [51.15679010507081, 8.148981408637328],
              [51.13328829581077, 8.218128393334274]
            ]
          ]
        },
        "id": "NJ",
        "properties": {
          "state_fips": "34",
          "id": "NJ",
          "name": "New Jersey"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [42.28826667869593, 6.758987673107011],
              [46.800527116881064, 7.596743747115674],
              [50.56165098569023, 8.38008203887622],
              [51.5842839176142, 4.692637704301388],
              [53.054809501804094, 4.98946484338065],
              [52.89413480515804, 3.637787835509635],
              [52.00515500853682, 3.2550747634487194],
              [51.191950407109914, 3.762211259886797],
              [50.37936542962787, 4.138070717630447],
              [50.114792194180225, 4.447223721232187],
              [50.158483009844716, 4.856942677911591],
              [49.92830090492399, 5.234037900364716],
              [50.21707386450381, 5.4618772490654806],
              [50.07019184248083, 6.338550906376641],
              [49.43455106066796, 6.53235282457856],
              [49.56507382683836, 5.752290369906138],
              [49.44326764774539, 5.1194863993429305],
              [49.75202413883969, 4.373768069764433],
              [50.06423443431768, 4.1404736218244675],
              [50.161215806479994, 3.638954761958362],
              [49.082316666261065, 3.53465216454818],
              [48.581443031101244, 4.01824915743723],
              [48.19690397628327, 3.676030268145939],
              [47.97177542722367, 4.007544022594229],
              [48.20862546625864, 4.528337726865749],
              [48.32206311073, 5.035062630198357],
              [48.053448276683326, 5.421670017084433],
              [47.00573171303321, 5.867286201001868],
              [46.82350529240118, 6.324718887275159],
              [46.469616905662704, 6.302403552836395],
              [45.97193323904229, 6.952559382280363],
              [45.23817727399968, 7.219242548998128],
              [44.718013894257496, 6.900854449572126],
              [44.669353399131694, 6.56165230695596],
              [43.82442893574222, 6.627450894066925],
              [43.6100615993818, 6.131453049257768],
              [43.307623236410784, 6.172568013806953],
              [42.93136449789904, 5.5762775751512],
              [42.53494503947297, 5.219293032257399],
              [42.28826667869593, 6.758987673107011]
            ]
          ]
        },
        "id": "MD",
        "properties": {
          "state_fips": "24",
          "id": "MD",
          "name": "Maryland"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [59.19931581462301, 20.825825261831618],
              [58.81876519772843, 21.275668558124394],
              [58.422160821463926, 21.497404785199677],
              [57.26276662037511, 25.24141560281879],
              [56.64795662774252, 27.077308338402638],
              [57.09937470013306, 27.488862034909054],
              [57.30346555120839, 28.178457797841958],
              [57.58632755318925, 28.559321672766607],
              [57.851977505541896, 29.360101483028444],
              [57.6355662559904, 29.53311149697071],
              [57.606794764293234, 30.479851354562427],
              [57.84010665964165, 30.800399423785436],
              [57.733344812086, 31.626872688631885],
              [58.63338519856991, 34.20945579767534],
              [59.041012111250694, 34.18779925798739],
              [59.17054076202196, 33.71037856094743],
              [59.48497118370342, 33.61528259184626],
              [60.04447878078249, 34.090445025532304],
              [60.69288081344813, 34.454397431986244],
              [61.78027954431245, 33.93199829277926],
              [62.78310540917717, 30.775743638145634],
              [62.971059912878786, 30.01390009916969],
              [63.80342609559666, 29.97540943913277],
              [63.756574781765096, 29.633970713430706],
              [63.98335811818778, 29.074087399282227],
              [64.35938159239639, 28.73909499575558],
              [64.72751769559186, 28.949275805211443],
              [65.41232839812137, 28.065814624393433],
              [65.12630572524075, 27.47075134583112],
              [64.87103769591197, 27.44133683445147],
              [64.46357092527323, 26.914466232133734],
              [63.87566381135744, 26.69653760305046],
              [63.327222906476905, 25.820387925109344],
              [62.562756886330604, 25.54630499736021],
              [62.1902938313254, 25.60775632927443],
              [61.93917543691247, 25.932932667042692],
              [61.68241419952123, 25.08296084245066],
              [61.607971526627644, 24.235628487837687],
              [61.09577857344173, 24.19472895059429],
              [60.631825080106815, 23.34409700270818],
              [59.77617450024527, 23.20157174183452],
              [59.64569830434855, 22.88779836422666],
              [59.85610716372784, 22.57357043735977],
              [59.363617763954586, 21.752509418562475],
              [59.19931581462301, 20.825825261831618]
            ]
          ]
        },
        "id": "ME",
        "properties": {
          "state_fips": "23",
          "id": "ME",
          "name": "Maine"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [-20.771839666081405, -36.31245126299862],
                [-20.102608339300716, -35.44132661006454],
                [-20.33623380891556, -34.94657749337467],
                [-20.148662480423987, -34.736983168829184],
                [-19.442013812300765, -35.17033888982559],
                [-19.01376323202257, -35.24540404336746],
                [-18.351159773963587, -35.60149903325113],
                [-17.77699074508989, -36.2787521623015],
                [-17.70682002894243, -36.56591792257478],
                [-17.227245240880166, -36.886694133080766],
                [-17.678162597523112, -37.40692130258918],
                [-18.300545991719353, -37.68548744465854],
                [-18.588237772971695, -37.664699809683675],
                [-19.176401995201907, -38.04509142475304],
                [-19.609002041951925, -38.66743998670116],
                [-20.24947624159533, -38.34623862112811],
                [-20.36044788498635, -38.109461579708224],
                [-20.2738000559295, -37.43531074212033],
                [-20.536514548637804, -36.6692231635365],
                [-20.771839666081405, -36.31245126299862]
              ]
            ],
            [
              [
                [-22.579612834653105, -32.848505219677214],
                [-22.19310670680416, -32.56256330160778],
                [-21.965717884924665, -32.90107143881801],
                [-21.291105336263854, -32.788712681825174],
                [-20.635746547111907, -33.19350618452431],
                [-20.74206235878531, -33.61594784724654],
                [-21.664809174951188, -33.84601626205137],
                [-21.91300451264795, -33.25099131722615],
                [-22.356277678405554, -33.1665722175851],
                [-22.579612834653105, -32.848505219677214]
              ]
            ],
            [
              [
                [-23.602439488396307, -32.89125220568782],
                [-23.101460521957037, -32.88376668463704],
                [-22.952641963501662, -33.28619712135791],
                [-23.329173842451496, -33.38873294714083],
                [-23.602439488396307, -32.89125220568782]
              ]
            ],
            [
              [
                [-24.295876724297834, -32.28706594054937],
                [-24.10504279592317, -31.96634680895351],
                [-23.05484969686585, -32.131763542049235],
                [-23.078992236089363, -32.47104556424074],
                [-23.701848484863632, -32.30100923878225],
                [-24.295876724297834, -32.28706594054937]
              ]
            ],
            [
              [
                [-27.024237260177642, -30.906389919375414],
                [-26.58915176678061, -30.889300624269694],
                [-26.268503306241364, -30.548565805758983],
                [-26.009229010079828, -30.704418868313944],
                [-25.789920243862944, -31.26357490593562],
                [-25.264987454333202, -31.72728902134653],
                [-26.564343406177052, -31.71385731416745],
                [-27.024237260177642, -30.906389919375414]
              ]
            ],
            [
              [
                [-31.25561937531849, -29.546539215440887],
                [-31.091075604394426, -29.22660840484898],
                [-30.669314553942627, -28.986308538861934],
                [-29.99376776668675, -29.036548277151326],
                [-29.972226255271416, -29.763947840065406],
                [-30.297568362822084, -30.02903426928947],
                [-30.755054323933898, -29.93331122940492],
                [-31.25561937531849, -29.546539215440887]
              ]
            ]
          ]
        },
        "id": "HI",
        "properties": {
          "state_fips": "15",
          "id": "HI",
          "name": "Hawaii"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [50.56165098569023, 8.38008203887622],
              [50.87242960032202, 8.793393027077443],
              [51.322452315831, 8.806999377407154],
              [51.036923970045024, 8.09679791204427],
              [51.15528074283448, 7.756509190406478],
              [51.60823907689962, 7.3420121506841145],
              [51.870677285664385, 6.607937864783068],
              [52.49927736889988, 5.9687115821599654],
              [52.76718181900302, 5.92080127872492],
              [53.054809501804094, 4.98946484338065],
              [51.5842839176142, 4.692637704301388],
              [50.56165098569023, 8.38008203887622]
            ]
          ]
        },
        "id": "DE",
        "properties": {
          "state_fips": "10",
          "id": "DE",
          "name": "Delaware"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [59.243129053859946, 16.531084441045763],
                [59.54338524858075, 16.057541416192215],
                [59.07418956157283, 15.998084778487643],
                [59.243129053859946, 16.531084441045763]
              ]
            ],
            [
              [
                [58.082770225694375, 15.121869090461146],
                [58.14522098011977, 15.438281783386918],
                [57.691637090097046, 17.159758207982208],
                [58.57783971877393, 17.424740513731017],
                [58.877123209866475, 16.762278584333508],
                [59.152892305549365, 16.617387160126675],
                [58.79458809817972, 16.375224890404013],
                [58.856718363995085, 15.484312880057274],
                [58.082770225694375, 15.121869090461146]
              ]
            ]
          ]
        },
        "id": "RI",
        "properties": {
          "state_fips": "44",
          "id": "RI",
          "name": "Rhode Island"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [20.98814880524312, -4.222124578597507],
              [20.85824316766749, -4.064287454261075],
              [21.180455012417752, -3.512969973573724],
              [21.44424766115622, -3.4535127976145743],
              [22.414942706769033, -3.861061349916647],
              [22.62332264026559, -3.580711832206857],
              [22.375879251094233, -3.2005000755272035],
              [22.443967171008225, -2.8826926776959576],
              [23.3511932970707, -2.5470564232789736],
              [23.12375933542605, -1.9838265836410254],
              [23.394657473035046, -1.5518421262089754],
              [23.83638958888863, -1.279255817001411],
              [24.85939949463097, -1.0027144820098632],
              [25.49886949498579, -1.4032914115331017],
              [25.665509481107584, -1.0598468448641483],
              [26.220360017034302, -0.7172019109734735],
              [26.71686023617083, -1.0400284453130304],
              [26.852583233955777, -0.4929594958772551],
              [27.43032789721429, -0.391228321129407],
              [28.012314278352214, -0.5204062451860421],
              [28.23792366267807, 0.05075240533530201],
              [29.239916710354017, 1.3468947290703197],
              [29.13722473552464, 1.8381196092330407],
              [29.836996330493456, 1.831220100800305],
              [30.37804158003894, 2.1823374977601753],
              [30.421974168155018, 3.135059759647154],
              [31.136957760996815, 3.1846730189395647],
              [31.638733444123304, 2.9800945899462112],
              [31.92428466882916, 2.4028594601549145],
              [32.75602253629543, 2.3587685093389195],
              [33.24515047572961, 2.028920420870708],
              [33.554064862201045, 2.2985286674488052],
              [34.1182094811679, 2.0483914609897984],
              [34.454850454547355, 2.181015361887428],
              [34.971847118521, 2.654124769372676],
              [35.1471689873678, 2.1848818651780384],
              [35.804373567978594, 1.7470422411462354],
              [35.823417144153126, 0.88455688986617],
              [36.87552875054483, -0.4190280135125206],
              [37.638906609567364, -0.6917822075100795],
              [36.863825882488506, -1.6350223111435227],
              [36.05704478359079, -2.1995499709239335],
              [35.78407735248558, -2.9443673590837944],
              [35.34366227160019, -3.110541745218553],
              [35.10307018548461, -3.495073819314354],
              [34.02250691655816, -4.0623920067885955],
              [33.9933881658244, -4.121763623944154],
              [31.970227450411173, -4.334157564539993],
              [30.414771905972835, -4.428010019746294],
              [28.906461733275172, -4.605547970730573],
              [27.710170666573646, -4.6494058859923895],
              [24.10595173297125, -5.03522794355232],
              [23.578241070857104, -4.94361970920724],
              [23.665169084883935, -5.486124759294474],
              [20.41565486933432, -5.729125175356537],
              [20.853142009280027, -5.484854932980091],
              [21.044722198478354, -4.505954689634359],
              [20.98814880524312, -4.222124578597507]
            ]
          ]
        },
        "id": "KY",
        "properties": {
          "state_fips": "21",
          "id": "KY",
          "name": "Kentucky"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [29.53320806346307, 10.918146511168347],
              [32.50145465752773, 11.401118950793487],
              [33.41053953925436, 11.105504152804805],
              [33.75761915665398, 10.618316409965137],
              [34.272675120359196, 10.825800772303202],
              [34.79300664741406, 10.646688008562506],
              [35.77048757838476, 11.19842850089713],
              [36.372420238008495, 11.20143167475549],
              [37.254241417602664, 12.169972605635952],
              [38.83112164761595, 13.085221179656006],
              [39.4885346782259, 9.098625557225562],
              [39.190711401704945, 8.850501835662646],
              [39.46512839784377, 8.111081857363365],
              [39.30981277067432, 7.58684939300059],
              [39.31919536161853, 7.024430845712754],
              [39.14201233611475, 6.329154102128504],
              [39.1716991663747, 5.927655058300104],
              [38.50566472695698, 5.102943081426357],
              [37.96713102501548, 5.090357227938142],
              [37.39083636449946, 4.291891153137941],
              [37.428458992170505, 3.6472748625051077],
              [36.82091468999626, 3.7419333292389285],
              [36.507435617540466, 2.9924124016777345],
              [36.66077611855411, 2.400470253363779],
              [36.41333418211467, 1.9183873231839006],
              [35.804373567978594, 1.7470422411462354],
              [35.1471689873678, 2.1848818651780384],
              [34.971847118521, 2.654124769372676],
              [34.454850454547355, 2.181015361887428],
              [34.1182094811679, 2.0483914609897984],
              [33.554064862201045, 2.2985286674488052],
              [33.24515047572961, 2.028920420870708],
              [32.75602253629543, 2.3587685093389195],
              [31.92428466882916, 2.4028594601549145],
              [31.638733444123304, 2.9800945899462112],
              [31.136957760996815, 3.1846730189395647],
              [30.421974168155018, 3.135059759647154],
              [30.18364452043385, 5.267592891066145],
              [29.859935050351673, 8.233608919477993],
              [29.53320806346307, 10.918146511168347]
            ]
          ]
        },
        "id": "OH",
        "properties": {
          "state_fips": "39",
          "id": "OH",
          "name": "Ohio"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [12.927849826761527, 24.62566094103758],
              [13.39386558237308, 24.61062681394533],
              [14.798777544360197, 25.171487734395292],
              [15.261046835607685, 25.494998032837508],
              [15.504666764459438, 25.30210152411268],
              [15.25082606110337, 24.88356636764003],
              [16.245935079450483, 24.375068677013225],
              [16.67390729830344, 24.20954428235219],
              [16.90308256027786, 23.733823446235398],
              [19.07762632860434, 23.28928341517512],
              [19.748173524731442, 22.97572595027239],
              [21.20223157245666, 22.79053692144042],
              [21.236703582226845, 22.405138608198556],
              [21.691539839941854, 22.3513157628297],
              [21.950547412084546, 21.917209403157067],
              [21.781300262286038, 21.187794886266154],
              [22.284394020566275, 21.161201474064917],
              [22.143711046063864, 20.66498891576888],
              [22.467174719684657, 20.443584872855304],
              [22.41756544322636, 20.087613967001644],
              [22.024716548379473, 19.976381766214324],
              [21.7368139983912, 19.25982928087329],
              [21.89930108873478, 18.720948298513377],
              [22.218627997000183, 19.066404198530044],
              [22.496964316565155, 19.67955803894241],
              [22.870968369143824, 19.844635068388577],
              [23.194431956192986, 20.734178968106754],
              [23.613543080176584, 20.90490008646745],
              [23.344283999482307, 19.86744455033665],
              [23.12723194251636, 19.586386550199855],
              [22.852238900199758, 18.729880476728788],
              [22.771894487211448, 18.152137847949895],
              [22.86466858156274, 17.735048260450462],
              [22.613415430083485, 17.4868166481615],
              [22.4804437714185, 16.80055775412312],
              [22.602956256987767, 16.23071369177105],
              [22.26496619724559, 14.884088445840993],
              [22.510413014404875, 13.767083999469603],
              [22.720590515932095, 13.509932652106375],
              [22.699553025107598, 12.633163612286982],
              [20.542689229733853, 12.456558397123937],
              [16.469007345093125, 12.237221056314997],
              [16.27709124771212, 12.625469703862654],
              [15.536902988821941, 12.875866676312603],
              [15.3026963432927, 13.384671708977764],
              [15.199902286905134, 14.03904493328543],
              [15.44133239797866, 14.427939277596456],
              [15.11437564689272, 14.697474321659383],
              [15.05703135237084, 15.150067890296958],
              [14.95854517414478, 15.968932691146174],
              [14.502597444015999, 16.627709869457533],
              [14.193854906167603, 16.69084323173378],
              [13.543085120442749, 17.181178746217363],
              [13.417920512531769, 17.57436614996581],
              [12.786308948515105, 17.86278296532719],
              [12.489963705721818, 18.2064813714686],
              [12.124017991415956, 18.246792070737456],
              [11.489124236255407, 18.75200305093147],
              [11.591898848970569, 19.310947498016915],
              [11.538593395714141, 20.36505365892652],
              [11.758972963010175, 20.82197625992425],
              [11.263529856845388, 21.627217509096305],
              [11.583390729255887, 22.165633725321328],
              [12.426523770056905, 22.7300580574097],
              [12.361308138562157, 24.48319300899832],
              [12.927849826761527, 24.62566094103758]
            ]
          ]
        },
        "id": "WI",
        "properties": {
          "state_fips": "55",
          "id": "WI",
          "name": "Wisconsin"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-51.76445924036776, 31.269453256410927],
              [-51.32821720618793, 30.790877834954728],
              [-50.966002953849994, 30.810166805703574],
              [-50.62785300617719, 30.40104821364249],
              [-50.54982888836556, 30.001587052558662],
              [-50.70224738536131, 29.11054302885818],
              [-49.75347158116293, 28.505945590699525],
              [-48.740146143030636, 28.713774558612407],
              [-48.162390329182955, 28.61667087653551],
              [-47.31783157273707, 28.148147377286385],
              [-46.330612279254034, 28.187395249813868],
              [-46.1041420047517, 27.988092213297087],
              [-45.486108171465034, 27.90746922786149],
              [-44.16151164406833, 28.12024443907829],
              [-43.13386766795387, 27.948622021399764],
              [-42.803933205564505, 28.072139429102457],
              [-38.59930748694645, 27.082220389802444],
              [-38.438265157634596, 26.530861581931397],
              [-38.08092836605654, 26.31059972619458],
              [-37.920323223057096, 25.781697956032318],
              [-38.54197488164806, 25.022494913027508],
              [-38.778146588774966, 24.521529060778988],
              [-39.0957194402707, 24.233955696917036],
              [-39.431748522116294, 23.58022699872773],
              [-39.68443050592998, 23.51817867031481],
              [-40.32841088373518, 22.59717475597361],
              [-40.049813777638434, 22.0276989574934],
              [-39.75658932786014, 21.746109098576312],
              [-40.266663660759185, 20.7811656931892],
              [-41.04967690129294, 17.320607991412103],
              [-41.462842571718724, 15.486875318724378],
              [-45.06846628265557, 16.302709261826934],
              [-47.863802953491714, 17.010183457362565],
              [-50.05464920169558, 17.580168113122973],
              [-52.93498256577929, 18.424849517184427],
              [-55.002177125811805, 18.989843958785833],
              [-56.83576201777243, 19.535684759880127],
              [-57.03755744052209, 19.972594728433734],
              [-56.919801356645515, 20.93834142549916],
              [-56.675648882731316, 21.555905710886258],
              [-56.824346675188146, 22.15427312188212],
              [-56.413730635365866, 22.646983388332387],
              [-56.06798223932583, 23.287111538201522],
              [-55.4975984199166, 24.02667758627272],
              [-55.149300697838804, 24.690499304040642],
              [-54.177685193440524, 27.042181556252253],
              [-54.08799788776126, 27.447398575345744],
              [-53.58469374506179, 28.373173677487898],
              [-53.01410002037485, 30.01967455278131],
              [-52.92422511372062, 30.65037908427022],
              [-52.464035115988096, 31.1629739266535],
              [-51.76445924036776, 31.269453256410927]
            ]
          ]
        },
        "id": "OR",
        "properties": {
          "state_fips": "41",
          "id": "OR",
          "name": "Oregon"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [-11.318090763061143, 31.8619317292712],
              [-7.666899990807423, 31.588189963930958],
              [-5.5066191749955316, 31.462837844017344],
              [-3.094647490246924, 31.349833003491966],
              [0.30622401522793363, 31.246086526150876],
              [2.254364420250381, 31.211830631986494],
              [2.496365598014748, 30.276681001167923],
              [2.409192262603188, 30.03553949695307],
              [2.388817669384956, 28.708265391358864],
              [2.957646858471593, 27.13208884381573],
              [2.942724869572226, 26.553634634427233],
              [3.0923825326732635, 24.947586587562768],
              [3.0520766315992045, 24.306798700897033],
              [3.213164007120784, 23.6711168272452],
              [3.4572559375069565, 23.341157770477317],
              [3.525472802829407, 22.170775006921495],
              [-0.3403090932374091, 22.234620459136977],
              [-3.1288882334961667, 22.337534417654375],
              [-5.601487692179513, 22.458067742558256],
              [-8.189843211893686, 22.610876677079624],
              [-12.081323464827218, 22.902169142087033],
              [-11.727695012400378, 27.024492079996968],
              [-11.318090763061143, 31.8619317292712]
            ]
          ]
        },
        "id": "ND",
        "properties": {
          "state_fips": "38",
          "id": "ND",
          "name": "North Dakota"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [19.762370997143343, -7.284807043877931],
              [19.614525446606805, -8.023900870519622],
              [19.410461073927905, -8.46852625232758],
              [18.823670385524736, -9.097947581990928],
              [18.987632406458054, -9.32614121571611],
              [18.86886446491323, -10.01469940403107],
              [18.56150480755671, -10.403579058977286],
              [18.721669911654153, -10.661269841849444],
              [18.333830278228028, -10.912631203075648],
              [17.924062626069855, -12.2694443873486],
              [16.82655918286234, -13.582686861662204],
              [16.88560331356441, -14.53858257494059],
              [16.54219304576793, -14.835258745873821],
              [16.95675298153168, -15.847374997471835],
              [16.78299504022661, -16.510831687535358],
              [12.709307861006511, -16.65007726206172],
              [9.59444707285703, -16.721071546138567],
              [9.560095527802753, -15.118876234806176],
              [9.263032678945367, -15.014472865769573],
              [8.701363227075026, -15.15140268390355],
              [8.457764901201173, -14.881255530886104],
              [8.486516333187714, -11.088304586474178],
              [8.507059010903065, -9.587954119250016],
              [8.004447953855829, -6.247889935116514],
              [11.567710732996542, -6.172884118652616],
              [15.676019215716805, -6.013701780498804],
              [18.65891295824288, -5.850683288161278],
              [18.91115799702093, -6.4226337351001135],
              [18.215719328729932, -7.396496609312152],
              [19.762370997143343, -7.284807043877931]
            ]
          ]
        },
        "id": "AR",
        "properties": {
          "state_fips": "05",
          "id": "AR",
          "name": "Arkansas"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [23.519085996275997, 10.331683364348805],
              [23.884375756326406, 10.127418662484821],
              [24.476117441579362, 10.247924448549831],
              [25.05182567143418, 10.631525097170131],
              [27.335874424228578, 10.861218441006635],
              [29.510639463542617, 11.110195167287358],
              [29.53320806346307, 10.918146511168347],
              [29.859935050351673, 8.233608919477993],
              [30.18364452043385, 5.267592891066145],
              [30.421974168155018, 3.135059759647154],
              [30.37804158003894, 2.1823374977601753],
              [29.836996330493456, 1.831220100800305],
              [29.13722473552464, 1.8381196092330407],
              [29.239916710354017, 1.3468947290703197],
              [28.23792366267807, 0.05075240533530201],
              [28.012314278352214, -0.5204062451860421],
              [27.43032789721429, -0.391228321129407],
              [26.852583233955777, -0.4929594958772551],
              [26.71686023617083, -1.0400284453130304],
              [26.220360017034302, -0.7172019109734735],
              [25.665509481107584, -1.0598468448641483],
              [25.49886949498579, -1.4032914115331017],
              [24.85939949463097, -1.0027144820098632],
              [23.83638958888863, -1.279255817001411],
              [23.394657473035046, -1.5518421262089754],
              [23.321337571694933, -0.8502217095218341],
              [23.57466554390122, -0.41968117773720415],
              [24.372129025231736, 1.5319003045580601],
              [23.903401794901264, 2.55623698544811],
              [24.138046567204313, 3.221766380166703],
              [23.841328759997197, 6.67717038076991],
              [23.519085996275997, 10.331683364348805]
            ]
          ]
        },
        "id": "IN",
        "properties": {
          "state_fips": "18",
          "id": "IN",
          "name": "Indiana"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [2.254364420250381, 31.211830631986494],
              [6.389538478489628, 31.19880813079893],
              [6.380164899806731, 32.320870546165786],
              [7.032678721961304, 32.06606526513747],
              [7.329596605625445, 30.874059952772058],
              [7.526065572517494, 30.39191284537447],
              [8.220476215458367, 30.21497240003697],
              [9.02417612722426, 30.160962143791743],
              [9.131286934718537, 29.831747774347782],
              [9.78524953448035, 29.934551724963498],
              [10.01925411676461, 30.1776479745223],
              [10.74597623557207, 30.190548421294665],
              [12.172796633002417, 29.41835437300972],
              [12.756613781753522, 29.378288243318167],
              [13.364114649130014, 28.798117773342675],
              [13.94343158217694, 28.63060777014219],
              [14.300841241917762, 28.752137341626067],
              [15.011090576401854, 29.263278427410654],
              [15.284624106566204, 28.844876965358452],
              [16.5511774846495, 28.95756380553152],
              [17.27779327230187, 28.354932718048865],
              [15.805246696889563, 27.711100349712893],
              [15.16731612454702, 27.255108255743366],
              [13.617585898740694, 25.569529670498667],
              [12.75530130881256, 24.859528282330572],
              [12.927849826761527, 24.62566094103758],
              [12.361308138562157, 24.48319300899832],
              [12.426523770056905, 22.7300580574097],
              [11.583390729255887, 22.165633725321328],
              [11.263529856845388, 21.627217509096305],
              [11.758972963010175, 20.82197625992425],
              [11.538593395714141, 20.36505365892652],
              [11.591898848970569, 19.310947498016915],
              [11.489124236255407, 18.75200305093147],
              [12.124017991415956, 18.246792070737456],
              [12.489963705721818, 18.2064813714686],
              [12.786308948515105, 17.86278296532719],
              [13.417920512531769, 17.57436614996581],
              [13.543085120442749, 17.181178746217363],
              [14.193854906167603, 16.69084323173378],
              [14.502597444015999, 16.627709869457533],
              [14.95854517414478, 15.968932691146174],
              [15.05703135237084, 15.150067890296958],
              [11.65391241650118, 15.004470975893014],
              [9.476921541270828, 14.942532789529407],
              [5.7539788243886365, 14.891079528179583],
              [3.7212180617901867, 14.892315613087801],
              [3.7380871050776623, 17.770455007642106],
              [3.747414774991417, 20.272846415339586],
              [3.2702557917126995, 20.610672788872748],
              [2.902251663595858, 21.195855553361966],
              [3.489907279911993, 21.8456664735374],
              [3.525472802829407, 22.170775006921495],
              [3.4572559375069565, 23.341157770477317],
              [3.213164007120784, 23.6711168272452],
              [3.0520766315992045, 24.306798700897033],
              [3.0923825326732635, 24.947586587562768],
              [2.942724869572226, 26.553634634427233],
              [2.957646858471593, 27.13208884381573],
              [2.388817669384956, 28.708265391358864],
              [2.409192262603188, 30.03553949695307],
              [2.496365598014748, 30.276681001167923],
              [2.254364420250381, 31.211830631986494]
            ]
          ]
        },
        "id": "MN",
        "properties": {
          "state_fips": "27",
          "id": "MN",
          "name": "Minnesota"
        }
      },
      {
        "type": "Feature",
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [54.03811225564949, 16.37644806409819],
              [57.67682276772191, 17.20409365415845],
              [57.691637090097046, 17.159758207982208],
              [58.14522098011977, 15.438281783386918],
              [58.082770225694375, 15.121869090461146],
              [57.81804468593633, 15.083886430321135],
              [56.65562506854494, 14.535626692513691],
              [55.85511593949793, 14.404351767530398],
              [54.42199100570848, 13.17022871082572],
              [54.18698796305469, 13.472473166601775],
              [54.64175309437856, 13.928850393184108],
              [54.43471616183223, 14.135432026487853],
              [54.03811225564949, 16.37644806409819]
            ]
          ]
        },
        "id": "CT",
        "properties": {
          "state_fips": "09",
          "id": "CT",
          "name": "Connecticut"
        }
      }
    ]
  }
  
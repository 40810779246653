import React from "react";
import { Route, Routes, useNavigate, useLocation, createSearchParams } from "react-router-dom";
import SubNavigation from "../../Components/SubNavigation";
import SubHeaderComponent from "../../Components/SubHeaderComponent";
import { highPatientNeedsTabs } from "../Dashboard/data";
import DisconSummary from "./Components/DisconSummary";
import DisconPredictions from "./Components/DisconPredictions";
import DriversSummary from "./Components/DriversSummary";
// import { SubTabBody } from '../Dashboard';
// import Overview from './Overview/Overview';
// import {NodeGraph} from '../HighPatientNeeds/NodeGraph/NodeGraph';
// import Discon from "../HighPatientNeeds/Discon/Discon";

export function SubTabBody(props: {
  title: string;
  subSubTabs?: string[];
  component: JSX.Element;
}) {
  return (
    <div className="discon">
      <SubHeaderComponent title={props.title} />
      {props.subSubTabs && <SubNavigation subSubTabs={props.subSubTabs} />}

      <div
        className={
          props.subSubTabs && props.subSubTabs?.length > 0
            ? "bg-body p-4 discon-body-subsubtab"
            : "bg-body p-4 discon-body"
        }
        style={{ zIndex: -1 }}
      >
        <div className="bg-body h-full w-full fixed" style={{ zIndex: -1 }}></div>
        <Routes>
          <Route path={"/"} element={props.component} />
          <Route path={":subsubtab/*"} element={props.component} />
        </Routes>
      </div>
    </div>
  );
}

/**
 * This function is the main funcion of High Need Patients page which setup the routes of all the inner tabs in this page
 *
 * @returns the tabs with routes and their respective component
 */

export default function HighPatientNeeds() {
  const navigate = useNavigate();
  const locations = useLocation();
  React.useEffect(() => {
    const ob: any = {};
    navigate({
      search: `?${createSearchParams(ob)}`,
    });
  }, [locations.pathname]);
  const components = [
    <DisconPredictions />,
    <DriversSummary />,
    <DisconSummary />,
    // <Overview />,
  ];
  return (
    <>
      <SubNavigation tabs={highPatientNeedsTabs} url={"/highpatientneeds"} redirectFlag={true} />
      <>
        <Routes>
          {highPatientNeedsTabs.map((subTab, index) => (
            <Route
              key={index}
              path={index === 0 ? "/" : subTab.subTab.split(" ").join("_") + "/*"}
              element={
                <SubTabBody
                  title={subTab.subTab}
                  subSubTabs={subTab.subSubTabs}
                  component={components[index]}
                />
              }
            />
          ))}
        </Routes>
      </>
    </>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import BarChartWithBg from "../../Components/BarChartWithBg";
import ReportTypes from "../../libraries/report-types";
import { transformBarChartData, transformTopChartData } from "./Processing";
import * as ReportTypesComponentInterface from "../../libraries/report-types/charts/interfaces/main";
import { POCard } from "../../Components/ReportCards/POCard";
import { PieChartLegends } from "../../Components/ReportCards/CommonCardComponents/PieChartLegends";
import { ProgramOverviewCard } from "./KPIs/ProgramOverviewCard";
import { CallsByState } from "./KPIs/CallsByState";
import { FilterActiveInterface, FilterInterface } from ".";
import { pageWiseFilters } from "./data";
import { getKPIs } from "./ServiceHelpers/KPIs";
import { Skeleton, Space } from "antd";
import SkeletonCard, { SkeletonFull } from "../../Components/SkeletonCard";

const DUMMY_DATA = {
	kpis: [
		{
			cardName: "Engagement",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: {
				"Time spent by agent on Calls": 58,
				"Time spent by patients on calls": 42,
			},
			chartName: ["Overall Engagement by Topic"],
			xAxisLabel: ["% of Calls"],
			yAxisLabel: [],
			footer: "",
			chartColor: ["#3B9FE7", "#FECA98"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					Billing: {
						"Series 1": 45,
					},
					"Pill Box": {
						"Series 1": 50,
					},
					Disoriented: {
						"Series 1": 52,
					},
					"Side Effects": {
						"Series 1": 37,
					},
					Access: {
						"Series 1": 85,
					},
				},
			],
		},
		{
			cardName: "Patient Experience",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { Positive: 47, Neutral: 21, Negative: 32 },
			chartName: ["Overall Unmet Needs"],
			xAxisLabel: ["% of Calls"],
			yAxisLabel: [],
			footer: "",
			chartColor: ["#26C38D", "#DEDCDE", "#E65428", "#3B9FE7"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Side Effects": {
						"Series 1": 45,
					},
					Access: {
						"Series 1": 50,
					},
					Disoriented: {
						"Series 1": 52,
					},
					Billing: {
						"Series 1": 37,
					},
					"Pill Box": {
						"Series 1": 85,
					},
				},
			],
		},
		{
			cardName: "Educator Preview",
			month: [],
			value: [],
			unit: [""],
			vsPrevMonth: [],
			topChart: { Confidence: 65, Politeness: 50, Sympathy: 22, NPS: 80 },
			chartName: [],
			xAxisLabel: [],
			yAxisLabel: [],
			footer: "",
			chartColor: [],
			tableLinks: [],
			chartAccessor: [],
			chartData: [],
		},
		{
			cardName: "All Agents",
			kpi: ["Successfull Calls", "New Patient Volume"],
			month: ["November 2021", "November 2021"],
			value: ["2377", "16"],
			unit: ["", ""],
			vsPrevMonth: [4.3, 3.9],
			topChart: {
				Outbound: 58,
				Inbound: 42,
			},
			chartName: ["Trends Of Total Calls", "Avg. duration of Calls"],
			yAxisLabel: ["# of Calls", "Avg. Duration of Calls"],
			xAxisLabel: [],
			footer: "",
			chartColor: ["#7232CF", "#E0C6FF"],
			tableLinks: [],
			chartAccessor: [],
			chartData: [
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
				{
					"Feb 21": {
						"Series 1": 45,
					},
					"Mar 21": {
						"Series 1": 50,
					},
					"Apr 21": {
						"Series 1": 52,
					},
					"May 21": {
						"Series 1": 37,
					},
					"Jun 21": {
						"Series 1": 85,
					},
					"Jul 21": {
						"Series 1": 63,
					},
					"Aug 21": {
						"Series 1": 70,
					},
					"Sep 21": {
						"Series 1": 65,
					},
					"Oct 21": {
						"Series 1": 68,
					},
					"Nov 21": {
						"Series 1": 72,
					},
				},
			],
		},
	],
};

//@ts-ignore
const HorizontalTick = ({ textAnchor, textBaseline, value, x, y }) => {
	const MAX_LINE_LENGTH = 16;
	const MAX_LINES = 2;
	const LENGTH_OF_ELLIPSIS = 3;
	const TRIM_LENGTH = MAX_LINE_LENGTH * MAX_LINES - LENGTH_OF_ELLIPSIS;
	const trimWordsOverLength = new RegExp(`^(.{${TRIM_LENGTH}}[^\\w]*).*`);
	const groupWordsByLength = new RegExp(
		`([^\\s].{0,${MAX_LINE_LENGTH}}(?=[\\s\\W]|$))`,
		"gm"
	);
	const splitValues = value
		.replace(trimWordsOverLength, "$1...")
		.match(groupWordsByLength)
		.slice(0, 2)
		.map((val: any, i: any) => {
			// console.log("VALUE", groupWordsByLength);
			if (value.length > 17)
				return (
					<tspan
						key={val}
						dy={15 * i - 1}
						x={-10}
						style={{
							fill: "#454250",
							fontSize: "0.675rem",
							fontWeight: 600,
						}}
					>
						{val}
					</tspan>
				);
			else
				return (
					<tspan
						key={val}
						dy={10 * i + 2}
						x={-10}
						style={{
							fill: "#454250",
							fontSize: "0.675rem",
							fontWeight: 600,
						}}
					>
						{val}
					</tspan>
				);
		});

	return (
		<g transform={`translate(${x},${y})`}>
			<text alignmentBaseline={textBaseline} textAnchor={textAnchor}>
				{splitValues}
			</text>
		</g>
	);
};

/**
 * This page is the main KPIs tab in the dashboard page. It contains the progra overview card, Calls by State,Engagement, Patient Experience and Educator Card
 *
 * @param filterActiveStatus - Props used to pass the active filters as true
 * @param setFilterActiveStatus - Function to set the status of active filters as true
 * @param filters - This props is used to pass the filters in the card
 * 
 * @returns 5 cards two at top and three at bottom
 */

export default function Kpis(props: {
	filterActiveStatus: FilterActiveInterface;
	setFilterActiveStatus: React.Dispatch<
		React.SetStateAction<FilterActiveInterface>
	>;
	filters: FilterInterface;
}) {
	const { subsubtab } = useParams();
	const [fetchedData, setFetchedData] = React.useState<any>({});
	const [loading, setLoading] = React.useState(true);
	var data = [
		{
			header: !loading && fetchedData[0].cardName,
			cardTopData: (
				<div className="flex">
					<div className="w-2/5">
						<ReportTypes
							hideShadow
							hideRibbon
							minHeight="30px"
							height="130px"
							bodyPadding="0px 10px"
							title={{ title: "xx" }}
							chartType={
								ReportTypesComponentInterface.chartType.pie
							}
							label={{
								format: (d: any) => {
									console.log(d);
									return `${d.value}%`;
								},
								skipSmallValue: true,
							}}
							// series={series4}
							// data={chartData}
							{...transformTopChartData(
								!loading && fetchedData[0].topChart,
								!loading && fetchedData[0].chartColor
							)}
							chartConfig={{}}
							style={{
								labelTextColor: "black",
								margin: {
									top: 10,
									right: 0,
									bottom: 10,
									left: 0,
								},
								// labelTextColor: "black",
							}}
							tooltip={{
								isInteractive: true,
								valueFormat: (value: any) => {
									return `${value}%`;
								},
							}}
						/>
					</div>
					<PieChartLegends
						legendData={
							transformTopChartData(
								!loading && fetchedData[0].topChart,
								!loading && fetchedData[0].chartColor
							).series
						}
					/>
				</div>
			),
			iconName: "Report Engagement",
			chartElement: [
				<div className="">
					{!loading && (
						<p className="text-base font-semibold">
							{fetchedData[0].chartName[0]}
						</p>
					)}
					<div className="w-full">
						{/* <div className="w-0/4">
							<div
								className="font-semibold text-text-gray-80"
								style={{ marginTop: "14px" }}
							>
								<div
									className="overflow-y-hidden"
									style={{ fontSize: "16px" }}
								>
									{console.log("chk", fetchedData)}
									{Object.entries(
										!loading && fetchedData[0].chartData[0]
									)
										.reverse()
										.map(([key], index) => {
											return (
												<div
													className="flex items-center overflow-hidden"
													style={{
														// height: "3.95rem",
														height: "30px",
													}}
													// className={
													// 	index === 0
													// 		? "my-4"
													// 		: "my-8"
													// }
												>
													<p className="w-full text-right text-xs leading-3">
														{key}
													</p>
												</div>
											);
										})}
								</div>
							</div>
						</div> */}
						<div
							className="w-full overflow-y-auto no-scroll-div mt-4"
							style={{ maxHeight: "41vh" }}
						>
							<ReportTypes
								header={""}
								hideShadow
								hideRibbon
								minHeight="30px"
								// height="44vh"
								// height="380px"
								height={`${
									!loading
										? Object.keys(
												fetchedData[0].chartData[0]
										  ).length * 60
										: 0
								}px`}
								bodyPadding="0px 0px"
								title={{
									title:
										!loading && fetchedData[0].chartName[0],
								}}
								chartType={
									ReportTypesComponentInterface.chartType.bar
								}
								{...transformBarChartData(
									!loading && fetchedData[0].chartData[0],
									[!loading && fetchedData[0].chartColor[2]]
								)}
								// series={chartsData[0] ? chartsData[0].series : []}
								// data={chartsData[0] ? chartsData[0].data : []}
								axis={{
									primaryX: {
										enable: false,
										dim: 0,
										tickValues: 5,
										// tickRotation: -45,
										legend:
											!loading &&
											fetchedData[0].xAxisLabel[0],
										legendPosition:
											ReportTypesComponentInterface
												.axisLegendPosition.middle,
										legendOffset: 40,
										minValue: 0,
										maxValue: 100,
									},
									primaryY: {
										tickSize: 5,
										tickValues: 5,
										tickRotation: 0,
										legendOffset: -40,
										renderTick: HorizontalTick,
										// minValue: 0,
										// maxValue: 100,
									},
								}}
								label={{
									format: (d: any) => {
										return `${Math.round(d.value)}%`;
									},
									skipSmallValue: true,
									skipWidth: 20,
								}}
								tooltip={{
									isInteractive: true,
									format: "unmet",
								}}
								chartArea={{
									layout: ReportTypesComponentInterface
										.chartAreaLayout.horizontal,
								}}
								chartConfig={{
									cartesian: {
										// groupMode: groupMode.grouped,
										grid: {
											enableGridX: true,
											enableGridY: false,
											gridYValues: 5,
											gridXValues: 5,
										},
										gaps: { barGap: 0.3 },
									},
								}}
								style={{
									margin: {
										top: 0,
										right: 10,
										bottom: 0,
										left: 100,
									},
									labelTextColor: "white",
									axisWidth: 1,
								}}
							/>
						</div>
						<div className="mt-4 font-semibold flex justify-center">
							{!loading && fetchedData[0].xAxisLabel[0]}
						</div>
					</div>
				</div>,
			],
		},
		{
			header: !loading && fetchedData[1].cardName,
			cardTopData: (
				<div className="flex">
					<div className="w-2/5">
						<ReportTypes
							hideShadow
							hideRibbon
							minHeight="30px"
							height="130px"
							bodyPadding="0px 0px"
							title={{ title: "xx" }}
							chartType={
								ReportTypesComponentInterface.chartType.pie
							}
							label={{
								format: (d: any) => {
									console.log(d);
									return `${d.value}%`;
								},
								skipSmallValue: true,
								skipWidth: 20,
							}}
							tooltip={{
								isInteractive: true,
								valueFormat: (value: any) => {
									return `${value}%`;
								},
							}}
							// series={series4}
							// data={chartData}
							{...transformTopChartData(
								!loading && fetchedData[1].topChart,
								!loading && fetchedData[1].chartColor
							)}
							chartConfig={{}}
							style={{
								labelTextColor: "black",
								margin: {
									top: 10,
									right: 0,
									bottom: 10,
									left: 0,
								},
								// labelTextColor: "black",
							}}
						/>
					</div>
					<PieChartLegends
						legendData={
							transformTopChartData(
								!loading && fetchedData[1].topChart,
								!loading && fetchedData[1].chartColor
							).series
						}
					/>
				</div>
			),
			iconName: "Report User",
			chartElement: [
				<div className="">
					{!loading && (
						<p className="text-base font-semibold">
							{fetchedData[1].chartName[0]}
						</p>
					)}
					<div className="w-full">
						{/* <div className="w-1/4">
							<div
								className="font-semibold text-text-gray-80 flex flex-col justify-center bg-red-200"
								style={{ marginTop: "14px", height: "336px" }}
							>
								<div
									className="overflow-y-hidden"
									style={{ fontSize: "16px" }}
								>
									{Object.entries(
										!loading && fetchedData[1].chartData[0]
									)
										.reverse()
										.map(([key], index) => {
											return (
												<div
													className="flex items-center overflow-hidden"
													style={{
														// height: "3.95rem",
														height: "30px",
													}}
													// className={
													// 	index === 0
													// 		? "my-4"
													// 		: "my-8"
													// }
												>
													<p className="w-full text-right text-xs leading-3">
														{key}
													</p>
												</div>
											);
										})}
								</div>
							</div>
						</div> */}
						<div
							className="w-full overflow-y-auto no-scroll-div mt-4"
							style={{ maxHeight: "41vh" }}
						>
							{/* {console.log(
								"Chk",
								Object.keys(fetchedData[1].chartData[0] || {})
									.length
							)} */}
							<ReportTypes
								header={""}
								hideShadow
								hideRibbon
								minHeight="30px"
								// height="44vh"
								height={`${
									!loading
										? Object.keys(
												fetchedData[1].chartData[0]
										  ).length * 60
										: 0
								}px`}
								bodyPadding="0px 0px"
								title={{
									title:
										!loading && fetchedData[1].chartName[0],
								}}
								chartType={
									ReportTypesComponentInterface.chartType.bar
								}
								{...transformBarChartData(
									!loading && fetchedData[1].chartData[0],
									[!loading && fetchedData[1].chartColor[3]]
								)}
								// series={chartsData[0] ? chartsData[0].series : []}
								// data={chartsData[0] ? chartsData[0].data : []}
								axis={{
									primaryX: {
										enable: false,
										dim: 0,
										tickValues: 5,
										// tickRotation: -45,
										legend:
											!loading &&
											fetchedData[1].xAxisLabel[0],
										legendPosition:
											ReportTypesComponentInterface
												.axisLegendPosition.middle,
										legendOffset: 40,
										minValue: 0,
										maxValue: 100,
									},
									primaryY: {
										tickSize: 5,
										tickValues: 5,
										tickRotation: 0,
										legendOffset: -40,
										renderTick: HorizontalTick,
									},
								}}
								label={{
									format: (d: any) => {
										return `${Math.round(d.value)}%`;
									},
									skipSmallValue: true,
									skipWidth: 20,
								}}
								tooltip={{
									isInteractive: true,
									format: "unmet",
								}}
								chartArea={{
									layout: ReportTypesComponentInterface
										.chartAreaLayout.horizontal,
								}}
								chartConfig={{
									cartesian: {
										// groupMode: groupMode.grouped,
										grid: {
											enableGridX: true,
											enableGridY: false,
											gridYValues: 5,
											gridXValues: 5,
										},
										gaps: { barGap: 0.2 },
									},
								}}
								style={{
									margin: {
										top: 0,
										right: 10,
										bottom: 0,
										left: 110,
									},
									labelTextColor: "white",
									axisWidth: 1,
								}}
							/>
						</div>
						<div className="mt-4 font-semibold flex justify-center">
							{!loading && fetchedData[0].xAxisLabel[0]}
						</div>
					</div>
				</div>,
			],
		},
		{
			header: !loading && fetchedData[2].cardName,
			cardTopData: <div className="w-full p-4"></div>,
			iconName: "EmotionHappyBlack",
			chartElement: [
				<div className="flex flex-col justify-between h-full">
					{Object.entries(!loading && fetchedData[2].topChart).map(
						([key, value], index) => {
							return (
								<div className="mt-4">
									<BarChartWithBg
										{...transformBarChartData(
											[
												{
													[key]: { [key]: value },
												},
											],
											["black"],
											"bar"
										)}
										backgroundDivisions={
											index === 3 ? 3 : 2
										}
										noHeader={false}
										range={
											index === 3
												? [-100, 0.0001, 30, 100]
												: [0, 33.33, 66.66, 100]
										}
										unit=""
									/>
								</div>
							);
						}
					)}
					,
				</div>,
			],
		},
	];

	const fetchKPIsPageData = async () => {
		setLoading(true);
		const data: any = await getKPIs(
			props.filters,
			props.filterActiveStatus
		);
		if (data) {
			console.log("Insdei Data ", data);
			setFetchedData(data.kpis);
			setLoading(false);
		}
	};
	React.useEffect(() => {
		const obj: FilterActiveInterface = props.filterActiveStatus;
		for (let key in obj) {
			obj[key] = false;
		}
		pageWiseFilters
			.find((page) => page.tab === "KPIs")
			?.filter.map((filter) => (obj[filter] = true));
		props.setFilterActiveStatus(obj);
		fetchKPIsPageData();
	}, [props.filters]);
	return (
		<>
			{loading ? (
				<div className="grid lg:grid-cols-12 md:grid-cols-1 gap-4">
					<div className="h-80 col-span-7 bg-white shadow-md shadow-gray-900">
						<div className="p-4">
							<Skeleton.Input active style={{ width: "20rem" }} />
							<div className="flex">
								<div className="w-1/3 mt-3 px-3">
									<Skeleton active />
								</div>
								<div className="mt-3 px-3 w-1/3">
									<Skeleton.Input
										style={{
											height: "10rem",
											width: "10rem",
										}}
									/>
								</div>
								<div className="w-1/3 mt-3 px-3">
									<Skeleton active />
								</div>
							</div>
						</div>
					</div>
					<div className="h-80 col-span-5 bg-white shadow-md shadow-gray-900">
						<div className="p-4">
							<Skeleton.Input
								className="width-full"
								style={{ height: "38vh" }}
								active
							/>
						</div>
					</div>
					<div className="grid col-span-4">
						<SkeletonCard />
					</div>
					<div className="grid col-span-4">
						<SkeletonCard />
					</div>
					<div className="grid col-span-4">
						<SkeletonCard />
					</div>
				</div>
			) : (
				<div className="grid lg:grid-cols-12 md:grid-cols-1 gap-4">
					<div className="h-80 col-span-7 bg-white shadow-md shadow-gray-900">
						<ProgramOverviewCard data={fetchedData[3]} />
					</div>
					<div className="h-80 col-span-5 bg-white shadow-md shadow-gray-900">
						<CallsByState data={fetchedData[4]} />
					</div>
					{data.map((card: any) => (
						<div className="grid col-span-4">
							<POCard {...card} />
						</div>
					))}
				</div>
			)}
		</>
	);
}

import { Modal, Input } from "antd";
import React from "react";
import { Route, Routes } from "react-router-dom";
import AddStoryModalContent from "../../Components/AddStoryModal";
import Stories from "../SearchEngine/Stories/index";
import "../SearchEngine/style.css";
import * as StoriesServiceHelper from "../SearchEngine/ServiceHelpers/stories";
import * as Messages from "../../Components/Messages";
import { SelectedStories } from "./Components/SelectedStories";
import { SelectedSavedSummary } from "./Components/SelectedSavedSummary";
// import { StoriesViewDummyData } from "../SearchEngine/data";
// import DeleteAlert from "../../Components/DeleteAlert";
// import StoryMode from "./Stories/storyMode";

/**
 * Renders the Stories Table. This function also handles the APIs for this table.
 *
 * @returns Stories Table View.
 */
function StoriesTable(): JSX.Element {
  const [fetching, setFetching] = React.useState(true);
  const [fetchedData, setFetchedData] = React.useState<
    {
      [id: string]: string | number | Date;
    }[]
  >([]);
  const [originalData, setOriginalData] = React.useState<
    {
      [id: string]: string | number | Date;
    }[]
  >([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState(<></>);
  // const [filteredData, setFilteredData] = React.useState([]);
  // const [alertVisible, setAlertVisible] = React.useState(false);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  function showModal() {
    setIsModalVisible(true);
    setModalContent(<AddStoryModalContent handleCancel={handleCancel} onSave={handleAddApi} tagName={"Collection"} data={fetchedData}/>);
  }

  function handleAddApi(addData: any) {
    setIsModalVisible(false);
    if (addData) {
      addNewStory(addData);
      fetchdata();
    }
  }
  async function deleteStory(storyId: number) {
    const key = "updatable";
    Messages.loading("Deleting Collection", key);
    const data: any = await StoriesServiceHelper.deleteStory(storyId);
    if (data === 200) {
      const temp = [...fetchedData];
      const idx = temp.findIndex((d) => d.id === storyId);
      temp.splice(idx, 1);
      setFetchedData(temp);
      Messages.success("Collection Deleted Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }
  async function addNewStory(addData: { StoryName: string }) {
    //    console.log(addData);
    const key = "updatable";
    Messages.loading("Adding Collection", key);
    const data: any = await StoriesServiceHelper.addNewStory(addData);
    if (data) {
      const temp = [data, ...fetchedData];
      // temp.push(data);
      setFetchedData(temp);
      Messages.success("Collection Added Sucessfully", key);
    } else {
      Messages.error("Failed!", key);
    }
  }

  const onSearch = (word: any) => {
    if (word !== "") {
      setFetchedData((prevData) => originalData.filter((item) => item["storyName"].toString().toLowerCase().includes(word.target.value.toLowerCase())));
    } else {
      // setFetchedData(StoriesViewDummyData);
      // fetchdata();
      setFetchedData(originalData);
    }
  };

  async function fetchdata() {
    const data: any = await StoriesServiceHelper.getAllStories();

    if (data) {
      setFetchedData(data);
      setOriginalData(data);
      setFetching(false);
    }
  }

  // console.log(fetchedStoryData);

  React.useEffect(() => {
    fetchdata();
    // setFetchedData(StoriesViewDummyData);
  }, []);
  return (
    <>
      {!fetching ? (
        <div>
          <div className="flex shadow-lg relative p-4 pl-11">
            <Input.Search className="w-7/12 input-search" placeholder="Search Collection Name" enterButton onChange={onSearch} />
          </div>
          <div className="stories-body bg-body p-4">
            <Modal title={null} open={isModalVisible} centered onOk={handleOk} onCancel={handleCancel} footer={null} destroyOnClose={true}>
              {modalContent}
            </Modal>
            <Stories
              data={{ stories: fetchedData }}
              deleteStory={deleteStory}
              addNewStory={showModal}
              // editStory={handleEdit}
              // handleSort={handleSort}
            />
          </div>
        </div>
      ) : (
        <>
          <div>
            <div className="flex shadow-lg relative p-4 pl-11">
              <Input.Search className="w-7/12 input-search" placeholder="Search Collection Name" enterButton onChange={onSearch} />
            </div>
            <div className="stories-body bg-body p-4">
              <Stories data={{}} loading={true} />
            </div>
          </div>
        </>
      )}
    </>
  );
}
/**
 * Renders the Stories Tab View. This function sets up the routes within the Stories Tab.
 *
 * @returns Stories Tab View Routes.
 */
export function StoriesView() {
  return (
    <>
      <Routes>
        <Route key={"Stories 0"} path="/" element={<StoriesTable />} />
        <Route key="Stories - Stories Mode" path="collection-mode/:storyId/*" element={<SelectedStories />} />
        <Route path="collection-mode/:storyId/:id/*" element={<SelectedSavedSummary />} />
      </Routes>
    </>
  );
}

import React, { useState, useEffect } from "react";
import { BellFilled, CloseOutlined } from "@ant-design/icons";
import { Badge, Dropdown, Menu, Card, message } from "antd";
import "./index.css";
import { deleteNotification, getNotificationMessages } from "../../Pages/SearchEngine/ServiceHelpers/study";
// import { NotificationCenterDummyData } from "../../Pages/SearchEngine/data";

const { Meta } = Card;

export default function NotificationDropdown() {
  const [data, setData] = useState<any[]>([]);

  async function getNotification() {
    const message: any = await getNotificationMessages();
    if (message) setData(message);
  }

  const handleDeleteNofication = async (ids: number | number[]) => {
    try {
      const idArray = Array.isArray(ids) ? ids : [ids];

      const response: any = await deleteNotification(idArray);

      if (response) {
        const updatedData = data.filter((item) => !idArray.includes(item.id));
        setData(updatedData);
      } else {
        console.error("Failed to delete");
      }
    } catch (error) {
      console.error("Error deleting:", error);
    }
  };

  useEffect(() => {
    getNotification();
    const intervalId = setInterval(getNotification, 30000);
    return () => clearInterval(intervalId);
    // In the future, you can replace this with an API call to fetch data
    // setData(NotificationCenterDummyData);
  }, []);

  const handleClear = (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    handleDeleteNofication(id);
  };

  const handleClearAll = () => {
    const allIds = data.map((item) => item.id);
    handleDeleteNofication(allIds);
    setData([]);
  };

  const items =
    data.length > 0 ? (
      data.map((item: any, index) => (
        <>
          <Menu.Item key={item.id}>
            <Card className="notification-card">
              <CloseOutlined onClick={(e) => handleClear(e, item.id)} className="close-icon" />
              <Meta title={item.title} description={item.description} />
            </Card>
          </Menu.Item>
        </>
      ))
    ) : (
      <div className="notification-card no-notification-card">No new notifications</div>
    );

  return (
    <Dropdown
      overlay={
        <Menu>
          {items}
          {data.length > 0 && (
            <div className="clear-all">
              <span style={{ cursor: "pointer" }} onClick={handleClearAll}>
                Clear All
              </span>
            </div>
          )}
        </Menu>
      }
    >
      <Badge size="small" offset={[-2, 2]} count={data.length}>
        <BellFilled className="bell-icon" />
      </Badge>
    </Dropdown>
  );
}

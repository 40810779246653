// import { searchQuery } from "../Search";
import { filterValues } from "../../NodeGraph/NodeGraph";
import * as NodeGraph from "./../../../services/nodeGraph";

export const getNodeJsons: (s3path: string) => Promise<unknown> = async (
	s3path: string
) => {
	const data = await NodeGraph.getNodeJsonsData(s3path);
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

export const getStudiesForDiscover: () => Promise<unknown> = async () => {
	const data = await NodeGraph.getStudiesDataForDiscover();
	if (data === null || data === undefined) return null;
	return data;
};

export const getWavesForStudyId: (studyId: number) => Promise<unknown> = async (
	studyId: number
) => {
	const data = await NodeGraph.getWavesDataForStudyId(studyId);
	if (data === null || data === undefined) return null;
	return data;
};

export const getStudyTypes: (waveId: number) => Promise<unknown> = async (
	waveId: number
) => {
	const data = await NodeGraph.getGeographyDataForStudyId(waveId);
	if (data === null || data === undefined) return null;
	return data;
};

export const getTranscriptDetailsForNode: (filters: {
	nodeId: string;
	studyId: number;
	waveId: number;
	geoId: number;
	studyIterId: number;
}) => Promise<unknown> = async (filters) => {
	const data = await NodeGraph.getTranscriptDetailsForNodeData(filters);
	if (data === null || data === undefined) return null;
	return data;
};

export const getExperiments: () => Promise<unknown> = async () => {
	const data = await NodeGraph.getExperimentData();
	// console.log("api", data);
	if (data === null || data === undefined) return null;
	return data;
};

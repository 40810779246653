import { Skeleton } from "antd";
import React from "react";
import { icons, userTypeEnum } from "../../GlobalThemes";
import AppContext from "../../store";

export const patientRiskBucket = [
  {
    cardName: "Low",
    value: "245",
    vsPrevMonth: -10,
  },
  {
    cardName: "Medium",
    value: "230",
    vsPrevMonth: -7,
  },
  {
    cardName: "High",
    value: "270",
    vsPrevMonth: 10,
  },
];

export const words = [
  {
    text: "told",
    value: 64,
  },
  {
    text: "mistake",
    value: 11,
  },
  {
    text: "thought",
    value: 16,
  },
  {
    text: "bad",
    value: 17,
  },
  {
    text: "correct",
    value: 10,
  },
  {
    text: "day",
    value: 54,
  },
  {
    text: "prescription",
    value: 12,
  },
  {
    text: "time",
    value: 77,
  },
  {
    text: "thing",
    value: 45,
  },
  {
    text: "left",
    value: 19,
  },
  {
    text: "pay",
    value: 13,
  },
  {
    text: "people",
    value: 32,
  },
  {
    text: "month",
    value: 22,
  },
  {
    text: "again",
    value: 35,
  },
  {
    text: "review",
    value: 24,
  },
  {
    text: "call",
    value: 38,
  },
  {
    text: "doctor",
    value: 70,
  },
  {
    text: "asked",
    value: 26,
  },
  {
    text: "finally",
    value: 14,
  },
  {
    text: "insurance",
    value: 29,
  },
  {
    text: "week",
    value: 41,
  },
  {
    text: "called",
    value: 49,
  },
  {
    text: "problem",
    value: 20,
  },
  {
    text: "going",
    value: 59,
  },
  {
    text: "help",
    value: 49,
  },
  {
    text: "felt",
    value: 45,
  },
  {
    text: "discomfort",
    value: 11,
  },
  {
    text: "lower",
    value: 22,
  },
  {
    text: "severe",
    value: 12,
  },
  {
    text: "free",
    value: 38,
  },
  {
    text: "better",
    value: 54,
  },
  {
    text: "muscle",
    value: 14,
  },
  {
    text: "neck",
    value: 41,
  },
  {
    text: "root",
    value: 24,
  },
  {
    text: "adjustment",
    value: 16,
  },
  {
    text: "therapy",
    value: 29,
  },
  {
    text: "injury",
    value: 20,
  },
  {
    text: "excruciating",
    value: 10,
  },
  {
    text: "chronic",
    value: 13,
  },
  {
    text: "chiropractor",
    value: 35,
  },
  {
    text: "treatment",
    value: 59,
  },
  {
    text: "tooth",
    value: 32,
  },
  {
    text: "chiropractic",
    value: 17,
  },
  {
    text: "dr",
    value: 77,
  },
  {
    text: "relief",
    value: 19,
  },
  {
    text: "shoulder",
    value: 26,
  },
  {
    text: "nurse",
    value: 17,
  },
  {
    text: "room",
    value: 22,
  },
  {
    text: "hour",
    value: 35,
  },
  {
    text: "wait",
    value: 38,
  },
  {
    text: "hospital",
    value: 11,
  },
  {
    text: "eye",
    value: 13,
  },
  {
    text: "test",
    value: 10,
  },
  {
    text: "appointment",
    value: 49,
  },
  {
    text: "medical",
    value: 19,
  },
  {
    text: "question",
    value: 20,
  },
  {
    text: "office",
    value: 64,
  },
  {
    text: "care",
    value: 54,
  },
  {
    text: "minute",
    value: 29,
  },
  {
    text: "waiting",
    value: 16,
  },
  {
    text: "patient",
    value: 59,
  },
  {
    text: "health",
    value: 49,
  },
  {
    text: "alternative",
    value: 24,
  },
  {
    text: "holistic",
    value: 19,
  },
  {
    text: "traditional",
    value: 20,
  },
  {
    text: "symptom",
    value: 29,
  },
  {
    text: "internal",
    value: 17,
  },
  {
    text: "prescribed",
    value: 26,
  },
  {
    text: "acupuncturist",
    value: 16,
  },
  {
    text: "pain",
    value: 64,
  },
  {
    text: "integrative",
    value: 10,
  },
  {
    text: "herb",
    value: 13,
  },
  {
    text: "sport",
    value: 22,
  },
  {
    text: "physician",
    value: 41,
  },
  {
    text: "herbal",
    value: 11,
  },
  {
    text: "eastern",
    value: 12,
  },
  {
    text: "chinese",
    value: 32,
  },
  {
    text: "acupuncture",
    value: 45,
  },
  {
    text: "prescribe",
    value: 14,
  },
  {
    text: "medication",
    value: 38,
  },
  {
    text: "western",
    value: 35,
  },
  {
    text: "sure",
    value: 38,
  },
];

export const DUMMY_DATA = [
  {
    numberOfPatients: 79,
    numberOfSearchResults: 3,
    searchResults: [
      {
        id: 0,
        name: "Transcripts from 12 June 2020 [18:50:23]",
        tags: ["Inbound", "Follow Up Call", "Nervous"],
        storiesAdded: [
          {
            id: 1,
            storyName: "Query about Alcohol Consumption",
          },
        ],
        dialogueNo: 34,
        callId: "393EBD7490B340B197BDD2552D4DF263",
        callDate: "2017-11-01T00:00:00",
        callTime: "0:12:20",
        callType: "Inbound",
        therapyStatus: "On Therapy",
        dialogueAppearedAt: 0.2,
        dialogueLength: 1.2,
        transcriptLength: 5,
        S3path: "ABC/transcript_sample_script.json",
        addedToStory: false,
        transcripts: [
          {
            dialogueNo: 1,
            speaker: "Patient",
            text: "Oh okay, sure.",
          },
          {
            dialogueNo: 2,
            speaker: "Agent",
            text: "Are there any other questions I can answer for you?",
          },
          {
            dialogueNo: 3,
            speaker: "Patient",
            text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
          },
          {
            dialogueNo: 4,
            speaker: "Agent",
            text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
          },
          {
            dialogueNo: 5,
            speaker: "Patient",
            text: "Alrigt. Thanks a lot",
          },
        ],
      },
      {
        id: 1,
        name: "Transcripts from 10 June 2020 [17:30:58]",
        tags: ["Inbound", "Follow Up Call", "Nervous"],
        dialogueNo: 34,
        callId: "393EBD7490B340B197BDD2552D4DF263",
        callDate: "2017-11-01T00:00:00",
        callTime: "0:12:20",
        callType: "Inbound",
        therapyStatus: "On Therapy",
        dialogueAppearedAt: 0.2,
        dialogueLength: 1.2,
        S3path: "ABC/transcript_sample_script.json",
        transcriptLength: 5,
        addedToStory: false,
        transcripts: [
          {
            dialogueNo: 1,
            speaker: "Patient",
            text: "Oh okay, sure.",
          },
          {
            dialogueNo: 2,
            speaker: "Agent",
            text: "Are there any other questions I can answer for you?",
          },
          {
            dialogueNo: 3,
            speaker: "Patient",
            text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
          },
          {
            dialogueNo: 4,
            speaker: "Agent",
            text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
          },
          {
            dialogueNo: 5,
            speaker: "Patient",
            text: "Alrigt. Thanks a lot",
          },
        ],
      },
      {
        id: 2,
        name: "Transcripts from 3 June 2020 [12:33:28]",
        tags: ["Inbound", "Follow Up Call", "Nervous"],
        dialogueAppearedAt: 0.2,
        dialogueNo: 34,
        callId: "393EBD7490B340B197BDD2552D4DF263",
        callDate: "2017-11-01T00:00:00",
        callTime: "0:12:20",
        callType: "Outbound",
        therapyStatus: "On Therapy",
        dialogueLength: 1.2,
        transcriptLength: 5,
        S3path: "ABC/transcript_sample_script.json",
        addedToStory: false,
        transcripts: [
          {
            dialogueNo: 1,
            speaker: "Patient",
            text: "Oh okay, sure.",
          },
          {
            dialogueNo: 2,
            speaker: "Agent",
            text: "Are there any other questions I can answer for you?",
          },
          {
            dialogueNo: 3,
            speaker: "Patient",
            text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
          },
          {
            dialogueNo: 4,
            speaker: "Agent",
            text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
          },
          {
            dialogueNo: 5,
            speaker: "Patient",
            text: "Alrigt. Thanks a lot",
          },
        ],
      },
    ],
  },
];

export const fetchedFilters: {
  filter: string;
  options?: string[] | undefined;
  optionsObject?:
    | {
        id: number;
        name: string;
        subgroup: {
          id: number;
          name: string;
        }[];
      }[]
    | undefined;
}[] = [
  {
    filter: "Call_Type",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "Inbound", subgroup: [] },
      { id: 2, name: "Outbound", subgroup: [] },
    ],
  },
  {
    filter: "Therapy_Status",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "On Therapy", subgroup: [] },
      { id: 2, name: "Never Started", subgroup: [] },
    ],
  },
  {
    filter: "Call_Intent",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "Introduction Call", subgroup: [] },
      { id: 2, name: "Follow-Up Call 1", subgroup: [] },
      { id: 3, name: "Follow-Up Call 2", subgroup: [] },
      { id: 4, name: "Follow-Up Call 3", subgroup: [] },
      { id: 5, name: "Inquiry Call", subgroup: [] },
      { id: 6, name: "Return Call", subgroup: [] },
    ],
  },
  {
    filter: "Emotions",
    optionsObject: [
      {
        id: 0,
        name: "All",
        subgroup: [],
      },
      {
        id: 1,
        name: "Joy",
        subgroup: [
          { id: 1, name: "Admiration" },
          { id: 2, name: "Amusement" },
          { id: 3, name: "Approval" },
          { id: 4, name: "Caring" },
          { id: 5, name: "Desire" },
          { id: 6, name: "Excitement" },
          { id: 7, name: "Gratitude" },
          { id: 8, name: "Joy" },
          { id: 9, name: "Love" },
          { id: 10, name: "Optimism" },
          { id: 11, name: "Pride" },
          { id: 12, name: "Relief" },
        ],
      },
      {
        id: 2,
        name: "Anger",
        subgroup: [
          { id: 1, name: "Anger" },
          { id: 2, name: "Annoyance" },
        ],
      },
      {
        id: 3,
        name: "Sadness",
        subgroup: [
          { id: 1, name: "Embarrassment" },
          { id: 2, name: "Disappointment" },
          { id: 3, name: "Remorse" },
          { id: 4, name: "Sadness" },
        ],
      },
      { id: 4, name: "Disgust", subgroup: [{ id: 1, name: "Disgust" }] },
      { id: 5, name: "Fear", subgroup: [{ id: 1, name: "Fear" }] },
      {
        id: 5,
        name: "Surprise",
        subgroup: [
          { id: 1, name: "Confusion" },
          { id: 2, name: "Curiosity" },
          { id: 3, name: "Surprise" },
          { id: 4, name: "Realization" },
        ],
      },
      { id: 6, name: "Neutral", subgroup: [{ id: 1, name: "Neutral" }] },
    ],
  },
  {
    filter: "Adherence",
    optionsObject: [
      { id: 0, name: "All", subgroup: [] },
      { id: 1, name: "High", subgroup: [] },
      { id: 2, name: "Medium", subgroup: [] },
      { id: 3, name: "Low", subgroup: [] },
    ],
  },
];

export const storyTypes = ["General Tracker", "Issues"];
export const storyStatus = ["In Draft", "Publishing", "Published"];

export const fullTranscriptData = [
  {
    speaker: "Patient",
    text: "Hello?",
  },
  {
    speaker: "Agent",
    text: "Hello, may I speak to <NAME>?",
  },
  {
    speaker: "Patient",
    text: "Who am I speaking to?",
  },
  {
    speaker: "Agent",
    text: "My name is <NAME>, I'm a nurse educator and I work for Ashfield on behalf of Biogen.",
  },
  {
    speaker: "Patient",
    text: "Oh okay, sure.",
  },
  {
    speaker: "Agent",
    text: "Are there any other questions I can answer for you?",
  },
  {
    speaker: "Patient",
    text: "So, how long um between vumerity and alcoholic beverage ? just because i do drink a glass of wine with my dinner and i you know usually take my taxes with my dinner and i never had any issues with so i'm not sure if that's gonna be a change. okay okay.",
  },
  {
    speaker: "Agent",
    text: "Um, yeah, so, it depends on the dosage, I would ask your doctor for more information.",
  },
  {
    speaker: "Patient",
    text: "Alrigt. Thanks a lot.",
  },
];

export const storyList = [
  {
    chartData: [
      [
        {
          id: 1,
          selected: false,
          "Story Name": "Query about Alcohol Consumption",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 2,
          selected: false,
          "Story Name": "Worried about Side Effects",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 3,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 4,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 5,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 6,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
        {
          id: 7,
          selected: false,
          "Story Name": "Unhappy with meal restrictions",
          "Story Type": "Type",
          "Date Created": "12th Nov 2021",
          "# of Annotated Selections": 12,
          Status: "Published",
        },
      ],
    ],
  },
];
export const stories_empty_grouped_columns = [
  {
    title: "Story Name",
    accessor: "Story_Name",
    width: 300,
    Cell: (props: any) => {
      if (props.cell.value === "Updating" || props.cell.value.props.children === "Updating")
        return (
          <div className="text-text-gray-80 text-base">
            {icons.find((l) => l.id === "HourGlass")?.icon}
            Updating
          </div>
        );
      return <div>{props.cell.value}</div>;
    },
  },
  {
    title: "Story Type",
    accessor: "Story_Type",
    sort: true,
    Cell: (props: any) => {
      if (props.cell.value === "Updating")
        return (
          <div className="text-text-gray-80 text-base">
            {icons.find((l) => l.id === "HourGlass")?.icon}
            Updating
          </div>
        );
      return <div>{props.cell.value}</div>;
    },
  },
  {
    title: "Date Created",
    accessor: "Date_Created",
    sort: true,
  },
  {
    title: "# of Annotated Selections",
    accessor: "#_of_Annotated_Selections",
    sort: true,
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
  },
  {
    title: "Status",
    accessor: "Status",
    sort: true,
    Cell: (props: any) => {
      return (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div className="flex">
            {icons.find((l) => l.id === props.cell.value)?.icon}
            &nbsp;
            {props.cell.value}
          </div>
        </div>
      );
    },
  },
  {
    title: "Actions",
    accessor: "Actions",
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
    width: 40,
  },
];
export const storiesEmptyData = [
  {
    Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Story_Name: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Story_Type: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Created: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
];

export const annotated_selection_empty_grouped_columns = [
  {
    title: "Annotated Selection",
    accessor: "Annotated_Selection",
    width: 300,
  },
  {
    title: "Date Added on",
    accessor: "Date_Added_on",
  },
  {
    title: "Source",
    accessor: "Source",
  },
  // {
  //   title: "Therapy Status",
  //   accessor: "therapyStatus",
  // },
  // {
  //   title: "ToT",
  //   accessor: "ToT",
  // },
  {
    title: "Tags",
    accessor: "Tags",
  },

  {
    title: "Status",
    accessor: "Status",
  },
  {
    title: "Actions",
    accessor: "Actions",

    width: 40,
  },
];

export const annotatedSelectionEmptyData = [
  {
    Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Annotated_Selection: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Date_Added_on: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    "#_of_Annotated_Selections": <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Source: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    therapyStatus: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    ToT: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Tags: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Status: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Actions: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
];
export const publishLimit = 2;

export const filterOptions = [
  {
    filterName: "Show Results",
    type: "radio",
    options: ["All", "Already added to a story", "Not added to a story"],
  },
  {
    filterName: "Speaker Type",
    type: "dropdown",
    options: ["Patient", "Agent"],
  },
  {
    filterName: "Time Range",
    type: "date-range",
    options: [],
  },
  {
    filterName: "Time on Therapy",
    type: "slider",
    options: [],
  },
];
export function SearchEngineTabs() {
  const context = React.useContext(AppContext);
  if (context.userType === userTypeEnum[1])
    return [
      {
        subTab: "Search",
        subSubTabs: [],
      },
    ];
  else
    return [
      {
        subTab: "Search",
        subSubTabs: [],
      },
    ];
}

export const searchFilterActiveStatus = {
  Sentiment: true,
  Start_Date: true,
  End_Date: true,
  Call_Type: true,
  Therapy_Status: true,
  Topics: true,
  Emotions: true,
  totStart: true,
  totEnd: true,
};

export const patientScoreChartData = [
  {
    cardName: "#Patients scorecard over time",
    yAxisLabel: [],
    chartData: [
      {
        "Jul 21": {
          Negative: 1136,
          Neutral: 353,
          Positive: 548,
        },
        "Aug 21": {
          Negative: 714,
          Neutral: 202,
          Positive: 305,
        },
        "Sep 21": {
          Negative: 1220,
          Neutral: 382,
          Positive: 579,
        },
        "Oct 21": {
          Negative: 1142,
          Neutral: 379,
          Positive: 543,
        },
        "Nov 21": {
          Negative: 1150,
          Neutral: 329,
          Positive: 529,
        },
        "Dec 21": {
          Negative: 1044,
          Neutral: 313,
          Positive: 530,
        },
        "Jan 22": {
          Negative: 1162,
          Neutral: 361,
          Positive: 574,
        },
        "Feb 22": {
          Negative: 1130,
          Neutral: 353,
          Positive: 550,
        },
        "Mar 22": {
          Negative: 1179,
          Neutral: 341,
          Positive: 528,
        },
        "Apr 22": {
          Negative: 937,
          Neutral: 297,
          Positive: 441,
        },
        "May 22": {
          Negative: 968,
          Neutral: 276,
          Positive: 401,
        },
      },
      {
        "Jul 21": {
          Negative: 1,
          Neutral: 0,
          Positive: 1,
        },
        "Aug 21": {
          Negative: 2,
          Neutral: 0,
          Positive: 0,
        },
        "Sep 21": {
          Negative: 1,
          Neutral: 1,
          Positive: 0,
        },
        "Nov 21": {
          Negative: 1,
          Neutral: 0,
          Positive: 0,
        },
        "Dec 21": {
          Negative: 3,
          Neutral: 0,
          Positive: 0,
        },
        "Jan 22": {
          Negative: 2,
          Neutral: 0,
          Positive: 0,
        },
        "Feb 22": {
          Negative: 3,
          Neutral: 1,
          Positive: 0,
        },
        "Mar 22": {
          Negative: 2,
          Neutral: 0,
          Positive: 0,
        },
        "Apr 22": {
          Negative: 2,
          Neutral: 0,
          Positive: 1,
        },
      },
      {
        "Jul 21": {
          Negative: 99,
          Neutral: 16,
          Positive: 21,
        },
        "Aug 21": {
          Negative: 62,
          Neutral: 13,
          Positive: 10,
        },
        "Sep 21": {
          Negative: 133,
          Neutral: 11,
          Positive: 15,
        },
        "Oct 21": {
          Negative: 109,
          Neutral: 16,
          Positive: 12,
        },
        "Nov 21": {
          Negative: 86,
          Neutral: 10,
          Positive: 10,
        },
        "Dec 21": {
          Negative: 74,
          Neutral: 11,
          Positive: 18,
        },
        "Jan 22": {
          Negative: 107,
          Neutral: 11,
          Positive: 9,
        },
        "Feb 22": {
          Negative: 84,
          Neutral: 18,
          Positive: 6,
        },
        "Mar 22": {
          Negative: 77,
          Neutral: 9,
          Positive: 15,
        },
        "Apr 22": {
          Negative: 49,
          Neutral: 10,
          Positive: 8,
        },
        "May 22": {
          Negative: 64,
          Neutral: 8,
          Positive: 5,
        },
      },
      {
        "Jul 21": {
          Negative: 0,
          Neutral: 1,
          Positive: 1,
        },
        "Aug 21": {
          Negative: 3,
          Neutral: 0,
          Positive: 0,
        },
        "Sep 21": {
          Negative: 4,
          Neutral: 0,
          Positive: 1,
        },
        "Oct 21": {
          Negative: 3,
          Neutral: 1,
          Positive: 0,
        },
        "Nov 21": {
          Negative: 6,
          Neutral: 0,
          Positive: 1,
        },
        "Jan 22": {
          Negative: 3,
          Neutral: 0,
          Positive: 0,
        },
        "Feb 22": {
          Negative: 3,
          Neutral: 0,
          Positive: 0,
        },
        "Mar 22": {
          Negative: 0,
          Neutral: 0,
          Positive: 1,
        },
        "Apr 22": {
          Negative: 2,
          Neutral: 1,
          Positive: 0,
        },
        "May 22": {
          Negative: 1,
          Neutral: 0,
          Positive: 0,
        },
      },
      {
        "Jul 21": {
          Negative: 259,
          Neutral: 48,
          Positive: 51,
        },
        "Aug 21": {
          Negative: 155,
          Neutral: 25,
          Positive: 31,
        },
        "Sep 21": {
          Negative: 267,
          Neutral: 47,
          Positive: 68,
        },
        "Oct 21": {
          Negative: 237,
          Neutral: 39,
          Positive: 73,
        },
        "Nov 21": {
          Negative: 247,
          Neutral: 33,
          Positive: 59,
        },
        "Dec 21": {
          Negative: 219,
          Neutral: 38,
          Positive: 52,
        },
        "Jan 22": {
          Negative: 208,
          Neutral: 33,
          Positive: 53,
        },
        "Feb 22": {
          Negative: 244,
          Neutral: 36,
          Positive: 57,
        },
        "Mar 22": {
          Negative: 222,
          Neutral: 33,
          Positive: 53,
        },
        "Apr 22": {
          Negative: 181,
          Neutral: 34,
          Positive: 46,
        },
        "May 22": {
          Negative: 179,
          Neutral: 30,
          Positive: 22,
        },
      },
      {
        "Jul 21": {
          Negative: 8,
          Neutral: 1,
          Positive: 6,
        },
        "Aug 21": {
          Negative: 10,
          Neutral: 1,
          Positive: 2,
        },
        "Sep 21": {
          Negative: 4,
          Neutral: 1,
          Positive: 1,
        },
        "Oct 21": {
          Negative: 1,
          Neutral: 0,
          Positive: 1,
        },
        "Nov 21": {
          Negative: 2,
          Neutral: 0,
          Positive: 0,
        },
        "Dec 21": {
          Negative: 2,
          Neutral: 1,
          Positive: 0,
        },
        "Jan 22": {
          Negative: 4,
          Neutral: 0,
          Positive: 1,
        },
        "Feb 22": {
          Negative: 1,
          Neutral: 0,
          Positive: 0,
        },
        "Mar 22": {
          Negative: 0,
          Neutral: 1,
          Positive: 0,
        },
        "May 22": {
          Negative: 2,
          Neutral: 0,
          Positive: 0,
        },
      },
      {
        "Jul 21": {
          Negative: 62,
          Neutral: 16,
          Positive: 21,
        },
        "Aug 21": {
          Negative: 59,
          Neutral: 12,
          Positive: 9,
        },
        "Sep 21": {
          Negative: 70,
          Neutral: 24,
          Positive: 26,
        },
        "Oct 21": {
          Negative: 72,
          Neutral: 28,
          Positive: 23,
        },
        "Nov 21": {
          Negative: 84,
          Neutral: 22,
          Positive: 28,
        },
        "Dec 21": {
          Negative: 95,
          Neutral: 25,
          Positive: 42,
        },
        "Jan 22": {
          Negative: 107,
          Neutral: 32,
          Positive: 36,
        },
        "Feb 22": {
          Negative: 106,
          Neutral: 25,
          Positive: 54,
        },
        "Mar 22": {
          Negative: 80,
          Neutral: 36,
          Positive: 45,
        },
        "Apr 22": {
          Negative: 81,
          Neutral: 26,
          Positive: 58,
        },
        "May 22": {
          Negative: 113,
          Neutral: 23,
          Positive: 60,
        },
      },
      {
        "Jul 21": {
          Negative: 2,
          Neutral: 1,
          Positive: 2,
        },
        "Aug 21": {
          Negative: 3,
          Neutral: 3,
          Positive: 1,
        },
        "Sep 21": {
          Negative: 8,
          Neutral: 1,
          Positive: 1,
        },
        "Oct 21": {
          Negative: 9,
          Neutral: 2,
          Positive: 1,
        },
        "Nov 21": {
          Negative: 3,
          Neutral: 2,
          Positive: 4,
        },
        "Dec 21": {
          Negative: 1,
          Neutral: 2,
          Positive: 4,
        },
        "Jan 22": {
          Negative: 4,
          Neutral: 2,
          Positive: 8,
        },
        "Feb 22": {
          Negative: 6,
          Neutral: 1,
          Positive: 4,
        },
        "Mar 22": {
          Negative: 5,
          Neutral: 0,
          Positive: 0,
        },
        "Apr 22": {
          Negative: 5,
          Neutral: 2,
          Positive: 0,
        },
        "May 22": {
          Negative: 4,
          Neutral: 3,
          Positive: 2,
        },
      },
      {
        "Jul 21": {
          Negative: 650,
          Neutral: 231,
          Positive: 384,
        },
        "Aug 21": {
          Negative: 375,
          Neutral: 140,
          Positive: 215,
        },
        "Sep 21": {
          Negative: 667,
          Neutral: 256,
          Positive: 409,
        },
        "Oct 21": {
          Negative: 658,
          Neutral: 267,
          Positive: 373,
        },
        "Nov 21": {
          Negative: 673,
          Neutral: 229,
          Positive: 382,
        },
        "Dec 21": {
          Negative: 592,
          Neutral: 207,
          Positive: 372,
        },
        "Jan 22": {
          Negative: 675,
          Neutral: 244,
          Positive: 406,
        },
        "Feb 22": {
          Negative: 617,
          Neutral: 243,
          Positive: 370,
        },
        "Mar 22": {
          Negative: 734,
          Neutral: 235,
          Positive: 373,
        },
        "Apr 22": {
          Negative: 555,
          Neutral: 195,
          Positive: 284,
        },
        "May 22": {
          Negative: 564,
          Neutral: 192,
          Positive: 284,
        },
      },
      {
        "Jul 21": {
          Negative: 55,
          Neutral: 39,
          Positive: 61,
        },
        "Aug 21": {
          Negative: 45,
          Neutral: 8,
          Positive: 37,
        },
        "Sep 21": {
          Negative: 66,
          Neutral: 41,
          Positive: 58,
        },
        "Oct 21": {
          Negative: 53,
          Neutral: 26,
          Positive: 60,
        },
        "Nov 21": {
          Negative: 48,
          Neutral: 33,
          Positive: 45,
        },
        "Dec 21": {
          Negative: 58,
          Neutral: 29,
          Positive: 42,
        },
        "Jan 22": {
          Negative: 52,
          Neutral: 39,
          Positive: 61,
        },
        "Feb 22": {
          Negative: 66,
          Neutral: 29,
          Positive: 59,
        },
        "Mar 22": {
          Negative: 59,
          Neutral: 27,
          Positive: 41,
        },
        "Apr 22": {
          Negative: 62,
          Neutral: 29,
          Positive: 44,
        },
        "May 22": {
          Negative: 41,
          Neutral: 20,
          Positive: 28,
        },
      },
    ],
    chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
  },
];

export const overAllDisconRate = [
  {
    cardName: "Over All Discon Rate",
    yAxisLabel: [],
    xAxisLabel: [],
    topChart: {},
    chartData: [
      {
        "Jul 21": {
          Positive: 90,
        },
        "Aug 21": {
          Positive: 60,
        },
        "Sep 21": {
          Positive: 70,
        },
        "Oct 21": {
          Positive: 20,
        },
        "Nov 21": {
          Positive: 14,
        },
        "Dec 21": {
          Positive: 26,
        },
        "Jan 21": {
          Positive: 36,
        },
        "Feb 21": {
          Positive: 45,
        },
        "Mar 21": {
          Positive: 28,
        },
        "Apr 21": {
          Positive: 41,
        },
        "May 21": {
          Positive: 80,
        },
      },
    ],
    chartColor: ["#4472C4"],
  },
];

export const disconRateRishBucket = [
  {
    options: [
      "July 21",
      "Aug 21",
      "Sep 21",
      "Oct 21",
      "Nov 21",
      "Dec 21",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 21",
      "June 21",
    ],
    cardName: "Discon Rate - By risk Bucket",
    yAxisLabel: [],
    xAxisLabel: [],
    topChart: {},
    chartData: [
      {
        "Jul 21": {
          High: 40,
          Medium: 30,
          Low: 10,
        },
        "Aug 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "Aug 21": {
          High: 10,
          Medium: 20,
          Low: 30,
        },
        "Sep 21": {
          High: 100,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "Sep 21": {
          High: 70,
          Medium: 80,
          Low: 90,
        },
        "Oct 21": {
          High: 10,
          Medium: 40,
          Low: 60,
        },
      },
      {
        "Oct 21": {
          High: 40,
          Medium: 30,
          Low: 10,
        },
        "Nov 21": {
          High: 55,
          Medium: 100,
          Low: 90,
        },
      },
      {
        "Nov 21": {
          High: 100,
          Medium: 80,
          Low: 70,
        },
        "Dec 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "Dec 21": {
          High: 20,
          Medium: 30,
          Low: 40,
        },
        "Jan 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "Jan 21": {
          High: 50,
          Medium: 60,
          Low: 70,
        },
        "Feb 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "Feb 21": {
          High: 20,
          Medium: 10,
          Low: 70,
        },
        "Mar 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "Mar 21": {
          High: 10,
          Medium: 10,
          Low: 10,
        },
        "Apr 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "Apr 21": {
          High: 70,
          Medium: 90,
          Low: 60,
        },
        "May 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
      {
        "May 21": {
          High: 30,
          Medium: 40,
          Low: 50,
        },
        "June 21": {
          High: 60,
          Medium: 20,
          Low: 10,
        },
      },
      {
        "June 21": {
          High: 20,
          Medium: 80,
          Low: 90,
        },
        "July 21": {
          High: 80,
          Medium: 70,
          Low: 50,
        },
      },
    ],
    chartColor: ["#76BCEE", "#FFC736", "#EE6767"],
  },
];

export const disconRateSplitByTactic = [
  {
    options: [
      "July 21",
      "Aug 21",
      "Sep 21",
      "Oct 21",
      "Nov 21",
      "Dec 21",
      "Jan 21",
      "Feb 21",
      "Mar 21",
      "Apr 21",
      "May 21",
      "June 21",
    ],
    cardName: "Discon Rate - Split By Tactic Activation",
    yAxisLabel: [],
    xAxisLabel: [],
    topChart: {},
    chartData: [
      {
        "Jul 21": {
          "More than One Tactic Scent": 40,
          "One Tactic Scent": 30,
          "No Tactic Scent": 10,
        },
        "Aug 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "Aug 21": {
          "More than One Tactic Scent": 10,
          "One Tactic Scent": 20,
          "No Tactic Scent": 30,
        },
        "Sep 21": {
          "More than One Tactic Scent": 100,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "Sep 21": {
          "More than One Tactic Scent": 70,
          "One Tactic Scent": 80,
          "No Tactic Scent": 90,
        },
        "Oct 21": {
          "More than One Tactic Scent": 10,
          "One Tactic Scent": 40,
          "No Tactic Scent": 60,
        },
      },
      {
        "Oct 21": {
          "More than One Tactic Scent": 40,
          "One Tactic Scent": 30,
          "No Tactic Scent": 10,
        },
        "Nov 21": {
          "More than One Tactic Scent": 55,
          "One Tactic Scent": 100,
          "No Tactic Scent": 90,
        },
      },
      {
        "Nov 21": {
          "More than One Tactic Scent": 100,
          "One Tactic Scent": 80,
          "No Tactic Scent": 70,
        },
        "Dec 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "Dec 21": {
          "More than One Tactic Scent": 20,
          "One Tactic Scent": 30,
          "No Tactic Scent": 40,
        },
        "Jan 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "Jan 21": {
          "More than One Tactic Scent": 50,
          "One Tactic Scent": 60,
          "No Tactic Scent": 70,
        },
        "Feb 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "Feb 21": {
          "More than One Tactic Scent": 20,
          "One Tactic Scent": 10,
          "No Tactic Scent": 70,
        },
        "Mar 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "Mar 21": {
          "More than One Tactic Scent": 10,
          "One Tactic Scent": 10,
          "No Tactic Scent": 10,
        },
        "Apr 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "Apr 21": {
          "More than One Tactic Scent": 70,
          "One Tactic Scent": 90,
          "No Tactic Scent": 60,
        },
        "May 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
      {
        "May 21": {
          "More than One Tactic Scent": 30,
          "One Tactic Scent": 40,
          "No Tactic Scent": 50,
        },
        "June 21": {
          "More than One Tactic Scent": 60,
          "One Tactic Scent": 20,
          "No Tactic Scent": 10,
        },
      },
      {
        "June 21": {
          "More than One Tactic Scent": 20,
          "One Tactic Scent": 80,
          "No Tactic Scent": 90,
        },
        "July 21": {
          "More than One Tactic Scent": 80,
          "One Tactic Scent": 70,
          "No Tactic Scent": 50,
        },
      },
    ],
    chartColor: ["#C3C3C3", "#BACAE5", "#5980C7"],
  },
];

export const dummyDataTable = [
  {
    Patient_ID: "ZS1000",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "Low",
    Top_Unmet_Needs: "Fear of side effects",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1001",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Confusion on Lab Monitoring",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1002",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "Low",
    Top_Unmet_Needs: "Frustration around delayed shipments",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1003",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Confusion on Dosing",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1004",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "Low",
    Top_Unmet_Needs: "Confusion on beverage consumption",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1005",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Lab Monitoring Guidance (average patient mentions)",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1006",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Common Side Effects (average negativity)",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1007",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Shipment (average patient mentions)",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1008",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Dosing Information (average negativity) ",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1009",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Insurance (average negativity)",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1010",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Diet (average curiousity)",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
  {
    Patient_ID: "ZS1011",
    Current_Therapy: "ZSabcd",
    Discon_Risk: "High",
    Top_Unmet_Needs: "Skin Rashes, Itching & Flushing (average negativity)",
    Therapy_Start_Date: "01 Dec 2022",
    Time_On_Therapy: "223",
    Out_Of_Stock_Date: "01 Dec 2022",
  },
];

export const emptyDataTableColumns = [
  {
    title: "Patient ID",
    accessor: "Patient_ID",
    width: 180,
    Cell: (props: any) => {
      return <div>{props.cell.value}</div>;
    },
  },
  {
    title: "Current Therapy",
    accessor: "Current_Therapy",
    sort: true,
    Cell: (props: any) => {
      // if (props.cell.value === "Updating")
      //   return (
      //     <div className="text-text-gray-80 text-base">
      //       {icons.find((l:any) => l.id === "HourGlass")?.icon}
      //       Updating
      //     </div>
      //   );
      return <div>{props.cell.value}</div>;
    },
  },
  {
    title: "Discon Risk",
    accessor: "Discon_Risk",
    sort: true,
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
  },
  {
    title: "Top Unmet Needs",
    accessor: "Top_Unmet_Needs",
    width: 300,
    sort: true,
    Cell: (props: any) => {
      return <div style={{ textAlign: "center" }}>{props.cell.value}</div>;
    },
  },
  {
    title: "Therapy Start Date",
    accessor: "Therapy_Start_Date",
    // sort: true,
  },

  {
    title: "Time On Therapy",
    accessor: "Time_On_Therapy",
    sort: true,
    Cell: (props: any) => {
      return (
        <div
          style={{
            textAlign: "left",
          }}
        >
          <div className="flex">
            {icons.find((l: any) => l.id === props.cell.value)?.icon}
            &nbsp;
            {props.cell.value}
          </div>
        </div>
      );
    },
  },
  {
    title: "Out of Stock Date",
    accessor: "Out_Of_Stock_Date",
    // sort: true,
  },
];

export const dataTableEmptyData = [
  {
    Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Therapy_Start_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Time_On_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Out_Of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Therapy_Start_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Time_On_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Out_Of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
  {
    Patient_ID: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Current_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Discon_Risk: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Top_Unmet_Needs: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Therapy_Start_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Time_On_Therapy: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
    Out_Of_Stock_Date: <Skeleton active title={false} paragraph={{ rows: 1 }} />,
  },
];

export const scatterPlotChartData = [
  {
    Time: "36:50",
    Place: 1,
    Seconds: 2210,
    Name: "Marco Pantani",
    Year: 1995,
    Nationality: "ITA",
    Doping: "Alleged drug use during 1995 due to high hematocrit levels",
    URL: "https://en.wikipedia.org/wiki/Marco_Pantani#Alleged_drug_use",
  },
  {
    Time: "36:55",
    Place: 2,
    Seconds: 2215,
    Name: "Marco Pantani",
    Year: 1997,
    Nationality: "ITA",
    Doping: "Alleged drug use during 1997 due to high hermatocrit levels",
    URL: "https://en.wikipedia.org/wiki/Marco_Pantani#Alleged_drug_use",
  },
  {
    Time: "37:15",
    Place: 3,
    Seconds: 2235,
    Name: "Marco Pantani",
    Year: 1994,
    Nationality: "ITA",
    Doping: "Alleged drug use during 1994 due to high hermatocrit levels",
    URL: "https://en.wikipedia.org/wiki/Marco_Pantani#Alleged_drug_use",
  },
  {
    Time: "37:36",
    Place: 4,
    Seconds: 2256,
    Name: "Lance Armstrong",
    Year: 2004,
    Nationality: "USA",
    Doping: "2004 Tour de France title stripped by UCI in 2012",
    URL: "https://en.wikipedia.org/wiki/History_of_Lance_Armstrong_doping_allegations",
  },
  {
    Time: "37:42",
    Place: 5,
    Seconds: 2262,
    Name: "Jan Ullrich",
    Year: 1997,
    Nationality: "GER",
    Doping: "Confessed later in his career to doping",
    URL: "https://en.wikipedia.org/wiki/Jan_Ullrich#Operaci.C3.B3n_Puerto_doping_case",
  },
  {
    Time: "38:05",
    Place: 6,
    Seconds: 2285,
    Name: "Lance Armstrong",
    Year: 2001,
    Nationality: "USA",
    Doping: "2001 Tour de France title stripped by UCI in 2012",
    URL: "https://en.wikipedia.org/wiki/History_of_Lance_Armstrong_doping_allegations",
  },
  {
    Time: "38:14",
    Place: 7,
    Seconds: 2294,
    Name: "Miguel Indurain",
    Year: 1995,
    Nationality: "ESP",
    Doping: "1994 Failed test for salbutemol, not a banned drug at that time",
    URL: "http://www.independent.co.uk/sport/drugs-in-sport-indurain-allowed-to-use-banned-drug-1379584.html",
  },
];

export const patientNeedsDrivers = [
  {
    chartName: ["Patient Needs Drivers"],
    yAxisLabel: [],
    xAxisLabel: [""],
    chartData: [
      {
        "Dosing Discussion": {
          value: 90,
        },
        "Sadness for Side Effect": {
          value: 75,
        },
        "Anger on Insurance": {
          value: 70,
        },
        "MBTI: Introversion": {
          value: 60,
        },
        "Curisoity in Dosing": {
          value: 30,
        },
      },
    ],
    chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#EAF6FF", "#FFF1F1"],
  },
  {
    chartName: ["Patient Needs Drivers"],
    yAxisLabel: [],
    xAxisLabel: [""],
    chartData: [
      {
        "Dosing Discussion": {
          value: 90,
        },
        "Sadness for Side Effect": {
          value: 75,
        },
        "Anger on Insurance": {
          value: 70,
        },
        "MBTI: Introversion": {
          value: 60,
        },
        "Curisoity in Dosing": {
          value: 30,
        },
      },
    ],
    chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#FFFEE8", "#FFF1F1"],
  },
  {
    cardName: "",
    chartName: ["Patient Needs Drivers"],
    yAxisLabel: [],
    xAxisLabel: [""],
    topChart: {},
    chartData: [
      {
        "Dosing Discussion": {
          value: 90,
        },
        "Sadness for Side Effect": {
          value: 75,
        },
        "Anger on Insurance": {
          value: 70,
        },
        "MBTI: Introversion": {
          value: 60,
        },
        "Curisoity in Dosing": {
          value: 30,
        },
      },
    ],
    chartColor: ["#ADD8E6", "#FFFFE0", "#0B654ACC", "#FFF1F1", "#FFF1F1"],
  },
];

export const driverSummaryTranscript = [
  {
    sideEffectId: 1,
    sideEffectName: "Side Effect",
    numberOfSearchResults: 100,
    searchResults: [
      {
        searchId: 1,
        name: "Transcript from 09 Feb 2022 [12:35:26]",
        // tags: ["Good", "Outbound", "On Therapy", "27", "Negative", "Others"],
        dialogueNo: 11,
        callId: "C83221FF96DD4888BF4D03051BAFA411",
        callDate: "2022-02-09T00:00:00",
        callTime: "12:35:26",
        dialogueAppearedAt: 69.48,
        dialogueLength: 64.83,
        transcriptLength: 837.46,
        patientId: 15078,
        speaker: "Patient",
        context:
          '[{"dialogueNo": 9, "text": "uh-hum well um i\'ve had a little bit of trouble, i haven\'t been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don\'t know what it\'s caused from um my vision is getting worse i think.", "speaker": "Patient"}, {"dialogueNo": 10, "text": "oh.", "speaker": "Agent"}, {"dialogueNo": 11, "text": "um but my my headaches i\'ve been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn\'t touch it without it hurting and they it\'s you know i i just didn\'t know what to do with it and anyway um this went on and it\'s been i\'ve been having a trip, the headaches every day since the my head is no longer sore but you know it\'s well if i push on it it\'s kind of sore but i you know i don\'t know i just it really and i was going to call the doctor here a local doctor and then i thought well it\'ll get better but and then i was gone for a day uh overnight and i well i\'ll wait till i get back and it\'s still the headache is still there.", "speaker": "Patient"}, {"dialogueNo": 12, "text": "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?", "speaker": "Agent"}, {"dialogueNo": 13, "text": "yes yes it is.", "speaker": "Patient"}]',
        elasticSearchId: "C83221FF96DD4888BF4D03051BAFA411_11",
        transcripts: [
          {
            speaker: "Patient",
            text: "uh-hum well um i've had a little bit of trouble, i haven't been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don't know what it's caused from um my vision is getting worse i think.",
            highLights: ["think"],
            dialogueNo: 9,
          },
          {
            speaker: "Agent",
            text: "oh.",
            dialogueNo: 10,
            highLights: [],
          },
          {
            speaker: "Patient",
            text: "um but my my headaches i've been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn't touch it without it hurting and they it's you know i i just didn't know what to do with it and anyway um this went on and it's been i've been having a trip, the headaches every day since the my head is no longer sore but you know it's well if i push on it it's kind of sore but i you know i don't know i just it really and i was going to call the doctor here a local doctor and then i thought well it'll get better but and then i was gone for a day uh overnight and i well i'll wait till i get back and it's still the headache is still there.",
            dialogueNo: 11,
            highLights: [],
          },
          {
            speaker: "Agent",
            text: "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?",
            dialogueNo: 12,
            highLights: ["vumerity"],
          },
        ],
        callTypeId: "2",
        storyDialogues: [],
        therapyStatusId: "1",
        notAddedStories: [],
      },
      {
        searchId: 2,
        name: "Transcript from 10 Jan 2023 [12:35:26]",
        // tags: ["Good", "Outbound", "On Therapy", "27", "Negative", "Others"],
        dialogueNo: 11,
        callId: "C83221FF96DD4888BF4D03051BAFA411",
        callDate: "2022-02-09T00:00:00",
        callTime: "12:35:26",
        dialogueAppearedAt: 69.48,
        dialogueLength: 64.83,
        transcriptLength: 837.46,
        patientId: 15078,
        speaker: "Patient",
        context:
          '[{"dialogueNo": 9, "text": "uh-hum well um i\'ve had a little bit of trouble, i haven\'t been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don\'t know what it\'s caused from um my vision is getting worse i think.", "speaker": "Patient"}, {"dialogueNo": 10, "text": "oh.", "speaker": "Agent"}, {"dialogueNo": 11, "text": "um but my my headaches i\'ve been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn\'t touch it without it hurting and they it\'s you know i i just didn\'t know what to do with it and anyway um this went on and it\'s been i\'ve been having a trip, the headaches every day since the my head is no longer sore but you know it\'s well if i push on it it\'s kind of sore but i you know i don\'t know i just it really and i was going to call the doctor here a local doctor and then i thought well it\'ll get better but and then i was gone for a day uh overnight and i well i\'ll wait till i get back and it\'s still the headache is still there.", "speaker": "Patient"}, {"dialogueNo": 12, "text": "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?", "speaker": "Agent"}, {"dialogueNo": 13, "text": "yes yes it is.", "speaker": "Patient"}]',
        elasticSearchId: "C83221FF96DD4888BF4D03051BAFA411_11",
        transcripts: [
          {
            speaker: "Patient",
            text: "uh-hum well um i've had a little bit of trouble, i haven't been taking the two pills in the morning and two in the evening every day because i have been having trouble with headaches and i don't know what it's caused from um my vision is getting worse i think.",
            highLights: ["think"],
            dialogueNo: 9,
          },
          {
            speaker: "Agent",
            text: "oh.",
            dialogueNo: 10,
            highLights: [],
          },
          {
            speaker: "Patient",
            text: "um but my my headaches i've been waking up happened about a week ago, i woke up one morning and i had a headache and i rarely ever have them but i had this headache and the top of my head was so sore, i couldn't touch it without it hurting and they it's you know i i just didn't know what to do with it and anyway um this went on and it's been i've been having a trip, the headaches every day since the my head is no longer sore but you know it's well if i push on it it's kind of sore but i you know i don't know i just it really and i was going to call the doctor here a local doctor and then i thought well it'll get better but and then i was gone for a day uh overnight and i well i'll wait till i get back and it's still the headache is still there.",
            dialogueNo: 11,
            highLights: [],
          },
          {
            speaker: "Agent",
            text: "mhm. mhm. i see. okay so the headaches did start after you started taking the vumerity is that correct ?",
            dialogueNo: 12,
            highLights: ["vumerity"],
          },
        ],
        callTypeId: "2",
        storyDialogues: [],
        therapyStatusId: "1",
        notAddedStories: [],
      },
    ],
    headers: {
      "x-time":
        '{"query_expansion_time": 0.2521553039550781, "retriever_time": 1.4508168697357178, "ranker_time": 1.2852530479431152, "df_prepare_time": 0.008672475814819336, "process_time": 3.0736827850341797}',
      "x-search-config":
        "{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
      "x-auth-time": "0.23431873321533203",
      "x-process-time": "3.311600923538208",
    },
  },
];

export const dummyDataDriversSummary = {
  numberOfPatients: 84,
  numberOfSearchResults: 20,
  searchResults: [
    {
      name: "Transcript from 01 Nov 2021 [15:47:45]",
      tags: ["Inbound", "Discontinued", "42", "Neutral", "Others"],
      storiesAdded: [],
      source: 1,
      dialogueNo: 40,
      callId: "97F9699980E0415EB33D5D7634F648B4",
      callDate: "2021-11-01T00:00:00",
      callTime: "15:47:45",
      dialogueAppearedAt: 466.45,
      dialogueLength: 36.57,
      transcriptLength: 728.5,
      s3path: "kural/processed_files/97F9699980E0415EB33D5D7634F648B4.json",
      addedToStory: false,
      score: 0.310449600219727,
      patientId: 8665,
      speaker: "Agent",
      context:
        '[{"dialogueNo": 38, "text": "you\'ve had migraines your whole life.", "speaker": "Agent"}, {"dialogueNo": 39, "text": "yes.", "speaker": "Patient"}, {"dialogueNo": 40, "text": "so you had these um even before starting on on vumerity okay, since you\'re still having issues with the headaches, i would suggest you give doctor nico\'s office a call um and let him know that even though you are, you know, taking your vumerity you\'re on your um m. s medication, you\'re having headaches still that are not resolved, he might be able to, you know, advise you or give you, you know something to help control the headaches.", "speaker": "Agent"}, {"dialogueNo": 41, "text": "okay.", "speaker": "Patient"}, {"dialogueNo": 42, "text": "now do you feel that you\'re tolerating the vumerity since you\'ve been on vumerity uh huh.", "speaker": "Agent"}]',
      elasticSearchId: "97F9699980E0415EB33D5D7634F648B4_40",
      transcripts: [
        {
          speaker: "Agent",
          text: "you've had migraines your whole life.",
          dialogueNo: 38,
        },
        {
          speaker: "Patient",
          text: "yes.",
          dialogueNo: 39,
        },
        {
          speaker: "Agent",
          text: "so you had these um even before starting on on vumerity okay, since you're still having issues with the headaches, i would suggest you give doctor nico's office a call um and let him know that even though you are, you know, taking your vumerity you're on your um m. s medication, you're having headaches still that are not resolved, he might be able to, you know, advise you or give you, you know something to help control the headaches.",
          dialogueNo: 40,
        },
        {
          speaker: "Patient",
          text: "okay.",
          dialogueNo: 41,
        },
        {
          speaker: "Agent",
          text: "now do you feel that you're tolerating the vumerity since you've been on vumerity uh huh.",
          dialogueNo: 42,
        },
      ],
      callTypeId: "1",
      storyDialogues: [],
      therapyStatusId: "2",
      notAddedStories: [],
    },
    {
      name: "Transcript from 05 Nov 2021 [12:21:44]",
      tags: ["Outbound", "Discontinued", "30", "Negative", "Others"],
      storiesAdded: [],
      source: 1,
      dialogueNo: 60,
      callId: "ACEC79FADC0946318DDDADC13129B0EF",
      callDate: "2021-11-05T00:00:00",
      callTime: "12:21:44",
      dialogueAppearedAt: 631.74,
      dialogueLength: 21.1,
      transcriptLength: 899.8,
      s3path: "kural/processed_files/ACEC79FADC0946318DDDADC13129B0EF.json",
      addedToStory: false,
      score: 0.299056351184845,
      patientId: 15127,
      speaker: "Patient",
      context:
        '[{"dialogueNo": 58, "text": "like i said a week ago monday and um i was better in about three days it took you know, they said it would take you know at least 12 hours to start getting it out of my system.", "speaker": "Patient"}, {"dialogueNo": 59, "text": "right i hear you okay alrighty wow mhm oh wow right yeah no i hear you, i hear you that makes sense to me.", "speaker": "Agent"}, {"dialogueNo": 60, "text": "but by the middle of last week i was the shaking was gone, the headache was gone, i had headaches with um a vumerity that i did not have with the tecfidera tecfidera for some reason, but i had headaches every day and they stopped and the shaking stop in both counts. so i don\'t know what else it would be.", "speaker": "Patient"}, {"dialogueNo": 61, "text": "um but so what actually no you did actually you did increase to the maintenance dose correct ?", "speaker": "Agent"}, {"dialogueNo": 62, "text": "it had to be no, i i increased it.", "speaker": "Patient"}]',
      elasticSearchId: "ACEC79FADC0946318DDDADC13129B0EF_60",
      transcripts: [
        {
          speaker: "Patient",
          text: "like i said a week ago monday and um i was better in about three days it took you know, they said it would take you know at least 12 hours to start getting it out of my system.",
          dialogueNo: 58,
        },
        {
          speaker: "Agent",
          text: "right i hear you okay alrighty wow mhm oh wow right yeah no i hear you, i hear you that makes sense to me.",
          dialogueNo: 59,
        },
        {
          speaker: "Patient",
          text: "but by the middle of last week i was the shaking was gone, the headache was gone, i had headaches with um a vumerity that i did not have with the tecfidera tecfidera for some reason, but i had headaches every day and they stopped and the shaking stop in both counts. so i don't know what else it would be.",
          dialogueNo: 60,
        },
        {
          speaker: "Agent",
          text: "um but so what actually no you did actually you did increase to the maintenance dose correct ?",
          dialogueNo: 61,
        },
        {
          speaker: "Patient",
          text: "it had to be no, i i increased it.",
          dialogueNo: 62,
        },
      ],
      callTypeId: "2",
      storyDialogues: [],
      therapyStatusId: "2",
      notAddedStories: [],
    },
    {
      searchId: 1,
      name: "Transcript from 25 May 2022 [20:15:09]",
      tags: ["Positive", "Outbound", "UNKNOWN"],
      dialogueNo: 4,
      callId: "O1388T9ZZ5BCLGTD",
      callDate: "2022-05-25",
      callTime: "20:15:09",
      dialogueAppearedAt: 16.1,
      dialogueLength: 15.18,
      transcriptLength: 75.44,
      patientId: -1,
      speaker: "Patient",
      storiesAdded: [],
      addedToStory: "false",
      context:
        '[{"dialogueNo": 2, "text": "i\'m just so frustrated. my insurance company denied my claim for lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me what happened ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "i\'ve been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they\'re saying it\'s not medically necessary and they won\'t pay for it.", "speaker": "Patient"}, {"dialogueNo": 5, "text": "that doesn\'t sound right. let me see if i can help you figure this out.", "speaker": "Agent"}, {"dialogueNo": 6, "text": "i just don\'t understand why they would deny it. it\'s not like it\'s a luxury medication or anything.", "speaker": "Patient"}]',
      s3path: "kural/processed_files/O1388T9ZZ5BCLGTD.json",
      elasticSearchId: "O1388T9ZZ5BCLGTD_4",
      transcripts: [
        {
          speaker: "Patient",
          text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
          highLights: ["lectrazine"],
          dialogueNo: 2,
        },
        {
          speaker: "Agent",
          text: "i'm sorry to hear that. can you tell me what happened ?",
          highLights: [],
          dialogueNo: 3,
        },
        {
          speaker: "Patient",
          text: "i've been taking lectrazine for my migraines for years, and my insurance has always covered it. but now they're saying it's not medically necessary and they won't pay for it.",
          highLights: ["lectrazine"],
          dialogueNo: 4,
        },
        {
          speaker: "Agent",
          text: "that doesn't sound right. let me see if i can help you figure this out.",
          highLights: [],
          dialogueNo: 5,
        },
        {
          speaker: "Patient",
          text: "i just don't understand why they would deny it. it's not like it's a luxury medication or anything.",
          highLights: [],
          dialogueNo: 6,
        },
      ],
      callTypeId: 2,
      therapyStatusId: -1,
    },
    {
      searchId: 2,
      name: "Transcript from 09 May 2022 [21:20:36]",
      tags: ["Negative", "Common Side Effects", "Outbound", "UNKNOWN"],
      dialogueNo: 3,
      callId: "WW8PUBYMYJOW4S8N",
      callDate: "2022-05-09",
      callTime: "21:20:36",
      dialogueAppearedAt: 39.56,
      dialogueLength: 11.5,
      transcriptLength: 118.68,
      patientId: -1,
      speaker: "Patient",
      storiesAdded: [],
      addedToStory: "false",
      context:
        '[{"dialogueNo": 1, "text": "i\'m really worried about the side effects of lectrazine. i\'ve been hearing a lot of bad things about it.", "speaker": "Patient"}, {"dialogueNo": 2, "text": "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.", "speaker": "Agent"}, {"dialogueNo": 3, "text": "i\'ve been having a lot of headaches and dizziness since i started taking lectrazine. it\'s really starting to affect my quality of life.", "speaker": "Patient"}, {"dialogueNo": 4, "text": "i\'m sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you\'re still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.", "speaker": "Agent"}, {"dialogueNo": 5, "text": "i\'m really getting worried about these side effects. is there anything i can do to reduce them ?", "speaker": "Patient"}]',
      s3path: "kural/processed_files/WW8PUBYMYJOW4S8N.json",
      elasticSearchId: "WW8PUBYMYJOW4S8N_3",
      transcripts: [
        {
          speaker: "Patient",
          text: "i'm really worried about the side effects of lectrazine. i've been hearing a lot of bad things about it.",
          highLights: ["lectrazine"],
          dialogueNo: 1,
        },
        {
          speaker: "Agent",
          text: "lectrazine is a very effective medication for treating allergies, but like all medications, it can cause some side effects. the most common side effects include headache, dizziness, and nausea. these side effects are usually mild and go away on their own. however, if you experience any of these side effects, please let your doctor know.",
          highLights: ["lectrazine"],
          dialogueNo: 2,
        },
        {
          speaker: "Patient",
          text: "i've been having a lot of headaches and dizziness since i started taking lectrazine. it's really starting to affect my quality of life.",
          highLights: ["lectrazine"],
          dialogueNo: 3,
        },
        {
          speaker: "Agent",
          text: "i'm sorry to hear that. headaches and dizziness are common side effects of lectrazine, but they usually go away on their own. if you're still experiencing these side effects after a few days, please let your doctor know. they may be able to adjust your dose or switch you to a different medication.",
          highLights: ["lectrazine"],
          dialogueNo: 4,
        },
        {
          speaker: "Patient",
          text: "i'm really getting worried about these side effects. is there anything i can do to reduce them ?",
          highLights: [],
          dialogueNo: 5,
        },
      ],
      callTypeId: 2,
      therapyStatusId: -1,
    },
    {
      searchId: 3,
      name: "Transcript from 25 May 2022 [15:30:20]",
      tags: ["Negative", "Outbound", "UNKNOWN"],
      dialogueNo: 2,
      callId: "F1T9KO8WBYD609GF",
      callDate: "2022-05-25",
      callTime: "15:30:20",
      dialogueAppearedAt: 3.68,
      dialogueLength: 6.44,
      transcriptLength: 119.14,
      patientId: -1,
      speaker: "Patient",
      storiesAdded: [],
      addedToStory: "false",
      context:
        '[{"dialogueNo": 1, "text": "hi, how can i help you ?", "speaker": "Agent"}, {"dialogueNo": 2, "text": "i\'m just so frustrated. my insurance company denied my claim for lectrazine.", "speaker": "Patient"}, {"dialogueNo": 3, "text": "i\'m sorry to hear that. can you tell me what happened ?", "speaker": "Agent"}, {"dialogueNo": 4, "text": "they said that it\'s not a covered medication. but i\'ve been taking it for years and it\'s the only thing that helps me.", "speaker": "Patient"}]',
      s3path: "kural/processed_files/F1T9KO8WBYD609GF.json",
      elasticSearchId: "F1T9KO8WBYD609GF_2",
      transcripts: [
        {
          speaker: "Agent",
          text: "hi, how can i help you ?",
          highLights: [],
          dialogueNo: 1,
        },
        {
          speaker: "Patient",
          text: "i'm just so frustrated. my insurance company denied my claim for lectrazine.",
          highLights: ["lectrazine"],
          dialogueNo: 2,
        },
        {
          speaker: "Agent",
          text: "i'm sorry to hear that. can you tell me what happened ?",
          highLights: [],
          dialogueNo: 3,
        },
        {
          speaker: "Patient",
          text: "they said that it's not a covered medication. but i've been taking it for years and it's the only thing that helps me.",
          highLights: [],
          dialogueNo: 4,
        },
      ],
      callTypeId: 2,
      therapyStatusId: -1,
    },
  ],
  statesResults: [
    {
      id: "TX",
      value: 12,
    },
    {
      id: "MI",
      value: 9,
    },
    {
      id: "VA",
      value: 8,
    },
    {
      id: "OH",
      value: 6,
    },
    {
      id: "NY",
      value: 6,
    },
    {
      id: "CA",
      value: 6,
    },
    {
      id: "NJ",
      value: 4,
    },
    {
      id: "MN",
      value: 4,
    },
    {
      id: "AZ",
      value: 3,
    },
    {
      id: "PA",
      value: 3,
    },
    {
      id: "MO",
      value: 3,
    },
    {
      id: "NC",
      value: 3,
    },
    {
      id: "ID",
      value: 3,
    },
    {
      id: "FL",
      value: 3,
    },
    {
      id: "IL",
      value: 2,
    },
    {
      id: "ME",
      value: 2,
    },
    {
      id: "GA",
      value: 2,
    },
    {
      id: "MA",
      value: 2,
    },
    {
      id: "WI",
      value: 2,
    },
    {
      id: "OK",
      value: 2,
    },
    {
      id: "NM",
      value: 1,
    },
    {
      id: "UT",
      value: 1,
    },
    {
      id: "WV",
      value: 1,
    },
    {
      id: "ND",
      value: 1,
    },
    {
      id: "SC",
      value: 1,
    },
    {
      id: "AR",
      value: 1,
    },
    {
      id: "NE",
      value: 1,
    },
    {
      id: "LA",
      value: 1,
    },
    {
      id: "KY",
      value: 1,
    },
    {
      id: "IN",
      value: 1,
    },
    {
      id: "WA",
      value: 1,
    },
    {
      id: "CO",
      value: 1,
    },
    {
      id: "MS",
      value: 1,
    },
    {
      id: "OR",
      value: 1,
    },
    {
      id: "AL",
      value: 1,
    },
  ],
  emotionsCounts: [
    {
      text: "information",
      value: 19,
    },
    {
      text: "questions",
      value: 16,
    },
  ],
  headers: {
    "x-time":
      '{"query_expansion_time": 0.24471282958984375, "retriever_time": 0.612053394317627, "ranker_time": 0.7366836071014404, "df_prepare_time": 0.008089542388916016, "process_time": 1.6488804817199707}',
    "x-search-config":
      "{\"query_type\": \"keyword\", \"expanded_query\": \"[('bad headache', 0.9180014), ('slight headache', 0.9074279), ('big headache', 0.90502834), ('little headache', 0.89852965), ('mild headache', 0.8773646), ('real bad headache', 0.87445515), ('very bad headache', 0.86825484), ('really bad headache', 0.86764216), ('weird headache', 0.863446), ('dull headache', 0.85253), ('headache', 1)]\"}",
    "x-auth-time": "0.19579458236694336",
    "x-process-time": "1.8481688499450684",
  },
};

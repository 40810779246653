import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import { ContextProvider } from "./store/index";
import axios from "axios";
import { Provider } from "react-redux";
import { store } from "./state/store";


export const request = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});
function App() {
	request.interceptors.request.use((config) => reqConfig(config));
	request.interceptors.response.use(
		(response) => responseHandler(response),
		(error) => errorHandler(error)
	);
	const reqConfig = (config: any) => {
		// config.headers["userName"] = localStorage.getItem("userName");
		config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
		return config;
	};
	const responseHandler = (response: any) => {
		return response;
	};
	const errorHandler = (error: any) => {
		console.log("ERR...", error.response.status);
		if (error.response.status === 401)
			window.location.href = process.env.REACT_APP_REDIRECT_URL || "";
		return Promise.reject(error);
		
	};
	return (
		<Provider store={store}>
			<ContextProvider>
				<BrowserRouter>
					<Layout />
				</BrowserRouter>
			</ContextProvider>
		</Provider>
	);
}

export default App;

import React from "react";
import TopNavbar from "./TopNavbar";
import { Route, Routes } from "react-router-dom";
import { Routes as AppRoutes } from "../routes";
import AppContext from "../store";
import NotFound from "../Components/NotFound";
import Welcome from "../Components/Welcome";
import * as LayoutServiceHelper from "./ServiceHelper";
import { request } from "../App";
import CryptoJS from "crypto-js";
// import { userTypeEnum } from "../GlobalThemes";
// import { debug } from "util";

/**
 * Renders the Main Layout of the App. This function also sets up the Main Routes of the App based on the User Type.
 *
 * @returns Main Layout of the App.
 */
export default function Layout() {
	
	const routes = AppRoutes();
	const context = React.useContext(AppContext);
	const [loading, setLoading] = React.useState(true);
	const [data, setData] = React.useState<any>(null);
	const [userProfileArray, setUserProfileArray] = React.useState<any[] | null>(null);

  React.useEffect(() => {
    // console.log(context.userProfile);
    const reqInterceptor = request.interceptors.request.use((config) => reqConfig(config));

    return () => {
      // Eject the interceptor when the component unmounts or when the dependency changes
      request.interceptors.request.eject(reqInterceptor);
    };
  }, [context.userProfile]);

  const reqConfig = (config: any) => {
    // Default headers
    config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;

    // If user profile exists, set userRole header
    if (context.userProfile) {
      config.headers["userRole"] = context.userProfile.profileName;
    }

    return config;
  };

	const createUserProfileObjectArray = (userProfileStringArray: string[], prefix: string) => {
		return userProfileStringArray?.map(profile => ({
			profileName: profile,
			displayName: profile.replace(prefix, "").replace(/_/g, ' ')
		}));
	};

	async function fetchData() {
		const data: any = await LayoutServiceHelper.getData();
		// console.log(data);
		// console.log("Check" + CryptoJS.MD5("Power"));
		if (data) {
			const prefixToRemove = "Profile_";
			const userProfilesObject: any = createUserProfileObjectArray(data.userProfileRoles, prefixToRemove);
			if (userProfilesObject) {
				setUserProfileArray(userProfilesObject);
				context.setUserProfile(userProfilesObject[0]);
			}
			// context.setUserProfile(data.userProfileRoles[0]);
			// console.log("Context Selected User Profile: ", userProfilesObject[0].displayName);

			context.setDates(
				data.lastRefreshedOn,
				data.startDate,
				data.endDate
			);
			context.setEnv(data.envId);
			
			let user  = {userName :"",userType : ""};
			if(CryptoJS.MD5("Power") == data.userType)
			{
				user = {
					userType: "Power",
					userName: data.userName,
				};
				console.log("Power");
			}
			else if(CryptoJS.MD5("Business") == data.userType)
			{
				user = {
					userType: "Business",
					userName: data.userName,
				};
				console.log("Business");
			}
			else if(CryptoJS.MD5("Analyst") == data.userType)
			{
				user = {
					userType: "Analyst",
					userName: data.userName,
				};
				console.log("Analyst");
			}
			context.setUser(user.userName, user.userType);
			
			const userString = new Response(JSON.stringify(user));
			caches.open("userData").then((cache) => {
				cache.put("userData", userString);
			});
			// request.interceptors.request.use((config) => reqConfig(config));
			// const reqConfig = (config: any) => {
			// 	// config.headers["userName"] = user.userName;
			// 	config.headers["userRole"] = context.userProfile;
			// 	config.headers["x-api-key"] = process.env.REACT_APP_API_KEY;
			// 	return config;
			// };
			setData(data);
			setLoading(false);
		}
	}
	// React.useEffect(() => {
	// 	context.setDates(
	// 		"30 Nov 2021 11:36 PM EST",
	// 		"01 Apr 2020",
	// 		"30 Nov 2021"
	// 	);
	// 	context.setUser(userTypeEnum[0]);
	// 	localStorage.setItem("lastRefreshedOn", "30 Nov 2021 11:36 PM EST");
	// 	localStorage.setItem("startDate", "01 Apr 2020");
	// 	localStorage.setItem("endDate", "30 Nov 2021");
	// 	var response = {
	// 		userType: userTypeEnum[0],
	// 		userName: "Vinayak",
	// 	};
	// 	const data = new Response(JSON.stringify(response));
	// 	caches.open("userData").then((cache) => {
	// 		cache.put("userData", data);
	// 	});

	// 	localStorage.setItem("userType", userTypeEnum[0]);
	// }, []);
	React.useEffect(() => {
		fetchData();
	}, []);

  const dummyData = ["Cali", "NY", "AMSTERDAM"];

	return (
		<>
			{!loading ? (
				<div className="w-screen h-screen font-body overflow-y-hidden overflow-x-hidden">
					<TopNavbar
						applicationName="Kural"
						lastRefreshedOn={context.lastRefreshedOn}
						dataTimeframeForCalls={`${context.startDate} - ${context.endDate}`}
						userProfileRoles={userProfileArray ? userProfileArray : []}
						// userProfileRoles={dummyData}
					/>
					<>
						<Routes>
							{routes.map((route, index) => (
								<Route key={index} {...route} />
							))}
							<Route path="/*" element={<NotFound />} />
						</Routes>
					</>
				</div>
			) : (
				<>
					<Welcome />
				</>
			)}
		</>
	);
}

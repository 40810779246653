import { ResponsiveScatterPlot } from "@nivo/scatterplot";
// import { url } from "inspector";
import * as ReportTypesComponentProps from "../../interfaces/main";
import transformData, { findMean, findRange, findZRange } from "./transform";
import { animated } from "@react-spring/web";
import "./style.css";
import React from "react";

export const changeSecondsFormat = (time: any) => {
	if (time < 60) return `${Math.floor(time)}s`;
	else {
		var m = Math.floor(time / 60);
		var s = Math.floor(time % 60);
		return `${m}m ${s}s`;
	}
};
function showTooltip(
	evt: any,

	setTooltipShow: React.Dispatch<React.SetStateAction<boolean>>,
	setTooltipContent: React.Dispatch<React.SetStateAction<string>>,
	text: string
) {
	let tooltip = document.getElementById("customTooltip");
	if (tooltip) {
		tooltip.style.left = evt.pageX + 10 + "px";
		tooltip.style.top = evt.pageY + 10 + "px";
		setTooltipShow(true);
		setTooltipContent(text);
	}
}

function hideTooltip(
	setTooltipShow: React.Dispatch<React.SetStateAction<boolean>>
) {
	setTooltipShow(false);
}
const customMarker = (
	specs: any,
	meanX: number,
	meanY: number,
	setTooltipShow: React.Dispatch<React.SetStateAction<boolean>>,
	setTooltipContent: React.Dispatch<React.SetStateAction<string>>
) => {
	return (
		<>
			<svg height={specs.innerHeight} width={specs.innerWidth}>
				<rect
					height={specs.innerHeight}
					width={2}
					fill="#86868E"
					x={`${
						((meanX - specs.xScale.domain()[0]) * 100) /
						(specs.xScale.domain()[1] - specs.xScale.domain()[0])
					}%`}
					// onMouseMove={(e) => {
					// 	showTooltip(
					// 		e,
					// 		setTooltipShow,
					// 		setTooltipContent,
					// 		`Mean : ${meanX}`
					// 	);
					// }}
					// onMouseOut={() => {
					// 	hideTooltip(setTooltipShow);
					// }}
				></rect>
				<rect
					height={specs.innerHeight}
					width={10}
					fill="red"
					fill-opacity="0"
					onMouseMove={(e) => {
						showTooltip(
							e,
							setTooltipShow,
							setTooltipContent,
							`Mean : ${meanX}`
						);
					}}
					onMouseOut={() => {
						hideTooltip(setTooltipShow);
					}}
					x={`${
						((meanX - specs.xScale.domain()[0]) * 100) /
							(specs.xScale.domain()[1] -
								specs.xScale.domain()[0]) -
						400 / specs.innerWidth
					}%`}
				></rect>

				<rect
					width={specs.innerWidth}
					height={2}
					fill="#86868E"
					y={`${
						100 -
						((meanY - specs.yScale.domain()[0]) * 100) /
							(specs.yScale.domain()[1] -
								specs.yScale.domain()[0])
					}%`}
					// onMouseMove={(e) => {
					// 	showTooltip(
					// 		e,
					// 		setTooltipShow,
					// 		setTooltipContent,
					// 		`Mean : ${meanY}`
					// 	);
					// }}
					// onMouseOut={() => {
					// 	hideTooltip(setTooltipShow);
					// }}
				></rect>
				<rect
					width={specs.innerWidth}
					height={10}
					fill="red"
					fill-opacity="0"
					onMouseMove={(e) => {
						showTooltip(
							e,
							setTooltipShow,
							setTooltipContent,
							`Mean : ${meanY}`
						);
					}}
					onMouseOut={() => {
						hideTooltip(setTooltipShow);
					}}
					y={`${
						100 -
						((meanY - specs.yScale.domain()[0]) * 100) /
							(specs.yScale.domain()[1] -
								specs.yScale.domain()[0]) -
						400 / specs.innerHeight
					}%`}
				></rect>
			</svg>
		</>
	);
};
export const removeMinuteString = (str: any) => {
	var newStr = str;
	if (str.indexOf("(mins)") > -1) {
		newStr = str.replace("(mins)", "");
	}
	if (str.indexOf("(minutes)") > -1) {
		newStr = str.replace("(minutes)", "");
	}
	return newStr;
};

const customNode = (val: any, style: any) => {
	return (
		<g>
			<animated.circle
				className="custom-node"
				cx={val.node.x}
				cy={val.node.y}
				r={val.node.size}
				stroke="white"
				stroke-width="1"
				fill={val.node.color}
				// style={{ mixBlendMode: "multiply" }}
				onMouseEnter={(e) => {
					val.onMouseEnter(val.node, e);
				}}
				onMouseMove={(e) => {
					val.onMouseMove(val.node, e);
				}}
				onMouseLeave={(e) => {
					val.onMouseLeave(val.node, e);
				}}
				onClick={(e) => {
					console.log("Here", e);
				}}
			/>
		</g>
	);
};
const theme = (
	axisWidth?: number,
	axisColor?: string,
	textColor?: string,
	background?: string,
	fontSize?: number
) => {
	return {
		background: background || "white",
		textColor: textColor || "black",
		fontSize: fontSize || 11,
		axis: {
			domain: {
				line: {
					stroke: axisColor || "black",
					strokeWidth: axisWidth !== undefined ? axisWidth : 2,
				},
			},
			textColor: axisColor || "black",
			tickColor: axisColor || "black",
			legend: {
				text: {
					fill: "#454250",
					fontSize: "0.875rem",
					fontWeight: 600,
				},
			},
			ticks: {
				line: {
					stroke: "#DDDDDD",
					// strokeWidth: 0,
				},
				text: {
					fill: "#454250",
					fontSize: "0.675rem",
					fontWeight: 600,
				},
			},
		},
	};
};
const AreaLayer = (
	specs: any,
	meanX: number,
	meanY: number,
	backgroundDivision?: 2 | 3 | 4
) => {
	console.log("InnerWidth", specs.xScale.domain());
	if (backgroundDivision === 3)
		return (
			<svg width={specs.innerWidth} height={specs.innerHeight}>
				<defs>
					<linearGradient
						id="Gradient1"
						x1="0%"
						x2="100%"
						y1="0"
						y2="0"
					>
						<stop offset="0%" stop-color="#FCEEE9" />
						<stop offset="33.33%" stop-color="#FCEEE9" />
						<stop offset="33.33%" stop-color="white" />
						<stop offset="66.66%" stop-color="white" />
						<stop offset="66.66%" stop-color="#E9F9F4" />
						<stop offset="100%" stop-color="#E9F9F4" />
					</linearGradient>
				</defs>
				<rect
					className="chartSvg"
					style={{ fill: "url(#Gradient1)" }}
				/>
			</svg>
		);
	else if (backgroundDivision === 4)
		return (
			<svg width={specs.innerWidth} height={specs.innerHeight}>
				<defs>
					<linearGradient
						id="Gradient1"
						x1="0%"
						x2="100%"
						y1="0%"
						y2="0%"
					>
						<stop offset="0%" stop-color="#E9F9F4" />
						<stop offset="100%" stop-color="#E9F9F4" />
					</linearGradient>
				</defs>
				<rect
					className="chartSvg1"
					style={{ fill: "url(#Gradient1)" }}
					x={`${
						((meanX - specs.xScale.domain()[0]) * 100) /
						(specs.xScale.domain()[1] - specs.xScale.domain()[0])
					}%`}
					y={`-${
						((meanY - specs.yScale.domain()[0]) * 100) /
						(specs.yScale.domain()[1] - specs.yScale.domain()[0])
					}%`}
				/>
			</svg>
		);
	else return <></>;
};
function generateGrid(min: number, max: number, divisions: number) {
	let range: number[] = [];
	if (min * max >= 0) {
		range.push(min);
		for (let i = 1; i < divisions; i++) {
			range.push(min + (i * (max - min)) / divisions);
		}
		range.push(max);
	} else {
		const temp = Math.max(Math.abs(min), Math.abs(max));
		range.push(min);
		for (let i = 1; i < divisions; i++) {
			for (let i = 1; i < divisions; i++) {
				range.push(min + (i * (max - min)) / divisions);
			}
			range.push(max);
		}
		// range.push(-temp);
		// for (let i = 1; i < divisions / 2; i++) {
		// 	range.push(-temp + (i * 2 * temp) / divisions);
		// }
		// range.push(0);
		// for (let i = 1; i < divisions / 2; i++) {
		// 	range.push((i * 2 * temp) / divisions);
		// }
		// range.push(temp);
	}
	return range;
}
/**
 * Renders the Scatter plot  Component. The component makes use of Nivo Charts Library.
 *
 * @@remarks
 * It is mandatory to assign height to the Container Element of this Component, else nothing will be rendered.
 *
 * @param props - Chart Config for the Scatter plot Component
 *
 * @returns Scatter plot Component of Nivo
 */
const ScatterPlot: React.FC<ReportTypesComponentProps.Interface> = (
	props: ReportTypesComponentProps.Interface
): JSX.Element => {
	const [tooltipShow, setTooltipShow] = React.useState(false);
	const [tooltipContent, setTooltipContent] = React.useState("");
	const [toolPos, setToolPos] = React.useState({
		top: 0,
		left: 0,
		right: 0,
	});
	var positions = {
		top: 0,
		left: 0,
		right: 0,
	};
	var inputRef = React.useRef();

	const changeTooltipPosition = (
		width: any,
		midX: any,
		midY: any,
		node: any,
		format: any
	) => {
		var minX = findRange(props.data, props.series).minX;
		var maxX = findRange(props.data, props.series).maxX;
		var mid =
			window.innerWidth > 1024
				? window.innerWidth / 2
				: window.innerWidth;
		if (format === "needs") {
			mid = window.innerWidth;
		}
		console.log(
			"pOSITIONS ",
			width,
			maxX,
			node.node.x,
			window.innerWidth,
			inputRef
		);
		console.log(node.node.x + width + 200);
		if (node.node.x + width + 200 > mid) {
			console.log("Inside If");
			positions.left = mid - (node.node.x + width + 200);
		}
		console.log(positions);
		return positions;
	};
	const customTooltip = (
		node: any,
		format: string,
		primaryX: string,
		primaryY: string,
		title: string,
		midX?: number,
		midY?: number
	) => {
		console.log(node);
		return (
			<div
				ref={(n) => {
					var pos = changeTooltipPosition(
						n?.offsetWidth,
						midX,
						midY,
						node,
						format
					);
					setToolPos(pos);
					console.log(pos);
				}}
				style={{
					color: "white",
					background: "#333333ba",
					padding: "12px 16px",
					position: "absolute",
					width: "max-content",
					top: toolPos.top,
					left: toolPos.left,
				}}
			>
				{format !== "agentA" && format !== "needs" ? (
					<p>
						<strong>Topic</strong>:{" "}
						{node.node.data ? node.node.data.w : 0}
					</p>
				) : null}
				{format === "agentA" &&
					<p>
						<strong>Agent ID</strong>:{" "}
						{node.node.data.w}
					</p>}
				{format !== "agentA" && format !== "needs" ? (
					<p>
						<strong>{removeMinuteString(primaryX)}</strong>:{" "}
						{node.node.data
							? Math.round(node.node.data.x * 10) / 10
							: 0}
						%
					</p>
				) : format === "agentA" ? (
					<p>
						<strong>{removeMinuteString(primaryX)}</strong>:{" "}
						{node.node.data
							? changeSecondsFormat(node.node.data.x * 60)
							: 0}
					</p>
				) : format === "needs" ? (
					<p>
						<strong>{removeMinuteString(primaryX)}</strong>:{" "}
						{node.node.data
							? Math.round(node.node.data.x * 10) / 10
							: 0}
					</p>
				) : null}
				{format !== "agentA" && format !== "needs" ? (
					<p>
						<strong>{removeMinuteString(primaryY)}</strong>:{" "}
						{node.node.data
							? title === "Average time Spent on Topic (minutes)"
								? Math.round(node.node.data.y * 10) / 10 + "%"
								: changeSecondsFormat(
										node.node.data
											? node.node.data.y * 60
											: 0
								  )
							: 0}
					</p>
				) : format === "agentA" || format === "needs" ? (
					<p>
						<strong>{removeMinuteString(primaryY)}</strong>:{" "}
						{node.node.data ? node.node.data.y : 0}
					</p>
				) : null}
				{format !== "agentA" && format !== "needs" ? (
					<p>
						<strong>{removeMinuteString(title)}</strong>:{" "}
						{node.node.data
							? title === "Average time Spent on Topic (minutes)"
								? changeSecondsFormat(
										node.node.data
											? node.node.data.z * 60
											: 0
								  )
								: node.node.data.z
							: 0}
					</p>
				) : null}
			</div>
		);
	};

	return (
		<>
			<div
				id="customTooltip"
				style={{
					position: "absolute",
					display: tooltipShow ? "block" : "none",
					color: "white",
					background: "#333333ba",
					padding: "12px 16px",

					right: 0,
					width: "max-content",
					zIndex: 99,
				}}
			>
				{tooltipContent}
			</div>
			<ResponsiveScatterPlot
				data={transformData(props.data, props.series)}
				markers={
					props.chartArea?.twoByTwo
						? [
								{
									axis: "y",
									value: findMean(props.data, props.series)
										.meanY,
									// findRange(props.data, props.series).minY *
									// 	findRange(props.data, props.series)
									// 		.maxY <
									// 0
									// 	? (findRange(props.data, props.series)
									// 			.maxY +
									// 			findRange(
									// 				props.data,
									// 				props.series
									// 			).minY) /
									// 	  2
									// 	: (findRange(props.data, props.series)
									// 			.maxY +
									// 			findRange(
									// 				props.data,
									// 				props.series
									// 			).minY) /
									// 	  2,
									lineStyle: {
										stroke: "#85858D",
										strokeWidth: 2,
									},
									// legend: `mean : ${
									// 	findMean(props.data, props.series).meanY
									// }`,
									legendPosition: "left",
									legendOrientation: "vertical",
									textStyle: { fontSize: "0.75rem" },
								},
								{
									axis: "x",
									value: findMean(props.data, props.series)
										.meanX,
									// findRange(props.data, props.series).minX *
									// 	findRange(props.data, props.series)
									// 		.maxX <
									// 0
									// 	? (findRange(props.data, props.series)
									// 			.maxX +
									// 			findRange(
									// 				props.data,
									// 				props.series
									// 			).minX) /
									// 	  2
									// 	: (findRange(props.data, props.series)
									// 			.maxX +
									// 			findRange(
									// 				props.data,
									// 				props.series
									// 			).minX) /
									// 	  2,
									lineStyle: {
										stroke: "#85858D",
										strokeWidth: 2,
									},
									// legend: `mean : ${
									// 	findMean(props.data, props.series).meanX
									// }`,

									legendPosition: "bottom",
									legendOrientation: "horizontal",
									textStyle: { fontSize: "0.75rem" },
								},
						  ]
						: []
				}
				theme={theme(
					props.style?.axisWidth,
					props.style?.axisColor,
					props.style?.textColor,
					props.chartArea?.background,
					props.style?.fontSize
				)}
				// theme={{ background: "url(#Gradient2)" }}
				colors={(d) => {
					return String(
						props.series.find((s) => s.name === String(d.serieId))
							?.color
					);
				}}
				xScale={{
					type: "linear",
					min: props.axis?.primaryX?.minValue
						? props.axis?.primaryX?.minValue
						: findRange(props.data, props.series).minX *
								findRange(props.data, props.series).maxX <
						  0
						? findRange(props.data, props.series).midX -
						  (findRange(props.data, props.series).scaleX -
								findRange(props.data, props.series).midX)
						: findRange(props.data, props.series).midX -
						  (findRange(props.data, props.series).scaleX -
								findRange(props.data, props.series).midX),
					max: props.axis?.primaryX?.maxValue
						? props.axis?.primaryX?.maxValue
						: findRange(props.data, props.series).minX *
								findRange(props.data, props.series).maxX <
						  0
						? findRange(props.data, props.series).midX +
						  (findRange(props.data, props.series).scaleX -
								findRange(props.data, props.series).midX)
						: findRange(props.data, props.series).midX +
						  (findRange(props.data, props.series).scaleX -
								findRange(props.data, props.series).midX),
				}}
				yScale={{
					type: props.axis?.primaryY?.scale || "linear",
					min: props.axis?.primaryY?.minValue
						? props.axis?.primaryY?.minValue
						: findRange(props.data, props.series).minY *
								findRange(props.data, props.series).maxY <
						  0
						? findRange(props.data, props.series).midY -
						  (findRange(props.data, props.series).scaleY -
								findRange(props.data, props.series).midY)
						: findRange(props.data, props.series).midY -
						  (findRange(props.data, props.series).scaleY -
								findRange(props.data, props.series).midY),
					max: props.axis?.primaryY?.maxValue
						? props.axis?.primaryY?.maxValue
						: findRange(props.data, props.series).minY *
								findRange(props.data, props.series).maxY <
						  0
						? findRange(props.data, props.series).midY +
						  (findRange(props.data, props.series).scaleY -
								findRange(props.data, props.series).midY)
						: findRange(props.data, props.series).midY +
						  (findRange(props.data, props.series).scaleY -
								findRange(props.data, props.series).midY),
				}}
				margin={
					props.style?.margin || {
						top: 20,
						right: 80,
						bottom: 50,
						left: 50,
					}
				}
				// blendMode=
				layers={
					props.chartArea?.backgroundDivision
						? [
								(innerHeight) =>
									AreaLayer(
										innerHeight,
										findMean(props.data, props.series)
											.meanX,
										findMean(props.data, props.series)
											.meanY,
										props.chartArea?.backgroundDivision
									),
								"grid",
								"axes",
								props.chartArea?.twoByTwo
									? (innerHeight) =>
											customMarker(
												innerHeight,
												findMean(
													props.data,
													props.series
												).meanX,
												findMean(
													props.data,
													props.series
												).meanY,
												setTooltipShow,
												setTooltipContent
											)
									: "markers",
								// "markers",
								"legends",
								"annotations",
								"nodes",
						  ]
						: [
								"grid",
								"axes",
								"markers",
								"legends",
								"annotations",
								"nodes",
						  ]
				}
				axisTop={
					props.axis?.dualAxis && props.axis.primaryX
						? {
								tickValues: generateGrid(
									findRange(props.data, props.series).minX,
									findRange(props.data, props.series).maxX,
									4
								),
								tickSize: props.axis.primaryX?.tickSize,
						  }
						: null
				}
				axisRight={
					props.axis?.dualAxis && props.axis?.primaryY
						? {
								tickValues: generateGrid(
									findRange(props.data, props.series).minY,
									findRange(props.data, props.series).maxY,
									4
								),
								tickSize: props.axis.primaryY.tickSize,
						  }
						: null
				}
				axisBottom={
					props.axis?.primaryX
						? {
								tickValues: generateGrid(
									findRange(props.data, props.series).minX,
									findRange(props.data, props.series).maxX,
									4
								),
								...props.axis?.primaryX,
						  }
						: undefined
				}
				axisLeft={
					props.axis?.primaryY
						? {
								tickValues: generateGrid(
									findRange(props.data, props.series).minY,
									findRange(props.data, props.series).maxY,
									4
								),
								...props.axis?.primaryY,
						  }
						: undefined
				}
				enableGridX={props.chartConfig.cartesian?.grid?.enableGridX}
				enableGridY={props.chartConfig.cartesian?.grid?.enableGridY}
				gridXValues={
					props.chartConfig.cartesian?.grid?.gridXValues
						? props.chartConfig.cartesian?.grid?.gridXValues
						: generateGrid(
								findRange(props.data, props.series).minX,
								findRange(props.data, props.series).maxX,
								4
						  )
				}
				gridYValues={
					props.chartConfig.cartesian?.grid?.gridYValues
						? props.chartConfig.cartesian?.grid?.gridYValues
						: generateGrid(
								findRange(props.data, props.series).minY,
								findRange(props.data, props.series).maxY,
								4
						  )
				}
				isInteractive={props.tooltip ? true : false} // tooltip format to be added
				animate={props.animate}
				tooltip={(node) =>
					props.tooltip?.format
						? customTooltip(
								node,
								props.tooltip.format,
								props.axis?.primaryX?.legend || "",
								props.axis?.primaryY?.legend || "",
								props.title?.title || "",
								findRange(props.data, props.series).midX,
								findRange(props.data, props.series).midY
						  )
						: null
				}
				nodeSize={{
					key: "data.z",
					values: [
						findZRange(props.data, props.series).minZ || 0,
						findZRange(props.data, props.series).maxZ || 10,
					],
					sizes: [4, 18],
					// sizes: [8, 32],
				}}
				nodeComponent={customNode}
				legends={
					props.legend
						? [
								{
									anchor:
										props.legend?.position ||
										"bottom-right",
									direction:
										props.legend?.direction || "column",
									itemHeight: 20,
									itemWidth: 110,
									toggleSerie: true,
									translateX: 120,
									symbolShape: props.legend.symbolShape,
								},
						  ]
						: []
				}
			/>
		</>
	);
};
export default ScatterPlot;

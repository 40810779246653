import { Skeleton, Tag } from "antd";
import { icons } from "../../../GlobalThemes";

/**
 * It is a wrapper of transcript list with result summary toggle and header of search page
 *
 * @param header - The props get the header of the search tab
 * @param noSummary - The props represent if summary is present
 * @param expanded - The props represent if more information on transcript card is visible
 * @param toggleExpand - The function is used to change the expanded property
 * @param toggleSummary - The function is used to change the result summary view
 * @param children - The props contain the children of the wrapper, such as Trancript card
 * @param showSummary - The props represent if summary is visible
 * @param loading - The props represent if page is loading and will render the skeleton in place of the data
 *
 * @returns The transcrpt wrapper with the list of transcripts in it
 */

export default function TranscriptsWrapper(props: {
  header: string;
  subHeader?: string
  noSummary?: boolean;
  expanded?: boolean;
  toggleScrollToTop?: any;
  toggleSummary?: React.Dispatch<React.SetStateAction<boolean>>;
  children?: JSX.Element;
  showSummary?: boolean;
  loading?: boolean;
  noSideSummary?: boolean;
  goodSearch?: any;
  badSearch?: any;
  averageSearch?: any;
  showKeywords?: boolean;
  hideSummaryTags?: boolean;
}) {
  return (
    <>
      {!props.loading ? (
        <div>
          {!props.noSideSummary && (
            <div className="py-4 px-4 border-b">
              <div className="flex justify-between">
                <p className="font-semibold text-base" style={{ color: "#716E79" }}>
                  {props.header}
                  <span className="ml-4 font-medium">
                    {!props.hideSummaryTags && props.goodSearch && (
                      <>
                        <Tag color="green">High : {props.goodSearch}</Tag>
                        <Tag color="yellow">Medium : {props.averageSearch}</Tag>
                        <Tag color="red">Low : {props.badSearch}</Tag>
                      </>
                    )}
                  </span>
                </p>

                {!props.noSideSummary && (
                  <div className="flex">
                    <div
                      style={{ color: "#27A6A4" }}
                      className="flex cursor-pointer scroll-btn"
                      onClick={() => {
                        if (props.toggleScrollToTop) props.toggleScrollToTop();
                      }}
                    > {icons.find(
                      (l) => l.id === "ArrowUp"
                    )?.icon}
                      {"Scroll To Top"}
                     
                    </div>
                    
                  </div>
                )}
              </div>
            </div>
          )}
          <div>{props.children}</div>
        </div>
      ) : (
        <>
          <div className="py-3 px-8 border-b">
            <div className="flex justify-between">
              <div className="w-64">
                <Skeleton.Button active size="default" block={true} />
              </div>
              <div className="flex">
                <div className="w-80">
                  <Skeleton.Button active size="default" block={true} />
                </div>
              </div>
            </div>
          </div>
          <div>{props.children}</div>
        </>
      )}
    </>
  );
}

import { Empty, Input, List, Skeleton, Spin, message } from "antd";
import React from "react";
import FilterResultCard from "../../../../Components/FilterResultCard";
import { Transcripts } from "../../../../Components/SearchEngineCards/Transcripts";
import TranscriptsWrapper from "../../../../Components/TransciptsWrapper";
import * as Messages from "../../../../Components/Messages";
import { FindSimilarSearchDummyData, filterOptions } from "../../data";
import { searchQuery } from "../../Search";
import moment from "moment";
import StoriesSummaryCard from "../../../Stories/Components/StoriesSummaryCard";
import { getSearchEmployee } from "../../../../services/search";
import * as StoriesFindSimilarConversations from "../../ServiceHelpers/stories_findSimilarConversations";
import * as SearchServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/search";
import * as OldStoriesServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/oldStories";
import * as NodeGraphServiceHelper from "../../../../Pages/SearchEngine/ServiceHelpers/nodeGraph";
import AppContext from "../../../../store";
// import { SearchFilters } from "../../../../Components/SearchEngineCards/SearchFilters";
// import { icons } from "../../../../GlobalThemes";

/**
 * Renders the Find Similar Conversations View, showing the Filters and the Similar Transcripts.
 *
 * @param storyId - Id of the Story that the component is getting rendered in
 * @param addTranscript - Callback function for handling Addition of Transcript to a Story
 *
 * @returns Find Similar Conversations View
 */

export default function FindSimilarConversations(props: { storyId: number; storyType?: number; addTranscript: (transcriptData: any) => void }) {
	const context = React.useContext(AppContext);
	const [showResultSummary, setShowResultSummary] = React.useState<boolean>(false);
	const [emptyTextField, setEmptyTextField] = React.useState("View transcripts");
	const [summaryData, setSummaryData] = React.useState<string>("");
	const [summaryDataFetched, setSummaryDataFetched] = React.useState<boolean>(false);
	const [dataResult2, setDataResult2] = React.useState<
		{
			filterOptions: {
				filterName: string;
				type: string;
				options: string[];
			}[];
			numberOfPatients: number;
			numberOfSearchResults: number;
			searchResults: {
				id: number;
				s3path: string;
				callDate: string;
				callId: string;
				callTime: string;
				dialogueNo: number;
				name: string;
				tags: string[];
				dialogueAppearedAt: number;
				dialogueLength: number;
				transcriptLength: number;
				addedToStory: boolean;
				patientId: number;
				speaker: string;
				elasticSearchId: string;
				transcripts: {
					dialogueNo: number;
					speaker: string;
					text: string;
				}[];
			}[];
		}[]
	>([]);
	const dataCopy = React.useRef<
		{
			filterOptions: {
				filterName: string;
				type: string;
				options: string[];
			}[];
			numberOfPatients: number;
			numberOfSearchResults: number;
			searchResults: {
				id: number;
				s3path: string;
				callDate: string;
				callId: string;
				callTime: string;
				dialogueNo: number;
				name: string;
				tags: string[];
				dialogueAppearedAt: number;
				dialogueLength: number;
				transcriptLength: number;
				addedToStory: boolean;
				patientId: number;
				speaker: string;
				elasticSearchId: string;
				transcripts: {
					dialogueNo: number;
					speaker: string;
					text: string;
				}[];
			}[];
		}[]
	>([]);
	const [expanded, setExpanded] = React.useState(false);
	const [filters, setFilters] = React.useState<
		{
			filter: string;
			value: string;
		}[]
	>([]);
	const [fetching, setFetching] = React.useState(true);

	// function handleAddToStory(transcriptData: any, storyId: number[]) {
	//   // console.log("DDD", transcriptData);

	//   const temp = [...dataResult2];
	//   const idx = temp[0].searchResults.findIndex(
	//     (res) => res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo
	//   );
	//   console.log("Console", idx);
	//   if (idx !== -1) {
	//     const res = addToStory({
	//       ...temp[0].searchResults[idx],
	//       storiesAdded: [{ storyId: props.storyId, storyName: "" }],
	//       source: 2,
	//     });
	//     return res;
	//   }
	//   return Promise.reject();
	// }

	// async function addToStory(transcriptData: any) {
	//   const key = "updatable";
	//   Messages.loading("Adding to Story", key);

	//   const idx = [...dataResult2][0].searchResults.findIndex(
	//     (res) => res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo
	//   );

	//   const notSelected: {
	//     // index: number;
	//     callId: string;
	//     dialogueNo: number;
	//   }[] = [];
	//   for (let i = 0; i < idx; i++) {
	//     if (!dataResult2[0].searchResults[i].addedToStory)
	//       notSelected.push({
	//         // index: i,
	//         callId: dataResult2[0].searchResults[i].callId,
	//         dialogueNo: dataResult2[0].searchResults[i].dialogueNo,
	//       });
	//   }
	//   // console.log("Transcript Data", notSelected);
	//   console.log(transcriptData, notSelected);
	//   const data: any = await StoriesFindSimilarConversations.addToStory(
	//     {
	//       ...transcriptData,
	//       notAddedStories: notSelected,
	//     },
	//     props.storyId
	//   );
	//   if (data) {
	//     const temp = [...dataResult2];
	//     const idx = temp[0].searchResults.findIndex(
	//       (res) =>
	//         res.callId === transcriptData.callId && res.dialogueNo === transcriptData.dialogueNo
	//     );

	//     temp[0].searchResults[idx].addedToStory = true;
	//     props.addTranscript(temp[0].searchResults[idx]);
	//     // dataCopy.current = [...temp];
	//     setDataResult2(temp);
	//     // setFetchedData(temp);
	//     Messages.success("Added Successfully", key);
	//     return true;
	//   } else {
	//     Messages.error("Failed!", key);
	//     return false;
	//   }
	//   return false;
	// }

	async function fetchdata() {
		setFetching(true);

		const obj: searchQuery = {};
		obj.storyId = props.storyId;
		const studyValue = filters.find((r) => r.filter === "Study Name")?.value;
		const intStudyValue = studyValue ? parseInt(studyValue, 10) : undefined;
		obj.studyId = intStudyValue !== -1 ? intStudyValue : undefined;
		obj.speaker = filters.find((r) => r.filter === "Speaker Type")?.value;
		// obj.startDate = filters.find((r) => r.filter === "Start Date")?.value;
		const startDate = filters.find((r) => r.filter === "Start Date")?.value;
		// obj.endDate = filters.find((r) => r.filter === "End Date")?.value;
		const endDate = filters.find((r) => r.filter === "End Date")?.value;
		// obj.startDate = startDate ? moment(startDate).startOf('month').add(1, 'days').toISOString() : undefined;
		// obj.endDate = endDate ? moment(endDate).add(1, 'months').toISOString() : undefined;
		if (startDate && context.envId !== 3) {
			// searchFilter.startDate = moment(query.startDate).format();
			obj.startDate = moment(startDate).startOf("month").add(1, "days").toISOString();
			obj.endDate = moment(endDate).add(1, "months").toISOString();
		} else if (startDate && context.envId === 3) {
			obj.startDate = moment(startDate).add(1, "days").toISOString();
			obj.endDate = moment(endDate).add(1, "days").toISOString();
		} else {
			obj.startDate = undefined;
			obj.endDate = undefined;
		}

		// obj.showResults = showResults ? (showResults === "All" ? undefined : showResults === "Already added to a story" ? true : false) : undefined;
		// obj.totStart = filters.find((r) => r.filter === "totStart")?.value;
		// obj.totEnd = filters.find((r) => r.filter === "totEnd")?.value;
		const data: any = await StoriesFindSimilarConversations.getAllStories(obj);
		// const data: any = FindSimilarSearchDummyData;
		if (data) {
			// data.filterOptions = filterOptions;
			// dataCopy.current = [{ ...data }];
			setDataResult2([data]);
			setFetching(false);
		}
	}

	const [pinnedStories, setPinnedStories] = React.useState<Array<any>>([]);
	const [fetchedStoryData, setFetchedStoryData] = React.useState<any>([]);
	const [transcriptData, setTranscriptData] = React.useState<any>([]);
	const [transcriptLoading, setTranscriptLoading] = React.useState<boolean>(false);

	const changePinnedStories = (stories: any) => {
		setPinnedStories(stories);
	};

  async function fetchStoryData() {
    const data: any = await OldStoriesServiceHelper.getAllStories(props.storyType);

		if (data) {
			// setFetchedStoryData(FindSimilarSearchDummyData);
			setFetchedStoryData(data);
		}
	}

	async function addStoryToTranscriptData(transcript: {
		id: any;
		name: any;
		tags: any;
		storiesAdded: any;
		source: number;
		studyId: number;
		trId: number;
		studyName: string;
		geography: string;
		wave: string;
		fileName: string;
		dialogueNo: any;
		callId: any;
		callDate: any;
		callTime: any;
		s3path: any;
		addedToStory: any;
		score: number;
		dialogueAppearedAt: any;
		dialogueLength: any;
		transcriptLength: any;
		transcripts: any;
		patientId: any;
		speaker: any;
		content: string;
		context: string;
		context_preview: string;
		elasticSearchId: any;
		storyDialogues: any;
		therapyStatusId: string;
		notAddedStories: any;
	}) {
		const key = "updatable";
		Messages.loading("Adding Transcript to Story", key);

		const data: any = await OldStoriesServiceHelper.addTransToStory(transcript);

		// console.log("Fetched : ", data);
		if (data) {
			Messages.success("Successfully Added Transcript to Story", key);

			const temp = [...dataResult2];
			setTranscriptData(temp);

			const idx = temp[0].searchResults.findIndex((res: any) => res.callId === transcript.callId && res.dialogueNo === transcript.dialogueNo);
			// temp[0].searchResults[idx].storiesAdded = data;

			// if (idx !== -1) {
			//   const res = addToStory({
			//     ...temp[0].searchResults[idx],
			//     storiesAdded: [{ storyId: storyId, storyName: "" }],
			//     source: 2,
			//   });
			//   console.log(res);
			//   return res;
			// }
			//@ts-ignore
			temp[0].searchResults[idx].storiesAdded = data;
			if (data.length === 0) {
				temp[0].searchResults[idx].addedToStory = false;
			}
			setTranscriptData(temp);

			return true;
		} else {
			Messages.error("Failed", key);
			return false;
		}
	}

	const addToStorySearch = (transcriptData: any, storyId: any) => {
		var request = {
			transcriptData: transcriptData,
			storyId: storyId,
		};
		var add: any = [];
		storyId.forEach((story: any, index: number) => {
			add.push({
				storyId: story,
				storyName: fetchedStoryData[fetchedStoryData.findIndex((storyFetch: any) => storyFetch.id === story)].storyName,
			});
		});

		var trans = {
			id: transcriptData.id,
			name: transcriptData.name,
			tags: transcriptData.tags,
			storiesAdded: add,
			source: 1,
			studyId: transcriptData.studyId,
			trId: transcriptData.trId,
			studyName: transcriptData.studyName,
			geography: transcriptData.geography,
			wave: transcriptData.wave,
			fileName: transcriptData.fileName,
			dialogueNo: transcriptData.dialogueNo,
			callId: transcriptData.callId,
			callDate: transcriptData.callDate,
			callTime: transcriptData.callTime,
			s3path: transcriptData.s3path,
			addedToStory: true,
			score: transcriptData.score,
			dialogueAppearedAt: transcriptData.dialogue_appeared_at,
			dialogueLength: transcriptData.dialogueLength,
			transcriptLength: transcriptData.transcriptLength,
			transcripts: transcriptData.transcripts,
			patientId: transcriptData.patientId,
			speaker: transcriptData.speaker,
			content: transcriptData.content,
			context: transcriptData.context,
			context_preview: transcriptData.context_preview,
			elasticSearchId: transcriptData.elasticSearchId,
			storyDialogues: transcriptData.storyDialogues,
			callTypeId: transcriptData.callTypeId,
			therapyStatusId: transcriptData.therapyStatusId,
			notAddedStories: transcriptData.notAddedStories,
		};
		const d = addStoryToTranscriptData(trans);
		return d;
	};

	async function addNewStory(addData: { StoryName: string }) {
		const key = "updatable";
		Messages.loading("Adding Transcript", key);
		// console.log(addData);
		const data: any = await OldStoriesServiceHelper.addNewStory(addData);
		if (data) {
			var temp: any = [];
			var d = data["Date Created"];
			data["Date Created"] = moment(d).format("DD MMM YYYY");
			temp = [data, ...fetchedStoryData];
			setFetchedStoryData(temp);
			Messages.success("Successfully Created New Story", key);
		} else Messages.error("Failed", key);
	}

	const fetchTranscripts = async (query: searchQuery) => {
		setTranscriptLoading(true);
		Messages.loading("Fetching...", "key1");
		const data: any = await SearchServiceHelper.getAllTranscripts(query);
		setTranscriptLoading(false);
		const key = "Key 1";

		if (data) {
			setTranscriptData([data]);
			dataCopy.current = [data];
			Messages.success("Success", "key1");
		} else {
			Messages.error("Fetching failed", "key1");
			setTranscriptData([]);
		}
	};

	async function fetchSummaryData() {
		const s3Data: any = await NodeGraphServiceHelper.getNodeJsons("story/" + props.storyId + ".txt");

		if (s3Data) {
			const summary = s3Data?.summary ? s3Data.summary : "Overview not available at the moment. Please try again later.";
			setSummaryData(summary);
			setSummaryDataFetched(true);
		}
	}

	// const [searchWordRequired, setsearchWordRequired] = React.useState<boolean>(false);
	const [searchTranscriptQuery, setSearchTranscriptQuery] = React.useState<searchQuery>({});
	const [metaData, setMetaData] = React.useState<any>();
	const [fetchedFilters, setFetchedFilters] = React.useState<
		{
			filter: string;
			type: string;
			optionsObject: {
				id: number;
				name: string;
				subgroup: {
					id: number;
					name: string;
				}[];
			}[];
		}[]
	>([]);

	// const showDrawer = () => {
	// 	setVisible(true);
	// };

	async function fetchFilters() {
		// const metaData: any = await getSearchEmployee(-1);
		// if (metaData) {
		//   setMetaData(metaData);
		// }
		if (context.envId !== 3) {
			const data: any = await SearchServiceHelper.getFilters();
			if (data) {
				// console.log("Check fetchFilters",data);
				// console.log("MetaData:"+ metaData[0]);
				setFetchedFilters(data);
			}
		}
	}
	function findId(filter: string, value: string) {
		const grpIdx: number[] = [];
		const idx: number[] = [];
		fetchedFilters.map((f) => {
			f.optionsObject.map((group) => {
				if (filter === f.filter) {
					if (group.subgroup.length === 0 && value === group.name) {
						idx.push(group.id);
						grpIdx.push(group.id);
						return group.id;
					} else if (group.subgroup.length > 0) {
						if (Array.isArray(value) && value.find((f) => f === group.name)) {
							grpIdx.push(group.id);
						} else if (Array.isArray(value)) {
							value.map((v) => {
								const ob = group.subgroup.find((sg) => sg.name === v);
								if (ob) {
									grpIdx.push(group.id);
									idx.push(ob.id);
								}
								return -1;
							});
						}
					}
				}
			});
		});
		return { idx, grpIdx };
	}

	const addSearchFilters = (query: searchQuery) => {
		var searchFilter: searchQuery = searchTranscriptQuery;
		// searchFilter.searchWord = query.searchWord;
		searchFilter.studyIds = query.studyIds;
		searchFilter.metaDataIds = query.metaDataIds;
		searchFilter.pptSearch = query.pptSearch;
		searchFilter.speaker = query.speaker;
		// console.log(
		// 	query.startDate,
		// 	moment(query.startDate, "MMM YYYY").format()
		// );
		// console.log(metaData);
		if ((query.startDate && context.envId === 1) || context.envId === 2) {
			// searchFilter.startDate = moment(query.startDate).format();
			searchFilter.startDate = moment(query.startDate).startOf("month").add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "months");
		} else if (query.startDate && context.envId === 3) {
			searchFilter.startDate = moment(query.startDate).add(1, "days");
			searchFilter.endDate = moment(query.endDate).add(1, "days");
		} else {
			searchFilter.startDate = undefined;
			searchFilter.endDate = undefined;
		}
		// searchFilter.deepSearch = query.deepSearch;
		// searchFilter.testButton1 = query.testButton1;
		// searchFilter.testButton2 = query.testButton2;
		// searchFilter.testButton3 = query.testButton3;
		// searchFilter.testButton4 = query.testButton4;
		// searchFilter.testButton5 = query.testButton5;
		// checksearchWord(searchFilter);
		setSearchTranscriptQuery(searchFilter);
	}; //not in use

	// const checksearchWord = (query: searchQuery) => {
	// 	if (query.searchWord === undefined || query.searchWord === "") {
	// 		setsearchWordRequired(true);
	// 	} else {
	// 		setsearchWordRequired(false);
	// 		setEmptyTextField("No Data");
	// 		//      console.log(query);
	// 		setStreamQuery(query);
	// 		setStreamingProcess(!streamingProcess);
	// 		// fetchTranscripts(query);
	// 	}
	// };

	async function fetchStoryStatus() {
		try {
			const response: any = await StoriesFindSimilarConversations.getStoryStatus(props.storyId);
			if (response.status === "COMPLETED") {
				fetchSummaryData();
				fetchdata();
			} else if (response.status === "IN PROGRESS") {
				const summary = "Story Overview generation in progress. Please wait.";
				setSummaryData(summary);
			} else if (response.status === "FAILED") {
				const summary = "Story Overview generation failed.";
				setSummaryData(summary);
			} else if (response.status === "UNAVAILABLE") {
				const summary = "Begin highlighting the annotations to generate story overview and recommendations.";
				setSummaryData(summary);
			} else if (response.status === "UNCHANGED" && !summaryDataFetched) {
				fetchSummaryData();
			}
		} catch (error) {
			console.error("Error fetching overview summary:", error);
		}
	}

	React.useEffect(() => {
		fetchFilters();
		fetchStoryData();
		// fetchSummaryData();
		// fetchdata();
	}, []);

	React.useEffect(() => {
		if (props.storyId) {
			fetchStoryStatus();
			fetchdata();
		}
	}, [props.storyId, filters]);

	React.useEffect(() => {
		const intervalId = setInterval(fetchStoryStatus, 60000);
		return () => clearInterval(intervalId);
	}, [summaryDataFetched]);

	return (
		<>
			{dataResult2.length > 0 ? (
				<div style={{ height: "calc(100vh - 15rem)", overflowY: "auto" }}>
					{summaryData.length > 0 ? (
						<div className="bg-white shadow-md shadow-gray-900 mb-1">
							<StoriesSummaryCard data={summaryData} searchString="Overview" isloaded={summaryData.length > 0 ? true : false}></StoriesSummaryCard>
						</div>
					) : (
						<></>
					)}
					<div className="p-4 bg-white">
						<div className="grid grid-cols-10">
							<div
								className="col-span-2 border-r-2 p-2 overflow-y-auto"
								// style={{ maxHeight: "30.8em" }}
								style={{ maxHeight: "59vh" }}>
								<FilterResultCard
									filterOptions={filterOptions}
									filters={filters}
									setFilters={setFilters}
									fetchTranscripts={addSearchFilters}
									fetchedFilters={fetchedFilters}
								/>
							</div>
							{!fetching ? (
								<div
									className="col-span-8 px-2 overflow-y-auto"
									// style={{ maxHeight: "30.8em" }}
									style={{ maxHeight: "59vh" }}>
									<TranscriptsWrapper
										header={"References"} //{`${dataResult2[0].numberOfSearchResults} search results across ${dataResult2[0].numberOfPatients} transcripts`}
										expanded={expanded}
										toggleExpand={setExpanded}
										showSummary={showResultSummary}
										toggleSummary={setShowResultSummary}
										loading={transcriptLoading}
										noSideSummary={false}
										noSummary={true}>
										<List
											itemLayout="vertical"
											size="large"
											pagination={{
												hideOnSinglePage: true,
												style: {
													marginLeft: "2rem",
													marginBottom: "1.2rem",
													float: "left",
												},
												onChange: (page) => {
													// console.log(page);
												},
												pageSize: 10,
											}}
											dataSource={dataResult2[0].searchResults}
											locale={{
												emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>{emptyTextField}</span>} />,
											}}
											renderItem={(result: any) => (
												<Transcripts
													data={result}
													expanded={expanded}
													storyId={props.storyId}
													addToStoryApi={addToStorySearch}
													// addToStoryApi={handleAddToStory}
													//added props for stories
													dataForNegativeSampling={dataResult2[0].searchResults}
													pinnedStoriesData={pinnedStories}
													fetchedStoryData={fetchedStoryData}
													fetchStoryData={fetchStoryData}
													addNewStory={addNewStory}
													changePinnedStories={changePinnedStories}
													tagName={"FindSimilarConv"}
												/>
											)}
										/>
									</TranscriptsWrapper>
								</div>
							) : (
								<div
									className="col-span-8 px-2 overflow-y-hidden"
									// style={{ maxHeight: "26.5em" }}
									style={{ height: "59vh" }}>
									<TranscriptsWrapper header="" loading>
										<>
											{[1, 2].map((result) => (
												<div className="p-4 px-8">
													<Skeleton
														loading={true}
														active
														paragraph={{
															rows: 4,
														}}></Skeleton>
												</div>
											))}
										</>
									</TranscriptsWrapper>
								</div>
							)}
						</div>
					</div>
				</div>
			) : (
				<>
					<div className="p-4 bg-white">
						<div className="grid grid-cols-10">
							<div
								className="col-span-2 border-r-2 p-2 overflow-y-auto"
								// style={{ maxHeight: "30.8em" }}
								style={{ height: "59vh" }}>
								<FilterResultCard
									loading={true}
									filterOptions={[]}
									filters={[]}
									setFilters={setFilters}
									fetchTranscripts={addSearchFilters}
									fetchedFilters={fetchedFilters}
								/>
							</div>
							<div
								className="col-span-8 px-2 overflow-y-hidden"
								// style={{ maxHeight: "26.5em" }}
								style={{ height: "59vh" }}>
								<TranscriptsWrapper header="" loading>
									<>
										{[1, 2].map((result) => (
											<div className="p-4 px-8">
												<Skeleton
													loading={true}
													active
													paragraph={{
														rows: 4,
													}}></Skeleton>
											</div>
										))}
									</>
								</TranscriptsWrapper>
							</div>
						</div>
					</div>
				</>
			)}
		</>
	);
}

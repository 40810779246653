import React from "react";
import CardHeaderCircle from "../../../../Components/CardHeaderCircle";
import NoChartData from "../../../../Components/NoChartData";
import { SentimentPatientCard } from "../../../../Components/ReportCards/CommonCardComponents/SentimentPatientCard";
import { icons } from "../../../../GlobalThemes";
import ReportTypes from "../../../../libraries/report-types";
import * as ReportTypesComponentInterface from "../../../../libraries/report-types/charts/interfaces/main";

// const DUMMY_DATA = {
// 	sentimentBreakdown: [
// 		{
// 			dot: "30",
// 			sentiments: {
// 				negative: "11%",
// 				neutral: "44%",
// 				positive: "45%",
// 			},
// 			chartData: [
// 				{
// 					Sad: {
// 						"Series 1": 75,
// 					},
// 					Confused: {
// 						"Series 1": 67,
// 					},
// 					Disoriented: {
// 						"Series 1": 51,
// 					},
// 					Mad: {
// 						"Series 1": 44,
// 					},
// 					Frustrated: {
// 						"Series 1": 30,
// 					},
// 				},
// 			],
// 		},
// 		{
// 			dot: "60",
// 			sentiments: {
// 				negative: "32%",
// 				neutral: "21%",
// 				positive: "47%",
// 			},
// 			chartData: [
// 				{
// 					Sad: {
// 						"Series 1": 75,
// 					},
// 					Confused: {
// 						"Series 1": 67,
// 					},
// 					Disoriented: {
// 						"Series 1": 51,
// 					},
// 					Mad: {
// 						"Series 1": 44,
// 					},
// 					Frustrated: {
// 						"Series 1": 30,
// 					},
// 				},
// 			],
// 		},
// 		{
// 			dot: "90",
// 			sentiments: {
// 				negative: "58%",
// 				neutral: "12%",
// 				positive: "30%",
// 			},
// 			chartData: [
// 				{
// 					Sad: {
// 						"Series 1": 75,
// 					},
// 					Confused: {
// 						"Series 1": 67,
// 					},
// 					Disoriented: {
// 						"Series 1": 51,
// 					},
// 					Mad: {
// 						"Series 1": 44,
// 					},
// 					Frustrated: {
// 						"Series 1": 30,
// 					},
// 				},
// 			],
// 		},
// 		{
// 			dot: "180",
// 			sentiments: {
// 				negative: "49%",
// 				neutral: "24%",
// 				positive: "27%",
// 			},
// 			chartData: [
// 				{
// 					Sad: {
// 						"Series 1": 75,
// 					},
// 					Confused: {
// 						"Series 1": 67,
// 					},
// 					Disoriented: {
// 						"Series 1": 51,
// 					},
// 					Mad: {
// 						"Series 1": 44,
// 					},
// 					Frustrated: {
// 						"Series 1": 30,
// 					},
// 				},
// 			],
// 		},
// 		{
// 			dot: "180+",
// 			sentiments: {
// 				negative: "61%",
// 				neutral: "20%",
// 				positive: "19%",
// 			},
// 			chartData: [
// 				{
// 					Sad: {
// 						"Series 1": 75,
// 					},
// 					Confused: {
// 						"Series 1": 67,
// 					},
// 					Disoriented: {
// 						"Series 1": 51,
// 					},
// 					Mad: {
// 						"Series 1": 44,
// 					},
// 					Frustrated: {
// 						"Series 1": 30,
// 					},
// 				},
// 			],
// 		},
// 	],
// };

function ChartComponent(props: {
	negative: number;
	positive: number;
	neutral: number;
}) {
	console.log("Chart Components ", props);
	return (
		<ReportTypes
			hideShadow
			hideRibbon
			// footer="Footer Text"
			minHeight="30px"
			height="50px"
			bodyPadding="0px 0px"
			title={{ title: "Chart Title" }}
			chartType={ReportTypesComponentInterface.chartType.bar}
			series={[
				{ name: "Series 1", dim: 1, color: "#26C38D" },
				{ name: "Series 2", dim: 2, color: "#B2B0B6" },
				{ name: "Series 3", dim: 3, color: "#E65428" },
			]}
			data={[
				["Emotions"],
				[props.positive],
				[props.neutral],
				[props.negative],
			]}
			axis={{
				primaryX: null,
				primaryY: {
					enable: false,
					reverse: true,
				},
			}}
			chartArea={{
				layout: ReportTypesComponentInterface.chartAreaLayout
					.horizontal,
			}}
			chartConfig={{
				cartesian: {
					gaps: {
						barGap: 0.5,
					},
					line: {
						pointSize: 10,
					},
					grid: {
						enableGridX: false,
						enableGridY: false,
						gridYValues: 3,
						gridXValues: 2,
					},
				},
			}}
			style={{
				margin: {
					top: 5,
					right: 15,
					bottom: 5,
					left: 15,
				},
				labelTextColor: "white",
				axisWidth: 0,
				borderRadius: 2,
				borderWidth: 8,
			}}
		/>
	);
}
function EmotionCard(props: {
	positive: number;
	negative: number;
	neutral: number;
}) {
	return props.positive === undefined ||
		props.negative === undefined ||
		props.neutral === undefined ? (
		<div className="p-6 pt-12">
			<div className="h-16"></div>
		</div>
	) : (
		<div className="p-6 pt-12">
			<div className="flex items-center justify-between">
				<div>
					<div className="text-text-gray-80 text-2xl font-semibold">
						{`${Math.round(props.negative)}%`}
					</div>
					<div className="text-text-gray-60 text-sm">Negative</div>
				</div>
				<div>
					<div className="text-text-gray-80 text-2xl font-semibold">
						{`${
							100 -
							Math.round(props.positive) -
							Math.round(props.negative)
						}%`}
					</div>
					<div className="text-text-gray-60 text-sm">Neutral</div>
				</div>
				<div>
					<div className="text-text-gray-80 text-2xl font-semibold">
						{`${Math.round(props.positive)}%`}
					</div>
					<div className="text-text-gray-60 text-sm">Positive</div>
				</div>
			</div>
			<div className="flex items-center justify-between">
				<div className="text-text-gray-60">
					{icons.find((l) => l.id === "EmotionUnhappy")?.icon}
				</div>
				<div className="w-60">
					<ChartComponent
						positive={Math.round(props.positive)}
						negative={Math.round(props.negative)}
						neutral={
							100 -
							Math.round(props.positive) -
							Math.round(props.negative)
						}
					/>
				</div>
				<div className="text-text-gray-60">
					{icons.find((l) => l.id === "EmotionHappy")?.icon}
				</div>
			</div>
		</div>
	);
}

function CardContent(props: {
	data: { name: string; value: string }[];
	header: string;
}) {
	return (
		<div className="p-4 py-8 ">
			{props.header ? (
				<div className="text-text-gray-90 text-lg font-bold">
					{props.header}
				</div>
			) : (
				<div className="py-3.5"></div>
			)}
			<div className="px-2">
				{props.data.map((need, index) => {
					return (
						<div className="flex items-center justify-between text-text-gray-90 text-base py-2 border-b font-medium">
							<div>{`${index + 1}. ${need.name}`}</div>
							<div>{need.value}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
export default function SentimentBreakdown(props: { data: any }) {
	const [fetchedData, setFetchedData] = React.useState<
		{
			cardName: string;
			gridValue?: number;
			kpi: string[];
			month: string[];
			value: number[];
			unit: string[];
			vsPrevMonth: string[];
			topChart: {
				positive: number;
				negative: number;
				neutral: number;
			};
			chartName: string[];
			xAxisLabel: string[];
			yAxisLabel: string[];
			chartColor: string[];
			chartAccessor: string[];
			footer: string;
			chartData: {
				[id: string]: {
					[id: string]: number;
				};
			}[];
		}[]
	>(props.data);
	const chartColors = ["#DFE0FE", "#A6B2F3", "#7E92E9", "#5369DA", "#2B44C7"];
	return (
		<div className="flex">
			{fetchedData.map((card, index) => {
				return (
					<div className="w-1/5 border-r">
						<CardHeaderCircle
							value={card.cardName}
							header={index === 0 ? true : false}
						/>
						<div className="h-auto">
							<EmotionCard {...card.topChart} />
						</div>
						{index === 0 ? (
							<div className="text-base font-semibold pl-4">
								Overall Negative Sentiments
							</div>
						) : (
							<div className="h-6"></div>
						)}
						{card.chartData.length === 0 ? (
							<div className="h-48 mx-5 mt-2">
								<NoChartData />
							</div>
						) : (
							<div className="pl-4">
								<SentimentPatientCard
									color={chartColors[index]}
									data={card.chartData}
									chartType={
										ReportTypesComponentInterface.chartType
											.bar
									}
									gridValue={fetchedData[0].gridValue}
								/>
							</div>
						)}
					</div>
				);
			})}
		</div>
	);
}

import React from "react";
import {
	createSearchParams,
	Route,
	Routes,
	useLocation,
	useNavigate,
} from "react-router-dom";
import { Drawer as AntDrawer } from "antd";
import SubHeaderComponent from "../../Components/SubHeaderComponent";
import SubNavigation from "../../Components/SubNavigation";

import ProgramOverview from "./ProgramOverview";
import Engagement from "./Engagement";
import PatientExperience from "./PatientExperience";
import Kpis from "./Kpis";
import CustomerSatisfaction from "./CustomerSatisfaction";
import Drawer from "./Components/Drawer";

import "./style.css";
import { dashboardTabs, fetchedFilters } from "./data";

export interface dashboardFilters {
	startDate: string;
	endDate: string;
	callType: string;
	callIntent: string;
	adherence: string;
	therapyStatus: string;
	timeframeDisplay: string;
	patientPersona: string;
}

export function SubTabBody(props: {
	title: string;
	subSubTabs?: string[];
	showDrawer?: () => void;
	component: JSX.Element;
	filters?: FilterInterface;
	setFilters?: React.Dispatch<React.SetStateAction<FilterInterface>>;
	filterActiveStatus?: FilterActiveInterface;
	setFilterActiveStatus?: React.Dispatch<
		React.SetStateAction<FilterActiveInterface>
	>;
}) {
	return (
		<div className="dashboard">
			<SubHeaderComponent
				title={props.title}
				showDrawer={props.showDrawer}
				filters={props.filters}
				setFilters={props.setFilters}
				filterActiveStatus={props.filterActiveStatus}
				// setFilterActiveStatus={props.setFilterActiveStatus}
			/>
			{props.subSubTabs && (
				<SubNavigation subSubTabs={props.subSubTabs} />
			)}

			<div
				className={
					props.subSubTabs && props.subSubTabs?.length > 0
						? "bg-body p-4 dashboard-body-subsubtab"
						: "bg-body p-4 dashboard-body"
				}
				style={{ zIndex: -1 }}>
				<div
					className="bg-body h-full w-full fixed"
					style={{ zIndex: -1 }}></div>
				<Routes>
					<Route path={"/"} element={props.component} />
					<Route path={":subsubtab/*"} element={props.component} />
				</Routes>
			</div>
		</div>
	);
}
// export enum FiterTimeframeDisplay {
// 	default = "",
// 	Quarter = "Quarter",
// 	Month = "Month",
// 	Week = "Week",
// }
export interface FilterInterface {
	[id: string]: string | undefined | string[] | number | number[];
}
export interface FilterActiveInterface {
	[id: string]: boolean;
}

export default function Dashboard() {
	// let navigate = useNavigate();
	const [visible, setVisible] = React.useState(false);
	const showDrawer = () => {
		setVisible(true);
	};
	const onClose = () => {
		setVisible(false);
	};

	const [filters, setFilters] = React.useState<FilterInterface>({
		Timeframe_Display: undefined,
		Start_Date: "",
		End_Date: "",
		Call_Type: undefined,
		Therapy_Status: undefined,
		Call_Intent: undefined,
		Adherence: undefined,
		Patient_Persona: undefined,
	});
	const [filterActiveStatus, setFilterActiveStatus] =
		React.useState<FilterActiveInterface>({
			Timeframe_Display: true,
			Start_Date: true,
			End_Date: true,
			Call_Type: true,
			Therapy_Status: true,
			Call_Intent: true,
			Adherence: true,
			Patient_Persona: true,
		});
	const components = [
		<Kpis
			filterActiveStatus={filterActiveStatus}
			setFilterActiveStatus={setFilterActiveStatus}
			filters={filters}
		/>,
		<ProgramOverview
			filterActiveStatus={filterActiveStatus}
			setFilterActiveStatus={setFilterActiveStatus}
			filters={filters}
		/>,
		<Engagement
			filterActiveStatus={filterActiveStatus}
			setFilterActiveStatus={setFilterActiveStatus}
			filters={filters}
		/>,
		<PatientExperience
			filterActiveStatus={filterActiveStatus}
			setFilterActiveStatus={setFilterActiveStatus}
			filters={filters}
		/>,
		<CustomerSatisfaction
			filterActiveStatus={filterActiveStatus}
			setFilterActiveStatus={setFilterActiveStatus}
			filters={filters}
		/>,
	];
	const clearFilters = () => {
		setFilters({
			Timeframe_Display: undefined,
			Start_Date: "",
			End_Date: "",
			Call_Type: undefined,
			Therapy_Status: undefined,
			Call_Intent: undefined,
			Adherence: undefined,
		});
		setVisible(false);
	};
	const navigate = useNavigate();
	const locations = useLocation();

	React.useEffect(() => {
		// console.log("Locations:", locations.pathname);
		const ob: any = {};
		for (let key in filters) {
			if (filters[key]) {
				ob[key] = filters[key];
			}
		}

		navigate({
			search: `?${createSearchParams(ob)}`,
		});
	}, [locations.pathname, filters]);

	const setDashboardFilters = (filters: any) => {
		console.log(filters);
		setFilters(filters);
	};
	return (
		<div>
			<AntDrawer
				title="Filters"
				placement="right"
				onClose={onClose}
				open={visible}
				destroyOnClose={true}>
				<Drawer
					filters={filters}
					setFilters={setDashboardFilters}
					filterOptions={fetchedFilters}
					clearFilters={clearFilters}
					filterActiveStatus={filterActiveStatus}
				/>
			</AntDrawer>
			<SubNavigation tabs={dashboardTabs} url={"/dashboard"} redirectFlag={true} />
			<div className="">
				<Routes>
					{dashboardTabs.map((subTab, index) => (
						<Route
							key={index}
							path={
								index === 0
									? "/"
									: subTab.subTab.split(" ").join("_") + "/*"
							}
							element={
								<SubTabBody
									title={subTab.subTab}
									subSubTabs={subTab.subSubTabs}
									showDrawer={showDrawer}
									component={components[index]}
									filters={filters}
									setFilters={setFilters}
									filterActiveStatus={filterActiveStatus}
									setFilterActiveStatus={
										setFilterActiveStatus
									}
								/>
							}
						/>
					))}
				</Routes>
			</div>
		</div>
	);
}
